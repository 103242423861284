import React, { useEffect, useState } from 'react'
import { 
  Table, 
  Button, 
  Space, 
  Tooltip,
  Modal,
  Row,
  Col,
  Typography,
} from 'antd';
import {
  DeleteOutlined, 
  EditOutlined
} from '@ant-design/icons'
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { DgrStatusEnum, addDgrType } from '../../../../constant/enumerations';
import { setDgrSelectedToEdit } from '../../../../actions/dgrGoodsAction';
import DryIceTableHead from './DryIceTableHead';

const { confirm } = Modal;

const DryIceTable = ({dataSource, setDataSource}) => {
  const { t } = useTranslation("common");
  const [modalVisible, setModalVisible] = useState(false);
  const dgrSelectedValue = useSelector((state) => state.dgrGoods.dgrSelectedValue);
  const isDiableEditDrgGoodFormDecl = useSelector((state) => state.dgrGoods.isDisableEditDrgGoodFormDecl);
  const dispatch = useDispatch();

  const edit = (record) => {
    dispatch(setDgrSelectedToEdit(record));
    setModalVisible(true);
  };
  
  const handleDelete = (deleteSelected) => {
    if (deleteSelected?.id) {
      const newData = dataSource.map((item) => {
        if (item.id === deleteSelected.id) {
          item.codSts = DgrStatusEnum.XX;
        }
        return item;
      });
      setDataSource(newData);
    } else {
      const key = deleteSelected.key;
      const newData = dataSource.filter((item) => item.key !== key);
      setDataSource(newData);
    }
  };

const showConfirm = (item) => {
  confirm({
    title: t("formInfo.confirmDeleteMsg"),
    centered: true,
    okText: t("ok"),
    cancelText: t('dangerousGood.formInfo.btnCancel'),
    content: (
      <>
        <div>{`${t("dangerousGood.formInfo.qtyPcs")}: ${item.qtyPcs}`}</div>
        <div>{`${t("dangerousGood.formInfo.qtyWgt")}: ${item.qtyWgt}`}</div>
        {item.dgrTypes?.map(typ => (<div key={typ.codDgr}>{typ.dgrNam}</div>))}
      </>
    ),
    onOk() {
      handleDelete(item);
      dispatch(setDgrSelectedToEdit(null));
    },
    onCancel() {
      // console.log('Cancel');
    },
  });
};
  
  useEffect(() => {
    const dgrItems = dgrSelectedValue && dgrSelectedValue.dgrItems ? dgrSelectedValue.dgrItems.map((dgrItem, index) => ({
      ...dgrItem,
      key: index
    })) : [];
    setDataSource(dgrItems);
    // eslint-disable-next-line
  },[dgrSelectedValue]);

  const defaultColumns = [
    {
      title: t("dangerousGood.formInfo.qtyPcs"),
      dataIndex: 'qtyPcs',
      key: 'qtyPcs',
      editable: true,
      width: '7%',
    },
    {
      title: t("dangerousGood.formInfo.qtyWgt"),
      dataIndex: 'qtyWgt',
      key: 'qtyWgt',
      editable: true,
      width: '7%',
    },
    {
      title: t("dangerousGood.formInfo.type"),
      dataIndex: 'type',
      key: 'type',
      editable: true,
      width: '40%',
      render: (_, record) => {
        //filter những type nào có codDgr
        const dgrType = record.dgrTypes.filter((type) => type?.codDgr && type?.codDgr !== "");
        return (
          <>
            <Row>
              {dgrType.map((dgrType, index) => {
                return (
                  <Col key={index} span={24} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: '5px' }}>
                    <Typography.Text style={{textAlign: 'left'}}>
                      {dgrType.dgrNam + ""}
                    </Typography.Text>
                    {dgrType?.namDgrExt && (
                      <Typography.Text>
                        {dgrType?.namDgrExt + dgrType?.namDgrSfx}
                      </Typography.Text>
                    )}
                  </Col>
                )
              })}
            </Row>
          </>
        )
      }
    },
    {
      title: t("dangerousGood.tableInfo.action"),
      dataIndex: 'action',
      hidden: isDiableEditDrgGoodFormDecl,
      fixed: 'right',
      width: '10%',
      render: (_, record) => {
        return dataSource.length >= 1 ? (
          <Space>
            <Tooltip title={t("dangerousGood.tableInfo.btnEdit")}>
              <Button type='text' onClick={() => edit(record)}  icon={<EditOutlined />} />
            </Tooltip>
            <Tooltip title={t("dangerousGood.tableInfo.btnDelete")}>
                <Button type='text' danger icon={<DeleteOutlined />} onClick={() => showConfirm(record)} />
            </Tooltip>
          </Space>
        ) : null
      },
    },
  ].filter(column => !column.hidden);

  const iceTable = dataSource.filter(data => data.codTyp === addDgrType.ICE);
  
  return (
    <>
      <Table
          bordered
          dataSource={iceTable.filter(x => x.codSts !== DgrStatusEnum.XX)}
          columns={defaultColumns}
          title={() => (<DryIceTableHead dataSource={dataSource} setDataSource={setDataSource} modalVisible={modalVisible} setModalVisible={setModalVisible}/>)}
          pagination={false}
        >
      </Table>
    </>
);
}

export default DryIceTable