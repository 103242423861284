import { Button, Col, Form, Input, notification, Row } from "antd";
import axios from "axios";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Banner from "../components/Banner";
import * as Constant from "../config/Constant";
import "./page-css/Feedback.css";

const Feedback = () => {
  const { status } = useSelector((state) => state.feedback);
  const { t } = useTranslation("common");
  /* eslint-disable no-template-curly-in-string */
  const validateMessages = {
    required: "${label}" + t("feedback.required"),
    types: {
      email: "${label}" + t("feedback.email"),
      number: "${label} " + t("feedback.number"),
    },
    number: {
      range: "${label} must be between ${min} and ${max}",
    },
  };
  /* eslint-enable no-template-curly-in-string */
  const { langId } = useSelector((state) => state.menu);

  const onFinish = (values) => {
    const data = {
      Name: values.Name || "",
      Email: values.Email || "",
      Subject: values.Subject || "",
      Message: values.Message || "",
      ClassName: "",
      LastEdited: "",
    };
    try {
      axios
        .post(`${Constant.DOMAIN_CLIENT}/feedBack`, data)
        .then((res) => {
          if (!res.data.status) {
            notification.error({
              message: "Send Feedback Fail",
              description: status,
              style: { marginRight: "-50%" },
            });
          } else {
            notification.success({
              message: "Send Feedback Success",
              description: status,
              style: { marginRight: "-50%" },
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Row className="feedback-wrapper">
      <Banner
        title={langId === "2" ? "GÓP Ý" : "FEEDBACK"}
        className="banner-wrapper"
        cover={"../../img/banner-gop-y.jpg"}
      />
      <Row className="feedback-form-wrapper">
        <Row className="feedback-form-title-wrapper">
          <Col className="feedback-form-title" offset={8} span={18}>
            <h2>{langId === "2" ? "Góp Ý" : "Feedback"}</h2>
          </Col>
        </Row>
        <Row className="feedback-form-content-wrapper">
          <Col className="feedback-form-content" offset={2} span={18}>
            <Form
              name="feedback-form"
              onFinish={onFinish}
              validateMessages={validateMessages}
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 10 }}
              autoComplete="off"
            >
              <Form.Item
                name={"Name"}
                label={langId === "2" ? "Họ tên: " : "Name: "}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name={"Email"}
                label="Email: "
                rules={[{ required: true, type: "email" }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name={"Subject"}
                label={langId === "2" ? "Chủ đề: " : "Subject: "}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name={"Message"}
                label={langId === "2" ? "Nội dung: " : "Content: "}
              >
                <Input.TextArea />
              </Form.Item>
              <Button
                style={{ position: "relative", left: "30%" }}
                htmlType="submit"
                name="btnFeedbackSubmit"
                className="btn-feedback-form"
              >
                {langId === "2" ? "Gửi" : "Send"}
              </Button>
            </Form>

            {/* /*need captcha */}
          </Col>
        </Row>
      </Row>
    </Row>
  );
};

export default Feedback;
