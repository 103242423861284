import React from "react";
import { Col, Descriptions, Row, Skeleton } from "antd";
import { useTranslation } from "react-i18next";

const AcceptanceResult = (props) => {
  const { t } = useTranslation("common");

  return (
    <>
      <Row className="result-title-wrapper">
        <Col span={24} className="awb-result-title">
          <h5>{props.titleAcceptance}</h5>
        </Col>
      </Row>
      <Row className="result-content-wrapper">
        <Col className="awb-result-content" span={24}>
          <Skeleton loading={props.loading}>
            <Descriptions bordered size={"small"}>
              <Descriptions.Item label={t("tabInfo.acceptanceDate")}>
                {props.acceptanceDate || ""}
              </Descriptions.Item>
              <Descriptions.Item label={t("agentReport.bookingFlight")}>
                {props.bookingInfo || ""}
              </Descriptions.Item>
            </Descriptions>
          </Skeleton>
        </Col>
      </Row>
    </>
  );
};
export default AcceptanceResult;
