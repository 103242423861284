import React from 'react'
import { Table, Space, Typography } from 'antd';
import { useTranslation } from "react-i18next";
import { errorHandling } from '../../../../config/AuthIntercept';
import { GetVhcRegById } from '../../../../config/esidExportApi';
import { setEsidExpTabPaneActive, setVhcRegSelected } from '../../../../actions/esidExportAction';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { esidExpTabsEnum } from '../../../../constant/enumerations';

const VehicleRegistrationTable = ({dataSource}) => {
    const { t } = useTranslation("common");
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const columns = [
    {
        title: t("esidVhcRegisList.table.vehicleId"),
        dataIndex: 'vehicleId',
    },
    {
        title: t("esidVhcRegisList.table.driverIdNum"),
        dataIndex: 'driverIdNum',
    },
    {
        title: t("esidVhcRegisList.table.driverName"),
        dataIndex: 'driverName',
    },
    {
        title: t("esidVhcRegisList.table.driverPhone"),
        dataIndex: 'driverPhone',
    },
    {
        title: t("esidVhcRegisList.table.datReg"),
        dataIndex: 'datReg',
        render: (value) => <>{(new Date(value)).toLocaleString()}</>,
    },
    {
        title: t("esidVhcRegisList.table.status"),
        dataIndex: 'codSts',
    },
    ];   
    const selectVehicleRegis = async (id) => {
        try {
            const res  =  await GetVhcRegById(id);
            dispatch(setVhcRegSelected(res.data));
            window.scrollTo(0, 380);
            dispatch(setEsidExpTabPaneActive(esidExpTabsEnum.VhcRegis));
        } catch (error) {
            errorHandling(error, history, location.pathname);
        }
    }
    return (
        <Table
            style={{marginTop: 10}}
            columns={columns}
            dataSource={dataSource}
            pagination={{ pageSize: 20, pageSizeOptions: [20]}}
            className={'esid-table'}
            bordered
            title={() => 
                <Space direction='horizontal'>
                    <Typography.Text strong>{t("esid.expVhcRegisList")}</Typography.Text> 
                    <Typography.Text style={{color: 'rgb(255, 151, 0)'}}>{`( ${t("esidExpList.table.rowInfo")} )`}</Typography.Text>
                </Space>
            }
            onRow={(record) => {
                return {
                  onClick: () => selectVehicleRegis(record.vehicleRegistrationId), // click row
                };
            }}
        />
    )
}

export default VehicleRegistrationTable