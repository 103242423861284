import { Form, Typography, Row, Col, InputNumber, Input } from 'antd'
import React, { useEffect, useState } from 'react'
import styles from '../TabExpDeclConsol.module.css'
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { GetConsolByEsid } from '../../../../config/esidExportApi';
import { setExpConsol, setExpConsolDtlList, setExpConsolEdit, setExpConsolRegServices } from '../../../../actions/esidExportAction';
import { errorHandling } from '../../../../config/AuthIntercept';
import { useHistory } from "react-router-dom";
import { createUUID } from '../../../../utils/utils';

export const ShipmentDetailForm = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation("common");
  const [form] = Form.useForm();
  const esidSelected = useSelector(state => state.esidExport.esidExpSelected);
  const expConsol = useSelector(state => state.esidExport.expConsol);
  const expConsolEdit = useSelector(state => state.esidExport.expConsolEdit);
  const consolDtlList = useSelector(state => state.esidExport.consolDtlList);
  const [timer, setTimer] = useState(null);

  const getConsolByESID = async () => {
    if (esidSelected?.id) {
      try {
        const res = await GetConsolByEsid(esidSelected.id);
        dispatch(setExpConsol(res.data));
      } catch (error) {
        dispatch(setExpConsol({
          esidId: esidSelected.id,
          codAwbPfx: esidSelected.codAwbPfx,
          codAwbNum: esidSelected.codAwbNum,
          codAwbSfx: esidSelected.codAwbSfx,
          qtyPcs: esidSelected.qtyPcs,
          codSts: 'NY'
        }));
        errorHandling(error, history, '/Esid/Export', false);
      }
    } else {
      dispatch(setExpConsol(null));
    }
  }

  useEffect(() => {
    const dtlList = expConsol?.consolDetails?.map(dtl => ({
      ...dtl,
      key: dtl.key || createUUID()
    })) || [];
    dispatch(setExpConsolDtlList(dtlList));
    dispatch(setExpConsolRegServices(expConsol?.regisServices || []));
    dispatch(setExpConsolEdit(expConsol));
    // eslint-disable-next-line
  }, [expConsol]);

  useEffect(() => {
    const qtyPcsTotal = consolDtlList?.length > 0 ? consolDtlList?.map(x => x.qtyPcs)?.reduce((a, b) => a + b) : esidSelected?.qtyPcs;
    form.setFieldsValue({
      'qtyPcs': qtyPcsTotal
    });
    dispatch(setExpConsolEdit({...expConsolEdit, 'qtyPcs': qtyPcsTotal}));
    // eslint-disable-next-line
  }, [consolDtlList]);

  useEffect(() => {
    form.setFieldsValue({
      'codAwbPfx': esidSelected?.codAwbPfx,
      'codAwbNum': esidSelected?.codAwbNum,
      'qtyPcs': esidSelected?.qtyPcs
    });
    getConsolByESID();
    // eslint-disable-next-line
  }, [esidSelected]);

  const handleFieldsChange = (_, values) => {
    clearTimeout(timer);
    const timerId = setTimeout(() => {
      dispatch(setExpConsolEdit({...expConsolEdit, ...values}));
    }, 500);
    setTimer(timerId);
  }

  return (
    <Form 
      form={form} 
      labelCol={{ span: 24}} 
      wrapperCol={{ span: 24 }} 
      style={{ marginBottom: 10 }}
      onValuesChange={handleFieldsChange}
      autoComplete="off"
    >
      <Typography.Text strong style={{ fontSize: 20 }}>
        Thông tin hàng hóa tiếp nhận
      </Typography.Text>
      <div className={styles.formItemWrapper}>
        <Form.Item 
          label={t("esidExpList.awb")} 
          style={{ flex: 1, minWidth: 200 }}
        >
          <Row gutter={5} style={{width: '100%', minWidth: 200 }}>
            <Col lg={9} md={10} sm={10} xs={12}>
                <Form.Item
                    name="codAwbPfx"
                    noStyle
                    rules={[{
                      required: true,
                      message: "Required"
                    }]}
                  >
                    <Input placeholder={t('Prefix')} />
                </Form.Item>
            </Col>
            <Col lg={15} md={14} sm={14} xs={12}>
                <Form.Item
                    name="codAwbNum"
                    noStyle
                    rules={[{
                      required: true,
                      message: "Required"
                    }]}
                >
                    <Input placeholder={t('AWB#')} />
                </Form.Item>
            </Col>
        </Row>
        </Form.Item>
        <Form.Item 
          label={"Tổng số kiện"}
          name={'qtyPcs'} 
          style={{ flex: 1, minWidth: 200 }}
          rules={[{
            required: true,
            message: "Required"
          }]}
        >
          <InputNumber controls={false} style={{ height: '100%', width: '100%', minWidth: 200 }} />
        </Form.Item>
      </div>
    </Form>
  )
}
