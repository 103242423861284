import React, { useEffect } from 'react'
import { Col, Row, Form, Button } from 'antd'
import { CodCtcTypEnum } from "../../../constant/enumerations";
import FadeIn from 'react-fade-in';
import { SenderForm } from '../../../pages/admin/EsidExport/components/GeneralInfo/SenderForm';
import { PersonalForm } from '../../../pages/admin/EsidExport/components/GeneralInfo/PersonalForm';
import { useSelector } from 'react-redux';
import { objectToAntFormData } from '../../tracking/CertLivAnm/TabExpCertLivAnm';
import { useTranslation } from 'react-i18next';
export const EsidReceiverInfo = ({ handleNextTab }) => {
    const selectEsidExport = useSelector(state => state.esidExport.esidExpSelected);
    const [shipperForm] = Form.useForm();
    const [agentForm] = Form.useForm();
    const [consigneeForm] = Form.useForm();
    const [notifyForm] = Form.useForm();
    const [senderForm] = Form.useForm();
    const { t } = useTranslation("common");
    useEffect(() => {
        if (selectEsidExport) {
            const { 
                shipper, 
                consignee, 
                agent, 
                notify, 
                shpRegNam,
                shpRegIdx,
                shpRegTel,
            } = selectEsidExport;
            shipperForm.setFields(objectToAntFormData(shipper));
            consigneeForm.setFields(objectToAntFormData(consignee));
            agentForm.setFields(objectToAntFormData(agent));
            notifyForm.setFields(objectToAntFormData(notify));
            senderForm.setFields(objectToAntFormData({
                shpRegNam,
                shpRegIdx,
                shpRegTel,
            }))
        }
        // eslint-disable-next-line
    },[selectEsidExport])
    return (
        <Row gutter={[10, 10]} style={{width: '100%', height: '100%', padding: '5px 0 20px', marginInline: 0}}>
            <Col span={24}>
                <div style={{ width: '100%', gap: 5, display: 'flex', justifyContent: 'flex-end'}}>
                    <Button onClick={handleNextTab} style={{ width: 'fit-content'}}>{t("esidCheckout.nextBtn")}</Button>
                </div>
            </Col>
            <Col style={{width: '100%'}} lg={12} md={12} sm={24}>
                <FadeIn>
                    <PersonalForm readOnly={true} title={CodCtcTypEnum.Shipper} name={'shipper'} form={shipperForm}/>
                    <PersonalForm readOnly={true} title={CodCtcTypEnum.Agent} name={'agent'} form={agentForm}/>
                </FadeIn>
            </Col>
            <Col style={{width: '100%'}} lg={12} md={12} sm={24}>
                <FadeIn transitionDuration={600}>
                    <PersonalForm readOnly={true} title={CodCtcTypEnum.Consignee} name={'consignee'} form={consigneeForm}/>
                    <PersonalForm readOnly={true} title={CodCtcTypEnum.Notify} name={'notify'} form={notifyForm}/>
                </FadeIn>
            </Col>
            <Col style={{width: '100%'}} lg={24} md={24} sm={24}>
                <FadeIn transitionDuration={600}>
                    <SenderForm readOnly={true} form={senderForm} />
                </FadeIn>
            </Col>
        </Row> 
    )
}
