import React, { useEffect, useState } from 'react'
import { 
  Table, 
  Button, 
  Space, 
  Form,
  Tooltip,
  Modal,
  Row,
  Col,
  Typography, 
} from 'antd';
import {
  DeleteOutlined, 
  EditOutlined,
  PlusOutlined
} from '@ant-design/icons'
import { useTranslation } from "react-i18next";
import GoodsTableHead from './GoodsTableHead';
import { useDispatch, useSelector } from 'react-redux';
import { setDgrSelectedValue } from '../../../../../actions/dgrGoodsAction';
import { AddDangerousGoodsModal } from './AddDangerousGoodsModal';
import { DgrStatusEnum, addDgrType } from '../../../../../constant/enumerations';
import useWindowSize from '../../../../../helpers/useWindowSize';
import { SmallGoodsTable } from './SmallGoodsTable';
import { objectToAntFormData } from '../../../../../components/tracking/CertLivAnm/TabExpCertLivAnm';
import { ContentItem } from '../ContentItem';

const GoodsTable = ({ dataSource, isDgrReceived}) => {
  const { t } = useTranslation("common");
  const [editingKey, setEditingKey] = useState('');
  const { user_name, fullName } = useSelector((state) => state.userAwb.currentUser);
  const [nextKey, setNextKey] = useState(dataSource.length > 0 ? parseInt(dataSource[dataSource.length - 1].key) + 1 : 0)
  const selectEsidExport = useSelector((state) => state.esidExport.esidExpSelected);
  const selectDgrGoods = useSelector((state) => state.dgrGoods.dgrSelectedValue) || {};
  // const selectUids = useSelector((state) => state.dgrGoods.uids) || {};
  const [addForm] = Form.useForm();
  const [modalVisible, setModalVisible] = useState(false);
  const [isCheckMamCheckbox, setIsCheckMamCheckbox] = useState(false);
  const dispatch = useDispatch();
  const size = useWindowSize();

  const handleAddOrSave = (formValue, dgrTypes, editingItemKey = -1) => {
    // console.log(formValue, editingItemKey = -1,dgrTypes);

    const newData = [...dataSource];
    const index = newData.findIndex((dataItem) => editingItemKey === dataItem.key);
    if (index > -1) {
        const oldItem = newData[index];
        newData.splice(index, 1, {
            ...oldItem,
            ...formValue,
            codSts: DgrStatusEnum.NY,
            codTyp: addDgrType.DGR,
            dgrTypes: [...dgrTypes]
        });
        dispatch(setDgrSelectedValue({
            ...selectDgrGoods,
            dgrItems: newData
        }));
    } else {
        // const itemUid = selectUids.find(uid => uid.dgrRegSeq === formValue.dgrRegSeq);
        const newItem = {
            key: nextKey,
            // ...itemUid,
            ...formValue,
            dgrAuth: fullName || user_name,
            codSts: DgrStatusEnum.NY,
            codTyp: addDgrType.DGR,
            dgrTypes: [...dgrTypes]
          };
        dispatch(setDgrSelectedValue({
            ...selectDgrGoods,
            dgrItems: [...dataSource,newItem]
        }));
    }
    setEditingKey('');
    addForm.resetFields();
  }

  
  const showDeleteConfirm = (record) => {
    Modal.confirm({
      title: t("dangerousGood.tableInfo.deleteConfirm"),
      content: (
        <div>
          <ContentItem label={t("dangerousGood.formInfo.UN")} value={record.codUid} />
          <ContentItem label={t("dangerousGood.formInfo.probShpName")} value={record.codPsn} />
          {/* <ContentItem label={t("dangerousGood.formInfo.clsDvs")} value={record.clsDvs} />
          <ContentItem label={t("dangerousGood.formInfo.pkgGrp")} value={record.dgrPkg} />
          <ContentItem label={t("dangerousGood.formInfo.qtyTypePkg")} value={record.qtyTypPkg} />
          <ContentItem label={t("dangerousGood.formInfo.pkgInst")} value={record.pkgInst} /> */}
          {
            record.dgrAuth && <ContentItem label={t("dangerousGood.formInfo.author")} value={record.dgrAuth} />
          }
        </div>
      ),
      onOk: () => handleDelete(record.key),
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      centered: true,
    });
  };
  const handleDelete = (key) => {
    const newData = selectDgrGoods?.dgrItems.map((dgrGood) => {
      const temp = dgrGood;
      if (temp.key === key) {
          temp.codSts = DgrStatusEnum.XX;
      }
      return temp;
    })
    dispatch(setDgrSelectedValue({
      ...selectDgrGoods,
      dgrItems: newData
    }));
  };

  const handleEdit = (record) => {
    // console.log(record);

    //dgr
    const isCheckedShcDgd = record?.dgrTypes?.find((type) => type.codDgr === 'DGD');
    const isCheckedShcCao = record?.dgrTypes?.find((type) => type.codDgr === 'CAO');
    const shcMam = record?.dgrTypes?.find((type) => type.codDgr === 'MAM');
    if(shcMam){
      setIsCheckMamCheckbox(true);
    }else{
        setIsCheckMamCheckbox(false);
    }
    const isCheckedShcUN3373 = record?.dgrTypes?.find((type) => type.codDgr === 'UN3373');
    // const note = dgrSelectedToEdit?.dgrTypes?.find((type) => type?.note && type?.note !== "");

    //ice
    const isCheckedShcDric = record?.dgrTypes?.find((type) => type.codDgr === 'DRIC');
    const isCheckedShcUN1845 = record?.dgrTypes?.find((type) => type.codDgr === 'UN1845');

    //pin
    const isCheckedShcUN3481 = record?.dgrTypes?.find((type) => type.codDgr === 'UN3481');
    const isCheckedShcUN3091 = record?.dgrTypes?.find((type) => type.codDgr === 'UN3091');
    const isCheckedShcPI966 = record?.dgrTypes?.find((type) => type.codDgr === 'PI966');
    const isCheckedShcPI967 = record?.dgrTypes?.find((type) => type.codDgr === 'PI967');
    const isCheckedShcPI969 = record?.dgrTypes?.find((type) => type.codDgr === 'PI969');
    const isCheckedShcPI970 = record?.dgrTypes?.find((type) => type.codDgr === 'PI970');
    const isCheckedShcEli = record?.dgrTypes?.find((type) => type.codDgr === 'ELI');
    const isCheckedShcElm = record?.dgrTypes?.find((type) => type.codDgr === 'ELM');

    setEditingKey(record.key);
    addForm.setFields(objectToAntFormData({
      ...record,
      dgrAuth: record.dgrAuth && record.dgrAuth !== '' ? record.dgrAuth : user_name,

      //dgr
      dgrRegSeq: record?.dgrRegSeq,
      codPsn: record?.codPsn,
      shcDgd: isCheckedShcDgd ? true : false,
      shcCao: isCheckedShcCao ? true : false,
      shcMam: shcMam ? true : false,
      pc: shcMam ? shcMam.namDgrExt: "",
      shcUN3373: isCheckedShcUN3373 ? true : false,
      // note: note ? note.note : "",
      qtyPcs: record?.qtyPcs,
      qtyWgt: record?.qtyWgt,
      //ice
      shcDric: isCheckedShcDric ? true : false,
      shcUN1845: isCheckedShcUN1845 ? true : false,
      //pin
      shcUN3481: isCheckedShcUN3481 ? true : false,
      shcUN3091: isCheckedShcUN3091 ? true : false,
      shcPI966: isCheckedShcPI966 ? true : false,
      shcPI967: isCheckedShcPI967 ? true : false,
      shcPI969: isCheckedShcPI969 ? true : false,
      shcPI970: isCheckedShcPI970 ? true : false,
      shcEli: isCheckedShcEli ? true : false,
      shcElm: isCheckedShcElm ? true : false,
    }));
    setModalVisible(true);
  } 

  useEffect(() => {
    dataSource.length > 0 && setNextKey(dataSource[dataSource.length - 1].key + 1);
  },[dataSource])
  
  const defaultColumns = [
    {
      title: t("dangerousGood.formInfo.UN"),
      dataIndex: 'codUid',
      key: 'codUid',
      editable: true,
      width: '8%',
    },
    // {
    //   title: t("dangerousGood.formInfo.probShpName"),
    //   dataIndex: 'codPsn',
    //   key: 'codPsn',
    //   editable: '24%',
    //   align: 'left'
    // },
    {
      title: "SHC",
      dataIndex: 'codShc',
      key: 'codShc',
      editable: true,
      width: '10%',
    },
    {
        title: "ERG CODE",
        dataIndex: 'codErg',
        key: 'codErg',
        editable: true,
        width: '10%',
    },
    {
      title: t("dangerousGood.formInfo.qtyPcs"),
      dataIndex: 'qtyPcs',
      key: 'qtyPcs',
      editable: true,
      width: '7%',
    },
    {
        title: t("dangerousGood.formInfo.qtyWgt"),
        dataIndex: 'qtyWgt',
        key: 'qtyWgt',
        editable: true,
        width: '7%',
    },
    {
      title: t("dangerousGood.formInfo.type"),
      dataIndex: 'type',
      key: 'type',
      editable: true,
      width: '24%',
      align: 'left',
      render: (_, record) => {
        //filter những type nào có codDgr
        const dgrType = record.dgrTypes.filter((type) => type?.codDgr && type?.codDgr !== "").sort((a, b) => a.sorter - b.sorter);
        return (
          <>
            <Row>
              {dgrType.map((dgrType, index) => {
                return (
                  <Col key={index} span={24} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: '5px' }}>
                    <Typography.Text>
                      {dgrType.dgrNam + ""}
                    </Typography.Text>
                    {dgrType?.namDgrExt && (
                      <Typography.Text>
                        {dgrType?.namDgrExt + dgrType?.namDgrSfx}
                      </Typography.Text>
                    )}
                  </Col>
                )
              })}
            </Row>
          </>
        )
      }
    },
    {
      title: t('dangerousGood.formInfo.note'),
      dataIndex: 'desRmk',
      key: 'desRmk',
      width: '15%',
      align: 'left',
      render: (value) => <div dangerouslySetInnerHTML={{ __html: value?.replace(/\n/g, "<br />")}} />
    },
    {
      title: t("dangerousGood.tableInfo.action"),
      dataIndex: 'action',
      width: '10%',
      hidden: !selectEsidExport || isDgrReceived,
      fixed: 'right',
      render: (_, record) => {
        return (
          dataSource.length >= 1 && !isDgrReceived ? (
            <Space>
              <Tooltip title={t("dangerousGood.tableInfo.btnEdit")}>
                <Button type='text' onClick={() => handleEdit(record)}  icon={<EditOutlined />} />
              </Tooltip>
              <Tooltip title={t("dangerousGood.tableInfo.btnDelete")}>
                  <Button type='text' danger icon={<DeleteOutlined />} onClick={() => showDeleteConfirm(record)} />
              </Tooltip>
            </Space>
          ) : null
        )
      },
    },
  ];

  const dgrTable = dataSource.filter(data => data.codTyp === addDgrType.DGR);

  return (
    <>
      <AddDangerousGoodsModal 
        editingKey={editingKey}
        modalVisible={modalVisible} 
        setModalVisible={setModalVisible} 
        form={addForm} 
        handleAddOrSave={handleAddOrSave} 
        typeAdd={addDgrType.DGR}
        isCheckMamCheckbox={isCheckMamCheckbox}
        setIsCheckMamCheckbox={setIsCheckMamCheckbox}
      />
      {
        ['xl', 'lg', 'md', 'xs', 'sm'].includes(size) ? ( // thẻ card hiển thị không đúng, tạm dùng table
          <Table
            bordered
            dataSource={dgrTable.filter(x => x.codSts !== DgrStatusEnum.XX)}
            // scroll={{x: 1200}}
            columns={defaultColumns.filter(col => !col.hidden)}
            title={() => (<GoodsTableHead setEditingKey={setEditingKey} setModalVisible={setModalVisible} addForm={addForm} disabled={isDgrReceived}/>)}
            pagination={false}
            className={'form-animals'}
            style={{ marginTop: 10, paddingInline: 5 }}
          >
          </Table>
        ) : (
          <>
            <div style={{ width: '100%', display: 'flex', justifyContent:'flex-end', paddingInline: 5, marginTop: 10}}>
              <Button 
                type='primary'
                onClick={() =>{
                    setEditingKey('')
                    setModalVisible(true);
                    addForm.resetFields();
                }} 
                icon={<PlusOutlined/>}
                disabled={isDgrReceived}
              >
                  {`${t("addBtn")}`}
              </Button>
            </div>
            <SmallGoodsTable dataSource={dataSource} handleEdit={handleEdit} handleDelete={showDeleteConfirm} isDgrReceive={isDgrReceived}/>
          </>
        )
      }
    </>
);
}

export default GoodsTable