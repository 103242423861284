/* eslint-disable */

import React from "react";
import { Row, Col } from "antd";
import "./component-css/ContactsDetail.css";
import { useSelector } from "react-redux";

const ContactsDetail = (props) => {
  const { langId } = useSelector((state) => state.menu);

  return (
    <Row className="contact-detail-wrapper">
      {props.contacts.map((contact) => {
        if (contact.id_parent === 0)
          return (
            <Col key={contact.id} className="contact-detail" span={5}>
              <Row className="contact-title-wrapper">
                <Col className="contact-title">{contact.name}</Col>
              </Row>
              <Row className="contact-content-wrapper">
                <Col className="contact-content">
                  <p>
                    {contact.title_of_manager}: {contact.department_manager}
                  </p>
                  <p>
                    {contact.ext_tel !== "" && (
                      <>
                        {langId ==="2" ? "Số nội bộ: " : "Ext: "}
                        {contact.ext_tel}
                      </>
                    )}
                  </p>
                  <p>
                    {contact.hotline !== "" && (
                      <>
                        {langId ==="2" ? "Đường dây nóng: " : "Hotline: "}
                        {contact.hotline}
                      </>
                    )}
                  </p>
                  <p>
                    Email:
                    <a href={`mailto:${contact.email}`}>{contact.email}</a>
                  </p>
                </Col>
              </Row>
            </Col>
          );
      })}
    </Row>
  );
};

export default ContactsDetail;
