/* eslint-disable */
import React, { useEffect, useState } from "react";
import {
  Button,
  Radio,
  Modal,
  Input,
  Form,
  Col,
  Row,
  Typography,
  notification,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { checkinUser, userAwbLogout } from "../../../actions/userAWB";
import {
  IMP_WEB_CHK_UPD_PAYMENT_MODE,
  IMP_WEB_CHK_CHK_PAID,
  updateOrderId,
} from "../../../config/apiConfig";
import { payWithoutOTP } from "../../../config/checkinApi";
import { setPaymentInfo } from "./../../../actions/payment";
const { TextArea } = Input;

const ModalSelectMethod = ({
  visible,
  data,
  setVisible,
  loading,
  setLoading,
  isHawb,
  setHasData,
  loadData,
}) => {
  const [visibleConfirm, setVisibleConfirm] = useState(false);
  const { token } = useSelector((state) => state.userAwb.dataCheckin);
  const webTrachToken = useSelector((state) => state.userAwb.dataLogin.token);
  const dispatch = useDispatch();
  const history = useHistory();
  const [transferSyntax, setTransferSyntax] = useState("");
  const [form] = useForm();

  const handleOk = async () => {
    if (form.getFieldValue("method") === "") {
      notification.warning({
        message: "Vui lòng chọn phương thức thanh toán!",
        style: { marginRight: "-50%" },
      });
    } else
      try {
        setLoading(true);
        setHasData(true);
        const updateMethodRes = await IMP_WEB_CHK_UPD_PAYMENT_MODE(
          {
            ...data,
            WEB_PMT_MOD: form.getFieldValue("method"),
            SND_PAY_STR: transferSyntax,
          },
          isHawb ? webTrachToken : token
        );
        if (form.getFieldValue("method") === "WCR") {
          const checkPaidRes = await IMP_WEB_CHK_CHK_PAID(
            {
              ...updateMethodRes.data.P_CURR_OBJ_CHK,
            },
            isHawb ? webTrachToken : token
          );
          if (checkPaidRes.data === "OK") {
            await IMP_WEB_CHK_PAID_ALL_INVOICE(
              {
                ...data,
              },
              isHawb ? webTrachToken : token
            );
          }
          loadData();
        }
        if (form.getFieldValue("method") === "WEB") {
          const payRes = await payWithoutOTP(
            data.AMT_CSH_INV,
            "VND",
            data.TABLE_ID,
            transferSyntax,
            isHawb ? webTrachToken : token
          );
          if (payRes.status === 200 && payRes.data) {
            dispatch(
              setPaymentInfo({
                ...payRes.data,
                orderRef: transferSyntax,
                isHawb: isHawb,
              })
            );
            localStorage.setItem(
              "pInfo",
              JSON.stringify({
                ...payRes.data,
                orderRef: transferSyntax,
                isHawb: isHawb,
              })
            );
            const updateOrderIdRes = await updateOrderId(
              {
                ...data,
                RCV_BNK_TRS_CD1: payRes.data.order.id,
              },
              isHawb ? webTrachToken : token
            );
            if (updateOrderIdRes.data) {
              notification.success({
                message: "Đã cập nhật phương thức thanh toán!",
                style: { marginRight: "-50%" },
              });
              setHasData(true);
              history.push(`/napas/checkout`);
            } else {
              notification.error({
                message: "Lỗi! Vui lòng thử lại!",
                style: { marginRight: "-50%" },
              });
              setHasData(false);
            }
          }
        }
      } catch (error) {
        if (error.response.status === 401) {
          notification.error({
            style: { marginRight: "-50%" },
            message: t("home.sessionNoti"),
          });

          dispatch(checkinUser({}));
          dispatch(setPaymentInfo(undefined));
          dispatch(userAwbLogout());
          localStorage.removeItem("userAWB");
          localStorage.removeItem("checkin");
          localStorage.removeItem("pInfo");
          history.push("/");
        } else if (error.response.error) {
          notification.error({
            message: error.response.error,
            style: { marginRight: "-50%" },
          });
          loadData();
        } else
          notification.error({
            message: "Lỗi! Vui lòng refresh trang để thử lại!",
            style: { marginRight: "-50%" },
          });
        console.log(error);
      } finally {
        setVisibleConfirm(false);
        setVisible(false);
      }
  };
  const sendFeedBack = (e) => {
    switch (e.target.value) {
      case "WCR":
        form.setFieldsValue({
          feedback: `Trước ngày ${moment(data.DAT_DLV).format(
            "LL"
          )}, quý khách chuyển số tiền ${data.AMT_CSH_INV.toString().replace(
            /\B(?=(\d{3})+(?!\d))/g,
            ","
          )} VND đến tài khoản ngân hàng với thông tin như sau:
          - Tên tài khoản: CÔNG TY CỔ PHẦN DỊCH VỤ HÀNG HÓA TÂN SƠN NHẤT  
          - Số tài khoản thanh toán VND : 19010600289019
          - Ngân hàng TMCP Kỹ Thương Việt Nam (Vietnam Technological and Commercial Joint Stock Bank)
          - Nội dung thanh toán: KHACH HANG ${data.CNE_CODE} THANH TOAN ${
            data.AMT_CSH_INV
          } VND TIEN DICH VU THEO DON HANG SO ${data.TABLE_ID}`,
        });
        setTransferSyntax(
          `KHACH HANG ${data.CNE_CODE} THANH TOAN ${data.AMT_CSH_INV} VND TIEN DICH VU THEO DON HANG SO ${data.TABLE_ID}`
        );
        break;
      case "WQR":
        form.setFieldsValue({
          feedback: `Quý khách thanh toán trực tuyến bằng cách quét mã QR`,
        });
        setTransferSyntax(
          `KHACH HANG ${data.CNE_CODE} THANH TOAN ${data.AMT_CSH_INV} VND TIEN DICH VU THEO DON HANG SO ${data.TABLE_ID}`
        );
        break;
      case "WEB":
        form.setFieldsValue({
          feedback: `Quý khách thanh toán trực tiếp bằng thẻ ATM nội địa`,
        });
        setTransferSyntax(
          `KHACH HANG ${data.CNE_CODE} THANH TOAN ${data.AMT_CSH_INV} VND TIEN DICH VU THEO DON HANG SO ${data.TABLE_ID}`
        );
        break;
      default:
        form.setFieldsValue({ feedback: "Hãy chọn 1 hình thức thanh toán!" });
        setTransferSyntax("");

        break;
    }
  };
  const showData = () => {
    if (data !== undefined) {
      form.setFieldsValue({
        AMT_GRS: data.AMT_GRS.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        AMT_TAX: data.AMT_TAX.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        AMT_TOT: data.AMT_TOT.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        AMT_CSH_INV: data.AMT_CSH_INV.toString().replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ","
        ),
        AMT_CDT_INV: data.AMT_CDT_INV.toString().replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ","
        ),
        AMT_PRO_INV: data.AMT_PRO_INV.toString().replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ","
        ),
      });
    }
  };
  useEffect(() => {
    showData();
  }, [data]);
  /*   const allowATMList = [
    "00000233",
    "00000498",
    "00008517",
    "00004414",
    "00021003",
    "00008288",
    "00005922",
    "00005977",
    "00004395",
    "00006026",
    "00008695",
    "00008506",
    "00005976",
    "00001132",
    "00005931",
    "00019946",
    "00005930",
  ]; */
  return (
    <Modal
      className="header-bold background-gray"
      title="Chọn phương thức thanh toán"
      width={1000}
      centered
      visible={visible}
      onCancel={() => setVisible(false)}
      footer={[<></>]}
    >
      <Form
        initialValues={{
          feedback: "Hãy chọn 1 hình thức thanh toán!",
          method: "",
        }}
        onFinish={() => setVisibleConfirm(true)}
        form={form}
        layout="vertical"
        autoComplete="off"
      >
        <Row gutter={[5, 0]}>
          <Col md={3} span={24}>
            <Form.Item name="AMT_GRS" label="Số tiền DV">
              <Input readOnly />
            </Form.Item>
          </Col>
          <Col md={3} span={24}>
            <Form.Item name="AMT_TAX" label="Tiền VAT">
              <Input readOnly />
            </Form.Item>
          </Col>
          <Col md={3} span={24}>
            <Form.Item name="AMT_TOT" label="Tổng">
              <Input readOnly />
            </Form.Item>
          </Col>
          <Col md={3} span={24}>
            <Form.Item name="AMT_CSH_INV" label="Tiền mặt">
              <Input readOnly />
            </Form.Item>
          </Col>
          <Col md={3} span={24}>
            <Form.Item name="AMT_CDT_INV" label="Chuyển khoản">
              <Input readOnly />
            </Form.Item>
          </Col>
          <Col md={3} span={24}>
            <Form.Item name="AMT_PRO_INV" label="Ghi nợ">
              <Input readOnly />
            </Form.Item>
          </Col>
          <Col
            style={{
              paddingTop: "3px",
              display: "flex",
              justifyContent: "flex-end",
            }}
            md={3}
            span={24}
          >
            <Form.Item label=" ">
              <Button
                htmlType="submit"
                style={{ width: "80px" }}
                type="primary"
                loading={loading}
                /*                 disabled={!allowATMList.includes(data.CNE_CODE) && !isHawb}
                 */
              >
                OK
              </Button>
            </Form.Item>
          </Col>
          <Col style={{ paddingTop: "3px" }} md={3} span={24}>
            <Form.Item label=" ">
              <Button
                onClick={() => setVisible(false)}
                style={{ width: "80px" }}
              >
                Hủy
              </Button>
            </Form.Item>
          </Col>
        </Row>
        <Form.Item name="method">
          <Radio.Group onChange={sendFeedBack}>
            <Radio disabled value="WCR">
              Thanh toán bằng chuyển khoản
            </Radio>
            <Radio disabled value="WQR">
              Thanh toán bằng QR Code
            </Radio>
            <Radio
              /*               disabled={!allowATMList.includes(data.CNE_CODE) && !isHawb}
               */ value="WEB"
            >
              Thanh toán bằng thẻ ATM nội địa
            </Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item name="feedback">
          <TextArea rows={5} readOnly></TextArea>
        </Form.Item>
        <Typography
          style={{
            color: "red",
            fontWeight: "bold",
            marginTop: "-15px",
            fontSize: "16px",
          }}
        >
          {transferSyntax}
        </Typography>
      </Form>
      <Modal
        centered
        width={"620px"}
        title={"Thông báo"}
        visible={visibleConfirm}
        confirmLoading={loading}
        onCancel={() => setVisibleConfirm(false)}
        footer={[
          <Button onClick={() => setVisibleConfirm(false)}>Không</Button>,
          <Button loading={loading} onClick={handleOk} type="primary">
            Đồng ý
          </Button>,
        ]}
      >
        Bạn có đồng ý chọn phương thức này?
      </Modal>
    </Modal>
  );
};
export default ModalSelectMethod;
