/* eslint-disable */
import React, { useRef, useState } from "react";
import Letter from "../../tabAgentReport/Letter";
import Report from "../../tabAgentReport/Report";

const TabAgentReport = ({ setActiveKey }) => {
  const [renderLetter, setRenderLetter] = useState(false);
  const [AWB, setAWB] = useState({
    HAWB: "",
    MAWB: "",
  });
  const scrollTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };
  const handleRenderLetter = (value1, value2) => {
    setRenderLetter(true);
    setAWB({ HAWB: value1, MAWB: value2 });
    scrollTop();
  };
  return !renderLetter ? (
    <Report
      handleRenderLetter={handleRenderLetter}
      setActiveKey={setActiveKey}
    />
  ) : (
    <Letter AWB={AWB} setRenderLetter={setRenderLetter} />
  );
};
export default TabAgentReport;
