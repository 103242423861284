/* eslint-disable */
import { Button, notification, Skeleton, Tabs } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Banner from "../components/Banner";
import AWB from "../components/checkout/hawb/HawbTabAWB";
import InvoiceInfo from "../components/checkout/hawb/HawbTabInvoiceInfo";
import TabEInvoice from "../components/checkout/hawb/HawbTabEInvoice";
import TabReceiverInfo from "../components/checkout/hawb/HawbTabReceiverInfo";
import "./page-css/Checkout.css";
import {
  IMP_WEB_CHK_LOAD_BIL_CHG_OBJ,
  IMP_WEB_CHK_LOAD,
  IMP_WEB_CHK_GET_AWB_DTL,
  IMP_WEB_CHK_PAID_ALL_INVOICE,
} from "../config/apiConfig";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";
import { checkPaymentSuccess, getMerchantData } from "../config/checkinApi";
import { checkinUser, userAwbLogout } from "../actions/userAWB";
import { setPaymentInfo } from "../actions/payment";

const { TabPane } = Tabs;

const CheckoutHAWB = () => {
  const [activeKey, setActiveKey] = useState();
  const [info, setInfo] = useState();
  const [invoiceInfo, setInvoiceInfo] = useState();
  const [chargeInfo, setChargeInfo] = useState();
  const [billChargeInfo, setBillChargeInfo] = useState();
  const [billChargeDetail, setBillChargeDetail] = useState();
  const [invoiceList, setInvoiceList] = useState();
  const [paymentDetails, setPaymentDetails] = useState();
  const [isInfoCreated, setIsInfoCreated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [awbDetail, setAwbDetail] = useState();
  const [status, setStatus] = useState("NY");

  const { checkinHAWB } = useSelector((state) => state.mawb);
  const { token } = JSON.parse(localStorage.getItem("userAWB"));
  const pInfo = JSON.parse(localStorage.getItem("pInfo"));

  const history = useHistory();
  const location = useLocation();

  const loadData = async () => {
    try {
      setLoading(true);
      let loadRes = await IMP_WEB_CHK_LOAD(checkinHAWB, token);
      const params = queryString.parse(location.search);
      if (Object.keys(params).length !== 0) {
        const dataDecoded = await getMerchantData(params.napasResult, token);
        if (dataDecoded.data.paymentResult.result === "SUCCESS") {
          const isSuccessRes = await checkPaymentSuccess(
            dataDecoded.data.paymentResult.order.id,
            token
          );
          const { transaction, order } = dataDecoded.data.paymentResult;
          if (
            isSuccessRes.data.status === true &&
            parseInt(pInfo.order.tableID) === loadRes.data.mCol[0].TABLE_ID
          ) {
            await IMP_WEB_CHK_PAID_ALL_INVOICE(
              {
                ...loadRes.data.mCol[0],
                RCV_DAT: transaction.acquirer.date,
                RCV_BNK_AMT: transaction.amount,
                RCV_BNK_TRS_COD: transaction.acquirer.transactionId,
                COD_CUR: transaction.currency,
                RCV_BNK_STR: `${transaction.acquirer.date}#${transaction.acquirer.id}#${transaction.acquirer.transactionId}#${transaction.amount}#${transaction.currency}#${transaction.id}#${transaction.type}#`,
                /*   SND_PAY_STR: `${order.amount}#${order.creationTime}#${order.currency}#${order.id}#`, */
                SND_PAY_DAT: order.creationTime,
                BNK_COD: `${order.id}#${pInfo.order.id}#`,
              },
              token
            );
            loadRes = await IMP_WEB_CHK_LOAD(checkinHAWB, token);
            notification.success({
              style: { marginRight: "-50%" },
              message:
                /* dataDecoded.data.paymentResult.response.message */ "Đã hoàn tất làm thủ tục cho lô hàng này!",
            });
          }
          setTimeout(() => history.push("/awb/checkoutHawb"), 1000);
        } else {
          notification.error({
            message:
              dataDecoded.data.paymentResult.error.explanation +
              " " +
              dataDecoded.data.paymentResult.error.validationType +
              " Không nhận được thông tin phản hồi từ ngân hàng, hãy kiểm tra lại thông tin về giao dịch trên tài khoản của bạn và liên hệ với bộ phận Tin học của công ty TCS nếu cần.",
            style: { marginRight: "-50%" },
          });
          setTimeout(() => history.push("/awb/checkoutHawb"), 1000);
        }
      }

      const getAwbDetailRes = await IMP_WEB_CHK_GET_AWB_DTL(checkinHAWB, token);
      setAwbDetail(getAwbDetailRes.data.mCol[0]);
      if (loadRes.data !== null) {
        if (loadRes.data.mCol.length === 0) {
          notification.info({
            style: { marginRight: "-50%" },
            message: "Chưa đăng kí thông tin người nhận",
          });
          setIsInfoCreated(false);
        } else if (loadRes.data.mCol[0].TABLE_ID !== 0) {
          const { COD_STS } = loadRes.data.mCol[0];
          setInfo(loadRes.data.mCol[0]);

          if (COD_STS !== "NY") {
            try {
              const loadBillChargeRes = await IMP_WEB_CHK_LOAD_BIL_CHG_OBJ(
                loadRes.data.mCol[0],
                token
              );
              if (COD_STS !== "AQ")
                setChargeInfo(
                  loadBillChargeRes.data.P_RET_IMP_WEB_BIL_CHG_COLL.mCol
                );
              setInvoiceInfo(
                loadBillChargeRes.data.P_RET_IMP_WEB_BIL_INV_COLL.mCol
              );
              setBillChargeInfo(loadBillChargeRes.data.P_RET_BIL_CHG_COLL.mCol);
              setBillChargeDetail(
                loadBillChargeRes.data.P_RET_BIL_CHG_DTL_COLL.mCol
              );
              setInvoiceList(loadBillChargeRes.data.P_RET_BIL_INV_COLL.mCol);
              setPaymentDetails(
                loadBillChargeRes.data.P_RET_BIL_INV_PMT_COLL.mCol
              );
              setIsInfoCreated(true);
              setStatus(COD_STS);
            } catch (err) {
              notification.error({
                style: { marginRight: "-50%" },
                message:
                  "Không nhận được thông tin phản hồi từ ngân hàng, hãy kiểm tra lại thông tin về giao dịch trên tài khoản của bạn, đăng nhập và thử lại hoặc liên hệ với bộ phận Tin học của công ty TCS nếu cần.",
              });
            }
          } else {
            setIsInfoCreated(false);
          }
        }
      } else {
        setIsInfoCreated(false);
      }
    } catch (err) {
      if (err.response.status === 401) {
        notification.error({
          style: { marginRight: "-50%" },
          message: t("home.sessionNoti"),
        });

        dispatch(checkinUser({}));
        dispatch(setPaymentInfo(undefined));
        dispatch(userAwbLogout());
        localStorage.removeItem("userAWB");
        localStorage.removeItem("checkin");
        localStorage.removeItem("pInfo");
        history.push("/");
      } else
        notification.error({
          style: { marginRight: "-50%" },
          message:
            "Không nhận được thông tin phản hồi từ ngân hàng, hãy kiểm tra lại thông tin về giao dịch trên tài khoản của bạn, đăng nhập và thử lại hoặc liên hệ với bộ phận Tin học của công ty TCS nếu cần.",
        });
    } finally {
      setLoading(false);
    }
  };
  const operations = (
    <Button
      className="exit-btn"
      onClick={() => history.push("/Awb/Agent")}
      style={{ width: "fit-content" }}
    >
      Exit
    </Button>
  );
  useEffect(() => {
    loadData();
  }, []);
  return (
    <React.Fragment>
      <Banner
        title={""}
        className="banner-wrapper"
        cover={"../../img/004.jpg"}
      />{" "}
      <Skeleton loading={loading} active={loading}>
        <div className="checkout-content-wrapper">
          <div className="checkout-content">
            <Tabs
              className="tab-checkout"
              activeKey={activeKey}
              onChange={(key) => setActiveKey(key)}
              defaultActiveKey={"receiver-info"}
              size={"default"}
              tabBarExtraContent={operations}
            >
              <TabPane tab={"receiver info"} key={"receiver-info"}>
                <TabReceiverInfo
                  status={status}
                  isInfoCreated={isInfoCreated}
                  awbDetail={awbDetail}
                  loadData={loadData}
                  info={info}
                  setActiveKey={setActiveKey}
                  className="form-receiver"
                />
              </TabPane>
              <TabPane disabled={status === "NY"} tab={"AWB"} key={"awb"}>
                <AWB
                  status={status}
                  loadData={loadData}
                  awbDetail={awbDetail}
                  billChargeDetail={billChargeDetail}
                  info={info}
                  chargeInfo={chargeInfo}
                  setActiveKey={setActiveKey}
                />
              </TabPane>
              <TabPane
                disabled={status === "NY" || status === "AQ"}
                tab={"invoice info"}
                key={"invoice-info"}
              >
                <InvoiceInfo
                  info={info}
                  status={status}
                  loadData={loadData}
                  invoiceInfo={invoiceInfo}
                  chargeInfo={chargeInfo}
                  setActiveKey={setActiveKey}
                  billChargeInfo={billChargeInfo}
                />
              </TabPane>
              <TabPane
                disabled={status !== "FN" && status != "OK"}
                tab={"Payment Info"}
                key={"eInvoice-info"}
              >
                <TabEInvoice
                  billChargeInfo={billChargeInfo}
                  info={info}
                  status={status}
                  invoiceList={invoiceList}
                  loadData={loadData}
                  paymentDetails={paymentDetails}
                  setActiveKey={setActiveKey}
                />
              </TabPane>
            </Tabs>
          </div>
        </div>
      </Skeleton>
    </React.Fragment>
  );
};
export default CheckoutHAWB;
