import React from "react";
import { Row, Col, Form, Input, Button } from "antd";
import "./component-css/LoginStatisticForm.css";
const LoginStatisticForm = () => {
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };
  const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
  };
  const onFinish = (values) => {
    // console.log("Success:", values);
  };

  const onFinishFailed = (errorInfo) => {
    // console.log("Failed:", errorInfo);
  };
  return (
    <Row className="statistic-wrapper">
      <Row className="statistic-form-title-wrapper">
        <Col className="statistic-form-title" offset={4}>
          <h2>Vui lòng điền thông tin đăng nhập</h2>
        </Col>
      </Row>
      <Row className="statistic-form-wrapper">
        <Col offset={5} span={8} className="form-wrapper">
          <Form
            className="statistic-form"
            {...layout}
            name="basic"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              label="Tên đăng nhập"
              name="username"
              rules={[
                { required: true, message: "Vui lòng nhập tên đăng nhập!" },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item 
              label="Mật khẩu"
              name="password"
              rules={[{ required: true, message: "Vui lòng nhập mật khẩu!" }]}
            >
              <Input.Password style={{background:'#E8F0FE'}}/>
            </Form.Item>
            <br/>
            <Form.Item {...tailLayout}>
              <Button style={{ width: "fit-content" }} htmlType="submit">
                Đăng nhập
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </Row>
  );
};

export default LoginStatisticForm;
