import { Button, Col, Divider, Form, Modal, Row, Space, notification } from 'antd';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DmsSizeContainer } from '../../../pages/admin/EsidExport/components/Acceptance/DimensionSize/DmsSizeContainer';
import { WeightContainer } from '../../../pages/admin/EsidExport/components/Acceptance/Weight/WeightContainer';
import { useDispatch, useSelector } from 'react-redux';
import { setEsidExpSelected, setEsidExpTabPaneActive } from '../../../actions/esidExportAction';
import { esidExpTabsEnum } from '../../../constant/enumerations';
import { RegistryExportPrint } from '../../RegistryExportPrint';
import { useReactToPrint } from 'react-to-print';
import { SaveEsidExpCarDim, SaveEsidExpCarWgt } from '../../../config/nvesidExportApi';
import { errorHandling } from '../../../config/AuthIntercept';
import { useHistory, useLocation } from 'react-router-dom';
import { finalizedCodSts } from '../../../pages/admin/EsidExport/EsidExp';
import { UpdateEsidExp } from '../../../config/esidExportApi';

export const TabExpCarDim = () => {
  const { t } = useTranslation("common");
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const esidSelected = useSelector((state) => state.esidExport.esidExpSelected);
  const dgrSelected = useSelector((state) => state.dgrGoods.dgrSelectedValue); 
  const livAnmsSelectedValue = useSelector((state) => state.livAnms.livAnmsSelectedValue);
  const expCarDimToEdit = useSelector((state) => state.esidExport.expCarDimToEdit);
  const expCarWgtToEdit = useSelector((state) => state.esidExport.expCarWgtToEdit);
  const isEsidExpFinalized = esidSelected ? finalizedCodSts.includes(esidSelected.codSts) : false;
  const [form] = Form.useForm();

  const handleBackToESID = () => {
    window.scrollTo(0, 350);
    dispatch(setEsidExpTabPaneActive(esidExpTabsEnum.ExpRegis));
  };

  const componentRef = useRef();
  // eslint-disable-next-line
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    copyStyles: true,
    pageStyle: `
      @media all {
        .page-break {
          display: none;
        }
      }
      @media print {
        html, body {
          padding: 0.7rem 0.7rem 0.7rem 0.7rem;
          height: initial !important;
          overflow: initial !important;
          -webkit-print-color-adjust: exact;
        }
      }
      @media print {
        .page-break {
          margin-top: 0.7rem;
          display: block;
          page-break-before: auto;
        }
      }
      @media print {
        @page { 
          size: A4 portrait;
          margin: 0;
        }
      }
    `
  });

  const showError = (msg) => {
    notification.error({
      message: t("Oops"),
      description: msg,
      top: 100,
    })
  }

  const showSuccess = (msg) => {
    notification.success({
      message: t("Congrats"),
      description: msg,
      top: 100,
    })
  }

  const [loading, setLoading] = useState(false);
  const handleSaveCarDim = async () => {
    try {
      if (esidSelected?.shcBup !== 'Y' && (!expCarDimToEdit || expCarDimToEdit?.length <= 0)) {
        showError(t('admin.notification.dmsEmptyError'));
        return;
      }
      if (!expCarWgtToEdit) {
        showError(t('admin.notification.wgtEmptyError'));
        return;
      }
      const qtyPcsTotal = expCarDimToEdit.filter(x => x.codSts !== 'XX').map(x => x.qtyDimPcs).reduce((prev, current) => prev + current, 0);
      if (esidSelected?.qtyPcs < qtyPcsTotal) {
        Modal.error({
            title: "Thông báo",
            content: "Số lượng tiếp nhận lớn hơn số lượng khai báo. Vui lòng kiểm tra lại.",
            onOk: () => {
                return;
            }
        });
        return;
      }
      setLoading(true);
      const esid = JSON.parse(JSON.stringify(esidSelected));
      esid.shcCod001 = localStorage.getItem('calcDimMode') || esid.shcCod001;
      if (!esid.expCarDim) {
        esid.expCarDim = esid.expCarDim || [];
      }
      if (!esid.expCarWgt) {
        esid.expCarWgt = esid.expCarWgt || [];
      }

      await UpdateEsidExp(esid);
      await SaveEsidExpCarDim({
        esidId: esidSelected.id,
        dimensionSizes: expCarDimToEdit
      });
      const expCarWgtRes = await SaveEsidExpCarWgt({
        esidId: esidSelected.id,
        weights: expCarWgtToEdit ? [expCarWgtToEdit] : (esidSelected.expCarWgt || []),
      });

      //get updated data
      dispatch(setEsidExpSelected(expCarWgtRes.data));
      setLoading(false);
      showSuccess(t('admin.notification.saveSuccess'));
    } catch (error) {
      setLoading(false);
      errorHandling(error, history, location.pathname, true);
    }
  }

  return (
    <>
      <p className="exp-sub-title" style={{ marginTop: '10px' }}>
        {t('formInfo.declCarDimLabel')}
      </p>
      <Row justify="center">
        <Col span={24} md={24} lg={22} xl={22} className="awb-tab-content">
          <p className="registry-notice">{t('header.registryInvNotice')}</p>
        </Col>
      </Row>
      <DmsSizeContainer isEsidExpFinalized={isEsidExpFinalized} />
      <Divider style={{ margin: '25px auto 15px', borderTop: '2px solid rgb(0 0 0 / 11%)', width: 'calc(100% - 10px)', minWidth: 'calc(100% - 10px)' }}/>
      <WeightContainer form={form} isEsidExpFinalized={isEsidExpFinalized} />
      <Space
        style={{
          marginTop: "20px",
          display: "flex",
          justifyContent: "center",
        }}
        className="awb-tab-content"
      >
        <Button onClick={handleBackToESID} style={{ width: "fit-content" }}>
          {t("formInfo.backToESID")}
        </Button>
        <Button 
          onClick={handleSaveCarDim} 
          style={{ width: "fit-content" }} 
          loading={loading}
          disabled={!['NY', 'VR', 'DR'].includes(esidSelected?.codSts)}
        >
          {t("formInfo.submit")}
        </Button>
        <Button onClick={handlePrint} style={{ width: "fit-content" }}>
          {t("header.print")}
        </Button>
      </Space>
      {esidSelected && (
            <Row>
              <Col span={0}>
                <RegistryExportPrint
                  ref={componentRef}
                  esidExport={esidSelected}
                  dgrSelected={dgrSelected}
                  aviSelected={livAnmsSelectedValue}
                />
              </Col>
            </Row>
          )}
    </>
  );
};
