import React, { useEffect, useState } from 'react';
import { Form, Input, Row, Col, Button, DatePicker, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useHistory, useLocation } from 'react-router-dom';
import { GetAllEsidExpByCondition, ExportAwbsToExcel } from '../../../../../config/nvesidExportApi';
import { errorHandling } from '../../../../../config/AuthIntercept';
import CalendarLocale from 'rc-picker/lib/locale/vi_VN';
import TimePickerLocale from 'antd/lib/time-picker/locale/vi_VN';
import locale from 'antd/es/date-picker/locale/vi_VN';
import 'moment/locale/vi';
import { useSelector } from 'react-redux';
import RoleClaim from '../../../../../constant/roleClaim';
const { RangePicker } = DatePicker;

const ModalSearchForm = ({ name, form, children, setDataSource, filterCodSts, autoRefresh }) => {
  const { t } = useTranslation('common');
  const dateFormat = 'DD-MM-YYYY';
  const { langId } = useSelector((state) => state.menu);
  const frmDat = filterCodSts
    ? localStorage.getItem('esidExpListFrmDat') || moment().startOf('month').toISOString()
    : moment().startOf('month').toISOString();
  const toDat = filterCodSts
    ? localStorage.getItem('esidExpListToDat') || moment().toISOString()
    : moment().toISOString();
  const [dates, setDates] = useState([moment(frmDat), moment(toDat)]);
  const [dateValue, setDateValue] = useState([moment(frmDat), moment(toDat)]);
  const history = useHistory();
  const location = useLocation();
  const [isSearching, setIsSearching] = useState(false);
  // eslint-disable-next-line
  const [rangePickerLocale, setRangePickerLocale] = useState(locale);
  const [dateType, setDateType] = useState(localStorage.getItem('dateType') || 'AcpDat');
  const { currentUser } = useSelector((state) => state.userAwb);
  const { roles } = currentUser;

  const viLocale = {
    ...locale,
    lang: {
      ...locale.lang,
      locale: 'vi_VN',
      placeholder: 'Chọn thời điểm',
      yearPlaceholder: 'Chọn năm',
      quarterPlaceholder: 'Chọn quý',
      monthPlaceholder: 'Chọn tháng',
      weekPlaceholder: 'Chọn tuần',
      rangePlaceholder: ['Ngày bắt đầu', 'Ngày kết thúc'],
      rangeYearPlaceholder: ['Năm bắt đầu', 'Năm kết thúc'],
      rangeQuarterPlaceholder: ['Quý bắt đầu', 'Quý kết thúc'],
      rangeMonthPlaceholder: ['Tháng bắt đầu', 'Tháng kết thúc'],
      rangeWeekPlaceholder: ['Tuần bắt đầu', 'Tuần kết thúc'],
      ...CalendarLocale,
    },
    timePickerLocale: {
      ...TimePickerLocale,
    },
  };
  const disabledDate = (current) => {
    if (!dates) {
      return false;
    }
    const tooLate = dates[0] && current.diff(dates[0], 'days') > 60;
    const tooEarly = dates[1] && dates[1].diff(current, 'days') > 60;
    return !!tooEarly || !!tooLate;
  };
  const onOpenChange = (open) => {
    if (open) {
      setDates([null, null]);
    } else {
      setDates(null);
    }
  };
  const handleRangeDateChange = (val) => {
    val &&
      setDateValue([
        val[0].set({ hour: 0, minute: 0, second: 0, millisecond: 0 }),
        val[1].set({ hour: 23, minute: 59, second: 59, millisecond: 0 }),
      ]);
  };
  const handleOnCalendarChange = (val) => {
    val &&
      (val[0] === null
        ? setDates([null, val[1]?.set({ hour: 23, minute: 59, second: 59, millisecond: 0 })])
        : setDates([val[0].set({ hour: 0, minute: 0, second: 0, millisecond: 0 }), null]));
  };

  const handleSubmitSearch = async (formValue) => {
    const depot = localStorage.getItem('GetFromLocation') || 'TCS';
    setIsSearching(true);
    const fromDate = new Date(dateValue[0]);
    fromDate.setHours(0);
    fromDate.setMinutes(0);
    fromDate.setMilliseconds(0);
    const toDate = new Date(dateValue[1]);
    toDate.setHours(23);
    toDate.setMinutes(59);
    toDate.setMilliseconds(59);
    // tai man hinh danh sach esid, chi luu trang thai ngay tim kiem
    if (filterCodSts) {
      localStorage.setItem('esidExpListFrmDat', fromDate.toISOString());
      localStorage.setItem('esidExpListToDat', toDate.toISOString());
    }
    //
    const paramsValue = filterCodSts
      ? {
          esidId: formValue?.esidId,
          awbPfx: formValue?.awbPfx,
          awbNum: formValue?.awbNum,
          fltNo: formValue?.fltCarAndNum,
          codSts: filterCodSts,
          fromDate: fromDate.toISOString(),
          toDate: toDate.toISOString(),
          dateType,
          depot,
        }
      : {
          esidId: formValue?.esidId,
          awbPfx: formValue?.awbPfx,
          awbNum: formValue?.awbNum,
          fltNo: formValue?.fltCarAndNum,
          fromDate: fromDate.toISOString(),
          toDate: toDate.toISOString(),
          dateType,
          depot,
        };
    try {
      const { data } = await GetAllEsidExpByCondition(paramsValue);
      const newDataSource = data.map((item) => ({
        awbPfxAndNum: `${item.codAwbPfx}-${item.codAwbNum}`,
        fltCarAndNum: `${item.codFltCar}${item.codFltNum}`,
        datFltOrigin: item.datFltOri,
        datFltOri: item.datFltOri,
        codAwbPfx: item.codAwbPfx,
        codAwbNum: item.codAwbNum,
        codAwbSfx: item.codAwbSfx,
        codFltCar: item.codFltCar,
        codFltNum: item.codFltNum,
        shcDgr: item.shcDgr,
        dgrAcpStfCod: item.dgrAcpStfCod,
        usrCre: item.usrCre,
        datCre: item.datCre,
        usrUpd: item.usrUpd,
        datUpd: item.datUpd,
        codSts: item.codSts,
        esidId: item.id,
        desRmk002: item.desRmk002,
        numOfMalSend: item.numOfMalSend,
        key: item.id,
      }));
      setDataSource(newDataSource);
      setIsSearching(false);
    } catch (error) {
      errorHandling(error, history, location.pathname);
      setIsSearching(false);
    }
  };

  useEffect(() => {
    if (filterCodSts) {
      const fDat = filterCodSts
        ? localStorage.getItem('esidExpListFrmDat') || moment().startOf('month').toISOString()
        : moment().startOf('month').toISOString();
      const tDat = filterCodSts
        ? localStorage.getItem('esidExpListToDat') || moment().toISOString()
        : moment().toISOString();
      setDates([moment(fDat), moment(tDat)]);
      setDateValue([moment(fDat), moment(tDat)]);
    }
    handleSubmitSearch({});
    // eslint-disable-next-line
  }, [filterCodSts]);

  useEffect(() => {
    // locale here is Vi
    setRangePickerLocale(langId === '2' ? locale : null);
  }, [langId]);

  const [timer, setTimer] = useState(null);
  const handleNextInput = (evt) => {
    clearTimeout(timer);
    const timeoutId = setTimeout(() => {
      const { maxLength, value, name } = evt.target;
      // Check if they hit the max character length
      if (value.length >= maxLength && name === 'awbPfx') {
        // Get the next input field
        // searchForm.resetFields(['awbNum']);
        const nextSibling = document.querySelector(`input[name=awbNum]`);
        // If found, focus the next field
        if (nextSibling !== null) {
          nextSibling.focus();
        }
      }
    }, 300);
    setTimer(timeoutId);
  };

  useEffect(() => {
    let intervalId = null;
    if (autoRefresh) {
      intervalId = setInterval(async () => {
        console.log('auto refreshing');
        const values = await form.validateFields();
        handleSubmitSearch(values);
      }, autoRefresh * 60 * 1000);
    }
    return () => {
      clearInterval(intervalId);
    };
    // eslint-disable-next-line
  }, [autoRefresh, filterCodSts]);

  const handleChangeDateType = (value) => {
    setDateType(value);
    localStorage.setItem('dateType', value);
  };

  const [downloading, setDownloading] = useState(false);
  const downloadExcelFile = async () => {
    try {
      setDownloading(true);
      const inputRequest = {
        fromDate: moment(dateValue[0]).format('YYYY-MM-DD'),
        toDate: moment(dateValue[1]).format('YYYY-MM-DD'),
        dateType,
        carrier: currentUser?.user_code,
      };
      const response = await ExportAwbsToExcel(inputRequest);
      setDownloading(false);
      // Create a link to download the file
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'exported_awbs.xlsx'); // File name
      document.body.appendChild(link);
      link.click();

      // Clean up by removing the link
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      setDownloading(false);
      console.error('Error downloading the file:', error);
    }
  };

  return (
    <Form
      name={name}
      labelAlign="left"
      labelCol={{ span: 24 }}
      autoComplete="off"
      form={form}
      className="search-form"
      style={{
        width: '100%',
        // border: '1px solid rgb(134, 130, 130)',
        // padding: '10px 10px 0',
        // position: 'relative',
        // borderRadius: '8px',
      }}
      initialValues={{
        esidId: '',
        awbPfx: '',
        awbNum: '',
      }}
      onFinish={handleSubmitSearch}>
      <Row gutter={2} style={{ width: '100%', margin: 0 }}>
        {!roles?.includes(RoleClaim.Airlines) && (
          <Col lg={2} md={5} sm={24} xs={24} style={{ padding: 0 }}>
            <Form.Item name="esidId" label={t('esidExpList.esidId')}>
              <Input placeholder={t('esidExpList.esidId')} />
            </Form.Item>
          </Col>
        )}
        <Col lg={5} md={14} sm={24} xs={24} style={{ padding: 0 }}>
          <Form.Item label={t('esidExpList.awb')} style={{ paddingInline: 5 }}>
            <Row gutter={5} style={{ width: '100%', margin: 0 }}>
              <Col lg={9} md={10} sm={10} xs={12} style={{ padding: 0 }}>
                <Form.Item name="awbPfx">
                  <Input
                    placeholder={t('Prefix')}
                    name="awbPfx"
                    maxLength={3}
                    onKeyUp={handleNextInput}
                  />
                </Form.Item>
              </Col>
              <Col lg={15} md={14} sm={14} xs={12}>
                <Form.Item name="awbNum">
                  <Input placeholder={t('AWB#')} name="awbNum" maxLength={8} />
                </Form.Item>
              </Col>
            </Row>
          </Form.Item>
        </Col>
        <Col lg={4} md={5} sm={24} xs={24}>
          <Form.Item name="fltCarAndNum" label={t('registerCar.table.flightNo')}>
            <Input
              placeholder={t('registerCar.table.flightNo')}
              style={{ textTransform: 'uppercase' }}
            />
          </Form.Item>
        </Col>
        <Col lg={5} md={16} sm={24} xs={24}>
          <Row gutter={5} style={{ width: '100%', margin: 0 }}>
            <Col span={24} style={{ padding: 0 }}>
              <Form.Item required label={t('esidExpList.dateRange')}>
                <RangePicker
                  locale={viLocale}
                  value={dates || dateValue}
                  disabledDate={disabledDate}
                  onCalendarChange={handleOnCalendarChange}
                  onChange={handleRangeDateChange}
                  onOpenChange={onOpenChange}
                  format={`${dateFormat}`}
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col lg={4} md={8} sm={24} xs={24} style={{ height: '100%' }}>
          <Form.Item required label={t('esidExpList.dateBy')}>
            <Select
              value={dateType}
              style={{ width: '100%' }}
              onChange={handleChangeDateType}
              options={[
                { value: 'ShpRegDat', label: 'Ngày lập tờ khai' },
                { value: 'AcpDat', label: 'Ngày tiếp nhận' },
                { value: 'DatFltOri', label: 'Ngày bay' },
              ]}
            />
          </Form.Item>
        </Col>
        <Col lg={4} md={8} sm={24} xs={24} style={{ height: '100%', textAlign: 'left' }}>
          <Form.Item name="awbNum" className="awb-tab-content searchBtn-container" label={' '}>
            <Button
              type="primary"
              htmlType="submit"
              style={{ width: 'fit-content', margin: 'auto' }}
              loading={isSearching}>
              {t('esidExpList.btnSearch')}
            </Button>
          </Form.Item>
        </Col>
      </Row>
      {roles?.includes(RoleClaim.Airlines) && (
        <Row>
          <Button type="primary" onClick={downloadExcelFile} loading={downloading}>
            Xuất excel
          </Button>
        </Row>
      )}
      {children && children}
    </Form>
  );
};

export default ModalSearchForm;
