import { Col, Row } from "antd";
import React from "react";
import * as Constant from "../config/Constant";
import "./component-css/AirlineBrand.css";
const AirlineBrand = (props) => {
  return (
    <Row style={{ width: "100%", height: "110px" }}>
      <Col span={24} className="brand-wrapper" style={{ height: "100%" }}>
        <a href={props.brand.link}>
          <img className="brand-logo" alt="logo" src={`${Constant.IMAGES_URL}${props.brand.image}`} />
        </a>
      </Col>
    </Row>
  );
};

export default AirlineBrand;
