import React from 'react'
import { Typography } from 'antd'

export const ContentItem = ({label, value}) => {
    return (
        <div>
            <Typography.Text strong style={{marginRight: 5}}>
                {`${label}: `}
            </Typography.Text>
            {value}
        </div>
    )
} 
