import {
  ADD_ITEM_TO_ESID_CTC_CMPs,
  GET_ESID_CTC_CMPs,
  SET_AIRPORT_LIST,
  SET_DECL_DGR_VISIBILITY,
  SET_ESID_EXPORT_SELECTED,
  SET_ESID_CTC_CMPs,
  SET_ESID_EXP_TAB_PANE_ACTIVE,
  SET_VHC_REG_SELECTED,
  SET_DIMENSION_SIZES,
  SET_TAB_ESID_VISIBILITY,
  SET_WEIGHT,
  UPDATE_ITEM_TO_ESID_CTC_CMPs,
  SET_SEARCH_CONDITION,
  SET_MODAL_ESID_VISIBILITY,
  SET_EXP_BIL_CHG,
  SET_EXP_BIL_CHG_LST,
  SET_ESID_LIST_CURRENT_PAGE,
  SET_ESID_SORTED_LIST_CONDITION,
  SET_EXP_CAR_DIM_TO_EDIT,
  SET_EXP_CAR_WGT_TO_EDIT,
  SET_EXP_CONSOL_DTL_LST,
  SET_EXP_CONSOL_DTL_EDT,
  SET_EXP_CONSOL,
  SET_EXP_CONSOL_REG_SERVICES,
  SET_EXP_CONSOL_EDT,
  SET_ESID_STF_LIST,
  SET_ESID_STF_USER
} from "../constant/actionTypes";

export const getEsidCtcCmps = () => {
  return {
    type: GET_ESID_CTC_CMPs,
  };
};

export const setEsidCtcCmps = (cmpList) => {
  return {
    type: SET_ESID_CTC_CMPs,
    payload: cmpList,
  };
};

export const addAnItemToEsidCtcCmps = (ctcCmp) => {
  return {
    type: ADD_ITEM_TO_ESID_CTC_CMPs,
    payload: ctcCmp,
  };
};

export const updateAnItemToEsidCtcCmps = (ctcCmp) => {
  return {
    type: UPDATE_ITEM_TO_ESID_CTC_CMPs,
    payload: ctcCmp,
  };
};

export const setAirportList = (airports) => {
  return {
    type: SET_AIRPORT_LIST,
    payload: airports,
  };
};

export const setDeclDgrVisibility = (visible) => {
  return {
    type: SET_DECL_DGR_VISIBILITY,
    payload: visible,
  };
};

export const setEsidExpSelected = (esidExp) => {
  localStorage.setItem('calcDimMode', esidExp?.shcCod001 || '');
  return {
    type: SET_ESID_EXPORT_SELECTED,
    payload: esidExp
  }
}

export const setExpBilChg = (bilChg) => {
  return {
    type: SET_EXP_BIL_CHG,
    payload: bilChg
  }
}

export const setExpBilChgLst = (bilChgLst) => {
  return {
    type: SET_EXP_BIL_CHG_LST,
    payload: bilChgLst
  }
}

export const setEsidExpTabPaneActive = (key) => {
  return {
    type: SET_ESID_EXP_TAB_PANE_ACTIVE,
    payload: key
  }
}

export const setTabEsidVisibility = (tab) => {
  return {
    type: SET_TAB_ESID_VISIBILITY,
    payload: tab
  }
}

export const setModalEsidVisibility = (modalVisibility) => {
  return {
    type: SET_MODAL_ESID_VISIBILITY,
    payload: modalVisibility
  }
}

export const setVhcRegSelected = (value) => {
  return {
    type: SET_VHC_REG_SELECTED,
    payload: value
  }
}
export const setDimensionSizes = (value) => {
  return {
    type: SET_DIMENSION_SIZES,
    payload: value
  }
}
export const setWeights = (value) => {
  return {
    type: SET_WEIGHT,
    payload: value
  }
}

export const setExpCarDimToEdit = (data) => {
  return {
    type: SET_EXP_CAR_DIM_TO_EDIT,
    payload: data
  }
}

export const setExpCarWgtToEdit = (data) => {
  return {
    type: SET_EXP_CAR_WGT_TO_EDIT,
    payload: data
  }
}

export const setExpConsol = (data) => {
  return {
    type: SET_EXP_CONSOL,
    payload: data
  }
}

export const setExpConsolEdit = (data) => {
  return {
    type: SET_EXP_CONSOL_EDT,
    payload: data
  }
}

export const setExpConsolDtlList = (data) => {
  return {
    type: SET_EXP_CONSOL_DTL_LST,
    payload: data
  }
}

export const setExpConsolDtlEdit = (data) => {
  return {
    type: SET_EXP_CONSOL_DTL_EDT,
    payload: data
  }
}

export const setExpConsolRegServices = (data) => {
  return {
    type: SET_EXP_CONSOL_REG_SERVICES,
    payload: data
  }
}

export const setSearchCondition = (value) => {
  return {
    type: SET_SEARCH_CONDITION,
    payload: value
  }
}
export const setEsidListCurrentPage = (value) => {
  return {
    type: SET_ESID_LIST_CURRENT_PAGE,
    payload: value
  }
}
export const setEsidSortedListCondition = (value) => {
  return {
    type: SET_ESID_SORTED_LIST_CONDITION,
    payload: value
  }
}

export const setEsidStfList = (data) => {
  return {
    type: SET_ESID_STF_LIST,
    payload: data
  }
}

export const setEsidStfUser = (data) => {
  return {
    type: SET_ESID_STF_USER,
    payload: data
  }
}
