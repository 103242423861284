/* eslint-disable */

import { Col, Row } from "antd";
import { useHistory } from "react-router-dom";
import React from "react";
import "./component-css/CustomNewsCard.css";
import { useDispatch, useSelector } from "react-redux";
import * as Constant from "../config/Constant";
import Moment from "react-moment";
import { menuItemSelected } from "../actions/menu";
import { getMenuById } from "../config/apiConfig";
const CustomNewsCard = (props) => {
  const { langId, menuItem } = useSelector((state) => state.menu);
  const history = useHistory();
  const dispatch = useDispatch();

  const handleClick = async (postId) => {
    if (langId === "2") {
      const menuParentRes = await getMenuById(8);
      const menu = {
        ...menuItem,
        banner: menuParentRes.data.params,
        menuParentId: menuParentRes.data.parent,
        menuParentTitle: menuParentRes.data.name,
      };
      dispatch(menuItemSelected(menu));
      localStorage.setItem("menuItem", JSON.stringify(menu));
    } else {
      const menuParentRes = await getMenuById(64);
      const menu = {
        ...menuItem,
        banner: menuParentRes.data.params,
        menuParentId: menuParentRes.data.parent,
        menuParentTitle: menuParentRes.data.name,
      };
      dispatch(menuItemSelected(menu));
      localStorage.setItem("menuItem", JSON.stringify(menu));
    }
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    history.push(`/news/detail/${postId}`);
  };
  return (
    <Row className="news-card-wrapper">
      <div className="news-card-title-wrapper">
        <div className="news-card-title-left">
          <img alt="news_logo" src="../img/news_icon.jpg" />
        </div>
        <div
          flex={"auto"}
          className={
            langId === "2"
              ? "news-card-title-right"
              : "news-card-title-right-en"
          }
        >
          {props.title}
        </div>
      </div>
      <Row gutter={[0, 24]} className="posts-list-wrapper">
        {props.news.map((post) => {
          return (
            <Col span={24} key={post.id} className="post-wrapper">
              <div className="post-cover-wrapper">
                <a onClick={() => handleClick(post.id)}>
                  <img
                    className="img-wrapper"
                    src={Constant.IMAGES_URL + "" + post.images}
                    alt="post_cover"
                  />
                </a>
              </div>
              <div className="post-content-wrapper">
                <a onClick={() => handleClick(post.id)}>
                  {post.title.replace(/^(.{85}[^\s]*).*/, "$1") + "..."}
                </a>
                <p className="post-footer">
                  <Moment format="DD-MM-YYYY HH-MM">{post.created}</Moment>
                  <a onClick={() => handleClick(post.id)}>
                    &emsp; {langId === "2" ? "Xem tiếp" : "More"}
                  </a>
                </p>
              </div>
            </Col>
          );
        })}
      </Row>
    </Row>
  );
};
export default CustomNewsCard;
