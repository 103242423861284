/* eslint-disable */

import React, { useEffect } from "react";
import { Row, Col } from "antd";
import "./page-css/Services.css";
import Banner from "../components/Banner";
import CustomTabs from "../components/CustomTabs";
import { useDispatch, useSelector } from "react-redux";
import { showLangSwitch } from "../actions/menu";
import * as Constant from "../config/Constant";

const Services = () => {
  const { menuItem } = useSelector((state) => state.menu);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(showLangSwitch(true));
  }, []);

  return (
    <Row className="services-wrapper">
      <Banner
        title={menuItem.title}
        className="banner-wrapper"
        cover={`${Constant.IMAGES_URL}${menuItem.banner}`}
      />
      <Row className="services-content-wrapper">
        <Col span={19} offset={3}>
          <CustomTabs />
        </Col>
      </Row>
    </Row>
  );
};

export default Services;
