import React from 'react'
import { Select} from 'antd'
import { useTranslation } from "react-i18next";
import tempReqData from '../constant/tempDropdown';

export const tempReqDataToSlcOptions = (temps) => {
  const options = [];
  temps.forEach((temp) => options.push({
    label: `${temp.code} - ${temp.title}`,
    value: temp.code,
    // title: temp.code
  }))
  return options;
}
const UNSelect = ({value, onChange, form, disabled, isSelectOpen, setIsSelectOpen}) => {
  const { t } = useTranslation("common");
  const tempOptions = tempReqData;

  const onChangeSelect = (value) => {
    const selectedOption = tempOptions.find(x => x.code === value);
    form.setFieldsValue({
        shcColTemReq: selectedOption.code,
        tempTitle: selectedOption.title
    })
  };
  const handleDropdownVisible = (open) => {
    setIsSelectOpen(open);
  }

  return (
    <Select
        showSearch
        onDropdownVisibleChange={handleDropdownVisible}
        disabled={disabled}
        // autoFocus
        style={{width: isSelectOpen ? '100%' : '30%'}}
        options={tempReqDataToSlcOptions(tempOptions)}
        onChange={(value) => onChangeSelect(value)}
        value={value}
        optionLabelProp='title'
        placeholder={t("formInfo.uidSelect")}
        optionFilterProp="children"
        filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
        filterSort={(optionA, optionB) =>
            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
        }
    />
  )
}

export default UNSelect