const initialState = {
  dataLogin: { data: { user_code: "" } },
  currentUser: {},
  signedIn: false,
  dataCheckin: {},
  token: ''
};

const decodedProfile = (token) => {
  if (!token) return {};
  const decodedProfile = Buffer.from(token.split('.')[1], 'base64').toString();
  const { user } = JSON.parse(decodedProfile);
  return user;
}

export const userAwbReducer = (state = initialState, action) => {
  switch (action.type) {
    case "USER_LOGGEDIN": {
      const { token } = action.payload;
      return (state = {
        ...state,
        dataLogin: action.payload,
        currentUser: decodedProfile(token),
        token,
        signedIn: true,
      });
    }
    case "CHECKIN": {
      const { token } = action.payload;
      return (state = {
        ...state,
        dataCheckin: action.payload,
        currentUser: decodedProfile(token),
        token,
        signedIn: true,
      });
    }
    case "USER_LOGGEDOUT":
      return (state = {
        ...state,
        dataLogin: { data: { user_code: "" } },
        currentUser: {},
        token: '',
        signedIn: false,
      });
    case "USER_GUEST": {
        const { token } = action.payload;
        return (state = {
          ...state,
          dataLogin: action.payload,
          currentUser: decodedProfile(token),
          token,
          signedIn: false,
        });
      }
    default:
      return state;
  }
};
