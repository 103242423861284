import React from 'react'
import {Button, Typography} from 'antd'
import { useTranslation } from "react-i18next";
import { useForm } from 'antd/lib/form/Form';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PlusOutlined } from '@ant-design/icons';
import { setLivAnmSelectedToEdit } from '../../../../actions/livAnmAction';
import { useEffect } from 'react';
import { AddLivAnimalsModal } from './AddLiveAnimalsModal';

const AnimalsTableHead = ({dataSource, setDataSource, modalVisible, setModalVisible}) => {
  const { t } = useTranslation("common");
  const [addForm] = useForm();
  const [nextKey, setNextKey] = useState(dataSource.length !==0 && parseInt(dataSource[dataSource.length - 1].key) + 1);
  const [editingKey, setEditingKey] = useState('');
  const livAnmsSelectedValue = useSelector((state) => state.livAnms.livAnmsSelectedValue);
  const isDisableEditLivAnmFormDecl = useSelector((state) => state.livAnms.isDisableEditLivAnmFormDecl);
  const dispatch = useDispatch();

  const add = (formValue) => {
    const newItem = {
      key: nextKey,
      ...formValue
    };
    setDataSource([...dataSource,newItem]);
    dispatch(setLivAnmSelectedToEdit(null));
  }

  const update = (newData) => {
    let i = dataSource.findIndex((data) => data.id === newData.id);
    dataSource[i]=newData;
    setDataSource([...dataSource]);
    dispatch(setLivAnmSelectedToEdit(null));
  }

  useEffect(() => {
    dataSource.length !== 0 && setNextKey(dataSource[dataSource.length - 1].key + 1)
  },[dataSource])

  const getSelectedLivAnm = () => {
    const { certDtl } = livAnmsSelectedValue;
    const certDtlItems = certDtl ? 
    certDtl.map((cert,index) => ({
      ...cert,
      key: index
    })) : [];
    setDataSource(certDtlItems);
  }

  useEffect(() => {
    livAnmsSelectedValue && livAnmsSelectedValue !== null && getSelectedLivAnm();
    // eslint-disable-next-line
  },[livAnmsSelectedValue])

  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
        <Typography.Text strong>{t("livAnm.tableInfo.title")}</Typography.Text>
        <Button 
            onClick={() =>{
                setEditingKey(false)
                setModalVisible(true);
                addForm.resetFields();
            }} 
            icon={<PlusOutlined/>}
            disabled={livAnmsSelectedValue?.id && isDisableEditLivAnmFormDecl}
            type='primary'
        >
            {`${t("addBtn")}`}
        </Button>
      </div>
      <AddLivAnimalsModal
        editingKey={editingKey}
        modalVisible={modalVisible} 
        setModalVisible={setModalVisible} 
        form={addForm} 
        handleAdd={add}
        handleUpdate={update}
      />
    </>
  )
}

export default AnimalsTableHead