import {
  Button,
  Modal,
  Table,
  Form,
  Col,
  Row,
  Input,
  notification,
} from "antd";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { setPaymentInfo } from "../../../actions/payment";
import { checkinUser, userAwbLogout } from "../../../actions/userAWB";
import { IMP_WEB_CHK_GET_ACC } from "../../../config/apiConfig";
const { useForm } = Form;
const ModalGetAccount = ({ visible, setVisible, info, ...props }) => {
  const [dataSource, setDataSource] = React.useState([]);
  const [form] = useForm();
  const { MAWB } = useSelector((state) => state.mawb);
  const { dataLogin } = useSelector((state) => state.userAwb);
  const [loading, setLoading] = React.useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation("common");
  const columns = [
    {
      title: "Tên đăng nhập",
      dataIndex: "PROCESSEDUSER",
      key: "PROCESSEDUSER",
      width: "100px",
    },
    {
      title: "Mật khẩu",
      dataIndex: "GOODSIDENTITY",
      key: "GOODSIDENTITY",
      width: "100px",
    },
  ];
  const handleOk = async (values) => {
    try {
      setLoading(true);
      const { DLV_ODR_RCV_ID, DLV_ODR_RCV_NAM } = values;
      const getAccRes = await IMP_WEB_CHK_GET_ACC(
        MAWB.first,
        MAWB.last,
        "",
        "",
        DLV_ODR_RCV_ID,
        DLV_ODR_RCV_NAM,
        dataLogin.token
      );
      if (getAccRes.data !== null) {
        setDataSource(getAccRes.data.mCol);
      } else {
        notification.error({ message: "Lỗi!", style: { marginRight: "-50%" } });
      }
      setLoading(false);
    } catch (error) {
      if (error.response.status === 401) {
        notification.error({
          style: { marginRight: "-50%" },
          message: t("home.sessionNoti"),
        });

        dispatch(checkinUser({}));
        dispatch(setPaymentInfo(undefined));
        dispatch(userAwbLogout());
        localStorage.removeItem("userAWB");
        localStorage.removeItem("checkin");
        localStorage.removeItem("pInfo");
        history.push("/");
      } else
        notification.error({ message: "Lỗi!", style: { marginRight: "-50%" } });
    }
  };
  useEffect(() => {
    return () => {
      form.resetFields();
      setDataSource([]);
    };
    // eslint-disable-next-line
  },[visible]);
  return (
    <Modal
      width={"50%"}
      title="Get Account"
      visible={visible}
      onCancel={() => {
        setVisible(false);
      }}
      footer={[<></>]}
    >
      <Form
        onFinish={handleOk}
        className="account-info"
        layout="vertical"
        form={form}
        initialValues={{ awbFirst: MAWB.first, awbLast: MAWB.last }}
        autoComplete="off"
      >
        <Row gutter={[5, 0]}>
          <Col span={8} md={4}>
            <Form.Item name="awbFirst" label="AWB No.">
              <Input readOnly />
            </Form.Item>
          </Col>
          <Col span={16} md={8}>
            <Form.Item name="awbLast" label=" ">
              <Input readOnly />
            </Form.Item>
          </Col>
          <Col span={8} md={4}>
            <Form.Item label="Suffix">
              <Input readOnly />
            </Form.Item>
          </Col>
          <Col span={16} md={8}>
            <Form.Item label="HAWB">
              <Input readOnly />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[5, 0]}>
          <Col span={12} md={8}>
            <Form.Item
              name="DLV_ODR_RCV_ID"
              required
              rules={[
                {
                  required: true,
                  message: /* t("header.msgComfirm") */ "",
                },
              ]}
              label="CMND/CCCD"
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12} md={8}>
            <Form.Item
              name="DLV_ODR_RCV_NAM"
              rules={[
                {
                  required: true,
                  message: /* t("header.msgComfirm") */ "",
                },
              ]}
              required
              label="Người nhận hàng"
            >
              <Input />
            </Form.Item>
          </Col>
          <Col
            style={{
              display: "flex",
              justifyContent: "center",
            }}
            span={24}
            md={8}
          >
            <Form.Item label=" ">
              <Button
                loading={loading}
                htmlType="submit"
                style={{ fontWeight: "bold" }}
                type="primary"
              >
                Tạo tài khoản
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Table
        bordered
        loading={loading}
        className="table-payment"
        columns={columns}
        pagination={false}
        dataSource={dataSource}
      />
    </Modal>
  );
};

export default ModalGetAccount;
