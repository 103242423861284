import { 
  SET_ESID_FORM, 
  SET_DGR_GOODS, 
  SET_UIDS, 
  SET_ESID_LOADING, 
  SET_DGR_SELECTED_VALUE,
  SET_DISABLE_EDIT_DRG_GOOD_FORM_DECL,
  SET_VISABLE_EDIT_DRG_GOOD_BUTTON,
  SET_DRG_SELECTED_TO_EDIT,
  SET_DGR_TYPE_STATUS_OK,
} from "../constant/actionTypes";

const initialState = {
  uids: [],
  esidForm: {},
  dgrGoods: [],
  dgrSelectedValue: null,
  isEsidFormLoading: false,
  isDisableEditDrgGoodFormDecl: true,
  isVisableEditDgrGoodsButton: true,
  dgrSelectedToEdit: null,
  dgrTypeStatusOK: [],
};

export const dgrGoodsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ESID_FORM:
      return (state = { ...state, esidForm: action.payload });
    case SET_DGR_GOODS:
      return (state = { ...state, dgrGoods: action.payload });
    case SET_UIDS:
      return (state = { ...state, uids: action.payload });
    case SET_ESID_LOADING:
      return (state = { ...state, isEsidFormLoading: action.payload });
    case SET_DGR_SELECTED_VALUE:
      return (state = { ...state, dgrSelectedValue: action.payload });
    case SET_DISABLE_EDIT_DRG_GOOD_FORM_DECL:
      return (state = { ...state, isDisableEditDrgGoodFormDecl: action.payload });
    case SET_VISABLE_EDIT_DRG_GOOD_BUTTON:
      return (state = { ...state, isVisableEditDgrGoodsButton: action.payload });
    case SET_DRG_SELECTED_TO_EDIT:
      return (state = { ...state, dgrSelectedToEdit: action.payload });
    case SET_DGR_TYPE_STATUS_OK:
      return (state = { ...state, dgrTypeStatusOK: action.payload });
    default:
      break;
  }
  return state;
};
