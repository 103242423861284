import React from "react";
import { Row, Col, Input, Form, Button, message } from "antd";
import { useTranslation } from "react-i18next";
import "./component-css/ChangePasswordTab.css";
import { changePasswordUserAwb } from "../config/apiConfig";
import { useHistory } from "react-router";
import { userAwbLogout } from "../actions/userAWB";
import { useDispatch } from "react-redux";

const ChangePasswordTab = () => {
  const [form] = Form.useForm();
  const { t } = useTranslation("common");
  // const [level, setLevel] = useState({});

  const errorMessage = 'Mật khẩu phải có từ 6 ký tự trở lên bao gồm chữ số chữ hoa chữ thường và ký tự đặc biệt';

  function checkpassword(password) {
    let strength = 0;
    if (password.match(/[a-z]+/)) {
      strength += 1;
    }
    if (password.match(/[A-Z]+/)) {
      strength += 1;
    }
    if (password.match(/[0-9]+/)) {
      strength += 1;
    }
    if (password.match(/[$@#&!]+/)) {
      strength += 1;
  
    }

    // setLevel({'password': strength, passwordLength: password.length});

    return strength === 4;
  }

  const tailLayout = {
    wrapperCol: { offset: 10, span: 16 },
  };
  const dispatch = useDispatch();
  const history = useHistory();
  const user = JSON.parse(localStorage.getItem("userAWB"));
  const submit = (values) => {
    if (values.newPass !== values.newPassConfirm) {
      message.error("Mật khẩu mới và nhập lại mật khẩu mới không trùng nhau");
    } else {
      const token = user.token;
      changePasswordUserAwb(values.newPass, values.oldPass, token)
        .then((res) => {
          if (res.data.error) {
            message.error(res.data.error);
          } else {
            message.success("Update password success");
            dispatch(userAwbLogout());
            history.push("/");
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            message.error(error.response.error);
          } else {
            message.error(error.response.status);
          }
        });
    }
  };
  const onFinishFailed = (errorInfo) => {
    // console.log("Failed:", errorInfo);
  };
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 8 },
  };

  return (
    <Row>
      <Row className="awb-tab-title-wrapper">
        <Col span={24} className="awb-tab-title">
          <h5>{t("header.changePass")}</h5>
        </Col>
      </Row>
      <Row className="awb-tab-content">
        <Row className="control-wrapper">
          <Col span={24} className="control">
            <Form
              {...layout}
              className="form-change-pass"
              onFinish={submit}
              onFinishFailed={onFinishFailed}
              form={form}
              initialValues={{
                remember: true,
              }}
              autoComplete="off"
            >
              <Form.Item
                name="oldPass"
                label={t("header.oldPass")}
                rules={[
                  {
                    required: true,
                    message: t("header.msgOldPass"),
                  },
                ]}
              >
                <Input type="password" />
              </Form.Item>
              <Form.Item
                name="newPass"
                label={`${t("header.newPass")}`}
                hasFeedback
                rules={[
                  { required: true, message: t('header.msgNewPass') },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value) return Promise.resolve();
                      const validatePassword = checkpassword(value);
                      if (!validatePassword) return Promise.reject(errorMessage);
                      return Promise.resolve();
                    }
                  })
                ]}
              >
                <Input.Password />
              </Form.Item>
              <Form.Item
                name="newPassConfirm"
                label={t("header.newPassConfirm")}
                dependencies={['newPass']}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: t("header.msgConfirm"),
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('newPass') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error(t("header.passwordNotMatch")));
                    },
                  }),
                ]}
              >
                <Input.Password />
              </Form.Item>
              <Form.Item {...tailLayout}>
                <Button style={{ width: "fit-content" }} htmlType="submit">
                  {t("header.submit")}
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Row>
    </Row>
  );
};

export default ChangePasswordTab;
