import React, { useState } from 'react'
import { Form, Row, Col, Button, DatePicker } from 'antd'
import { useTranslation } from "react-i18next";
import moment from "moment";
import { GetVhcRegByToDate } from '../../../../config/esidExportApi';
import { errorHandling } from '../../../../config/AuthIntercept';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';

const { RangePicker } = DatePicker;
const SearchForm = ({ name, form, children, setDataSource}) => {
    const { t } = useTranslation("common");
    const dateFormat = "DD-MM-YYYY";
    const [dates, setDates] = useState([moment().startOf('month'), moment().endOf('date')]);
    const [dateValue, setDateValue] = useState([moment().startOf('month'), moment().endOf('date')]);
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const location = useLocation();
    const codRefNum=useSelector((state)=>state.userAwb.currentUser.user_name)
    const disabledDate = (current) => {
        if (!dates) {
            return false;
        }
        const tooLate = dates[0] && current.diff(dates[0], 'days') > 30;
        const tooEarly = dates[1] && dates[1].diff(current, 'days') > 30;
        return !!tooEarly || !!tooLate;
    };
    const onOpenChange = (open) => {
        if (open) {
          setDates([null, null]);
        } else {
          setDates(null);
        }
    };
    const handleRangeDateChange = (val) => {
        setDateValue([
            val[0].set({hour:0,minute:0,second:0,millisecond:0}),
            val[1].set({hour:23,minute:59,second:59,millisecond:0})
        ]);
    }
    const handleOnCalendarChange = (val) => {
        val[0] === null ? 
        setDates([null, val[1].set({hour:23,minute:59,second:59,millisecond:0})]) :
        setDates([val[0].set({hour:0,minute:0,second:0,millisecond:0}), null])
    }

    const handleSubmitSearch = async (formValue) => {
        const paramsValue = {
            fromDate:  new Date(dateValue[0]).toISOString(),
            toDate: new Date(dateValue[1]).toISOString(),
            codRefNum: codRefNum,
        }
        
        try {
            setLoading(true);
            const { data } = await GetVhcRegByToDate(paramsValue);
            
            const newDataSource = data.map((item) => ({
                ...item,
                esidId: item.id,
                key: item.id
            }));
            setDataSource(newDataSource);
            setLoading(false);
        } catch (error) {
            setLoading(false)
            errorHandling(error, history, location.pathname);
        }
    }

    useEffect(() => {
        handleSubmitSearch({});
        // eslint-disable-next-line
    }, []);

    return (
        <Form
            name={name}
            labelAlign='right'
            labelCol={{span: 8}}
            wrapperCol={{span: 16}}
            autoComplete="off"
            form={form}
            className="search-form"
            style={{ 
                width: '100%',
                border: '1px solid rgb(134, 130, 130)',
                padding: '10px 10px 0',
                position: 'relative',
                borderRadius: '8px',
            }}
            initialValues={{
                esidId: '',
                awbPfx: '',
                awbNum: '',
            }}
            onFinish={handleSubmitSearch}
        >
            <Row gutter={10} style={{width: '100%', margin: 0}}>
                <Col >
                    <Row gutter={5} style={{width: '100%', margin: 0}}>
                        <Col span={24} style={{paddingTop: 5}}>   
                            <Form.Item
                                required
                                label={t("esidExpList.dateRange")}
                            >
                                <RangePicker
                                    value={dates || dateValue}
                                    disabledDate={disabledDate}
                                    onCalendarChange={handleOnCalendarChange}
                                    onChange={handleRangeDateChange}
                                    onOpenChange={onOpenChange}
                                    format={`${dateFormat}`}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
                <Col style={{height: '100%'}}>
                    <Form.Item
                        name="awbNum"
                        className="awb-tab-content"
                        // label={" "}
                    >
                            <Button 
                                loading={loading}
                                type="primary" 
                                htmlType='submit'
                                style={{ width: "fit-content", marginLeft: 10, marginTop: 10}} 
                            >
                                {t("esidExpList.btnSearch")}
                            </Button>
                    </Form.Item>
                </Col>
            </Row>
            
            { 
                children && children
            }
        </Form>
    )
}

export default SearchForm