import { Layout, Menu } from 'antd'

import React, { useEffect, useState } from 'react'

import { SnippetsOutlined, MenuOutlined, AlignLeftOutlined, TeamOutlined, ContainerOutlined } from '@ant-design/icons'
import Logo from '../../../assets/favicon.ico'
import './style.css'
import { Link, useLocation } from 'react-router-dom';
import { APP_PATH } from '../../../../../constant//app_path';
import useWindowSize from '../../../../../helpers/useWindowSize'
const { Sider } = Layout;

function getItem(
  label,
  key,
  icon,
  children,
) {
  return {
    key,
    icon,
    children,
    label,
  };
}
const items = [
  {
    title: 'ESID',
    children: [
      getItem(APP_PATH.ESID_ACPT.displayTitle, APP_PATH.ESID_ACPT.path, <SnippetsOutlined />),
      getItem(APP_PATH.ESID_EXPORT.displayTitle, APP_PATH.ESID_EXPORT.path, <ContainerOutlined />),
      getItem(APP_PATH.ESID_PERMISSION.displayTitle, APP_PATH.ESID_PERMISSION.path, <TeamOutlined />)
    ]
  },
  // {
  //   title: 'TCS WEB',
  //   children: [
  //     getItem(APP_PATH.HOME.displayTitle,APP_PATH.HOME.path, <HomeOutlined />)
  //   ]
  // },
];
const defaultCollapsedWidth = 80
const SiderComponent = () => {
  const [collapsed, setCollapsed] = useState(true);
  const [collapsedWidth, setCollapsedWidth] = useState(window.innerWidth < 992 ? 0 : defaultCollapsedWidth)
  const { pathname } = useLocation();
  const size = useWindowSize();

  useEffect(() => {
    switch (size) {
      case 'xs':
        setCollapsed(true);
        setCollapsedWidth(0)
        break;
      case 'sm':
        setCollapsed(true);
        setCollapsedWidth(0)
        break;
      case 'md':
        setCollapsed(true);
        setCollapsedWidth(0);
        break;
    
      default:
        setCollapsed(true);
        setCollapsedWidth(defaultCollapsedWidth);
        break;
    }
  }, [size]);

  return (
    <Sider 
        trigger={null} 
        className='sider-container'
        collapsible 
        collapsed={collapsed} 
        onCollapse={(value) => setCollapsed(value)}
        collapsedWidth={collapsedWidth}
        width={ size === 'md' ? 200 : 240}
      >
        <div style={{ position: 'sticky', top: '0', margin: '0 20px', height: 60, display: 'flex', justifyContent: 'center'}}>
          <Link to={`/`}>
            <img style={{maxWidth: 60, height: 'auto', objectFit: 'cover'}} src={Logo} alt="TCS logo" />
          </Link>
        </div>
        <Menu 
          style={{backgroundColor: 'transparent', color: 'black', paddingBlock: 15, position: 'sticky', top: '60px' }}  
          theme="dark" 
          defaultSelectedKeys={[ pathname]} 
          mode="inline" 
        >
          {
            items.map((item, index) => (
              <Menu.ItemGroup title={item.title} style={{color: 'black'}} key={`group${index}`} >
                {
                  item.children?.map((child) => (
                  <Menu.Item style={{color: 'black'}} icon={child.icon} key={child.key} >
                    <span>{child.label}</span>
                    <Link to={child.key} />
                  </Menu.Item>
                  ))
                }
              </Menu.ItemGroup>
              )
            )
          }
        </Menu>
        <div 
          className={`collapse-btn ${collapsed && 'active'}`}
          onClick={() => setCollapsed(!collapsed)}
        >
          { collapsed ? <MenuOutlined /> : <AlignLeftOutlined />}
        </div>
      </Sider>
  )
}

export default SiderComponent