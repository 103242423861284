/* eslint-disable */
import React, { useState } from "react";
import { Col, Row, Switch } from "antd";
import { NavLink } from "react-router-dom";
import Navigator from "./Navigator";
import { changeLang } from "../actions/menu";
import { setLanguage } from "../helpers/language-helper";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getMenuById } from "../config/apiConfig";
import { menuItemSelected } from "../actions/menu";

import "./component-css/CustomHeader.css";

const CustomHeader = () => {
  const [check, setCheck] = useState(true);
  const { t } = useTranslation("common");
  const { langId, isShow, menuItem } = useSelector((state) => state.menu);
  const dispatch = useDispatch();

  const dispatchMenu = async () => {
    const menuRes = await getMenuById(menuItem.referenceId);
    if (menuRes.data.parent !== 0) {
      const menuParentRes = await getMenuById(menuRes.data.parent);
      const menuReference = {
        key: menuRes.data.alias,
        title: menuRes.data.name,
        menuParentTitle: menuParentRes.data.name,
        banner: menuParentRes.data.params,
        menuId: menuRes.data.id,
        menuParentId: menuRes.data.parent,
        images: menuRes.data.images,
        referenceId: menuRes.data.reference_id,
      };
      dispatch(menuItemSelected(menuReference));
      localStorage.setItem("menuItem", JSON.stringify(menuReference));
    } else {
      const menuReference = {
        key: menuRes.data.alias,
        title: menuRes.data.name,
        menuParentTitle: menuRes.data.name,
        banner: menuRes.data.params,
        menuId: menuRes.data.id,
        menuParentId: menuRes.data.parent,
        images: menuRes.data.images,
        referenceId: menuRes.data.reference_id,
      };
      dispatch(menuItemSelected(menuReference));
      localStorage.setItem("menuItem", JSON.stringify(menuReference));
    }
  };

  const switchLang = () => {
    if (langId === "2") {
      setCheck(false);
      dispatch(changeLang("1"));
      localStorage.setItem("langId", "1");
      localStorage.setItem("LANGUAGE", "en");
      dispatchMenu();
      setLanguage("en");
    } else {
      setCheck(true);
      dispatch(changeLang("2"));
      localStorage.setItem("langId", "2");
      localStorage.setItem("LANGUAGE", "vi");
      dispatchMenu();
      setLanguage("vi");
    }
  };
  return (
    <>
      <Row className="header-wrapper">
        <Col
          span={0}
          lg={3}
          style={{ display: "flex", justifyContent: "flex-end" }}
          className="logo-wrapper"
        >
          <NavLink to="/">
            <div style={{ height: "100%" }}>
              <img
                style={{ objectFit: "contain", width: "80%", height: "80%" }}
                className="logo"
                alt="logo"
                src="../../img/logotcs_new_2015.jpg"
              />
            </div>
          </NavLink>
        </Col>
        <Col span={24} lg={19} className="nav-banner-wrapper">
          <div className="nav-banner-content-wrapper">
            <div className="nav-banner-content">Hotline:</div>
            <div className="nav-banner-content nav-banner-content-phone">
              (02-8) 38 486 489
            </div>
            <div
              style={{
                height: "30px",
              }}
              className="nav-banner-content"
              span={"auto"}
            >
              {isShow && (
                <Switch
                  checked={check}
                  onChange={switchLang}
                  checkedChildren={t("header.language")}
                  unCheckedChildren={t("header.language")}
                  className="change-lang"
                ></Switch>
              )}
            </div>
          </div>
          <div className="banner-nav-wrapper">
            <Navigator />
          </div>
        </Col>
      </Row>
    </>
  );
};

export default CustomHeader;
