/* eslint-disable */

import { Card, Col, Row, Steps } from "antd";
import React, { useEffect, useState } from "react";
import "../../components/component-css/GuideContent.css";
import * as API from "../../config/apiConfig";
import { useDispatch, useSelector } from "react-redux";
import Parser from "html-react-parser";
import * as Constant from "../../config/Constant";
import { useLocation } from "react-router";
import { changeLang, menuItemSelected } from "../../actions/menu";

const { Step } = Steps;

const GuideContent = () => {
    const [currentStep, setCurrentStep] = useState(0);

    const dispatch = useDispatch();
    const location = useLocation();
    const onChange = (currentStep) => {
        setCurrentStep(currentStep);
    };
    const { langId, menuItem } = useSelector((state) => state.menu);

    const [steps, setSteps] = useState([]);
    const setMenu = async (menuId, langId) => {
        const menuRes = await API.getMenuById(menuId);
        const menuParentRes = await API.getMenuById(menuRes.data.parent);
        const menuReference = {
            key: menuRes.data.alias,
            title: menuRes.data.name,
            menuParentTitle: menuParentRes.data.name,
            banner: menuParentRes.data.params,
            menuId: menuRes.data.id,
            menuParentId: menuRes.data.parent,
            images: menuRes.data.images,
            referenceId: menuRes.data.reference_id,
        };
        dispatch(changeLang(langId));
        localStorage.setItem("langId", langId || 1);
        localStorage.setItem("LANGUAGE", langId === "2" ? "vi" : "en");
        dispatch(menuItemSelected(menuReference));
        localStorage.setItem("menuItem", JSON.stringify(menuReference));
    };
    const getContent = async () => {
        const slug = location.pathname.slice(
            location.pathname.lastIndexOf("/") + 1
        );
        const menuIdRes = await API.getMenuBySlug(slug);
        const { id, lang_id } = menuIdRes.data;

        API.getListMenuDetail(langId, id).then((res) =>
            setSteps(res.data.data.reverse())
        );
        setMenu(id, lang_id + "");

        return () => setCurrentStep(0);
    }
    useEffect(() => {
        getContent()
    }, [location]);

    useEffect(() => {
        API.getListMenuDetail(langId, menuItem.referenceId).then((res) =>
            setSteps(res.data.data.reverse())
        );
        return () => setCurrentStep(0);
    }, [langId]);
    return (
        <>
            {menuItem.images === "" ? (
                <></>
            ) : (
                <Row>
                    <Col span={24}>
                        <div className="instructor-image-wrapper">
                            <img
                                alt="instructor"
                                src={`${Constant.IMAGES_URL}${menuItem.images}`}
                            />
                        </div>
                    </Col>
                </Row>
            )}

            <Steps type={"navigation"} current={currentStep} onChange={onChange}>
                {steps.map((step) => {
                    return (
                        <Step
                            key={step.id}
                            icon={
                                <img
                                    alt="instructor"
                                    style={{ maxWidth: "100%" }}
                                    src={`${Constant.IMAGES_URL}${step.images}`}
                                />
                            }
                        ></Step>
                    );
                })}
            </Steps>
            {steps.map((step, index) => {
                if (index === currentStep)
                    return (
                        <Card
                            key={step.id}
                            bordered={false}
                            className="step-content-wrapper"
                            // title={step.title}
                        >
                            <div style={{ marginBottom: 10, fontSize: 18, fontWeight: 'bold', color: '#ffa401' }}>{step.title}</div>
                            {step.fulltext && Parser(step.fulltext)}
                        </Card>
                    );
                else return "";
            })}
        </>
    );
};
export default GuideContent;
