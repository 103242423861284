/* eslint-disable */

import React, { useEffect, useState } from "react";
import { Row, Col, Menu, Skeleton } from "antd";
import "./page-css/News.css";
import CustomNewsTabs from "../components/CustomNewsTabs";
import Banner from "../components/Banner";
import { useDispatch, useSelector } from "react-redux";
import * as Constant from "../config/Constant";
import * as API from "../config/apiConfig";
import { useHistory } from "react-router-dom";
import { menuItemSelected, showLangSwitch } from "../actions/menu";
const News = () => {
  const { menuItem, langId } = useSelector((state) => state.menu);
  const [menuItems, setMenuItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    dispatch(showLangSwitch(true));
  }, []);
  const scrollTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };
  const getMenuNews = () => {
    setLoading(true);
    API.getMenu(langId)
      .then((res) => {
        let data = [];
        res.data.data.forEach((dt) => {
          if (langId === "2") {
            if (dt.parent === 8) data.push(dt);
          } else {
            if (dt.parent === 64) data.push(dt);
          }
        });
        setMenuItems(data);
      })
      .catch((err) => console.log(err));
    setLoading(false);
  };
  const handleChange = (e) => {
    const menu = {
      key: e.key,
      title: e.item.props.title,
      banner: e.item.props.banner,
      menuId: e.item.props.menuid,
      menuParentId: e.item.props.menuparentid,
      menuParentTitle: e.item.props.menuparenttitle,
      images: e.item.props.images,
      referenceId: e.item.props.referenceid,
    };
    dispatch(menuItemSelected(menu));
    localStorage.setItem("menuItem", JSON.stringify(menu));

    scrollTop();
    history.push(
      langId === "2" ? `/kenh-thong-tin/${e.key}` : `/news/${e.key}`
    );
  };
  useEffect(() => {
    getMenuNews();
  }, [langId]);
  return (
    <Row className="news-wrapper">
      <Banner
        title={menuItem.menuParentTitle}
        className="banner-wrapper"
        cover={`${Constant.IMAGES_URL}${menuItem.banner}`}
      />
      <Skeleton loading={loading} active={loading}>
        {menuItems.length > 0 && (
          <>
            <Row style={{ width: "100%" }}>
              <Col offset={3} span={18}>
                <Menu
                  className="no-padding"
                  onClick={handleChange}
                  mode="horizontal"
                >
                  {menuItems.map((item) => {
                    return (
                      <Menu.Item
                        className="child-menu"
                        key={item.alias}
                        banner={menuItem.banner}
                        menuparenttitle={menuItem.menuParentTitle}
                        menuparentid={menuItem.menuParentId}
                        menuid={item.id}
                        images={item.images}
                        referenceid={item.reference_id}
                        title={item.name}
                      >
                        {item.name}
                      </Menu.Item>
                    );
                  })}
                </Menu>
              </Col>
            </Row>
            <Row className="news-title-wrapper">
              <Col className="news-title" span={"auto"}>
                <h2>{menuItem.title}</h2>
              </Col>
            </Row>

            <Row className="news-content-wrapper">
              <Col className="news-content" span={24}>
                <CustomNewsTabs loading={loading} setLoading={setLoading} />
              </Col>
            </Row>
          </>
        )}
      </Skeleton>
    </Row>
  );
};

export default News;
