import * as types from '../constant/actionTypes'

export const menuItemSelected = (menuItem) => {
    return {
        type: types.MENU_ITEM_SELECTED,
        payload: menuItem
    }
}
export const changeLang = (langId) => {
    return {
        type: types.CHANGE_LANG,
        payload: langId
    }
}
export const showLangSwitch = (isShow) => {
    return {
        type: types.SHOW_SWITCH,
        payload: isShow
    }
}
