import React, { useState } from "react";
import { notification, DatePicker, Modal, Button } from "antd";
import moment from "moment";
import { getImpCat } from "../../../utils/utils";

export const ModalDatePicker = ({ visible, setVisible, form, ata }) => {
  const [selectedDate, setSelectedDate] = useState();
  const [current, setCurrent] = useState();

  function disabledDate(current) {
    return (
      current < moment().startOf("day") /* || moment(current).day() === 0 */
    );
  }
  return (
    <Modal
      width={770}
      bodyStyle={{ height: 340 }}
      title="Date Picker"
      visible={visible}
      onCancel={() => setVisible(false)}
      footer={[<></>]}
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ width: "48%", marginRight: "2rem" }}>
          <DatePicker
            style={{ width: "100%" }}
            value={selectedDate}
            open
            defaultValue={form.getFieldValue("receiveDate")}
            dropdownClassName="hide-footer"
            disabledDate={disabledDate}
            showTime={{
              defaultValue:
                moment(current).format("DD") !== moment().format("DD")
                  ? moment().set("hour", 8).set("minute", 0)
                  : moment().add(1, "hour"),
            }}
            format="DD MMM YYYY HH:mm"
            onSelect={(val) => {
              setSelectedDate(moment(val));
              setCurrent(val);
            }}
          />
        </div>
        <div style={{ width: "40%" }}>
          <p style={{ textAlign: "justify" }}>
            &nbsp;&nbsp; Quý khách cần chú ý khi chọn các khung giờ phát sinh
            phụ phí (từ 17:00 đến 05:59 ngày thường hoặc vào các ngày Chủ Nhật,
            ngày Lễ)
          </p>
          <p style={{ textAlign: "justify" }}>Ghi chú:</p>
          <p style={{ textAlign: "justify" }}>
            - Bấm 2 lần vào ngày hôm nay để chọn nhanh giờ hiện tại (+ 1 giờ)
          </p>
          <p style={{ textAlign: "justify" }}>
            - Bấm 2 lần vào ngày khác để chọn nhanh giờ (8:00 AM)
          </p>
          <div>
            <Button
              style={{ marginRight: 5 }}
              onClick={() => setVisible(false)}
            >
              Close
            </Button>
            <Button
              onClick={() => {
                form.setFieldsValue({ receiveDate: selectedDate });
                setVisible(false);
                const impCat = getImpCat(ata, selectedDate);
                form.setFieldsValue({ impCat: impCat !== 5 ? impCat : "" });
                let message =
                  impCat !== 5
                    ? `Chọn ngày nhận thành công!\nGiá phục vụ sẽ được tính ở mức ${impCat}`
                    : "Chọn ngày nhận thành công!";
                notification.success({
                  message,
                  style: { marginRight: "-50%", whiteSpace: "pre-wrap" },
                });
              }}
              className="btn-payment"
            >
              Ok
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
