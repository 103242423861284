import React, { useEffect, useState } from 'react'
import { Form, Row, Col, InputNumber, Typography, Input, Checkbox } from 'antd'
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import styles from '../../../style.module.css'
import { objectToAntFormData } from '../../../../../../components/tracking/CertLivAnm/TabExpCertLivAnm';
import { setExpCarWgtToEdit, setExpCarDimToEdit } from '../../../../../../actions/esidExportAction';
import { CaclDimVolWgt } from '../../../../../../utils/utils';

export const WeightContainer = ({ form, isEsidExpFinalized }) => {
    const { t } = useTranslation("common");
    const dispatch = useDispatch();
    const esidSelected = useSelector((state) => state.esidExport.esidExpSelected);
    const expCarWgtToEdit = useSelector((state) => state.esidExport.expCarWgtToEdit);
    const expCarDimToEdit = useSelector((state) => state.esidExport.expCarDimToEdit);
    const [timer, setTimer] = useState(null);
    const [dimCalcMode, SetDimCalcMode] = useState(esidSelected?.shcCod001 === 'VKD' ? true : false);

    const handleFormChange = (value, allValues) => {
        clearTimeout(timer);
        const timeoutId = setTimeout(async () => {
            const wgtNet = allValues.volWgt > allValues.wgtGrs ? allValues.volWgt : allValues.wgtGrs
            form.setFields(objectToAntFormData({ wgtNet }));
            dispatch(setExpCarWgtToEdit({
                ...expCarWgtToEdit,
                ...allValues,
                wgtNet
            }));
        }, 500)
        setTimer(timeoutId);
    }

    useEffect(() => {
        if (esidSelected?.expCarWgt?.length > 0) {
            const expCarWgt = esidSelected?.expCarWgt[0];
            dispatch(setExpCarWgtToEdit({...expCarWgt, desRmk: esidSelected.desRmk}));
        } else {
            const carWgtData = {
                volWgt: 0,
                wgtGrs: 0,
                wgtNet: 0,
                desRmk: esidSelected?.desRmk,
                codAwbNum: esidSelected?.codAwbNum,
                codAwbPfx: esidSelected?.codAwbPfx,
                codAwbSfx: esidSelected?.codAwbSfx,
                codSts: 'NY'
            };
            dispatch(setExpCarWgtToEdit({
                ...carWgtData
            }));
        }
        // eslint-disable-next-line
    }, [esidSelected]);

    useEffect(() => {
        if (expCarWgtToEdit) {
            form.setFields(objectToAntFormData({
                volWgt: expCarWgtToEdit.volWgt,
                wgtGrs: expCarWgtToEdit.wgtGrs,
                wgtNet: expCarWgtToEdit.volWgt > expCarWgtToEdit.wgtGrs ? expCarWgtToEdit.volWgt : expCarWgtToEdit.wgtGrs,
                desRmk: expCarWgtToEdit.desRmk
            }));
        } else {
            const carWgtData = {
                volWgt: 0,
                wgtGrs: 0,
                wgtNet: 0,
                desRmk: esidSelected?.desRmk
            };
            form.setFields(objectToAntFormData(carWgtData));
        }
        // eslint-disable-next-line
    }, [expCarWgtToEdit])

    const onChangeVolWgtMode = async (mode) => {
        localStorage.setItem('calcDimMode', mode);
        SetDimCalcMode(mode === 'VKD');
        const dimList = JSON.parse(JSON.stringify(expCarDimToEdit));
        for (let index = 0; index < dimList.length; index++) {
            const dim = dimList[index];
            const volWgt = CaclDimVolWgt(
                dim.qtyDimPcs,
                dim.qtyDimLen,
                dim.qtyDimWid,
                dim.qtyDimHgt,
                mode === 'VKD' ? 1 : 0
            );
            dim.qtyDimWgt = volWgt;
        }   
        dispatch(setExpCarDimToEdit(dimList));
        // tinh tổng trọng lượng theo thể tích
        const volWgtTotal = dimList?.filter(x => x.codSts !== 'XX')?.length > 0 ?
            dimList?.filter(x => x.codSts !== 'XX')?.map(x => x.qtyDimWgt)?.reduce((a, b) => a + b) : 0;
        dispatch(setExpCarWgtToEdit({
            ...expCarWgtToEdit, 
            volWgt: volWgtTotal,
            wgtNet: volWgtTotal > expCarWgtToEdit?.wgtNet ? volWgtTotal : expCarWgtToEdit?.wgtNet
        }));
    }

    return (
        <Form
            name={'addWeightForm'}
            labelCol={{ span: 24, offset: 0 }}
            labelAlign={'left'}
            wrapperCol={{ span: 24 }}
            autoComplete="off"
            form={form}
            className={`${styles.personalForm}`}
            onValuesChange={handleFormChange}
            defaultValue={{
                wgtGrs: 0
            }}
        >
            <Row gutter={10}>
                <Col xs={24}>
                    <Checkbox style={{ color: '#ffa401', fontWeight: 600, marginRight: 10 }} 
                        checked={dimCalcMode} onClick={() => onChangeVolWgtMode('VKD')}>
                        {'Thể tích làm tròn số lẻ bằng 0.5 nếu < 0.5 và bằng 1 nếu >= 0.5'}
                    </Checkbox>
                    <Checkbox style={{ color: 'tomato', fontWeight: 600 }} checked={!dimCalcMode}
                        onClick={() => onChangeVolWgtMode('RN0')}>
                        {'Thể tích làm tròn số lẻ bằng 0 nếu < 0.5 và bằng 1 nếu >= 0.5'}
                    </Checkbox>
                </Col>
                <Col md={8} sm={24} xs={24}>
                    <Form.Item
                        name="volWgt"
                        label={<Typography.Text strong>{t("admin.acceptance.vlmWeight")}</Typography.Text>}
                        style={{marginBottom: '0 !important'}}
                        labelCol={{span: 24}}
                        rules={[
                            {
                                required: true,
                                message: t("admin.validation.required"),
                            },
                        ]}
                    >
                        <InputNumber readOnly={isEsidExpFinalized} style={{width: '100%', height: 32}} min={0} placeholder={'kg'} name="volWgt" />
                    </Form.Item>
                </Col>
                <Col md={8} sm={24} xs={24}>
                    <Form.Item
                        name="wgtGrs"
                        label={<Typography.Text strong>{t("admin.acceptance.grsWeight")}</Typography.Text>}
                        style={{marginBottom: '0 !important'}}
                        labelCol={{span: 24}}
                        rules={[
                            {
                                required: true,
                                message: t("admin.validation.required"),
                            },
                        ]}
                    >
                        <InputNumber readOnly={isEsidExpFinalized} style={{width: '100%', height: 32}} min={0} placeholder={'kg'} />
                    </Form.Item>
                </Col>
                <Col md={8} sm={24} xs={24}>
                    <Form.Item
                        name="wgtNet"
                        label={<Typography.Text strong>{t("admin.acceptance.chrgWeight")}</Typography.Text>}
                        style={{marginBottom: '0 !important'}}
                        labelCol={{span: 24}}
                        rules={[
                            {
                                required: true,
                                message: t("admin.validation.required"),
                            },
                        ]}
                    >
                        <InputNumber readOnly style={{width: '100%', height: 32}} min={0} placeholder={'kg'} />
                    </Form.Item>
                </Col>
                <Col span={24} style={{ marginTop: '15px' }}>
                    {/* <Form onFinish={handleDgCheck} initialValues={{ dgChk: true }}>
                        <Form.Item noStyle>
                            <Form.Item name="dgChk" valuePropName="checked" noStyle>
                                <Checkbox>DG Check</Checkbox>
                            </Form.Item>

                            <Button type="primary" onClick={handleDgCheck}>
                                {t("admin.acceptance.needDgChk")}
                            </Button>
                        </Form.Item>
                    </Form> */}
                    <Form.Item
                        name="desRmk"
                        label={<Typography.Text strong>{t("admin.acceptance.desRmk")}</Typography.Text>}
                        style={{marginBottom: '0 !important'}}
                        labelCol={{span: 24}}
                    >
                        <Input.TextArea autoSize readOnly={isEsidExpFinalized} style={{width: '100%'}} />
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    )
}
