/* eslint-disable */
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  message,
  notification,
  Tooltip,
} from "antd";
import React, { useEffect, useState } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import Banner from "../components/Banner";
import "./page-css/AwbLogin.css";
import { userAwbLoggin } from "../actions/userAWB";
import { useDispatch } from "react-redux";
import * as API from "../config/apiConfig";
// import { showLangSwitch } from "../actions/menu";
import { useTranslation } from "react-i18next";
import { SyncOutlined } from "@ant-design/icons";

const AwbLogin = () => {
  let { type } = useParams();
  const history = useHistory();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation("common");
  const [captcha, setCaptcha] = useState({});
  const [form] = Form.useForm();

  const refreshCaptchaCode = async () => {
    const captchatRes = await API.getCaptcha();
    if (captchatRes.data) {
      setCaptcha(captchatRes.data);
    }
  };

  const dispatch = useDispatch();
  const layout = {
    labelCol: { span: 12 },
    wrapperCol: { span: 12 },
  };

  useEffect(() => {
    // dispatch(showLangSwitch(true));
    refreshCaptchaCode();
  }, []);

  const onSubmitLogin = async (values) => {
    const { username, password } = values;
    setLoading(true);
    if (username.startsWith("TCS")) {
      notification.warning({
        message: "Đây là tài khoản khách hàng vui lòng đăng nhập Checkin!",
        style: { marginRight: "-50%" },
      });
      return;
    }
    API.login(username, password, captcha.id, values.captchaCode)
      .then((res) => {
        if (res.data.data) {
          notification.success({
            message:
              "Đăng nhập thành công! Phiên đăng nhập sẽ hết hạn sau 90 phút!",
            style: { marginRight: "-50%" },
          });
          dispatch(userAwbLoggin(res.data));
          localStorage.setItem("userAWB", JSON.stringify(res.data));
          localStorage.removeItem("userGuest");
          const user = res.data;

          const { from } = location.state || { from: { pathname: "/" } }; // LocationState
          if (user && user.data && from && from.pathname !== "/") {
            history.replace(
              from && from.pathname === "/login"
                ? { from: { pathname: "/" } }
                : from
            );
          } else if (user.data.user_code === "admi" && type !== undefined) {
            history.push(`/Awb/${type}`);
          } else if (user.data.user_code.slice(0, 2) === "90") {
            history.push(`/Awb/Airlines`);
          } else if (
            user.data.user_code === "admi" ||
            user.data.user_code === "t888"
          ) {
            history.push(`/statistic`);
          } else if (user.data.user_code === "ad") {
            history.push("/awb/samsung");
          } else history.push(`/Awb/Agent`);
        }
      })
      .catch((error) => {
        let mess = error.response.data.err;
        if (
          error.response.status === 403 &&
          error.response.data === "invalid captcha"
        ) {
          mess = "Mã xác thực không đúng.";
        }
        if (
          error.response.status === 403 &&
          error.response.data === "expired captcha"
        ) {
          mess = "Mã xác thực đã quá hạn.";
        }
        form.setFieldsValue({ captchaCode: "" });
        refreshCaptchaCode();
        notification.error({
          message: mess,
          style: { marginRight: "-50%" },
        });
      })
      .finally(() => setLoading(false));
  };
  return (
    <Row className="login-wrapper">
      <Banner
        title={"LOGIN"}
        className="banner-wrapper"
        cover={"../../img/004.jpg"}
      />
      <Row className="login-form-title-wrapper">
        <Col className="login-form-title" offset={4}>
          <h2>{t("header.description")}</h2>
        </Col>
      </Row>
      <Row className="login-form-wrapper">
        <Col offset={4} span={12} className="form-wrapper">
          <Form
            className="login-form"
            {...layout}
            name="basic"
            onFinish={onSubmitLogin}
            form={form}
            autoComplete="off"
          >
            <Form.Item
              label={t("header.username")}
              name="username"
              rules={[
                { required: true, message: "Vui lòng nhập tên đăng nhập!" },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={t("header.password")}
              name="password"
              rules={[{ required: true, message: "Vui lòng nhập mật khẩu!" }]}
            >
              <Input.Password style={{ background: "#E8F0FE" }} />
            </Form.Item>
            <Form.Item label={t("home.captcha")} style={{ marginBottom: 0 }}>
              <Form.Item
                name="captchaCode"
                rules={[{ required: true, message: t("home.required") }]}
                style={{ display: "inline-block", width: "calc(100% - 140px)" }}
              >
                <Input />
              </Form.Item>
              <Form.Item style={{ display: "inline-block", margin: "0 2px" }}>
                <img
                  src={captcha.url}
                  style={{ backgroundColor: "whitesmoke", padding: "3px" }}
                />
              </Form.Item>
              <Form.Item style={{ display: "inline-block", margin: "0 2px" }}>
                <Tooltip title={t("home.refreshCaptcha")}>
                  <SyncOutlined
                    style={{
                      cursor: "pointer",
                      fontSize: 18,
                      marginLeft: "3px",
                      marginTop: "3px",
                    }}
                    onClick={refreshCaptchaCode}
                  />
                </Tooltip>
              </Form.Item>
            </Form.Item>
            <Row>
              <Col
                style={{ display: "flex", justifyContent: "center" }}
                span={24}
              >
                <Form.Item>
                  <Button
                    loading={loading}
                    style={{ width: "fit-content" }}
                    htmlType="submit"
                  >
                    {t("header.login")}
                  </Button>
                </Form.Item>
                <Form.Item>
                  <Button
                    style={{ width: "fit-content" }}
                    onClick={() => history.push("/awb/checkoutLogin")}
                  >
                    Checkin
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </Row>
  );
};

export default AwbLogin;
