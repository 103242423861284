/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Button, notification, Table } from "antd";
import Modal from "antd/lib/modal/Modal";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { setPaymentInfo } from "../../../actions/payment";
import { checkinUser, userAwbLogout } from "../../../actions/userAWB";
import { useHistory } from "react-router";
import {
  IMP_WEB_CHK_LOAD,
  IMP_WEB_CHK_CRE_HAWB_FEE_OBJ,
  IMP_WEB_CHK_LOAD_NY_HAWB_FEE_LST,
} from "../../../config/apiConfig";
const ModalHawbList = ({
  visible,
  setVisible,
  info,
  loadData,
  setRowBillNo,
}) => {
  const [visibleConfirm, setVisibleConfirm] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const { token } = useSelector((state) => state.userAwb.dataLogin);
  const history = useHistory();
  const dispatch = useDispatch();
  const [dataSource, setDataSource] = useState([]);
  const { checkinHAWB } = useSelector((state) => state.mawb);
  const [loading, setLoading] = useState(false);

  const handleOk = async () => {
    try {
      if (selectedRows.length > 0) {
        setLoading(true);
        try {
          const loadRes = await IMP_WEB_CHK_LOAD(
            {
              COD_AWB_PFX: checkinHAWB.COD_AWB_PFX,
              COD_AWB_NUM: checkinHAWB.COD_AWB_NUM,
              COD_AWB_SFX: checkinHAWB.COD_AWB_SFX,
              COD_HWB_NUM: checkinHAWB.COD_HWB_NUM,
            },
            token
          );
          if (loadRes.data.mCol[0] && loadRes.data.mCol[0].COD_STS === "AQ") {
            let selectedHAWB = ``;
            selectedRows.map((row) => {
              selectedHAWB += row.COD_HWB_NUM + "#";
            });
            const createFeeRes = await IMP_WEB_CHK_CRE_HAWB_FEE_OBJ(
              {
                ...loadRes.data.mCol[0],
                COD_HWB_NUM: selectedHAWB,
              },
              token
            );

            setRowBillNo(
              createFeeRes.data.P_RET_IMP_WEB_BIL_CHG_COLL.mCol[0].COD_BIL
            );
          } else {
            notification.error({
              style: { marginRight: "-50%" },
              message: "Lỗi!",
            });
            setLoading(false);
          }
          fetchList();
          loadData();
          if (["FN", "OK", "XX"].includes(info.COD_STS)) {
            notification.success({
              style: { marginRight: "-50%" },
              message: "Đã hoàn tất làm thủ tục cho lô hàng này!",
            });
          } else {
            notification.error({
              style: { marginRight: "-50%" },
              message: "Không thể tính phí!",
            });
          }
        } catch (error) {
          if (err.response.status === 401) {
            notification.error({
              style: { marginRight: "-50%" },
              message: t("home.sessionNoti"),
            });

            dispatch(checkinUser({}));
            dispatch(setPaymentInfo(undefined));
            dispatch(userAwbLogout());
            localStorage.removeItem("userAWB");
            localStorage.removeItem("checkin");
            localStorage.removeItem("pInfo");
            history.push("/");
          }
        }
      } else {
        setVisibleConfirm(false);
        notification.warning({
          style: { marginRight: "-50%" },
          message: "Vui lòng chọn ít nhất 1 HAWB!",
        });
      }
      setLoading(false);
    } catch (error) {
      // console.log(error);
    }
  };

  const columns = [
    {
      title: "AWB",
      dataIndex: "COD_AWB_PFX",
      key: "COD_AWB_PFX",
      colSpan: 2,
    },
    {
      title: "",
      dataIndex: "COD_AWB_NUM",
      key: "COD_AWB_NUM",
      colSpan: 0,
    },
    {
      title: "HAWB",
      dataIndex: "COD_HWB_NUM",
      key: "COD_HWB_NUM",
    },

    {
      title: "PCS",
      dataIndex: "QTY_HWB_PCS",
      key: "QTY_HWB_PCS",
    },
    {
      title: "Weight",
      dataIndex: "QTY_HWB_WGT",
      key: "QTY_HWB_WGT",
    },
    {
      title: "Flight No",
      dataIndex: "COD_FLT_CAR",
      key: "COD_FLT_CAR",
      colSpan: 2,
    },
    {
      title: "",
      dataIndex: "COD_FLT_NUM",
      key: "COD_FLT_NUM",
      colSpan: 0,
    },
    {
      title: "Flight Date",
      dataIndex: "DAT_FLT_ATA",
      key: "DAT_FLT_ATA",
    },
  ];

  const fetchList = async () => {
    setLoading(true);
    try {
      const loadRes = await IMP_WEB_CHK_LOAD_NY_HAWB_FEE_LST(info, token);

      let data = [];
      loadRes.data.mCol.map((dt, index) => {
        data.push({
          key: index,
          COD_AWB_PFX: dt.COD_AWB_PFX,
          COD_AWB_NUM: dt.COD_AWB_NUM,
          COD_HWB_NUM: dt.COD_HWB_NUM,
          QTY_HWB_PCS: dt.QTY_HWB_PCS,
          QTY_HWB_WGT: dt.QTY_HWB_WGT,
          COD_FLT_CAR: dt.COD_FLT_CAR,
          COD_FLT_NUM: dt.COD_FLT_NUM,
          DAT_FLT_ATA: moment(dt.DAT_FLT_ATA).format("LL HH:mm"),
        });
      });
      setDataSource(data);
      setLoading(false);
    } catch (error) {
      if (err.response.status === 401) {
        notification.error({
          style: { marginRight: "-50%" },
          message: t("home.sessionNoti"),
        });

        dispatch(checkinUser({}));
        dispatch(setPaymentInfo(undefined));
        dispatch(userAwbLogout());
        localStorage.removeItem("userAWB");
        localStorage.removeItem("checkin");
        localStorage.removeItem("pInfo");
        history.push("/");
      }
    }
  };
  useEffect(() => {
    if (visible === true) fetchList();
  }, [visible]);
  const onSelectChange = (selectedRowKeys, selectedRows) => {
    setSelectedRowKeys(selectedRowKeys);
    setSelectedRows(selectedRows);
  };

  return (
    <Modal
      width={"100%"}
      title="HAWB Check-in List"
      visible={visible}
      onCancel={() => {
        setSelectedRowKeys([]);
        setSelectedRows([]);
        setVisible(false);
      }}
      footer={[
        <Button
          className="btn-payment"
          onClick={() => {
            setSelectedRowKeys([]);
            setSelectedRows([]);
            setVisible(false);
          }}
          style={{ width: "fit-content", marginLeft: "5px" }}
        >
          Cancel
        </Button>,
        <Button
          className="btn-payment"
          onClick={() => setVisibleConfirm(true)}
          style={{ width: "fit-content" }}
        >
          Ok
        </Button>,
      ]}
    >
      <Table
        bordered
        loading={loading}
        className="table-payment"
        columns={columns}
        pagination={false}
        rowSelection={{
          selectedRowKeys,
          fixed: true,
          onChange: onSelectChange,
        }}
        dataSource={dataSource}
      />
      <Modal
        centered
        width={"620px"}
        title={"Thông báo"}
        visible={visibleConfirm}
        onCancel={() => setVisibleConfirm(false)}
        footer={[
          <Button onClick={() => setVisibleConfirm(false)}>Không</Button>,
          <Button onClick={handleOk} loading={loading} type="primary">
            Đồng ý
          </Button>,
        ]}
      >
        Bạn có đồng ý tính tiền phục vụ HAWB cho các lô hàng đã chọn không?
      </Modal>
    </Modal>
  );
};
export default ModalHawbList;
