import React from 'react';
import { Col, Table, Row, Skeleton } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { setMAWB } from "../actions/MAWB";

const ShipmentInfo = (props) => {
  const dispatch = useDispatch();
  const { MAWB, checkinHAWB } = useSelector((state) => state.mawb);
  const rowSelection = {
    type: "radio",
    fixed: true,
    hideSelectAll: true,
    onChange: (selectedRowKeys, selectedRows) => {
      if (selectedRows.length > 0) {
        props.setIsRowSelected(true);
        dispatch(
          setMAWB({
            ...MAWB,
            suffix:
              selectedRows[0].ES03_COD_AWB_SFX !== undefined
                ? selectedRows[0].ES03_COD_AWB_SFX
                : selectedRows[0].S03_COD_AWB_SFX !== undefined
                ? selectedRows[0].S03_COD_AWB_SFX
                : selectedRows[0].H03_COD_AWB_SFX,
            hawb: selectedRows[0].H03_COD_AWB_SFX ? checkinHAWB : "",
          })
        );
        const localMAWB = JSON.stringify({
          ...MAWB,
          suffix:
            selectedRows[0].ES03_COD_AWB_SFX !== undefined
              ? selectedRows[0].ES03_COD_AWB_SFX
              : selectedRows[0].S03_COD_AWB_SFX !== undefined
              ? selectedRows[0].S03_COD_AWB_SFX
              : selectedRows[0].H03_COD_AWB_SFX,
          hawb: selectedRows[0].H03_COD_AWB_SFX ? checkinHAWB : "",
        });
        localStorage.setItem("MAWB", localMAWB);
      }
      if (selectedRows.length === 0) props.setIsRowSelected(false);
    },
  };
  return (
    <>
      <Row className="result-title-wrapper">
        <Col span={24} className="awb-result-title">
          <h5>{props.titleShipmentInfo}</h5>
        </Col>
      </Row>

      <Row className="result-content-wrapper">
        <Col className="awb-result-content" span={24}>
          <Skeleton loading={props.loading}>
            <Table
              className="table-shipment-info"
              dataSource={props.dataShipmentInfo}
              columns={props.columnsTableShipment}
              bordered
              rowSelection={{ ...rowSelection }}
              // scroll={{ x: 1300 }}
              pagination={false}
            />
          </Skeleton>
        </Col>
      </Row>
    </>
  );
};
export default ShipmentInfo;
