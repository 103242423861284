import React from "react";
import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import RoleClaim from "../constant/roleClaim";
import { NotAllowed } from "./NotAllowed";

export const GuardedRoute = ({
  children,
  loginPath = "/nvesidLogin",
  allowedRoles = [RoleClaim.EsidAdmin, RoleClaim.EsidDocStaff, RoleClaim.EsidDgStaff, 
    RoleClaim.EsidACPT, RoleClaim.EsidAccountant, RoleClaim.EsidTecsAgent],
  ...rest
}) => {
  const { signedIn, currentUser } = useSelector((state) => state.userAwb);
  const { roles } = currentUser;
  const userRoles =
    allowedRoles && roles ? allowedRoles.filter((r) => roles.includes(r)) : [];

  const rolesUser = [RoleClaim.Admin, RoleClaim.Agent, RoleClaim.CheckInOnl, RoleClaim.BusinessAnalyst, RoleClaim.Airlines];
  const isUser = roles ? rolesUser.filter((r) => roles.includes(r)) : [];

  return (
    <Route
      {...rest}
      render={({ location }) => {
        return signedIn ? (
          allowedRoles ? (
            userRoles.length > 0 ? (
              children
            ) : (
              isUser.length > 0 ? <Redirect to='/' /> : <NotAllowed />
            )
          ) : (
            children
          )
        ) : (
          <Redirect
            to={{
              pathname: loginPath,
              state: { from: location },
            }}
          />
        );
      }}
    />
  );
};
