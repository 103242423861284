import React, { useEffect, useState } from "react";
import { Tabs, Button, notification } from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import * as API from "../config/apiConfig";
import { userAwbLogout } from "../actions/userAWB";
import Banner from "../components/Banner";
import { setMAWB } from "../actions/MAWB";
import { errorHandling } from "../config/AuthIntercept";
import { EsidInvoiceInfo, EsidReceiverInfo, EsidAwbInfo, EsidPaymentInfo } from "../components/EsidCheckout";
import { EXP_WEB_CHK_PAID_CDT_INVOICE, EsidCheckLoad, EsidIssueEInvoice, EsidLoadBilCharge, EsidPaidAllInv } from "../config/esidExportApi";
import queryString from "query-string";
import { checkPaymentSuccess, getMerchantDataV2, getOrderObj } from "../config/checkinApi";
import moment from "moment";
import { setEsidExpSelected } from "../actions/esidExportAction";

const { TabPane } = Tabs;
export const EsidCheckout = () => {
  const { t } = useTranslation("common");
  const token = useSelector((state) => state.userAwb.token) || '';
  const history = useHistory();
  const location = useLocation()
  const dispatch = useDispatch();
  const [activeKey, setActiveKey] = useState("invoice-info");
  const [invoices, setInvoices] = useState();
  const [invoicesDetail, setInvoicesDetail] = useState();
  const [isFetchData, setIsFetchData] = useState(false);
  const [chkLoadData, setChkLoadData] = useState();
  const pInfo = JSON.parse(localStorage.getItem("pInfo"));
  const selectEsidExport = useSelector(state => state.esidExport.esidExpSelected);
  const bilChg = useSelector(state => state.esidExport.bilChg);
  const params = queryString.parse(location.search);

  const fetchData = async () => {
    if (!selectEsidExport) {
      return;
    }
    try {
      setIsFetchData(true)
      if (localStorage.getItem('esidExp')) {
        setActiveKey("eInvoice-info");
      }
      let chkLoadRes = await EsidCheckLoad({
        TABLE_ID: bilChg?.TABLE_ID || localStorage.getItem('chk_id'),
        COD_AWB_PFX: selectEsidExport.codAwbPfx,
        COD_AWB_NUM: selectEsidExport.codAwbNum,
        COD_AWB_SFX: selectEsidExport.codAwbSfx,
        COD_HWB_NUM: selectEsidExport.codHwb ?? '',
      });
      let chkResult = chkLoadRes?.data?.mCol.length > 0 ? chkLoadRes.data.mCol[0] : null;
      if (chkResult?.AMT_CSH_INV === 0 && 
        ['AC', 'AM', 'AP'].includes(chkResult?.COD_STS) &
        chkResult?.AMT_CDT_INV + chkResult?.AMT_PRO_INV > 0) {
        await EXP_WEB_CHK_PAID_CDT_INVOICE(
          {
            ...chkResult,
          },
          token
        );
        chkLoadRes = await EsidCheckLoad({
          TABLE_ID: bilChg?.TABLE_ID || localStorage.getItem('chk_id'),
          COD_AWB_PFX: selectEsidExport.codAwbPfx,
          COD_AWB_NUM: selectEsidExport.codAwbNum,
          COD_AWB_SFX: selectEsidExport.codAwbSfx,
          COD_HWB_NUM: selectEsidExport.codHwb ?? '',
        });
        chkResult = chkLoadRes?.data?.mCol.length > 0 ? chkLoadRes.data.mCol[0] : null;
      }
      //
      const isChkNapas = params?.napasResult;
      await checkNapasResult(chkLoadRes);
      // lay lai du lieu sau khi thanh toan xong
      if (isChkNapas) {
        chkLoadRes = await EsidCheckLoad(
          {
            TABLE_ID: bilChg?.TABLE_ID || localStorage.getItem('chk_id'),
            COD_AWB_PFX: selectEsidExport.codAwbPfx,
            COD_AWB_NUM: selectEsidExport.codAwbNum,
            COD_AWB_SFX: selectEsidExport.codAwbSfx,
            COD_HWB_NUM: selectEsidExport.codHwb ?? '',
          },
          token
        );
        localStorage.removeItem('chk_id');
      }
      // phat hanh hoa don
      chkResult = chkLoadRes?.data?.mCol.length > 0 ? chkLoadRes.data.mCol[0] : null;
      if (['AP'].includes(chkResult?.COD_STS) && chkResult?.AMT_CSH_INV > 0 && chkResult?.AMT_CDT_INV + chkResult?.AMT_PRO_INV === 0) {
        try {
          await EsidIssueEInvoice(chkResult);
        } catch (error) {
          console.log('Loi phat hanh hddt: ', error);          
        }
      }
      //
      if (chkLoadRes.data) {
        setChkLoadData(chkLoadRes.data.mCol[0])
        if ( chkResult.COD_STS !== 'NY' ) {
          console.log(`${selectEsidExport.codAwbPfx}-${selectEsidExport.codAwbNum}`,'STS: ', chkResult.COD_STS);
          const billChrRes = await EsidLoadBilCharge(chkLoadRes.data.mCol[0]);
          if (billChrRes.data) {
            setInvoices(billChrRes.data.P_RET_BIL_CHG_COLL.mCol);
            setInvoicesDetail(billChrRes.data.P_RET_EXP_WEB_BIL_CHG_COLL.mCol);
          }
        }
        dispatch(setEsidExpSelected({
          ...selectEsidExport,
          codSts: chkResult.COD_STS || selectEsidExport.codSts
        }));
      }
      setIsFetchData(false);
    } catch (error) {
      console.log(error);
      setIsFetchData(false);
    }
  }
  const checkNapasResult = async (loadRes) => {
      if (Object.keys(params).length !== 0) {
        const dataDecoded = await getMerchantDataV2(params.napasResult, token);
        localStorage.removeItem('esidExp');
        if (dataDecoded.data.paymentResult.result === "SUCCESS") {
          const isSuccessRes = await checkPaymentSuccess(
            dataDecoded.data.paymentResult.order.id,
            token
          );
          const { transaction, order } = dataDecoded.data.paymentResult;
          const orderObjRes = await getOrderObj(order.id, token);
          if (orderObjRes.data) {
            if (
              isSuccessRes.data.status === true &&
              parseInt(orderObjRes.data.tableID.replaceAll('TCSEXP_', 0)) ===
                loadRes.data.mCol[0].TABLE_ID &&
              orderObjRes.data.orderID ===
                loadRes.data.mCol[0].RCV_BNK_TRS_CD1 &&
              parseFloat(orderObjRes.data.amount) === loadRes.data.mCol[0].AMT_CSH_INV 
              // &&
              // orderObjRes.data.orderReference ===
                // loadRes.data.mCol[0].SND_PAY_STR
            ) {
              await EsidPaidAllInv(
                {
                  ...loadRes.data.mCol[0],
                  RCV_DAT:
                    transaction.acquirer.date ??
                    moment().format("YYYY/MM/DD HH:mm:ss"),
                  RCV_BNK_AMT: transaction.amount,
                  RCV_BNK_TRS_COD: transaction.acquirer.transactionId,
                  COD_CUR: transaction.currency,
                  RCV_BNK_STR: `${
                    transaction.acquirer.date ??
                    moment().format("YYYY/MM/DD HH:mm:ss")
                  }#${transaction.acquirer.id ?? ""}#${
                    transaction.acquirer.transactionId ?? ""
                  }#${transaction.amount}#${transaction.currency}#${
                    transaction.id
                  }#${transaction.type}#`,
                  /* SND_PAY_STR: `${order.amount}#${order.creationTime}#${order.currency}#${order.id}#`, */
                  SND_PAY_DAT: order.creationTime,
                  BNK_COD: `${order.id}#${pInfo.order.id}#`,
                  RCV_BNK_TRS_CD1: loadRes.data.mCol[0].RCV_BNK_TRS_CD1
                    ? loadRes.data.mCol[0].RCV_BNK_TRS_CD1
                    : orderObjRes.data.orderID,
                },
                token
              );
              notification.success({
                message:
                  /* dataDecoded.data.paymentResult.response.message */ "Đã hoàn tất làm thủ tục cho lô hàng này!",
              });
              setTimeout(() => history.push("/Esid/Checkout"), 1000);
            } else {
              notification.error({
                message:
                  "Không nhận được thông tin phản hồi từ ngân hàng, hãy kiểm tra lại thông tin về giao dịch trên tài khoản của bạn và liên hệ với bộ phận Tin học của công ty TCS nếu cần.",
              });
              setTimeout(() => history.push("/Esid/Checkout"), 1000);
            }
          } else {
            notification.error({
              message:
                "Không nhận được thông tin phản hồi từ ngân hàng, hãy kiểm tra lại thông tin về giao dịch trên tài khoản của bạn và liên hệ với bộ phận Tin học của công ty TCS nếu cần.",
            });
            setTimeout(() => history.push("/Esid/Checkout"), 1000);
          }
        } else {
          notification.error({
            message:
              "Không nhận được thông tin phản hồi từ ngân hàng, hãy kiểm tra lại thông tin về giao dịch trên tài khoản của bạn và liên hệ với bộ phận Tin học của công ty TCS nếu cần.",
          });
          setTimeout(() => history.push("/Esid/Checkout"), 1000);
        }
      }
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  },[])

  useEffect(() => {
    if (!selectEsidExport) {
      history.push("/Esid/Export");
    }
    // eslint-disable-next-line
  }, [selectEsidExport])

  const handleLogoutCheckin = async () => {
    try {
      const token = JSON.parse(localStorage.getItem("userAWB"));
      if (token) {
        const res = await API.logout(token);
        dispatch(userAwbLogout());
        dispatch(setMAWB({}));
        localStorage.removeItem("userAWB");
        localStorage.removeItem("MAWB");
        notification.success({
          message: t("notification"),
          description: t(res.data.message),
        });
      }
      history.push("/");
    } catch (error) {
      errorHandling(error, history, "/");
    }
  };

  const backToEsis = () => {
    history.push("/Esid/Export");
  };

  const operations = (
    <>
      <Button
        className="exit-btn"
        style={{ width: "fit-content" }}
        onClick={backToEsis}
      >
        {t('esid.backToEsid')}
      </Button>
      <Button
        className="exit-btn"
        onClick={() => handleLogoutCheckin()}
        style={{ width: "fit-content" }}
      >
        {t("header.logOut")}
      </Button>
    </>
  );

  return (
    <>
      <Banner
        title={""}
        className="banner-wrapper"
        cover={"../../img/004.jpg"}
      />
      <div className="checkout-content-wrapper">
        <div className="checkout-content">
          <Tabs
            className="esid-tab-checkout"
            activeKey={activeKey}
            onChange={(key) => setActiveKey(key)}
            defaultActiveKey={"receiver-info"}
            size={"default"}
            tabBarExtraContent={operations}
          >
            <TabPane tab={t("esid.checkoutTabs.receiverInfo")} key={"receiver-info"}>
              <EsidReceiverInfo handleNextTab={() => setActiveKey("awb")} />
            </TabPane>
            <TabPane tab={"AWB"} key={"awb"}>
              <EsidAwbInfo handleNextTab={() => setActiveKey("invoice-info")} handlePrevTab={() => setActiveKey("receiver-info")}/>
            </TabPane>
            <TabPane tab={t("esid.checkoutTabs.invoiceInfo")} key={"invoice-info"}>
              <EsidInvoiceInfo 
                handleNextTab={() => setActiveKey("eInvoice-info")} 
                handlePrevTab={() => setActiveKey("awb")}
                invoices={invoices}
                invoicesDetail={invoicesDetail}
                chkLoadData={chkLoadData}
                isFetchData={isFetchData}
                fetchData={fetchData}
              />
            </TabPane>
            <TabPane tab={t("esid.checkoutTabs.paymentInfo")} key={"eInvoice-info"}>
              <EsidPaymentInfo 
                handlePrevTab={() => setActiveKey("invoice-info")}
                invoicesDetail={invoicesDetail}
                isFetchData={isFetchData}
                chkLoadData={chkLoadData}
                fetchData={fetchData}
              />
            </TabPane>
          </Tabs>
        </div>
      </div>
    </>
  );
};
