import {
    Button,
    Modal,
    Table,
    notification,
  } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import SearchForm from "../ExpList/components/SearchForm";
import { useHistory, useLocation } from 'react-router-dom';
import { GetAllEsidExpByCondition } from "../../../config/esidExportApi";
import { errorHandling } from "../../../config/AuthIntercept";
import { useSelector } from "react-redux";

export const EsidListModal = ({ visible, setVisible, setEsidList }) => {
    // eslint-disable-next-line
    const [visibleConfirm, setVisibleConfirm] = useState(false);
    const [dataSource, setDataSource] = useState([]);
    const { t } = useTranslation("common");
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const vhcRegSelected = useSelector((state) => state.esidExport.vhcRegSelected);
    const dateFormat = "DD-MM-YYYY HH:mm";
    const history = useHistory();
    const location = useLocation();

    const columns = [
        {
            title: 'AWB/ESID',
            dataIndex: 'awbPfxAndNum',
        },
        {
            title: t("registerCar.table.flightNo"),
            dataIndex: 'fltCarAndNum',
        },
        {
            title: t("registerCar.table.flightDate"),
            dataIndex: 'datFltOrigin',
        },
        {
            title: t("registerCar.table.esid"),
            dataIndex: 'esidId',
        },
        {
            title: t("esidExpList.table.status"),
            dataIndex: 'codSts',
            render: (_, record) => (t(`esid.codSts.${record.codSts}`))
        },
    ];
    
    const fetchEsidList = async () => {
        const initialParamsValue = {
            codSts: 'NY',
            fromDate: new Date(moment().startOf('month')).toISOString(),
            toDate: new Date(moment()).toISOString(),
        }
        try {
            const { data } = await GetAllEsidExpByCondition(initialParamsValue);
            const newDataSource = data.sort((a, b) => {
                return new Date(a.datFltOri) - new Date(b.datFltOri);
            }).map((item) => ({
                ...item,
                awbPfxAndNum: `${item.codAwbPfx}${item.codAwbNum}`,
                fltCarAndNum: `${item.codFltCar}${item.codFltNum}`,
                datFltOrigin: moment(new Date(item.datFltOri)).format(dateFormat),
                esidId: item.id,
                key: item.id
            }));
            setDataSource(newDataSource);
        } catch (error) {
            errorHandling(error, history, location.pathname);
        }
    }
    
    useEffect(() => {
        fetchEsidList();
        // eslint-disable-next-line
    },[vhcRegSelected])

    const clearSelected = () => {
        setSelectedRowKeys([]);
        setSelectedRows([]);
    }
    const handleOk = () => {
          if (selectedRows && selectedRows.length > 0) {
            setEsidList(selectedRows);
            // console.log(selectedRows);
            setVisibleConfirm(false);
            setTimeout(() => setVisible(false), 200);
            clearSelected();
          } else {
          notification.error({
            style: { marginRight: "-50%" },
            message: 'Esid list is empty',
          });
        }
      };
    const onSelectChange = (selectedRowKeys, selectedRows) => {
        setSelectedRowKeys(selectedRowKeys);
        setSelectedRows(selectedRows);
    };    
    return (
        <Modal
            width={"60%"}
            title={t("esid.exportList")}
            style={{ height: "620px" }}
            visible={visible}
            onCancel={() => {
                clearSelected();
                setVisible(false);
            }}
            footer={[
                <Button
                    key={`btnCancel1`}
                    className="btn-payment"
                    onClick={() => {
                        clearSelected();
                        setVisible(false);
                    }}
                    style={{ width: "fit-content", marginLeft: "5px" }}
                >
                Cancel
                </Button>,
                <Button
                    key={`btnOk1`}
                    className="btn-payment"
                    onClick={handleOk}
                    style={{ width: "fit-content" }}
                >
                Ok
                </Button>,
            ]}
        >
            <SearchForm name='searchForm' setDataSource={setDataSource} filterCodSts={'NY'} />
            <Table
                bordered
                className="table-cutoff"
                columns={columns}
                pagination={{
                    position: "bottomRight",
                    defaultPageSize: 20,
                    hideOnSinglePage: true,
                }}
                rowSelection={{
                    selectedRowKeys,
                    fixed: true,
                    type: "checkbox",
                    onChange: onSelectChange,
                }}
                dataSource={dataSource}
                style={{ marginTop: 10 }}
            />
        </Modal>
  )
}
