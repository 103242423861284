import { 
    SET_ESID_LOADING,
    SET_LIV_ANMS,
    SET_LIVANMS_SELECTED_VALUE,
    SET_DISABLE_EDIT_LIV_ANM_FORM_DECL,
    SET_VISABLE_EDIT_LIV_ANM_BUTTON,
    SET_LIV_SELECTED_TO_EDIT, 
  } from "../constant/actionTypes";
  
  const initialState = {
    livAnms: [],
    livAnmsSelectedValue: null,
    isEsidFormLoading: false,
    isDisableEditLivAnmFormDecl: true,
    isVisableEditLivAnmButton: true,
    livAnmSelectedToEdit: null,
  };
  
  export const livAnmReducer = (state = initialState, action) => {
    switch (action.type) {
      case SET_ESID_LOADING:
        return (state = { ...state, isEsidFormLoading: action.payload });
      case SET_LIV_ANMS:
        return (state = { ...state, livAnms: action.payload });
      case SET_LIVANMS_SELECTED_VALUE:
        return (state = { ...state, livAnmsSelectedValue: action.payload });
      case SET_DISABLE_EDIT_LIV_ANM_FORM_DECL:
        return (state = { ...state, isDisableEditLivAnmFormDecl: action.payload });
      case SET_VISABLE_EDIT_LIV_ANM_BUTTON:
        return (state = { ...state, isVisableEditLivAnmButton: action.payload });
      case SET_LIV_SELECTED_TO_EDIT:
        return (state = { ...state, livAnmSelectedToEdit: action.payload });
      default:
        break;
    }
    return state;
  };
  