import React, { useState, useEffect, useRef } from 'react'
import { Form, Modal, Row, Col } from 'antd';
import { useTranslation } from "react-i18next";
import { DimensionsTable } from './DimensionsTable';
import { useDispatch, useSelector } from 'react-redux';
import { DimensionSizeEnum } from '../../../../../../constant/enumerations';
import { setExpCarDimToEdit, setExpCarWgtToEdit } from '../../../../../../actions/esidExportAction';
import { objectToAntFormData } from '../../../../../../components/tracking/CertLivAnm/TabExpCertLivAnm';
import { AddDmsSizeModal } from './AddDmsSizeModal';
import { handleGetNextKey } from '../AcceptanceForm';
import { ContentItem } from '../../ContentItem';
import { CalDimVolWgt } from '../../../../../../config/esidExportApi';
import { DmsSizeToPrint } from './DmsSizeToPrint';
import { useReactToPrint } from 'react-to-print';
import { printConfig } from '../../../EsidExp';
import { createUUID } from '../../../../../../utils/utils';

export const DmsSizeContainer = ({ isEsidExpFinalized }) => {
    const { t } = useTranslation("common");
    const [sizeEditingKey, setSizeEditingKey] = useState('');
    const [sizeModalVisible, setSizeModalVisible] = useState(false);
    const [addSizeForm] = Form.useForm();
    const selectEsidExport = useSelector((state) => state.esidExport.esidExpSelected);
    const expCarDimToEdit = useSelector((state) => state.esidExport.expCarDimToEdit);
    const expCarWgtToEdit = useSelector((state) => state.esidExport.expCarWgtToEdit);
    const [nextSizeKey, setNextSizeKey] = useState(handleGetNextKey(expCarDimToEdit, 'codSeqNum'));
    const dispatch = useDispatch();
    const dmsPrintRef = useRef(null);
    const [carDimDataSource, setCarDimDataSource] = useState([]);

    useEffect(() => {
        dispatch(setExpCarDimToEdit(selectEsidExport?.expCarDim?.map(dim => ({...dim, uuid: dim.uuid || createUUID()})) || []));
        // eslint-disable-next-line
    }, [selectEsidExport]);

    useEffect(() => {
        const ds = expCarDimToEdit ? expCarDimToEdit.filter(x => x.codSts !== DimensionSizeEnum.XX) : [];
        setCarDimDataSource(ds);
    }, [expCarDimToEdit])

    const handleAddOrSaveSize = async (formValue, editingKey = -1) => {
        const newDimensionSizes = [...expCarDimToEdit];
        const index = newDimensionSizes.findIndex((dataItem) => editingKey === dataItem.uuid);
        const { qtyDimLen, qtyDimWid, qtyDimHgt, qtyDimPcs } = formValue;
        const { data: volWgt } = await CalDimVolWgt({
            codAwbPfx: selectEsidExport.codAwbPfx,
            codAwbNum: selectEsidExport.codAwbNum,
            dimPcs: qtyDimPcs,
            lenght: qtyDimLen,
            width: qtyDimWid,
            height: qtyDimHgt,
            keepDecimal: (localStorage.getItem('calcDimMode') || selectEsidExport?.shcCod001) === 'VKD' ? 1 : 0,
        })
        if (index > -1) {
            const oldItem = newDimensionSizes[index];
            newDimensionSizes.splice(index, 1, {
                ...oldItem,
                ...formValue,
                qtyDimWgt: parseFloat(volWgt),
            });
            dispatch(setExpCarDimToEdit(newDimensionSizes));
        } else {
            const newItem = {
                uuid: createUUID(),
                codSeqNum: nextSizeKey,
                esidId: selectEsidExport.id,
                codAwbNum: selectEsidExport.codAwbNum,
                codAwbPfx: selectEsidExport.codAwbPfx,
                codAwbSfx: selectEsidExport.codAwbSfx,
                codDimUnt: '0',
                qtyDimHgt: parseFloat(qtyDimHgt),
                qtyDimLen: parseFloat(qtyDimLen),
                qtyDimWid: parseFloat(qtyDimWid),
                qtyDimPcs: qtyDimPcs ?? 0,
                qtyDimWgt: parseFloat(volWgt),
                usrCre: selectEsidExport.usrCre,
                codSts: DimensionSizeEnum.NY
            }
            newDimensionSizes.push(newItem);
            dispatch(setExpCarDimToEdit(newDimensionSizes));
        }
        addSizeForm.resetFields();
        setSizeEditingKey('');
        // tinh tổng trọng lượng theo thể tích
        const volWgtTotal = newDimensionSizes?.filter(x => x.codSts !== 'XX')?.length > 0 ?
            newDimensionSizes?.filter(x => x.codSts !== 'XX')?.map(x => x.qtyDimWgt)?.reduce((a, b) => a + b) : 0;
        dispatch(setExpCarWgtToEdit({
            ...expCarWgtToEdit, 
            volWgt: volWgtTotal,
            wgtNet: volWgtTotal > expCarWgtToEdit?.wgtNet ? volWgtTotal : expCarWgtToEdit?.wgtNet
        }));
    }

    const handleEditSize = (record) => {
        setSizeEditingKey(record.uuid);
        setSizeModalVisible(true);
        addSizeForm.setFields(objectToAntFormData(record));
    }

    const showDeleteSizeConfirm = (record) => {
        Modal.confirm({
            title: t("dangerousGood.tableInfo.deleteConfirm"),
            content: (
                <div >
                    <ContentItem label={t("admin.acceptance.lengthHolder")} value={record.qtyDimLen} />
                    <ContentItem label={t("admin.acceptance.wideHolder")} value={record.qtyDimWid} />
                    <ContentItem label={t("admin.acceptance.heightHolder")} value={record.qtyDimHgt} />
                </div>
            ),
            onOk: () => handleDeleteSize(record.uuid),
            okText: "Yes",
            okType: "danger",
            cancelText: "No",
            centered: true,
        });
    }

    const handleDeleteSize = (uuid) => {
        const newData = expCarDimToEdit?.map((size) => {
            const temp = size;
            if (temp.uuid === uuid) {
                temp.codSts = DimensionSizeEnum.XX;
            }
            return temp;
        })
        // tinh tổng trọng lượng theo thể tích
        const volWgtTotal = newData?.filter(x => x.codSts !== 'XX').length > 0 ?
            newData?.filter(x => x.codSts !== 'XX')?.map(x => x.qtyDimWgt).reduce((a, b) => a + b) : 0;
        dispatch(setExpCarWgtToEdit({
            ...expCarWgtToEdit, 
            volWgt: volWgtTotal,
            wgtNet: volWgtTotal > expCarWgtToEdit?.wgtNet ? volWgtTotal : expCarWgtToEdit?.wgtNet
        }));
        dispatch(setExpCarDimToEdit([...newData]));
    }

    const handleClearEditing = (clearFnc, addForm) => {
        clearFnc('');
        addForm.resetFields();
    }

    useEffect(() => {
        setNextSizeKey(handleGetNextKey(expCarDimToEdit, 'codSeqNum'));
    }, [expCarDimToEdit])
    
    const openDmsPrint = useReactToPrint({
        content: () => dmsPrintRef.current,
        copyStyles: true,
        pageStyle: printConfig
    })

    return (
        <>
            {sizeModalVisible && (
                <AddDmsSizeModal 
                    modalVisible={sizeModalVisible} 
                    setModalVisible={setSizeModalVisible} 
                    editingKey={sizeEditingKey} 
                    form={addSizeForm} 
                    handleAddOrSave={handleAddOrSaveSize} 
                    handleClearEdit={() => handleClearEditing(setSizeEditingKey, addSizeForm)}
                />
            )}
            <DimensionsTable 
                handleDelete={showDeleteSizeConfirm} 
                handleEdit={handleEditSize} 
                setSizeEditingKey={setSizeEditingKey}
                setSizeModalVisible={setSizeModalVisible}
                addSizeForm={addSizeForm}
                dataSource={carDimDataSource} 
                isEsidExpFinalized={isEsidExpFinalized}
                openDmsPrint={openDmsPrint}
            />
            {
                expCarDimToEdit && selectEsidExport?.expCarWgt && selectEsidExport?.expCarWgt[0] && (
                    <Row>
                        <Col span={0}>
                            <DmsSizeToPrint 
                                dataExport={{
                                    dmsSizes: expCarDimToEdit, 
                                    wgt: selectEsidExport?.expCarWgt[0], 
                                    shpRegNam: selectEsidExport?.shpRegNam,
                                    acpStfNam: selectEsidExport?.acpStfNam
                                }} 
                                ref={dmsPrintRef}
                            />
                        </Col>
                    </Row>
                )
            }
        </>
    )
}
