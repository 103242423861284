import * as Constant from "./Constant";
import axios from "axios";

export const payWithoutOTP = (
  price,
  currency,
  orderId,
  orderReference,
  token
) => {
  return axios.post(
    `${Constant.DOMAIN_CLIENT}PayWithoutOTP`,
    {
      price,
      currency,
      orderId,
      orderReference,
    },
    {
      headers: {
        Authorization: token,
      },
    }
  );
};
export const checkPaymentSuccess = (orderId, token) => {
  return axios.post(
    `${Constant.DOMAIN_CLIENT}IsPaymentSuccess`,
    { orderId },
    {
      headers: {
        Authorization: token,
      },
    }
  );
};
export const getMerchantData = (data, token) => {
  return axios.post(
    `${Constant.DOMAIN_CLIENT}getMerchantData`,
    { data },
    {
      headers: {
        Authorization: token,
      },
    }
  );
};

export const getMerchantDataV2 = (data, token) => {
  return axios.post(
    `${Constant.DOMAIN_CLIENT}getMerchantDataV2`,
    { data },
    {
      headers: {
        Authorization: token,
      },
    }
  );
};

export const getOrderObj = (orderID, token) => {
  return axios.post(
    `${Constant.DOMAIN_CLIENT}GetTableID`,
    { orderID },
    {
      headers: {
        Authorization: token,
      },
    }
  );
};
export const saveReleaseObj = (obj) => {
  return axios.post(`${Constant.DOMAIN_CLIENT}HWB_MST_DLV_RLS_SAVE`, {
    ...obj,
  });
};
export const PDA_IMP_LND_SVC_LOAD_BY_OBJ_WEB = (obj, token) => {
  return axios.post(
    `${Constant.DOMAIN_CLIENT}PDA_IMP_LND_SVC_LOAD_BY_OBJ_WEB`,
    {
      ...obj,
    },
    {
      headers: {
        Authorization: token,
      },
    }
  );
};
export const PDA_IMP_LND_SVC_SAVE = (obj, token) => {
  return axios.post(
    `${Constant.DOMAIN_CLIENT}PDA_IMP_LND_SVC_SAVE`,
    {
      ...obj,
    },
    {
      headers: {
        Authorization: token,
      },
    }
  );
};
export const loadReleaseObj = (obj) => {
  return axios.post(`${Constant.DOMAIN_CLIENT}HWB_MST_DLV_RLS_LOAD_BY_OBJ`, {
    ...obj,
  });
};
export const logoutCheckin = (token) => {
  return axios.post(
    `${Constant.DOMAIN_CLIENT}logoutPayment`,
    { token },
    {
      headers: {
        Authorization: token,
      },
    }
  );
};
