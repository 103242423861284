/* eslint-disable */
import React from "react";
import { Button, Col, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { menuItemSelected } from "../actions/menu";
import { getMenuById } from "../config/apiConfig";

const ChargeInfoControls = (props) => {
  const { langId } = useSelector((state) => state.menu);
  const dispatch = useDispatch();
  const history = useHistory();
  const scrollTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };
  const setMenu = async (menuId) => {
    const menuRes = await getMenuById(menuId);
    const menuParentRes = await getMenuById(menuRes.data.parent);
    const menuReference = {
      key: menuRes.data.alias,
      title: menuRes.data.name,
      menuParentTitle: menuParentRes.data.name,
      banner: menuParentRes.data.params,
      menuId: menuRes.data.id,
      menuParentId: menuRes.data.parent,
      images: menuRes.data.images,
      referenceId: menuRes.data.reference_id,
    };
    dispatch(menuItemSelected(menuReference));
    localStorage.setItem("menuItem", JSON.stringify(menuReference));
  };
  const handleLink = () => {
    if (langId === "2") {
      setMenu(267);
    } else {
      setMenu(53);
    }
    scrollTop();
    history.push(
      langId === "2"
        ? "/dich-vu/bang-gia-dich-vu"
        : "/services/handling-charges"
    );
  };
  return (
    <Row className="result-content-wrapper">
      <Col span={24} className="control">
        <Row className="control-button-group-wrapper control-footer">
          <Col span={"auto"}>
            <Button style={{ width: "fit-content" }} htmlType={"submit"}>
              <a onClick={handleLink}>
                {langId === "2"
                  ? "Bảng giá dịch vụ áp dụng"
                  : "TCS Cargo Handling Charges"}
              </a>
            </Button>
          </Col>
          <Col>
            <Button
              onClick={() => props.setIsShowCharges(false)}
              style={{ width: "fit-content" }}
            >
              {langId === "2" ? "Quay lại" : "Back"}
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
export default ChargeInfoControls;
