import React, { useEffect } from "react";
import { Row, Col, Tabs, Button, notification } from "antd";
import Banner from "../components/Banner";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { errorHandling } from "../config/AuthIntercept";
import { logout } from "../config/apiConfig";
import { useDispatch, useSelector } from "react-redux";
import { userAwbLogout } from "../actions/userAWB";
import { setMAWB } from "../actions/MAWB";
import TabRegistryExportInformation from "../components/TabRegistryExportInformation";
import TabRegistryCar from "../components/TabRegistryCar";
import TabExpDeclDgr from "../components/tracking/ExpDeclDgr/TabExpDeclDgr";
import TabExpCertLivAnm from "../components/tracking/CertLivAnm/TabExpCertLivAnm";
import { setEsidExpTabPaneActive } from "../actions/esidExportAction";
import { ModalName, esidExpTabsEnum } from "../constant/enumerations";
import TabExpList from "../components/tracking/ExpList/TabExpList";
import TabVhcRegisList from "../components/tracking/VhcRegisList";
import { ExpBillChargeModal } from "../components/EsidCheckout/BillCharge/ExpBilChgModal";
import { TabExpDeclConsol } from "../components/tracking/ExpDeclConsol/TabExpDeclConsol";
import { TabExpCarDim } from "../components/tracking/ExpCarDim/TabExpCarDim";

const { TabPane } = Tabs;

const EsidExport = () => {
  const { t } = useTranslation("common");
  const history = useHistory();
  const dispatch = useDispatch();
  const tabPaneActive = useSelector((state) => state.esidExport.tabPaneActive);
  const tabVisibility = useSelector((state) => state.esidExport.tabEsidVisibility);
  const bilChgModalVisibility = useSelector((state) => state.esidExport.modal[ModalName.ExpBillCharge]);
  // const esidExport = useSelector((state) => state.esidExport.esidExpSelected);

  useEffect(() => {
    window.scrollTo(0, 380);
  }, []);

  const onChange = (key) => {
    if (key === esidExpTabsEnum.Home) {
      history.push("/Awb/Agent");
    } else {
      dispatch(setEsidExpTabPaneActive(key));
    }
  };

  const logOut = async () => {
    try {
      const token = JSON.parse(localStorage.getItem("userAWB"));
      if (token) {
        const res = await logout(token);
        dispatch(userAwbLogout());
        dispatch(setMAWB({}));
        localStorage.removeItem("userAWB");
        localStorage.removeItem("MAWB");
        notification.success({
          message: t("notification"),
          description: t(res.data.message),
        });
      }
      history.push("/");
    } catch (error) {
      errorHandling(error, history, "/");
    }
  };

  return (
    <Row
      style={{ display: "flex", justifyContent: "center" }}
      className="airlines-wrapper">
      <Banner
        title={""}
        className="banner-wrapper"
        cover={"../../img/004.jpg"}
      />
      <Row
        style={{ display: "flex", justifyContent: "center" }}
        className="airlines-content-wrapper">
        <Col
          style={{ display: "flex", justifyContent: "center" }}
          className="airlines-content-wrapper-large"
          // span={20}
        >
          {bilChgModalVisibility && (
            <ExpBillChargeModal />
          )}
          <Tabs
            tabBarGutter={20}
            activeKey={tabPaneActive}
            onChange={onChange}
            tabBarExtraContent={
              <Button
                className="logout-btn"
                onClick={logOut}
                style={{ width: "fit-content" }}>
                {t("header.logOut")}
              </Button>
            }>
            <TabPane
              className="awb-tab-content-wrapper"
              tab={t("home.serviceInfor")}
              key={esidExpTabsEnum.Home}
            >
              <div>Home</div>
            </TabPane>  
            <TabPane
              className="awb-tab-content-wrapper"
              tab={t("esid.exportList")}
              key={esidExpTabsEnum.List}>
              <TabExpList />
            </TabPane>
            <TabPane
              className="awb-tab-content-wrapper"
              tab={t("esid.exportRegistration")}
              key={esidExpTabsEnum.ExpRegis}>
              {tabVisibility === esidExpTabsEnum.ExpRegis && (
                <TabRegistryExportInformation />
              )}
              {tabVisibility === esidExpTabsEnum.Dgr && (
                <TabExpDeclDgr />
              )}
              {tabVisibility === esidExpTabsEnum.LivAnm && (
                <TabExpCertLivAnm />
              )}
              {tabVisibility === esidExpTabsEnum.Consol && (
                <TabExpDeclConsol />
              )}
              {tabVisibility === esidExpTabsEnum.CarDim && (
                <TabExpCarDim />
              )}
            </TabPane>
            {/* <TabPane
              className="awb-tab-content-wrapper"
              tab={t("esid.expDeclDGR")}
              key={esidExpTabsEnum.Dgr}
              disabled={esidExport?.shcDgr === "Y" ? false : true}>
              <TabExpDeclDgr />
            </TabPane>
            <TabPane
              className="awb-tab-content-wrapper"
              tab={t("esid.expCertLivAnm")}
              key={esidExpTabsEnum.LivAnm}
              disabled={esidExport?.shcAvi === "Y" ? false : true}>
              <TabExpCertLivAnm />
            </TabPane> */}
            <TabPane
              className="awb-tab-content-wrapper"
              tab={t("esid.expVhcRegisList")}
              key={esidExpTabsEnum.VhcRegisList}>
              <TabVhcRegisList />
            </TabPane>
            <TabPane
              className="awb-tab-content-wrapper"
              tab={t("esid.expVhcRegis")}
              key={esidExpTabsEnum.VhcRegis}>
              <TabRegistryCar />
            </TabPane>
          </Tabs>
        </Col>
      </Row>
    </Row>
  );
};

export default EsidExport;
