/* eslint-disable */
import React from "react";
import { Tabs, Row, Col, Skeleton } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import NewsTabsContent from "./NewsTabsContent";
import * as API from "../config/apiConfig";
import { useLocation } from "react-router";
import { changeLang, menuItemSelected } from "../actions/menu";
const { TabPane } = Tabs;

const CustomNewsTabs = ({ setLoading, loading }) => {
  const [listOfPosts, setPostsList] = useState([]);
  const { menuItem } = useSelector((state) => state.menu);
  const { langId } = useSelector((state) => state.menu);
  const [years, setFiveYears] = useState([]);
  const dispatch = useDispatch();
  const location = useLocation();
  const getFiveYears = () => {
    let result = [];
    let currentYear = new Date().getFullYear();
    for (let index = 0; index < 5; index++) {
      result.push(currentYear--);
    }
    return result;
  };

  const setMenu = async (menuId, langId) => {
    const menuRes = await API.getMenuById(menuId);
    const menuParentRes = await API.getMenuById(menuRes.data.parent);
    const menuReference = {
      key: menuRes.data.alias,
      title: menuRes.data.name,
      menuParentTitle: menuParentRes.data.name,
      banner: menuParentRes.data.params,
      menuId: menuRes.data.id,
      menuParentId: menuRes.data.parent,
      images: menuRes.data.images,
      referenceId: menuRes.data.reference_id,
    };
    dispatch(changeLang(langId));
    localStorage.setItem("langId", langId || 1);
    localStorage.setItem("LANGUAGE", langId === "2" ? "vi" : "en");
    dispatch(menuItemSelected(menuReference));
    localStorage.setItem("menuItem", JSON.stringify(menuReference));
  };
  useEffect(async () => {
    loadPage();
  }, [location]);

  const loadPage = async () => {
    setLoading(true);
    const slug = location.pathname.slice(
      location.pathname.lastIndexOf("/") + 1
    );
    const menuIdRes = await API.getMenuBySlug(slug);
    const { id, lang_id } = menuIdRes.data;

    API.getListMenuDetail(langId, id).then((res) => {
      setPostsList(res.data.data);
    });

    setMenu(id, lang_id + "");
    setLoading(false);
  }
  useEffect(() => {
    setLoading(true);
    API.getListMenuDetail(langId, menuItem.menuId).then((res) => {
      setPostsList(res.data.data);
    });
    setFiveYears(getFiveYears());
    setLoading(false);
  }, [menuItem.menuId, langId]);
  return (
    <Tabs
      centered
      tabBarGutter={20}
      size={"default"}
      defaultActiveKey={years[0]}
    >
      {years.map((year) => {
        return (
          <TabPane className="tab-content" tab={year} key={year}>
            <Skeleton loading={loading} active={loading}>
              {listOfPosts.length > 0 ? (
                listOfPosts.map((post) => {
                  if (
                    post &&
                    post.created.slice(0, post.created.indexOf("-")) == year
                  )
                    return (
                      <Row style={{ marginTop: "8px" }} key={post.id}>
                        <Col span={24}>
                          <NewsTabsContent post={post} />
                        </Col>
                      </Row>
                    );
                })
              ) : (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  {langId === "2" ? "CHƯA CÓ BÀI VIẾT" : "NO NEWS"}
                </div>
              )}
            </Skeleton>
          </TabPane>
        );
      })}
    </Tabs>
  );
};
export default CustomNewsTabs;
