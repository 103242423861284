const initialState = {
  menuItem: {},
  langId: 2,
  isShow: true,
};

export const menuReducer = (state = initialState, action) => {
  switch (action.type) {
    case "MENU_ITEM_SELECTED":
      const { payload } = action;
      if (payload.key) {
        return (state = { ...state, menuItem: action.payload });
      }
      return state;
    case "CHANGE_LANG":
      return (state = { ...state, langId: action.payload });
    case "SHOW_SWITCH":
      return (state = { ...state, isShow: action.payload });
    default:
      return state;
  }
};
