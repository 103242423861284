import { Modal, Table, notification } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ModalName } from "../../../constant/enumerations";
import { useTranslation } from "react-i18next";
import moment from "moment";
import {
  setExpBilChg,
  setExpBilChgLst,
  setModalEsidVisibility,
} from "../../../actions/esidExportAction";
import { useHistory } from "react-router-dom";
import { formatCurrency } from "../../../utils/utils";

export const ExpBillChargeModal = () => {
  const { t } = useTranslation("common");
  const history = useHistory();
  const dispatch = useDispatch();
  const selectEsidExport = useSelector(
    (state) => state.esidExport.esidExpSelected
  );
  const bilChgLst = useSelector((state) => state.esidExport.bilChgLst);
  const visibility = useSelector(
    (state) => state.esidExport.modal[ModalName.ExpBillCharge]
  );
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const selfClose = () => {
    dispatch(setExpBilChgLst(null));
    dispatch(setExpBilChg(null));
    dispatch(setModalEsidVisibility({ [`${ModalName.ExpBillCharge}`]: false }));
  };

  const handleSelectExpBilChg = () => {
    if (selectedRows.length === 0) {
      notification.warning({
        message: "Vui lòng chọn hóa đơn cần thanh!",
        style: { marginRight: "-50%" },
      });
      return;
    }
    dispatch(setModalEsidVisibility({ [`${ModalName.ExpBillCharge}`]: false }));
    history.push("/Esid/Checkout");
  };

  useEffect(() => {
    const fetchBilChgLst = () => {
      if (!selectEsidExport) {
        return;
      }
    };

    fetchBilChgLst();
  }, [selectEsidExport]);

  const onSelectChange = (selectedRowKeys, selectedRows) => {
    setSelectedRowKeys(selectedRowKeys);
    setSelectedRows(selectedRows);
    dispatch(setExpBilChg(selectedRows[0]));
  };

  const columns = [
    {
      title: "STT",
      dataIndex: "key",
      key: "key",
    },
    {
      title: "Trạng thái",
      dataIndex: "COD_STS",
      key: "COD_STS",
      render: (_, record) => (t(`esid.codSts.${record.COD_STS}`)),
      align: 'left'
    },
    {
      title: "Không vận đơn",
      dataIndex: "COD_AWB_PFX",
      key: "COD_AWB_PFX",
      colSpan: 2,
    },
    {
      title: "",
      dataIndex: "COD_AWB_NUM",
      key: "COD_AWB_NUM",
      colSpan: 0,
    },
    {
      title: "Thanh toán bởi",
      dataIndex: "CNE_CODE",
      key: "CNE_CODE",
      colSpan: 2,
    },
    {
      title: "Tên",
      dataIndex: "CNE_NAME",
      key: "CNE_NAME",
      colSpan: 0,
      align: 'left'
    },
    {
      title: "PTTT",
      dataIndex: "COD_PAY_MOD",
      key: "COD_PAY_MOD",
    },
    {
      title: "Tổng tiền",
      dataIndex: "AMT_TOT",
      key: "AMT_TOT",
      render: (_, record) => (formatCurrency(record.AMT_TOT)),
      align: 'right'
    },
    // {
    //   title: "Email",
    //   dataIndex: "EML_RCV_EINV",
    //   key: "EML_RCV_EINV",
    // },
    {
      title: "Họ tên người gửi hàng",
      dataIndex: "DLV_ODR_RCV_NAM",
      key: "DLV_ODR_RCV_NAM",
    },
    {
      title: "CCCD",
      dataIndex: "DLV_ODR_RCV_ID",
      key: "DLV_ODR_RCV_ID",
    },
    {
      title: "Ngày lập",
      dataIndex: "DAT_CRE",
      key: "DAT_CRE",
      render: (_, record) => (moment(record.DAT_CRE).format('DD/MM/YYYY HH:mm')),
    },
  ];

  return (
    <Modal
      title={t("esid.checkoutTabs.selectBillCharge")}
      visible={visibility}
      onCancel={selfClose}
      onOk={handleSelectExpBilChg}
      centered
      okText={t("registerCar.submitBtn")}
      width="90%">
      <Table
        bordered
        // loading={loading}
        className="table-payment"
        columns={columns}
        pagination={false}
        rowSelection={{
          selectedRowKeys,
          type: "radio",
          fixed: true,
          hideSelectAll: true,
          onChange: onSelectChange,
        }}
        dataSource={bilChgLst.map( (bil, idx) => ({ ...bil, key: idx + 1 }))}
      />
    </Modal>
  );
};
