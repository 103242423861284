import { Form, Input, Row, Col, Typography} from 'antd'
import React, { useState } from 'react'
import { useTranslation } from "react-i18next";
// import { useSelector } from 'react-redux';
import { CodCtcTypEnum } from '../../../../../constant/enumerations';
import styles from '../../style.module.css'
export const PersonalForm = ({title, name, children, form, required, readOnly}) => {
    const { t } = useTranslation("common");
    const [isNamRequired, setIsNamRequired] = useState(false);
    const [isAddrRequired, setIsAddrRequired] = useState(false);
    const [timer, setTimer] = useState(null);
    const handleRequiredNamAndAddr = (_, allValues) => {
        clearTimeout(timer);
        const timeoutId = setTimeout(async () => {
            if (required) {
                setIsNamRequired(true);
                setIsAddrRequired(true);
                return;
            }
            if ( allValues.namCtc && allValues.namCtc !== '' && (allValues.desAddStt === '' || !allValues.desAddStt) ) {
                setIsAddrRequired(true);
            }
            else {
                setIsAddrRequired(false);
            }
            if ( allValues.desAddStt &&  allValues.desAddStt !== '' && (allValues.namCtc === '' || !allValues.namCtc)) {
                setIsNamRequired(true);
            }
            else {
                setIsNamRequired(false);
            }
            await form.validateFields();
        },400)
        setTimer(timeoutId);
    }

    return (
        <Form
            name={name}
            labelCol={{ span: 24, offset: 0 }}
            labelAlign={'left'}
            wrapperCol={{ span: 24 }}
            autoComplete="off"
            form={form}
            className={`${styles.personalForm} personalForm`}
            onValuesChange={handleRequiredNamAndAddr}
        >
            <Form.Item
                label={<Typography.Text className={(isNamRequired || required) && styles.labelRequired} strong >{`${t(`formInfo.${title}`)}`}</Typography.Text>}
                name="namCtc"
                className={styles.formItem}
                rules={[
                    {
                        required: isNamRequired || required,
                        message: t("admin.validation.required"),
                    }
                ]}
            >
                <Input readOnly={readOnly} />
            </Form.Item>
            <Form.Item
                label={<Typography.Text className={(isAddrRequired || required) && styles.labelRequired} >{t("formInfo.address")}</Typography.Text>}
                name="desAddStt"
                className={styles.formItem}
                rules={[
                    {
                        required: isAddrRequired || required,
                        message: t("admin.validation.required"),
                    }
                ]}
            >
                <Input readOnly={readOnly} />
            </Form.Item>
            <Input.Group>
                <Row gutter={[20,10]} className={styles.responsiveRow}>
                    <Col md={12} xs={24}>
                        <Form.Item
                            label="Email"
                            name="desEmail"
                            className={styles.formItem}
                            >
                            <Input readOnly={readOnly} />
                        </Form.Item>
                    </Col>
                    <Col md={12} xs={24}>
                        <Form.Item
                            label={t("formInfo.tel")}
                            name="desPhone"
                            className={styles.formItem}
                            >
                            <Input readOnly={readOnly} />
                        </Form.Item>
                    </Col>
                </Row>
            </Input.Group>
            <Input.Group>
                <Row gutter={20} className={styles.responsiveRow}>
                    <Col md={title && title !== CodCtcTypEnum.Notify ? 12 : 24} xs={24}>
                        <Form.Item
                            label="Fax"
                            name="desFax"
                            className={styles.formItem}
                        >
                            <Input readOnly={readOnly} />
                        </Form.Item>
                    </Col>
                {
                    title && title !== CodCtcTypEnum.Notify && (
                        <Col md={12} xs={24}>
                            <Form.Item
                                label={t("formInfo.vatCode")}
                                name="codTaxCmp"
                                className={styles.formItem}
                                style={{ fontWeight: 'bold' }}
                                >
                                <Input readOnly={readOnly} style={{ fontWeight: 'bold' }}/>
                            </Form.Item>
                        </Col>
                    )
                }
                </Row>
            </Input.Group>
            { 
                children && children
            }
        </Form>
  )
}
