/* eslint-disable */
import React, { useEffect, useRef, useState } from "react";
import {
  Carousel,
  Row,
  Col,
  Form,
  Button,
  Radio,
  Input,
  Divider,
  notification,
  Tooltip
} from "antd";
import "./page-css/Home.css";
import CustomCard from "../components/CustomCard";
import CustomNewsCard from "../components/CustomNewsCard";
import { NavLink, useHistory } from "react-router-dom";
import { faBriefcase, faTrophy } from "@fortawesome/free-solid-svg-icons";
import { faCompass } from "@fortawesome/free-regular-svg-icons";
import * as API from "../config/apiConfig";
import * as Constant from "../config/Constant";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { setMAWB } from "../actions/MAWB";
import { useTranslation } from "react-i18next";
import { menuItemSelected, showLangSwitch } from "../actions/menu";
import { userAwbLoggin, userAwbLogout, userGuest } from "../actions/userAWB";
import { useLocation } from "react-router";
import RoleClaim from "../constant/roleClaim";
import axios from "axios";
import { SyncOutlined } from '@ant-design/icons';

const Home = () => {
  const [introCard, setIntroCard] = useState([]);
  const [serviceCard, setServiceCard] = useState([]);
  const [newsCard, setNewsCard] = useState([]);
  const [customerNews, setCustomerNews] = useState([]);
  const [tcsNews, setTcsNews] = useState([]);
  const [industrialNews, setIndustrialNews] = useState([]);
  const [form] = Form.useForm();
  const [carouselContents, setCarouselContents] = useState([]);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const { MAWB } = useSelector((state) => state.mawb);
  const { signedIn, currentUser } = useSelector((state) => state.userAwb);
  const { langId } = useSelector((state) => state.menu);
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation("common");
  const [captcha, setCaptcha] = useState({});

  useEffect(() => {
    if (!currentUser || !currentUser.roles || currentUser.roles.length === 0) return;
    const role = currentUser.roles[0];
    switch (role) {
      case RoleClaim.CheckInOnl:
        history.push('/awb/checkoutLogin');
        break;
      case RoleClaim.CheckInOnl:
        history.push('/awb/checkoutLogin');
        break;  
      default:
        break;
    }
  }, [currentUser]);

  useEffect(() => {
    API.getListMenuDetail(langId, langId === "2" ? 273 : 65)
      .then((res) => {
        setCustomerNews(res.data.data.slice(0, 3));
      })
      .catch((er) => {
        // console.log(er);
      });
    API.getListMenuDetail(langId, langId === "2" ? 275 : 66)
      .then((res) => {
        setTcsNews(res.data.data.slice(0, 3));
      })
      .catch((err) => console.log(err));
    API.getListMenuDetail(langId, langId === "2" ? 274 : 119)
      .then((res) => {
        setIndustrialNews(res.data.data.slice(0, 3));
      })
      .catch((err) => console.log(err));
    API.getContentById(langId === "2" ? 13823 : 13824)
      .then((res) => {
        setIntroCard(res.data.data);
      })
      .catch((er) => {
        // console.log(er);
      });
    API.getContentById(langId === "2" ? 13834 : 13835)
      .then((res) => {
        setServiceCard(res.data.data);
      })
      .catch((er) => {
        // console.log(er);
      });
    API.getContentCustomerNew(langId, langId === "2" ? 273 : 65)
      .then((res) => {
        setNewsCard(res.data.response);
      })
      .catch((er) => {
        // console.log(er);
      });
    API.get("getBanners")
      .then((res) => setCarouselContents(res.data))
      .catch((er) => {
        // console.log(er);
      });
    API.getCaptcha().then(res => setCaptcha(res.data));
    setLoading(false);
  }, [langId]);

  const refreshCaptchaCode = async () => {
    const captchatRes = await API.getCaptcha();
    if (captchatRes.data) {
      setCaptcha(captchatRes.data);
    }
  }

  const { dataLogin } = useSelector((state) => state.userAwb);
  const getLogin = (e) => {
    history.push(`/AwbLogin/${e.target.value}`);
  };

  const awbLast = useRef();
  const awbFirst = useRef();
  const inputCaptcha = useRef();
  const contentStyle = {
    height: "489px",
    color: "#fff",
    lineHeight: "160px",
    textAlign: "center",
    background: "gray",
  };
  useEffect(() => {
    dispatch(showLangSwitch(true));
  }, []);

  const handleMAWB = async (values) => {
    try {
      // if (!dataLogin.token) {
      const checkMawbRes = await API.CHECK_MAWB(
        values.txtAwbFirst,
        values.txtAwbLast,
        captcha.id,
        values.captchaCode
      );
      dispatch(
        userGuest({
          data: { user_code: "" },
          token: checkMawbRes.data.token,
        })
      );
      localStorage.setItem(
        "userGuest",
        JSON.stringify({
          data: { user_code: "" },
          token: checkMawbRes.data.token,
        })
      );
      // }
      dispatch(
        setMAWB({
          ...MAWB,
          first: values.txtAwbFirst,
          last: values.txtAwbLast
          // first: awbFirst.current.state.value,
          // last: awbLast.current.state.value,
        })
      );

      history.push("/MAWB");
    } catch (error) {
      console.log(error);
      let mess = "Số vận đơn không hợp lệ!";
      if (axios.isAxiosError(error)) {
        console.log(error.code, error.message, error.response)
        if (error.response.status === 403 && error.response.data === "invalid captcha") {
          mess = "Mã xác thực không đúng.";
        }
        if (error.response.status === 403 && error.response.data === "expired captcha") {
          mess = "Mã xác thực đã quá hạn.";
        }
        if (error.response.data === "Too many requests, please try again later.") {
          mess = "Bạn đã thử nhập quá nhiều mã. Vui lòng thử lại sau 15 phút.";
        }
        if (error.response.data === "HAWB.please.login") {
          mess = t('home.HAWB.please.login');
        }
      }
      form.setFieldsValue({ captchaCode: ''});
      refreshCaptchaCode();
      notification.error({
        message: mess,
        style: { marginRight: "-50%" },
      });
    }
  };
  const formItemLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: "auto" },
  };
  const { menuItem } = useSelector((state) => state.menu);
  const autoTab = (e) => {
    e.target.value.length === 3 && awbLast.current.focus();
  };
  const autoFocusCaptcha = (e) => {
    e.target.value.length === 8 && inputCaptcha.current.focus();
  };
  const handleLink = () => {
    if (langId === "2") {
      const link = {
        title: "Liên kết",
        banner: "2021-06-11T10-17-17.313Z-banner-lien-ket.jpg",
        menuId: 9,
        menuParentTitle: "Liên kết",
        referenceId: 68,
      };
      dispatch(menuItemSelected({ ...menuItem, ...link }));
      history.push("/lien-ket");
    } else {
      const link = {
        title: "Link",
        banner: "2021-06-11T10-17-17.313Z-banner-lien-ket.jpg",
        menuId: 68,
        menuParentTitle: "Link",
        referenceId: 9,
      };
      dispatch(menuItemSelected({ ...menuItem, ...link }));
      history.push("/link");
    }
  };
  const handleOpenEInvoice = () => {
    window.open(
      "http://e-invoice.tcs.com.vn/",
      "_blank"
    );
  };
  const limitInput = (e) => {
    if (e.target.value.length > e.target.maxLength)
      switch (e.target.name) {
        case "txtAwbLast":
          form.setFieldsValue({
            txtAwbLast: e.target.value.slice(0, e.target.maxLength),
          });
          break;
        case "txtAwbFirst":
          form.setFieldsValue({
            txtAwbFirst: e.target.value.slice(0, e.target.maxLength),
          });
          break;
        default:
          break;
      }
  };
  return (
    <Row gutter={[0, 10]} className="home-content-wrapper">
      <Col
        span={0}
        md={24}
        style={{ height: "500px" }}
        className="home-carousel-wrapper"
      >
        <Carousel
          className="home-carousel"
          style={{ height: "500px", width: "100%" }}
          autoplay
        >
          {/*       <iframe
            width="100%"
            height="500px"
            src="https://www.youtube-nocookie.com/embed/SmnKv0f05do?&autoplay=1&loop=1&mute=1"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; loop"
            allowFullScreen
          ></iframe> */}
          {carouselContents.map((content, index) => {
            return (
              <div key={index}>
                <h3 style={contentStyle}>
                  <a href={content.clickurl}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      alt="banner-carousel"
                      src={`${Constant.IMAGES_URL}${content.imageurl}`}
                    />
                  </a>
                </h3>
              </div>
            );
          })}
        </Carousel>
      </Col>
      <Col span={24}>
        <Row className="home-control-wrapper">
          <Col className="home-control" span={22} lg={18}>
            <div className="tab-container">
              <div className="tab tab-show">
                <h2 className="tab-title">{t("home.awbNav")}</h2>
              </div>
              <div className="tab tab-hidden">
                {/* <a onClick={handleLink}> */}
                <a onClick={handleOpenEInvoice}>
                  <h2 className="tab-title">{t("home.billsNav")}</h2>
                </a>
              </div>
              <div className="tab tab-hidden">
                <NavLink to="/statistic">
                  <h2 className="tab-title">{t("home.statistic")}</h2>
                </NavLink>
              </div>
              {window.innerWidth > 576 && (
                <div className="tab tab-hidden">
                  <NavLink to="/nvesid">
                    <h2 className="tab-title">{t("home.adminEsidExport")}</h2>
                  </NavLink>
                </div>
              )}
            </div>
            <div className="tab-content-container">
              <Row gutter={8} justify="space-between" align="middle">
                <Col md={20} lg={16} xl={10}>
                  <Form 
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    onFinish={handleMAWB}
                    layout='horizontal'
                    form={form}
                    autoComplete="off"
                  >
                    <Form.Item label={t("home.awbNo")} style={{ marginBottom: 0 }}>
                      <Form.Item
                        name="txtAwbFirst"
                        rules={[{ required: true, message: t("home.required") }]}
                        style={{ display: 'inline-block', width: '75px' }}
                      >
                        <Input name="txtAwbFirst" ref={awbFirst} maxLength={3} onKeyUp={autoTab} onChange={limitInput}/>
                      </Form.Item>
                      <Form.Item
                        name="txtAwbLast"
                        rules={[{ required: true, message: t("home.required") }]}
                        style={{ display: 'inline-block', width: 'calc(100% - 80px)', margin: '0 2px' }}
                      >
                        <Input name="txtAwbLast" ref={awbLast} maxLength={8} onKeyUp={autoFocusCaptcha} onChange={limitInput}/>
                      </Form.Item>
                    </Form.Item>
                    <Form.Item label={t("home.captcha")} style={{ marginBottom: 0 }}>
                      <Form.Item
                        name="captchaCode"
                        rules={[{ required: true, message: t("home.required") }]}
                        style={{ display: 'inline-block', width: 'calc(100% - 140px)' }}
                      >
                        <Input ref={inputCaptcha}/>
                      </Form.Item>
                      <Form.Item
                        style={{ display: 'inline-block', margin: '0 2px' }}
                      >
                        <img src={captcha.url} style={{ backgroundColor: 'whitesmoke', padding: '3px' }} />
                      </Form.Item>
                      <Form.Item
                        style={{ display: 'inline-block', margin: '0 2px' }}
                      >
                        <Tooltip title={t("home.refreshCaptcha")}>
                          <SyncOutlined 
                            style={{ cursor: 'pointer', fontSize: 18, marginLeft: '3px', marginTop: '3px' }} 
                            onClick={refreshCaptchaCode}
                          />
                        </Tooltip>
                      </Form.Item>
                    </Form.Item>
                    <Form.Item wrapperCol={{ offset: 9, span: 15 }}>
                          <Button
                            type="primary" htmlType="submit"
                            className="btn-customer-form"
                            style={{ float: 'right' }}
                          >
                            {t("home.go")}
                          </Button>
                    </Form.Item>
                  </Form>
                </Col>
                <Col lg={8} xl={14}>
                  {dataLogin?.data?.roles?.includes(RoleClaim.EsidACPT) &&
                    <Radio
                      onClick={() => {
                        history.push("/nvesid");
                      }}
                      name="rdoAdminEsidExport"
                      value="adminEsidExport"
                    >
                      {t("home.adminEsidExport")}
                    </Radio>
                  }
                {!signedIn && (
                    <Form.Item {...formItemLayout}>
                      <Radio.Group>
                        <Radio
                          style={{ marginRight: "-30px" }}
                          onClick={getLogin}
                          name="rdoAgent"
                          value="agent"
                        >
                          {t("home.agent")}
                        </Radio>
                        <Radio
                          style={{ marginRight: "-30px" }}
                          onClick={getLogin}
                          name="rdoAirline"
                          value="airlines"
                        >
                          {t("home.airlines")}
                        </Radio>
                        <Radio
                          onClick={() => {
                            history.push("/awb/checkoutLogin");
                          }}
                          name="rdoAirline"
                          value="airlines"
                        >
                          {t("home.checkin")}
                        </Radio>
                        {/* <Radio
                          onClick={() => {
                            history.push("/Esid/Export");
                          }}
                          name="rdoEsidExport"
                          value="esidExport"
                        >
                          {t("home.esidExport")}
                        </Radio> */}
                      </Radio.Group>
                    </Form.Item>
                  )}
                  {signedIn &&
                  ["admi", ""].includes(dataLogin.data.user_code) ? (
                    <Radio.Group
                      style={{
                        display: "flex",
                        height: "100%",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Radio
                        style={{ marginRight: "-30px" }}
                        onClick={() => history.push("/Awb/Agent")}
                        name="rdoAgent"
                        value="agent"
                      >
                        {t("home.agent")}
                      </Radio>
                      <Radio
                        style={{ marginRight: "-30px" }}
                        onClick={() => history.push("/Awb/Airlines")}
                        name="rdoAirline"
                        value="airlines"
                      >
                        {t("home.airlines")}
                      </Radio>
                      <Radio
                        onClick={() => {
                          !dataLogin.data.user_code.startsWith("TCS") &&
                            dispatch(userAwbLogout());
                          history.push("/awb/checkoutLogin");
                        }}
                        name="rdoAirline"
                        value="airlines"
                      >
                        {t("home.checkin")}
                      </Radio>
                      {/* <Radio
                        onClick={() => {
                          history.push("/Esid/Export");
                        }}
                        name="rdoEsidExport"
                        value="esidExport"
                      >
                        {t("home.esidExport")}
                      </Radio> */}
                    </Radio.Group>
                  ) : dataLogin &&
                    dataLogin.data.user_code.slice(0, 2) === "90" &&
                    isNaN(
                      dataLogin.data.user_code.slice(
                        2,
                        dataLogin.data.user_code.length
                      )
                    ) ? (
                    <Radio.Group>
                      {!dataLogin.data.user_code.startsWith("TCS") && (
                        <Radio
                          onClick={() => history.push("/Awb/Airlines")}
                          name="rdoAirline"
                          value="airlines"
                        >
                          {t("home.airlines")}
                        </Radio>
                      )}
                      <Radio
                        onClick={() => {
                          !dataLogin.data.user_code.startsWith("TCS") &&
                            dispatch(userAwbLogout());
                          history.push("/awb/checkoutLogin");
                        }}
                        name="rdoAirline"
                        value="airlines"
                      >
                        {t("home.checkin")}
                      </Radio>
                      {/* <Radio
                        onClick={() => {
                          history.push("/Esid/Export");
                        }}
                        name="rdoEsidExport"
                        value="esidExport"
                      >
                        {t("home.esidExport")}
                      </Radio> */}
                    </Radio.Group>
                  ) : (
                    signedIn && (
                      <Radio.Group>
                        {!dataLogin.data.user_code.startsWith("TCS") &&
                          dataLogin.data.user_code !== "" && (
                            <Radio
                              onClick={() => {
                                if (dataLogin.data.user_code !== "ad")
                                  history.push("/Awb/Agent");
                                else {
                                  history.push("/Awb/samsung");
                                }
                              }}
                              name="rdoAgent"
                              value="agent"
                            >
                              {t("home.agent")}
                            </Radio>
                          )}

                        <Radio
                          onClick={() => {
                            !dataLogin.data.user_code.startsWith("TCS") &&
                              dispatch(userAwbLogout());
                            history.push("/awb/checkoutLogin");
                          }}
                          name="rdoAirline"
                          value="airlines"
                        >
                          {t("home.checkin")}
                        </Radio>
                        {/* <Radio
                          onClick={() => {
                            history.push("/Esid/Export");
                          }}
                          name="rdoEsidExport"
                          value="esidExport"
                        >
                          {t("home.esidExport")}
                        </Radio> */}
                      </Radio.Group>
                    )
                  )}
                </Col>
              </Row>
              {/* <Form
                size={"middle"}
                className="customer-form"
                layout={"inline"}
                form={form}
              >
                <Row style={{ display: "flex", alignItems: "center" }}>
                  <Col style={{ paddingRight: "5px" }}>{t("home.awbNo")}</Col>
                  <Col style={{ display: "flex" }}>
                    <Form.Item name="txtAwbFirst">
                      <Input
                        type="number"
                        name="txtAwbFirst"
                        maxLength={3}
                        ref={awbFirst}
                        onKeyUp={autoTab}
                        onChange={limitInput}
                        style={{
                          maxWidth: "65px",
                          height: "24px",
                        }}
                      />
                    </Form.Item>
                    <Form.Item name="txtAwbLast">
                      <Input
                        ref={awbLast}
                        type="number"
                        name="txtAwbLast"
                        maxLength={8}
                        onChange={limitInput}
                        style={{
                          maxWidth: "161px",
                          height: "24px",
                        }}
                      />
                    </Form.Item>
                    <Form.Item style={{ marginLeft: "-30px" }}>
                      <Button
                        onClick={handleMAWB}
                        className="btn-customer-form"
                      >
                        {t("home.go")}
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
                <div style={{ marginLeft: "-25px" }}>
                  {!signedIn && (
                    <Form.Item {...formItemLayout}>
                      <Radio.Group>
                        <Radio
                          style={{ marginRight: "-30px" }}
                          onClick={getLogin}
                          name="rdoAgent"
                          value="agent"
                        >
                          {t("home.agent")}
                        </Radio>
                        <Radio
                          style={{ marginRight: "-30px" }}
                          onClick={getLogin}
                          name="rdoAirline"
                          value="airlines"
                        >
                          {t("home.airlines")}
                        </Radio>
                        <Radio
                          onClick={() => {
                            history.push("/awb/checkoutLogin");
                          }}
                          name="rdoAirline"
                          value="airlines"
                        >
                          {t("home.checkin")}
                        </Radio>
                      </Radio.Group>
                    </Form.Item>
                  )}
                  {signedIn &&
                  ["admi", ""].includes(dataLogin.data.user_code) ? (
                    <Radio.Group
                      style={{
                        display: "flex",
                        height: "100%",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Radio
                        style={{ marginRight: "-30px" }}
                        onClick={() => history.push("/Awb/Agent")}
                        name="rdoAgent"
                        value="agent"
                      >
                        {t("home.agent")}
                      </Radio>
                      <Radio
                        style={{ marginRight: "-30px" }}
                        onClick={() => history.push("/Awb/Airlines")}
                        name="rdoAirline"
                        value="airlines"
                      >
                        {t("home.airlines")}
                      </Radio>
                      <Radio
                        onClick={() => {
                          !dataLogin.data.user_code.startsWith("TCS") &&
                            dispatch(userAwbLogout());
                          history.push("/awb/checkoutLogin");
                        }}
                        name="rdoAirline"
                        value="airlines"
                      >
                        {t("home.checkin")}
                      </Radio>
                    </Radio.Group>
                  ) : dataLogin &&
                    dataLogin.data.user_code.slice(0, 2) === "90" &&
                    isNaN(
                      dataLogin.data.user_code.slice(
                        2,
                        dataLogin.data.user_code.length
                      )
                    ) ? (
                    <Radio.Group>
                      {!dataLogin.data.user_code.startsWith("TCS") && (
                        <Radio
                          onClick={() => history.push("/Awb/Airlines")}
                          name="rdoAirline"
                          value="airlines"
                        >
                          {t("home.airlines")}
                        </Radio>
                      )}
                      <Radio
                        onClick={() => {
                          !dataLogin.data.user_code.startsWith("TCS") &&
                            dispatch(userAwbLogout());
                          history.push("/awb/checkoutLogin");
                        }}
                        name="rdoAirline"
                        value="airlines"
                      >
                        {t("home.checkin")}
                      </Radio>
                    </Radio.Group>
                  ) : (
                    signedIn && (
                      <Radio.Group>
                        {!dataLogin.data.user_code.startsWith("TCS") &&
                          dataLogin.data.user_code !== "" && (
                            <Radio
                              onClick={() => {
                                if (dataLogin.data.user_code !== "ad")
                                  history.push("/Awb/Agent");
                                else {
                                  history.push("/Awb/samsung");
                                }
                              }}
                              name="rdoAgent"
                              value="agent"
                            >
                              {t("home.agent")}
                            </Radio>
                          )}

                        <Radio
                          onClick={() => {
                            !dataLogin.data.user_code.startsWith("TCS") &&
                              dispatch(userAwbLogout());
                            history.push("/awb/checkoutLogin");
                          }}
                          name="rdoAirline"
                          value="airlines"
                        >
                          {t("home.checkin")}
                        </Radio>
                      </Radio.Group>
                    )
                  )}
                </div>
              </Form> */}
            </div>
          </Col>
        </Row>
      </Col>
      <Col className="home-btn" span={22} md={0}>
        <Button onClick={() => history.push("/statistic")}>
          {t("home.statistic")}
        </Button>
      </Col>
      <Col className="home-btn" span={22} md={0}>
        <Button onClick={() => history.push("/link")}>
          {t("home.airlinesNav")}
        </Button>
      </Col>
      <Divider style={{ border: "transparent" }} />
      <Row gutter={[24, 24]} className="content-wrapper">
        {introCard && newsCard && serviceCard && (
          <React.Fragment>
            <Col span={23} md={23} lg={6} className="home-content">
              <CustomCard
                loading={loading}
                icon={faBriefcase}
                cardType={"intro"}
                title={langId === "2" ? "Giới thiệu" : "Introduction"}
                cover={
                  introCard.images
                    ? `${Constant.IMAGES_URL}${introCard.images}`
                    : "${Constant.IMAGES_URL}2022-08-10T07-41-20.252Z-logotcs.jpg"
                }
                link={`/news/detail/${introCard.id}`}
              >
                {introCard.introtext}
              </CustomCard>
            </Col>
            <Col span={23} md={23} lg={6} className="home-content">
              <CustomCard
                loading={loading}
                icon={faCompass}
                cover={
                  serviceCard.images
                    ? `${Constant.IMAGES_URL}${serviceCard.images}`
                    : "${Constant.IMAGES_URL}2022-08-10T07-41-20.252Z-logotcs.jpg"
                }
                cardType={"service"}
                title={langId === "2" ? "Dịch vụ" : "Service"}
                link={
                  langId === "2"
                    ? "/dich-vu/phuc-vu-hang-xuat"
                    : `/services/export-cargo-services`
                }
              >
                {serviceCard.introtext}
              </CustomCard>
            </Col>
            <Col span={23} md={23} lg={6} className="home-content">
              <CustomCard
                loading={loading}
                title={newsCard.title}
                icon={faTrophy}
                cardType={"news"}
                cover={
                  newsCard.images
                    ? `${Constant.IMAGES_URL}${newsCard.images}`
                    : "${Constant.IMAGES_URL}2022-08-10T07-41-20.252Z-logotcs.jpg"
                }
                link={`/news/detail/${newsCard.id}`}
              >
                {newsCard.introtext}
              </CustomCard>
            </Col>
          </React.Fragment>
        )}
      </Row>
      <Row style={{ marginTop: "30px" }} className="content-wrapper">
        <Col span={22} md={24} lg={8} xl={8} xxl={6} className="home-content">
          <CustomNewsCard
            title={langId === "2" ? "Dành cho khách hàng" : "Customer"}
            news={customerNews}
          />
        </Col>
        <Col span={22} md={24} lg={8} xl={6} xxl={6} className="home-content">
          <CustomNewsCard title={"TCS"} news={tcsNews} />
        </Col>
        <Col span={22} md={24} lg={8} xl={6} xxl={6} className="home-content">
          <CustomNewsCard
            title={langId === "2" ? "chuyên ngành" : "industrial"}
            news={industrialNews}
          />
        </Col>
      </Row>
    </Row>
  );
};

export default React.memo(Home);
