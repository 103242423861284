import React from 'react'
import styles from './payment.module.css'
import { Radio, Typography } from 'antd'
import { useTranslation } from 'react-i18next';
export const ChosePayment = ({ checkedOption, setCheckedOption}) => {
    const { t } = useTranslation("common")
    const paymentOptions = [
        {
            label: <div className={styles.paymentOption}>
                        <img src="../../../img/credit-cards.png" alt="credit" className={styles.paymentIcon} />
                        <span className='payment-label'>{t("esidCheckout.paymentTab.cardPayment")}</span>
                    </div>,
            value: 'WEB',
        },
        // {
        //     label: <div className={styles.paymentOption}>
        //                 <img src="../../../img/qr-code.png" alt="credit" className={styles.paymentIcon} />
        //                 <div style={{ display: 'flex', flexDirection: 'column'}}>
        //                     <span className='payment-label'>{t("esidCheckout.paymentTab.qrPayment")}</span>
        //                     <span style={{ fontSize: 10 }}>{t("esidCheckout.paymentTab.qrPaymentDesc")}</span>
        //                 </div>
        //             </div>,
        //     value: 'WQR',
        // },
        // {
        //     label: <div className={styles.paymentOption}>
        //                 <img src="../../../img/pay.png" alt="pay" className={styles.paymentIcon} />
        //                 <span className='payment-label'>{t("esidCheckout.paymentTab.cashPayment")}</span>
        //             </div>,
        //     value: 'CSH',
        // },
    ]

    const handleOptionChange = (e) => {
        setCheckedOption(e.target.value);
    }

    const renderPaymentLogoSrc = () => {
        switch (checkedOption) {
            case 'WQR':
                return "../../../img/qr-payment-logo.png";
            case 'CSH':
                return "../../../img/logotcs_new_2015.jpg"
            default:
                return "../../../img/napas-logo.jpg";
        }
    }
    return (
        <>
            <div className={styles.paymentLogoContainer}>
                <div className={styles.paymentLogoWrapper}>
                    <img src={renderPaymentLogoSrc()} className={styles.paymentLogo} alt="napas" />
                </div>
                <div>
                    <Typography.Title level={4}>{t("esidCheckout.paymentTab.chosePaymentTitle")}</Typography.Title>
                    <Typography.Text type='danger' style={{ fontSize: 16 }}>{t("esidCheckout.paymentTab.chosePaymentSubTitle")}</Typography.Text>
                </div>
            </div>
            <Radio.Group className='paymentOptions' options={paymentOptions} value={checkedOption} onChange={handleOptionChange}/>
        </>
  )
}
