/* eslint-disable */
import React from "react";
import { Button, Col, notification, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { menuItemSelected } from "../actions/menu";
import { getMenuById } from "../config/apiConfig";
import { useTranslation } from "react-i18next";

const TabInfoControls = (props) => {
  const { langId } = useSelector((state) => state.menu);
  const { t } = useTranslation("common");
  const { dataCheckin, dataLogin, signedIn } = useSelector(
    (state) => state.userAwb
  );
  const { MAWB } = useSelector((state) => state.mawb);
  const dispatch = useDispatch();
  const history = useHistory();
  const handlePaymentBtnClick = () => {
    if (props.isRowSelected) {
      if (
        (signedIn && dataLogin.data.user_code === "admi") ||
        props.allowCheckin
      ) {
        history.push("/Awb/checkout");
        return;
      }
      if (
        MAWB.hawb !== "" &&
        (dataCheckin.AWB + dataCheckin.COD_AWB_SFX !==
          MAWB.first + MAWB.last + MAWB.suffix ||
          dataCheckin.HAWB !== MAWB.hawb)
      ) {
        notification.warning({
          message: "Thông báo",
          description:
            "Tài khoản này không thể thanh toán lô hàng được chọn! Vui lòng kiểm tra lại!",
          style: { marginRight: "-50%" },
        });
        return;
      } else {
        history.push("/Awb/checkout");
      }
    } else
      notification.warning({
        message: "Thông báo",
        description: "Vui lòng chọn lô hàng muốn thanh toán!",
        style: { marginRight: "-50%" },
      });
  };
  const scrollTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };
  const setMenu = async (menuId) => {
    const menuRes = await getMenuById(menuId);
    const menuParentRes = await getMenuById(menuRes.data.parent);
    const menuReference = {
      key: menuRes.data.alias,
      title: menuRes.data.name,
      menuParentTitle: menuParentRes.data.name,
      banner: menuParentRes.data.params,
      menuId: menuRes.data.id,
      menuParentId: menuRes.data.parent,
      images: menuRes.data.images,
      referenceId: menuRes.data.reference_id,
    };
    dispatch(menuItemSelected(menuReference));
    localStorage.setItem("menuItem", JSON.stringify(menuReference));
  };
  const handleLink = () => {
    if (langId === "2") {
      setMenu(267);
    } else {
      setMenu(53);
    }
    scrollTop();
    history.push(
      langId === "2"
        ? "/dich-vu/bang-gia-dich-vu"
        : "/services/handling-charges"
    );
  };

  return (
    <Row
      style={{ marginTop: 20 }}
      className="result-content-wrapper control-footer"
    >
      <Col className="awb-result-content" span={"auto"}>
        <Button style={{ width: "fit-content" }}>
          <a onClick={handleLink}>{t("tabInfo.btnHandlingCharges")}</a>
        </Button>
      </Col>
      <Col className="awb-result-content" span={"auto"}>
        <Button
          onClick={() => props.setIsShowCharges(true)}
          style={{ width: "fit-content" }}
        >
          {t("tabInfo.btnCharge")}
        </Button>
      </Col>
      {props.billType !== "H" && (
        <Col className="awb-result-content" span={"auto"}>
          <Button
            disabled={!props.ableToGetAcc}
            onClick={() => props.setVisibleModalGetAcc(true)}
            style={{ width: "fit-content" }}
          >
            {t("tabInfo.btnGetAcc")}
          </Button>
        </Col>
      )}
      {/* <Col className="awb-result-content" span={"auto"}>
        <Button style={{ width: "fit-content" }}>
          <a onClick={handlePaymentBtnClick}>Checkin</a>
        </Button>
      </Col> */}
    </Row>
  );
};
export default TabInfoControls;
