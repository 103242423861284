import { combineReducers } from "redux";
import { menuReducer } from "./menuReducer";
import { userAwbReducer } from "./userAwbReducer";
import { MAWBReducer } from "./MAWBReducer";
import feedbackReducer from "./feedbackReducer";
import { paymentReducer } from './paymentReducer';
import { esidExportReducer } from './esidExportReducer';
import { airportReducer } from "./airportReducer";
import { dgrGoodsReducer } from "./dgrGoodsReducer";
import { livAnmReducer } from "./livAnmReducer";

const appReducer = combineReducers({
  userAwb: userAwbReducer,
  menu: menuReducer,
  mawb: MAWBReducer,
  feedback: feedbackReducer,
  payment: paymentReducer,
  esidExport: esidExportReducer,
  airport: airportReducer,
  dgrGoods: dgrGoodsReducer,
  livAnms: livAnmReducer,
});

const rootReducer = (state, action) => {
  if (action.type === 'USER_LOGGEDOUT') {
    localStorage.removeItem('GetFromLocation');
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
}

export default rootReducer;
