/* eslint-disable */

import React from 'react'
import { Row, Col, Skeleton, Menu } from "antd";
import "./page-css/Guides.css";
import Banner from "../components/Banner";
import { useDispatch, useSelector } from "react-redux";
import * as Constant from "../config/Constant";
import * as API from "../config/apiConfig";
import { menuItemSelected, showLangSwitch } from "../actions/menu";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import { useEffect } from 'react';
import GuideContent from '../components/Guides/GuideContent';

function ReceiveImport(props) {
    const { menuItem, langId } = useSelector((state) => state.menu);
    const [menuItems, setMenuItems] = useState([]);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    let history = useHistory();

    useEffect(() => {
        dispatch(showLangSwitch(true));
    }, []);


    const getMenuGuides = () => {
        setLoading(true);
        API.getMenu(langId)
            .then((res) => {
                let data = [];
                res.data.data.forEach((dt) => {
                    if (langId === "2") {
                        if (dt.parent === 297) data.push(dt);
                    }
                });
                setMenuItems(data);
            })
            .catch((err) => console.log(err));
        setLoading(false);
    };
    const handleChange = (e) => {
        const menu = {
            key: e.key,
            title: e.item.props.title,
            banner: e.item.props.banner,
            menuId: e.item.props.menuid,
            menuParentId: e.item.props.menuparentid,
            menuParentTitle: e.item.props.menuparenttitle,
            images: e.item.props.images,
            referenceId: e.item.props.referenceid,
        };

        dispatch(menuItemSelected(menu));
        localStorage.setItem("menuItem", JSON.stringify(menu));
        history.push(`/thu-tuc-nhan-hang-nhap/${e.key}`);
    };
    useEffect(() => {
        getMenuGuides();
    }, [langId]);
    return (
        <Row className="guide-wrapper">
            <Banner
                title={menuItem.menuParentTitle}
                className="banner-wrapper"
                cover={`${Constant.IMAGES_URL}${menuItem.banner}`}
            />
            <Skeleton loading={loading} active={loading}>
                {menuItems.length > 0 && (
                    <>
                        <Row style={{ width: "100%" }}>
                            <Col offset={3} span={17}>
                                <Menu
                                    className="no-padding"
                                    onClick={handleChange}
                                    mode="horizontal"
                                    triggerSubMenuAction='click'
                                >
                                    {menuItems.map((item) => {
                                        return (
                                            <Menu.Item
                                                className="child-menu"
                                                key={item.alias}
                                                banner={menuItem.banner}
                                                menuparenttitle={menuItem.menuParentTitle}
                                                menuparentid={menuItem.menuParentId}
                                                menuid={item.id}
                                                images={item.images}
                                                referenceid={item.reference_id}
                                                title={item.name}
                                            >
                                                {item.name}
                                            </Menu.Item>
                                        );
                                    })}
                                </Menu>
                            </Col>
                        </Row>
                        <Row className="guide-title-wrapper">
                            <Col className="guide-title" span={"auto"}>
                                <h2>{menuItem.title}</h2>
                            </Col>
                        </Row>
                        <div className="guide-content-wrapper">
                            <div className="guide-content">
                                <GuideContent />
                            </div>
                        </div>
                    </>
                )}
            </Skeleton>
        </Row>
    )
}

export default ReceiveImport;
