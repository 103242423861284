import { Checkbox, Form, Typography } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { consolServices } from "../../../../constant/enumerations";
import { useTranslation } from "react-i18next";
import { setExpConsolRegServices } from "../../../../actions/esidExportAction";

export const ServiceRegistration = () => {
  const { t } = useTranslation("common");
  const dispatch = useDispatch();
  const esidSelected = useSelector(state => state.esidExport.esidExpSelected);
  const consolRegServices = useSelector((state) => state.esidExport.consolRegServices);
  const expConsol = useSelector((state) => state.esidExport.expConsol);

  const handleRegService = (service) => {
    const consolRegServiceLst = [...consolRegServices];
    const idx = consolRegServiceLst.findIndex(x => x.codHdlReg === service);
    if (idx > -1) {
      consolRegServiceLst.splice(idx, 1);
    } else {
      const svr = expConsol?.regisServices?.find(x => x.codHdlReg === service) || 
        { 
          esidId: esidSelected.id,
          codAwbPfx: esidSelected.codAwbPfx,
          codAwbNum: esidSelected.codAwbNum,
          codAwbSfx: esidSelected.codAwbSfx,
          codSts: 'NY',
          codHdlReg: service, 
          codHdlRegNam: t(`consolLabels.${service}`) 
        };
      consolRegServiceLst.push(svr)
    }
    dispatch(setExpConsolRegServices(consolRegServiceLst));
  }

  return (
    <div style={{ paddingInline: 5, marginTop: 10 }}>
      <Typography.Title level={5}>Đăng ký sử dụng dịch vụ</Typography.Title>
      <Form autoComplete="off">
        <div style={{ display: "flex", gap: 8, flexWrap: "wrap" }}>
          <Form.Item valuePropName="checked">
            <Checkbox
              checked={consolRegServices?.findIndex(x => x.codHdlReg === consolServices.PrintQRCode) > -1}
              onClick={() => handleRegService(consolServices.PrintQRCode)}
            >
              In số định danh hàng hóa
            </Checkbox>
          </Form.Item>
          <Form.Item valuePropName="checked">
            <Checkbox 
              checked={consolRegServices?.findIndex(x => x.codHdlReg === consolServices.FWB) > -1}
              onClick={() => handleRegService(consolServices.FWB)}
            >
              Gửi điện FWB
            </Checkbox>
          </Form.Item>
          <Form.Item valuePropName="checked">
            <Checkbox 
              checked={consolRegServices?.findIndex(x => x.codHdlReg === consolServices.FHL) > -1}
              onClick={() => handleRegService(consolServices.FHL)}
            >
              Gửi điện FHL
            </Checkbox>
          </Form.Item>
        </div>
      </Form>
      <Typography.Text strong>
        Đại lý gửi hàng/ Người gửi hàng chịu toàn bộ trách nhiệm về nội dung
        khai báo các lô hàng trên đây, đảm bảo các thông tin khai báo đúng với
        thực tế.
      </Typography.Text>
    </div>
  );
};
