import { Col, Form, Input, Row, Typography } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next';
// import { useSelector } from 'react-redux';
// import { EsidStatusEnum } from '../../../../../constant/enumerations';
export const SenderForm = ({form, readOnly}) => {
    const { t } = useTranslation("common");
    return (
        <Form 
            form={form}
            labelCol={{ span: 24 }}
            labelAlign='left'
            style={{ paddingInline: 10 }}
            autoComplete="off"
        >
            <Row gutter={[10,10]}>
                <Col xs={24} sm={24} md={10}>
                    <Form.Item
                        name="shpRegNam"
                        label={<div style={{ fontWeight: '600' }}>{t("formInfo.shipperFullName")}</div>}
                    >
                        <Input type="text" name="shpRegNam" readOnly={readOnly} />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={7}>
                    <Form.Item
                        name="shpRegTel"
                        label={t("formInfo.shipperTel")}
                    >
                        <Input type="text" name="shpRegTel" readOnly={readOnly} />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={7}>
                    <Form.Item
                        name="shpRegIdx"
                        label={t("formInfo.shipperID_Passport")}
                        // rules={[
                        //     {
                        //         pattern: '0[0-9]{11}|[A-Z][0-9]{7}',
                        //         message: `${t("formInfo.shipperID_Passport")} không hợp lệ`
                        //     },
                        // ]}
                    >
                        <Input type="text" name="shpRegIdx" readOnly={readOnly} />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item
                        name="desRmk002"
                        label={<Typography.Text strong>{t("admin.acceptance.desRmk002")}</Typography.Text>}
                        style={{marginBottom: '0 !important'}}
                        labelCol={{span: 24}}
                    >
                        <Input.TextArea autoSize readOnly={readOnly} style={{width: '100%'}} />
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    )
}
