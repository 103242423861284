import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Route, useHistory, useLocation } from "react-router-dom";
import { NotAllowed } from "./pages/NotAllowed";

export const PrivateRoute = ({ component: Component, allowedRoles, ...rest }) => {
  const history = useHistory();
  const location = useLocation();
  const { signedIn, currentUser } = useSelector((state) => state.userAwb);
  const { roles } = currentUser;
  const userRoles = allowedRoles && roles ? allowedRoles.filter((r) => roles.includes(r)) : [];

  useEffect(() => {
    if (!signedIn || (allowedRoles && userRoles.length === 0)) {
      history.push("/AwbLogin", {
        from: { pathname: location.pathname || "/" },
      });
    }
    // eslint-disable-next-line
  }, [signedIn, userRoles]);

  return (
    <Route
      {...rest}
      render={(props) => {
          if (!allowedRoles && signedIn) {
            return <Component {...props} />;
          }
          if (signedIn && userRoles.length > 0) {
            return <Component {...props} />;
          }
          return <NotAllowed />
        }
      }
    />
  );
};
