import React from 'react'
import { Card, Col, List, Row, Typography, Button, Divider } from 'antd'
import { useTranslation } from "react-i18next";
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import FadeIn from 'react-fade-in'
import useWindowSize from '../../../../../helpers/useWindowSize';
// import { useSelector } from 'react-redux';
// import { EsidStatusEnum } from '../../../../../constant/enumerations';

export const GoodsCard = ({item, handleEdit, handleDelete, disabled }) => {
    const { t } = useTranslation("common");
    const handleEditClick = () => {
        handleEdit(item)
    }
    const handleDeleteClick = () => {
        handleDelete(item);
    }

    const size = useWindowSize();
    return (
        <FadeIn>
            <List.Item style={{padding: '10px 0', height: '100%'}}>
                <Card 
                    // title={`${t("dangerousGood.formInfo.UN")}: ${item.codUid}`} 
                    key={item.key} 
                    bordered={true}
                    style={{width: '100%', border: '1px solid #bababa', borderRadius: 5, height: '100%'}}
                    
                >
                    <div style={size === 'xs' ? {
                            display: 'flex', justifyContent: 'space-between', paddingInline: 4
                        } : {
                            display: 'flex', justifyContent: 'space-between'
                        }
                    }>
                        <Typography.Title style={{ textAlign: 'left', maxWidth: '75%'}} level={4}>
                                {/* remove the dot */}
                                {`${
                                    t("dangerousGood.formInfo.UN")[t("dangerousGood.formInfo.UN").length - 1] === '.' ? 
                                    t("dangerousGood.formInfo.UN").substring(0, t("dangerousGood.formInfo.UN").length - 1) :
                                    t("dangerousGood.formInfo.UN")
                                }: ${item.codUid}`}
                        </Typography.Title>
                        {
                            !disabled && (
                                <div style={{ display: 'flex'}}>
                                    <Button type='text' onClick={handleEditClick} icon={<EditOutlined />} />
                                    <Button type='text' danger icon={<DeleteOutlined />} onClick={handleDeleteClick} />
                                </div>
                            )
                        }
                    </div>
                    <Divider style={{ marginBlock: 5, borderBottom: '1px solid rgb(0 0 0 / 15%)' }} />
                    <Row gutter={[8,8]}>
                        <Col md={24} sm={24} xs={24}>
                            <Typography.Text style={{textAlign: 'left'}} strong >
                                {item.codPsn}
                            </Typography.Text>
                        </Col>
                        <Col md={12} sm={24} xs={24}>
                            <p>
                                <Typography.Text style={{marginRight: 5}}>
                                    {`${t("dangerousGood.formInfo.clsDvs")}: `}
                                </Typography.Text>
                                {item.clsDvs}
                            </p>
                        </Col>
                        <Col md={12} sm={24} xs={24}>
                            <p>
                                <Typography.Text style={{marginRight: 5}}>
                                    {`${t("dangerousGood.formInfo.pkgGrp")}: `}
                                </Typography.Text>
                                {item.dgrPkg}
                            </p>
                        </Col>
                        <Col md={24} sm={24} xs={24}>
                            <p>
                                <Typography.Text style={{marginRight: 5}}>
                                    {`${t("dangerousGood.formInfo.qtyTypePkg")}: `}
                                </Typography.Text>
                                {item.qtyTypPkg}
                            </p>
                        </Col>
                        <Col md={24} sm={24} xs={24}>
                            <p>
                                <Typography.Text>
                                    {`${t("dangerousGood.formInfo.pkgInst")}: `}
                                </Typography.Text>
                                {item.pkgInst}
                            </p>
                        </Col>
                        <Col md={24} sm={24} xs={24}>
                            <p>
                                <Typography.Text>
                                    {`${t("dangerousGood.formInfo.author")}: `}
                                </Typography.Text>
                                {item.dgrAuth}
                            </p>
                        </Col>
                    </Row>
                </Card>
            </List.Item>
        </FadeIn>
    )
}
