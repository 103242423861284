import { Col, Row, Typography } from 'antd';
import React from 'react'

const { Text } = Typography;
export class DmsSizeToPrint extends React.PureComponent {

    render() {
        const dmsSizes = this.props.dataExport.dmsSizes;
        const wgt = this.props.dataExport.wgt;
        const { shpRegNam, acpStfNam } = this.props.dataExport;
        // const defaultColumns = [
        //     {
        //       title: null,
        //       dataIndex: 'codSeqNum',
        //       key: 'codSeqNum',
        //       width: '15%'
        //     },
        //     {
        //       title: null,
        //       dataIndex: 'qtyDimLen',
        //       key: 'qtyDimLen',
        //       editable: true,
        //       width: '15%'
        //     },
        //     {
        //       title: null,
        //       dataIndex: 'qtyDimWid',
        //       key: 'qtyDimWid',
        //       editable: true,
        //       width: '15%'
        //     },
        //     {
        //       title: null,
        //       dataIndex: 'qtyDimHgt',
        //       key: 'qtyDimHgt',
        //       editable: true,
        //       width: '15%'
        //     },
        //     {
        //       title: null,
        //       dataIndex: 'qtyDimPcs',
        //       key: 'qtyDimPcs',
        //       editable: true,
        //       width: '20%'
        //     },
        //     {
        //       title: null,
        //       dataIndex: 'qtyDimWgt',
        //       key: 'qtyDimWgt',
        //       editable: true,
        //       width: '20%',
        //     },
        // ];
        return (
          <Row gutter={10}>
            <Col span={4} >
              <div style={{ display: 'flex', flexDirection: 'column', gap: 10, paddingTop: 60, alignItems: 'center'}}>
                <Text strong>
                  {`GW: ${wgt.wgtGrs}`}
                </Text>
                <Text strong>
                  {`CW: ${wgt.volWgt > wgt.wgtGrs ? wgt.volWgt : wgt.wgtGrs}`}
                </Text>
              </div>
            </Col>
            <Col span={20}>
              <div style={{ display: "flex", justifyContent: "center", flexDirection: 'column', paddingBlock: 20 }}>
                  <Typography.Title style={{ textAlign: 'center' }} level={4}>
                      ATTACHED LIST DIMS
                  </Typography.Title>
                  <div className='ant-table-wrapper'>
                    <div className='ant-table ant-table-bordered'>
                      <div className='ant-table-container'>
                        <div className='ant-table-content'>
                          <table style={{ tableLayout: 'auto' }}>
                            <thead className='ant-table-thead'>
                              <tr>
                                {Array(6).fill('').map(() => (<th className='ant-table-cell'></th>))}
                              </tr>
                            </thead>
                            <tbody className='ant-table-tbody'>
                              {dmsSizes?.map((dim, idx) => (
                                <tr key={idx+1} className='ant-table-row ant-table-row-level-0'>
                                  <td className='ant-table-cell'>{idx+1}</td>
                                  <td className='ant-table-cell'>{dim.qtyDimLen}</td>
                                  <td className='ant-table-cell'>{dim.qtyDimWid}</td>
                                  <td className='ant-table-cell'>{dim.qtyDimHgt}</td>
                                  <td className='ant-table-cell'>{dim.qtyDimPcs}</td>
                                  <td className='ant-table-cell'>{dim.qtyDimWgt}</td>
                                </tr>
                              ))}
                              <tr style={{ backgroundColor: '#fafafa' }}>
                                <td className='ant-table-cell'></td>
                                <td colSpan={3} className='ant-table-cell' style={{paddingTop: '10px', paddingBottom: '10px'}}>
                                  <Text strong style={{ textAlign: 'center' }}>TOTAL CARTON</Text>
                                </td>
                                <td className='ant-table-cell' style={{ textAlign: 'center', fontWeight: 'bold' }}>
                                  {dmsSizes?.length > 0 ? dmsSizes.map(dim => dim.qtyDimPcs).reduce((total, qty) => total + qty) : 0}
                                </td>
                                <td className='ant-table-cell'></td>
                              </tr>
                              <tr style={{ backgroundColor: '#fafafa'}}>
                                <td colSpan={4} className='ant-table-cell'></td>
                                <td className='ant-table-cell' style={{paddingTop: '10px', paddingBottom: '10px'}}>
                                  <Text strong style={{ textAlign: 'center' }} >TOTAL</Text>
                                </td>
                                <td className='ant-table-cell' style={{ textAlign: 'center', fontWeight: 'bold' }}>{wgt.volWgt}</td>
                              </tr>
                            </tbody>
                            {/* <tfoot className='ant-table-summary'>
                              <tr style={{ backgroundColor: '#fafafa' }}>
                                <td className='ant-table-cell'></td>
                                <td colSpan={3} className='ant-table-cell'>
                                  <Text strong style={{ textAlign: 'center' }}>TOTAL CARTON</Text>
                                </td>
                                <td className='ant-table-cell' style={{ textAlign: 'center' }}>197</td>
                                <td className='ant-table-cell'></td>
                              </tr>
                              <tr style={{ backgroundColor: '#fafafa' }}>
                                <td colSpan={4} className='ant-table-cell'></td>
                                <td className='ant-table-cell'>
                                  <Text strong style={{ textAlign: 'center' }}>TOTAL CARTON</Text>
                                </td>
                                <td className='ant-table-cell' style={{ textAlign: 'center' }}>962</td>
                              </tr>
                            </tfoot> */}
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <Table
                      columns={defaultColumns}
                      dataSource={dmsSizes.sort((a,b) => a.codSeqNum - b.codSeqNum)}
                      bordered
                      pagination={false}
                      summary={(pageData) => {
                          let totalCarton = 0;
                          let total = 0;

                          pageData.forEach(({  qtyDimPcs, qtyDimWgt }) => {
                              totalCarton += qtyDimPcs;
                              total += qtyDimWgt;
                          });

                          return (
                              <>
                                <Table.Summary.Row style={{ backgroundColor: '#fafafa' }}>
                                    <Table.Summary.Cell index={0} colSpan={1}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={1} colSpan={3} align='center'>
                                        <Text strong>TOTAL CARTON</Text>
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell index={2} align='center' colSpan={1}>
                                        <Text strong>{totalCarton}</Text>
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell index={4} colSpan={1}></Table.Summary.Cell>
                                </Table.Summary.Row>
                                <Table.Summary.Row style={{ backgroundColor: '#fafafa' }}>
                                    <Table.Summary.Cell index={0} colSpan={4}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={1} colSpan={1}>
                                      <Text strong>TOTAL</Text>
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell index={2} align='center' colSpan={1}>
                                      <Text strong>{total}</Text>
                                    </Table.Summary.Cell>
                                </Table.Summary.Row>
                              </>
                            );
                      }}
                  /> */}
              </div>
            </Col>
            <Col span={24}>
              <div style={{ display: 'flex', justifyContent: 'space-around', marginTop: 10, paddingInline: 20 }}>
                  <div style={{ display: 'flex', flexDirection: 'column',justifyContent: 'center' }}>
                    <Text strong>NGƯỜI GỬI HÀNG</Text>
                    <Text style={{ marginTop: '60px' }}>{shpRegNam}</Text>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column',justifyContent: 'center' }}>
                    <Text strong>TIẾP NHẬN</Text>
                    <Text style={{ marginTop: '60px' }}>{acpStfNam}</Text>
                  </div>
              </div>
            </Col>
          </Row>
        )
    }
}