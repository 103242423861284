import { Checkbox, Col, Row, Typography } from "antd";
import React from "react";

export class ExpCertLivAnmPrint extends React.PureComponent {
  // eslint-disable-next-line
  constructor(props) {
    super(props);
  }
  render() {

    const data = this.props?.dataExport;

    // console.log(this.props.dataExport);

    return (
      <div style={{ display: "flex", justifyContent: "center", fontFamily: "sans-serif" }}>
        <div className="awb-tab-content" style={{ width: "770px" }}>
          <Row>
            <br></br>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                paddingTop: "10px",
              }}
            >
              <p
                style={{
                  marginBottom: "20px",
                  fontWeight: "600",
                  fontSize: "18px",
                  textAlign: "center",
                }}
              >
                SHIPPER'S CERTIFICATION EXAMPLE (Front)
              </p>
            </div>
          </Row>
          <div style={{ border: "1px solid" }}>
            <Row style={{ paddingTop: "10px", paddingBottom: "5px" }}>
              <Col span={5} />
              <Col
                span={14}
                style={{
                  width: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <p
                    className="item-name"
                    style={{
                      fontWeight: 600,
                      fontSize: "16px",
                      textAlign: "center",
                      lineHeight: "12px",
                    }}
                  >
                    SHIPPER'S CERTICATION FOR LIVE ANIMALS
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "flex-end",
                    paddingRight: "80px",
                  }}
                >
                  <p
                    className="item-name"
                    style={{
                      fontStyle: "italic",
                      lineHeight: "12px",
                      fontSize: "12px",
                    }}
                  >
                    (to be completed in duplicate)
                  </p>
                </div>
              </Col>
              <Col span={5} />
            </Row>
            <Row
              style={{
                paddingLeft: "5px",
                paddingRight: "5px",
              }}
            >
              <p className="item-name" style={{  lineHeight: "5px" }}>
                This is to certify that(check appropriate box):
              </p>
            </Row>
            <Row
              style={{
                paddingLeft: "5px",
                paddingRight: "5px",
              }}
            >
              <Checkbox checked={data.iatA001 === "Y" ? true : false}>
                <Typography.Text className="item-name">
                  The animals contained in this cinsignment are in suitable
                  condition for air trnaport and have been describe and packaged
                  in compliance with the current edition of the IATA Live
                  Animals Regulations and all apllicate operator and government
                  regulations. All required advance arrangments have been
                  completed and the animals(s) is (are) in good health an
                  condition.
                </Typography.Text>
              </Checkbox>
            </Row>
            <Row style={{ paddingLeft: "5px", paddingRight: "5px" }}>
              <Checkbox checked={data.iatA002 === "Y" ? true : false}>
                <Typography.Text className="item-name">
                  Animals are wild caught.
                </Typography.Text>
              </Checkbox>
            </Row>
            <Row style={{ paddingLeft: "5px", paddingRight: "5px" }}>
              <Checkbox checked={data.iatA003 === "Y" ? true : false}>
                <Typography.Text className="item-name">
                  Animals taken from the wild for shipment have been
                  appropriately acclimatised.
                </Typography.Text>
              </Checkbox>
            </Row>
            <Row
              style={{
                paddingLeft: "30px",
                paddingRight: "5px",
              }}
            >
              <Typography.Text className="item-name">
                This consignment includes species as described in the Convention
                on International Trade in Endangered Species of Wild Fauna and
                Flora (CITES). Apllicable permits/certificates are attached to
                the air waybill.
              </Typography.Text>
            </Row>
            <div
              style={{
                paddingLeft: "5px",
                paddingRight: "5px",
              }}
            >
              <Checkbox checked={data.iatA004 === "Y" ? true : false}> 
                <Typography.Text className="item-name">
                  This consignment includes species as described in order
                  applicable national legislation.
                </Typography.Text>
              </Checkbox>
            </div>
            <Row
              style={{
                paddingLeft: "5px",
                paddingRight: "5px",
              }}
            >
              <Checkbox checked={data.iatA005 === "Y" ? true : false}>
                <Typography.Text
                  className="item-name"
                  style={{
                    lineHeight: "14px",
                  }}
                >
                  In the case of reptiles and amphibians, the animals contained
                  in this shipment are healthy and they have been examined prior
                  to shipment and are free of any apparent injury and readily
                  recognizable diseases. They are also free of external parastic
                  infestation, including mites, ticks and leeches, that can
                  readily be seen under normal lighting conditions.
                </Typography.Text>
              </Checkbox>
            </Row>
            <Row style={{ paddingTop: "5px", paddingLeft: "5px", paddingRight: "5px" }}>
              <Typography.Text className="item-name" style={{ lineHeight: "14px" }}>
                The shipper accepts that operators will not be liable for any
                loss, damage or expense arising from death due to natural
                causes, or death or injury of any animal caused by the conduct
                or acts of the live animal itself or of other animals, In no
                event will operator be liable for death or injury to an animal
                attendant caused or contributed to by the condition, conduct or
                acts of animals.
              </Typography.Text>
            </Row>

            {/* map */}
            <Row style={{ borderTop: "1px solid", height: "65px" }}>
              <Col span={5} style={{ borderRight: "1px solid" }}>
                <div
                  style={{
                    paddingLeft: "10px",
                    paddingRight: "10px",
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography.Text
                    style={{
                      fontWeight: 600,
                      fontSize: "12px",
                    }}
                  >
                    Number of Package(s)
                  </Typography.Text>
                </div>
              </Col>
              <Col
                span={9}
                style={{
                  borderRight: "1px solid",
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "65%",
                  }}
                >
                  <Typography.Text
                    style={{
                      fontWeight: 600,
                      fontSize: "12px",
                      textAlign: "center",
                      lineHeight: "101%"
                    }}
                  >
                    Species-Specific Container Requirement Number (see IATA Live
                    Animals Regulations Chapters 8 and 8)
                  </Typography.Text>
                </div>
              </Col>
              <Col
                span={10}
                style={{
                  height: "100%",
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <Row
                  style={{
                    paddingLeft: "10px",
                    paddingRight: "10px",
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography.Text
                    style={{
                      fontWeight: 600,
                      fontSize: "12px",
                      textAlign: "center"
                    }}
                  >
                    Species (description and names - scientific and common) and
                    Quantity of Animals
                  </Typography.Text>
                </Row>
                <Row style={{ borderTop: "1px solid" }}>
                  <Col
                    span={12}
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRight: "1px solid",
                    }}
                  >
                    <Typography.Text
                      style={{ fontSize: "12px", textAlign: "center" }}
                    >
                      Description and Names
                    </Typography.Text>
                  </Col>
                  <Col
                    span={12}
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography.Text
                      style={{ fontSize: "12px", textAlign: "center" }}
                    >
                      Quantity
                    </Typography.Text>
                  </Col>
                </Row>
              </Col>
            </Row>

            {data.certDtl?.map((item) => {
              return (
                <Row
                  key={item.id}
                  style={{ borderTop: "1px solid", height: "30px" }}
                >
                  <Col
                    span={5}
                    style={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRight: "1px solid",
                    }}
                  >
                    <Typography.Text style={{fontSize: "12px"}}>{item.numOfPkg}</Typography.Text>
                  </Col>
                  <Col
                    span={9}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRight: "1px solid",
                    }}
                  >
                    <Typography.Text style={{fontSize: "12px"}}>{item.specSpecConReq}</Typography.Text>
                  </Col>
                  <Col
                    span={10}
                    style={{
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    {/* <p>
                      {item.specSpecConReq}-{item.qtyAvi}
                    </p> */}
                    <Row
                      style={{
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      <Col
                        span={12}
                        style={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRight: "1px solid",
                        }}
                      >
                        <Typography.Text style={{fontSize: "12px"}}>{item.specDesNam}</Typography.Text>
                      </Col>
                      <Col
                        span={12}
                        style={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Typography.Text style={{fontSize: "12px"}}>{item.qtyAvi}</Typography.Text>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              );
            })}

            <Row
              style={{
                borderTop: "1px solid",
                borderBottom: "1px solid",
              }}
            >
              <Col
                span={14}
                style={{ borderRight: "1px solid", padding: "5px" }}
              >
                <p className="item-name">
                  Name/Title of Signatory................................................................................
                </p>
                <Row>
                  <Col span={12}>
                    <p className="item-name">
                      Place and Date...................................
                    </p>
                  </Col>
                  <Col span={12}>
                    <p className="item-name">
                      Year/Month/Day...................................
                    </p>
                  </Col>
                </Row>
                <p className="item-name">
                  Signature.....................................................................................................
                </p>
                <p className="item-name">
                  ..............................................
                  (See reverse side for special conditions)
                </p>
              </Col>
              <Col
                span={10}
                style={{
                  padding: "5px",
                }}
              >
                <p className="item-name" style={{ lineHeight: "101%" }}>
                  Shippers failure to comply in all respects with the applicable
                  IATA Live Animals Regulations and any other international
                  and/or national government regulations, maybe in breach of
                  applicable law and subject to legal penalties. (Refer to
                  Chapter 1, Section 1.2.)
                </p>
              </Col>
            </Row>
            <Row>
              <Col
                span={14}
                style={{ 
                  padding: "5px", 
                  borderRight: "1px solid", 
                  display: "flex",
                  flexDirection: "column", 
                }}
              >
                <Typography.Text className="item-name">
                  Air Waybill No.{" "}
                </Typography.Text>
                <Typography.Text className="item-name" style={{ fontWeight: 600 }}>
                  {data.codAwbPfx}-{data.codAwbNum}
                </Typography.Text>
              </Col>
              <Col
                span={5}
                style={{
                  padding: "5px",
                  borderRight: "1px solid",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography.Text className="item-name">Airport of Departure</Typography.Text>
                <Typography.Text
                  className="item-name" style={{ fontWeight: "bold", textAlign: "center" }}
                >
                  {data.codOri}
                </Typography.Text>
              </Col>
              <Col
                span={5}
                style={{
                  padding: "5px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography.Text className="item-name">Airport of Destination</Typography.Text>
                <Typography.Text
                 className="item-name" style={{ fontWeight: "bold", textAlign: "center" }}
                >
                  {data.codFds}
                </Typography.Text>
              </Col>
            </Row>
          </div>
          <Row style={{ paddingTop: "10px" }}>
            <div
              style={{
                display: "flex",
                width: "100%",
                flexDirection: "column",
              }}
            >
              <p className="item-name" style={{ fontWeight: 600, fontStyle: "italic"}}>Note:</p>
              <p className="item-name" style={{ fontStyle: "italic" }}>
                Recommended size: ISO Standard A4
              </p>
            </div>
          </Row>
          <Row style={{ paddingTop: "100px" }}>
            <div
              style={{
                display: "flex",
                width: "100%",
                flexDirection: "column",
              }}
            >
              <Typography.Text className="item-name">EDITION 49, JANUARY 2023</Typography.Text>
            </div>
          </Row>
        </div>
      </div>
    );
  }
}
