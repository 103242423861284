import React from 'react'
import { Col, Row } from 'antd'
import { PersonalForm } from './PersonalForm'
import { CodCtcTypEnum } from "../../../../../constant/enumerations";
import { AwbInformationForm } from '../AWBInfo/AwbInformationForm';
import { HandlingInfomationForm } from './HandlingInfomationForm';
import FadeIn from 'react-fade-in';
import { SenderForm } from './SenderForm';
export const GeneralInfomationContainer = ({ forms, isEsidExpFinalized }) => {
  return (
    <Row gutter={[5, 5]} style={{width: '100%', height: '100%', padding: '5px 0 20px', marginInline: 0}}>
        <Col style={{width: '100%'}} lg={12} md={12} sm={24}>
          <FadeIn>
            <PersonalForm required={true} title={CodCtcTypEnum.Shipper} name={'shipper'} form={forms.shipperForm} readOnly={isEsidExpFinalized}/>
            <PersonalForm title={CodCtcTypEnum.Agent} name={'agent'} form={forms.agentForm} readOnly={isEsidExpFinalized}/>
          </FadeIn>
        </Col>
        <Col style={{width: '100%'}} lg={12} md={12} sm={24}>
          <FadeIn transitionDuration={600}>
            <PersonalForm required={true} title={CodCtcTypEnum.Consignee} name={'consignee'} form={forms.consigneeForm} readOnly={isEsidExpFinalized}/>
            <PersonalForm title={CodCtcTypEnum.Notify} name={'notify'} form={forms.notifyForm} readOnly={isEsidExpFinalized}/>
          </FadeIn>
        </Col>
        <FadeIn >
          <Col style={{width: '100%'}} lg={{span: 24}} md={24} sm={24}>
            <AwbInformationForm form={forms.airWaybillForm} readOnly={isEsidExpFinalized}/>
          </Col>
          <Col style={{width: '100%'}} lg={{span: 24}} md={24} sm={24}>
            <HandlingInfomationForm form={forms.handlingInfoForm} readOnly={isEsidExpFinalized}/>
          </Col>
        </FadeIn>
        <Col style={{width: '100%'}} lg={24} md={24} sm={24}>
          <FadeIn transitionDuration={600}>
            <SenderForm form={forms.senderForm} readOnly={isEsidExpFinalized}/>
          </FadeIn>
        </Col>

    </Row> 
  )
}
