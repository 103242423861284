import React, { useState } from 'react'
import {Button, Typography} from 'antd'
import { useTranslation } from "react-i18next";
import { PlusOutlined } from '@ant-design/icons';
import { useForm } from 'antd/lib/form/Form';
import { useDispatch, useSelector } from 'react-redux';
import { setDgrSelectedToEdit } from '../../../../actions/dgrGoodsAction';
import { useEffect } from 'react';
import { AddDangerousGoodsModal } from './AddDangerousGoodsModal';
import { addDgrType } from '../../../../constant/enumerations';

const GoodsTableHead = ({dataSource, setDataSource, modalVisible, setModalVisible}) => {
  const { t } = useTranslation("common");
  const [addForm] = useForm();
  const [nextKey, setNextKey] = useState(dataSource.length > 0 && parseInt(dataSource[dataSource.length - 1].key) + 1)
  const [editingKey, setEditingKey] = useState('');
  const dgrSelectedValue = useSelector((state) => state.dgrGoods.dgrSelectedValue);
  const isDiableEditDrgGoodFormDecl = useSelector((state) => state.dgrGoods.isDisableEditDrgGoodFormDecl);
  const dispatch = useDispatch();
  // const uids = useSelector((state) => state.dgrGoods.uids);
  
  const add = (formValue, dgrTypes) => {
    // const uId = uids?.find(x => x.dgrRegSeq === formValue.dgrRegSeq);
    const newItem = {
      key: nextKey,
      // ...uId,
      ...formValue,
      codPsn: formValue.codPsn,
      codTyp: addDgrType.DGR,
      dgrTypes: [...dgrTypes]
    }
    // console.log([...dataSource,newItem]);
    setDataSource([...dataSource,newItem]);
    dispatch(setDgrSelectedToEdit(null));
  }

  const update = (newData) => {
    let i = dataSource.findIndex((data) => data.id === newData.id);
    // const uId = uids?.find(x => x.dgrRegSeq === newData.dgrRegSeq);
    // dataSource[i]={...newData, codUid: uId.codUid};
    dataSource[i] = newData;
    setDataSource([...dataSource]);
    dispatch(setDgrSelectedToEdit(null));
  }
  
  useEffect(() => {
    const dgrItems = dgrSelectedValue && dgrSelectedValue.dgrItems ? dgrSelectedValue.dgrItems.map((dgrItem, index) => ({
      ...dgrItem,
      key: index
    })) : [];
    setDataSource(dgrItems);
    // eslint-disable-next-line
  },[dgrSelectedValue]);
  
  useEffect(() => {
    dataSource.length > 0 && setNextKey(dataSource[dataSource.length - 1].key + 1);
  },[dataSource])

  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Typography.Text strong>{t("dangerousGood.tableInfo.title")}</Typography.Text>
        <Button
          onClick={() => {
            setEditingKey(false)
            setModalVisible(true);
            addForm.resetFields();
          }}
          icon={<PlusOutlined />}
          disabled={dgrSelectedValue?.id && isDiableEditDrgGoodFormDecl}
          type='primary'
        >
          {`${t("addBtn")}`}
        </Button>
      </div>
      <AddDangerousGoodsModal
        editingKey={editingKey}
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        form={addForm}
        handleAdd={add}
        handleUpdate={update}
        typeAdd={addDgrType.DGR}
      />
    </>
  )
}

export default GoodsTableHead