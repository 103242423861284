import React from "react";
import { useSelector } from "react-redux";

function NapasPaymentPage() {
  const { info } = useSelector((state) => state.payment);
  React.useEffect(() => {
    if (info !== undefined) {
      console.log(info.FLG_IMP_EXP);
      const script = document.createElement("script");
      script.src =
        "https://dps.napas.com.vn/api/restjs/resources/js/napas.hostedform.min.js";
      script.type = "text/javascript";
      script.id = "napas-widget-script";
      script.setAttribute("merchantId", /*  info.merchantId */ "TCS");
      script.setAttribute("clientIP", /* info.clientId */ "192.168.1.1");
      script.setAttribute("deviceId", /* info.deviceId */ "0123456789");
      script.setAttribute("environment", "WebApp");
      script.setAttribute("cardScheme", /* info.cardScheme */ "AtmCard");
      script.setAttribute("enable3DSecure", "false");
      script.setAttribute("orderId", info.order.id);
      script.setAttribute("dataKey", info.dataKey);
      script.setAttribute("napasKey", info.napasKey);
      script.setAttribute("apiOperation", "PAY");
      script.setAttribute("orderAmount", info.order.amount);
      script.setAttribute("orderCurrency", info.order.currency);
      script.setAttribute("orderReference", info.orderRef);
      script.setAttribute("channel", "6014");
      script.setAttribute("sourceOfFundsType", "CARD");

      //   script.setAttribute("merchantId", /*  info.merchantId */ "TCS");
      //   script.setAttribute("clientIP", /* info.clientId */ "192.168.1.1");
      //   script.setAttribute("deviceId", /* info.deviceId */ "0123456789");
      //   script.setAttribute("environment", "WebApp");
      //   script.setAttribute("cardScheme", /* info.cardScheme */ "AtmCard");
      //   script.setAttribute("enable3DSecure", "false");
      //   script.setAttribute("orderId", "a5875-548554-45648sd-sadect52");
      //   script.setAttribute(
      //     "dataKey",
      //     "XxHnHSmY9qJQ+GGuI4TSvaU8RgvTJXR0sYCqS8CSqe0PxDhSGoFIvrGua+KVHbG637SAoAThYPomBkjaiCj+WIQmdL2R/Vy3n9F+XlpetvpyXeQJTDdb4SsNRtxggQlrFogYE12Z1O8Ul9d9S/pVCP/dY72Yf3D2DyTRDq9S4Z550No1fGLuLRnpekLaF+U/CdV1DN0+Prd/4864ctTO6dO/zkFh53GFhY+L6smUyoE0eDDBVKFyFUci3MoXeqYg7hznsvPh6bCKdl+fskdEdZYn7k7RVHzCI1avi3cjHQ2PHJKV/ghqICUukKmwYbQ8hOCXSJ/N7LOTZExs95WWteyLrjOTATUHskJeNHU1trELWc8ZUWsPfm6GN7nNsqLquyLDWULXpqqh9SQZCctpqukR4s0WRX8Wo/ES0++y1LMnZkqrHMhDrmmw6Z0ZT8vq"
      //   );
      //   script.setAttribute(
      //     "napasKey",
      //     "MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEArbP6iFl0Z9pDHv5ni/fhl4ME6AfP16E5zmrHYftaBlcvq1bWRwk1TH23OrYQ6n6jEGIbmrVXzIM00lk723/ZHtvbyZld8r5TcEh5M+h/jIu3/J9gqNYGTo9jLJ0DvRdSfzbDWJqPImPfcGJDRPTrEtckqmd8HvkU6J3MCPrg0IOGTYplUmw6DZ25g1SpBSb1CAWGqP36nqyxNZ4hNJ08agHQbRc/ICHUb/8+/UjDETX96SYVn+GBKbrMM/NcgiJV7UXbEmQ9OEiM3BbI5srhCOt0oYoCp0sJcpLcLbRlpdC7/nV44KPty1rkZ32KlBufz3UVggXpHPz0k0yxYTvl/wIDAQAB:MIICtTCCAZ0CBgFoko89+DANBgkqhkiG9w0BAQUFADAeMRwwGgYDVQQDExNUZXN0IENBIENlcnRpZmljYXRlMB4XDTE5MDEyODAzNDQ0N1oXDTE5MDEyOTAzNDQ0N1owHjEcMBoGA1UEAxMTVGVzdCBDQSBDZXJ0aWZpY2F0ZTCCASIwDQYJKoZIhvcNAQEBBQADggEPADCCAQoCggEBAK2z+ohZdGfaQx7+Z4v34ZeDBOgHz9ehOc5qx2H7WgZXL6tW1kcJNUx9tzq2EOp+oxBiG5q1V8yDNNJZO9t/2R7b28mZXfK+U3BIeTPof4yLt/yfYKjWBk6PYyydA70XUn82w1iajyJj33BiQ0T06xLXJKpnfB75FOidzAj64NCDhk2KZVJsOg2duYNUqQUm9QgFhqj9+p6ssTWeITSdPGoB0G0XPyAh1G//Pv1IwxE1/ekmFZ/hgSm6zDPzXIIiVe1F2xJkPThIjNwWyObK4QjrdKGKAqdLCXKS3C20ZaXQu/51eOCj7cta5Gd9ipQbn891FYIF6Rz89JNMsWE75f8CAwEAATANBgkqhkiG9w0BAQUFAAOCAQEAFXK48p71S87EmrnCm5Yvv42Oxzh0B18/q4jn891xS1abFRkW2jdCvpc3IUQL6gy+JFQcY2NSaLhIYgBafmcngiBFt4kkTqUuwSdIWudl3jYkO58SOYKdxW8jbXM5KwTujpb0gYBpf1u5828RmEq6YEog/yx/hYQOFQlfIBBZFNmUJ7U5TDCFL2wT5MqPg2cfb1DirveD3sLSIUc90IJM3eUXfzXqkwdrCKDZRSuV3TMHChi1IRio2fg7zesi9HliFueaekkvynnwXoG41LKaMSBMM/Mdrb2tm29jVYnlYJ8Coj+dOjWhU2y83dFi5DGr8a3ftWOc+rEz2zlOUKRF7Q=="
      //   );
      //   script.setAttribute("apiOperation", "PAY");
      //   script.setAttribute("orderAmount", "200000");
      //   script.setAttribute("orderCurrency", "VND");
      //   script.setAttribute(
      //     "orderReference",
      //     "Thanh toan "+
      //   );
      //   script.setAttribute("channel", "6014");
      //   script.setAttribute("sourceOfFundsType", "CARD");

      script.async = true;
      const container = document.querySelector("#napas-widget-container");

      container.parentNode.insertBefore(script, container.nextSibling);
      return () => {
        container.parentNode.removeChild(script);
      };
    }
  }, [info]);
  return (
    <>
      <form
        id="merchant-form"
        action={
          info?.FLG_IMP_EXP === 'E' ?
          "https://www.tcs.com.vn/api/EsidWebChk/callbackLink" : (
          info.isHawb
            ? "https://www.tcs.com.vn/api/redirectLinkHAWB"
            : "https://www.tcs.com.vn/api/redirectLink"
          )
        }
        method="POST"
        encType="application/x-www-form-urlencoded"
      ></form>
      <div id="napas-widget-container"></div>
    </>
  );
}

export default NapasPaymentPage;
