/* eslint-disable */

import { Col, Descriptions, Row, Table } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const AirlinesReportResult = (props) => {
  const { t } = useTranslation("common");
  const columnsGroup = {
    partA: [
      {
        title: t("airlines.A"),
        dataIndex: "parent",
        key: "parent",
        className: "table-report-header",
        children: [
          {
            title: t("airlines.fromSchedule"),
            dataIndex: "FA01_COD_ORI",
            key: "FA01_COD_ORI",
            className: "head_tb_repo",
          },
        ],
      },
      {
        title: t("airlines.differenceDelivery"),
        dataIndex: "parent",
        key: "parent",
        className: "table-report-header",
        children: [
          {
            title: t("airlines.MNF"),
            dataIndex: "FA02_NUM_OF_MNF",
            key: "FA02_NUM_OF_MNF",
            className: "head_tb_repo",
          },
          {
            title: t("airlines.handling"),
            dataIndex: "FA03_MNF_IRR",
            key: "FA03_MNF_IRR",
            className: "head_tb_repo",
          },
        ],
      },
    ],
    partB: [
      {
        title: t("airlines.B"),
        dataIndex: "parent",
        key: "parent",

        className: "table-report-header",
        children: [
          {
            title: t("agentReport.MAWB"),
            dataIndex: "FB01_MAWB_NO",
            key: "FB01_MAWB_NO",
            className: "head_tb_repo",
          },
        ],
      },
      {
        title: t("airlines.DifferrenMAWB"),
        dataIndex: "parent",
        key: "parent",
        className: "table-report-header",
        children: [
          {
            title: t("airlines.from"),
            dataIndex: "FB02_ORG",
            key: "FB02_ORG",
            className: "head_tb_repo",
          },
          {
            title: t("airlines.to"),
            dataIndex: "FB03_DES",
            key: "FB03_DES",
            className: "head_tb_repo",
          },
          {
            title: t("agentReport.Pieces"),
            dataIndex: "FB04_PCS",
            key: "FB04_PCS",
            className: "head_tb_repo",
          },
          {
            title: t("agentReport.GrossWeight"),
            dataIndex: "FB05_WEIGHT",
            key: "FB05_WEIGHT",
            className: "head_tb_repo",
          },
          {
            title: t("agentReport.NatureGoods"),
            dataIndex: "FB06_NATURE_OF_GOODS",
            key: "FB06_NATURE_OF_GOODS",
            className: "head_tb_repo",
          },
          {
            title: t("airlines.handling"),
            dataIndex: "FB07_IFF_INF",
            key: "FB07_IFF_INF",
            className: "head_tb_repo",
          },
        ],
      },
    ],
    partC: [
      {
        title: t("airlines.C"),
        dataIndex: "parent",
        key: "parent",

        className: "table-report-header",
        children: [
          {
            title: t("agentReport.MAWB"),
            dataIndex: "FC01_MAWB_NO",
            key: "FC01_MAWB_NO",
            className: "head_tb_repo",
          },
        ],
      },
      {
        title: t("airlines.differenceStatus"),
        dataIndex: "parent",
        key: "parent",
        className: "table-report-header",
        children: [
          {
            title: t("airlines.from"),
            dataIndex: "FC02_ORG",
            key: "FC02_ORG",
            className: "head_tb_repo",
          },
          {
            title: t("airlines.to"),
            dataIndex: "FC03_DES",
            key: "FC03_DES",
            className: "head_tb_repo",
          },
          {
            title: t("agentReport.Pieces"),
            dataIndex: "FC04_PCS",
            key: "FC04_PCS",
            className: "head_tb_repo",
          },
          {
            title: t("agentReport.GrossWeight"),
            dataIndex: "FC05_WEIGHT",
            key: "FC05_WEIGHT",
            className: "head_tb_repo",
          },
          {
            title: t("agentReport.NatureGoods"),
            dataIndex: "FC06_NATURE_OF_GOODS",
            key: "FC06_NATURE_OF_GOODS",
            className: "head_tb_repo",
          },
          {
            title: t("airlines.handling"),
            dataIndex: "FC07_IRR_INF",
            key: "FC07_IRR_INF",
            className: "head_tb_repo",
          },
        ],
      },
    ],
  };
  const [document, setDocument] = useState();
  const [checker, setChecker] = useState();
  const [csImport, setCsImport] = useState();
  const [endTime, setEndTime] = useState();
  useEffect(() => {
    props.dataPartD.map((dt) => {
      if (dt.FD01_CHECK_TYPE === "OAD" && dt.FD04_SEQUENCE === "2")
        setDocument(dt.FD02_USER_CHECK);
      if (dt.FD01_CHECK_TYPE === "OAW" && dt.FD04_SEQUENCE === "2")
        setChecker(dt.FD02_USER_CHECK);
      if (dt.FD01_CHECK_TYPE === "COD" && dt.FD04_SEQUENCE === "1")
        setCsImport(dt.FD02_USER_CHECK);
      if (dt.FD01_CHECK_TYPE === "OAW" && dt.FD04_SEQUENCE === "1")
        setEndTime(dt.FD02_USER_CHECK);
    });
  });
  return (
    <>
      <Row className="result-content-wrapper">
        <Col span={24} className="result-content">
          <Table
            className="table-report"
            dataSource={props.dataPartA}
            columns={columnsGroup.partA}
            bordered
            // scroll={{ x: 1300 }}
            title={() =>
              `${t("CutOffTime.fightNum")} ${props.flightNo} ${t('agentReport.Date')}: ${props.flightDate}`
            }
            pagination={false}
            footer={() => {
              "&nbsp;";
            }}
          />
        </Col>
      </Row>
      <Row className="result-content-wrapper">
        <Col span={24} className="result-content">
          <Table
            className="table-report"
            dataSource={props.dataPartB}
            columns={columnsGroup.partB}
            bordered
            // scroll={{ x: 1300 }}
            pagination={false}
            footer={() => {
              "&nbsp;";
            }}
          />
        </Col>
      </Row>
      <Row className="result-content-wrapper">
        <Col span={24} className="result-content">
          <Table
            className="table-report"
            dataSource={props.dataPartC}
            columns={columnsGroup.partC}
            bordered
            // scroll={{ x: 1300 }}
            pagination={false}
            footer={() => {
              "&nbsp;";
            }}
          />
        </Col>
      </Row>
      <Row className="result-content-wrapper">
        <Col span={24} className="result-content">
          <Row className="report-result">
            <Col className="header-report-part-D" span={6}>
              {t("airlines.customer")}
            </Col>
            <Col className="header-report-part-D" span={10}>
              {t("airlines.goods")}
            </Col>
            <Col className="header-report-part-D" span={8}>
              Mails
            </Col>
          </Row>
          <Row className="report-result" bordered size={"small"}>
            <Col className="report-part-D" span={6}>
              {t("airlines.document")}
            </Col>
            <Col className="report-part-D" span={10}>
              {document}
            </Col>
            <Col className="report-part-D" span={8}></Col>
          </Row>
          <Row className="report-result" bordered size={"small"}>
            <Col className="report-part-D" span={6}>
              {t("airlines.delivery")}
            </Col>
            <Col className="report-part-D" span={10}>
              {checker}
            </Col>
            <Col className="report-part-D" span={8}></Col>
          </Row>
          <Row className="report-result" bordered size={"small"}>
            <Col className="report-part-D" span={6}>
              {t("airlines.manager")}
            </Col>
            <Col className="report-part-D" span={10}>
              {csImport}
            </Col>
            <Col className="report-part-D" span={8}></Col>
          </Row>
          <Row className="report-result" bordered size={"small"}>
            <Col className="report-part-D" span={6}>
              {t("airlines.timeOver")}
            </Col>
            <Col className="report-part-D" span={10}>
              {endTime}
            </Col>
            <Col className="report-part-D" span={8}></Col>
          </Row>
          <Descriptions className="report-result" bordered size={"small"}>
            <Descriptions.Item>
              {t("agentReport.Time")} {moment().format("DD MM YYYY hh:mm")}
            </Descriptions.Item>
          </Descriptions>
        </Col>
      </Row>
    </>
  );
};
export default AirlinesReportResult;
