import React, { useEffect, useState } from 'react'
import { Select} from 'antd'
import tempReqData from '../../../../../constant/tempDropdown';

export const tempReqsToSlcOptions = (tempReqs) => {
  const options = [{
    label: null,
    value: null,
    title: null,
  }];
  tempReqs.forEach((tempReq) => options.push({
    label: `${tempReq.code} - ${tempReq.title}`,
    value: tempReq.code,
    title: tempReq.title,
  }))
  return options;
}
export const TempSelect = ({value, form, disabled, isSelectOpen, setIsSelectOpen}) => {
    const [isTempReqsLoading, setIsTempReqsLoading] = useState(false);
    const [tempReqs, setTempReqs] = useState([]);

    useEffect(() => {
        setIsTempReqsLoading(true);
        setTempReqs(tempReqsToSlcOptions(tempReqData));
        setIsTempReqsLoading(false);
    },[])
    const onChangeSelect = (value) => {
        const selectedOption = tempReqs.find(x => x.value === value);
        form.setFieldsValue({
            shcColTemReq: selectedOption.value,
            shcColTemReqString: selectedOption.title
        })
    };
    const handleDropdownVisible = (open) => {
        setIsSelectOpen(open);
    }
    return (
      <Select
        id='tempReqSelect'
        disabled={disabled}
        loading={isTempReqsLoading}
        style={{ width: isSelectOpen ? '100%' : '30%' }}
        options={tempReqs}
        value={value}
        onChange={(value) => onChangeSelect(value)}
        onDropdownVisibleChange={(open) => handleDropdownVisible(open)}
        optionLabelProp='value'
      />
    )
}
