import React from "react";
import { ShipmentDetailForm } from "./ShipmentDetailForm";
import { ConsolTable } from "./ConsolTable";
import { ServiceRegistration } from "./ServiceRegistration";
import { StaffForm } from "../../../../pages/admin/EsidExport/components/Acceptance/StaffForm";
import { useSelector } from "react-redux";
import { ModalName } from "../../../../constant/enumerations";
import { EditConsolModel } from "./EditConsolModel";
import { ConsolDeclarant } from "./ConsolDeclarant";

export const ExpDeclConsolContainer = ({forms}) => {
  const consolModalVisible = useSelector(state => state.esidExport.modal[`${ModalName.ConsolModal}`]);
  
  return (
    <div>
      {consolModalVisible && (
        <EditConsolModel />
      )}
      <ShipmentDetailForm form={forms.consoltMstForm}/>
      <ConsolTable />
      <ServiceRegistration />
      <ConsolDeclarant form={forms.consoltMstForm}/>
      <StaffForm readOnly={true} form={forms.consoltMstForm}/>
    </div>
  );
};
