/* eslint-disable */
import React, { useRef, useState } from "react";
import { Row, Col, Input, Form, Button, DatePicker, notification, Table, Space, Typography, Modal, Tooltip, InputNumber } from "antd";
import {
    CloseOutlined,
    LoadingOutlined,
    CheckOutlined,
    EditOutlined,
    DeleteOutlined,
  } from '@ant-design/icons'
import { useForm } from "antd/lib/form/Form";
import moment from "moment";
import QRCode from "qrcode.react";
import { useTranslation } from "react-i18next";
import { useReactToPrint } from "react-to-print";
import "moment/locale/en-au";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CreateVhcReg, GetEsidExportByAwb, GetEsidExportById } from "../config/esidExportApi";
import { errorHandling } from "../config/AuthIntercept";
import { setVhcRegSelected } from "../actions/esidExportAction";
import { CarRegistryToPrint } from "./CarRegistryToPrint";
import { EsidListModal } from "./tracking/RegistryVehicleModal/EsidListModal";
import { EsidTypeEnum } from "../constant/enumerations";
import * as API from '../config/apiConfig';
import './style.css';
import { data } from "jquery";

const { confirm } = Modal;

const TabRegistryCar = () => {
    const [form] = useForm();
    const [formEditEsid] = useForm();
    const [QR, setQR] = useState("");
    const [numberQR, setNumberQR] = useState();
    const [QRToPrint, setQRToPrint] = useState();
    const [loading, setLoading] = useState(false);
    const [mAwbState, setMAwbState] = useState({loading: false, errorMsg: null, isFound: true});
    const [mEsidState, setMEsidState] = useState({loading: false, errorMsg: null, isFound: true});
    const [dataSource, setDataSource] = useState([]);
    const [esidListModalVisible, setEsidListModalVisible] = useState(false);
    const [formIsDisable, setFormIsDisable] = useState(false);
    const { t } = useTranslation("common");
    const selectVhcRegSelected = useSelector((state) => state.esidExport.vhcRegSelected);
    const dateFormat = "DD-MM-YYYY HH:mm";
    const [esidExport, setEsidExport] = useState();
    const dispatch = useDispatch();
    // console.log(dataSource)
    const [isOpenEditModal, setIsOpenEditModal] = useState(false);
    const [selectedEsid, setSelectedEsid] = useState(false);

    const showModal = () => {
        setIsOpenEditModal(true);
      };
    
    const handleOkModal = () => {
        setIsOpenEditModal(false);
    };
    
    const handleCancelModal = () => {
        setIsOpenEditModal(false);
    };

    const edit = (record) =>{
        setSelectedEsid(record);
        formEditEsid.setFieldsValue({
            qtyPcs: record.qtyPcs,
            qtyWgtKg: record.qtyWgtKg
        })
        showModal();
        // console.log(record);
    }

    const handleDelete = (deleteSelected) => {
        // console.log(deleteSelected)
        const key = deleteSelected.key;
        const newData = dataSource.filter((item) => item.key !== key);
        setDataSource(newData);
      };
    
    const showConfirm = (item) => {
      confirm({
        title: t("registerCar.confirmDeleteMsg"),
        // icon: <ExclamationCircleFilled />,
        centered: true,
        okText: t("ok"),
        cancelText: t('dangerousGood.formInfo.btnCancel'),
        content: (
          <>
            <div>{`AWB: ${item.awbPfxAndNum}`}</div>
            <div>{t("registerCar.table.flightNo")}{`: ${item.fltCarAndNum}`}</div>
          </>
        ),
        onOk() {
          handleDelete(item);
        },
        onCancel() {
        //   console.log('Cancel');
        },
      });
    };

    const layout = {
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
    };
    
    const handleFinishUpdate = async (values) =>{
        // console.log(values);
        // console.log({...selectedEsid,...values})
        const newData = {...selectedEsid,...values};
        const i = dataSource.findIndex((data) => data.id === selectedEsid.id);
        dataSource[i] = newData;
        setDataSource([...dataSource]);
        setIsOpenEditModal(false);
    }

    const showError = () => {
        notification.error({
          message: t("Oops"),
          top: 100,
          description: t("dangerousGood.tableInfo.emptyError"),
        });
      };
      const showSuccess = () => {
        notification.success({
          message: t("Congrats"),
          description: t("dangerousGood.formInfo.successMsg"),
          top: 100,
        });
      };
    const createQR = (value) => {
        setQR(<QRCode value={`${value}`} />);
    };

    const today = new Date(),
        date =
            today.getFullYear() +
            "-" +
            (today.getMonth() + 1) +
            "-" +
            today.getDate();

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const handleFinish = async (values) => {
        setLoading(true);
        setFormIsDisable(true);
        if ( dataSource && dataSource.length > 0) {
            const esidShpRegs = dataSource.map(({key, fltCarAndNum, awbPfxAndNum, datFltOrigin, ...rest}) => ({ ...rest, }));
            console.log(esidShpRegs)
            const postValue = {
                esidId: 0,
                datReg: new Date(values.datReg).toISOString(),
                vehicleId: values.vehicleId,
                driverName: values.driverName,
                driverIdNum: values.driverIdNum,
                driverPhone: values.driverPhone,
                codSts: "VN",
                esidShpRegs,
                // TODO: dont know what these field for yet
                tcsCodRefNum: ''
            }
            try {
                const resp = await CreateVhcReg(postValue);  
                for (let i = 0; i < esidShpRegs.length; i++) {
                    const item = esidShpRegs[i];
                    const pdaExpData = {
                        VEHICE_NUM: postValue.vehicleId,
                        DRIVER_NAME: postValue.driverName,
                        CONTACT_NUM: postValue.driverPhone,
                        DRIVER_ID: postValue.driverIdNum,
                        AWB_NO: `${item.codAwbPfx}${item.codAwbNum}`,
                        COD_HWB_NUM: '',//
                        PCS: item.qtyPcs,
                        WGT: item.qtyWgtKg,
                        DAT_FLT_ORI: item.datFltOri,
                        COD_FLT_CAR: item.codFltCar,
                        COD_FLT_NUM: item.codFltNum,
                        DAT_ISSUES: item.datCre,//
                        ISSUE_USER: "NAMVV",//
                        ARRIVAL_TIME: item.datUpd,//
                        TCS_GATE: "00",
                        COD_SVC_TYP: "TCS",
                    }
                    await API.createQR(
                        pdaExpData.VEHICE_NUM, 
                        pdaExpData.DRIVER_NAME, 
                        pdaExpData.CONTACT_NUM, 
                        pdaExpData.DRIVER_ID, 
                        pdaExpData.AWB_NO, 
                        pdaExpData.COD_HWB_NUM, 
                        pdaExpData.PCS,
                        pdaExpData.WGT, 
                        pdaExpData.DAT_ISSUES,
                        pdaExpData.ARRIVAL_TIME,
                        pdaExpData.DAT_FLT_ORI,
                        pdaExpData.COD_FLT_CAR, 
                        pdaExpData.COD_FLT_NUM,
                        `ESID_${item.tcsCodRefNum}`
                        );
                }
                dispatch(setVhcRegSelected(resp.data));
                setLoading(false);
                showSuccess();
                setFormIsDisable(false);
            } catch (error) {
                setFormIsDisable(false);
                setLoading(false);
                errorHandling(error, null, null); 
            }
        }
        else {
            setLoading(false);
            setFormIsDisable(false);
            showError();
            return;
        }
    };
    const columns = [
        {
            title: 'AWB',
            dataIndex: 'awbPfxAndNum',
        },
        {
            title: t("registerCar.table.flightNo"),
            dataIndex: 'fltCarAndNum',
        },
        {
            title: t("registerCar.table.flightDate"),
            dataIndex: 'datFltOrigin',
            render: (value) => moment(value).format(dateFormat)
        },
        {
            title: t("registerCar.table.qtyPcs"),
            dataIndex: 'qtyPcs',
        },
        {
            title: t("registerCar.table.qtyWgtKg"),
            dataIndex: 'qtyWgtKg',
        },
        {
            title: t("registerCar.table.esid"),
            dataIndex: 'esidId',
        },
        {
            title: t("QR Code"),
            render: (_,record) => {
                return <QRCode value={`${record?.codAwbPfx}${record?.codAwbNum}`} style={{ margin: '5px', width: '50px', height: '50px' }}/>
            }
        },
        {
            title: "Action",
            dataIndex: 'action',
            hidden: selectVhcRegSelected ? true : false ,
            fixed: 'right',
            render: (_,record) => {
              return (
                <Space>
                  <Tooltip title={t("dangerousGood.tableInfo.btnEdit")}>
                    <Button className="nostyle" type='text' onClick={() => edit(record)}  icon={<EditOutlined />} />
                  </Tooltip>
                  <Tooltip title={t("dangerousGood.tableInfo.btnDelete")}>
                    <Button className="nostyle" type='text' danger icon={<DeleteOutlined />} onClick={() => showConfirm(record)} />
                  </Tooltip>
                </Space>
              )
            }
        },
    ].filter(item => !item.hidden);

    useEffect(() => {
        if (selectVhcRegSelected) {
            form.setFieldsValue({...selectVhcRegSelected, awbPfxAndNum: selectVhcRegSelected.codAwbPfx,datReg: moment(selectVhcRegSelected.datReg, dateFormat)});
            setDataSource(selectVhcRegSelected.esidShpRegs.map(x => ({
                ...x, 
                key: x.id, 
                fltCarAndNum: `${x.codFltCar}${x.codFltNum}`,
                awbPfxAndNum: `${x.codAwbPfx}${x.codAwbNum}`,
                datFltOrigin: x.datFltOri
            })));
            const qrCodeStr = `${EsidTypeEnum.EsidVhc}#${selectVhcRegSelected.tcsCodRefNum}`;
            setNumberQR(selectVhcRegSelected.tcsCodRefNum);
            setQRToPrint(qrCodeStr);
            createQR(qrCodeStr);
        }
        else {
            setNumberQR(null);
            setQRToPrint(null);
            createQR(null);
            form.resetFields();
        }
    },[selectVhcRegSelected])
    useEffect(() => {
        if (esidExport) {
            const data = {
                awbPfxAndNum: `${esidExport.codAwbPfx}${esidExport.codAwbNum}`,
                fltCarAndNum: `${esidExport.codFltCar}${esidExport.codFltNum}`,
                datFltOrigin: esidExport.datFltOri,
                datFltOri: esidExport.datFltOri,
                codAwbPfx: esidExport.codAwbPfx,
                codAwbNum: esidExport.codAwbNum,
                codAwbSfx: esidExport.codAwbSfx,
                codFltCar: esidExport.codFltCar,
                codFltNum: esidExport.codFltNum,
                usrCre: esidExport.usrCre,
                datCre: esidExport.datCre,
                usrUpd: esidExport.usrUpd,
                datUpd: esidExport.datUpd,
                codSts: esidExport.codSts,
                esidId: esidExport.id,
                key: esidExport.id
            };
            const isFound = dataSource.some(item => {
                if (item.esidId === data.esidId || item.awbPfxAndNum === data.awbPfxAndNum) {
                  return true;
                }
              
                return false;
            });
            if (!isFound) {
                setDataSource([...dataSource, data]);
            }
            else {
                notification.info({
                    description: t("registerCar.error.exportExisted"),
                    top: 200,
                })
            }
            setEsidExport(null);
        }
        else {
            form.resetFields(['esidId','awbPfx','awbNum']);
        }
    },[esidExport])

    const isValidEsid = (value) => {
        if (value) {
            if (isNaN(value)) {
                return false;
            }
            if (value.length > 11 || value.length < 1) {
                return false;
            }
        }
        else {
            return false;
        }
        return true;
    }
    const [timer, setTimer] = useState(null);
    const handleNextInput = (evt) => {
        clearTimeout(timer);
        const timeoutId = setTimeout(() => {
          const { maxLength, value, name } = evt.target;
          // Check if they hit the max character length
          if (value.length >= maxLength && name === "awbPfx") {
            // Get the next input field
            const nextSibling = document.querySelector(`input[name=awbNum]`);
            // If found, focus the next field
            if (nextSibling !== null) {
              nextSibling.focus();
            }
          }
        }, 300);
    setTimer(timeoutId);
    };
    const handleChangeMEsid = async (evt) => {
        clearTimeout(timer);
        const timeoutId = setTimeout(() => {
            setMEsidState({
                ...mEsidState,
                isFound: false,
                loading: true,
            });
            if (isValidEsid(form.getFieldValue(evt.target.name))) {
                const id = evt.target.value;
                setSelectedEsidById(id);
            }
            else {
                setMEsidState({
                    ...mEsidState,
                    errorMsg: null,
                    loading: false,
                });
            }
        }, 700);
        setTimer(timeoutId);
    }
    const handleChangeMAwb = async () => {
        // const prevSibling = document.querySelector(`input[name=awbPfx]`);
        // if (prevSibling.value.length === 0) {
        //     if (prevSibling !== null) {
        //         prevSibling.focus();
        //         form.resetFields(['awbNum']);
        //     }
        //     return;
        // }
        clearTimeout(timer);
        const timeoutId = setTimeout(() => {
            setMAwbState({
                errorMsg: '',
                isFound: false,
                loading: true,
            });
            // const { awbPfx, awbNum } = form.getFieldsValue();
            const {awbPfxAndNum} = form.getFieldsValue();
            if(awbPfxAndNum >= 11){
                const awbPfx = awbPfxAndNum.slice(0,3);
                const awbNum = awbPfxAndNum.slice(3,12);
                setSelectedEsidByAwb(awbPfx, awbNum);
            }
            else {
                setMAwbState({
                    ...mAwbState,
                    errorMsg: "Invalid number",
                    loading: false,
                });
            }

            // if ( awbPfx && awbNum && awbPfx.length >= 3 && awbNum.length >= 8 ) {
            //     setSelectedEsidByAwb(awbPfx, awbNum);
            // }
            // else {
            //     setMAwbState({
            //         ...mAwbState,
            //         errorMsg: "Invalid number",
            //         loading: false,
            //     });
            // }

        }, 700);
        setTimer(timeoutId);
    }

    const setSelectedEsidById = async (id) => {
        try {
            const res  = await GetEsidExportById(id); 
            if(res.data.codSts && res.data.codSts !== 'NY') {
                setMEsidState({
                    errorMsg: t("registerCar.error.esidExisted"),
                    loading: false,
                    isFound: false
                });
                return;
            }
            setEsidExport(res.data)
            setMEsidState({
                errorMsg: null,
                loading: false,
                isFound: true
            });
        } catch (error) {
            setMEsidState({
                errorMsg: t("registerCar.error.notFound"),
                loading: false,
                isFound: false
            });
        }
    }
    const setSelectedEsidByAwb = async (awbPfx, awbNum) => {
        try {
            const res  = await GetEsidExportByAwb(awbPfx, awbNum);
            if(res.data.codSts && res.data.codSts !== 'NY') {
                setMAwbState({
                    errorMsg: t("registerCar.error.awbExisted"),
                    loading: false,
                    isFound: false
                });
                return;
            }
            setEsidExport(res.data)
            setMAwbState({
                errorMsg: null,
                loading: false,
                isFound: true
            });
        } catch (error) {
            setMAwbState({
                errorMsg: t("registerCar.error.notFound"),
                loading: false,
                isFound: false
            });
        }
    }

    const handleCancel = () =>{
        // console.log(dataSource)
        setDataSource([]);
        form.resetFields();
    }

    // chose ESID feature
    const filteredExistedItemArray = (array, subArray) => {
        const isItemIdExisted = (key, array) => {
            return array.some((item) => item.key === key);
        }
        return subArray.filter((subItem) => !isItemIdExisted(subItem.key, array))
    }
    const setEsidList = (esidList) => {
        if(esidList && esidList.length > 0) {
            const newEsidList = filteredExistedItemArray(dataSource,esidList);
            // console.log(esidList, newEsidList);
            setDataSource([...dataSource, ...newEsidList]);
        }
    }

    return (
        <div style={{ display: "flex", justifyContent: "center" }}>
            <div className="awb-tab-content" style={{ width: "900px" }}>
                <p className="registry-notice">{t("header.registryInvNotice")} </p>
                <div className="control-wrapper" style={{paddingLeft: 10}}>
                    <div>
                        <Form
                            layout="vertical"
                            className="form-date"
                            form={form}
                            initialValues={{
                                datReg: moment(today, dateFormat),
                                vehicleId: "",
                                esidId: "",
                                driverName: "",
                                driverIdNum: "",
                                driverPhone: "",
                            }}
                            onFinish={handleFinish}
                            autoComplete="off"
                        >
                            <EsidListModal 
                                visible={esidListModalVisible}
                                setVisible={setEsidListModalVisible}
                                setEsidList={setEsidList} 
                            />
                            <Row>
                                <Col span={24}>
                                    <Form.Item 
                                        name="datReg" 
                                        label={t("header.createDate")}
                                        rules={[
                                            {
                                                required: true,
                                                message: `Required`,
                                            },
                                        ]}
                                    >
                                        <DatePicker
                                            style={{ width: "100%" }}
                                            format={`${dateFormat}`}
                                            disabled={formIsDisable}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={[10, 0]}>
                                <Col span={24} md={12}>
                                    <Form.Item label={t("registerCar.MAWBNo")}>
                                        <Row >   
                                            {/* <Col span={8}>
                                                <Form.Item name={'awbPfx'}>
                                                    <Input
                                                        maxLength={3}
                                                        name={'awbPfx'}
                                                        onKeyUp={handleNextInput}
                                                        placeholder={t("Prefix")}
                                                        />
                                                </Form.Item>
                                            </Col> */}
                                            <Col span={24}>
                                                <Form.Item name={'awbPfxAndNum'}>
                                                    <Input
                                                        onChange={handleChangeMAwb}
                                                        maxLength={11}
                                                        placeholder="MAWB"
                                                        name={'awbPfxAndNum'}
                                                        suffix={
                                                            mAwbState.loading ? 
                                                            <LoadingOutlined /> : 
                                                            mAwbState.errorMsg ?
                                                            <CloseOutlined style={{color: "red"}} /> : 
                                                            <CheckOutlined style={{color: "green"}} />
                                                        }
                                                        disabled={formIsDisable}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Form.Item>
                                    {
                                        mAwbState.errorMsg && mAwbState.errorMsg !== '' && (
                                            <Typography.Text type='danger'>{mAwbState.errorMsg}</Typography.Text>
                                        )
                                    }
                                </Col>
                                <Col span={14} md={7}>
                                    <Form.Item 
                                        name="esidId" 
                                        label={t("registerCar.MEsidNo")}
                                        rules={[
                                            {
                                                pattern: new RegExp('^[0-9]{1,11}$'),
                                                message: t("registerCar.error.awbErr"),
                                            },
                                        ]}
                                    >
                                        <Input
                                            name="esidId"
                                            onChange={handleChangeMEsid}
                                            suffix={
                                                mEsidState.loading ? 
                                                <LoadingOutlined /> : 
                                                mEsidState.errorMsg ?
                                                <CloseOutlined style={{color: "red"}} /> : 
                                                <CheckOutlined style={{color: "green"}} />
                                            }
                                            disabled={formIsDisable}
                                        />
                                    </Form.Item>
                                    {
                                        mEsidState.errorMsg && mEsidState.errorMsg !== '' && (
                                            <Typography.Text type='danger'>{mEsidState.errorMsg}</Typography.Text>
                                        )
                                    }
                                </Col>
                                <Col span={10} md={5}>
                                    <Form.Item label=" ">
                                        <Space style={{display: 'flex', justifyContent: 'center', marginTop: '3.14px'}}>
                                            <Button 
                                                disabled={selectVhcRegSelected}
                                                style={{ width: "fit-content", marginLeft: 0 }}
                                                onClick={() => setEsidListModalVisible(true)}
                                            >
                                                {t("registerCar.chooseEsid")}
                                            </Button>
                                        </Space>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={[10, 0]}>
                                <Col span={24} md={12}>
                                    <Form.Item 
                                        name="vehicleId" 
                                        label={t("header.licensePlates")}
                                        rules={[
                                            {
                                                required: true,
                                                message: t("registerCar.error.required"),
                                            },
                                        ]}
                                    >
                                        <Input disabled={formIsDisable} />
                                    </Form.Item>
                                </Col>
                                <Col span={24} md={12}>
                                    <Form.Item 
                                        name="driverName" 
                                        label={t("header.controller")}
                                        rules={[
                                            {
                                                required: true,
                                                message: t("registerCar.error.required"),
                                            },
                                        ]}
                                    >
                                        <Input disabled={formIsDisable}/>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={[10, 0]}>
                                <Col span={24} md={12}>
                                    <Form.Item 
                                        name="driverIdNum" 
                                        label={t("header.id")}
                                        rules={[
                                            {
                                                required: true,
                                                message: t("registerCar.error.required"),
                                            },
                                        ]}
                                    >
                                        <Input disabled={formIsDisable} maxLength={12} />
                                    </Form.Item>
                                </Col>
                                <Col span={24} md={12}>
                                    <Form.Item 
                                        name="driverPhone" 
                                        label={t("header.phoneNumber")}
                                        rules={[
                                            {
                                                required: true,
                                                message: t("registerCar.error.required"),
                                            },
                                        ]}
                                    >
                                        <Input disabled={formIsDisable} maxLength={10} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={[10, 30]}>
                                <Col span={24}>
                                    <p style={{textAlign:"center"}}>{t("registerCar.goodsList")}</p>
                                    <Table
                                        className={'esid-table'}
                                        // onRow={(record) => {
                                        //     return {
                                        //         onClick: () =>{
                                        //             edit(record)
                                        //         }
                                        //     }
                                        // }}
                                        columns={columns}
                                        dataSource={dataSource}
                                        pagination={false}
                                    />
                                </Col>
                                <Col span ={24}>
                                    <Form.Item>
                                        <div
                                            style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            }}
                                        >
                                        {   
                                            selectVhcRegSelected ? (
                                            <>
                                                <Button
                                                    style={{ width: "fit-content" }}
                                                    onClick={() => {
                                                        form.resetFields();
                                                        setDataSource([]);
                                                        setTimeout(() => {
                                                            dispatch(setVhcRegSelected(null));
                                                        },200)
                                                    }}
                                                >
                                                    {t("registerCar.addNewBtn")}
                                                </Button>
                                                <Button
                                                    onClick={handlePrint}
                                                    style={{ width: "fit-content" }}
                                                >
                                                    {t("header.print")}
                                                </Button>
                                            </>
                                            ) : (
                                            <>
                                                <Button
                                                    loading={loading}
                                                    style={{ width: "fit-content" }}
                                                    htmlType="submit"
                                                >
                                                    {t("registerCar.submitBtn")}
                                                </Button>
                                                <Button
                                                    onClick={handleCancel}
                                                    style={{ width: "fit-content" }}
                                                >
                                                    {t("registerCar.cancelBtn")}
                                                </Button>
                                            </>
                                            )
                                        }
                                        </div>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                        <Modal 
                            width='500px'
                            title={t("registerCar.updateMsg")}
                            visible={isOpenEditModal} 
                            onOk={handleOkModal} 
                            onCancel={handleCancelModal} 
                            footer={null}
                            centered
                        >
                            <Form 
                                {...layout}
                                form={formEditEsid}
                                initialValues={{
                                    qtyPcs: "",
                                    qtyWgtKg: "",
                                }}
                                onFinish={handleFinishUpdate}
                                autoComplete="off"
                            >
                                <Row gutter={[16,16]}>
                                    <Col span={12}>
                                        <Form.Item 
                                            label="AWB"
                                        >
                                            <Input value={selectedEsid.awbPfxAndNum} readOnly/>
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item 
                                            label="ESID"
                                        >
                                            <Input value={selectedEsid.esidId} readOnly/>
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item 
                                            label={t("registerCar.table.flightNo")}
                                        >
                                            <Input value={selectedEsid.codFltCar+selectedEsid.codFltNum} readOnly/>
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item 
                                            label={t("registerCar.table.flightDate")}
                                        >
                                            <Input value={moment(selectedEsid.datFltOri).format(dateFormat).slice(0,10)} readOnly/>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24}>
                                        <Form.Item 
                                            name="qtyPcs" 
                                            label={t("header.number")}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: t("registerCar.error.required"),
                                                },
                                            ]}
                                        >
                                            <InputNumber 
                                                min={0} 
                                                style={{width: '100%', height: '100%'}}
                                                type="number"
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24}>
                                        <Form.Item 
                                            name="qtyWgtKg" 
                                            label={t("header.weight")}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: t("registerCar.error.required"),
                                                },
                                            ]}
                                        >
                                            <InputNumber 
                                                min={0} 
                                                style={{width: '100%', height: '100%'}}
                                                type="number"
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24}>
                                        <div style={{display:'flex', justifyContent:'flex-end', gap: '5px'}}>
                                            <Button
                                                style={{ width: "fit-content", color:'white', background: '#fd8805', borderRadius: '4px' }}
                                                onClick={handleCancelModal}
                                            >
                                                {t("registerCar.cancelBtn")}
                                            </Button>
                                            <Button
                                                style={{ width: "fit-content", color:'white', background: '#fd8805', borderRadius: '4px' }}
                                                htmlType="submit"
                                            >
                                                {t("registerCar.updateBtn")}
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </Modal>
                    </div>
                </div>
                {numberQR && selectVhcRegSelected && (
                    <Row>
                        <Col span={0}>
                            <CarRegistryToPrint
                                ref={componentRef}
                                {...selectVhcRegSelected}
                                QR={QRToPrint}
                                numberQR={numberQR}
                            />
                        </Col>
                    </Row>
                )}
                
                {/* {numberQR && (<Row className="result-wrapper">
                    <Row className="result-wrapper">
                        <Col
                            style={{ display: "flex", justifyContent: "center" }}
                            span={24}
                            className="result"
                        >
                            <Row className="ticket-wrapper">
                                <Row className="control-button-group-wrapper">
                                    <Col span={24} className="control qr-input-wrapper">
                                        <Col className="qr-title">{t("header.yourRegister")}</Col>
                                        <Col className="qr-no">{numberQR}</Col>
                                    </Col>
                                </Row>
                                <Row className="control-button-group-wrapper">
                                    <Col span={24} className="control qr-wrapper">
                                        {QR}
                                    </Col>
                                </Row>
                            </Row>
                        </Col>
                    </Row>
                </Row>)} */}
            </div>
        </div>
    );
};

export default TabRegistryCar;
