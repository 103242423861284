import { Table, Typography } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next';
import { formatCurrency } from '../../../utils/utils';

const { Text } = Typography;
export const InvoiceTable = ({ invoices }) => {
    const { t } = useTranslation("common");
    const columns = [
        {
            title: t("esidCheckout.invoice.invNo"),
            dataIndex: 'INV_NO',
            key: 'INV_NO',
        },
        {
            title: t("esidCheckout.invoice.serNo"),
            dataIndex: 'INV_SRL_NO',
            key: 'INV_SRL_NO',
        },
        {
            title: t("esidCheckout.invoice.totalGrs"),
            dataIndex: 'AMT_GROSS',
            key: 'AMT_GROSS',
            render: (_, record) => (<>{formatCurrency(record.AMT_GROSS - record.WAIVE_AMT, record.COD_CUR)}</>)
        },
        // {
        //     title: t("esidCheckout.invoice.totalWaive"),
        //     dataIndex: 'WAIVE_AMT',
        //     key: 'WAIVE_AMT',
        //     render: (_, record) => (<>{formatCurrency(record.WAIVE_AMT, record.COD_CUR)}</>)
        // },
        {
            title: t("esidCheckout.invoice.totalVat"),
            dataIndex: 'AMT_TAX',
            key: 'AMT_TAX',
            render: (_, record) => (<>{formatCurrency(record.AMT_TAX, record.COD_CUR)}</>)
        },
        {
            title: t("esidCheckout.invoice.totalAmt"),
            dataIndex: 'AMT_TOTAL',
            key: 'AMT_TOTAL',
            render: (_, record) => (<>{formatCurrency(record.AMT_GROSS - record.WAIVE_AMT + record.AMT_TAX, record.COD_CUR)}</>)
        },
        {
            title: t("esidCheckout.invoice.rmk"),
            dataIndex: 'RMK',
            key: 'RMK',
            render: (_, record) => ''
        },
        {
            title: 'Print Flag',
            dataIndex: 'PrintFlag',
            key: 'PrintFlag',
        },
    ]
  return (
    <Text>
        <Table
            title={() => <Text strong>{t("esidCheckout.invoice.tableTitle")}</Text>}
            columns={columns}
            style={{ marginBottom: 10}}
            dataSource={invoices ?? []}
            pagination={false}
            bordered
            rowKey={"Key"}
            summary={(pageData) => {
                let totalGross = 0;
                // let totalWaive = 0;
                let totalVat = 0;
                let totalAmt = 0;
        
                pageData.forEach(({ AMT_GROSS, WAIVE_AMT, AMT_TAX }) => {
                    totalGross += AMT_GROSS;
                    // totalWaive += WAIVE_AMT;
                    totalVat += AMT_TAX;
                    totalAmt += AMT_GROSS - WAIVE_AMT + AMT_TAX;
                });
        
                return (
                  <>
                    <Table.Summary.Row style={{ backgroundColor: '#fafafa' }}>
                        <Table.Summary.Cell index={0} colSpan={2}>
                            <Text strong>
                                {t("esidCheckout.invoice.total")}
                            </Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={1} align='center'>
                            <Text strong>{formatCurrency(totalGross)}</Text>
                        </Table.Summary.Cell>
                        {/* <Table.Summary.Cell index={2} align='center'>
                            <Text strong>{formatCurrency(totalWaive)}</Text>
                        </Table.Summary.Cell> */}
                        <Table.Summary.Cell index={3} align='center'>
                            <Text strong>{formatCurrency(totalVat)}</Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={5} align='center'>
                            <Text strong>{formatCurrency(totalAmt)}</Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={5} colSpan={2}></Table.Summary.Cell>
                    </Table.Summary.Row>
                  </>
                );
            }}
        />
    </Text>
  )
}
