import { Row, Col, Form, Radio, Button, message } from "antd";
import { Link, useHistory } from "react-router-dom";
import React, { useEffect, useState } from "react";
import "./component-css/StatisticForm.css";
import * as API from "../config/apiConfig";
import { userAwbLogout } from "../actions/userAWB";
import { useDispatch } from "react-redux";

const StatisticForm = () => {
  const [year, setYear] = useState([]);
  const [selectYear, setSelectYear] = React.useState();
  const [selectType, setSelectType] = React.useState();
  const history = useHistory();
  const dispatch = useDispatch();

  const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
  };

  const logout = () => {
    dispatch(userAwbLogout());
    history.push("/");
  };

  useEffect(() => {
    API.getYearUpload().then((res) => {
      setYear(res.data);
    });
  }, []);

  const onSelectYear = (e) => {
    setSelectYear(e.target.value);
  };

  const onSelectType = (e) => {
    setSelectType(e.target.value);
  };

  const onFinish = () => {
    API.getNameFileUpload(selectYear, selectType)
      .then((res) => {
        API.downloadFileUpload(res.data[0].FileName);
      })
      .catch((error) => {
        if (error) {
          message.error("File down not found");
        }
      });
  };

  return (
    <Row className="statistic-wrapper">
      <Row className="statistic-form-wrapper">
        <Col span={24} className="form-wrapper">
          <Form
            className="statistic-form"
            name="basic"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            autoComplete="off"
          >
            <div className="statistic-controls">
              <Form.Item {...tailLayout}>
                <Radio.Group onChange={onSelectYear} value={selectYear}>
                  {year &&
                    year.map((y, i) => {
                      return (
                        <div key={i}>
                          <Radio value={y.Year}>{y.Year}</Radio>
                        </div>
                      );
                    })}
                </Radio.Group>
              </Form.Item>
              <Form.Item {...tailLayout} name="type">
                <Radio.Group onChange={onSelectType} value={selectType}>
                  <Radio value="pdf">
                    <p>PDF</p>
                  </Radio>
                  <Radio value="xls">Excel</Radio>
                </Radio.Group>
              </Form.Item>
            </div>
            <div className="statistic-controls">
              <Form.Item >
                <Button style={{ width: "fit-content" }} htmlType="submit">
                  Tải về
                </Button>
              </Form.Item>
              <Form.Item >
                <Button style={{ width: "fit-content" }} onClick={logout}>
                  Đăng xuất
                </Button>
              </Form.Item>
            </div>
          </Form>
        </Col>
      </Row>
      <Row className="change-password-wrapper" style={{ width: "100%" }}>
        <Row className="statistic-form-wrapper">
          <Col className="change-password" offset={11}>
            <Link to={"/changePassword"}>Thay đổi mật khẩu</Link>
          </Col>
        </Row>
      </Row>
    </Row>
  );
};

export default StatisticForm;
