import React from 'react'
import moment from 'moment'
import { useTranslation } from "react-i18next";
import useWindowSize from '../../../../../helpers/useWindowSize';
import { Col, DatePicker, Form, Row, Input, Typography } from 'antd'

export const DgrReceivedForm = ({ form, readOnly }) => {
    const { t } = useTranslation("common");
    const disabledDate = (current) => {
        return current && current > moment().endOf('day');
    }
    const size = useWindowSize();
    return (
        <Form 
            labelAlign='left'
            style={{ padding: '2px 5px', marginTop: 10}}
            labelCol={{ span: 24 }}
            form={form}
            initialValues={{
                acpDat: moment()
            }}
            autoComplete="off"
        >
            <Row gutter={[5,5]}>
                <Col xs={24} sm={24} xl={16}>
                    <Form.Item
                        label={<Typography.Text type='danger' strong>{t('admin.acceptance.dgrAcpStfNam')}</Typography.Text>}
                        name={'dgrAcpStfNam'}
                    >
                        <Input readOnly={readOnly}/>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} xl={8}>
                    <Form.Item 
                        label={<Typography.Text strong>{t('admin.acceptance.acpDat')}</Typography.Text>}
                        name={'dgrAcpDat'}
                    >
                        <DatePicker disabled={readOnly} showTime format={ size === 'xs' ? 'DD-MM-YYYY' : 'DD-MM-YYYY HH:mm:ss'} style={{ width: '100%'}} disabledDate={disabledDate}/>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    )
}
