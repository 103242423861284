/* eslint-disable */

import React, { useEffect } from "react";
import { Row, Col, Skeleton, Menu } from "antd";
import "./page-css/Guides.css";
import Banner from "../components/Banner";
import GuideContent from "../components/Guides/GuideContent";
import { useDispatch, useSelector } from "react-redux";
import * as Constant from "../config/Constant";
import * as API from "../config/apiConfig";
import { menuItemSelected, showLangSwitch } from "../actions/menu";
import { useLocation } from "react-router";
import TabRegistryInventory from "../components/TabRegistryInventory";
import TabRegistryCar from "../components/TabRegistryCar";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import GuideProcedure from "../components/Guides/GuideProcedure";
import { setEsidExpTabPaneActive } from "../actions/esidExportAction";
import { esidExpTabsEnum } from "../constant/enumerations";

const Guides = () => {
  const { menuItem, langId } = useSelector((state) => state.menu);
  const [menuItems, setMenuItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  let location = useLocation();
  const history = useHistory();
  useEffect(() => {
    dispatch(showLangSwitch(true));
  }, []);

  useEffect(() => {
    switch (menuItem.menuId) {
      case 300:
      case 301:
        dispatch(setEsidExpTabPaneActive(esidExpTabsEnum.ExpRegis));
        history.push('/Esid/Export');
        break;
      default:
        getMenuGuides();
        break;
    }
  }, [langId, menuItem]);
  
  const scrollTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };
  const getMenuGuides = () => {
    setLoading(true);
    API.getMenu(langId)
      .then((res) => {
        let data = [];
        res.data.data.forEach((dt) => {
          if (langId === "2") {
            if (dt.parent === 6) data.push(dt);
          } else {
            if (dt.parent === 60) data.push(dt);
          }
        });
        setMenuItems(data);
      })
      .catch((err) => console.log(err));
    setLoading(false);
  };

  const handleChange = (e) => {
    if (e.key === "thu-tuc-nhan-hang-nhap")
      history.push(`/${e.key}/${e.key}-ca-nhan`);
    else if (e.key !== "e-invoice" && e.key !== "tra-cuu-hoa-don-dien-tu") {
      scrollTop();
      history.push(langId === "2" ? `/huong-dan/${e.key}` : `/guides/${e.key}`);
    } else {
      window.open("http://e-invoice.tcs.com.vn/", "_blank");
      return;
    }
    const menu = {
      key: e.key,
      title: e.item.props.title,
      banner: e.item.props.banner,
      menuId: e.item.props.menuid,
      menuParentId: e.item.props.menuparentid,
      menuParentTitle: e.item.props.menuparenttitle,
      images: e.item.props.images,
      referenceId: e.item.props.referenceid,
    };
    dispatch(menuItemSelected(menu));
    localStorage.setItem("menuItem", JSON.stringify(menu));
  };

  const renderContentHTML = () => {
    switch (location.pathname) {
      case "/huong-dan/dang-ky-hang-hoa-vao-kho-tcs":
      case "/guides/cargo-acceptance-registration":
        return <TabRegistryInventory />;
      case "/huong-dan/giai-dap-thong-tin-truc-tiep-cho-khach-hang":
      case "/huong-dan/huong-dan-cac-thu-tuc-hanh-chinh":
      case "/guides/cargo-acceptance-registration":
        return <GuideProcedure />;
      case "/huong-dan/dang-ky-xe-cho-hang":
      case "/guides/cargo-vehicle-registration":
        return <TabRegistryCar />;
      default:
        return <GuideContent />;
    }
  };

  return (
    <Row className="guide-wrapper">
      {menuItem.banner &&
        <Banner
          title={menuItem.menuParentTitle}
          className="banner-wrapper"
          cover={`${Constant.IMAGES_URL}${menuItem.banner}`}
        />
      }
      <Skeleton loading={loading} active={loading}>
        {menuItems.length > 0 && (
          <>
            <Row style={{ width: "100%" }}>
              <Col offset={3} span={17}>
                <Menu
                  className="no-padding"
                  onClick={handleChange}
                  mode="horizontal"
                  triggerSubMenuAction="click"
                >
                  {menuItems.map((item) => {
                    return (
                      <Menu.Item
                        className="child-menu"
                        key={item.alias}
                        banner={menuItem.banner}
                        menuparenttitle={menuItem.menuParentTitle}
                        menuparentid={menuItem.menuParentId}
                        menuid={item.id}
                        images={item.images}
                        referenceid={item.reference_id}
                        title={item.name}
                      >
                        {item.name}
                      </Menu.Item>
                    );
                  })}
                </Menu>
              </Col>
            </Row>
            <Row className="guide-title-wrapper">
              <Col className="guide-title" span={"auto"}>
                <h2>{menuItem.title}</h2>
              </Col>
            </Row>
            <div className="guide-content-wrapper">
              <div className="guide-content">
                {
                  /* {location.pathname ===
                  "/huong-dan/dang-ky-hang-hoa-vao-kho-tcs" ||
                location.pathname ===
                  "/guides/cargo-acceptance-registration" ? (
                  <TabRegistryInventory />
                ) : location.pathname ===
                    "/huong-dan/huong-dan-cac-thu-tuc-hanh-chinh" ||
                  location.pathname ===
                    "/guides/cargo-acceptance-registration" ? (
                  <GuideProcedure />
                ) : (
                  <GuideContent />
                )} */
                  renderContentHTML()
                }
              </div>
            </div>
          </>
        )}
      </Skeleton>
    </Row>
  );
};

export default Guides;
