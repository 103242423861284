import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import App from "../App";
import QRReaderView from "../components/QRReaderView";
import { MainAdmin } from "../pages/admin";
import { EsidExp } from "../pages/admin/EsidExport";
import { GuardedRoute } from "./GuardedRoute";
import enUS from "antd/es/locale/en_US";
import viVN from "antd/es/locale/vi_VN";
import moment from "moment";
import "moment/locale/vi";
import { ConfigProvider } from "antd";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useState } from "react";
import { ExpPermission } from "../pages/admin/Permission/ExpPermission";
import RoleClaim from "../constant/roleClaim";
import { EsidExpList } from "../pages/admin/EsidExport/components/EsidList/EsidExpList";
moment.locale("en");

export default () => {
  const [locale, setLocal] = useState(enUS);
  const langId = useSelector((state) => state.menu.langId); // 1:en, 2:vi
  
  useEffect(() => {
    if (langId === 1) {
      setLocal(enUS);
      // moment.locale("en");
    } else if (langId === 2) {
      setLocal(viVN);
      // moment.locale("vi");
    }
  }, [langId]);

  return (
    <ConfigProvider locale={locale}>
      <Router>
        <Switch>
          <Route path="/nvesid/:path?" exact>
            <MainAdmin>
              <GuardedRoute path="/nvesid" exact>
                <EsidExp />
              </GuardedRoute>
              <GuardedRoute path="/nvesid/QRReader">
                <QRReaderView />
              </GuardedRoute>
              <GuardedRoute 
                path="/nvesid/EsidList"
                allowedRoles={[RoleClaim.EsidDocStaff, RoleClaim.EsidAdmin]}
              >
                <EsidExpList />
              </GuardedRoute>
              <GuardedRoute 
                path="/nvesid/Permission" 
                allowedRoles={[RoleClaim.EsidAdmin]}
                exact
              >
                <ExpPermission />
              </GuardedRoute>
            </MainAdmin>
          </Route>
          <Route>
            <App />
          </Route>
        </Switch>
      </Router>
    </ConfigProvider>
  );
};
