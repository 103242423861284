import React, { useEffect } from 'react';
import { BillingAddrForm } from './BillingAddrForm';
import { Button, Divider, Form, Input, Spin, Typography, notification } from 'antd';
import { InvoiceTable } from './InvoiceTable';
import { useTranslation } from 'react-i18next';
import { objectToAntFormData } from '../../tracking/CertLivAnm/TabExpCertLivAnm';
import { InvoiceDetailTable } from './InvoiceDetailTable';
import { updateExpWebChk } from '../../../config/esidExportApi';
import { errorHandling } from '../../../config/AuthIntercept';
import { useHistory, useLocation } from 'react-router-dom';

export const EsidInvoiceInfo = ({ handleNextTab, handlePrevTab, invoices, invoicesDetail, isFetchData, chkLoadData, fetchData }) => {
  const { t } = useTranslation("common");
  const [billForm] = Form.useForm();
  const [rcvForm] = Form.useForm();
  useEffect(() => {
    if (invoicesDetail) {
      billForm.setFields(objectToAntFormData(invoicesDetail[0]));
    }
    // eslint-disable-next-line
  }, [invoicesDetail])
  useEffect(() => {
    if (chkLoadData) {
      rcvForm.setFields(objectToAntFormData({einvoiceEmail: chkLoadData.EML_RCV_EINV}))
    }
    // eslint-disable-next-line
  }, [chkLoadData])
  const history = useHistory();
  const location = useLocation();

  const handleSaveEmail = async (value) => {
    if (value && value.einvoiceEmail) {
      const count = value.einvoiceEmail.toUpperCase().split(";");
      if (count.length > 3) {
        notification.error({
          message: t("esidCheckout.multipleEmail")
        })
        const input = document.querySelector('input#einvoiceEmail')
        if (input) input.focus() 
        return;
      }
      if (chkLoadData) {
        const postValue = {
          ...chkLoadData,
          EML_RCV_EINV: value.einvoiceEmail.toUpperCase(),
        }
        try {
          await updateExpWebChk(postValue);
          notification.success({
            message: "Cập nhật thông tin thành công!",
          });
          fetchData();
        } catch (error) {
          errorHandling(error, history, location.pathname, true);
        }
      }
    }
  }
  
  return (
    <>
      <div style={{ padding: '5px 0'}} className='esidInvoiceInfo'>
        <div style={{ width: '100%', gap: 5, display: 'flex', justifyContent: 'space-between'}}>
            <Form onFinish={handleSaveEmail} form={rcvForm} autoComplete="off">
              <div style={{ display: 'flex', gap: 5 }}>
                <Form.Item 
                  labelCol={{ span: 24 }} 
                  label={<Typography.Text strong style={{ color: 'red'}}>
                    {t("esidCheckout.eInvoiceEmail")}
                  </Typography.Text>}
                  name={"einvoiceEmail"}
                  style={{ width: 500 }}
                  rules={[
                    {
                      // eslint-disable-next-line
                      pattern: new RegExp(/^(([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)(\s*;\s*|\s*$))*$/),
                      message: t("esidCheckout.unvalidatedEmail"),
                    },
                    {
                      required: true,
                      message: t("esidCheckout.requiredEmail")
                    }
                  ]}
                  help={t("esidCheckout.multipleEmail")}
                >
                  <Input />
                </Form.Item>
                <Form.Item 
                  help={<span style={{ opacity: 0, pointerEvents: 'none'}}> </span>} 
                  label={<span style={{ opacity: 0, pointerEvents: 'none'}}> </span>} 
                  labelCol={{ span: 24}}
                >
                  <Button htmlType='submit'>
                    {t("esidCheckout.saveEmailBtn")}
                  </Button>
                </Form.Item>
              </div>
              
            </Form>
            <div style={{ gap: 5, display: 'flex' }}>
              <Button onClick={handlePrevTab} style={{ width: 'fit-content'}}>{t("esidCheckout.prevBtn")}</Button>
              <Button onClick={handleNextTab} style={{ width: 'fit-content'}}>{t("esidCheckout.checkoutBtn")}</Button>
            </div>
        </div>
        <Divider style={{ marginTop: 0, marginBottom: 8}} />
        <BillingAddrForm form={billForm} />
        <InvoiceTable invoices={invoices} />
        <InvoiceDetailTable invoiceDetails={invoicesDetail}/>
      </div>
      {
        isFetchData && 
          <div style={{ position: 'fixed', inset: '0', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#0000003d', zIndex: '3'}}>
            <Spin size='large' tip={t(`esidCheckout.loading`)} />
          </div> 
      }
    </>
  )
}