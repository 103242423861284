const RoleClaim = {
    // roles for web portal
    "Guest": "Guest",
    "Admin": "Admin", // user_code = admi
    "BusinessAnalyst": "BusinessAnalyst", // user_code = t888
    "VipAgent": "VipAgent", // user_code = ad
    "Agent": "Agent", // default
    "Airlines": "Airlines", // user_code starts with 90
    "CheckInOnl": "CheckInOnl", // Check-in Online, user_name starts with TCS
    //
    "EsidAdmin": "EsidAdmin",
    "EsidDocStaff": "EsidDocStaff", 
    "EsidDgStaff": "EsidDgStaff",
    "EsidACPT": "EsidACPT", // ESID Export Acceptance
    "Export": "Export", // Export for customer
    "EsidAccountant": "EsidAccountant",
    "EsidTecsAgent": "EsidTecsAgent",
    // roles for web admin related the navigation toolbar
    "Banner": 1,
    "Menu": 2,
    "UserAWB": 3,
    "Content": 4,
    "Link": 5,
    "Contact": 6,
    "Statistic": 7,
    "Feedback": 8,
    "Upload": 9,
    "User": 10
}

module.exports = RoleClaim