/* eslint-disable */
import React, { useRef, useState } from "react";
import {
  Button,
  Col,
  DatePicker,
  Form,
  notification,
  Row,
  Skeleton,
  Table,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { useReactToPrint } from "react-to-print";
import { setPaymentInfo } from "../../actions/payment";
import { checkinUser, userAwbLogout } from "../../actions/userAWB";
import { GET_SS_IMP_STATUS_SHP_V2 } from "../../config/webTrackApi";
const { RangePicker } = DatePicker;

const TabVIPInboundShippment = ({ setActiveKey }) => {
  const [tableDataSHP, setTableDataSHP] = useState();
  const [loading, setLoading] = useState();
  const { dataLogin } = useSelector((state) => state.userAwb);
  const today = new Date(),
    date =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate() +
      " " +
      today.getHours() +
      ":" +
      today.getMinutes();
  const { t } = useTranslation("common");
  const componentRef = useRef();
  const history = useHistory();
  const dispatch = useDispatch();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  // const handleRenderLetter = (value1, value2) => {
  // 	setRenderLetter(true);
  // 	setAWB({ HAWB: value1, MAWB: value2 });
  // };

  const renderTableSHP = (footerDocs, dataSource) => {
    let columns = [
      {
        title: t("agentReport.no"),
        dataIndex: "key",
        key: "key",
        className: "head_tb_repo",
      },
      {
        title: t("agentReport.fightNo"),
        dataIndex: "",
        key: "SS03_SHP_FLIGHT_NO",
        className: "head_tb_repo",
        children: [
          {
            title: "NO",
            dataIndex: "SS03_FLIGHT_NO",
            key: "SS03_FLIGHT_NO",
            className: "head_tb_repo",
          },
          {
            title: "DATE",
            dataIndex: "SS04_FLIGHT_DATE",
            key: "SS04_FLIGHT_DATE",
            className: "head_tb_repo",
          },
        ],
      },
      {
        title: t("agentReport.MAWB"),
        dataIndex: "SS01_MAWB_NO",
        key: "SS01_MAWB_NO",
        className: "head_tb_repo",
      },
      {
        title: "HAWB",
        dataIndex: "SS02_HAWB_NO",
        key: "SS02_HAWB_NO",
        className: "head_tb_repo",
      },
      {
        title: t("agentReport.Pieces"),
        dataIndex: "SS07_QTY_RCV_PCS",
        key: "SS07_QTY_RCV_PCS",
        className: "head_tb_repo",
      },
      {
        title: t("agentReport.GrossWeight"),
        dataIndex: "SS08_QTY_RCV_WEIGHT",
        key: "SS08_QTY_RCV_WEIGHT",
        className: "head_tb_repo",
      },
      {
        title: "FLIGHT DATE ATA",
        dataIndex: "SS12_ATA",
        key: "SS12_ATA",
        className: "head_tb_repo",
      },
      {
        title: "READY TO DELIVERY (Y/N)",
        dataIndex: "SS10_SHP_STATUS",
        key: "SS10_SHP_STATUS",
        className: "head_tb_repo",
      },
      {
        title: "CARGO STATUS",
        dataIndex: "SS13_SHC",
        key: "SS13_SHC",
        className: "head_tb_repo",
      },
      {
        title: "REMARKS",
        dataIndex: "SS09_IRR_INF",
        key: "SS09_IRR_INF",
        className: "head_tb_repo",
      },
      {
        title: "CUSTOMS CLEARENCE",
        dataIndex: "",
        key: "SS13_SHC",
        className: "head_tb_repo",
        children: [
          {
            title: "START",
            dataIndex: "SS15_CUS_STR_DATE",
            key: "SS15_CUS_STR_DATE",
            className: "head_tb_repo",
          },
          {
            title: "END",
            dataIndex: "SS16_CUS_END_DATE",
            key: "SS16_CUS_END_DATE",
            className: "head_tb_repo",
          },
        ],
      },
      {
        title: "Factory ATA",
        dataIndex: "SS11_GOODS_FL_DATE",
        key: "SS11_GOODS_FL_DATE",
        className: "head_tb_repo",
      },
    ];

    let date =
      today.getDate() +
      "/" +
      (today.getMonth() + 1) +
      "/" +
      today.getFullYear() +
      " " +
      today.getHours() +
      ":" +
      today.getMinutes() +
      ":" +
      today.getSeconds();

    setTableDataSHP(
      <Table
        className="table-bill"
        dataSource={dataSource}
        columns={columns}
        bordered
        scroll={{ x: "calc(300px + 50%)" }}
        pagination={{
          position: "bottomRight",
          defaultPageSize: 1000,
          hideOnSinglePage: true,
        }}
        summary={() => (
          <>
            <Table.Summary.Row>
              <Table.Summary.Cell index={0} colSpan={14}>
                {t("agentReport.total")} {footerDocs}
              </Table.Summary.Cell>
            </Table.Summary.Row>
            <Table.Summary.Row>
              <Table.Summary.Cell index={0} colSpan={14}>
                {t("agentReport.Time")} {date}
              </Table.Summary.Cell>
            </Table.Summary.Row>
            <Table.Summary.Row>
              <Table.Summary.Cell index={0}>
                {t("agentReport.Note")}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={0} colSpan={14} />
            </Table.Summary.Row>
          </>
        )}
      />
    );
  };
  const submitReport = async (value) => {
    try {
      setLoading(true);
      let dataSourceDocs = [];
      let footerDocs = "";
      let start_date =
        today.getDate() +
        "/" +
        (today.getMonth() + 1) +
        "/" +
        today.getFullYear();
      let end_date =
        today.getDate() +
        "/" +
        (today.getMonth() + 1) +
        "/" +
        today.getFullYear();
      let code = "*";
      let user_code;
      if (value.code !== undefined) {
        code = value.code;
      }

      if (value.user_code !== undefined) {
        user_code = value.user_code;
      }
      if (value.dateReport !== undefined) {
        start_date = value.dateReport[0].format("DD/MM/YYYY");
        end_date = value.dateReport[1].format("DD/MM/YYYY");
      }
      let data = {
        STR_CONNECT: "sss",
        START_DATE: start_date,
        END_DATE: end_date,
        FLIGHT_NO: "*",
        AWB_NO: code,
        AGENT_CODE:
          dataLogin.data.user_code === "admi"
            ? user_code
            : dataLogin.data.user_code,
        LD_BY_FLT: "N",
        LD_BY_DLV: "N",
        LD_BY_CUS: "N",
      };

      await GET_SS_IMP_STATUS_SHP_V2(data, dataLogin.token).then((res) => {
        if (res && res.status === 200 && res.data && res.data.mCol.length > 0) {
          res.data.mCol.map((data, index) => {
            dataSourceDocs.push({
              key: index + 1,
              Key: data.Key,
              SS01_MAWB_NO: data.SS01_MAWB_NO,
              SS02_HAWB_NO: data.SS02_HAWB_NO,
              SS03_FLIGHT_NO: data.SS03_FLIGHT_NO,
              SS04_FLIGHT_DATE: data.SS04_FLIGHT_DATE,
              SS05_QTY_MAN_PCS: data.SS05_QTY_MAN_PCS,
              SS06_QTY_MAN_WEIGHT: data.SS06_QTY_MAN_WEIGHT,
              SS07_QTY_RCV_PCS: data.SS07_QTY_RCV_PCS,
              SS08_QTY_RCV_WEIGHT: data.SS08_QTY_RCV_WEIGHT,
              SS09_IRR_INF: data.SS09_IRR_INF,
              SS10_SHP_STATUS: data.SS10_SHP_STATUS,
              SS11_GOODS_FL_DATE: data.SS11_GOODS_FL_DATE,
              SS12_ATA: data.SS12_ATA,
              SS13_SHC: data.SS13_SHC,
              SS14_DLV_DATE: data.SS14_DLV_DATE,
              SS15_CUS_STR_DATE: data.SS15_CUS_STR_DATE,
              SS16_CUS_END_DATE: data.SS16_CUS_END_DATE,
            });
            footerDocs = index + 1;
          });
          renderTableSHP(footerDocs, dataSourceDocs);
          setLoading(false);
        } else {
          renderTableSHP(footerDocs, dataSourceDocs);
        }
      });
    } catch (error) {
      if (error.response.status === 401) {
        notification.error({
          style: { marginRight: "-50%" },
          message: t("home.sessionNoti"),
        });

        dispatch(checkinUser({}));
        dispatch(setPaymentInfo(undefined));
        dispatch(userAwbLogout());
        localStorage.removeItem("userAWB");
        localStorage.removeItem("checkin");
        localStorage.removeItem("pInfo");
        history.push("/");
      }
    }
  };

  const [form] = useForm();
  const tailLayout = {
    wrapperCol: { offset: 1, span: 16 },
  };
  const dateFormat = "DD-MM-YYYY";

  function disabledDate(current) {
    return current && current > moment().endOf("day");
  }

  return (
    <div>
      {/* <BackTop /> */}
      <Row className="awb-tab-content">
        <Row className="control-wrapper">
          <Col span={24} className="control">
            <Form
              className="form-report"
              onFinish={submitReport}
              layout={"inline"}
              initialValues={{
                dateReport: [
                  moment(today, dateFormat),
                  moment(today, dateFormat),
                ],
                awbNo: "*",
              }}
              form={form}
              autoComplete="off"
            >
              <Form.Item
                style={{ marginLeft: "35px" }}
                {...tailLayout}
                label={t("header.dateReport")}
                name="dateReport"
              >
                <RangePicker
                  disabledDate={disabledDate}
                  format={dateFormat}
                  className="flight-date"
                />
              </Form.Item>
              <Form.Item>
                <Button style={{ width: "fit-content" }} htmlType={"submit"}>
                  {t("header.click")}
                </Button>
              </Form.Item>
              <Form.Item>
                <Button style={{ width: "fit-content" }} onClick={handlePrint}>
                  {t("header.print")}
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
        <Row ref={componentRef} className="result-wrapper">
          <Row className="result-content-wrapper">
            <Col span={24} className="result-content">
              <h5>REPORT FOR INBOUND SHIPMENT</h5>
              <Skeleton loading={loading}>{tableDataSHP}</Skeleton>
            </Col>
          </Row>
        </Row>
      </Row>
    </div>
  );
};
export default TabVIPInboundShippment;
