import React, { useEffect, useState } from "react";
import { ModalName, UserInviteStatus } from "../../../constant/enumerations";
import { useDispatch, useSelector } from "react-redux";
import { Checkbox, Col, Form, Input, Modal, Row, Select, Typography } from "antd";
import { setEsidStfList, setEsidStfUser, setModalEsidVisibility } from "../../../actions/esidExportAction";
import { LoadingOutlined } from "@ant-design/icons";
import { ExpGetNamStf, ExpInviteUser, ExpRemoveUser } from "../../../config/nvesidExportApi";
import { errorHandling } from "../../../config/AuthIntercept";
import { convertBoolToString, convertStringYToBool } from "../../../utils/utils";

const esidStatus = [
  { label: "ALL - Xem tất cả", value: "ALL" },
  { label: "NY - Đã đăng ký ESID", value: "NY" },
  { label: "VR - Đã đăng ký xe", value: "VR" },
  { label: "DR - Đã được DG tiếp nhận", value: "DR" },
  { label: "FR - Hoàn thành tiếp nhận", value: "FR" },
  { label: "AQ - Đã có số thứ tự", value: "AQ" },
  { label: "AC - Đã tính tiền", value: "AC" },
  { label: "AM - Đã chọn hình thức thanh toán", value: "AM" },
  { label: "AP - Đã thanh toán qua thẻ", value: "AP" },
  { label: "QR - Thanh toán bằng mã QR", value: "QR" },
  { label: "FN - Đã xuất hóa đơn điện tử", value: "FN" },
  { label: "OK - Đã hoàn thành", value: "OK" },
  { label: "XX - Đã hủy", value: "XX" }
];

const denyString = 'Deny';

export const InviteUserModal = () => {
  const dispatch = useDispatch();
  const visibility = useSelector((state) => state.esidExport.modal[ModalName.InviteUserModal]);
  const usersList = useSelector(state => state.esidExport.stfList);
  const stfUser = useSelector((state) => state.esidExport.stfUser);
  const [checkAdmin, setCheckAdmin] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [form] = Form.useForm();const [timer, setTimer] = useState();
  const [namLoading, setNamLoading] = useState(false);
  const [checkViewEsid, setCheckViewEsid] = useState([]);

  useEffect(() => {
    form.setFieldsValue({
      ...stfUser,
      admin: convertStringYToBool(stfUser?.admin),
      docStaff: convertStringYToBool(stfUser?.docStaff),
      dgStaff: convertStringYToBool(stfUser?.dgStaff),
      acceptance: convertStringYToBool(stfUser?.acceptance),
      accountant: convertStringYToBool(stfUser?.accountant),
      tecs: convertStringYToBool(stfUser?.tecs)
    });
    if (stfUser?.admin === 'Y') {
      setCheckAdmin(true);
    }
    if (stfUser?.tecs === 'Y') {
      setIsTecs(true);
    }
    setCheckViewEsid(stfUser?.viewEsid ? stfUser.viewEsid.split(',') : [])
    // eslint-disable-next-line
  }, [stfUser]);

  const closeSelf = () => {
    dispatch(setModalEsidVisibility({ [ModalName.InviteUserModal]: false }));
  }

  const removeUser = async () => {
    if (!stfUser?.id) {
      return;
    }
    try {
      setSubmitLoading(true);
      const stfList = [...usersList];
      const idx = stfList.findIndex(x => x.codStfLog === stfUser.codStfLog);
      await ExpRemoveUser(stfUser.codStfLog);
      dispatch(setEsidStfUser(null));
      form.resetFields();
      if (idx > -1) {
        stfList.splice(idx, 1);
        dispatch(setEsidStfList(stfList));
      }
      setSubmitLoading(false);
      closeSelf();
    } catch (error) {
      setSubmitLoading(false);
      errorHandling(error);
    }
  }

  const permissionSubmit = async () => {
    try {
      setSubmitLoading(true);
      const values = await form.validateFields();
      const res = await ExpInviteUser({
        ...stfUser,
        ...values,
        admin: convertBoolToString(values.admin),
        docStaff: convertBoolToString(values.docStaff),
        dgStaff: convertBoolToString(values.dgStaff),
        acceptance: convertBoolToString(values.acceptance),
        accountant: convertBoolToString(values.accountant),
        tecs: convertBoolToString(values.tecs),
        viewEsid: checkViewEsid.length > 0 ? checkViewEsid.join(',') : denyString
      });
      const stfList = [...usersList];
      const idx = stfList.findIndex(x => x.id === res.data.id);
      if (idx > -1) {
        stfList[idx] = res.data;
      } else {
        stfList.push(res.data);
      }
      setSubmitLoading(false);
      dispatch(setEsidStfList(stfList));
      closeSelf();
    } catch (error) {
      setSubmitLoading(false);
      errorHandling(error);
    }
  }

  const getNamStf = (username) => {
    clearTimeout(timer);
    const timeId = setTimeout(async () => {
      if (!username) {
        return;
      }
      try {
        const stf = usersList.find(x => x.codStfLog === username);
        if (stf) {
          dispatch(setEsidStfUser(stf));
          return;
        }
        setNamLoading(true);
        const res = await ExpGetNamStf(username);
        form.setFieldsValue({namStf: res.data});
        setNamLoading(false);
      } catch (error) {
        form.setFieldsValue({namStf: ''});
        setNamLoading(false);
        await form.validateFields();
      }
    }, 500);
    setTimer(timeId);
  }

  const handleCheckAdmin = (evt) => {
    setCheckAdmin(evt.target.checked);
    if (evt.target.checked) {
      form.setFieldsValue({
        docStaff: false,
        dgStaff: false,
        acceptance: false,
        accountant: false,
        tecs: false,
      });
      setCheckViewEsid(["ALL"]);
    }
  }

  const [isTecs, setIsTecs] = useState(false);
  const handleCheckTecs = (evt) => {
    setIsTecs(evt.target.checked);
    if (evt.target.checked) {
      form.setFieldsValue({
        admin: false,
        accountant: false,
      });
    }
  }

  const handleCheckViewEsid = (checkedValues) => {
    if (checkedValues.includes("ALL") || checkAdmin) {
      setCheckViewEsid(["ALL"]);
    } else if (checkedValues.length + 1 === esidStatus.length) {
      setCheckViewEsid(["ALL"]);
    }
    else {
      setCheckViewEsid(checkedValues);
    }
  }

  return (
    <Modal
      title='Invite User'
      visible={visibility}
      onCancel={closeSelf}
      onOk={permissionSubmit}
      confirmLoading={submitLoading}
      width={600}
    >
      <Form form={form} layout="vertical" requiredMark>
        <Form.Item 
          name='codStfLog' 
          labelCol={{span: 24}}
          wrapperCol={{span: 24}}
          label={
            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100vw' }}>
              <div>LOG ID</div>
              {stfUser?.id && (
                <div>
                  <Typography.Link 
                    onClick={removeUser} 
                    style={{ fontWeight: 'bold', color: 'red' }}
                  >
                    Remove User
                  </Typography.Link>
                </div>
              )}
            </div>
          }
          rules={[{ required: true, message: 'Please input your LOG ID!' }]}
          required>
          <Input 
            autoComplete="Off"
            readOnly={stfUser?.id}
            onInput={e => e.target.value = e.target.value.toUpperCase()} 
            onChange={(e) => getNamStf(e.target.value.toUpperCase())}
          />
        </Form.Item>
        <Form.Item name='namStf' label="NAME" required rules={[{ required: true, message: 'LOG ID invalid.' }]}>
          <Input readOnly suffix={namLoading ? <LoadingOutlined /> : null}/>
        </Form.Item>
        <Row>
          <Col span={6}>
            <Form.Item name='admin' noStyle valuePropName="checked">
              <Checkbox disabled={isTecs} onChange={handleCheckAdmin}>ADMIN</Checkbox>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name='docStaff' noStyle valuePropName="checked">
              <Checkbox disabled={checkAdmin}>DOC STAFF</Checkbox>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name='dgStaff' noStyle valuePropName="checked">
              <Checkbox disabled={checkAdmin}>DG STAFF</Checkbox>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name='acceptance' noStyle valuePropName="checked">
              <Checkbox disabled={checkAdmin}>ACCEPTANCE</Checkbox>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name='accountant' noStyle valuePropName="checked">
              <Checkbox disabled={checkAdmin || isTecs}>ACCOUNTANT</Checkbox>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name='tecs' noStyle valuePropName="checked">
              <Checkbox disabled={checkAdmin} onChange={handleCheckTecs}>TECS AGT</Checkbox>
            </Form.Item>
          </Col>
        </Row>
        <Form.Item label="VIEW ESID" style={{ marginTop: '10px' }}>
          <Checkbox.Group onChange={handleCheckViewEsid} value={checkViewEsid}>
            <Row style={{ marginLeft: '20px' }}>
              {esidStatus.map(status => (
                <Col key={status.value} span={status.value === "ALL" ? 24 : 12}>
                  <Form.Item noStyle valuePropName="checked">
                    <Checkbox value={status.value} disabled={checkViewEsid.includes("ALL") && status.value !== "ALL"}>{status.label}</Checkbox>
                  </Form.Item>
                </Col>
              ))}
            </Row>
          </Checkbox.Group>
        </Form.Item>
        <Form.Item name='status' label="STATUS" style={{ marginTop: '10px' }} required>
          <Select>
            <Select.Option value={UserInviteStatus.Grant}>{UserInviteStatus.Grant}</Select.Option>
            <Select.Option value={UserInviteStatus.Pending}>{UserInviteStatus.Pending}</Select.Option>
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
}