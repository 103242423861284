import {
  ADD_ITEM_TO_ESID_CTC_CMPs,
  GET_ESID_CTC_CMPs,
  SET_DECL_DGR_VISIBILITY,
  SET_ESID_EXPORT_SELECTED,
  SET_ESID_CTC_CMPs,
  SET_ESID_EXP_TAB_PANE_ACTIVE,
  SET_VHC_REG_SELECTED,
  SET_DIMENSION_SIZES,
  SET_TAB_ESID_VISIBILITY,
  SET_WEIGHT,
  UPDATE_ITEM_TO_ESID_CTC_CMPs,
  SET_SEARCH_CONDITION,
  SET_MODAL_ESID_VISIBILITY,
  SET_EXP_BIL_CHG,
  SET_EXP_BIL_CHG_LST,
  SET_ESID_LIST_CURRENT_PAGE,
  SET_ESID_SORTED_LIST_CONDITION,
  SET_EXP_CAR_DIM_TO_EDIT,
  SET_EXP_CAR_WGT_TO_EDIT,
  SET_EXP_CONSOL_DTL_LST,
  SET_EXP_CONSOL_DTL_EDT,
  SET_EXP_CONSOL,
  SET_EXP_CONSOL_REG_SERVICES,
  SET_EXP_CONSOL_EDT,
  SET_ESID_STF_LIST,
  SET_ESID_STF_USER
} from "../constant/actionTypes";
import { esidExpTabsEnum } from "../constant/enumerations";

const initialState = {
  cmpList: [],
  declDgrVisibility: false,
  declLiveAnimalsVisibility: false,
  tabPaneActive: esidExpTabsEnum.ExpRegis,
  tabEsidVisibility: esidExpTabsEnum.ExpRegis,
  searchCondition: null,
  esidListCurrentPage: 1,
  esidSortedListCondition: {},
  modal: {}
};

export const esidExportReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ESID_CTC_CMPs:
      return (state = { ...state, cmpList: action.payload });
    case SET_ESID_CTC_CMPs:
      return (state = { ...state, cmpList: action.payload });
    case ADD_ITEM_TO_ESID_CTC_CMPs:
      return (state = {
        ...state,
        cmpList: [...state.cmpList, action.payload],
      });
    case UPDATE_ITEM_TO_ESID_CTC_CMPs:
      return (state = {
        ...state,
        cmpList: [...action.payload],
      });
    case SET_DECL_DGR_VISIBILITY:
      return (state = { ...state, declDgrVisibility: action.payload });
    case SET_ESID_EXPORT_SELECTED:
      return (state = { ...state, esidExpSelected: action.payload });
    case SET_EXP_CAR_DIM_TO_EDIT:
      return (state = { ...state, expCarDimToEdit: action.payload });
    case SET_EXP_CAR_WGT_TO_EDIT:
      return (state = { ...state, expCarWgtToEdit: action.payload });
    case SET_EXP_CONSOL:
      return (state = { ...state, expConsol: action.payload });
    case SET_EXP_CONSOL_EDT:
      return (state = { ...state, expConsolEdit: action.payload });
    case SET_EXP_CONSOL_DTL_LST:
      return (state = { ...state, consolDtlList: action.payload });
    case SET_EXP_CONSOL_DTL_EDT:
      return (state = { ...state, consolDtlEdit: action.payload });
    case SET_EXP_CONSOL_REG_SERVICES:
      return (state = { ...state, consolRegServices: action.payload });
    case SET_EXP_BIL_CHG:
      return (state = { ...state, bilChg: action.payload });
    case SET_EXP_BIL_CHG_LST:
      return (state = { ...state, bilChgLst: action.payload });
    case SET_ESID_EXP_TAB_PANE_ACTIVE:
      return (state = { ...state, tabPaneActive: action.payload, tabEsidVisibility: action.payload });
    case SET_TAB_ESID_VISIBILITY:
      return (state = { ...state, tabEsidVisibility: action.payload });
    case SET_MODAL_ESID_VISIBILITY:
      return (state = { ...state, modal: { ...state.modal, ...action.payload } });
    case SET_VHC_REG_SELECTED:
      return (state = { ...state, vhcRegSelected: action.payload });
    case SET_DIMENSION_SIZES:
      return (state = { 
        ...state, 
        esidExpSelected: {
          ...state.esidExpSelected,
          expCarDim: action.payload
        } 
      });
    case SET_WEIGHT:
      return (state = { 
        ...state, 
        esidExpSelected: {
          ...state.esidExpSelected,
          expCarWgt: action.payload
        } 
    });
    case SET_SEARCH_CONDITION:
      return (state = { ...state, searchCondition: action.payload });
    case SET_ESID_LIST_CURRENT_PAGE:
      return (state = { ...state, esidListCurrentPage: action.payload });
    case SET_ESID_SORTED_LIST_CONDITION:
      return (state = { ...state, esidSortedListCondition: action.payload });
    case SET_ESID_STF_LIST:
      return (state = { ...state, stfList: action.payload });
    case SET_ESID_STF_USER:
      return (state = { ...state, stfUser: action.payload });
    default:
      break;
  }
  return state;
};
