import React from 'react'
import { Space, Typography, Button } from 'antd'
import { PlusOutlined } from '@ant-design/icons';
import { useTranslation } from "react-i18next";
// import { useSelector } from 'react-redux';
// import { EsidStatusEnum } from '../../../../../constant/enumerations';

const GoodsTableHead = ({setEditingKey, setModalVisible, addForm, disabled}) => {
  const { t } = useTranslation("common");
  return (
    <Space direction='horizontal'style={{ width: '100%', justifyContent: 'space-between'}}>
        <Typography.Text strong>{t("dangerousGood.tableInfo.title")}</Typography.Text>
        <div style={{ width: '100%', display: 'flex', justifyContent:'flex-end', paddingInline: 5, margin: 'auto'}}>
          <Button 
            type='primary'
            onClick={() =>{
                setEditingKey('')
                setModalVisible(true);
                addForm.resetFields();
            }} 
            icon={<PlusOutlined/>}
            disabled={disabled}
          >
              {`${t("addBtn")}`}
          </Button>
        </div>
    </Space>
  )
}

export default GoodsTableHead