import React from 'react'
import styles from './payment.module.css'
import { Typography, Divider } from 'antd'
import { useTranslation } from 'react-i18next'
import { formatCurrency } from '../../../utils/utils'

export const ReceiptDetail = ({ chkLoadData }) => {
    const { t } = useTranslation("common");
    return (
        <div className={styles.receiptDetailContainer}>
            <div className={styles.detailContainer}>
                <div className={styles.detailItem} style={{ marginBottom: 5, backgroundColor: '#fafafa' }}>
                    <Typography.Title level={4}>{t("esidCheckout.receipt.title")}</Typography.Title>
                </div>
                <div className={styles.detailItem}>
                    <Typography.Text strong>{t("esidCheckout.receipt.paymentMod")}</Typography.Text>
                    <Typography.Text>{t(`esidCheckout.receipt.mod${chkLoadData.COD_PAY_MOD}`)}</Typography.Text>
                </div>
                <div className={styles.detailItem}>
                    <Typography.Text strong>{`RCV BANK`}</Typography.Text>
                    <Typography.Text>{chkLoadData.RCV_BNK_TRS_COD}</Typography.Text>
                </div>
                <Divider />
                <div className={styles.detailItem}>
                    <Typography.Text strong>{t("esidCheckout.receipt.total")}</Typography.Text>
                    <Typography.Text strong>{formatCurrency(chkLoadData.AMT_TOT, "VND")}</Typography.Text>
                </div>
            </div>
        </div>
    )
}
