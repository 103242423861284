import { SET_ESID_FORM, SET_DGR_GOODS, SET_UIDS, SET_ESID_LOADING, SET_DGR_SELECTED_VALUE, SET_DISABLE_EDIT_DRG_GOOD_FORM_DECL, SET_VISABLE_EDIT_DRG_GOOD_BUTTON, SET_DRG_SELECTED_TO_EDIT, SET_DGR_TYPE_STATUS_OK, SET_DRY_ICE_GOODS, SET_BATTERY_GOODS } from "../constant/actionTypes";

export const setUids = (uids) => {
  return {
    type: SET_UIDS,
    payload: uids
  }
}

export const setDgrGoods = (dgrGoods) => {
  return {
    type: SET_DGR_GOODS,
    payload: dgrGoods
  }
}

export const setEsidForm = (formValue) => {
  return {
    type: SET_ESID_FORM,
    payload: formValue
  }
}
export const setEsidLoading = (value) => {
  return {
    type: SET_ESID_LOADING,
    payload: value
  }
}
export const setDgrSelectedValue = (value) => {
  return {
    type: SET_DGR_SELECTED_VALUE,
    payload: value
  }
}
export const setDisableEditDrgGood = (value) => {
  return {
    type: SET_DISABLE_EDIT_DRG_GOOD_FORM_DECL,
    payload: value
  }
}
export const setIsVisableEditDrgGoodButton = (value) => {
  return {
    type: SET_VISABLE_EDIT_DRG_GOOD_BUTTON,
    payload: value
  }
}
export const setDgrSelectedToEdit = (value) => {
  return {
    type: SET_DRG_SELECTED_TO_EDIT,
    payload: value
  }
}
export const setDgrTypeStatusOK = (value) => {
  return {
    type: SET_DGR_TYPE_STATUS_OK,
    payload: value
  }
}
export const setDryIceGoods = (value) => {
  return {
    type: SET_DRY_ICE_GOODS,
    payload: value
  }
}
export const setBatteryGoods = (value) => {
  return {
    type: SET_BATTERY_GOODS,
    payload: value
  }
}