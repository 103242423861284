import { Layout, Divider } from "antd";
import React, { useEffect } from "react";
import SiderComponent from './Dashboard/components/sider/SiderComponent';
import { DashboardHead } from './Dashboard/components/DashboardHead';
import styles from './style.module.css'
import { useDispatch } from "react-redux";
import { userAwbLoggin } from "../../actions/userAWB";
import './styles/global.css'
import { useHistory } from "react-router-dom";
const { Content } = Layout;
export const MainAdmin = ({ children }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const tag = document.querySelector("meta[name=viewport]");
  if (!tag) {
    const metaTag = document.createElement("meta");
    metaTag.name = "viewport";
    metaTag.content = "width=device-width, initial-scale=1, maximum-scale=1.0, user-scalable=0, viewport-fit=cover";
    document.getElementsByTagName("head")[0].appendChild(metaTag);
  }

  if (localStorage.getItem('userAWB')) {
    dispatch(userAwbLoggin(JSON.parse(localStorage.getItem('userAWB'))));
  }
  
  const handleAddScrollEvent = (node) => {
    node.addEventListener('scroll', () => {
      node.classList.add(styles.scrollable);
      setTimeout(function() {
        node.classList.remove(styles.scrollable);
      }, 700); 
    })
  }
  let scrollRestoration = history.scrollRestoration;
  if (scrollRestoration) {
    scrollRestoration = "manual";
  }

  useEffect(() => {
    const content = document.querySelector('.contentContainer');
    handleAddScrollEvent(content);
    
    window.scrollTo(0,0);
  },[]);

  return (
    <div className="main-admin" style={{backgroundColor: '#F1F4F8', minHeight: '100dvh'}}>
      <Layout className={styles.MainLayout}>
        <SiderComponent />
        <Layout className={`${styles.Page}`}>
            <DashboardHead />
            <Divider className={styles.Divider} />
            <Content className={`${styles.Content} contentContainer`}>
              <div className={`${styles.ContentContainer} `}>
                {children}
              </div>
            </Content>
        </Layout>
      </Layout>
    </div>
  );
};
