/* eslint-disable */

import { Collapse } from "antd";
import React, { useEffect, useState } from "react";
import "../../components/component-css/GuideContent.css";
import * as API from "../../config/apiConfig";
import { useDispatch, useSelector } from "react-redux";
import Parser from "html-react-parser";
import { useLocation } from "react-router";
import { changeLang, menuItemSelected } from "../../actions/menu";

const { Panel } = Collapse;

const GuideProcedure = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { langId, menuItem } = useSelector((state) => state.menu);

  const [steps, setSteps] = useState([]);
  const setMenu = async (menuId, langId) => {
    const menuRes = await API.getMenuById(menuId);
    const menuParentRes = await API.getMenuById(menuRes.data.parent);
    const menuReference = {
      key: menuRes.data.alias,
      title: menuRes.data.name,
      menuParentTitle: menuParentRes.data.name,
      banner: menuParentRes.data.params,
      menuId: menuRes.data.id,
      menuParentId: menuRes.data.parent,
      images: menuRes.data.images,
      referenceId: menuRes.data.reference_id,
    };
    dispatch(changeLang(langId));
    localStorage.setItem("langId", langId || 1);
    localStorage.setItem("LANGUAGE", langId === "2" ? "vi" : "en");
    dispatch(menuItemSelected(menuReference));
    localStorage.setItem("menuItem", JSON.stringify(menuReference));
  };

  const getContent = async () => {
    const slug = location.pathname.slice(
      location.pathname.lastIndexOf("/") + 1
    );
    const menuIdRes = await API.getMenuBySlug(slug);
    const { id, lang_id } = menuIdRes.data;
    API.getListMenuDetail(langId, id).then((res) =>
      setSteps(res.data.data.reverse())
    );
    setMenu(id, lang_id + "");
  };

  useEffect(() => {
    getContent();
  }, [location]);

  useEffect(() => {
    API.getListMenuDetail(langId, menuItem.referenceId).then((res) =>
      setSteps(res.data.data.reverse())
    );
  }, [langId]);

  return (
    <>
      <Collapse accordion ghost>
        {steps.map((step) => {
          return (
            <Panel header={step.title} key={step.id}>
              {step.fulltext && Parser(step.fulltext)}
            </Panel>
          );
        })}
      </Collapse>
    </>
  );
};
export default GuideProcedure;
