import axios from 'axios';
import { notification } from 'antd';
import i18next from 'i18next';
import { logout } from "../config/apiConfig";
import { userAwbLogout } from '../actions/userAWB';
import { setMAWB } from '../actions/MAWB';

const instance = axios.create();

let store;
// eslint-disable-next-line
let history;

export const setupAxios = (_store, _history) => {
  store = _store;
  history = _history;
}

// Request interceptor for API calls
instance.interceptors.request.use(
  (config) => {
    const state = store.getState();
    const token = (state && state.userAwb && state.userAwb.token) || '';
    if (token) {
      config.headers = {
        Authorization: token,
      };
    }
    return config;
  },
  (error) => Promise.reject(error),
);

export const errorNotification = (error) => { // Handling Errors
  let errMessage = '';
  if (axios.isAxiosError(error)) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      errMessage = (error.response.data && error.response.data.detail) 
        || (error.response.data && error.response.data.title) 
        || (error.response.data && error.response.data.Message) ||
        error?.response?.data?.error ||
        `${error.response.status} ${error.response.statusText}`;
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      errMessage = 'No Server Response';
    } else {
      // Something happened in setting up the request that triggered an Error
      errMessage = error.message;
    }
  } else {
    errMessage = 'Different error than axios';
    console.log(error);
  }
  notification.error({
    message: i18next.t(`common:Oops`),
    description: i18next.t(`common:${errMessage}`),
  });
};

/**
 * Error handling
 * @param {*} error 
 * @param {*} _history 
 * @param {*} from 
 */
export const errorHandling = (error, _history, from = '/', notify = true) => {
  if (notify
    // error?.response?.status !== 403 &&
    // error?.response?.status !== 401
  ) {
    errorNotification(error);
  }
  if (_history) {
    if (error && error.response && 
      (error.response.status === 403 || error.response.status === 401)
    ) {
      if (store) {
        const state = store.getState();
        const token = (state && state.userAwb && state.userAwb.token) || '';
        const { dispatch } = store;
        try {
          logout(token);
          dispatch(userAwbLogout());
          dispatch(setMAWB({}));
          localStorage.removeItem("userAWB");
          localStorage.removeItem("MAWB");
        } catch (error) {
          console.warn(error);
        }
      }
      if (from?.toLocaleLowerCase()?.includes('/nvesid')) {
        _history.push("/nvesidLogin", {
          from: { pathname: from || "/" },
        });
      } else {
        _history.push("/AwbLogin", {
          from: { pathname: from || "/" },
        });
      }
    }
  }
}

// Response interceptor for API calls
// instance.interceptors.response.use(
//   (response) => response,
//   async (error) => {
//     const originalRequest = error.config;
//     const refreshToken = localStorage.getItem('refresh_token');
//     if (refreshToken && error.response.status === 401 && !originalRequest._retry) {
//       originalRequest._retry = true;
//       const accessToken = await refreshAccessToken(refreshToken);
//       if (!accessToken) return Promise.reject(error);
//       originalRequest.headers.Authorization = `Bearer ${accessToken}`;
//       return instance(originalRequest);
//     }
//     return Promise.reject(error);
//   },
// );

export default instance;
