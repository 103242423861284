/* eslint-disable */

import React, { useState, useEffect } from "react";
import { Col, Drawer, Menu, Row, Switch } from "antd";
import "./component-css/Navigator.css";
import * as API from "../config/apiConfig";
import * as Constant from "../config/Constant";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { menuItemSelected } from "../actions/menu";
import { CloseOutlined, MenuOutlined } from "@ant-design/icons";

const { SubMenu } = Menu;

const Navigator = () => {
  const [current, setCurrent] = useState("");
  const [menuItems, setMenuItems] = useState([]);
  const { langId} = useSelector((state) => state.menu);
  const dispatch = useDispatch();
  const [Items, setItems] = useState([]);
  const [drawerShow, setdrawerShow] = useState(false);

  let noChild = [];
  const scrollTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  const handleClick = (e) => {
    setCurrent(e.key);
    if (e.item) {
      const menu = {
        key: e.key,
        title: e.item.props.title,
        banner: e.item.props.banner,
        menuId: e.item.props.menuid,
        menuParentId: e.item.props.menuparentid,
        menuParentTitle: e.item.props.menuparenttitle,
        images: e.item.props.images,
        referenceId: e.item.props.referenceid,
      };
      dispatch(menuItemSelected(menu));
      localStorage.setItem("menuItem", JSON.stringify(menu));
      if (e.key !== "e-invoice" && e.key !== "tra-cuu-hoa-don-dien-tu") {
        scrollTop();
        // history.push(e.key);
      }
    }
  };

  useEffect(() => {
    API.getMenu(langId)
      .then((res) => {
        setMenuItems(res.data.data);
        let flag = false;
        for (let i = 0; i < res.data.data.length; i++) {
          for (let j = 0; j < res.data.data.length; j++) {
            if (res.data.data[j].parent === res.data.data[i].id) flag = true;
          }
          if (!flag) {
            noChild.push(res.data.data[i]);
          }
          flag = false;
        }
        setItems(noChild);
      })
      .catch((err) => console.log(err));
  }, [langId]);

  const renderNav = (data) => {
    return data.map((menuItem) => {
      if (!Items.includes(menuItem)) {
        if (menuItem.parent === 0 && menuItem.access === 1) {
          return (
            <SubMenu
              onTitleClick={handleClick}
              key={menuItem.alias}
              title={menuItem.name}
            >
              {data.map((childItem) => {
                if (childItem.parent === menuItem.id)
                  return (
                    <Menu.Item
                      banner={menuItem.params}
                      key={childItem.alias}
                      menuparenttitle={menuItem.name}
                      menuparentid={menuItem.id}
                      menuid={childItem.id}
                      images={childItem.images}
                      referenceid={childItem.reference_id}
                      title={childItem.name}
                    >
                      <img
                        style={{
                          height: "20px",
                          width: "20px",
                        }}
                        className="menu-logo"
                        alt="logo"
                        src={`${Constant.IMAGES_URL}${childItem.params}`}
                      />
                      {`/${menuItem.alias}/${childItem.alias}` ===
                        "/huong-dan/tra-cuu-hoa-don-dien-tu" ||
                      `/${menuItem.alias}/${childItem.alias}` ===
                        "/guides/e-invoice" ? (
                        <NavLink
                          to="/"
                          onClick={() => {
                            window.open(
                              "http://e-invoice.tcs.com.vn/",
                              "_blank"
                            );
                          }}
                        >
                          {childItem.name}
                        </NavLink>
                      ) : `/${menuItem.alias}/${childItem.alias}` ===
                        "/huong-dan/thu-tuc-nhan-hang-nhap" ? (
                        <NavLink
                          activeClassName="active-link"
                          to={`/thu-tuc-nhan-hang-nhap/thu-tuc-nhan-hang-nhap-ca-nhan`}
                        >
                          {childItem.name}
                        </NavLink>
                      ) : (
                        <NavLink
                          activeClassName="active-link"
                          to={`/${menuItem.alias}/${childItem.alias}`}
                        >
                          {childItem.name}
                        </NavLink>
                      )}
                    </Menu.Item>
                  );
                else return "";
              })}
            </SubMenu>
          );
        }
      } else {
        if (menuItem.parent === 0 && menuItem.access === 1)
          return (
            <Menu.Item
              onClick={handleClick}
              banner={menuItem.params}
              key={menuItem.alias}
              menuid={menuItem.id}
              referenceid={menuItem.reference_id}
              menuparenttitle={menuItem.name}
              title={menuItem.name}
            >
              <NavLink to={`/${menuItem.alias}`}>{menuItem.name}</NavLink>
            </Menu.Item>
          );
      }
    });
  };

  return (
    <React.Fragment>
      <Drawer
        closable={false}
        placement="left"
        maskStyle={{}}
        drawerStyle={{ background: "#fda400" }}
        visible={drawerShow}
        onClose={() => {
          setdrawerShow(false);
        }}
        bodyStyle={{ padding: "20px 0px" }}
      >
        <Menu
          className="navigator"
          onClick={handleClick}
          selectedKeys={[current]}
          mode="inline"
          style={{
            color: "#fff",
            background: "#fda400",
            borderColor: "transparent",
          }}
        >
          {/* kiem tra menu co menu con */}
          {renderNav(menuItems)}
        </Menu>
      </Drawer>
      <Row>
        <Col span={0} lg={24}>
          <Menu
            className="navigator"
            onClick={handleClick}
            selectedKeys={[current]}
            mode="horizontal"
          >
            {/* kiem tra menu co menu con */}
            {renderNav(menuItems)}
          </Menu>
        </Col>
        <Col span={24} lg={0}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {drawerShow ? (
              <CloseOutlined
                className="icon-close"
                style={{ fontSize: 26, cursor: "pointer" }}
                onClick={() => {
                  setdrawerShow(false);
                }}
              />
            ) : (
              <MenuOutlined
                className="icon-menu"
                style={{ fontSize: 26, cursor: "pointer" }}
                onClick={() => {
                  setdrawerShow(true);
                }}
              />
            )}
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Navigator;
