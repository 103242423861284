/* eslint-disable */
import { Menu } from "antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import "./component-css/FooterContent.css";

const FooterContent = () => {
  const { langId } = useSelector((state) => state.menu);
  const [current, setCurrent] = useState("");
  const history = useHistory();
  const handleClick = (e) => {
    setCurrent(e.key);
    if (e.key === "https://login.microsoftonline.com/") window.open(e.key);
    else history.push(`/${e.key}`);
  };
  const menuItems = [
    {
      key: "feedback",
      title: "Góp ý",
      titleEn: "Feedback",
    },
    {
      key: langId === "2" ? "lien-ket" : "link",
      title: "Liên kết",
      titleEn: "Link",
    },
    {
      key: "https://login.microsoftonline.com/",
      title: "Webmail",
      titleEn: "Webmail",
    },
  ];

  return (
    <div className="footer-links">
      <div>
        <h3>
          ® 2010 Copyright by Tan Son Nhat Cargo Services Joint Stock Company
          (TCS)
        </h3>
      </div>
      <div style={{ width: "300px" }}>
        <Menu
          className="navigator"
          onClick={handleClick}
          selectedKeys={[current]}
          mode="horizontal"
        >
          {menuItems.map((item) => {
            return (
              <Menu.Item key={item.key}>
                <a>{langId === "2" ? item.title : item.titleEn}</a>
              </Menu.Item>
            );
          })}
        </Menu>
      </div>
    </div>
  );
};
export default FooterContent;
