import moment from "moment";
import { v4 as uuidv4 } from 'uuid';
import stc from 'string-to-color';

/**
 * Function returning the build date(as per provided epoch)
 * @param epoch Time in milliseconds
 */
export const getBuildDate = (epoch) => {
  const buildDate = moment(epoch).format("DD-MM-YYYY HH:mm");
  return buildDate;
};

export const getImpCat = (ata, receiveDate) => {
  const hoursDiff = moment(receiveDate).diff(moment(ata), "hours");
  console.log(hoursDiff);
  if (hoursDiff < 3) return 1;
  else if (hoursDiff < 6) return 2;
  else if (hoursDiff < 9) return 3;
  else if (hoursDiff < 12) return 4;
  return 5;
};

export const convertNumberToTime = (value) => {
  const valueString = value.toString().padStart(4, "0");

  const m = valueString.substr(valueString.length - 2);
  let h = valueString.substring(0, valueString.length - 2);

  return h + ":" + m;
};

export const isNumeric = (value) => {
  if (typeof value !== 'string') {
    return false;
  }
  return !isNaN(value) && !isNaN(parseFloat(value));
}

export const formatCurrency = (number, currency = 'VND') => {
  return new Intl.NumberFormat('vi-VN', { style: 'currency', currency: currency}).format(number)
}

export const formatFltDateDisplay = 'DD-MMM-YYYY HH:mm';
export const formatFltDateInput = 'DD/MM/YYYY HH:mm';

export const createUUID = () => uuidv4();

export const stringToColor = (str = '') => stc(str);

export const convertStringYToBool = (str = 'N') => str === 'Y';
export const convertBoolToString = (value = false) => value ? 'Y' : 'N';

export const encodeUint8Array = (input) => {
  var keyStr = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";
  var output = "";
  var chr1, chr2, chr3, enc1, enc2, enc3, enc4;
  var i = 0;

  while (i < input.length) {
    chr1 = input[i++];
    chr2 = i < input.length ? input[i++] : Number.NaN; // Not sure if the index 
    chr3 = i < input.length ? input[i++] : Number.NaN; // checks are needed here

    enc1 = chr1 >> 2;
    enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
    enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
    enc4 = chr3 & 63;

    if (isNaN(chr2)) {
      enc3 = enc4 = 64;
    } else if (isNaN(chr3)) {
      enc4 = 64;
    }
    output += keyStr.charAt(enc1) + keyStr.charAt(enc2) +
      keyStr.charAt(enc3) + keyStr.charAt(enc4);
  }
  return output;
}

export const CaclDimVolWgt = (pcs, len, wid, hgt, keepDecimal) => {
  let wgtRet = pcs*len*wid*hgt/6000;
  const decimalValue = wgtRet - Math.floor(wgtRet);
  if (decimalValue >= 0.5) {
    return wgtRet - decimalValue + 1;
  }
  if (keepDecimal === 1) {
    if (0 < decimalValue && decimalValue < 0.5 ) {
      return wgtRet - decimalValue + 0.5
    } 
  }
  return wgtRet - decimalValue;
}

export const removeAccents = str =>
  str ? str.toLocaleUpperCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '') : '';