import React, { useEffect, useState } from 'react'
import { Form, Input, Row, Col, Button, DatePicker } from 'antd'
import { useTranslation } from "react-i18next";
import moment from "moment";
import { GetAllEsidExpByCondition } from '../../../../config/esidExportApi';
import { errorHandling } from '../../../../config/AuthIntercept';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setEsidListCurrentPage, setEsidSortedListCondition, setSearchCondition } from '../../../../actions/esidExportAction';

const { RangePicker } = DatePicker;
const SearchForm = ({ name, form, children, setDataSource, filterCodSts}) => {
    const { t } = useTranslation("common");
    const dateFormat = "DD-MM-YYYY";
    const { searchCondition } = useSelector((state) => state.esidExport)
    const [dates, setDates] = useState(searchCondition?.fromDate ? (
        [
            moment(searchCondition?.fromDate, dateFormat),
            moment(searchCondition?.toDate, dateFormat)
        ]) : ([
            moment().startOf('month'),
            moment().endOf('month')
        ]));
    const [dateValue, setDateValue] = useState(searchCondition?.fromDate ? (
        [
            moment(searchCondition?.fromDate, dateFormat),
            moment(searchCondition?.toDate, dateFormat)
        ]) : ([
            moment().startOf('month'),
            moment().endOf('month')
        ]));
    const [loading, setLoading] = useState(true);
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();

    const disabledDate = (current) => {
        if (!dates) {
            return false;
        }
        const tooLate = dates[0] && current.diff(dates[0], 'days') > 30;
        const tooEarly = dates[1] && dates[1].diff(current, 'days') > 30;
        return !!tooEarly || !!tooLate;
    };
    const onOpenChange = (open) => {
        if (open) {
          setDates([null, null]);
        } else {
          setDates(null);
        }
    };
    const handleRangeDateChange = (val) => {
        val && setDateValue([
            val[0].set({hour:0,minute:0,second:0,millisecond:0}),
            val[1].set({hour:23,minute:59,second:59,millisecond:0})
        ]);
    }
    const handleOnCalendarChange = (val) => {
        val && (
            val[0] === null ? 
            setDates([null, val[1].set({hour:23,minute:59,second:59,millisecond:0})]) :
            setDates([val[0].set({hour:0,minute:0,second:0,millisecond:0}), null])
        )
    }

    const areObjectsEqual = (obj1, obj2) => {
        const json1 = JSON.stringify(obj1);
        const json2 = JSON.stringify(obj2);
      
        return json1 === json2;
    }
    
    const handleSubmitSearch = async (formValue) => {
        const paramsValue = filterCodSts ? {
            esidId: formValue.esidId,
            awbPfx: formValue.awbPfx,
            awbNum: formValue.awbNum,
            codSts: filterCodSts,
            fromDate: new Date(dateValue[0]).toISOString(),
            toDate: new Date(dateValue[1]).toISOString(),
        } : {
            esidId: formValue.esidId,
            awbPfx: formValue.awbPfx,
            awbNum: formValue.awbNum,
            fromDate: new Date(dateValue[0]).toISOString(),
            toDate: new Date(dateValue[1]).toISOString(),
        }
        try {
            setLoading(true);
            dispatch(setSearchCondition({
                esidId: formValue.esidId ? (formValue.esidId) : (""),
                awbPfx: formValue.awbPfx ? (formValue.awbPfx) : (""),
                awbNum: formValue.awbNum ? (formValue.awbNum) : (""),
                fromDate: dateValue[0],
                toDate: dateValue[1],
            }))

            const searchCondtionFromSearchForm = {
                esidId: formValue.esidId,
                awbPfx: formValue.awbPfx,
                awbNum: formValue.awbNum,
                fromDate: dateValue[0],
                toDate: dateValue[1],
            }

            const searchConditionFromRedux = searchCondition;

            // if search condition changed, it will move to page 1
            // otherwhise, it wont 
            if(!areObjectsEqual(searchCondtionFromSearchForm, searchConditionFromRedux)) {
                dispatch(setEsidListCurrentPage(1));
                // if search condition changed, it will remove esid sort list condition
                dispatch(setEsidSortedListCondition({}));
            }
            // dispatch(setSearchCondition(paramsValue));
            const { data } = await GetAllEsidExpByCondition(paramsValue);
            const newDataSource = data.map((item) => ({
                ...item,
                awbPfxAndNum: `${item.codAwbPfx}${item.codAwbNum}`,
                fltCarAndNum: `${item.codFltCar}${item.codFltNum}`,
                datFltOrigin: item.datFltOri,
                esidId: item.id,
                key: item.id
            }));
            setDataSource(newDataSource);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            errorHandling(error, history, location.pathname);
        }
    }
    useEffect(() => {
        if(form && (searchCondition?.esidId || searchCondition?.awbPfx || searchCondition?.awbNum || searchCondition?.fromDate)) {
            setLoading(false);
            form.setFieldsValue({
                esidId: searchCondition?.esidId,
                awbPfx: searchCondition?.awbPfx,
                awbNum: searchCondition?.awbNum,
            });
            if(searchCondition?.fromDate) {
                handleRangeDateChange([
                    searchCondition?.fromDate,
                    searchCondition?.toDate
                ])
                handleOnCalendarChange([
                    searchCondition?.fromDate,
                    searchCondition?.toDate
                ])
            }
            handleSubmitSearch(form.getFieldsValue());
            // console.log(form.getFieldsValue())
            // console.log(new Date(dateValue[0]).toISOString())
            // console.log(new Date(dateValue[1]).toISOString())
        }
        else {
            handleSubmitSearch({});
        }
        // eslint-disable-next-line
    }, []);
    // console.log(defaultDateValue);

    return (
        <Form
            name={name}
            labelAlign='left'
            labelCol={{span: 24}}
            autoComplete="off"
            form={form}
            className="search-form"
            style={{ 
                width: '100%',
                border: '1px solid rgb(134, 130, 130)',
                padding: '10px 10px 0',
                position: 'relative',
                borderRadius: '8px',
            }}
            initialValues={{
                esidId: '',
                awbPfx: '',
                awbNum: '',
                dateSearch: searchCondition?.fromDate ? (
                    [
                        moment(searchCondition?.fromDate, dateFormat),
                        moment(searchCondition?.toDate, dateFormat)
                    ]) : ([
                        moment().startOf('month'),
                        moment().endOf('month')
                    ])
            }}
            onFinish={handleSubmitSearch}
        >
            <Row gutter={10} style={{width: '100%', margin: 0}}>
                <Col lg={4} md={10} sm={24} xs={24} >
                    <Form.Item
                        name="esidId"
                        label={t("esidExpList.esidId")}
                    >
                        <Input placeholder={t("esidExpList.esidId")} />
                    </Form.Item>
                </Col>
                <Col lg={8} md={14} sm={24} xs={24} style={{padding: 0}}>
                    <Form.Item label={t("esidExpList.awb")} style={{paddingInline: 5}}> 
                        <Row gutter={5} style={{width: '100%', margin: 0}}>
                            <Col lg={9} md={10} sm={10} xs={12} style={{padding: 0}}>
                                <Form.Item
                                    name="awbPfx"
                                    >
                                    <Input placeholder={t('Prefix')} />
                                </Form.Item>
                            </Col>
                            <Col lg={15} md={14} sm={14} xs={12}>
                                <Form.Item
                                    name="awbNum"
                                >
                                    <Input placeholder={t('AWB#')} />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form.Item>
                </Col>
                <Col lg={7} md={16} sm={24} xs={24} >
                    <Row gutter={5} style={{width: '100%', margin: 0}}>
                        <Col span={24} style={{padding: 0}}>
                            <Form.Item
                                required
                                label={t("esidExpList.dateRange")}
                                name="dateSearch"
                            >
                                <RangePicker
                                    value={dates || dateValue}
                                    disabledDate={disabledDate}
                                    onCalendarChange={handleOnCalendarChange}
                                    onChange={handleRangeDateChange}
                                    onOpenChange={onOpenChange}
                                    format={`${dateFormat}`}
                                    style={{width: '100%'}}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
                <Col lg={5} md={8} sm={24} xs={24} style={{height: '100%'}}>
                    <Form.Item
                        name="awbNum"
                        className="awb-tab-content"
                        label={" "}
                    >
                            <Button 
                                loading={loading}
                                type="primary" 
                                htmlType='submit'
                                style={{ width: "fit-content", marginLeft: 10}} 
                            >
                                {t("esidExpList.btnSearch")}
                            </Button>
                    </Form.Item>
                </Col>
            </Row>
            
            { 
                children && children
            }
        </Form>
    )
}

export default SearchForm