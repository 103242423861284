import {
    Button,
    Modal,
    Table,
  } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useHistory, useLocation } from 'react-router-dom';
import { errorHandling } from "../../../../../config/AuthIntercept";
import { GetAllEsidExpByCondition, GetEsidExportById } from "../../../../../config/nvesidExportApi";

import styles from '../../style.module.css'
import ModalSearchForm from "./ModalSearchForm";
import { formatFltDateDisplay } from "../../../../../utils/utils";

export const SelectEsidModal = ({ modalSearchForm, visible, setVisible, setEsidExport, redirectToEsidInfo }) => {
    // eslint-disable-next-line
    const [dataSource, setDataSource] = useState([]);
    const { t } = useTranslation("common");
    // eslint-disable-next-line
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const dateFormat = formatFltDateDisplay;
    const history = useHistory();
    const location = useLocation();
 
    const columns = [
        {
            title: 'AWB/ESID',
            dataIndex: 'awbPfxAndNum',
            width: '15%',
            sorter: (a, b) => a.awbPfxAndNum.localeCompare(b.awbPfxAndNum),
        },
        {
            title: t("registerCar.table.flightNo"),
            dataIndex: 'fltCarAndNum',
            width: '15%',
            sorter: (a, b) => a.fltCarAndNum.localeCompare(b.fltCarAndNum),
        },
        {
            title: t("registerCar.table.flightDate"),
            dataIndex: 'datFltOrigin',
            width: '15%',
            render: (_, record) => moment(record.datFltOrigin).format(dateFormat).toLocaleUpperCase(),
            sorter: (a, b) => moment(a.datFltOrigin).unix() - moment(b.datFltOrigin).unix()
        },
        {
            title: t("registerCar.table.esid"),
            dataIndex: 'esidId',
            width: '15%',
            sorter: (a, b) => a.esidId - b.esidId,
            defaultSortOrder: 'descend'
        },
        {
            title: t("esidExpList.table.dgrChk"),
            dataIndex: 'shcDgr',
            key: 'shcDgr',
            width: '7%',
            sorter: (a, b) => a.shcDgr - b.shcDgr,
            render: (_, record) => record.shcDgr === 'Y' ? 'Y' : ''
        },
        {
            title: t("esidExpList.table.dgrAcp"),
            dataIndex: 'dgrAcpStfCod',
            key: 'dgrAcpStfCod',
            width: '10%',
            sorter: (a, b) => a.dgrAcpStfCod.localeCompare(b.dgrAcpStfCod),
            render: (_, record) => record.dgrAcpStfCod || ''
        },
        {
            title: t("esidExpList.table.status"),
            dataIndex: 'codSts',
            width: '25%',
            render: (_, record) => (t(`esid.codSts.${record.codSts}`)),
            sorter: (a, b) => t(`esid.codSts.${a.codSts}`).localeCompare(t(`esid.codSts.${b.codSts}`))
        },
    ];
    
    const fetchEsidList = async () => {
        const initialParamsValue = {
            fromDate: new Date(moment().startOf('month')).toISOString(),
            toDate: new Date(moment()).toISOString(),
        }
        try {
            const { data } = await GetAllEsidExpByCondition(initialParamsValue);
            const newDataSource = data.sort((a, b) => {
                return new Date(a.datFltOri) - new Date(b.datFltOri);
            }).map((item) => ({
                awbPfxAndNum: `${item.codAwbPfx}-${item.codAwbNum}`,
                fltCarAndNum: `${item.codFltCar}${item.codFltNum}`,
                datFltOrigin: item.datFltOri,
                ...item,
                esidId: item.id,
                key: item.id
            }));
            setDataSource(newDataSource);
        } catch (error) {
            errorHandling(error, history, location.pathname);
        }
    }
    
    useEffect(() => {
        fetchEsidList();
        // eslint-disable-next-line
    },[])

    const clearSelected = () => {
        setSelectedRowKeys([]);
        setSelectedRows([]);
    }

    const [esidLoading, setEsidLoading] = useState(false);
    const onSelectChange = async (selectedRowKeys, selectedRows) => {
        try {
          if (selectedRows && selectedRows.length > 0) {
            setEsidLoading(true);
              const { data } = await GetEsidExportById(selectedRows[0].esidId); 
              setEsidExport({
                ...data,
                expCarDim: data.expCarDim ? 
                    data.expCarDim.map((dim, index) => (
                    {
                        ...dim,
                        key: dim.codSeqNum,
                    })) : [],
                expCarWgt: data.expCarWgt ?  
                    data.expCarWgt.map((wgt, index) => (
                    {
                        ...wgt,
                        key: index,
                    }
                    )) : [],  
              });
              setEsidLoading(false);
              setTimeout(() => setVisible(false), 200);
              clearSelected();
              redirectToEsidInfo();
            } 
        } catch (error) {
            errorHandling(error, history, location.pathname, false);
        }
      };
    // const onSelectChange = (selectedRowKeys, selectedRows) => {
    //     setSelectedRowKeys(selectedRowKeys);
    //     setSelectedRows(selectedRows);
    // };    
    return (
        <Modal
            title={t("esid.exportList")}
            className={styles.EsidModal}
            visible={visible}
            centered
            onCancel={() => {
                clearSelected();
                setVisible(false);
            }}
            footer={[
                <Button
                    key={`btnCancel1`}
                    className="btn-payment"
                    onClick={() => {
                        clearSelected();
                        setVisible(false);
                    }}
                    style={{ width: "fit-content", marginLeft: "5px" }}
                >
                Cancel
                </Button>,
                // <Button
                //     key={`btnOk1`}
                //     className="btn-payment"
                //     onClick={handleOk}
                //     style={{ width: "fit-content" }}
                // >
                // Ok
                // </Button>,
            ]}
            style={{ marginTop: 10 }}
        >
            <ModalSearchForm name='searchForm' setDataSource={setDataSource} form={modalSearchForm} />
            <Table
                bordered
                columns={columns}
                pagination={{
                    position: "bottomRight",
                    defaultPageSize: 100,
                }}
                rowSelection={{
                    selectedRowKeys,
                    fixed: true,
                    type: "radio",
                    onChange: onSelectChange,
                }}
                scroll={{x: 800, y: 340}}
                dataSource={dataSource}
                className={`${styles.Table} table-cutoff table-esid`}
                rowClassName='cursorPointer'
                loading={esidLoading}
                onRow={(record) => ({
                  onClick: () => onSelectChange([record.key], [record])  
                })}
            />
        </Modal>
  )
}
