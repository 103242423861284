import React, { useEffect } from 'react'
import { Col, Form, Row, Button } from 'antd'
import FadeIn from 'react-fade-in';
import { AwbInformationForm } from '../../../pages/admin/EsidExport/components/AWBInfo/AwbInformationForm';
import { HandlingInfomationForm } from '../../../pages/admin/EsidExport/components/GeneralInfo/HandlingInfomationForm';
import { useSelector } from 'react-redux';
import { objectToAntFormData } from '../../tracking/CertLivAnm/TabExpCertLivAnm';
import tempReqData from '../../../constant/tempDropdown';
import { useTranslation } from 'react-i18next';
const handlingInfoKeys = [
    "shcPer",
    "shcOth002",
    "shcVal",
    "shcAvi",
    "shcDgr",
    "shcVun",
    "shcHum",
    "shcDip",
    "shcVunNml",
    "shcEcort",
    "shcReinforce",
  ]
export const EsidAwbInfo = ({ handlePrevTab, handleNextTab }) => {
    const { t } = useTranslation("common");
    const [airWaybillForm] = Form.useForm();
    const [handlingInfoForm] = Form.useForm();
    const selectEsidExport = useSelector(state => state.esidExport.esidExpSelected);
    const selectAirports = useSelector((state) => state.airport.airports); 
    const getHandlingInfomation = (data) => {
      const checkboxArr = [];
      let shcOth001 = null;
      let shcCod002 = null;
      let shcColStr = null;
      let shcOth = null;
      let shcColTemReq = null;
      let shcColTemReqString = null;
      if (data) {
        handlingInfoKeys.forEach((item) => {
          if (data[item] && data[item] === 'Y') {
            checkboxArr.push(item);
          }
        });
        if (data.shcOth001 && data.shcOth001 === 'Y') {
          shcOth001 = 'checked'
        }
        if (data.shcOth && data.shcOth === 'Y') {
          shcOth = 'checked'
        }
        if (data.shcColStr && data.shcColStr === 'Y') {
          shcColStr = 'checked'
        }
        if (data.shcColTemReq) {
          const temp = tempReqData.find(x => x.code === data.shcColTemReq);
          shcColTemReq = data.shcColTemReq ?? null;
          shcColTemReqString = temp.title ?? null;
        }
        if (data?.shcCod002 === 'TEC') {
          shcCod002 = 'checked';
        }
        return {
          handlingInfomations: checkboxArr,
          shcColStr,
          shcOth,
          shcOth001,
          shcColTemReq,
          shcColTemReqString,
          shcOthReq: data.shcOthReq,
          shcCod002
        }
      }
    }
    useEffect(() => {
        if (selectEsidExport) {
            const oriFlgt = selectAirports.find((x) => x.codApt === selectEsidExport.codOri);
            const fdsFlgt = selectAirports.find((x) => x.codApt === selectEsidExport.codFds);
            airWaybillForm.setFields(objectToAntFormData({
                ...selectEsidExport,
                flightNo: `${selectEsidExport.codFltCar}${selectEsidExport.codFltNum}`,
                originName: oriFlgt && oriFlgt.namApt ? oriFlgt.namApt : null,
                fdsName:  fdsFlgt && fdsFlgt.namApt ? fdsFlgt.namApt : null
            }));
            handlingInfoForm.setFields(objectToAntFormData(getHandlingInfomation(selectEsidExport)));
        }
        // eslint-disable-next-line
    }, [selectEsidExport, selectAirports])
    return (
        <Row gutter={[10, 10]} style={{width: '100%', height: '100%', padding: '5px 0 20px', marginInline: 0}}>
            <Col span={24}>
                <div style={{ width: '100%', gap: 5, display: 'flex', justifyContent: 'flex-end'}}>
                    <Button onClick={handlePrevTab} style={{ width: 'fit-content'}}>{t("esidCheckout.prevBtn")}</Button>
                    <Button onClick={handleNextTab} style={{ width: 'fit-content'}}>{t("esidCheckout.nextBtn")}</Button>
                </div>
            </Col>
            <Col style={{width: '100%'}} lg={{span: 24}} md={24} sm={24}>
                <FadeIn >
                    <AwbInformationForm form={airWaybillForm} readOnly={true} />
                </FadeIn>
            </Col>
            <Col style={{width: '100%'}} lg={{span: 24}} md={24} sm={24}>
                <FadeIn >
                    <HandlingInfomationForm form={handlingInfoForm} readOnly={true}/>
                </FadeIn>
            </Col>
        </Row>
    )
}
