/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Row, Col, Form, Input, Button, notification, Tooltip } from "antd";
import Banner from "../../Banner";
import "../../../pages/page-css/AwbLogin.css";
import { useDispatch, useSelector } from "react-redux";
import { showLangSwitch } from "../../../actions/menu";
import { checkinUser, userAwbLoggin } from "../../../actions/userAWB";
import AccountInfo from "./AccountInfo";
import * as API from "../../../config/apiConfig";
import { useTranslation } from "react-i18next";
import { SyncOutlined } from '@ant-design/icons';

const SignIn = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { signedIn } = useSelector((state) => state.userAwb);
  const { t } = useTranslation("common");
  const [captcha, setCaptcha] = useState({});
  const [form] = Form.useForm();

  const refreshCaptchaCode = async () => {
    const captchatRes = await API.getCaptcha();
    if (captchatRes.data) {
      setCaptcha(captchatRes.data);
    }
  }

  const onSubmitLogin = async (values) => {
    try {
      setLoading(true);
      const signInRes = await API.signIn(values.username, values.password, captcha.id, values.captchaCode);
      if (signInRes.data.error) {
        notification.warning({
          message: signInRes.data.error,
          style: { marginRight: "-50%" },
        });
      }
      if (signInRes.data.mCol.length === 0) {
        notification.warning({
          style: { marginRight: "-50%" },
          message: "Tài khoản hoặc mật khẩu không đúng! Vui lòng kiểm tra lại!",
        });
      } else {
        const { COD_AWB_PFX, COD_AWB_NUM, COD_AWB_SFX, COD_HWB_NUM } =
          signInRes.data.mCol[0];
        notification.success({
          style: { marginRight: "-50%" },
          message:
            "Đăng nhập thành công! Phiên đăng nhập sẽ hết hạn sau 90 phút",
        });
        dispatch(userAwbLoggin({ data: { user_code: values.username }, token:  signInRes.data.token}));
        dispatch(
          checkinUser({
            ...signInRes.data.mCol[0],
            token: signInRes.data.token,
          })
        );
        localStorage.removeItem("userGuest");

        localStorage.setItem(
          "MAWB",
          JSON.stringify({
            first: COD_AWB_PFX,
            last: COD_AWB_NUM,
            suffix: COD_AWB_SFX,
            hawb: COD_HWB_NUM,
          })
        );
        localStorage.setItem(
          "userAWB",
          JSON.stringify({ data: { user_code: values.username }, token:  signInRes.data.token })
        );
        localStorage.setItem(
          "checkin",
          JSON.stringify({
            ...signInRes.data.mCol[0],
            token: signInRes.data.token,
          })
        );
      }
    } catch (err) {
      let mess = err.response.data.err;
      if (err.response.status === 403 && err.response.data === "invalid captcha") {
        mess = "Mã xác thực không đúng.";
      }
      if (err.response.status === 403 && err.response.data === "expired captcha") {
        mess = "Mã xác thực đã quá hạn.";
      }
      form.setFieldsValue({ captchaCode: ''});
      refreshCaptchaCode();
      if (err.response)
        notification.error({
          style: { marginRight: "-50%" },
          message: mess,
        });
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (localStorage.getItem("checkin")) {
      dispatch(checkinUser(JSON.parse(localStorage.getItem("checkin"))));
    }
    if (localStorage.getItem("userAWB")) {
      dispatch(userAwbLoggin(JSON.parse(localStorage.getItem("userAWB"))));
    }

    dispatch(showLangSwitch(false));
    refreshCaptchaCode();
  }, []);
  const tailLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 8 },
  };
  return (
    <Row className="login-wrapper">
      <Banner
        title={""}
        className="banner-wrapper"
        cover={"../../img/checkin-banner.jpg"}
      />
      {signedIn ? (
        <AccountInfo />
      ) : (
        <>
          <Row className="login-form-title-wrapper">
            <Col className="login-form-title" offset={4}>
              <h2>{t("header.description")}</h2>
            </Col>
          </Row>
          <Row className="login-form-wrapper">
            <Col span={24} className="form-wrapper">
              <Form className="login-form" onFinish={onSubmitLogin} form={form} autoComplete="off">
                <Form.Item
                  {...tailLayout}
                  label={t("header.username")}
                  name="username"
                  rules={[
                    { required: true, message: "Vui lòng nhập tên đăng nhập!" },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  {...tailLayout}
                  label={t("header.password")}
                  name="password"
                  rules={[
                    { required: true, message: "Vui lòng nhập mật khẩu!" },
                  ]}
                >
                  <Input.Password style={{ background: "#E8F0FE" }} />
                </Form.Item>
                <Form.Item label={t("home.captcha")} {...tailLayout} style={{ marginBottom: 0 }}>
                  <Form.Item
                    name="captchaCode"
                    rules={[{ required: true, message: t("home.required") }]}
                    style={{ display: 'inline-block', width: 'calc(100% - 140px)' }}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    style={{ display: 'inline-block', margin: '0 2px' }}
                  >
                    <img src={captcha.url} style={{ backgroundColor: 'whitesmoke', padding: '3px' }} />
                  </Form.Item>
                  <Form.Item
                    style={{ display: 'inline-block', margin: '0 2px' }}
                  >
                    <Tooltip title={t("home.refreshCaptcha")}>
                      <SyncOutlined 
                        style={{ cursor: 'pointer', fontSize: 18, marginLeft: '3px', marginTop: '3px' }} 
                        onClick={refreshCaptchaCode}
                      />
                    </Tooltip>
                  </Form.Item>
                </Form.Item>
                <Row>
                  <Col
                    span={24}
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <Form.Item>
                      <Button
                        style={{ width: "fit-content" }}
                        htmlType="submit"
                        loading={loading}
                      >
                        {t("header.login")}
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </>
      )}
    </Row>
  );
};
export default SignIn;
