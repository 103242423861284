import React from "react";
import { Col, Row } from "antd";
import { Link } from "react-router-dom";

const ImportResultNoDetails = (props) => {
  return (
    <>
      <Row className="result-content-wrapper">
        <Col className="awb-result-content" span={24}>
          <Row>
            <Col span={24} style={{ lineHeight: "45px" }}>
              <Link to="/AwbLogin/Agent">
                Để xem chi tiết về thông tin lô hàng, vui lòng Log In.
              </Link>
            </Col>
          </Row>
          <Row style={{ lineHeight: "30px" }}>
            <Col span={24}>Hoặc liên hệ đại lý:</Col>
          </Row>
          <Row style={{ lineHeight: "30px" }}>
            <Col span={2} className="customer-info-label">
              Tên:
            </Col>
            <Col className="customer-info">{props.customer.C06_NAME_VN}</Col>
          </Row>
          <Row style={{ lineHeight: "30px" }}>
            <Col span={2} className="customer-info-label">
              Địa chỉ:
            </Col>
            <Col className="customer-info">{props.customer.C07_ADDRESS_VN}</Col>
          </Row>
          <Row style={{ lineHeight: "30px" }}>
            <Col span={2} className="customer-info-label">
              Điện thoại:
            </Col>
            <Col className="customer-info">{props.customer.C03_TEL}</Col>
          </Row>
          <Row style={{ lineHeight: "30px" }}>
            <Col span={2} className="customer-info-label">
              Fax:
            </Col>
            <Col className="customer-info">{props.customer.C04_FAX}</Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};
export default ImportResultNoDetails;
