/* eslint-disable */

import React from "react";
import { Row, Col, Input, DatePicker, Form } from "antd";
import QRCode from "qrcode.react";
import moment from "moment";

export class RegistryToPrint extends React.PureComponent {
  constructor(props) {
    super(props);
  }
  render() {
    const { flightNo, flightDate } = this.props;
    const checkLanguage = localStorage.getItem("LANGUAGE");
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div className="awb-tab-content" style={{ width: "600px" }}>
          <div className="control-wrapper">
            <div>
              <Form
                layout="vertical"
                className="form-date"
                initialValues={{
                  controller: this.props.controller,
                  mawb: this.props.mawb,
                  hawb: this.props.hawb,
                  plateNo: this.props.plateNo,
                  weight: this.props.weight,
                  quantity: this.props.quantity,
                  nationalID: this.props.nationalID,
                  phone: this.props.phone,
                }}
                autoComplete="off"
              >
                <Row>
                  <Col span={24}>
                    <Form.Item label="Date" name="createdDate">
                      <DatePicker
                        defaultValue={this.props.createdDate}
                        style={{ width: "100%" }}
                        format="DD-MM-YYYY HH:mm"
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[10, 0]}>
                  <Col span={12}>
                    <Form.Item label="MAWB" name="mawb">
                      <Input type="text" maxLength={11} />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label="HAWB" name="hawb">
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[10, 0]}>
                  <Col span={12}>
                    <Form.Item label="Quantity" name="quantity">
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label="Weight" name="weight">
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[10, 0]}>
                  <Col span={12}>
                    <Form.Item label="Vehicle ID" name="plateNo">
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label="Driver Name" name="controller">
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[10, 0]}>
                  <Col span={12}>
                    <Form.Item label="Driver ID" name="nationalID">
                      <Input maxLength={12} />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label="Phone" name="phone">
                      <Input maxLength={10} />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </div>
          </div>
          <Row className="result-wrapper">
            <Row className="result-wrapper">
              <Col offset={5} span={14} className="result">
                <Row className="ticket-wrapper">
                  <Row className="control-button-group-wrapper">
                    <Col span={24} className="control qr-input-wrapper">
                      <Col className="qr-title" offset={7}>
                        Your Register:
                      </Col>
                      <Col className="qr-no" offset={1}>
                        {this.props.numberQR}
                      </Col>
                    </Col>
                  </Row>
                  <Row className="control-button-group-wrapper">
                    <Col offset={8} span={14} className="control qr-wrapper">
                      <QRCode value={`${this.props.QR}`} />
                    </Col>
                  </Row>
                </Row>
              </Col>
            </Row>
            <Row className="awb-tab-title-wrapper">
              <Col span={24} className="awb-tab-title">
                <h5>
                  {checkLanguage === "vi"
                    ? "QUÝ KHÁCH VUI LÒNG XUẤT TRÌNH MÃ ĐĂNG KÝ/QR TẠI CỔNG AN NINH TCS"
                    : " Please provide the registration code/QR code at the TCS security gate"}
                </h5>
              </Col>

              <Col
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
                className="registry-footer"
                span={12}
              >
                {checkLanguage === "vi" ? "Số hiệu chuyến bay" : "Flight No"}: {flightNo}
              </Col>
              <Col
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
                className="registry-footer"
                span={12}
              >
                {checkLanguage === "vi" ? "Ngày bay" : "Flight Date"}:{" "}
                {flightDate && moment(flightDate).format("MM/DD/YYYY")}
              </Col>
            </Row>
          </Row>
        </div>
      </div>
    );
  }
}
