export const USER_LOGGEDIN = 'USER_LOGGEDIN';
export const USER_GUEST = 'USER_GUEST';
export const USER_LOGGEDOUT = 'USER_LOGGEDOUT';
export const CHECKIN = 'CHECKIN';
export const MENU_ITEM_SELECTED = 'MENU_ITEM_SELECTED';
export const CHANGE_LANG = 'CHANGE_LANG';
export const SET_MAWB = 'SET_MAWB';
export const SET_HAWB = 'SET_HAWB';
export const POST_FEEDBACK = 'POST_FEEDBACK';
export const SHOW_SWITCH = 'SHOW_SWITCH';
export const SET_PAYMENT_INFO = 'SET_PAYMENT_INFO';
export const GET_ESID_CTC_CMPs = 'GET_ESID_CTC_CMPs';
export const SET_ESID_CTC_CMPs = 'SET_ESID_CTC_CPMs';
export const ADD_ITEM_TO_ESID_CTC_CMPs = 'ADD_ITEM_TO_ESID_CTC_CMPs';
export const UPDATE_ITEM_TO_ESID_CTC_CMPs = 'UPDATE_ITEM_TO_ESID_CTC_CMPs';
export const SET_AIRPORT_LIST = 'SET_AIRPORT_LIST';
export const SET_DECL_DGR_VISIBILITY = 'SET_DECL_DGR_VISIBILITY';
export const SET_ESID_EXPORT_SELECTED = 'SET_ESID_EXPORT_SELECTED';
export const SET_ESID_EXP_TAB_PANE_ACTIVE = 'SET_ESID_EXP_TAB_PANE_ACTIVE';
export const SET_VHC_REG_SELECTED = 'SET_VHC_REG_SELECTED';
export const SET_TAB_ESID_VISIBILITY = 'SET_TAB_ESID_VISIBILITY';
export const SET_EXP_BIL_CHG = 'SET_EXP_BIL_CHG';
export const SET_EXP_BIL_CHG_LST = 'SET_EXP_BIL_CHG_LST';
export const SET_MODAL_ESID_VISIBILITY = 'SET_MODAL_ESID_VISIBILITY';
export const SET_EXP_CAR_DIM_TO_EDIT = 'SET_EXP_CAR_DIM_TO_EDIT';
export const SET_EXP_CAR_WGT_TO_EDIT = 'SET_EXP_CAR_WGT_TO_EDIT';
export const SET_EXP_CONSOL = 'SET_EXP_CONSOL';
export const SET_EXP_CONSOL_EDT = 'SET_EXP_CONSOL_EDT';
export const SET_EXP_CONSOL_DTL_LST = 'SET_EXP_CONSOL_DTL_LST';
export const SET_EXP_CONSOL_DTL_EDT = 'SET_EXP_CONSOL_DTL_EDT';
export const SET_EXP_CONSOL_REG_SERVICES = 'SET_EXP_CONSOL_REG_SERVICES';
export const SET_ESID_STF_LIST = 'SET_ESID_STF_LIST';
export const SET_ESID_STF_USER = 'SET_ESID_STF_USER';
// dangerous goods
export const SET_ESID_FORM = 'SET_ESID_FORM';
export const SET_UIDS = 'SET_UIDS';
export const SET_DGR_GOODS = 'ADD_DGR_GOODS';
export const SET_ESID_LOADING = 'SET_ESID_LOADING';
export const SET_DGR_SELECTED_VALUE = 'SET_DGR_SELECTED_VALUE';
export const SET_DISABLE_EDIT_DRG_GOOD_FORM_DECL = 'SET_DISABLE_EDIT_DRG_GOOD_FORM_DECL';
export const SET_DGR_TYPE_STATUS_OK = 'SET_DGR_TYPE_STATUS_OK';
export const SET_DRY_ICE_GOODS = 'SET_DRY_ICE_GOODS';
export const SET_BATTERY_GOODS = 'SET_BATTERY_GOODS';

// acceptance
export const SET_DIMENSION_SIZES = 'SET_DIMENSION_SIZES';
export const SET_WEIGHT = 'SET_WEIGHT';
export const SET_VISABLE_EDIT_DRG_GOOD_BUTTON = 'SET_VISABLE_EDIT_DRG_GOOD_BUTTON';
export const SET_DRG_SELECTED_TO_EDIT = 'SET_DRG_SELECTED_TO_EDIT';
// live animals
export const SET_LIV_ANMS = 'SET_LIV_ANMS';
export const SET_LIVANMS_SELECTED_VALUE = 'SET_LIVANMS_SELECTED_VALUE';
export const SET_DISABLE_EDIT_LIV_ANM_FORM_DECL = 'SET_DISABLE_EDIT_LIV_ANM_FORM_DECL';
export const SET_VISABLE_EDIT_LIV_ANM_BUTTON = 'SET_VISABLE_EDIT_LIV_ANM_BUTTON';
export const SET_LIV_SELECTED_TO_EDIT = 'SET_LIV_SELECTED_TO_EDIT';

// esid search condition
export const SET_SEARCH_CONDITION = 'SET_SEARCH_CONDITION'

//esid list current page
export const SET_ESID_LIST_CURRENT_PAGE = 'SET_ESID_LIST_CURRENT_PAGE'

//esid sorted list condition
export const SET_ESID_SORTED_LIST_CONDITION = 'SET_ESID_SORTED_LIST_CONDITION'

