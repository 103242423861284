import React from "react";
import { Row, Col } from "antd";
import "./page-css/News.css";
import Banner from "../components/Banner";
import LoginStatisticForm from "../components/LoginStatisticForm";
import StatisticForm from "../components/StatisticForm";
import { useSelector } from "react-redux";
import RoleClaim from "../constant/roleClaim";

const Statistic = () => {
  const { dataLogin, currentUser } = useSelector((state) => state.userAwb);
  let isAuthen =
    currentUser.roles.includes(RoleClaim.BusinessAnalyst) ||
    dataLogin.data.user_code === "admi" ||
    dataLogin.data.user_code === "t888";
  return (
    <Row className="news-wrapper">
      <Banner className="banner-wrapper" cover={"../../img/004.jpg"} />
      <Row className="news-title-wrapper">
        <Col
          style={{ display: "flex", justifyContent: "center" }}
          className="news-title"
          span={"auto"}
        >
          <h2>Thống kê</h2>
        </Col>
      </Row>
      {isAuthen ? <StatisticForm /> : <LoginStatisticForm />}
    </Row>
  );
};

export default Statistic;
