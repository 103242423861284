import React, { useEffect, useState } from 'react'
import { Space, Table, Tooltip, Button, Upload, notification  } from 'antd';
import { useTranslation } from "react-i18next";
import * as XLSX from "xlsx";
import { EditOutlined, DeleteOutlined, UploadOutlined } from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux';
import { setExpConsolDtlEdit, setExpConsolDtlList, setModalEsidVisibility } from '../../../../actions/esidExportAction';
import { ModalName } from '../../../../constant/enumerations';
import { createUUID } from '../../../../utils/utils';
import RoleClaim from '../../../../constant/roleClaim';
import useHasRoles from '../../../../hooks/useHasRoles';

export const ConsolTable = () => {
  const { t } = useTranslation("common");
  const dispatch = useDispatch();
  const esidSelected = useSelector(state => state.esidExport.esidExpSelected);
  const expConsolEdit = useSelector(state => state.esidExport.expConsolEdit);
  const consolDtlList = useSelector(state => state.esidExport.consolDtlList);
  const consolDtlEdit = useSelector(state => state.esidExport.consolDtlEdit);
  const [hideAction, setHideAction] = useState(true);
  const [importing, setImporting] = useState(false);
  const hasAirlines = useHasRoles([RoleClaim.Airlines]);

  useEffect(() => {
    setHideAction(expConsolEdit?.codSts === 'OK');
  }, [expConsolEdit])

  const defaultColumns = [
    {
      title: 'STT',
      dataIndex: 'index',
      key: 'index',
      render: (_, record, index) => index + 1,
      width: '7%',
    },
    {
      title: 'SỐ VẬN ĐƠN PHỤ',
      dataIndex: 'codHwbNum',
      key: 'codHwbNum',
      editable: true,
      width: '20%',
    },
    {
      title: 'Số định danh hàng hóa',
      dataIndex: 'qrCod',
      key: 'qrCod',
      editable: true,
      width: '20%',
    },
    {
      title: 'SỐ KIỆN',
      dataIndex: 'qtyPcs',
      key: 'qtyPcs',
      editable: true,
      width: '10%',
    },
    {
      title: 'SỐ KÝ',
      dataIndex: 'qtyWgt',
      key: 'qtyWgt',
      editable: true,
      width: '10%',
      render: (value) => value || ''
    },
    {
      title: 'CHỦNG LOẠI HÀNG',
      dataIndex: 'natureOfGoods',
      key: 'natureOfGoods',
      editable: true,
      width: '20%',
    },
    {
      title: t("livAnm.tableInfo.action"),
      dataIndex: 'action',
      fixed: 'right',
      hidden: hideAction,
      width: '10%',
      render: (_, record) => {
        return record.key ? (
          <Space>
            <Tooltip title={"Edit"}>
              <Button type='text'  icon={<EditOutlined />} onClick={() => showConsolModalToEdit(record)} />
            </Tooltip>
            <Tooltip title={"Edit"}>
                <Button type='text' danger icon={<DeleteOutlined />} onClick={() => deleteConsolDtl(record)}/>
            </Tooltip>
          </Space>
        ) : null
      },
    },
  ].filter(column => !column.hidden);

  const showAddConsolModal = () => {
    dispatch(setModalEsidVisibility({ [`${ModalName.ConsolModal}`]: true }));
  }

  const showConsolModalToEdit = (dtl) => {
    dispatch(setExpConsolDtlEdit(dtl));
    dispatch(setModalEsidVisibility({ [`${ModalName.ConsolModal}`]: true}));
  }

  const deleteConsolDtl = (dtl) => {
    const dtlList = [...consolDtlList];
    const idx = dtlList.findIndex(x => x.key === dtl.key);
    if (idx > -1) {
      dtlList.splice(idx, 1);
    }
    dispatch(setExpConsolDtlList(dtlList));
  }

  const handleUploadProps = {
      beforeUpload: (file) => {
          setImporting(true);
          const promise = new Promise((resolve, reject) => {
              const fileReader = new FileReader();
              fileReader.readAsArrayBuffer(file);
              fileReader.onload = (e) => {
                  const bufferArray = e.target.result;
                  const wb = XLSX.read(bufferArray, { type: "buffer" });
                  const wsname = wb.SheetNames[0];
                  const ws = wb.Sheets[wsname];
                  const data = XLSX.utils.sheet_to_json(ws);
                  resolve(data);
              };
      
              fileReader.onerror = (error) => {
                  notification.error({
                      message: "Thông báo",
                      description: "Có lỗi xảy ra. Vui lòng thử lại sau."
                  });
                  reject(error);
              };
          });
          promise.then((data) => {
              // kiểm tra dữ liệu
              if (data?.length > 0) {
                  const firstRow = JSON.parse(JSON.stringify(data[0]));
                  const colKeys = Object.keys(firstRow).map(k => k.toLocaleLowerCase().trim());
                  if (!colKeys.includes('số vận đơn phụ') || // !colKeys.includes('số định danh hàng hóa') ||
                  !colKeys.includes('số ký') || !colKeys.includes('số kiện') || !colKeys.includes('chủng loại hàng')) {
                      setImporting(false);
                      notification.error({
                          message: 'Thông báo',
                          description: 'Định dạng tệp không đúng. Vui lòng thử lại.'
                      });
                      return;
                  }
                  const dtlList  = [...consolDtlList];
                  data.forEach(row => {
                    let codHwbNum = '';
                    let qrCod = '';
                    let qtyPcs = 0;
                    let qtyWgt = 0;
                    let natureOfGoods = '';
                    Object.entries(row).forEach(([key, value]) => {
                      if (key.toLocaleLowerCase().includes('số vận đơn phụ')) {
                        codHwbNum = value.toLocaleUpperCase();
                      }
                      if (key.toLocaleLowerCase().includes('số định danh hàng hóa')) {
                        qrCod = value || '';
                      }
                      if (key.toLocaleLowerCase().includes('số kiện')) {
                        qtyPcs = isNaN(value) ? 0 : Number(value);
                      }
                      if (key.toLocaleLowerCase().includes('số ký')) {
                        qtyWgt = isNaN(value) ? 0 : Number(value);
                      }
                      if (key.toLocaleLowerCase().includes('chủng loại hàng')) {
                        natureOfGoods = value.toLocaleUpperCase();
                      }
                    });
                    const dataDtl = {
                      esidId: esidSelected.id,
                      codAwbPfx: esidSelected.codAwbPfx,
                      codAwbNum: esidSelected.codAwbNum,
                      codAwbSfx: esidSelected.codAwbSfx,
                      codSts: 'NY',
                      key: createUUID(),
                      ...consolDtlEdit,
                      codHwbNum,
                      qrCod,
                      qtyPcs,
                      qtyWgt,
                      natureOfGoods
                    }
                    dtlList.push(dataDtl);
                  });
                  dispatch(setExpConsolDtlList(dtlList));
              }
              //
              setImporting(false);
              notification.success({
                  message: "Thông báo",
                  description: "Nhập khẩu dữ liệu thành công.",
              })
          });
          return false;
      },
  };

  return (
    <div style={{ marginBottom: '20px' }}>
      <div style={{ 
        display: 'flex', 
        justifyContent: 'flex-end', 
        marginBottom: '10px', 
        marginRight: '5px' 
      }}>
        {!hideAction && !hasAirlines && (
          <>
            <Button href='https://www.tcs.com.vn/api/downloadFileUpload/HAWB_ITEMS.xlsx' style={{ marginRight: 5 }}>{t('downloadTemplateFile')}</Button>
            <Upload {...handleUploadProps} showUploadList={false} accept='.xls, .xlsx'>
              <Button loading={importing} icon={<UploadOutlined />} style={{ marginRight: 5 }} >{t('importCsv')}</Button>
            </Upload>
            <Button type='primary' onClick={showAddConsolModal}>Thêm mới</Button>
          </>
        )}
      </div>
      <Table columns={defaultColumns} dataSource={consolDtlList} pagination={false} style={{ paddingInline: 5 }}/>
    </div>
  )
}
