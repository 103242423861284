import { Form, Input } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const AviDeclaration = ({form}) => {
  const { t } = useTranslation('common');

  const handleSaveAvi = (values) => {
    console.log(values);
  };

  return (
    <Form form={form} layout="vertical" onFinish={handleSaveAvi}>
      <Form.Item
        name='specHdlCod'
        label={t('livAnm.formInfo.specHdlCod')}
      >
        <Input />
      </Form.Item>
      <Form.Item name='desRmk' label={t('admin.acceptance.desRmk')}>
        <Input.TextArea rows={6} />
      </Form.Item>
    </Form>
  );
};
