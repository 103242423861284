/* eslint-disable */
import React, { useRef, useState } from "react";
import { Row, Col, Input, Form, Button, DatePicker, notification } from "antd";
import { useForm } from "antd/lib/form/Form";
import moment from "moment";
import QRCode from "qrcode.react";
import { useTranslation } from "react-i18next";
import { useReactToPrint } from "react-to-print";
import "moment/locale/en-au";
import * as API from "../config/apiConfig";
import { RegistryToPrint } from "./RegistryPrint";
import { ModalCutOffTime } from "./tracking/RegistryInvModal/ModalCutOffTime";
import { useEffect } from "react";
const TabRegistryInventory = () => {
  const [form] = useForm();
  const [QR, setQR] = useState("");
  const [numberQR, setNumberQR] = useState();
  const [values, setValues] = useState();
  const [flightNo, setFlightNo] = useState("");
  const [flightDate, setFlightDate] = useState(moment());
  const [QRToPrint, setQRToPrint] = useState();
  const [visibleFlightTable, setVisibleFlightTable] = useState(false);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation("common");
  const dateFormat = "DD-MM-YYYY HH:mm";
  const createQR = (value) => {
    setQR(<QRCode value={`${value}`} />);
  };

  const today = new Date(),
    date =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate();

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const disabledDate = (current) => {
    return current < moment().startOf("day");
  };

  const handleFinish = (values) => {
    const {
      controller,
      createdDate,
      mawb,
      hawb,
      plateNo,
      weight,
      quantity,
      nationalID,
      phone,
    } = values;
    if (mawb.length === 0) {
      notification["error"]({
        description: t("header.MAWB"),
        style: { marginRight: "-50%" },
      });
    } else if (mawb.length < 11) {
      notification["error"]({
        description: t("header.MAWB<11"),
        style: { marginRight: "-50%" },
      });
    } else if (quantity.length === 0) {
      notification["error"]({
        description: t("header.quantity"),
        style: { marginRight: "-50%" },
      });
    } else if (weight.length === 0) {
      notification["error"]({
        description: t("header.weights"),
        style: { marginRight: "-50%" },
      });
    } else if (plateNo.length === 0) {
      notification["error"]({
        description: t("header.plateNo"),
        style: { marginRight: "-50%" },
      });
    } else if (controller.length === 0) {
      notification["error"]({
        description: t("header.controllers"),
        style: { marginRight: "-50%" },
      });
    } else if (nationalID.length === 0) {
      notification["error"]({
        description: t("header.nationalID"),
        style: { marginRight: "-50%" },
      });
    } else if (phone.length === 0) {
      notification["error"]({
        description: t("header.phone"),
        style: { marginRight: "-50%" },
      });
    } else {
      setLoading(true);
      API.createQR(
        plateNo,
        controller,
        phone,
        nationalID,
        mawb,
        hawb,
        quantity,
        weight,
        moment(createdDate).format("LL HH-mm-ss"),
        moment().format("LL HH-mm-ss"),
        flightDate,
        flightNo.slice(0, 2),
        flightNo.slice(2, flightNo.length),
        0, // accp_reference_id
      )
        .then((res) => {
          if (res && res.data !== null) {
            setNumberQR(res.data.AWB_NO);
            createQR(res.data.AWB_NO);
            setValues({
              plateNo,
              controller,
              phone,
              nationalID,
              mawb,
              hawb,
              quantity,
              weight,
              createdDate,
            });
            setQRToPrint(res.data.AWB_NO);
            form.resetFields();
          } else {
            notification["error"]({
              description: "Lỗi ",
              style: { marginRight: "-50%" },
            });
          }
        })
        .catch((error) => console.log(error))
        .finally(setLoading(false));
    }
  };
  useEffect(() => {
    if (flightNo !== "")
      form.setFieldsValue({ flightNo: flightNo, flightDate: flightDate });
  }, [flightNo, flightDate]);

  const limitInput = (e) => {
    switch (e.target.name) {
      case "mawb":
        if (e.target.value.length > e.target.maxLength)
          form.setFieldsValue({
            mawb: e.target.value.slice(0, e.target.maxLength),
          });
        break;
      case "hawb":
        if (!e.target.value.match(/^[A-Za-z0-9-]+$/))
          form.setFieldsValue({
            hawb: e.target.value.slice(0, -1),
          });
        break;
      default:
        break;
    }
  };
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <div className="awb-tab-content" style={{ width: "600px" }}>
        <p className="registry-notice">{t("header.registryInvNotice")}</p>

        <div className="control-wrapper">
          <ModalCutOffTime
            visible={visibleFlightTable}
            setVisible={setVisibleFlightTable}
            setFlightNo={setFlightNo}
            setFlightDate={setFlightDate}
            dateIsString={true}
          />

          <div>
            <Form
              layout="vertical"
              className="form-date"
              form={form}
              initialValues={{
                createdDate: moment(today, dateFormat),
                controller: "",
                mawb: "",
                hawb: "",
                plateNo: "",
                weight: "",
                quantity: "",
                nationalID: "",
                phone: "",
              }}
              onFinish={handleFinish}
              autoComplete="off"
            >
              <Row>
                <Col span={24}>
                  <Form.Item name="createdDate" label={t("header.createDate")}>
                    <DatePicker
                      style={{ width: "100%" }}
                      format={`${dateFormat}`}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[10, 0]}>
                <Col span={24} md={12}>
                  <Form.Item name="mawb" label={t("header.MAWBNo")}>
                    <Input
                      type="number"
                      name="mawb"
                      maxLength={11}
                      onChange={limitInput}
                    />
                  </Form.Item>
                </Col>
                <Col span={24} md={12}>
                  <Form.Item name="hawb" label={t("header.HAWBNo")}>
                    <Input name="hawb" onChange={limitInput} maxLength={16} />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[10, 0]}>
                <Col span={24} md={12}>
                  <Form.Item name="quantity" label={t("header.number")}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={24} md={12}>
                  <Form.Item name="weight" label={t("header.weight")}>
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[10, 0]}>
                <Col span={24} md={12}>
                  <Form.Item name="plateNo" label={t("header.licensePlates")}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={24} md={12}>
                  <Form.Item name="controller" label={t("header.controller")}>
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[10, 0]}>
                <Col span={24} md={7}>
                  <Form.Item name="flightNo" label={t("tabInfo.flightNo")}>
                    <Input style={{ background: "#d6d6d6" }} readOnly />
                  </Form.Item>
                </Col>
                <Col span={24} md={7}>
                  <Form.Item name="flightDate" label={t("tabInfo.flightDate")}>
                    <Input style={{ background: "#d6d6d6" }} readOnly />
                  </Form.Item>
                </Col>
                <Col span={24} md={8}>
                  <Form.Item name="flightBtn" label={" "}>
                    <Button
                      onClick={() => setVisibleFlightTable(true)}
                      style={{ width: "fit-content" }}
                    >
                      Chọn chuyến bay
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[10, 0]}>
                <Col span={24} md={12}>
                  <Form.Item name="nationalID" label={t("header.id")}>
                    <Input maxLength={12} />
                  </Form.Item>
                </Col>
                <Col span={24} md={12}>
                  <Form.Item name="phone" label={t("header.phoneNumber")}>
                    <Input maxLength={10} />
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    loading={loading}
                    style={{ width: "fit-content" }}
                    htmlType="submit"
                  >
                    {t("header.click")}
                  </Button>
                  <Button
                    onClick={handlePrint}
                    style={{ width: "fit-content" }}
                  >
                    {t("header.print")}
                  </Button>
                </div>
              </Form.Item>
            </Form>
          </div>
        </div>
        {numberQR !== undefined && values !== undefined && (
          <Row>
            <Col span={0}>
              <RegistryToPrint
                ref={componentRef}
                {...values}
                QR={QRToPrint}
                numberQR={numberQR}
                flightNo={flightNo}
                flightDate={flightDate}
              />
            </Col>
          </Row>
        )}
        <Row className="result-wrapper">
          <Row className="result-wrapper">
            <Col
              style={{ display: "flex", justifyContent: "center" }}
              span={24}
              className="result"
            >
              <Row className="ticket-wrapper">
                <Row className="control-button-group-wrapper">
                  <Col span={24} className="control qr-input-wrapper">
                    <Col className="qr-title">{t("header.yourRegister")}</Col>
                    <Col className="qr-no">{numberQR}</Col>
                  </Col>
                </Row>
                <Row className="control-button-group-wrapper">
                  <Col span={24} className="control qr-wrapper">
                    {QR}
                  </Col>
                  {QR && (
                    <Row className="awb-tab-title-wrapper">
                      <Col span={24} className="awb-tab-title">
                        <h5>{t("header.showQR")}</h5>
                      </Col>
                      <Col
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                        className="registry-footer"
                        span={12}
                      >
                        {t("tabInfo.flightNo")}: {flightNo}
                      </Col>
                      <Col
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                        className="registry-footer"
                        span={12}
                      >
                        {t("tabInfo.flightDate")}:{" "}
                        {moment(flightDate).format("MM/DD/YYYY")}
                      </Col>
                    </Row>
                  )}
                </Row>
              </Row>
            </Col>
          </Row>
        </Row>
      </div>
    </div>
  );
};

export default TabRegistryInventory;
