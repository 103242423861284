/* eslint-disable */

import React from "react";
import { Row, Col, Input, DatePicker, Form, Typography } from "antd";
import QRCode from "qrcode.react";
import moment from "moment";

export class CarRegistryToPrint extends React.PureComponent {
  constructor(props) {
    super(props);
  }
  render() {
    // const checkLanguage = localStorage.getItem("LANGUAGE");
    const esidShpRegs=this.props.esidShpRegs
    
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div className="awb-tab-content" style={{ width: "600px" }}>
          <div 
            style={{
              display:'flex', 
              width:'100%', 
              justifyContent:'center',
              alignItems:'center', 
              marginTop:'20px', 
              border:'1px solid', 
              padding:"10px"
            }}>
              <Form
                layout="vertical"
                className="form-date"
                initialValues={{
                  datCre: this.props?.datCre ? moment(this.props?.datCre) : null,
                  mawbPrefix: this.props?.mawbPrefix,
                  mawbNum: this.props?.mawbNum,
                  driverIdNum: this.props?.driverIdNum,
                  driverName: this.props?.driverName,
                  driverPhone: this.props?.driverPhone,
                  esidId: this.props?.esidId,
                  tcsCodRefNum: this.props?.tcsCodRefNum,
                  vehicleId: this.props?.vehicleId
                }}
                autoComplete="off"
                style={{
                  width:'100%'
                }}
              >
                <Row>
                  <Col span={24}>
                    <Form.Item label="Ngày Tạo Thông Tin" name="datCre">
                      <DatePicker
                        style={{ width: "100%" }}
                        format="DD-MM-YYYY HH:mm"
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[10, 0]}>
                  <Col span={12}>
                    <div style={{display:'flex',flexDirection:'column'}}>
                      <Typography.Text style={{fontSize:'15px',paddingTop:'2px',paddingBottom:'7px'}}>MAWB</Typography.Text>
                      <Row>
                        {/* <Col span={8}>
                          <Form.Item  name="mawbPrefix">
                            <Input type="text"  />
                          </Form.Item>
                        </Col> */}
                        <Col span={24}>
                          <Form.Item  name="mawbNum">
                            <Input type="text"  />
                          </Form.Item>
                        </Col>
                      </Row>
                    </div>  
                  </Col>
                  <Col span={12}>
                    <Form.Item label="Mã ESID" name="esidId">
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[10, 0]}>
                  <Col span={12}>
                    <Form.Item label="Người Điều Khiển" name="driverName">
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label="Biển Số Xe" name="vehicleId">
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[10, 0]}>
                  <Col span={12}>
                    <Form.Item label="CMND/Hộ Chiếu" name="driverIdNum">
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label="Số Điện Thoại" name="driverPhone">
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>

                <Row style={{display:'flex',justifyContent:'center',alignItems:'center', padding:'10px 0'}}>
                  <Typography.Text>Danh sách hàng hóa</Typography.Text>
                </Row>

                <div style={{width:'100%', border:'1px solid'}}>
                  <Row style={{borderBottom:'1px solid', height:'50px'}}>
                    <Col span={6} style={{display:'flex', justifyContent:'center', alignItems:'center', borderRight:'1px solid'}}>
                      <Typography.Text>AWB/ESID</Typography.Text>
                    </Col>
                    <Col span={6} style={{display:'flex', justifyContent:'center', alignItems:'center', borderRight:'1px solid'}}>
                      <Typography.Text>Số hiệu chuyến bay</Typography.Text>
                    </Col>
                    <Col span={6} style={{display:'flex', justifyContent:'center', alignItems:'center', borderRight:'1px solid'}}>
                      <Typography.Text>Ngày bay</Typography.Text>
                    </Col>
                    <Col span={6} style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                      <Typography.Text>Mã QR</Typography.Text>
                    </Col>
                  </Row>
                  {esidShpRegs?.map((item)=>{
                    return (
                        <Row key={item.id}>
                          <Col span={6} style={{display:'flex', justifyContent:'center', alignItems:'center', borderRight:'1px solid', borderTop: '1px solid'}}>
                            <Typography.Text>{item.codAwbPfx}{item.codAwbNum}</Typography.Text>
                          </Col>
                          <Col span={6} style={{display:'flex', justifyContent:'center', alignItems:'center', borderRight:'1px solid', borderTop: '1px solid'}}>
                            <Typography.Text>{`${item.codFltCar}${item.codFltNum}`}</Typography.Text>
                          </Col>
                          <Col span={6} style={{display:'flex', justifyContent:'center', alignItems:'center', borderRight:'1px solid', borderTop: '1px solid'}}>
                            <Typography.Text>{moment(item.datFltOri).format("DD-MM-YYYY HH:mm")}</Typography.Text>
                          </Col>
                          <Col span={6} style={{display:'flex', justifyContent:'center', alignItems:'center', borderTop: '1px solid', padding: '10px 0'}}>
                              {/* <Typography.Text>{item.esidId}</Typography.Text> */}
                              <QRCode style={{width: '50px', height: '50px'}} value={`${item.codAwbPfx+item.codAwbNum}`} />
                          </Col>
                        </Row>
                    )
                  })}
                        
                </div>
                
              </Form>
          </div>
          {/* <Row className="result-wrapper">
            <Row className="result-wrapper">
              <Col offset={5} span={14} className="result">
                <Row className="ticket-wrapper">
                  <Row className="control-button-group-wrapper">
                    <Col span={24} 
                      style={{
                        backgroundColor: '#e2f2ff',
                        border: '1px solid #aaa',
                        padding: '10px 0',
                        display: 'flex',
                        justifyContent: 'center'
                        }}>
                      <Col className="qr-title">
                        Your Register:
                      </Col>
                      <Col className="qr-no" offset={1}>
                        {this.props?.numberQR}
                      </Col>
                    </Col>
                  </Row>
                  <Row className="control-button-group-wrapper">
                    <Col 
                      span={24} 
                      style={{
                      display:'flex',
                      justifyContent:'center',
                      marginTop: '20px',
                      marginBottom: '10px'
                      }}>
                      <QRCode value={`${this.props?.QR}`} />
                    </Col>

                    <Row className="awb-tab-title-wrapper">
                      <Col span={24} className="awb-tab-title">
                        <h5>
                          {checkLanguage === "vi"
                            ? "QUÝ KHÁCH VUI LÒNG XUẤT TRÌNH MÃ ĐĂNG KÝ/QR TẠI CỔNG AN NINH TCS"
                            : " Please provide the registration code/QR code at the TCS security gate"}
                        </h5>
                      </Col>
                    </Row>
                  </Row>
                </Row>
              </Col>
            </Row>
          </Row> */}
        </div>
      </div>
    );
  }
}
