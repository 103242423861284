import React, { useState } from "react";
import { Row, Col, Input, Form, Space, Button, Typography } from "antd";
import {
    CloseOutlined,
    LoadingOutlined,
    CheckOutlined,
} from '@ant-design/icons'
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { GetEsidExportByAwb, GetEsidExportById } from "../../../../config/nvesidExportApi";
import styles from '../style.module.css'
import { objectToAntFormData } from "../../../../components/tracking/CertLivAnm/TabExpCertLivAnm";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { EsidStatusEnum } from "../../../../constant/enumerations";

const InputGroup = Input.Group;
export const SearchForm = ({ setEsidExport, handleOpenEsidModal, redirectToEsidInfo }) => {
    const [mAwbState, setMAwbState] = useState({loading: false, errorMsg: null, isFound: true});
    const [mEsidState, setMEsidState] = useState({loading: false, errorMsg: null, isFound: true});
    const selectEsidExport = useSelector(state => state.esidExport.esidExpSelected) ;
    const history = useHistory();
    const { t } = useTranslation("common");
    const [searchForm] = Form.useForm();
    const [timer, setTimer] = useState(null);
    const handleNextInput = (evt) => {
        clearTimeout(timer);
        const timeoutId = setTimeout(() => {
          const { maxLength, value, name } = evt.target;
          // Check if they hit the max character length
          if (value.length >= maxLength && name === "awbPfx") {
            // Get the next input field
            // searchForm.resetFields(['awbNum']);
            const nextSibling = document.querySelector(`input[name=awbNum]`);
            // If found, focus the next field
            if (nextSibling !== null) {
                nextSibling.focus();
            }
          }
        }, 300);
        setTimer(timeoutId);
    };
    const handlePasteAwb = (evt) => {
        const regex = new RegExp('[0-9]');
        let pasteData = (evt.clipboardData || window.Clipboard).getData("text");
        if (pasteData.length < 11) {
            return;
        }
        const index = 3; //pasteData.indexOf('-');
        const awbPfx = pasteData.slice(0, index).trim();
        const awbNum = pasteData.slice(index, pasteData.length).trim();
        if (regex.test(awbPfx) && regex.test(awbNum)) {
            searchForm.setFields(objectToAntFormData({
                awbPfx,
                awbNum,
            }));
            setSelectedEsidByAwb(awbPfx, awbNum);
        }
    }

    const scanQrCodeAwb = (evt) => {
        const { value, name } = evt.target;
        if (value.length === 3 && name === "awbPfx" && !isNaN(evt.key)) {
            const nextSibling = document.querySelector(`input[name=awbNum]`);
            if (nextSibling) {
                nextSibling.focus();
            }
        }
    }

    const handleChangeMEsid = async (evt) => {
        clearTimeout(timer);
        const timeoutId = setTimeout(() => {
            setMEsidState({
                errorMsg: null,
                isFound: false,
                loading: true,
            });
            const id = evt.target.value;
            setSelectedEsidById(id);
        }, 700);
        setTimer(timeoutId);
    }
    const handleChangeMAwb = async () => {
        const prevSibling = document.querySelector(`input[name=awbPfx]`);
        if (prevSibling.value.length === 0) {
            if (prevSibling !== null) {
                prevSibling.focus();
                searchForm.resetFields(['awbNum']);
            }
            return;
        }
        clearTimeout(timer);
        const timeoutId = setTimeout(() => {
            setMAwbState({
                errorMsg: null,
                isFound: false,
                loading: true,
            });
            const { awbPfx, awbNum } = searchForm.getFieldsValue();
            if ( awbPfx && awbNum && awbPfx.length >= 3 && awbNum >= 8 ) {
                setSelectedEsidByAwb(awbPfx, awbNum);
            }
            else {
                setMAwbState({
                    ...mAwbState,
                    errorMsg: "Invalid input",
                    loading: false,
                });
            }
        }, 700);
        setTimer(timeoutId);
    }
    
    const setSelectedEsidById = async (id) => {
        if (id && id !== '') {
            try {
                const res  = await GetEsidExportById(id);
                setEsidExport({
                    ...res.data,
                    codSts: res.data.codSts ?? EsidStatusEnum.NY,
                    expCarDim: res.data.expCarDim ? 
                    res.data.expCarDim.map((dim, index) => (
                        {
                            ...dim,
                            key: dim.codSeqNum,
                        }
                        )) : [],
                        expCarWgt: res.data.expCarWgt ?  
                        res.data.expCarWgt.map((wgt, index) => (
                            {
                                ...wgt,
                                key: index,
                            }
                            )) : [],  
                        });
                        setMEsidState({
                    errorMsg: null,
                    loading: false,
                    isFound: true
                });
            } catch (error) {
                setMEsidState({
                    errorMsg: 'notfound',
                    loading: false,
                    isFound: false
                });
                setEsidExport(null)
                // errorHandling(error, history, location.pathname);
            }
            redirectToEsidInfo();
        }
        else {
            setMEsidState({
                errorMsg: null,
                loading: false,
                isFound: false
            });
            
        }
    }
    const setSelectedEsidByAwb = async (awbPfx, awbNum) => {
        try {
            const res  = await GetEsidExportByAwb(awbPfx, awbNum);
            setEsidExport({
                ...res.data,
                codSts: res.data.codSts ?? EsidStatusEnum.NY,
                expCarDim: res.data.expCarDim ? 
                    res.data.expCarDim.map((dim, index) => (
                    {
                        ...dim,
                        key: dim.codSeqNum,
                    })) : [],
                expCarWgt: res.data.expCarWgt ?  
                    res.data.expCarWgt.map((wgt, index) => (
                    {
                        ...wgt,
                        key: index,
                    }
                    )) : [],  
            });
            setMAwbState({
                errorMsg: null,
                loading: false,
                isFound: true
            });
        } catch (error) {
            setMAwbState({
                errorMsg: "notfound",
                loading: false,
                isFound: false
            });
            setEsidExport(null)
            // errorHandling(error, history, location.pathname);
        }
        redirectToEsidInfo();
    }
    
    useEffect(() => {
        if (selectEsidExport) {
            searchForm.setFields(objectToAntFormData({
                awbPfx: selectEsidExport.codAwbPfx,
                awbNum: selectEsidExport.codAwbNum,
                esidId: selectEsidExport.id
            }));
            setMAwbState({
                errorMsg: null,
                loading: false,
                isFound: true
            });
            setMEsidState({
                errorMsg: null,
                loading: false,
                isFound: true
            });
        }
        // eslint-disable-next-line 
    },[selectEsidExport])
    const redirectQRReader = () => {
        history.push('/nvesid/QRReader?type=ESID')
    }

    const handleRefresh = async () => {
        const values = await searchForm.validateFields();
        setSelectedEsidById(values?.esidId || '000000');
    }

    const handleClearAll = () => {
        setEsidExport(null);
        searchForm.resetFields();
    }
    
    return (
        <Form labelCol={{span: 24}} className={`${styles.SearchForm} searchForm`} form={searchForm}>
            <Row gutter={10} style={{maxWidth: '1000px', width: '100%', marginInline: 0}}>
                <Col span={24} lg={9} md={6}>
                    <Form.Item 
                        style={{ marginBottom: 0}} 
                        label={
                            <>
                                <label>{t("registerCar.MAWBNo")}</label>
                                {/* <Tooltip title={'Paste follow format 000-111111 to AWB'}>
                                    <InfoCircleOutlined style={{ marginLeft: 5, cursor: 'pointer'}}/>
                                </Tooltip> */}
                            </>
                        }
                    >
                        <InputGroup compact>
                            <Form.Item style={{ width: '33%' }} name={'awbPfx'}>
                                <Input
                                    autoFocus
                                    maxLength={3}
                                    name={'awbPfx'}
                                    onKeyUp={handleNextInput}
                                    placeholder={t("Prefix")}
                                    onPaste={(e) => handlePasteAwb(e)}
                                    onKeyDown={scanQrCodeAwb}
                                />
                            </Form.Item>
                            <Form.Item style={{ width: '66%' }} name={'awbNum'}>
                                <Input
                                    onPaste={(e) => handlePasteAwb(e)}
                                    onChange={handleChangeMAwb}
                                    maxLength={8}
                                    placeholder={t("AWB#")}
                                    name={'awbNum'}
                                    suffix={
                                        mAwbState.loading ?
                                        <LoadingOutlined /> :
                                        mAwbState.errorMsg ?
                                        <CloseOutlined style={{color: "red"}} /> :
                                        <CheckOutlined style={{color: "green"}} />
                                    }
                                />
                            </Form.Item>
                            {
                                mAwbState.errorMsg && mAwbState.errorMsg !== '' && (
                                    <Typography.Text type='danger'>{mAwbState.errorMsg === 'notfound' && t("admin.notification.awbNotFound")}</Typography.Text>
                                )
                            }
                        </InputGroup>
                    </Form.Item>
                </Col>
                <Col span={11} lg={5} md={4} sm={12} xs={10} className={'esidInput'}>
                    <Form.Item
                        name="esidId"
                        label={t("registerCar.MEsidNo")}
                        style={{ marginBottom: 0}}
                    >
                        <Input
                            name="esidId"
                            onChange={handleChangeMEsid}
                            maxLength={10}
                            suffix={
                                mEsidState.loading ?
                                <LoadingOutlined /> :
                                mEsidState.errorMsg ?
                                <CloseOutlined style={{color: "red"}} /> :
                                <CheckOutlined style={{color: "green"}} />
                            }
                        />
                    </Form.Item>
                    {
                        mEsidState.errorMsg && mEsidState.errorMsg !== '' && (
                            <Typography.Text type='danger'>{mEsidState.errorMsg === 'notfound' && t("admin.notification.esidNotFound")}</Typography.Text>
                        )
                    }
                </Col>
                <Col span={12} lg={10} md={14} sm={{span: 12}} xs={14} className={'btnGroup'}>
                    <Space style={{display: 'flex', justifyContent: 'center'}}>
                        <Form.Item label={" "}>
                            <Space style={{marginTop: -3}}>
                                <Button onClick={() => handleOpenEsidModal(true)} className={styles.submitBtn}>{t("registerCar.chooseEsid")}</Button>
                            </Space>
                        </Form.Item>
                        <Form.Item label={" "}>
                            <Space style={{ marginTop: -3}}>
                                <Button onClick={redirectQRReader} className={styles.submitBtn}>QR Code</Button>
                            </Space>
                        </Form.Item>
                        <Form.Item label={" "}>
                            <Space style={{ marginTop: -3}}>
                                <Button onClick={handleRefresh} className={styles.submitBtn}>Refresh</Button>
                            </Space>
                        </Form.Item>
                        <Form.Item label={" "}>
                            <Space style={{ marginTop: -3}}>
                                <Button onClick={handleClearAll} className={styles.submitBtn}>Clear all</Button>
                            </Space>
                        </Form.Item>
                    </Space>
                </Col>
            </Row>
        </Form>
    )
}
