/* eslint-disable */
import React from 'react';
import { Breadcrumb, Col, Row } from 'antd';
import { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import Banner from '../components/Banner';
import './page-css/PostDetail.css';
import * as API from '../config/apiConfig';
import Parser from 'html-react-parser';
import { useDispatch, useSelector } from 'react-redux';
import * as Constant from '../config/Constant';
import { showLangSwitch } from '../actions/menu';
import { HomeOutlined } from '@ant-design/icons';

const PostDetail = () => {
  const { id } = useParams();
  const [post, setPost] = useState(null);
  const { menuItem, langId } = useSelector((state) => state.menu);
  const [menu, setMenu] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    API.getPost(id)
      .then((res) => {
        setPost(res.data.data);
        API.getMenuById(res.data.data.menuId)
          .then((res) => setMenu(res.data))
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
    dispatch(showLangSwitch(false));
  }, [id]);
  return (
    <Row className="post-detail-wrapper">
      {['1000000292', '1000000293'].includes(id) ? (
        <Banner
          title={menuItem.menuParentTitle}
          className="banner-wrapper"
          cover={`https://www.tcs.com.vn/api/images/BannerTurkmenistan.jpg`}
        />
      ) : (
        <Banner
          title={menuItem.menuParentTitle}
          className="banner-wrapper"
          cover={`${Constant.IMAGES_URL}${menuItem.banner}`}
        />
      )}
      <Col style={{ marginTop: '10px' }} offset={3} span={21}>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/home">
              <HomeOutlined />
            </Link>
          </Breadcrumb.Item>
          {menu && (
            <Breadcrumb.Item>
              {langId === '2' ? (
                <Link to={`/kenh-thong-tin/${menu.alias}`}>Kênh thông tin</Link>
              ) : (
                <Link to={`/news/${menu.alias}`}>News</Link>
              )}
            </Breadcrumb.Item>
          )}
          {menu && (
            <Breadcrumb.Item>
              {langId === '2' ? (
                <Link to={`/kenh-thong-tin/${menu.alias}`}>{menu.name}</Link>
              ) : (
                <Link to={`/news/${menu.alias}`}>{menu.name}</Link>
              )}
            </Breadcrumb.Item>
          )}
        </Breadcrumb>
      </Col>

      {post && (
        <>
          <Row className="post-detail-title-wrapper">
            <Col span={18} offset={3} className="post-detail-title">
              <h2>{post.title}</h2>
            </Col>
          </Row>
          <Row className="post-detail-content-wrapper">
            <Col span={18} offset={3} className="post-detail-content">
              {post.content_standard ? (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <div style={{ maxWidth: '800px' }}>
                    <div className="ck-content">
                      {post.fulltext && Parser(post.fulltext ? post.fulltext : '')}
                    </div>
                  </div>
                </div>
              ) : (
                <div className="ck-content">
                  {post.fulltext && Parser(post.fulltext ? post.fulltext : '')}
                </div>
              )}
            </Col>
          </Row>
        </>
      )}
    </Row>
  );
};

export default PostDetail;
