import React, { useState } from 'react'
import { Typography, Button, Upload, notification } from 'antd'
import { PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { useTranslation } from "react-i18next";
import * as XLSX from "xlsx";
import { useDispatch, useSelector } from 'react-redux';
import { CaclDimVolWgt, createUUID } from '../../../../../../utils/utils';
import { setExpCarDimToEdit, setExpCarWgtToEdit } from '../../../../../../actions/esidExportAction';
import RoleClaim from '../../../../../../constant/roleClaim';
import useHasRoles from '../../../../../../hooks/useHasRoles';

export const DimensionsTableHead = ({ openDmsPrint, disabled, setSizeEditingKey, setSizeModalVisible, addSizeForm}) => {
    const { t } = useTranslation("common");
    const dispatch = useDispatch();
    const [importing, setImporting] = useState(false);
    const expCarDimToEdit = useSelector((state) => state.esidExport.expCarDimToEdit);
    const selectEsidExport = useSelector((state) => state.esidExport.esidExpSelected);
    const expCarWgtToEdit = useSelector((state) => state.esidExport.expCarWgtToEdit);
    const hasAirlines = useHasRoles([RoleClaim.Airlines]);

    const handleUploadProps = {
        beforeUpload: (file) => {
            setImporting(true);
            const promise = new Promise((resolve, reject) => {
                const fileReader = new FileReader();
                fileReader.readAsArrayBuffer(file);
                fileReader.onload = (e) => {
                    const bufferArray = e.target.result;
                    const wb = XLSX.read(bufferArray, { type: "buffer" });
                    const wsname = wb.SheetNames[0];
                    const ws = wb.Sheets[wsname];
                    const data = XLSX.utils.sheet_to_json(ws);
                    resolve(data);
                };
        
                fileReader.onerror = (error) => {
                    notification.error({
                        message: "Thông báo",
                        description: "Có lỗi xảy ra. Vui lòng thử lại sau."
                    });
                    reject(error);
                };
            });
            promise.then((data) => {
                // kiểm tra dữ liệu
                if (data?.length > 0) {
                    const firstRow = JSON.parse(JSON.stringify(data[0]));
                    const colKeys = Object.keys(firstRow).map(k => k.toLocaleLowerCase().trim());
                    if (!colKeys.includes('chiều dài') || !colKeys.includes('chiều rộng') ||
                    !colKeys.includes('chiều cao') || !colKeys.includes('số kiện')) {
                        setImporting(false);
                        notification.error({
                            message: 'Thông báo',
                            description: 'Định dạng tệp không đúng. Vui lòng thử lại.'
                        });
                        return;
                    }
                    const listDims = expCarDimToEdit ? JSON.parse(JSON.stringify(expCarDimToEdit)) : [];
                    let codSeqNum = listDims.length + 1;
                    const dimMode = localStorage.getItem('calcDimMode') || selectEsidExport.shcCod001;
                    data.forEach(row => {
                        const dim = {};
                        Object.entries(row).forEach(([key, value]) => {
                            if (key.toLocaleLowerCase().includes('chiều dài')) {
                                dim['qtyDimLen'] = isNaN(value) ? 0 : Number(value);
                            }
                            if (key.toLocaleLowerCase().includes('chiều rộng')) {
                                dim['qtyDimWid'] = isNaN(value) ? 0 : Number(value);
                            }
                            if (key.toLocaleLowerCase().includes('chiều cao')) {
                                dim['qtyDimHgt'] = isNaN(value) ? 0 : Number(value);
                            }
                            if (key.toLocaleLowerCase().includes('số kiện')) {
                                dim['qtyDimPcs'] = isNaN(value) ? 0 : Number(value);
                            }
                        });
                        if (dim['qtyDimLen'] + dim['qtyDimWid'] + dim['qtyDimHgt'] + dim['qtyDimPcs']> 0) {
                            const newItem = {
                                uuid: createUUID(),
                                codSeqNum: codSeqNum++,
                                esidId: selectEsidExport.id,
                                codAwbNum: selectEsidExport.codAwbNum,
                                codAwbPfx: selectEsidExport.codAwbPfx,
                                codAwbSfx: selectEsidExport.codAwbSfx,
                                codDimUnt: '0',
                                qtyDimHgt: dim['qtyDimHgt'],
                                qtyDimLen: dim['qtyDimLen'] ,
                                qtyDimWid: dim['qtyDimWid'],
                                qtyDimPcs: dim['qtyDimPcs'],
                                qtyDimWgt: CaclDimVolWgt(dim['qtyDimPcs'], dim['qtyDimLen'], dim['qtyDimWid'], dim['qtyDimHgt'], dimMode === 'VKD' ? 1 : 0),
                                usrCre: selectEsidExport.usrCre,
                                codSts: 'NY'
                            }
                            listDims.push(newItem);
                        }
                    });
                    dispatch(setExpCarDimToEdit(listDims));
                    // tinh tổng trọng lượng theo thể tích
                    const volWgtTotal = listDims?.filter(x => x.codSts !== 'XX')?.length > 0 ?
                    listDims?.filter(x => x.codSts !== 'XX')?.map(x => x.qtyDimWgt)?.reduce((a, b) => a + b) : 0;
                    dispatch(setExpCarWgtToEdit({
                        ...expCarWgtToEdit, 
                        volWgt: volWgtTotal,
                        wgtNet: volWgtTotal > expCarWgtToEdit?.wgtNet ? volWgtTotal : expCarWgtToEdit?.wgtNet
                    }));
                }
                //
                setImporting(false);
                notification.success({
                    message: "Thông báo",
                    description: "Nhập khẩu dữ liệu thành công.",
                })
            });
            return false;
        },
    };

    return (
        <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', flexDirection: 'row'}}>
            <Typography.Text style={{ display: 'flex', alignItems: 'center'}} strong>{t("admin.acceptance.shipmentSizeList")}</Typography.Text>
            {!hasAirlines && (
                <div style={{ display: 'flex', justifyContent:'flex-end', paddingInline: 5, marginBlock: 'auto', gap: 10  }}>
                    <Button href='https://www.tcs.com.vn/api/downloadFileUpload/ATTACHED_LIST_DIMS.xlsx'>{t('downloadTemplateFile')}</Button>
                    <Upload {...handleUploadProps} showUploadList={false} accept='.xls, .xlsx'>
                        <Button loading={importing} icon={<UploadOutlined />} disabled={disabled}>{t('importCsv')}</Button>
                    </Upload>
                    <Button 
                        type='primary'
                        // disabled={disabled}
                        onClick={openDmsPrint}
                    >
                        {`${t("admin.esid.printBtn")}`}
                    </Button>
                    <Button 
                        type='primary'
                        onClick={() =>{
                            setSizeEditingKey('')
                            setSizeModalVisible(true);
                            addSizeForm.resetFields();
                        }} 
                        icon={<PlusOutlined/>}
                        disabled={disabled}
                    >
                        {`${t("addBtn")}`}
                    </Button>
                    
                </div>
            )}
        </div>
  )
}
