import React from "react";
import { Col, Descriptions, Row, Skeleton } from "antd";

const CustomsResult = (props) => {
  return (
    <>
      <Row className="result-title-wrapper">
        <Col span={24} className="awb-result-title">
          <h5>{props.titleCustoms}</h5>
        </Col>
      </Row>

      <Row className="result-content-wrapper">
        <Col className="awb-result-content" span={24}>
          <Skeleton loading={props.loading}>
            <Descriptions bordered size={"small"}>
              <Descriptions.Item style={{ textAlign: "center" }}>
                {props.customsInfo || ""}
              </Descriptions.Item>
            </Descriptions>
          </Skeleton>
        </Col>
      </Row>
    </>
  );
};
export default CustomsResult;
