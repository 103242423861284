import React from 'react'
import { Form, Col, Row, Button, Modal, InputNumber } from 'antd';
import { useTranslation } from "react-i18next";
import styles from '../../../style.module.css'

export const AddDmsSizeModal = ({ editingKey, modalVisible, handleAddOrSave, setModalVisible, form, handleClearEdit }) => {
    const { t } = useTranslation("common");
    const hideModal = () => {
        setModalVisible(false);
    }
    const handleSubmit = (formValue) => {
        editingKey !== '' ? handleAddOrSave(formValue, editingKey) : handleAddOrSave(formValue);
        setModalVisible(false);
        form.resetFields();
    }
    const handleAddAndCreateAnother = async () => {
        const formValue = await form.validateFields();
        editingKey !== '' ? handleAddOrSave(formValue, editingKey) : handleAddOrSave(formValue);
        form.resetFields();
        const firstField = document.querySelector('input[name=qtyDimLen]');
        firstField && firstField.focus();
    }
    return (
        <Modal
            visible={modalVisible}
            onCancel={hideModal}
            footer={null}
            centered
            className={styles.AddSizeModal}
            title={t("admin.acceptance.shipmentSize")}
        >
            <Form
                name={'addSizeForm'}
                labelCol={{ span: 24, offset: 0 }}
                labelAlign={'left'}
                wrapperCol={{ span: 24 }}
                autoComplete="off"
                form={form}
                className={`${styles.personalForm}`}
                onFinish={handleSubmit}
                layout='vertical'
            >
                <Form.Item required noStyle>
                    <Row gutter={[10,10]}>
                        <Col lg={24} sm={24} xs={24}>
                            <Form.Item
                                name="qtyDimLen"
                                label={t("admin.acceptance.lengthHolder")}
                                style={{marginBottom: '0 !important'}}
                                rules={[
                                    {
                                        required: true,
                                        message: t("admin.validation.required"),
                                    },
                                ]}
                            >
                                <InputNumber style={{width: '100%', height: 32}} min={0} placeholder={t("admin.acceptance.lengthHolder")} name="qtyDimLen" />
                            </Form.Item>
                        </Col>
                        <Col lg={24} sm={24} xs={24}>
                            <Form.Item
                                name="qtyDimWid"
                                label={t("admin.acceptance.wideHolder")}
                                style={{marginBottom: '0 !important'}}
                                rules={[
                                    {
                                        required: true,
                                        message: t("admin.validation.required"),
                                    },
                                ]}
                            >
                                <InputNumber style={{width: '100%', height: 32}} min={0} placeholder={t("admin.acceptance.wideHolder")} />
                            </Form.Item>
                        </Col>
                        <Col lg={24} sm={24} xs={24}>
                            <Form.Item
                                name="qtyDimHgt"
                                label={t("admin.acceptance.heightHolder")}
                                style={{marginBottom: '0 !important'}}
                                rules={[
                                    {
                                        required: true,
                                        message: t("admin.validation.required"),
                                    },
                                ]}
                            >
                                <InputNumber style={{width: '100%', height: 32}} min={0} placeholder={t("admin.acceptance.heightHolder")}/>
                            </Form.Item>
                        </Col>
                        <Col lg={24} sm={24} xs={24}>
                            <Form.Item
                                name="qtyDimPcs"
                                label={t("admin.acceptance.qtyHolder")}
                                style={{marginBottom: '0 !important'}}
                                rules={[
                                    {
                                        required: true,
                                        message: t("admin.validation.required"),
                                    },
                                ]}
                            >
                                <InputNumber style={{width: '100%', height: 32}} min={0} placeholder={t("admin.acceptance.qtyHolder")}/>
                            </Form.Item>
                        </Col>
                        {/* <Col lg={24} sm={24} xs={24}>
                            <Form.Item
                                name="qtyDimWgt"
                                label={t("admin.acceptance.volumeHolder")}
                                style={{marginBottom: '0 !important'}}
                                rules={[
                                    {
                                        required: true,
                                        message: t("admin.validation.required"),
                                    },
                                ]}
                            >
                                <InputNumber style={{width: '100%', height: 32}} min={0} placeholder={t("admin.acceptance.volumeHolder")}/>
                            </Form.Item>
                        </Col> */}
                    </Row>
                </Form.Item>
                <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 5}}>
                    {editingKey !== '' ?
                        <Button 
                            htmlType='submit' 
                            className={styles.submitBtn}
                            style={{ margin: 'unset'}}
                            >
                            {t('admin.acceptance.saveSizeBtn')}
                        </Button> :
                        <Button 
                            htmlType='submit' 
                            className={styles.submitBtn}
                            style={{ margin: 'unset'}}
                        >
                            {t('admin.acceptance.addSizeBtn')}
                        </Button>
                    }
                    <Button style={{ margin: 'unset'}} className={styles.submitBtn} onClick={handleAddAndCreateAnother}>
                        {t('admin.esid.saveAndCreateBtn')}
                    </Button>
                </div>
            </Form>
        </Modal>
  )
}
