import { Space, Typography, Switch, Popover, Button, notification } from 'antd'
import { Header } from 'antd/es/layout/layout'
import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import UserAvatar from '../components/UserAvatar'
import { useTranslation } from "react-i18next";
import { setLanguage } from '../../../../helpers/language-helper'
import { changeLang } from '../../../../actions/menu'
import styles from '../dashboard.module.css'
import { logout } from '../../../../config/apiConfig'
import { userAwbLogout } from '../../../../actions/userAWB'
import { setMAWB } from '../../../../actions/MAWB'
import { useHistory } from 'react-router-dom'
import { errorHandling } from '../../../../config/AuthIntercept'

export const DashboardHead = () => {
  const { currentUser } = useSelector((state) => state.userAwb);
  const { t } = useTranslation("common");
  const [check, setCheck] = useState(localStorage.getItem("langId") === "2" ? true : false);
  const dispatch = useDispatch();
  const history = useHistory();

  const switchLang = () => {
    if (localStorage.getItem("langId") === "2") {
      setCheck(false);
      dispatch(changeLang("1"));
      localStorage.setItem("langId", "1");
      localStorage.setItem("LANGUAGE", "en");
      setLanguage("en");
    } else {
      setCheck(true);
      dispatch(changeLang("2"));
      localStorage.setItem("langId", "2");
      localStorage.setItem("LANGUAGE", "vi");
      setLanguage("vi");
    }
  };
  const logOut = async () => {
    try {
      const token = JSON.parse(localStorage.getItem("userAWB"));
      if (token) {
        const res = await logout(token);
        dispatch(userAwbLogout());
        dispatch(setMAWB({}));
        localStorage.removeItem("userAWB");
        localStorage.removeItem("MAWB");
        notification.success({
          message: t("notification"),
          description: t(res.data.message),
        });
      }
      history.push("/");
    } catch (error) {
      errorHandling(error, history, "/");
    }
  };


  const content = (
    <div style={{ position: 'sticky', display: 'flex', flexDirection: 'column', alignItems: 'center', minWidth: '100px', top: 60 }}>
      <Typography.Text style={{ width: '100%', paddingBottom: '6px', borderBottom: '1px solid', borderColor: '#94AF9F', fontWeight: 'bold', textAlign: 'center' }}>
        {currentUser?.fullName || currentUser?.user_name || 'Ben'}
      </Typography.Text>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '10px 0', gap: '10px' }}>
        <Typography.Text>{t("Language")}</Typography.Text>
        <Switch
          checked={check}
          onChange={switchLang}
          checkedChildren={t("header.language")}
          unCheckedChildren={t("header.language")}
          className={styles.changeLang}
        />
      </div>
      <div style={{ width: '100%', paddingTop: '10px', borderTop: '1px solid', borderColor: '#94AF9F', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Button className={styles.submitBtn} onClick={logOut}>{t("Logout")}</Button>
      </div>
    </div>
  );
  return (
    <Header className={`${styles.Header}`}>
      <div className='logo' style={{ height: "100%", position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%,-50%)', display: 'none', justifyContent: 'center' }}>
        <img
          style={{ objectFit: "contain", width: "80%", height: "80%", margin: 'auto' }}
          alt="logo"
          src="../../img/logotcs_new_2015.jpg"
        />
      </div>
      <Popover getPopupContainer={trigger => trigger.parentElement} placement="bottomRight" content={content} trigger="click" arrowContent={false}>
        <Space style={{ paddingInline: 10, cursor: 'pointer'}}>
          <Typography style={{ fontSize: '16px', fontWeight: '700' }} >{currentUser?.user_name || 'Ben'}</Typography>
          <UserAvatar width='24px' userName={currentUser?.user_name || 'Ben'} />
        </Space>
      </Popover>
    </Header>
  )
}
