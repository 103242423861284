import { Col, DatePicker, Form, Row, Input, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { CalendarOutlined  } from '@ant-design/icons';
import { useTranslation } from "react-i18next";
import useWindowSize from '../../../../helpers/useWindowSize';
import { setExpConsolEdit } from '../../../../actions/esidExportAction';
import { useDispatch, useSelector } from 'react-redux';

export const ConsolDeclarant = ({ form, readOnly }) => {
    const { t } = useTranslation("common");
    const dispatch = useDispatch();
    const expConsolEdit = useSelector(state => state.esidExport.expConsolEdit);
    const disabledDate = (current) => {
        return current && current > moment().endOf('day');
    }
    const size = useWindowSize();
    
    const[timer, setTimer] = useState();
    const handleFieldsChange = (_, values) => {
      clearTimeout(timer);
      const timerId = setTimeout(() => {
        dispatch(setExpConsolEdit({...expConsolEdit, ...values, datDecl: new Date(values.datDecl).toISOString()}));
      }, 500);
      setTimer(timerId);
    }

    useEffect(() => {
        form.setFieldsValue({
            declNam: expConsolEdit?.declNam,
            datDecl: moment(expConsolEdit?.datDecl),
            acpStfNam: expConsolEdit?.acpStfNam,
            acpDat: expConsolEdit?.acpDat ? moment(expConsolEdit?.acpDat) : null
        });
        // eslint-disable-next-line
    },[expConsolEdit]);

    return (
        <Form 
            labelAlign='left'
            style={{ padding: '2px 5px', marginTop: 10}}
            labelCol={{ span: 24 }}
            form={form}
            initialValues={{ datDecl: moment()}}
            requiredMark
            onValuesChange={handleFieldsChange}
            autoComplete="off"
        >
            <Row gutter={[5,5]}>
                <Col xs={24} sm={24} xl={16}>
                    <Form.Item
                        label={<Typography.Text style={{ fontWeight: 'bold', color: 'red' }}>{t('consolLabels.declarant')}</Typography.Text>}
                        name='declNam'
                        required
                        rules={[{
                            required: true,
                            message: t('dangerousGood.errorInfo.required')
                        }]}
                    >
                        <Input readOnly={readOnly}/>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} xl={8}>
                    <Form.Item 
                        label={<Typography.Text strong>{t('consolLabels.datDecl')}</Typography.Text>}
                        name='datDecl'
                    >
                        <DatePicker disabled={readOnly} showTime 
                            format={ size === 'xs' ? 'DD-MM-YYYY' : 'DD-MM-YYYY HH:mm'} 
                            style={{ width: '100%'}} disabledDate={disabledDate} 
                            suffixIcon={readOnly ? null : <CalendarOutlined />}/>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
  )
}
