import React from "react";
import moment from "moment";
import {
  GET_AIRLINE_FLIGHT_REPORT_PART_A,
  GET_AIRLINE_FLIGHT_REPORT_PART_B,
  GET_AIRLINE_FLIGHT_REPORT_PART_C,
  GET_AIRLINE_FLIGHT_REPORT_PART_D,
} from "../../../config/webTrackApi";
import { Row, Col, Form, Button, Input, DatePicker, notification } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import AirlinesReportResult from "./AirlinesReportResult";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { checkinUser, userAwbLogout } from "../../../actions/userAWB";
import { setPaymentInfo } from "../../../actions/payment";
const TabAirlinesReport = (props) => {
  const [dataPartA, setDataPartA] = useState([]);
  const [dataPartB, setDataPartB] = useState([]);
  const [dataPartC, setDataPartC] = useState([]);
  const [dataPartD, setDataPartD] = useState([]);
  const [hasData, setHasData] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [flightNo, setFlightNo] = useState("");
  const [flightDate, setFlightDate] = useState("");
  const { dataLogin } = useSelector((state) => state.userAwb);
  const history = useHistory();
  const dispatch = useDispatch();
  const [form] = useForm();
  const { t } = useTranslation("common");
  const [loading, setLoading] = useState(false);

  const submitReport = async (values) => {
    try {
      setLoading(true);
      setIsSubmitted(true);
      setFlightNo(values.flightNumber);
      setFlightDate(moment(values.flightDate).format("LL"));
      let _hasData  = 0;
      await GET_AIRLINE_FLIGHT_REPORT_PART_A(
        values.flightNumber,
        moment(values.flightDate).format("DD/MM/YYYY"),
        dataLogin.token
      ).then((res) => {
        if (res.status === 200 && res.data.mCol.length > 0) {
          setDataPartA(res.data.mCol);
          setHasData(true);
        }
        _hasData += res.data.mCol.length;
        if (res.data.mCol.length === 0) setHasData(false);
      });
      await GET_AIRLINE_FLIGHT_REPORT_PART_B(
        values.flightNumber,
        moment(values.flightDate).format("DD/MM/YYYY"),
        dataLogin.token
      ).then((res) => {
        if (res.status === 200 && res.data.mCol.length > 0) {
          setDataPartB(res.data.mCol);
          setHasData(true);
        }
        _hasData += res.data.mCol.length;
        if (res.data.mCol.length === 0) setHasData(false);
      });
      await GET_AIRLINE_FLIGHT_REPORT_PART_C(
        values.flightNumber,
        moment(values.flightDate).format("DD/MM/YYYY"),
        dataLogin.token
      ).then((res) => {
        if (res.status === 200 && res.data.mCol.length > 0) {
          setDataPartC(res.data.mCol);
          setHasData(true);
        }
        _hasData += res.data.mCol.length;
        if (res.data.mCol.length === 0) setHasData(false);
      });
      await GET_AIRLINE_FLIGHT_REPORT_PART_D(
        values.flightNumber,
        moment(values.flightDate).format("DD/MM/YYYY"),
        dataLogin.token
      ).then((res) => {
        if (res.status === 200 && res.data.mCol.length > 0) {
          setDataPartD(res.data.mCol);
          setHasData(true);
        }
        _hasData += res.data.mCol.length;
        if (res.data.mCol.length === 0) setHasData(false);
      });
      setHasData(_hasData > 0 ? true : false);
      setLoading(false);
    } catch (error) {
      if (error.response.status === 401) {
        notification.error({
          style: { marginRight: "-50%" },
          message: t("home.sessionNoti"),
        });
        dispatch(checkinUser({}));
        dispatch(setPaymentInfo(undefined));
        dispatch(userAwbLogout());
        localStorage.removeItem("userAWB");
        localStorage.removeItem("checkin");
        localStorage.removeItem("pInfo");
        setLoading(false);
        history.push("/");
      }
    }
  };

  const tailLayout = {
    wrapperCol: { offset: 1, span: 16 },
  };
  const dateFormat = "DD-MM-YYYY";
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  function disabledDate(current) {
    return current > moment().endOf("day");
  }
  let airlineCode = "";
  if (dataLogin.data.user_code !== "admi")
    airlineCode = dataLogin.data.user_code.slice(2, 4);
  return (
    <div>
      <Row className="awb-tab-title-wrapper">
        <Col span={24} className="awb-tab-title">
          <h5>{t("airlines.flightReport")}</h5>
        </Col>
      </Row>
      <Row className="awb-tab-content">
        <Row className="control-wrapper">
          <Col span={24} className="control">
            <Form
              className="form-report"
              onFinish={submitReport}
              layout={"inline"}
              initialValues={{
                flightDate: moment(props.today, dateFormat),
                flightNumber: airlineCode,
              }}
              form={form}
              autoComplete="off"
            >
              <Form.Item
                {...tailLayout}
                name="flightNumber"
                label={t("CutOffTime.fightNum")}
              >
                <Input className="flight-number" />
              </Form.Item>
              <Form.Item
                {...tailLayout}
                label={t("agentReport.Date")}
                name="flightDate"
              >
                <DatePicker
                  format={dateFormat}
                  disabledDate={disabledDate}
                  className="flight-date"
                />
              </Form.Item>
              <Form.Item wrapperCol={{ offset: loading ? 15 : 21, span: 4 }}>
                <Button loading={loading} style={{ width: "fit-content" }} htmlType={"submit"}>
                  {t("header.click")}
                </Button>
              </Form.Item>
              <Form.Item wrapperCol={{ offset: 16, span: 4 }}>
                <Button onClick={handlePrint} style={{ width: "fit-content" }}>
                  {t("header.print")}
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
        <Row ref={componentRef} className="result-wrapper">
          <Row className="result-title-wrapper">
            <Col span={24} className="result-title">
              <h5>{t("airlines.flightReport")}</h5>
            </Col>
          </Row>
          {loading ? 
            (<Row className="result-content-wrapper">
              <Col span={24} className="result-content">
                {t("airlines.loading")}
              </Col>
            </Row>)
            :
            (!isSubmitted ? (
              <Row className="result-content-wrapper">
                <Col span={24} className="result-content">
                  {t("airlines.inputDate")}
                </Col>
              </Row>
            ) : !hasData ? (
              <Row className="result-content-wrapper">
                <Col span={24} className="result-content">
                  {t("airlines.noflight")}
                </Col>
              </Row>
            ) : (
              <AirlinesReportResult
                flightNo={flightNo}
                flightDate={flightDate}
                dataPartA={dataPartA}
                dataPartB={dataPartB}
                dataPartC={dataPartC}
                dataPartD={dataPartD}
              />
            ))
          }
        </Row>
      </Row>
    </div>
  );
};
export default TabAirlinesReport;
