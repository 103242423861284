import React, { useEffect } from 'react';
import './App.css';
import { Layout, Row, Col, notification } from 'antd';
import { Route, Switch, useLocation } from 'react-router-dom';
import { PrivateRoute } from './PrivateRoute';
import CustomHeader from './components/CustomHeader';
import FooterContent from './components/FooterContent';
import Home from './pages/Home';
import Introduction from './pages/Introduction';
import Links from './pages/Links';
import Services from './pages/Services';
import Feedback from './pages/Feedback';
import Guides from './pages/Guides';
import News from './pages/News';
import Contacts from './pages/Contacts';
import AwbLogin from './pages/AwbLogin';
import Airlines from './pages/Airlines';
import NotFound from './pages/NotFound';
import PostDetail from './pages/PostDetail';
import Agent from './pages/Agent';
import { checkinUser, userAwbLoggin, userGuest } from './actions/userAWB';
import { useDispatch } from 'react-redux';
import { changeLang, menuItemSelected } from './actions/menu';
import FooterSlides from './components/FooterSlides';
import MAWBInfo from './pages/MAWBInfo';
import ChangePasswordTab from './pages/ChangePassword';
import Statistic from './pages/Statistic';
import Checkout from './pages/Checkout';
import { setHAWB, setMAWB } from './actions/MAWB';
import SignIn from './components/checkout/SignIn/SignIn';
import VIP from './pages/VIP';
import CheckoutHAWB from './pages/CheckoutHAWB';
import ReceiveImport from './pages/ReceiveImport';
import NapasPaymentPage from './components/checkout/TabInvoiceInfo/NapasPaymentPage';
import { setPaymentInfo } from './actions/payment';
import WebFont from 'webfontloader';
import packageJson from '../package.json';
import { getBuildDate } from './utils/utils';
import withClearCache from './ClearCache';
import RoleClaim from './constant/roleClaim';
import EsidExport from './pages/EsidExport';
import { AdminLogin } from './pages/admin/Login';
import { EsidCheckout } from './pages/EsidCheckout';
import { setEsidExpSelected } from './actions/esidExportAction';

const { Header, Footer, Content } = Layout;

const ClearCacheComponent = withClearCache(MainApp);

function App() {
  return <ClearCacheComponent />;
}

function MainApp(props) {
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    const metaTag = document.querySelector("meta[name=viewport]");
    if (metaTag) {
      metaTag.remove();
      if (window.innerWidth < 768) {
        window.location.reload();
      }
    }
  }, [location]);

  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Saira'],
      },
    });
  }, []);
  if (localStorage.getItem('userAWB'))
    dispatch(userAwbLoggin(JSON.parse(localStorage.getItem('userAWB'))));

  if (localStorage.getItem('userGuest'))
    dispatch(userGuest(JSON.parse(localStorage.getItem('userGuest'))));

  if (localStorage.getItem('checkin')) {
    dispatch(checkinUser(JSON.parse(localStorage.getItem('checkin'))));
  }

  if (localStorage.getItem('langId'))
    dispatch(changeLang(localStorage.getItem('langId')));
  else dispatch(changeLang('2'));

  if (localStorage.getItem('menuItem'))
    dispatch(menuItemSelected(JSON.parse(localStorage.getItem('menuItem'))));

  if (localStorage.getItem('MAWB'))
    dispatch(setMAWB(JSON.parse(localStorage.getItem('MAWB'))));

  if (localStorage.getItem('HAWB'))
    dispatch(setHAWB(JSON.parse(localStorage.getItem('HAWB'))));

  if (localStorage.getItem('pInfo'))
    dispatch(setPaymentInfo(JSON.parse(localStorage.getItem('pInfo'))));

  const esidExpLocalStrg = JSON.parse(localStorage.getItem("esidExp"));
  if (esidExpLocalStrg) {
    dispatch(setEsidExpSelected(esidExpLocalStrg));
  }

  notification.config({
    placement: 'topRight',
    top: 300,
    duration: 3,
    rtl: false,
  });

  return (
    <Layout style={{ fontFamily: 'Saira', minHeight: '100dvh' }}>
      <Header className="header">
        <CustomHeader />
        <p style={{ display: 'none' }}>
          Build date: {getBuildDate(packageJson.buildDate)}
        </p>
      </Header>
      <Content>
        <Switch>
          <Route exact path={['/', '/trang-chu', '/home']} component={Home} />
          <Route
            path={['/gioi-thieu/:id', '/about-us/:id']}
            component={Introduction}
          />
          <Route path={['/lien-ket', '/link']} component={Links} />
          <Route
            exact
            path={['/dich-vu/:id', '/services/:id']}
            component={Services}
          />
          <Route
            exact
            path={[
              '/dich-vu/bang-gia-dich-vu/:tab',
              '/services/handling-charges/:tab',
            ]}
            component={Services}
          />
          <Route path="/feedback" component={Feedback} />
          <Route path={['/huong-dan/:id', '/guides/:id']} component={Guides} />
          <Route
            path={'/thu-tuc-nhan-hang-nhap/:menu'}
            component={ReceiveImport}
          />
          <Route
            exact
            path={['/kenh-thong-tin/:id', '/news/:id']}
            component={News}
          />
          <Route exact path="/news/detail/:id" component={PostDetail} />
          <Route path="/contacts" component={Contacts} />
          <Route path="/MAWB" component={MAWBInfo} />
          <Route exact path="/nvesidLogin" component={AdminLogin} />
          <Route exact path="/AwbLogin" component={AwbLogin} />
          <Route exact path="/AwbLogin/:type" component={AwbLogin} />
          <PrivateRoute
            path="/Awb/airlines"
            component={Airlines}
            allowedRoles={[RoleClaim.Airlines, RoleClaim.Admin]}
          />
          <PrivateRoute
            path="/changePassword"
            component={ChangePasswordTab}
            allowedRoles={[
              RoleClaim.Agent,
              RoleClaim.VipAgent,
              RoleClaim.Airlines,
              RoleClaim.Admin,
              RoleClaim.BusinessAnalyst,
            ]}
          />
          <PrivateRoute
            path="/statistic"
            component={Statistic}
            allowedRoles={[RoleClaim.Admin, RoleClaim.BusinessAnalyst]}
          />
          <PrivateRoute
            path="/Awb/agent"
            component={Agent}
            allowedRoles={[RoleClaim.Agent, RoleClaim.Admin]}
          />
          <PrivateRoute
            path="/Awb/samsung"
            component={VIP}
            allowedRoles={[RoleClaim.VipAgent, RoleClaim.Admin]}
          />
          <PrivateRoute path="/napas/checkout" component={NapasPaymentPage} />
          <PrivateRoute path="/Awb/checkout" component={Checkout} />
          <PrivateRoute path="/Awb/checkoutHAWB" component={CheckoutHAWB} />
          <Route path="/Awb/checkoutLogin" component={SignIn} />
          <PrivateRoute path="/Esid/Export" component={EsidExport} />
          <PrivateRoute path="/Esid/Checkout" component={EsidCheckout} />
          <Route path="*" component={NotFound} />
        </Switch>
      </Content>
      <Footer className="footer">
        <Row className="footer-slides">
          <Col style={{ background: '#fff', height: '50px' }} span={24}>
            <FooterSlides />
          </Col>
        </Row>
        <FooterContent />
      </Footer>
    </Layout>
  );
}

export default App;
