const initialState = {
  info: undefined,
};

export const paymentReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_PAYMENT_INFO":
      return (state = { ...state, info: action.payload });
    default:
      return state;
  }
};
