import React, { useEffect, useState } from 'react'
import { 
  Table,
  Button, 
  Space, 
  Tooltip,
  Modal 
} from 'antd';
import {
  DeleteOutlined, 
  EditOutlined
} from '@ant-design/icons'
import AnimalsTableHead from './AnimalsTableHead';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { setLivAnmSelectedToEdit } from '../../../../actions/livAnmAction';
import { LivAnmStatusEnum } from '../../../../constant/enumerations';

const { confirm } = Modal;

const AnimalsTable = ({dataSource, setDataSource}) => {
  const { t } = useTranslation("common");
  const [modalVisible, setModalVisible] = useState(false);
  const livAnmsSelectedValue = useSelector((state) => state.livAnms.livAnmsSelectedValue);
  const isDisableEditLivAnmFormDecl = useSelector((state) => state.livAnms.isDisableEditLivAnmFormDecl);
  const dispatch = useDispatch();

  const edit = (record) => {
    dispatch(setLivAnmSelectedToEdit(record));
    setModalVisible(true);
  };

  const handleDelete = (deleteSelected) => {
    if (deleteSelected?.id) {
      const newData = dataSource.map((item) => {
        if (item.id === deleteSelected.id) {
          item.codSts = LivAnmStatusEnum.XX;
        }
        return item;
      });
      setDataSource(newData);
    } else {
      const key = deleteSelected.key;
      const newData = dataSource.filter((item) => item.key !== key);
      setDataSource(newData);
    }
  };

  const showConfirm = (item) => {
    confirm({
      title: t("formInfo.confirmDeleteMsg"),
      centered: true,
      okText: t("ok"),
      cancelText: t('livAnm.formInfo.btnCancel'),
      content: (
        <>
          <div>{`UID: ${item.codUid}`}</div>
          <div>{t("dangerousGood.formInfo.probShpName")}{`: ${item.codPsn}`}</div>
        </>
      ),
      onOk() {
        handleDelete(item);
        dispatch(setLivAnmSelectedToEdit(null));
      },
      onCancel() {
        // console.log('Cancel');
      },
    });
  };

  const getSelectedLivAnm = () => {
    const { certDtl } = livAnmsSelectedValue;
    const certDtlItems = certDtl ? 
    certDtl.map((cert,index) => ({
      ...cert,
      key: index
    })) : [];
    setDataSource(certDtlItems);
  }

  useEffect(() => {
    livAnmsSelectedValue && livAnmsSelectedValue !== null && getSelectedLivAnm();
    // eslint-disable-next-line
  },[livAnmsSelectedValue])

  const defaultColumns = [
    {
      title: t("livAnm.formInfo.numOfPkg"),
      dataIndex: 'numOfPkg',
      key: 'numOfPkg',
      editable: true,
    },
    {
      title: t("livAnm.formInfo.specSpecConReq"),
      dataIndex: 'specSpecConReq',
      key: 'specSpecConReq',
      editable: true,
    },
    {
      title: t("livAnm.formInfo.desNameQty"),
      dataIndex: 'desNameQty',
      key: 'desNameQty',
      editable: true,
      children: [
        {
          title: t("livAnm.formInfo.specDesNam"),
          dataIndex: 'specDesNam',
          key: 'specDesNam',
          editable: true,
        },
        {
          title: t("livAnm.formInfo.qtyAvi"),
          dataIndex: 'qtyAvi',
          key: 'qtyAvi',
          editable: true,
        },
        
      ]
    },
    {
      title: t("livAnm.tableInfo.action"),
      dataIndex: 'action',
      fixed: 'right',
      hidden: isDisableEditLivAnmFormDecl,
      width: 100,
      render: (_, record) => {
        return dataSource.length >= 1 ? (
          <Space>
            <Tooltip title={t("livAnm.tableInfo.btnEdit")}>
              <Button type='text' onClick={() => edit(record)}  icon={<EditOutlined />} />
            </Tooltip>
            <Tooltip title={t("livAnm.tableInfo.btnDelete")}>
                <Button type='text' danger icon={<DeleteOutlined />} onClick={() => showConfirm(record)} />
            </Tooltip>
          </Space>
        ) : null
      },
    },
  ].filter(column => !column.hidden);;

  return (
    <>
      <Table
          bordered
          dataSource={dataSource.filter( x => x.codSts !== LivAnmStatusEnum.XX)}
          scroll={{x: 768}}
          columns={defaultColumns}
          title={() => (<AnimalsTableHead dataSource={dataSource} setDataSource={setDataSource} modalVisible={modalVisible} setModalVisible={setModalVisible}/>)}
          pagination={false}
        >
      </Table>
    </>
);
}

export default AnimalsTable