/* eslint-disable */
import React, { useCallback, useEffect, useState } from "react";
import { Button, Input, notification, Table } from "antd";
import Modal from "antd/lib/modal/Modal";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  IMP_WEB_CHK_LOAD_APT_AGT,
  IMP_WEB_CHK_UPD_APT_AGC,
} from "../../../config/apiConfig";

import { useHistory } from "react-router";
import { checkinUser, userAwbLogout } from "../../../actions/userAWB";
import { setPaymentInfo } from "../../../actions/payment";
const ModalUpdateAgent = ({ visible, info, setVisible, loadData }) => {
  const [valueInput, setValueInput] = useState("");
  const [visibleConfirm, setVisibleConfirm] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [dataProject, setDataProject] = useState([]);
  const { MAWB } = useSelector((state) => state.mawb);
  const { token } = useSelector((state) => state.userAwb.dataCheckin);
  const webTrackToken = useSelector((state) => state.userAwb.dataLogin.token);
  const history = useHistory();
  const dispatch = useDispatch();
  const [agent, setAgent] = useState({ code: "", name: "" });
  const [loading, setLoading] = useState(false);

  const onSearch = useCallback(
    (value) => {
      setLoading(true);
      setTimeout(() => {
        if (dataSource.length > 0) {
          setValueInput(value);
          const newData = dataSource.filter((items) =>
            items.DES_RMK.toLowerCase().includes(value.toLowerCase())
          );
          setDataProject(newData);
        }
        setLoading(false);
      }, 400);
    },
    // eslint-disable-next-line
    [valueInput]
  );

  useEffect(() => {
    setDataProject(dataSource ?? []);
  }, [dataSource]);
  useEffect(() => {
    const timeout = setTimeout(() => {
      onSearch(valueInput);
    }, 500);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line
  }, [valueInput]);

  const FilterByNameInput = (
    <Input.Search
      onChange={(e) => setValueInput(e.target.value)}
      placeholder="Search"
      onSearch={(value) => {
        setValueInput(value);
      }}
    // style={{ marginBottom: 24, borderRadius: '4px !important' }}
    // prefix={<img src={iconSearch} alt="search" />}import { userAwbLogout } from './../../../actions/userAWB';
    />
  );

  const handleOk = async () => {
    if (agent.code === "" || agent.name === "") {
      return;
    }
    try {
      if (info !== undefined) {
        const updateAgentRes = await IMP_WEB_CHK_UPD_APT_AGC(
          info,
          agent.code,
          agent.name,
          webTrackToken ?? token
        );
        if (updateAgentRes.data.slice(0, 2) !== "OK") {
          notification.warn({
            message: updateAgentRes.data.slice(3, updateAgentRes.data.length),
            style: { marginRight: "-50%" },
          });
        } else
          notification.success({
            message: "Update agent successfully!",
            style: { marginRight: "-50%" },
          });
      } else {
        const updateAgentRes = await IMP_WEB_CHK_UPD_APT_AGC(
          {
            COD_AWB_PFX: MAWB.first,
            COD_AWB_NUM: MAWB.last,
            COD_AWB_SFX: MAWB.suffix,
            COD_HWB_NUM: MAWB.hawb,
          },
          agent.code,
          agent.name,
          webTrackToken ?? token
        );
        if (updateAgentRes.data.slice(0, 2) !== "OK") {
          notification.warn({
            message: updateAgentRes.data.slice(3, updateAgentRes.data.length),
            style: { marginRight: "-50%" },
          });
        } else
          notification.success({
            message: "Cập nhật đại lý thành công!",
            style: { marginRight: "-50%" },
          });
      }

      setVisibleConfirm(false);
      setVisible(false);
      loadData();
    } catch (error) {
      if (error.response.status === 401) {
        notification.error({
          style: { marginRight: "-50%" },
          message: t("home.sessionNoti"),
        });

        dispatch(checkinUser({}));
        dispatch(setPaymentInfo(undefined));
        dispatch(userAwbLogout());
        localStorage.removeItem("userAWB");
        localStorage.removeItem("checkin");
        localStorage.removeItem("pInfo");
        history.push("/");
      } else
        notification.error({
          style: { marginRight: "-50%" },
          message: "Lỗi!",
        });
    }
  };

  const columns = [
    {
      title: FilterByNameInput,
      dataIndex: "search",
      key: "search",
      children: [
        {
          title: "Code",
          dataIndex: "COD_CMP_AUT",
          key: "COD_CMP_AUT",
          width: "100px",
        },
        {
          title: "Code Tax",
          dataIndex: "COD_TAX_CMP",
          key: "COD_TAX_CMP",
          width: "150px",
        },
        {
          title: "Name",
          dataIndex: "DES_RMK",
          key: "DES_RMK",
          width: "250px",
        },
        {
          title: "Date Expired",
          dataIndex: "DAT_STP_VLY",
          key: "DAT_STP_VLY",
          width: "200px",
        },
      ],
    },
  ];
  const handleRowSelect = (record) => {
    return {
      onClick: () => {
        setAgent({ code: record.COD_CMP_AUT, name: record.DES_RMK });
      },
    };
  };
  const fetchListAgent = async () => {
    try {
      setLoading(true);
      const loadAgentRes = await IMP_WEB_CHK_LOAD_APT_AGT(
        MAWB.first,
        MAWB.last,
        MAWB.suffix,
        MAWB.hawb,
        webTrackToken ?? token
      );
      let data = [];
      loadAgentRes.data.mCol.map((dt) => {
        data.push({
          COD_CMP_AUT: dt.COD_CMP_AUT,
          DES_RMK: dt.DES_RMK,
          DAT_STP_VLY: moment(dt.DAT_STP_VLY, "YYYY/MM/DD").format("DDMMMYYYY"),
          COD_TAX_CMP: dt.COD_TAX_CMP,
        });
      });
      setDataSource(data);
      setLoading(false);
    } catch (error) {
      if (error.response.status === 401) {
        notification.error({
          style: { marginRight: "-50%" },
          message: t("home.sessionNoti"),
        });

        dispatch(checkinUser({}));
        dispatch(setPaymentInfo(undefined));
        dispatch(userAwbLogout());
        localStorage.removeItem("userAWB");
        localStorage.removeItem("checkin");
        localStorage.removeItem("pInfo");
        history.push("/");
      }
    }
  };
  useEffect(() => {
    if (visible === true) fetchListAgent();
  }, [visible]);

  return (
    <Modal
      width={770}
      title="Update Agent"
      visible={visible}
      onCancel={() => setVisible(false)}
      footer={[
        <Button onClick={() => setVisible(false)}>Cancel</Button>,
        <Button onClick={() => setVisibleConfirm(true)} type="primary">
          Cập nhật
        </Button>,
      ]}
    >
      <Table
        bordered
        loading={loading}
        className="table-agent-list"
        columns={columns}
        pagination={false}
        onRow={handleRowSelect}
        dataSource={dataProject}
        scroll={{ x: "700px", y: "800px" }}
        rowClassName={(record) => {
          return record.COD_CMP_AUT === agent.code ? "row-selected" : "";
        }}
      />
      <Modal
        centered
        width={"620px"}
        title={"Thông báo"}
        visible={visibleConfirm}
        onCancel={() => setVisibleConfirm(false)}
        footer={[
          <Button onClick={() => setVisibleConfirm(false)}>Không</Button>,
          <Button
            disabled={agent.code === ""}
            onClick={handleOk}
            type="primary"
          >
            Đồng ý
          </Button>,
        ]}
      >
        Bạn có đồng ý cập nhật đại lý?
      </Modal>
    </Modal>
  );
};
export default ModalUpdateAgent;
