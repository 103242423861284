import React, { useEffect, useState } from 'react';
import { Tabs, Form, Table, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router";
import moment from 'moment';
import ModalSearchForm from '../EsidModal/ModalSearchForm';
import { formatFltDateDisplay } from '../../../../../utils/utils';
import { GetEsidExportById } from '../../../../../config/nvesidExportApi';
import { errorHandling } from '../../../../../config/AuthIntercept';
import { useDispatch } from 'react-redux';
import { setEsidExpSelected } from '../../../../../actions/esidExportAction';
import useHasRoles from '../../../../../hooks/useHasRoles';
import RoleClaim from '../../../../../constant/roleClaim';

const basketStatus = ['NY', 'VR', 'DR', 'FR', 'AQ', 'AC', 'AM'];
const paidStatus = ['AP', 'QR'];
const invStatus = ['FN', 'OK'];
const notStatusAccpYet = ['AP', 'QR', 'FN'];
export const EsidExpList = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation('common');
  const dateFormat = formatFltDateDisplay;
  const [dataSource, setDataSource] = useState([]);
  const [searchForm] = Form.useForm();
  const [esidStatus, setEsidStatus] = useState(localStorage.getItem('esidFindStatus') || basketStatus);
  const [activeTab, setActiveTab] = useState(localStorage.getItem('expListTab') || 'esid-list-basket');
  const [autoRefresh, setAutoRefresh] = useState(0);

  const hasTECS = useHasRoles([RoleClaim.EsidTecsAgent]);

  useEffect(() => {
    const autoRef = localStorage.getItem('autoRefresh') || '1';
    setAutoRefresh(Number.parseFloat(autoRef));
    localStorage.setItem('GetFromLocation', hasTECS ? 'TEC' : 'TCS');
    switch (activeTab) {
      case 'esid-list-basket':
        setEsidStatus(basketStatus);
        localStorage.setItem('esidFindStatus', basketStatus);
        break;
      case 'esid-list-paid':
        setEsidStatus(paidStatus);
        localStorage.setItem('esidFindStatus', paidStatus);
        break;
      case 'esid-list-inv':
        setEsidStatus(invStatus);
        localStorage.setItem('esidFindStatus', invStatus);
        break;
      case 'esid-list-accp-doc-not-yet':
        setEsidStatus(notStatusAccpYet);
        localStorage.setItem('esidFindStatus', notStatusAccpYet);
        break;
      case 'esid-list-tecs-depot':
        const esidSts = [...basketStatus, ...paidStatus, ...invStatus, ...notStatusAccpYet];
        setEsidStatus(esidSts);
        localStorage.setItem('esidFindStatus', esidSts);
        localStorage.setItem('GetFromLocation', 'TEC');
        break;
      default:
        break;
    }
    // eslint-disable-next-line
  }, [activeTab]);

  const columns = [
    {
      title: 'AWB/ESID',
      dataIndex: 'awbPfxAndNum',
      width: '15%',
      sorter: (a, b) => a.awbPfxAndNum.localeCompare(b.awbPfxAndNum),
    },
    {
      title: t('registerCar.table.flightNo'),
      dataIndex: 'fltCarAndNum',
      width: '15%',
      sorter: (a, b) => a.fltCarAndNum.localeCompare(b.fltCarAndNum),
    },
    {
      title: t('registerCar.table.flightDate'),
      dataIndex: 'datFltOrigin',
      width: '15%',
      render: (_, record) => moment(record.datFltOrigin).format(dateFormat).toLocaleUpperCase(),
      sorter: (a, b) => moment(a.datFltOrigin).unix() - moment(b.datFltOrigin).unix(),
    },
    {
      title: t('registerCar.table.esid'),
      dataIndex: 'esidId',
      width: '15%',
      sorter: (a, b) => a.esidId - b.esidId,
      defaultSortOrder: 'descend',
    },
    {
      title: t('esidExpList.table.dgrChk'),
      dataIndex: 'shcDgr',
      key: 'shcDgr',
      width: '7%',
      sorter: (a, b) => a.shcDgr - b.shcDgr,
      render: (_, record) => (record.shcDgr === 'Y' ? 'Y' : ''),
    },
    {
      title: t('esidExpList.table.dgrAcp'),
      dataIndex: 'dgrAcpStfCod',
      key: 'dgrAcpStfCod',
      width: '10%',
      sorter: (a, b) => a.dgrAcpStfCod.localeCompare(b.dgrAcpStfCod),
      render: (_, record) => record.dgrAcpStfCod || '',
    },
    {
      title: t('admin.acceptance.desRmk002'),
      dataIndex: 'desRmk002',
      key: 'desRmk002',
      width: '15%',
      align: 'left',
      ellipsis: true,
      sorter: (a, b) => a.desRmk002.localeCompare(b.desRmk002),
    },
    {
      title: t('Đã gửi mail'),
      dataIndex: 'numOfMalSend',
      key: 'numOfMalSend',
      width: '10%',
    },
    {
      title: t('esidExpList.table.status'),
      dataIndex: 'codSts',
      width: '25%',
      render: (_, record) => t(`esid.codSts.${record.codSts}`),
      sorter: (a, b) => t(`esid.codSts.${a.codSts}`).localeCompare(t(`esid.codSts.${b.codSts}`)),
    },
  ];

  const handleAutoRefreshData = (value) => {
    localStorage.setItem('autoRefresh', value);
    setAutoRefresh(value);
  }

  const [esidLoading, setEsidLoading] = useState(false);
  const onSelectChange = async (selectedRowKeys, selectedRows) => {
    try {
      if (selectedRows && selectedRows.length > 0) {
        setEsidLoading(true);
        const { data } = await GetEsidExportById(selectedRows[0].esidId);
        setEsidLoading(false);
        dispatch(setEsidExpSelected(data));
        history.push('/nvesid');
      }
    } catch (error) {
      errorHandling(error);
    }
  };

  const onChangeTab = (tab) => {
    setActiveTab(tab);
    localStorage.setItem('expListTab', tab);
  }

  return (
    <>
      <div style={{ marginBottom: '5px' }}>
        <ModalSearchForm setDataSource={setDataSource} form={searchForm} filterCodSts={esidStatus} autoRefresh={autoRefresh} />
      </div>
      <div>
        <form style={{ marginBottom: 10 }}>
          <label htmlFor='autoRefreshSelect' style={{ fontWeight: 'bold' }}>{'Tự động lấy dữ liệu sau:  '}</label>
          <Select
            name='autoRefreshSelect'
            value={autoRefresh}
            style={{ width: 150 }}
            onChange={handleAutoRefreshData}
            options={[
              { value: 0, label: 'không áp dụng' },
              { value: 0.5, label: '30 giây' },
              { value: 1, label: '1 phút' },
              { value: 3, label: '3 phút' },
              { value: 5, label: '5 phút' },
              { value: 10, label: '10 phút' },
            ]}
          />
        </form>
      </div>
      <Tabs activeKey={activeTab} onChange={(key) => onChangeTab(key)}>
        <Tabs.TabPane tab="Chưa thanh toán" key="esid-list-basket">
          <Table
            bordered
            columns={columns}
            pagination={{
              position: 'bottomRight',
              defaultPageSize: 100,
            }}
            dataSource={dataSource}
            loading={esidLoading}
            rowClassName='cursorPointer'
            onRow={(record) => ({
              onClick: () => onSelectChange([record.key], [record])  
            })}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Đã thanh toán" key="esid-list-paid">
          <Table
            bordered
            columns={columns}
            pagination={{
              position: 'bottomRight',
              defaultPageSize: 100,
            }}
            dataSource={dataSource}
            loading={esidLoading}
            rowClassName='cursorPointer'
            onRow={(record) => ({
              onClick: () => onSelectChange([record.key], [record])  
            })}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Đã xuất hóa đơn" key="esid-list-inv">
          <Table
            bordered
            columns={columns}
            pagination={{
              position: 'bottomRight',
              defaultPageSize: 100,
            }}
            dataSource={dataSource}
            loading={esidLoading}
            rowClassName='cursorPointer'
            onRow={(record) => ({
              onClick: () => onSelectChange([record.key], [record])  
            })}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Chưa Accept Doc" key="esid-list-accp-doc-not-yet">
          <Table
              bordered
              columns={columns}
              pagination={{
                position: 'bottomRight',
                defaultPageSize: 100,
              }}
              dataSource={dataSource}
              loading={esidLoading}
              rowClassName='cursorPointer'
              onRow={(record) => ({
                onClick: () => onSelectChange([record.key], [record])  
              })}
            />
        </Tabs.TabPane>
        {!hasTECS && (
          <Tabs.TabPane tab="Kho hàng TECS" key="esid-list-tecs-depot">
            <Table
                bordered
                columns={columns}
                pagination={{
                  position: 'bottomRight',
                  defaultPageSize: 100,
                }}
                dataSource={dataSource}
                loading={esidLoading}
                rowClassName='cursorPointer'
                onRow={(record) => ({
                  onClick: () => onSelectChange([record.key], [record])  
                })}
              />
          </Tabs.TabPane>
        )}
      </Tabs>
    </>
  );
};
