import { Button, Form, Input, InputNumber, Modal, Space } from "antd";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ModalName } from "../../../../constant/enumerations";
import { setExpConsolDtlEdit, setExpConsolDtlList, setModalEsidVisibility } from "../../../../actions/esidExportAction";
import { createUUID } from "../../../../utils/utils";

export const EditConsolModel = () => {
  const { t } = useTranslation("common");
  const [form] = Form.useForm();
  const visibility = useSelector((state) => state.esidExport.modal[`${ModalName.ConsolModal}`]);
  const esidSelected = useSelector(state => state.esidExport.esidExpSelected);
  const consolDtlList = useSelector(state => state.esidExport.consolDtlList);
  const consolDtlEdit = useSelector(state => state.esidExport.consolDtlEdit);
  const dispatch = useDispatch();

  useEffect(() => {
    form.setFieldsValue({
      codHwbNum: consolDtlEdit?.codHwbNum,
      qrCod: consolDtlEdit?.qrCod,
      qtyPcs: consolDtlEdit?.qtyPcs,
      qtyWgt: consolDtlEdit?.qtyWgt,
      natureOfGoods: consolDtlEdit?.natureOfGoods
    });
    // eslint-disable-next-line
  }, [consolDtlEdit])

  const closeSelf = () => {
    dispatch(setExpConsolDtlEdit(null));
    dispatch(setModalEsidVisibility({ [`${ModalName.ConsolModal}`]: false }));
  };

  const saveConsolDtl = (values, closeModal = true) => {
    const dtlList  = [...consolDtlList];
    const dataDtl = {
      esidId: esidSelected.id,
      codAwbPfx: esidSelected.codAwbPfx,
      codAwbNum: esidSelected.codAwbNum,
      codAwbSfx: esidSelected.codAwbSfx,
      codSts: 'NY',
      ...consolDtlEdit,
      ...values
    }
    if (!dataDtl.key) {
      dataDtl.key = createUUID();
      dtlList.push(dataDtl);
    } else {
      const idx = dtlList.findIndex(x => x.key === dataDtl.key);
      if (idx > -1) {
        dtlList[idx] = dataDtl;
      }
    }
    dispatch(setExpConsolDtlList(dtlList));
    if (closeModal) {
      closeSelf();
    }
  }

  const saveAndCreateConsolDtl = async () => {
    const values = await form.validateFields();
    saveConsolDtl(values, false);
    form.resetFields();
    dispatch(setExpConsolDtlEdit(null));
  }

  return (
    <>
      <Modal visible={visibility} centered onCancel={closeSelf} footer={null}>
        <Form form={form} layout="vertical" initialValues={{
          codHwbNum: consolDtlEdit?.codHwbNum,
          qrCod: consolDtlEdit?.qrCod,
          qtyPcs: consolDtlEdit?.qtyPcs || 0,
          qtyWgt: consolDtlEdit?.qtyWgt,
          natureOfGoods: consolDtlEdit?.natureOfGoods
        }} onFinish={saveConsolDtl} requiredMark>
          <Form.Item
            name="codHwbNum"
            label={t("consolLabels.hawb")}
            required
            rules={[
              {
                  required: true,
                  message: t('dangerousGood.errorInfo.required'),
              },
            ]}
          >
            <Input name="codHwbNum"/>
          </Form.Item>

          <Form.Item
            name="qrCod"
            label={t("consolLabels.qrCode")}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="qtyPcs"
            label={t("consolLabels.qtyPcs")}
            required
            rules={[
              {
                  required: true,
                  message: t('dangerousGood.errorInfo.required'),
              },
            ]}
          >
            <InputNumber min={0} style={{ width: '100%', height: '32px' }}/>
          </Form.Item>

          <Form.Item
            name="qtyWgt"
            label={t("consolLabels.qtyWgt")}
          >
            <InputNumber min={0} style={{ width: '100%', height: '32px' }}/>
          </Form.Item>

          <Form.Item
            name="natureOfGoods"
            label={t("consolLabels.nog")}
            required
            rules={[
              {
                  required: true,
                  message: t('dangerousGood.errorInfo.required'),
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item style={{ display: "flex", justifyContent: "center" }}>
            <Space style={{ justifyContent: "center", width: "100%" }}>
              <Button
                type="primary"
                htmlType="submit"
                style={{ width: "fit-content" }}
                className="submitBtn"
              >
                {t("consolLabels.save")}
              </Button>
              <Button style={{ width: "fit-content" }} className="submitBtn" onClick={saveAndCreateConsolDtl}>
                {t("consolLabels.saveAndAdd")}
              </Button>
              <Button style={{ width: "fit-content" }} className="submitBtn" onClick={closeSelf}>
                {t("consolLabels.cancel")}
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
