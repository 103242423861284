import React, { useEffect, useRef, useState } from "react";
import {
  Row,
  Col,
  Space,
  Button,
  Spin,
  notification,
  Collapse,
  Typography,
  Modal,
} from "antd";
import { useForm } from "antd/es/form/Form";
import AnimalsTable from "./components/AnimalsTable";
import IATAForm from "./components/IATAForm";
import "./style.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  setLivAnms,
  setLivAnmsSelectedValue,
  setEsidLoading,
  setDisableEditLivAnmsFormDecl,
  setIsVisableEditLivAnmButton,
  setLivAnmSelectedToEdit,
} from "../../../actions/livAnmAction";
import {
  CheckIfLivAnmIsAllowEditing,
  CreateCertLivAnm,
  GetCertLivAnmByEsid,
  UpdateInformationOfLivAnm,
} from "../../../config/esidExportApi";
import { errorHandling } from "../../../config/AuthIntercept";
import { setEsidExpTabPaneActive, setTabEsidVisibility } from "../../../actions/esidExportAction";
import { esidExpTabsEnum } from "../../../constant/enumerations";
import { useHistory, useLocation } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { RegistryExportPrint } from "../../RegistryExportPrint";


const { Panel } = Collapse;

const { confirm } = Modal;

const iatas = ["iatA001", "iatA002", "iatA003", "iatA004", "iatA005"];
export const objectToAntFormData = (object) => {
  const antFormData = [];
  for (const key in object) {
    antFormData.push({
      name: [key],
      value: object[key],
    });
  }
  return antFormData;
};
const TabExpCertLivAnm = () => {
  const [loadingCheckIsEditable, setLoadingCheckIsEditable] = useState(false);
  const { t } = useTranslation("common");
  const history = useHistory();
  const location = useLocation();
  const [shipperForm] = useForm();
  const [consigneeForm] = useForm();
  const [iataForm] = useForm();
  const [airwayBillForm] = useForm();
  const [noteForm] = useForm();
  const esidExport = useSelector((state) => state.esidExport.esidExpSelected);
  const dgrSelected = useSelector((state) => state.dgrGoods.dgrSelectedValue);
  const isEsidFormLoading = useSelector(
    (state) => state.livAnms.isEsidFormLoading
  );
  const dataSource = useSelector((state) => state.livAnms.livAnms);
  // const dataExport = useSelector((state) => state.livAnms.livAnmsSelectedValue);
  const livAnmsSelectedValue = useSelector(
    (state) => state.livAnms.livAnmsSelectedValue
  );
  const isVisableEditLivAnmButton = useSelector((state) => state.livAnms.isVisableEditLivAnmButton);
  const isDisableEditLivAnmFormDecl = useSelector((state) => state.livAnms.isDisableEditLivAnmFormDecl);

  const dispatch = useDispatch();
  const [submitLoading, setSubmitLoading] = useState(false);
  const [iataActiveKey, setIataActiveKey] = useState("1");

  const handleIataKeyChange = (key) => {
    setIataActiveKey(key);
  };

  const showError = () => {
    notification.error({
      message: t("Oops"),
      centered: true,
      description: t("livAnm.tableInfo.emptyError"),
    });
  };

  const unEditableNoti = () => {
    notification.error({
      message: t("Oops"),
      centered: true,
      description: t("livAnm.unEdittable"),
    });
  };

  const nextStep = (esid, currentStep) => {
    window.scroll({ left: 0, top: 350 });
    if (esid?.shcDgr === 'Y' && currentStep === esidExpTabsEnum.ExpRegis) {
      dispatch(setTabEsidVisibility(esidExpTabsEnum.Dgr));
      return;
    }
    if (esid?.shcAvi === 'Y' && 
      (currentStep === esidExpTabsEnum.ExpRegis || currentStep === esidExpTabsEnum.Dgr)) {
        dispatch(setTabEsidVisibility(esidExpTabsEnum.LivAnm));
      return;
    }
    if (esid?.shcConsol === 'Y' && 
      (currentStep === esidExpTabsEnum.ExpRegis || 
        currentStep === esidExpTabsEnum.Dgr || currentStep === esidExpTabsEnum.LivAnm)) {
          dispatch(setTabEsidVisibility(esidExpTabsEnum.Consol));
      return;
    }
    dispatch(setTabEsidVisibility(esidExpTabsEnum.CarDim));
  }

  const handleSubmit = async () => {
    if (!esidExport) {
      // Todo: Notification error
      return;
    }
    if (dataSource && dataSource.length > 0) {
      setSubmitLoading(true);
      await shipperForm.validateFields();
      await consigneeForm.validateFields();
      await airwayBillForm.validateFields();
      const noteFormValue = await noteForm.validateFields();
      const { iatA } = await iataForm.validateFields();
      let iataFormValue = {};
      
      iatas.forEach((iataKey) => {
        if (iatA && iatA.includes(iataKey)) {
          iataFormValue[iataKey] = "Y";
        } else {
          iataFormValue[iataKey] = "N";
        }
      });
      
      const certDtl = dataSource.map(({ key, codSts,...rest }) => ({ 
        ...rest,
        codSts: codSts || 'NY'
      }));
      const postValue = {
        esidId: esidExport.id,
        codAwbPfx: esidExport.codAwbPfx,
        codAwbNum: esidExport.codAwbNum,
        codAwbSfx: esidExport.codAwbSfx,
        codOri: esidExport.codOri,
        codFds: esidExport.codFds,
        codFltCar: esidExport.codFltCar,
        codFltNum: esidExport.codFltNum,
        datFltOri: esidExport.datFltOri,
        desRmk: noteFormValue.desRmk, // Additional Handling Information
        shpRegDat: livAnmsSelectedValue?.shpRegDat || new Date(),
        shpRegIdx: esidExport.shpRegIdx,
        shpRegNam: esidExport.shpRegNam,
        shpRegAdr: esidExport.shpRegAdr,
        shpRegTel: esidExport.shpRegTel,
        shpRegEml: esidExport.shpRegEml,
        shpRegSignYn: esidExport.shpRegSignYn,
        // CodSts: certDtl.codSts || "NY",
        codSts: "NY",
        usrCre: esidExport.UsrCre,
        usrUpd: esidExport.UsrUpd,
        certDtl,
        ...iataFormValue,
      };
      try {
        // const resp = await CreateCertLivAnm(postValue);
        // dispatch(setLivAnmsSelectedValue(resp.data));
        // showSuccess();
        // setSubmitLoading(false);
        if (livAnmsSelectedValue?.id && esidExport?.id) { 
          const resp = await UpdateInformationOfLivAnm(esidExport.id, livAnmsSelectedValue.id, postValue);
          dispatch(setLivAnmsSelectedValue(resp.data));
          dispatch(setDisableEditLivAnmsFormDecl(true))
          dispatch(setIsVisableEditLivAnmButton(true))
        }else{     
          const resp = await CreateCertLivAnm(postValue);
          dispatch(setLivAnmsSelectedValue(resp.data));
        }
        nextStep(esidExport, esidExpTabsEnum.LivAnm);
        setSubmitLoading(false);
      } catch (error) {
        setSubmitLoading(false);
        errorHandling(error, null, null);
      }
    } else {
      showError();
      return;
    }
  };

  useEffect(() => {
    if (esidExport) {
      const { shipper, consignee, desRmk, ...rest } = esidExport;
      shipperForm.setFields(objectToAntFormData(shipper));
      consigneeForm.setFields(objectToAntFormData(consignee));
      airwayBillForm.setFields(objectToAntFormData(rest));
      noteForm.setFields(objectToAntFormData({ desRmk: desRmk }));
      fetchData();
    }
    // eslint-disable-next-line
  }, [esidExport]);

  const fetchData = async () => {
    try {
      dispatch(setEsidLoading(true));
      const { data } = await GetCertLivAnmByEsid(esidExport.id);
      dispatch(setLivAnmsSelectedValue(data));
      let iatAItems = [];
      iatas.forEach((iata) => {
        if (data[iata] && data[iata] === "Y") {
          iatAItems.push(iata);
        }
      });
      iataForm.setFields(objectToAntFormData({ iatA: iatAItems }));
      dispatch(setEsidLoading(false));
    } catch (error) {
      dispatch(setLivAnmsSelectedValue(null));
      dispatch(setLivAnms([]));
      dispatch(setLivAnmSelectedToEdit(null));
      dispatch(setEsidLoading(false));
      errorHandling(error, history, location.pathname, false);
    }
  };

  const showConfirm = () => {
    confirm({
      title: t("formInfo.confirmSaveTitle"),
      content: t("formInfo.confirmSaveMsg"),
      centered: true,
      okText: t("save"),
      cancelText: t('livAnm.formInfo.btnCancel'),
      onOk() {
        handleSubmit().then(() => {
          window.scrollTo(0, 350);
          dispatch(setEsidExpTabPaneActive(esidExpTabsEnum.ExpRegis));  
        });
      },
      onCancel() {
        window.scrollTo(0, 350);
        dispatch(setEsidExpTabPaneActive(esidExpTabsEnum.ExpRegis));
      },
    });
  };

  const handleBackToESID = async () => {
    if (
      (dataSource.length > 0 && !isVisableEditLivAnmButton) ||
      (!livAnmsSelectedValue && dataSource.length > 0)
    ) {
      showConfirm();
    } else {
      window.scrollTo(0, 380);
      dispatch(setEsidExpTabPaneActive(esidExpTabsEnum.ExpRegis));
    }
  };

  // const handleVehicleRegis = () => {
  //   window.scrollTo(0, 380);
  //   dispatch(setEsidExpTabPaneActive(esidExpTabsEnum.VhcRegis));
  // };

  const handleCancel = () =>{
    if(livAnmsSelectedValue && livAnmsSelectedValue?.id !== null){
      const previousLivItems = livAnmsSelectedValue.certDtl.map((items) => ({
        ...items,
        key: items.id
      }));
      dispatch(setLivAnms(previousLivItems));
    }else{
      dispatch(setLivAnms([]));
    }
    dispatch(setDisableEditLivAnmsFormDecl(true));
    dispatch(setIsVisableEditLivAnmButton(true));
  }

  const handleEditForm = async () => {
    //To do
    try {
      setLoadingCheckIsEditable(true);
      const checkIsEditable = await CheckIfLivAnmIsAllowEditing(livAnmsSelectedValue.id);
      if (checkIsEditable.data.allowEdit) {
        dispatch(setDisableEditLivAnmsFormDecl(false))
        dispatch(setIsVisableEditLivAnmButton(false))
      }else{
        unEditableNoti();
      }
      setLoadingCheckIsEditable(false);
    } catch (error) {
      setLoadingCheckIsEditable(false);
      errorHandling(error, history, location.pathname);
    }
  };

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    copyStyles: true,
    pageStyle: `
      @media all {
        .page-break {
          display: none;
        }
      }
      @media print {
        html, body {
          padding: 0.7rem 0.7rem 0.7rem 0.7rem;
          height: initial !important;
          overflow: initial !important;
          -webkit-print-color-adjust: exact;
        }
      }
      @media print {
        .page-break {
          margin-top: 0.7rem;
          display: block;
          page-break-before: auto;
        }
      }
      @media print {
        @page { 
          size: A4 portrait;
          margin: 0;
        }
      }
    `
  });

  useEffect(() => {
    dispatch(setDisableEditLivAnmsFormDecl(true))
    dispatch(setIsVisableEditLivAnmButton(true))
    // eslint-disable-next-line
  },[])

  return (
    <>
      {esidExport ? (
        <>
          <p className='exp-sub-title' style={{ marginTop: '10px' }}>{t("formInfo.certLivAnmLabel")}</p>
          <Row justify="center">
            <Col span={24} md={24} lg={22} xl={22} className="awb-tab-content">
              <p className="registry-notice">{t("header.registryInvNotice")}</p>
            </Col>
          </Row>
          {/* <Collapse className="form-animals">
            <Panel
              header={
                <>
                  <Typography.Text strong>
                    {`${t("formInfo.airWaybillNumber")}: `}
                  </Typography.Text>
                  {`${esidExport.codAwbPfx} - ${esidExport.codAwbNum}`}
                </>
              }
              key="1"
              style={{ marginBottom: 10 }}
            >
              <Row gutter={[10, 40]}>
                <Col lg={14} sm={24} xs={24}>
                  <LeftFormContainer
                    forms={{ shipperForm, consigneeForm, noteForm }}
                  />
                </Col>
                <Col lg={10} sm={24} xs={24}>
                  <RightFormContainer forms={{ airwayBillForm }} />
                </Col>
              </Row>
            </Panel>
          </Collapse> */}
          <Collapse
            // collapsible={(livAnmsSelectedValue && livAnmsSelectedValue !== null) && 'disabled'}
            className="form-animals iata-form"
            activeKey={iataActiveKey}
            onChange={handleIataKeyChange}
            collapsible={livAnmsSelectedValue?.id && isDisableEditLivAnmFormDecl ? 'disabled' : 'header'}
          >
            <Panel
              header={
                <>
                  <Typography.Text strong>
                    {t(`livAnm.iataForm.title`)}
                  </Typography.Text>
                </>
              }
              key="1"
              style={{ marginBottom: 10 }}
            >
              <Row gutter={[10, 40]}>
                <Col span={24}>
                  <IATAForm form={iataForm} />
                </Col>
              </Row>
            </Panel>
          </Collapse>

          <AnimalsTable
            dataSource={dataSource}
            setDataSource={(animals) => dispatch(setLivAnms(animals))}
          />

          <Space
            style={{
              marginTop: "20px",
              display: "flex",
              justifyContent: "center",
            }}
            className="awb-tab-content"
          >
            <Button style={{ width: "fit-content" }} onClick={handleBackToESID}>
              {t("formInfo.backToESID")}
            </Button>

            {livAnmsSelectedValue?.id && isVisableEditLivAnmButton ? (
              <Button loading={loadingCheckIsEditable} style={{ width: "fit-content" }} onClick={handleEditForm}>
                {t("formInfo.editForm")}
              </Button>
            ) : (
              <></>
            )}

            {livAnmsSelectedValue?.id && !isVisableEditLivAnmButton ? (
              <Button loading={submitLoading} onClick={handleSubmit} style={{ width: "fit-content" }}>
                 {t("formInfo.saveForm")}
              </Button>
            ) : (
              <></>
            )}

            {livAnmsSelectedValue && livAnmsSelectedValue !== null ? (
              <Button onClick={handlePrint} style={{ width: "fit-content" }}>
                {t("header.print")} ESID
              </Button>
            ) : (
              <Button
                type="primary"
                loading={submitLoading}
                onClick={handleSubmit}
                style={{ width: "fit-content" }}
              >
                {t("formInfo.submit")}
              </Button>
            )}

            {/* <Button onClick={handlePrint} style={{ width: "fit-content" }}>
              {t("header.print")}
            </Button>
            <Button
              type="primary"
              loading={submitLoading}
              onClick={handleSubmit}
              style={{ width: "fit-content" }}
            >
              {t("formInfo.submit")}
            </Button> */}

            {/* <Button
              onClick={handleVehicleRegis}
              style={{ width: "fit-content" }}
            >
              {t("formInfo.registration")}
            </Button> */}
            {livAnmsSelectedValue?.id && !isVisableEditLivAnmButton ? (
              <Button
                onClick={handleCancel}
                style={{ width: "fit-content" }}
              >
                {t("livAnm.formInfo.btnCancel")}
              </Button>
            ) : (
              <></>
            )}
             
          </Space>
          {isEsidFormLoading && (
            <Space
              style={{
                justifyContent: "center",
                display: "flex",
                position: "fixed",
                inset: 0,
                backgroundColor: "#0000001f",
              }}
            >
              <Spin tip={"Loading"} />
            </Space>
          )}
          {/* {dataExport && (
            <Row>
              <Col span={0}>
                <ExpCertLivAnmPrint
                  ref={componentRef}
                  dataExport={dataExport}
                />
              </Col>
            </Row>
          )} */}
          {esidExport && (
            <Row>
              <Col span={0}>
                <RegistryExportPrint
                  ref={componentRef}
                  esidExport={esidExport}
                  dgrSelected={dgrSelected}
                  aviSelected={livAnmsSelectedValue}
                  // QR={QRToPrint}
                  // numberQR={numberQR}
                />
              </Col>
            </Row>
          )}
        </>
      ) : (
        <>Living animals</>
      )}
    </>
  );
};

export default TabExpCertLivAnm;
