import React from "react";
import { ClockCircleOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import Parser from "html-react-parser";
import "./component-css/NewsTabsContent.css";
import * as Constant from "../config/Constant";
import Moment from "react-moment";
const NewsTabsContent = (props) => {
  return (
    <div className="post-wrapper">
      <div className="post-cover-wrapper">
        <Link to={`/news/detail/${props.post.id}`}>
          <img
            className="post-cover"
            alt="post-cover"
            src={`${Constant.IMAGES_URL}${props.post.images}`}
          />
        </Link>
      </div>
      <div className="post-content-wrapper">
        <Link to={`/news/detail/${props.post.id}`}>{props.post.title}</Link>
        <h6>
          <ClockCircleOutlined />
          <Moment format="DD-MM-YYYY">{props.post.created}</Moment>
        </h6>
        <div className="ck-content">
          {props.post.introtext &&
            Parser(props.post.introtext ? props.post.introtext : "")}
        </div>
      </div>
    </div>
  );
};

export default NewsTabsContent;
