import React from 'react'


const getHashOfString = (str) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    // tslint:disable-next-line: no-bitwise
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  hash = Math.abs(hash);
  return hash;
};

const normalizeHash = (hash, min, max) => {
  return Math.floor((hash % (max - min)) + min);
};

const generateHSL = (name, saturationRange, lightnessRange) => {
  const hash = getHashOfString(name);
  const h = normalizeHash(hash, 0, 360);
  const s = normalizeHash(hash, saturationRange[0], saturationRange[1]);
  const l = normalizeHash(hash, lightnessRange[0], lightnessRange[1]);
  return [h, s, l];
};

const HSLtoString = (hsl) => {
  return `hsl(${hsl[0]}, ${hsl[1]}%, ${hsl[2]}%)`;
};

const generateColorHsl = (id, saturationRange, lightnessRange) => {
  return HSLtoString(generateHSL(id, saturationRange, lightnessRange));
};

const UserAvatar = (props) => {
  const saturationRange = [65,85];
  const lightnessRange = [45,65];
  const firstLetter = props.userName[0];
  const color = generateColorHsl(props.userName,saturationRange, lightnessRange);
  return (
    <div 
        className="user-avatar" 
        style={{
            display: 'flex', 
            justifyContent: 'center',
            alignItems: 'center',
            color: 'white',
            textTransform: 'uppercase',
            overflow: 'hidden',
            width: props.width,
            height: props.height ? props.height : props.width,
            borderRadius: props.radius ? props.radius : '50%',
            backgroundColor: color,
            ...props.style
        }}
    >
        {firstLetter}
    </div>
  )
}

export default UserAvatar