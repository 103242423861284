import {
  Button,
  Col,
  Modal,
  notification,
  Row,
  Space,
  Spin,
  Checkbox,
} from "antd";
import { useForm } from "antd/es/form/Form";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import {
  setDgrGoods,
  setDgrSelectedValue,
  setDgrTypeStatusOK,
  setDisableEditDrgGood,
  setEsidLoading,
  setIsVisableEditDrgGoodButton,
} from "../../../actions/dgrGoodsAction";
import { setEsidExpTabPaneActive, setTabEsidVisibility } from "../../../actions/esidExportAction";
import { errorHandling } from "../../../config/AuthIntercept";
import { CheckIfDrgIsAllowEditing, CreateDeclDgr, GetDeclDgrByEsid, GetDgrTypeStatusOK, UpdateInformationOfDgr } from "../../../config/esidExportApi";
import { esidExpTabsEnum } from "../../../constant/enumerations";
import GoodsTable from "./components/GoodsTable";
import "./style.css";
import DryIceTable from "./components/DryIceTable";
import PinTable from "./components/PinTable";
import { RegistryExportPrint } from "../../RegistryExportPrint";

const { confirm } = Modal;

export const objectToAntFormData = (object) => {
  const antFormData = [];
  for (const key in object) {
    antFormData.push({
      name: [key],
      value: object[key],
    });
  }
  return antFormData;
};
const TabExpDeclDgr = () => {
  const [loadingCheckIsEditable, setLoadingCheckIsEditable] = useState(false);
  const { t } = useTranslation("common");
  const history = useHistory();
  const location = useLocation();
  const [shipperForm] = useForm();
  const [consigneeForm] = useForm();
  const [airwayBillForm] = useForm();
  const [noteForm] = useForm();
  const esidExport = useSelector((state) => state.esidExport.esidExpSelected);
  const dataSource = useSelector((state) => state.dgrGoods.dgrGoods);
  const dataExport = useSelector((state) => state.dgrGoods.dgrSelectedValue); 
  const dgrSelectedValue = useSelector(
    (state) => state.dgrGoods.dgrSelectedValue
  );
  const livAnmsSelectedValue = useSelector((state) => state.livAnms.livAnmsSelectedValue);
  const dgrTypeStatusList = useSelector((state) => state.dgrGoods.dgrTypeStatusOK);
  // const uidList = useSelector((state) => state.dgrGoods.uids);
  const isVisableEditDgrGoodsButton = useSelector((state) => state.dgrGoods.isVisableEditDgrGoodsButton);

  const dispatch = useDispatch();
  const [submitLoading, setSubmitLoading] = useState(false);

  const showError = () => {
    notification.error({
      message: t("Oops"),
      top: 100,
      description: t("dangerousGood.tableInfo.emptyError"),
    });
  };

  const unEditableNoti = () => {
    notification.error({
      message: t("Oops"),
      centered: true,
      description: t("dangerousGood.unEdittable"),
    });
  };

  const nextStep = (esid, currentStep) => {
    window.scroll({ left: 0, top: 350 });
    if (esid?.shcDgr === 'Y' && currentStep === esidExpTabsEnum.ExpRegis) {
      dispatch(setTabEsidVisibility(esidExpTabsEnum.Dgr));
      return;
    }
    // if (esid?.shcAvi === 'Y' && 
    //   (currentStep === esidExpTabsEnum.ExpRegis || currentStep === esidExpTabsEnum.Dgr)) {
    //     dispatch(setTabEsidVisibility(esidExpTabsEnum.LivAnm));
    //   return;
    // }
    if (esid?.shcConsol === 'Y' && 
      (currentStep === esidExpTabsEnum.ExpRegis || 
        currentStep === esidExpTabsEnum.Dgr || currentStep === esidExpTabsEnum.LivAnm)) {
          dispatch(setTabEsidVisibility(esidExpTabsEnum.Consol));
      return;
    }
    dispatch(setTabEsidVisibility(esidExpTabsEnum.CarDim));
  }
  
  const handleSubmit = async () => {
    if (!esidExport) {
      // Todo: Notification error
      return;
    }
    if (dataSource && dataSource.length > 0) {
      setSubmitLoading(true);
      await shipperForm.validateFields();
      await consigneeForm.validateFields();
      await airwayBillForm.validateFields();
      const noteFormValue = await noteForm.validateFields();
      const dgrItems = dataSource.map(({ key, codSts, ...rest }) => ({
        ...rest,
        codSts: codSts || 'NY'
      }));
      
      const postValue = {
        esidId: esidExport.id,
        shipper: esidExport.shipper,
        consignee: esidExport.consignee,
        notify: esidExport.notify,
        agent: esidExport.agent,
        codAwbPfx: esidExport.codAwbPfx,
        codAwbNum: esidExport.codAwbNum,
        codAwbSfx: esidExport.codAwbSfx,
        codOri: esidExport.codOri,
        codFds: esidExport.codFds,
        codFltCar: esidExport.codFltCar,
        codFltNum: esidExport.codFltNum,
        datFltOri: esidExport.datFltOri,
        desRmk: noteFormValue.desRmk, // Additional Handling Information
        shpRegDat: dgrSelectedValue?.shpRegDat || new Date(),
        shpRegIdx: esidExport.shpRegIdx,
        shpRegNam: esidExport.shpRegNam,
        shpRegAdr: esidExport.shpRegAdr,
        shpRegTel: esidExport.shpRegTel,
        shpRegEml: esidExport.shpRegEml,
        shpRegSignYn: esidExport.shpRegSignYn,
        UsrCre: esidExport.UsrCre,
        UsrUpd: esidExport.UsrUpd,
        dgrItems,
      };

      try {
        if (dgrSelectedValue?.id && esidExport?.id) {
          const resp = await UpdateInformationOfDgr(esidExport.id, dgrSelectedValue.id, dgrItems);
          dispatch(setDgrSelectedValue(resp.data));
          dispatch(setDisableEditDrgGood(true))
          dispatch(setIsVisableEditDrgGoodButton(true))
        }else{     
          const resp = await CreateDeclDgr(postValue);
          dispatch(setDgrSelectedValue(resp.data));
        }
        nextStep(esidExport, esidExpTabsEnum.Dgr);
        setSubmitLoading(false);
      } catch (error) {
        setSubmitLoading(false);
        errorHandling(error, null, null);
      }
    } else {
      showError();
      return;
    }
  };

  const fetchData = async () => {
    try {
      dispatch(setEsidLoading(true));
      // if (!uidList || uidList?.length === 0) {
      //   const res = await GetUidOptions();
      //   dispatch(setUids(res.data));
      // }
      if (!dgrSelectedValue ) {
        const { data } = await GetDeclDgrByEsid(esidExport.id);
        dispatch(setDgrSelectedValue(data));
      }
      dispatch(setEsidLoading(false));
    } catch (error) {
      dispatch(setDgrSelectedValue(null));
      dispatch(setEsidLoading(false));
      errorHandling(error, history, location.pathname, false);
    }
  };

  useEffect(() => {
    if (esidExport) {
      const { shipper, consignee, desRmk, ...rest } = esidExport;
      shipperForm.setFields(objectToAntFormData(shipper));
      consigneeForm.setFields(objectToAntFormData(consignee));
      airwayBillForm.setFields(objectToAntFormData(rest));
      noteForm.setFields(objectToAntFormData({ desRmk: desRmk }));
      fetchData();
    }
    // eslint-disable-next-line
  }, [esidExport]);

  const showConfirm = () => {
    confirm({
      title: t("formInfo.confirmSaveTitle"),
      content: t("formInfo.confirmSaveMsg"),
      centered: true,
      okText: t("save"),
      cancelText: t('dangerousGood.formInfo.btnCancel'),
      onOk() {
        handleSubmit().then(() => {
          window.scrollTo(0, 380);
          dispatch(setEsidExpTabPaneActive(esidExpTabsEnum.ExpRegis));
        });
      },
      onCancel() {
        window.scrollTo(0, 380);
        dispatch(setEsidExpTabPaneActive(esidExpTabsEnum.ExpRegis));
      },
    });
  };

  const handleBackToESID = () => {
    if (
      (dataSource.length > 0 && !isVisableEditDgrGoodsButton) ||
      (!dgrSelectedValue && dataSource.length > 0)
    ) {
      showConfirm();
    } else {
      window.scrollTo(0, 350);
      dispatch(setEsidExpTabPaneActive(esidExpTabsEnum.ExpRegis));
    }
  };

  // const handleVehicleRegis = () => {
  //   window.scrollTo(0, 380);
  //   dispatch(setEsidExpTabPaneActive(esidExpTabsEnum.VhcRegis));
  // };

  const handleCancel = () =>{
    if(dgrSelectedValue && dgrSelectedValue?.id !== null){
      const previousDgrItems = dgrSelectedValue.dgrItems.map((items) => ({
        ...items,
        key: items.id
      }));
      dispatch(setDgrGoods(previousDgrItems));
    }else{
      dispatch(setDgrGoods([]));
    }
    dispatch(setDisableEditDrgGood(true));
    dispatch(setIsVisableEditDrgGoodButton(true));
  }

  const handleEditForm = async () => {
    try {
      setLoadingCheckIsEditable(true);
      const checkIsEditable = await CheckIfDrgIsAllowEditing(dgrSelectedValue.id);
      if (checkIsEditable.data.allowEdit) {
        dispatch(setDisableEditDrgGood(false))
        dispatch(setIsVisableEditDrgGoodButton(false))
      }else{
        unEditableNoti();
      }
      setLoadingCheckIsEditable(false);
    } catch (error) {
      setLoadingCheckIsEditable(false);
      errorHandling(error, history, location.pathname);
    }
  };

  const componentRef = useRef();
  // eslint-disable-next-line
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    copyStyles: true,
    pageStyle: `
      @media all {
        .page-break {
          display: none;
        }
      }
      @media print {
        html, body {
          padding: 0.7rem 0.7rem 0.7rem 0.7rem;
          height: initial !important;
          overflow: initial !important;
          -webkit-print-color-adjust: exact;
        }
      }
      @media print {
        .page-break {
          margin-top: 0.7rem;
          display: block;
          page-break-before: auto;
        }
      }
      @media print {
        @page { 
          size: A4 portrait;
          margin: 0;
        }
      }
    `
  });

  const fetchDgrTypeOption = async () => {
    try {
        if (!dgrTypeStatusList || dgrTypeStatusList?.length === 0) {
          const { data } = await GetDgrTypeStatusOK();
          dispatch(setDgrTypeStatusOK(data))
        }
    } catch (error) {
        errorHandling(error, history, location.pathname);
    }
  }

  useEffect(() => {
    dispatch(setIsVisableEditDrgGoodButton(true))
    fetchDgrTypeOption();
    // eslint-disable-next-line
  },[]);

  useEffect(() => {
    dispatch(setDisableEditDrgGood(!!dgrSelectedValue));
    // eslint-disable-next-line
  }, [dgrSelectedValue]);

  const [dgrTypes, setDgrTypes] = useState(['DGR']);
  const onDgrTypeChange = (checkedValues) => {
    const dgTypes = [];
    if (dataSource) {
      dataSource.filter(x => x.codSts !== 'XX').forEach((x) => {
        if (x.codTyp === 'DGR' && !dgTypes.includes('DGR')) {
          dgTypes.push('DGR');
        }
        if (x.codTyp === 'PIN' && !dgTypes.includes('PIN')) {
          dgTypes.push('PIN');
        }
        if (x.codTyp === 'ICE' && !dgTypes.includes('ICE')) {
          dgTypes.push('ICE');
        }
      });
    }
    checkedValues.forEach(x => {
      if (!dgTypes.includes(x)) {
        dgTypes.push(x);
      }
    });
    if (dgTypes.length > 0) {
      setDgrTypes(dgTypes);
    }
  };

  useEffect(() => {
    if (!dataExport) {
      setDgrTypes(['DGR']);
      return;
    }
    const dgTypes = [];
    if (dataExport.dgrItems) {
      dataExport.dgrItems.forEach((x) => {
        if (x.codTyp === 'DGR' && !dgTypes.includes('DGR')) {
          dgTypes.push('DGR');
        }
        if (x.codTyp === 'PIN' && !dgTypes.includes('PIN')) {
          dgTypes.push('PIN');
        }
        if (x.codTyp === 'ICE' && !dgTypes.includes('ICE')) {
          dgTypes.push('ICE');
        }
      });
    }
    if (dgTypes.length === 0) {
      dgTypes.push('DGR');
    }
    setDgrTypes(dgTypes);
  }, [dataExport]);

  return (
    <>
      {esidExport ? (
        <>
          <p className='exp-sub-title' style={{ marginTop: '10px' }}>{t("formInfo.declDgrLabel")}</p>
          <Row justify="center">
            <Col span={24} md={24} lg={22} xl={22} className="awb-tab-content">
              <p className="registry-notice">{t("header.registryInvNotice")}</p>
            </Col>
          </Row>
          {/* <Collapse
            className="declDgrForm-container"
            activeKey={dgrActiveKey}
            onChange={handleDrgActiveKey}
          >
            <Panel
              header={
                <>
                  <Typography.Text strong>
                    {`${t("formInfo.airWaybillNumber")}: `}
                  </Typography.Text>
                  {`${esidExport.codAwbPfx} - ${esidExport.codAwbNum}`}
                </>
              }
              key="1"
              style={{ marginBottom: 10 }}
            >
              <Row gutter={[10, 40]}>
                <Col lg={14} sm={24} xs={24}>
                  <LeftFormContainer
                    forms={{ shipperForm, consigneeForm, noteForm }}
                  />
                </Col>
                <Col lg={10} sm={24} xs={24}>
                  <RightFormContainer forms={{ airwayBillForm }} />
                </Col>
              </Row>
            </Panel>
          </Collapse> */}
          <div style={{ marginBottom: '15px' }} >
            <Checkbox.Group style={{ width: '100%', fontWeight: 600 }} value={dgrTypes} onChange={onDgrTypeChange}>
              <Checkbox value="DGR" style={{ color: dgrTypes.includes('DGR') ? '#ffa401' : 'black'}}>HÀNG NGUY HIỂM</Checkbox>
              <Checkbox value="ICE" style={{ color: dgrTypes.includes('ICE') ? '#ffa401' : 'black'}}>HÀNG ĐÁ KHÔ</Checkbox>
              <Checkbox value="PIN" style={{ color: dgrTypes.includes('PIN') ? '#ffa401' : 'black'}}>HÀNG PIN</Checkbox>
            </Checkbox.Group>
          </div>
          {dgrTypes.includes('DGR') && (
            <GoodsTable
              dataSource={dataSource}
              setDataSource={(goods) => dispatch(setDgrGoods(goods))}
            />
          )}

          {dgrTypes.includes('ICE') && (<>
            <div style={{marginBottom: '30px'}}/>
            <DryIceTable
              dataSource={dataSource}
              setDataSource={(goods) => dispatch(setDgrGoods(goods))}
            />
          </>)}
          {dgrTypes.includes('PIN') && (<>
            <div style={{marginBottom: '30px'}}/>
            <PinTable
              dataSource={dataSource}
              setDataSource={(goods) => dispatch(setDgrGoods(goods))}
            />
          </>)}
          <Space
            style={{
              marginTop: "20px",
              display: "flex",
              justifyContent: "center",
            }}
            className="awb-tab-content"
          >
            <Button onClick={handleBackToESID} style={{ width: "fit-content" }}>
              {t("formInfo.backToESID")}
            </Button>
            {dgrSelectedValue?.id && isVisableEditDgrGoodsButton ? (
              <Button loading={loadingCheckIsEditable} onClick={handleEditForm} style={{ width: "fit-content" }}>
                {t("formInfo.editForm")}
              </Button>
            ) : (
              <></>
            )}

            {dgrSelectedValue?.id && !isVisableEditDgrGoodsButton ? (
              <Button loading={submitLoading} onClick={handleSubmit} style={{ width: "fit-content" }}>
                 {t("formInfo.saveForm")}
              </Button>
            ) : (
              <></>
            )}
            
            {dgrSelectedValue && dgrSelectedValue !== null ? (
              <Button onClick={handlePrint} style={{ width: "fit-content" }}>
                {t("header.print")} ESID
              </Button>
            ) : (
              <Button
                type="primary"
                onClick={handleSubmit}
                style={{ width: "fit-content" }}
                loading={submitLoading}
              >
                {t("formInfo.submit")}
              </Button>
            )}
            
            {/* <Button
              onClick={handleVehicleRegis}
              style={{ width: "fit-content" }}
            >
              {t("formInfo.registration")}
            </Button> */}
            {dgrSelectedValue?.id && !isVisableEditDgrGoodsButton ? (
              <Button
                onClick={handleCancel}
                style={{ width: "fit-content" }}
              >
                {t('dangerousGood.formInfo.btnCancel')}
              </Button>
            ) : (
              <></>
            )}
            
          </Space>
          {!esidExport && (
            <Space
              style={{
                justifyContent: "center",
                display: "flex",
                position: "fixed",
                inset: 0,
                backgroundColor: "#0000001f",
              }}
            >
              <Spin tip={"Loading"} />
            </Space>
          )}
          {/* {dataExport && (
            <Row>
              <Col span={0}>
                <ExpDeclDgrPrint ref={componentRef} dataExport={dataExport} />
              </Col>
            </Row>
          )} */}
          {esidExport && (
            <Row>
              <Col span={0}>
                <RegistryExportPrint
                  ref={componentRef}
                  esidExport={esidExport}
                  dgrSelected={dataExport}
                  aviSelected={livAnmsSelectedValue}
                  // QR={QRToPrint}
                  // numberQR={numberQR}
                />
              </Col>
            </Row>
          )}
        </>
      ) : (
        <>Dangerous Goods</>
      )}
    </>
  );
};

export default TabExpDeclDgr;
