/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Button, Input, Form, Col, Row, Modal, notification } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useForm } from "antd/lib/form/Form";
import moment from "moment";
import {
  IMP_WEB_CHK_CRE_CHK_OBJ,
  IMP_WEB_CHK_INS,
  IMP_WEB_CHK_UPD,
} from "../../../config/apiConfig";
import { useDispatch, useSelector } from "react-redux";
import ModalUpdateAgent from "../TabReceiverInfo/ModalUpdateAgent";
const { warning, confirm } = Modal;
const HawbTabReceiverInfo = ({
  info,
  isInfoCreated,
  status,
  loadData,
  awbDetail,
  ...props
}) => {
  const [form] = useForm();
  const [formAWB] = useForm();
  const dispatch = useDispatch();
  const { MAWB } = useSelector((state) => state.mawb);
  const { token } = useSelector((state) => state.userAwb.dataLogin);

  const [visible, setVisible] = useState(false);
  const [disable, setDisable] = useState(true);
  const showData = () => {
    if (info !== undefined) {
      const { DLV_ODR_RCV_ID, DLV_ODR_RCV_NAM, EML_RCV_EINV, CUS_RMK } = info;

      form.setFieldsValue({
        receiverId: DLV_ODR_RCV_ID,
        receiverName: DLV_ODR_RCV_NAM,
        email: EML_RCV_EINV,
        phone: CUS_RMK.slice(0, CUS_RMK.indexOf("-")),
        address: CUS_RMK.slice(CUS_RMK.indexOf("-") + 1, CUS_RMK.length),
      });
    }
    if (awbDetail !== undefined) {
      const {
        COD_QUE_NUM_CHK,
        FLG_IMP_EXP,
        COD_APT_BRD,
        COD_APT_OFF,
        TOT_PCS,
        TOT_WGT,
        RE_WGT,
        SHC1,
        SHC2,
        SHC3,
        FLG_HDL,
        NAM_CNE_SHP,
        COD_CNE_SHP,
        COD_AGT,
        NAM_AGT,
        COD_FLT_CAR,
        DAT_FLT,
        COD_FLT_NUM,
        COD_AWB_CHG,
      } = awbDetail;
      formAWB.setFieldsValue({
        queueNo: COD_QUE_NUM_CHK,
        awbFirst: MAWB.first,
        awbLast: MAWB.last,
        hawb: MAWB.hawb,
        impExp: FLG_IMP_EXP,
        org: COD_APT_BRD,
        des: COD_APT_OFF,
        pcs: TOT_PCS,
        weight: TOT_WGT,
        "re-weight": RE_WGT === 0 ? "" : RE_WGT,
        "H/M": FLG_HDL,
        SHC1: SHC1,
        SHC2: SHC2,
        SHC3: SHC3,
        consigneeCode: COD_CNE_SHP,
        consigneeName: NAM_CNE_SHP,
        agentCode: COD_AGT,
        agentName: NAM_AGT,
        flightCarrier: COD_FLT_CAR,
        flightDate: moment(DAT_FLT).format("DD-MMM-YYYY"),
        flightNo: COD_FLT_NUM,
        awbCharge: COD_AWB_CHG,
      });
    }
  };

  const nextTab = () => {
    if (!isInfoCreated)
      warning({
        title: "Thông báo",
        icon: <ExclamationCircleOutlined />,
        content: "Bạn cần phải đăng kí thông tin người nhận để tiếp tục!",
      });
    else props.setActiveKey("awb");
  };

  const createCheckinObj = async (obj, token) => {
    const createCheckinRes = await IMP_WEB_CHK_CRE_CHK_OBJ(obj, token);
    if (createCheckinRes.data === null) {
      notification.error({ message: "Lỗi", style: { marginRight: "-50%" } });
      return false;
    }
    return true;
  };

  const submit = async (values) => {
    confirm({
      title: "Thông báo",
      icon: <ExclamationCircleOutlined />,
      content: "Bạn có đồng ý cập nhật thông tin hay không?",
      async onOk() {
        const { receiverId, receiverName, email, phone, address } = values;
        if (info === undefined) {
          try {
            const chkInsertRes = await IMP_WEB_CHK_INS(
              {
                COD_AWB_PFX: MAWB.first,
                COD_AWB_NUM: MAWB.last,
                COD_AWB_SFX: MAWB.suffix,
                COD_HWB_NUM: MAWB.hawb,
                DAT_DLV: moment().format("LL HH:mm"),
                DLV_ODR_RCV_ID: receiverId.toUpperCase(),
                DLV_ODR_RCV_NAM: receiverName.toUpperCase(),
                EML_RCV_EINV: email.toUpperCase(),
                CUS_RMK: phone.toUpperCase() + "-" + address.toUpperCase(),
              },
              token
            );
            if (chkInsertRes.data.TABLE_ID > 0)
              notification.success({
                message: "Tạo thông tin thành công!",
                style: { marginRight: "-50%" },
              });
            loadData();
          } catch (err) {
            notification.error({
              message: err.response.data.error.slice(
                3,
                err.response.data.error.length
              ),
              style: { marginRight: "-50%" },
            });
          }
        } else {
          try {
            if (info.COD_STS === "NY") {
              form.validateFields([
                "email",
                "receiverId",
                "receiverName",
                "phone",
              ]);
              await IMP_WEB_CHK_UPD(
                {
                  ...info,
                  DLV_ODR_RCV_ID: receiverId.toUpperCase(),
                  DLV_ODR_RCV_NAM: receiverName.toUpperCase(),
                  DAT_DLV: moment().format("LL HH:mm"),
                  EML_RCV_EINV: email.toUpperCase(),
                  CUS_RMK: phone.toUpperCase() + "-" + address.toUpperCase(),
                },
                token
              );
              notification.success({
                message: "Cập nhật thông tin thành công!",
                style: { marginRight: "-50%" },
              });
              loadData();
            }
          } catch (err) {
            notification.error({
              message: err.response.data.err,
              style: { marginRight: "-50%" },
            });
          }
        }
      },
    });
  };

  const handleQueue = () => {
    confirm({
      title: "Thông báo",
      icon: <ExclamationCircleOutlined />,
      content: "Bạn có đồng ý đăng ký thông tin hay không?",
      async onOk() {
        try {
          if (info.COD_STS === "NY") {
            const isCreatedCheckinObj = createCheckinObj(info, token);
            if (isCreatedCheckinObj) {
              notification.success({
                message: "Đăng kí thông tin thành công!",
                style: { marginRight: "-50%" },
              });
              loadData();
            }
          }
          loadData();
        } catch (err) {
          // console.log(err);
          if (err.response.status === 401) {
            notification.error({
              style: { marginRight: "-50%" },
              message: t("home.sessionNoti"),
            });

            dispatch(checkinUser({}));
            dispatch(setPaymentInfo(undefined));
            dispatch(userAwbLogout());
            localStorage.removeItem("userAWB");
            localStorage.removeItem("checkin");
            localStorage.removeItem("pInfo");
            history.push("/");
          } else
            notification.error({
              message: "Đăng kí thông tin thất bại!",
              style: { marginRight: "-50%" },
            });
        } finally {
          setTimeout(() => loadData(), 500);
        }
      },
    });
  };
  useEffect(() => {
    showData();
    if (status === "NY") setDisable(false);
    else setDisable(true);
  }, [info, awbDetail, status]);
  return (
    <>
      <ModalUpdateAgent
        loadData={loadData}
        visible={visible}
        setVisible={setVisible}
        info={info}
      />
      <Row>
        <Col span={1}>
          <h2>AWB</h2>
        </Col>
        <Col style={{ display: "flex", justifyContent: "flex-end" }} span={23}>
          <Form.Item style={{ marginRight: "5px" }}>
            <Button
              className="btn-payment"
              style={{ width: "fit-content" }}
              onClick={() => form.submit()}
              disabled={disable}
            >
              {/* {t("header.submit")} */} Cập nhật
            </Button>
          </Form.Item>
          <Form.Item style={{ marginRight: "5px" }}>
            <Button
              className="btn-payment"
              style={{ width: "fit-content" }}
              onClick={handleQueue}
              disabled={disable}
            >
              {/* {t("header.submit")} */}Đăng ký
            </Button>
          </Form.Item>
          <Form.Item>
            <Button
              className="btn-payment"
              style={{ width: "fit-content" }}
              onClick={nextTab}
            >
              {/* {t("header.submit")} */} Tiếp tục
            </Button>
          </Form.Item>
        </Col>
      </Row>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Form
          className="awb-payment"
          layout="vertical"
          onFinish={submit}
          form={formAWB}
          autoComplete="off"
        >
          <Row gutter={[5, 0]}>
            <Col span={4} md={2}>
              <Form.Item name="awbFirst" label="AWB No">
                <Input readOnly style={{ textTransform: "uppercase" }} />
              </Form.Item>
            </Col>
            <Col span={6} md={3}>
              <Form.Item label=" " name="awbLast">
                <Input readOnly style={{ textTransform: "uppercase" }} />
              </Form.Item>
            </Col>
            <Col span={3} md={2}>
              <Form.Item name="H/M" label="H/M">
                <Input readOnly style={{ textTransform: "uppercase" }} />
              </Form.Item>
            </Col>
            <Col span={6} md={2}>
              <Form.Item name="impExp" label="IMP/EXP">
                <Input readOnly style={{ textTransform: "uppercase" }} />
              </Form.Item>
            </Col>

            <Col span={5} md={3} lg={3} xl={2}>
              <Form.Item name="org" label="Org">
                <Input readOnly style={{ textTransform: "uppercase" }} />
              </Form.Item>
            </Col>

            <Col span={5} md={3} lg={3} xl={2}>
              <Form.Item name="des" label="Des">
                <Input readOnly style={{ textTransform: "uppercase" }} />
              </Form.Item>
            </Col>

            <Col span={6} md={1}>
              <Form.Item name="pcs" label="Pieces">
                <Input readOnly style={{ textTransform: "uppercase" }} />
              </Form.Item>
            </Col>

            <Col span={5} md={2}>
              <Form.Item name="weight" label="Weight">
                <Input readOnly style={{ textTransform: "uppercase" }} />
              </Form.Item>
            </Col>

            <Col span={5} md={2} lg={2}>
              <Form.Item name="flightCarrier" label="Flight No">
                <Input style={{ textTransform: "uppercase" }} readOnly />
              </Form.Item>
            </Col>
            <Col span={7} md={2}>
              <Form.Item label=" " name="flightNo">
                <Input style={{ textTransform: "uppercase" }} readOnly />
              </Form.Item>
            </Col>
            <Col span={6} md={3}>
              <Form.Item label="Flight Date" name="flightDate">
                <Input style={{ textTransform: "uppercase" }} readOnly />
              </Form.Item>
            </Col>
            <Col span={5} md={1}>
              <Form.Item name="awbCharge" label="Charge">
                <Input style={{ textTransform: "uppercase" }} readOnly />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
      <hr />
      <Form form={formAWB} layout="vertical" className="awb-payment">
        <Row>
          <Col span={24} md={12}>
            <h2>Tên đại lý</h2>
            <Row gutter={[5, 0]}>
              <Col span={6} md={4}>
                <Form.Item name="consigneeCode" label="Code">
                  <Input style={{ textTransform: "uppercase" }} readOnly />
                </Form.Item>
              </Col>
              <Col span={18} md={12}>
                <Form.Item name="consigneeName" label="Name">
                  <Input style={{ textTransform: "uppercase" }} readOnly />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col span={24} md={12}>
            <h2>Công ty được uỷ quyền nhận hàng</h2>
            <Row gutter={[5, 0]}>
              <Col span={6} md={4}>
                <Form.Item name="agentCode" label="Code">
                  <Input style={{ textTransform: "uppercase" }} readOnly />
                </Form.Item>
              </Col>
              <Col span={18} md={12}>
                <Form.Item name="agentName" label="Name">
                  <Input style={{ textTransform: "uppercase" }} readOnly />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
      <hr />
      <h2>Thông tin người nhận</h2>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Form
          className="form-receiver"
          requiredMark="optional"
          layout="vertical"
          onFinish={submit}
          form={form}
          autoComplete="off"
        >
          <Row gutter={[5, 0]}>
            <Col span={24} md={3}>
              <Form.Item
                name="receiverId"
                label={/* t("header.newPassConfirm") */ "CCCD/ CMND người nhận"}
                rules={[
                  {
                    required: true,
                    message: /* t("header.msgComfirm") */ "",
                  },
                ]}
              >
                <Input
                  readOnly={status !== "NY"}
                  style={{ textTransform: "uppercase" }}
                />
              </Form.Item>
            </Col>
            <Col span={24} md={3}>
              <Form.Item
                name="receiverName"
                label={/* t("header.username") */ "Họ và tên người nhận"}
                rules={[
                  {
                    required: true,
                    message: /* t("header.msgUsername") */ "",
                  },
                ]}
              >
                <Input
                  readOnly={status !== "NY"}
                  style={{ textTransform: "uppercase" }}
                />
              </Form.Item>
            </Col>
            <Col span={24} md={4}>
              <Form.Item
                name="phone"
                label={/* t("header.oldPass") */ "Số điện thoại người nhận"}
              >
                <Input
                  readOnly={status !== "NY"}
                  style={{ textTransform: "uppercase" }}
                />
              </Form.Item>
            </Col>
            <Col span={24} md={7}>
              <Form.Item name="address" label={"Địa chỉ người nhận"}>
                <Input
                  style={{ textTransform: "uppercase" }}
                  readOnly={status !== "NY"}
                />
              </Form.Item>
            </Col>
            <Col span={24} md={7}>
              <Form.Item
                name="email"
                label={"Email người nhận"}
                rules={[
                  {
                    type: "email",
                    message: "",
                  },
                  {
                    required: true,
                    message: /* t("header.msgNewPass") */ "",
                  },
                ]}
              >
                <Input
                  readOnly={status !== "NY"}
                  style={{ textTransform: "uppercase" }}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
};
export default HawbTabReceiverInfo;
