/* eslint-disable */

import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import * as API from "../config/apiConfig";
import "./component-css/IntroductionContent.css";
import { useDispatch, useSelector } from "react-redux";
import Parser from "html-react-parser";
import { changeLang, menuItemSelected } from "../actions/menu";
import { useLocation } from "react-router";

const IntroductionContent = () => {
  const [intro, setIntroContent] = useState([]);
  const { langId, menuItem } = useSelector((state) => state.menu);
  const dispatch = useDispatch();
  const location = useLocation();

  const setMenu = async (menuId, langId) => {
    const menuRes = await API.getMenuById(menuId);
    const menuParentRes = await API.getMenuById(menuRes.data.parent);
    const menuReference = {
      key: menuRes.data.alias,
      title: menuRes.data.name,
      menuParentTitle: menuParentRes.data.name,
      banner: menuParentRes.data.params,
      menuId: menuRes.data.id,
      menuParentId: menuRes.data.parent,
      images: menuRes.data.images,
      referenceId: menuRes.data.reference_id,
    };
    dispatch(changeLang(langId));
    localStorage.setItem("langId", langId || 1);
    localStorage.setItem("LANGUAGE", langId === "2" ? "vi" : "en");
    dispatch(menuItemSelected(menuReference));
    localStorage.setItem("menuItem", JSON.stringify(menuReference));
  };
  useEffect(async () => {
    const slug = location.pathname.slice(
      location.pathname.lastIndexOf("/") + 1
    );
    const menuIdRes = await API.getMenuBySlug(slug);
    const { id, lang_id } = menuIdRes.data;
    API.getListMenuDetail(langId, id).then((res) => {
      setIntroContent(res.data.data);
    });
    setMenu(id, lang_id + "");
  }, [location]);
  useEffect(() => {
    API.getListMenuDetail(langId, menuItem.referenceId).then((res) => {
      setIntroContent(res.data.data);
    });
  }, [langId]);

  return (
    <Row className="introduction-content">
      <Row className="introduction-title-wrapper">
        <Col className="introduction-title" offset={3} span={20}>
          <h2 style={{ textAlign: "left" }}>{menuItem.title}</h2>
        </Col>
      </Row>
      <Row className="introduction-body-wrapper">
        <Col
          className={
            location.pathname.slice(location.pathname.lastIndexOf("/") + 1) ===
            "cac-co-dong"
              ? "introduction-body shareholder-content"
              : "introduction-body"
          }
          offset={3}
          span={18}
        >
          {intro.length > 0 && intro[0].fulltext && Parser(intro[0].fulltext)}
        </Col>
      </Row>
    </Row>
  );
};
export default IntroductionContent;
