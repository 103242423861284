const initialState = {
  MAWB: { first: "", last: "", suffix: "", isSubmitForm: true },
  checkinHAWB: {},
};

export const MAWBReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_MAWB":
      return (state = { ...state, MAWB: action.payload });
    case "SET_HAWB":
      return (state = { ...state, checkinHAWB: action.payload });
    default:
      return state;
  }
};
