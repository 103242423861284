/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Button, Input, notification, Table } from "antd";
import Modal from "antd/lib/modal/Modal";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { setHAWB, setMAWB } from "../../../actions/MAWB";
import { setPaymentInfo } from "../../../actions/payment";
import { checkinUser, userAwbLogout } from "../../../actions/userAWB";
import {
  IMP_WEB_CHK_INS,
  IMP_WEB_CHK_LOAD_LST,
} from "../../../config/apiConfig";
const ModalAddNew = ({ visible, setVisible }) => {
  const [visibleConfirm, setVisibleConfirm] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const { MAWB } = useSelector((state) => state.mawb);
  const { token } = useSelector((state) => state.userAwb.dataLogin);
  const [loading, setLoading] = useState(false);
  const [disable, setDisable] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  const handleOk = async () => {
    try {
      if (selectedRows.length > 0) {
        selectedRows.map((record) => {
          if (record.COD_STS !== "AP" && record.COD_STS !== "FN") {
            return;
          }
        });

        try {
          setLoading(true);
          const insertRes = await IMP_WEB_CHK_INS(
            {
              COD_AWB_NUM: MAWB.last,
              COD_AWB_PFX: MAWB.first,
              COD_AWB_SFX: "P",
              COD_HWB_NUM: "",
              COD_CHK_CDO: "HWB",
            },
            token
          );
          if (insertRes.data.TABLE_ID > 0)
            notification.success({
              message: "Tạo thông tin thành công!",
              style: { marginRight: "-50%" },
            });
          setLoading(false);
        } catch (error) {
          notification.error({
            message: error.response.data.error,
            style: { marginRight: "-50%" },
          });
        }
      } else {
        try {
          setLoading(true);
          const insertRes = await IMP_WEB_CHK_INS(
            {
              COD_AWB_NUM: MAWB.last,
              COD_AWB_PFX: MAWB.first,
              COD_AWB_SFX: "P",
              COD_HWB_NUM: "",
              COD_CHK_CDO: "HWB",
            },
            token
          );
          if (insertRes.data.TABLE_ID > 0)
            notification.success({
              message: "Tạo thông tin thành công!",
              style: { marginRight: "-50%" },
            });
          setLoading(true);
        } catch (error) {
          if (error.response.status === 401) {
            notification.error({
              style: { marginRight: "-50%" },
              message: t("home.sessionNoti"),
            });

            dispatch(checkinUser({}));
            dispatch(setPaymentInfo(undefined));
            dispatch(userAwbLogout());
            localStorage.removeItem("userAWB");
            localStorage.removeItem("checkin");
            localStorage.removeItem("pInfo");
            history.push("/");
          } else
            notification.error({
              message: error.response.data.error,
              style: { marginRight: "-50%" },
            });
        }
      }
      fetchList();
    } catch (error) {
      if (error.response.status === 401) {
        notification.error({
          style: { marginRight: "-50%" },
          message: t("home.sessionNoti"),
        });

        dispatch(checkinUser({}));
        dispatch(setPaymentInfo(undefined));
        dispatch(userAwbLogout());
        localStorage.removeItem("userAWB");
        localStorage.removeItem("checkin");
        localStorage.removeItem("pInfo");
        history.push("/");
      } else
        notification.error({
          message: error.response.data.error,
          style: { marginRight: "-50%" },
        });
      console.log(error);
    }
    setVisibleConfirm(false);
    setVisible(true);
  };

  const columns = [
    {
      title: "",
      dataIndex: "key",
      key: "key",
    },
    {
      title: "Status",
      dataIndex: "COD_STS",
      key: "COD_STS",
    },
    {
      title: "Queue No",
      dataIndex: "COD_QUE_NUM_CHK",
      key: "COD_QUE_NUM_CHK",
    },
    {
      title: "Ref Num",
      dataIndex: "COD_REF_NUM",
      key: "COD_REF_NUM",
    },
    {
      title: "AWB",
      dataIndex: "COD_AWB_PFX",
      key: "COD_AWB_PFX",
      colSpan: 2,
    },
    {
      title: "",
      dataIndex: "COD_AWB_NUM",
      key: "COD_AWB_NUM",
      colSpan: 0,
    },
    {
      title: "HAWB",
      dataIndex: "COD_HWB_NUM",
      key: "COD_HWB_NUM",
    },
    {
      title: "Created Date",
      dataIndex: "DAT_CRE",
      key: "DAT_CRE",
    },
    {
      title: "Email",
      dataIndex: "EML_RCV_EINV",
      key: "EML_RCV_EINV",
    },
    {
      title: "Receiver ID",
      dataIndex: "DLV_ODR_RCV_ID",
      key: "DLV_ODR_RCV_ID",
    },
    {
      title: "Receiver name",
      dataIndex: "DLV_ODR_RCV_NAM",
      key: "DLV_ODR_RCV_NAM",
    },
  ];

  const fetchList = async () => {
    try {
      setLoading(true);
      const loadRes = await IMP_WEB_CHK_LOAD_LST(
        MAWB.first,
        MAWB.last,
        "P",
        "",
        token
      );
      let flag = true;

      let data = [];
      loadRes.data.mCol.map((dt, index) => {
        data.push({
          key: ++index,
          COD_STS: dt.COD_STS,
          COD_QUE_NUM_CHK: dt.COD_QUE_NUM_CHK,
          COD_REF_NUM: dt.COD_REF_NUM,
          COD_AWB_PFX: dt.COD_AWB_PFX,
          COD_AWB_NUM: dt.COD_AWB_NUM,
          COD_HWB_NUM: dt.COD_HWB_NUM,
          COD_AWB_SFX: dt.COD_AWB_SFX,
          DAT_CRE: moment(dt.DAT_CRE).format("LL HH:mm"),
          EML_RCV_EINV: dt.EML_RCV_EINV,
          DLV_ODR_RCV_ID: dt.DLV_ODR_RCV_ID,
          DLV_ODR_RCV_NAM: dt.DLV_ODR_RCV_NAM,
          TABLE_ID: dt.TABLE_ID,
        });
        if (
          dt.COD_STS !== "AP" &&
          dt.COD_STS !== "FN" &&
          dt.COD_STS !== "XX" &&
          dt.COD_STS !== "OK"
        ) {
          flag = false;
        }
      });
      if (!flag) {
        setDisable(true);
      }
      if (flag) {
        setDisable(false);
      }
      setDataSource(data);

      setLoading(false);
    } catch (error) {
      if (err.response.status === 401) {
        notification.error({
          style: { marginRight: "-50%" },
          message: t("home.sessionNoti"),
        });

        dispatch(checkinUser({}));
        dispatch(setPaymentInfo(undefined));
        dispatch(userAwbLogout());
        localStorage.removeItem("userAWB");
        localStorage.removeItem("checkin");
        localStorage.removeItem("pInfo");
        history.push("/");
      }
    }
  };
  useEffect(() => {
    if (visible === true) fetchList();
  }, [visible]);
  const onSelectChange = (selectedRowKeys, selectedRows) => {
    setSelectedRowKeys(selectedRowKeys);
    setSelectedRows(selectedRows);
    dispatch(setHAWB(selectedRows[0]));
    localStorage.setItem("HAWB", JSON.stringify(selectedRows[0]));
  };
  const handleDirect = () => {
    if (selectedRows.length === 0) {
      notification.warning({
        message: "Vui lòng chọn mã HAWB để thanh toán!",
        style: { marginRight: "-50%" },
      });
      return;
    }
    history.push("/Awb/checkoutHawb");
  };
  /*   useEffect(() => {
    return () => {
      dispatch(setHAWB({}));
      localStorage.removeItem("HAWB");
    };
  }, []); */
  return (
    <Modal
      width={"100%"}
      title="Add"
      visible={visible}
      onCancel={() => {
        setSelectedRowKeys([]);
        setSelectedRows([]);
        dispatch(setMAWB({}));
        setVisible(false);
      }}
      footer={[
        <Button
          className="btn-payment"
          onClick={() => setVisibleConfirm(true)}
          style={{ width: "fit-content" }}
          disabled={disable}
          loading={loading}
        >
          Add new
        </Button>,
        <Button
          className="btn-payment"
          onClick={handleDirect}
          style={{ width: "fit-content", marginLeft: "5px" }}
          loading={loading}
        >
          Tiếp tục
        </Button>,
      ]}
    >
      <Table
        bordered
        loading={loading}
        className="table-payment"
        columns={columns}
        pagination={false}
        rowSelection={{
          selectedRowKeys,
          type: "radio",
          fixed: true,
          hideSelectAll: true,
          onChange: onSelectChange,
        }}
        dataSource={dataSource}
      />
      <Modal
        centered
        width={"620px"}
        title={"Thông báo"}
        visible={visibleConfirm}
        onCancel={() => setVisibleConfirm(false)}
        footer={[
          <Button onClick={handleOk} type="primary">
            Đồng ý
          </Button>,
          <Button onClick={() => setVisibleConfirm(false)}>Không</Button>,
        ]}
      >
        Bạn có đồng ý thêm đăng ký đóng tiền HAWB của lô hàng này?
      </Modal>
    </Modal>
  );
};
export default ModalAddNew;
