import { Form, Input, Row, Typography, Col } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'

export const BillingAddrForm = ({ form }) => {
    const { t } = useTranslation("common");
  return (
    <Form form={form} labelCol={{ span: 24}}>
        <Typography.Title level={3}>{t("esidCheckout.invoice.billingAddr")}</Typography.Title>
        <Row gutter={[10, 5]}>
            <Col xs={24} sm={12} md={8}>
                <Form.Item label={t("esidCheckout.invoice.name")} name={'NAM_VTN'}>
                    <Input readOnly/>
                </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={16}>
                <Form.Item label={t("esidCheckout.invoice.addr")} name={'DES_ADD_FULL'}>
                    <Input readOnly/>
                </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={5}>
                <Form.Item label={t("esidCheckout.invoice.place")} name={'DES_ADD_PLC'}>
                    <Input readOnly/>
                </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={5}>
                <Form.Item label={t("esidCheckout.invoice.city")} name={'NAM_CTY'}>
                    <Input readOnly/>
                </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={5}>
                <Form.Item label={t("esidCheckout.invoice.country")} name={'NAM_COU'}>
                    <Input readOnly/>
                </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={5}>
                <Form.Item label={t("esidCheckout.invoice.postal")} name={'DES_ADD_POS'}>
                    <Input readOnly/>
                </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={4}>
                <Form.Item label={t("esidCheckout.invoice.tax")} name={'COD_TAX_CMP'}>
                    <Input readOnly/>
                </Form.Item>
            </Col>
        </Row>
    </Form>
  )
}
