import { Col, Row } from "antd";
import React from "react";
import "./component-css/Banner.css";

const Banner = (props) => {
  return (
    <Row style={{ width: "100%" }}>
      <Col
        className={props.className}
        span={24}
        style={{
          background: `url(${props.cover})`,
          backgroundSize: "100% 380px",
        }}
      ></Col>
      <Col className="banner-title-wrapper" offset={3}>
        {props.title}
      </Col>
    </Row>
  );
};

export default Banner;
