import React from "react";
import { Row, Col } from "antd";
import "./page-css/News.css";
import Banner from "../components/Banner";
import ChangePasswordTab from "../components/ChangePasswordTab";

export default function ChangePassword() {
  return (
    <Row className="news-wrapper">
      <Banner className="banner-wrapper" cover={"../../img/004.jpg"} />
      <Row style={{ marginTop: "15px" }} className="news-title-wrapper">
        <Col className="news-title" span={24}>
          <ChangePasswordTab />
        </Col>
      </Row>
    </Row>
  );
}
