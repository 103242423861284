import React  from 'react'
import { Form, Checkbox } from 'antd'
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { objectToAntFormData } from '../TabExpCertLivAnm';
const IATAForm = ({name, children, form}) => {
  const { t } = useTranslation("common");
  const isDisableEditLivAnmFormDecl = useSelector((state) => state.livAnms.isDisableEditLivAnmFormDecl);
  const livAnmSelectedValue = useSelector((state) => state.livAnms.livAnmsSelectedValue);

  const options = [
    { label: t("livAnm.iataForm.iata1"), value: 'iatA001' },
    { label: t("livAnm.iataForm.iata2"), value: 'iatA002' },
    { label: t("livAnm.iataForm.iata3"), value: 'iatA003' },
    { label: t("livAnm.iataForm.iata4"), value: 'iatA004' },
    { label: t("livAnm.iataForm.iata5"), value: 'iatA005' },
  ];
  
  useEffect(()=>{
    if ( livAnmSelectedValue ) {
      let result = [];
      options.forEach((option) => {
        if(livAnmSelectedValue[option.value] === 'Y') {
          result.push(option.value);
        }
      })
      // console.log(result);
      form.setFieldsValue(objectToAntFormData({iatA: result}))
    }
    // eslint-disable-next-line
  },[livAnmSelectedValue, options])

  return (
    <Form
        name={name}
        labelAlign='left'
        labelCol={{span: 24}}
        autoComplete="off"
        form={form}
        className="iata-form"
    >
        <Form.Item
            label={t("livAnm.iataForm.label")}
            name="iatA"
            initialValue={[]}
        >
            <Checkbox.Group
                disabled={livAnmSelectedValue && isDisableEditLivAnmFormDecl}
                options={options}
            />
        </Form.Item>
        { 
            children && children
        }
    </Form>
  )
}

export default IATAForm