import { Col, Row, Button, notification, Spin } from 'antd'
import React, { useState } from 'react'
import { ChosePayment } from './ChosePayment';
import { useTranslation } from 'react-i18next';
import { BillInformation } from './BillInformation';
import { errorHandling } from '../../../config/AuthIntercept';
import { EsidUpdOrderId, EsidUpdPayMode } from '../../../config/esidExportApi';
import { payWithoutOTP } from '../../../config/checkinApi';
import { useDispatch, useSelector } from 'react-redux';
import { setPaymentInfo } from '../../../actions/payment';
import { useHistory, useLocation } from 'react-router-dom';
import { EsidStatusEnum } from '../../../constant/enumerations';
import { ReceiptDetail } from './ReceiptDetail';

export const EsidPaymentInfo = ({ handlePrevTab, invoicesDetail, chkLoadData, isFetchData, fetchData }) => {
  const { t } = useTranslation("common");
  const token = useSelector((state) => state.userAwb.token) || '';
  const selectEsidExport = useSelector(state => state.esidExport.esidExpSelected);
  const [checkedOption, setCheckedOption] = useState('WEB');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const issueEInvoice = async () => {
    window.open('http://e-invoice.tcs.com.vn/', '_blank').focus();
    // try {
    //   await EsidIssueEInvoice(chkLoadData);
    //   await fetchData();
    //   if (chkLoadData.COD_STS === "AP") {
    //     notification.error({
    //       message: "Vui lòng thử lại sau!",
    //     });
    //   }
    //   else if (chkLoadData.COD_STS === 'FN' || chkLoadData.COD_STS === 'OK') {
    //     notification.info({
    //       message: "Hóa đơn điện tử đã xuất"
    //     })
    //   }
    //   else {
    //     notification.success({
    //       message: "Đã xuất hóa đơn thành công!",
    //     });
    //   }
    // } catch (error) {
    //   if (error.response.status === 401) {
    //     notification.error({
    //       message: t("home.sessionNoti"),
    //     });
    //     dispatch(userAwbLogout());
    //     dispatch(setMAWB({}));
    //     localStorage.removeItem("userAWB");
    //     localStorage.removeItem("MAWB");
    //     history.push("/");
    //   } else
    //     notification.error({
    //       message: "Lỗi",
    //     });
    // }
  };
  const isEqualSts = (codSts, acceptedStsArr) => {
    if ( acceptedStsArr.includes(codSts) ) {
      return true;
    }
    return false;
  }
  const handleCheckout = async () => {
    const transferSyntax = getTransferSyntax(checkedOption)
    try {
      setIsSubmitting(true);
      await EsidUpdPayMode({
        ...chkLoadData,
        WEB_PMT_MOD: checkedOption,
        SND_PAY_STR: transferSyntax,
        COD_STS: chkLoadData?.COD_STS === 'AM' ? 'AC' : chkLoadData?.COD_STS // Thanh toán lại
      })

      if ( checkedOption === 'WEB' ) {
        const payRes = await payWithoutOTP(
          chkLoadData.AMT_TOT,
          "VND",
          `TCSEXP_${chkLoadData.TABLE_ID}`,
          transferSyntax,
          token 
        );
        if (payRes.status === 200 && payRes.data) {
          dispatch(
            setPaymentInfo({
              ...payRes.data,
              FLG_IMP_EXP: chkLoadData.FLG_IMP_EXP,
              orderRef: transferSyntax,
              isHawb: false,
            })
          );
          localStorage.setItem(
            "pInfo",
            JSON.stringify({
              ...payRes.data,
              FLG_IMP_EXP: chkLoadData.FLG_IMP_EXP,
              orderRef: transferSyntax,
              isHawb: false,
            })
          );
          localStorage.setItem(
            "esidExp",
            JSON.stringify({
              ...selectEsidExport
            })
          );
          if (chkLoadData.TABLE_ID) {
            localStorage.setItem('chk_id', chkLoadData.TABLE_ID);
          }
          const updateOrderIdRes = await EsidUpdOrderId(
            {
              ...chkLoadData,
              RCV_BNK_TRS_CD1: payRes.data.order.id,
            },
          );
          if (updateOrderIdRes.data) {
            notification.success({
              message: "Đã cập nhật phương thức thanh toán!",
            });
            history.push(`/napas/checkout`);
            fetchData();
          } else {
            notification.error({
              message: "Lỗi! Vui lòng thử lại!",
            });
          }
        }
      }
      else {
        notification.info({
          message: "Payment not supported yet",
        });
      }
    } catch (error) {
      errorHandling(error, history, location.pathname);
    } finally {
      setIsSubmitting(false);
    }
  }
  const getTransferSyntax = (paymentOpt) => {
    switch (paymentOpt) {
      case "CSH":
        return `EXP ${chkLoadData.TABLE_ID.toString().padStart(8, 0)} KHACH HANG ${chkLoadData.CNE_CODE} THANH TOAN ${chkLoadData.AMT_TOT} VND TIEN DICH VU AWB ${chkLoadData.COD_AWB_PFX}${chkLoadData.COD_AWB_NUM}`
      case "WQR":
        return `EXP ${chkLoadData.TABLE_ID.toString().padStart(8, 0)} KHACH HANG ${chkLoadData.CNE_CODE} THANH TOAN ${chkLoadData.AMT_TOT} VND TIEN DICH VU AWB ${chkLoadData.COD_AWB_PFX}${chkLoadData.COD_AWB_NUM}`
      case "WEB":
        return `EXP ${chkLoadData.TABLE_ID.toString().padStart(8, 0)} KHACH HANG ${chkLoadData.CNE_CODE} THANH TOAN ${chkLoadData.AMT_TOT} VND TIEN DICH VU AWB ${chkLoadData.COD_AWB_PFX}${chkLoadData.COD_AWB_NUM}`
      default:
        return ""
    }
  }
  return (
    <Spin spinning={isFetchData} tip={t("esidCheckout.paymentTab.chosePaymentSubTitle")}>
      <div style={{ maxWidth: 1300, marginInline: 'auto'} }>
        <Row gutter={[30,10]} style={{marginBlock: 40, width: '100%' }}>
            {
              chkLoadData && 
                isEqualSts(
                  chkLoadData.COD_STS, 
                  [EsidStatusEnum.AP, EsidStatusEnum.QR, EsidStatusEnum.FN, EsidStatusEnum.OK]
                ) ? (
                  <>
                    <Col span={24}>
                      <div style={{ width: '100%', gap: 5, display: 'flex', justifyContent: 'flex-end'}}>
                        <Button onClick={issueEInvoice} style={{ width: 'fit-content'}}>{t("esidCheckout.exportEInvBtn")}</Button>
                      </div>
                    </Col>
                    <Col xs={24} sm={24} md={14}>
                      <ReceiptDetail chkLoadData={chkLoadData}/>
                    </Col>
                  </>
                ) : (
                <Col xs={24} sm={24} md={14}>
                  <ChosePayment checkedOption={checkedOption} setCheckedOption={setCheckedOption} />
                  <Row style={{ margin: 20 }}>
                    {
                      chkLoadData && 
                        !isEqualSts(
                          chkLoadData.COD_STS, 
                          [EsidStatusEnum.AP, EsidStatusEnum.QR, EsidStatusEnum.FN, EsidStatusEnum.OK]
                        ) && (
                          <Col span={24}>
                            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginBlock: 10}}>
                              <Button 
                                loading={isSubmitting} 
                                style={{ width: 'fit-content'}}
                                onClick={handleCheckout}>
                                {t("esidCheckout.nextBtn")}
                              </Button>
                            </div>
                          </Col>
                        )
                    }
                  </Row>
                </Col>
                )
            }
            <Col xs={24} sm={24} md={10}>
              <BillInformation handlePrevTab={handlePrevTab} invoicesDetail={invoicesDetail}/>
            </Col>
            {/* <Col xs={24} sm={24} md={12}>
              <PaymentDetail invoicesDetail={invoicesDetail}/>
            </Col> */}
            {/* {
              chkLoadData && 
                !isEqualSts(
                  chkLoadData.COD_STS, 
                  [EsidStatusEnum.AP, EsidStatusEnum.QR, EsidStatusEnum.FN, EsidStatusEnum.OK]
                ) && (
                  <Col span={24}>
                    <div style={{ width: '50%', display: 'flex', justifyContent: 'center', marginBlock: 10}}>
                      <Button 
                        loading={isSubmitting} 
                        style={{ width: 'fit-content'}}
                        onClick={handleCheckout}>
                        {t("esidCheckout.nextBtn_TT")}
                      </Button>
                    </div>
                  </Col>
                )
            } */}
        </Row>
      {/* <ModalSelectMethod visible={true} /> */}
      </div>
    </Spin>
  )
}
