import i18n from "i18next";
import common_en from "../translations/en.json";
import common_vi from "../translations/vi.json";

export var currentLanguage = () => {
  const language = localStorage.getItem("LANGUAGE");
  if (language) {
    return language;
  } else {
    localStorage.setItem("LANGUAGE", "vi");
    return "vi";
  }
};

export const initLanguage = () => {
  let nameLanguage = currentLanguage();
  i18n.init({
    interpolation: { escapeValue: false },
    lng: nameLanguage,
    resources: {
      en: {
        common: common_en,
      },
      vi: {
        common: common_vi,
      },
    },
  });
};

export const setLanguage = (lg) => {
  i18n.changeLanguage(lg);
  localStorage.LANGUAGE = lg;
  return lg;
};
