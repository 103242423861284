import React, { useState } from 'react'
import { Row, Col } from 'antd'
import SearchForm from './components/SearchForm';
import { useForm } from 'antd/es/form/Form';
import './style.css'
import VehicleRegistrationTable from './components/VehicleRegistrationTable';

const TabVhcRegisList = () => {
  const [searchForm] = useForm();
  const [dataSource, setDataSource] = useState([])
  return (
    <div className='esid-list_container'>
      <Row gutter={[10, 40]}>
        <Col span={24}>
          <SearchForm setDataSource={setDataSource} name="search-form" form={searchForm} />
        </Col>
      </Row>
      
      <VehicleRegistrationTable dataSource={dataSource} />
    </div>
  )
}

export default TabVhcRegisList