import React from 'react'
import { DmsSizeContainer } from './DimensionSize/DmsSizeContainer';
import { WeightContainer } from './Weight/WeightContainer';
import { Divider } from 'antd';
import { StaffForm } from './StaffForm';
import { DgrReceivedForm } from './DgrReceivedForm';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

export const handleGetNextKey = (array, keyParam = 'key') => {
    if(array && array.length > 0) {
        return parseInt(array[array.length - 1]?.[keyParam]) + 1;
    } 
    else {
        return 1;
    }
}
export const AcceptanceForm = ({ forms, isEsidExpFinalized }) => {
    const { t } = useTranslation("common");
    const selectEsidExport = useSelector(state => state.esidExport.esidExpSelected)
    return (
        <>
            <DmsSizeContainer isEsidExpFinalized={isEsidExpFinalized}/>
            <Divider style={{ margin: '25px auto 15px', borderTop: '2px solid rgb(0 0 0 / 11%)', width: 'calc(100% - 10px)', minWidth: 'calc(100% - 10px)' }}/>
            
            <WeightContainer form={forms.weightForm} isEsidExpFinalized={isEsidExpFinalized}/>
            <Divider style={{ margin: '25px auto 15px', borderTop: '2px solid rgb(0 0 0 / 11%)', width: 'calc(100% - 10px)', minWidth: 'calc(100% - 10px)' }}/>
            
            <StaffForm form={forms.acptStfForm} readOnly={isEsidExpFinalized}/>
            
            {
                selectEsidExport && selectEsidExport.shcDgr === 'Y' && selectEsidExport.dgrAcpStfNam && (
                    <>
                        <Divider style={{ margin: '15px auto 5px', borderTop: '2px solid rgb(0 0 0 / 11%)', width: 'calc(100% - 10px)', minWidth: 'calc(100% - 10px)' }}/>
                        <DgrReceivedForm form={forms.dgrAcptStfForm} readOnly={isEsidExpFinalized}/>
                        {/* <Divider style={{ margin: '15px auto 5px', borderTop: '2px solid rgb(0 0 0 / 11%)', width: 'calc(100% - 10px)', minWidth: 'calc(100% - 10px)' }}/> */}
                    </>        
                )
            }
            {selectEsidExport?.shcDgr === 'Y' && !selectEsidExport?.dgrAcpStfNam && (
                <>
                    <Divider style={{ margin: '15px auto 5px', borderTop: '2px solid rgb(0 0 0 / 11%)', width: 'calc(100% - 10px)', minWidth: 'calc(100% - 10px)' }}/>
                    <div style={{ padding: '2px 5px', fontWeight: 'bold', color: 'red', marginTop: '20px' }}>{t('admin.acceptance.dgrWarning')}</div>
                </>
            )}
            {/* <Row style={{ padding: '0px 5px', marginBlock: 10, maxWidth: 1600}} gutter={[5,5]}>
                <Col span={24}>
                    <Typography.Text strong>{t('admin.acceptance.distribution.title')}</Typography.Text>
                </Col>
                <Col xs={24} sm={12} order={size === 'xs' ? 1 : 1}>
                    {`${t('admin.acceptance.distribution.copy1')}.`}
                </Col>
                <Col xs={24} sm={12} order={size === 'xs' ? 2 : 3}>
                    {`${t('admin.acceptance.distribution.copy2')}.`}
                </Col>
                <Col xs={24} sm={12} order={size === 'xs' ? 3 : 2}>
                    {`${t('admin.acceptance.distribution.copy3')}.`}
                </Col>
                <Col xs={24} sm={12} order={size === 'xs' ? 4 : 4}>
                    {`${t('admin.acceptance.distribution.copy4')}.`}
                </Col>
            </Row> */}
        </>                        
    )
}
