export const CodCtcTypEnum = {
  Shipper: 'SHP',
  Consignee: 'CNE',
  Agent: 'AGT',
  Notify: "NTF"
}

export const AptTypEnum = {
  Origin: 'origin',
  Destination: 'destination'
}

export const codPayModEnum  = {
  Cash :"CSH",
  Transfer: "CDT"
}

export const esidExpTabsEnum = {
  Home: "esid-export-home",
  List: "esid-export-list",
  ExpRegis: "esid-export-registration",
  Dgr: "esid-exp-decl-dgr",
  LivAnm: "esid-ex-cert-liv-anm",
  Consol: "esid-exp-consol",
  CarDim: "esid-exp-car-dim",
  VhcRegis: "esid-exp-vhc-regis",
  VhcRegisList: "esid-exp-vhc-regis-list"
}
export const esidExpAdminTabsEnum = {
  ExpInfo: "export-information",
  ExpDgr: "export-dgr",
  ExpDgCheck: "export-dg-chk",
  ExpLivAnm: "export-liv-anm",
  ExpConsol: "export-consol",
  Acceptance: "export-acceptance",
  Payment: "export-payment"
}

export const EsidTypeEnum = {
  EsidExp: "EsidExp",
  EsidVhc: "EsidVhc"
}

export const EsidStatusEnum = {
  NY: "NY", // Đã đăng ký ESID
  VR: "VR", // Đã đăg ký xe
  DR: "DR", // DG đã tiếp nhận
  FR: "FR", // Hoàn thành tiếp nhận
  AQ: "AQ", // Đã có số thứ tự
  AC: "AC", // Đã tính tiền
  AS: "AS", // Kiểm tra xong, được phép thanh toán
  AM: "AM", // Đã chọn HTTT
  AP: "AP", // Đã thanh toán qua Thẻ
  QR: "QR", // Đã thanh toán qua QR Code
  FN: "FN", // Đã xuất HDDT
  OK: "OK", // Hoàn tất
  XX: "XX", // Đã xóa
} 
export const DgrStatusEnum = {
  NY: 'NY',
  XX: 'XX',
  OK: 'OK',
} 
export const LivAnmStatusEnum = {
  NY: 'NY',
  XX: 'XX',
  OK: 'OK',
} 
export const DimensionSizeEnum = {
  NY: 'NY',
  XX: 'XX',
  OK: 'OK',
} 
export const WeightsEnum = {
  NY: 'NY',
  XX: 'XX',
  OK: 'OK',
} 

export const tempEnum = {
  FRO: 'FRO',
  COL: 'COL',
  CRT: 'CRT',
  ERT: 'ERT',
  ACT: 'ACT',
}

export const ModalName = {
  ExpBillCharge: 'expBillCharge',
  ConsolModal: 'consolModal',
  InviteUserModal: 'inviteUserModal'
}

export const addDgrType = {
  DGR: 'DGR',
  ICE: 'ICE',
  PIN: 'PIN',
}

export const consolServices = {
  PrintQRCode: 'PQR',
  FWB: 'FWB',
  FHL: 'FHL'
}

export const UserInviteStatus  = {
  Grant: 'Grant',
  Pending: 'Pending',
  Declined: 'Declined'
}