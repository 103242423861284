/* eslint-disable */
import React from 'react';
import { Button, Col, Row } from "antd";
import { useTranslation } from "react-i18next";

const Controls = ({ setVisible, setIsGetRelease }) => {
  const { t } = useTranslation("common");

  return (
    <Row
      style={{ marginTop: 20 }}
      className="result-content-wrapper control-footer"
    >
      <Col className="awb-result-content" span={"auto"}>
        <Button
          onClick={() => {
            setVisible(true);
            setIsGetRelease(true);
          }}
          style={{ width: "fit-content" }}
        >
          {t("agentReport.DLVRelease")}
        </Button>
      </Col>
      <Col className="awb-result-content" span={"auto"}>
        <Button
          onClick={() => {
            setVisible(true);
            setIsGetRelease(false);
          }}
          style={{ width: "fit-content" }}
        >
          {t("agentReport.UndoDLVRelease")}
        </Button>
      </Col>
    </Row>
  );
};
export default Controls;
