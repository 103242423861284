/* eslint-disable */
import React, { useRef, useState } from "react";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  notification,
  Row,
  Skeleton,
  Table,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { useReactToPrint } from "react-to-print";
import { setPaymentInfo } from "../../actions/payment";
import { checkinUser, userAwbLogout } from "../../actions/userAWB";
import { GET_SS_EXP_STATUS_SHP } from "../../config/webTrackApi";
const { RangePicker } = DatePicker;

const TabReportForOutboundShipment = ({ setActiveKey }) => {
  const [tableDataSHP, setTableDataSHP] = useState();
  const [loading, setLoading] = useState();
  const { dataLogin } = useSelector((state) => state.userAwb);
  const today = new Date(),
    date =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate() +
      " " +
      today.getHours() +
      ":" +
      today.getMinutes();
  const { t } = useTranslation("common");
  const componentRef = useRef();
  const history = useHistory();
  const dispatch = useDispatch();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const renderTableSHP = (footer, dataSource) => {
    let columns = [
      {
        title: "No",
        dataIndex: "key",
        key: "key",
        className: "head_tb_repo",
      },
      {
        title: "BOOKING",
        dataIndex: "parent",
        key: "parent",
        className: "head_tb_repo",
        children: [
          {
            title: "FL NO",
            dataIndex: "SS01_BKG_FLT_NO",
            key: "SS01_BKG_FLT_NO",
            className: "head_tb_repo",
          },
          {
            title: "FL DATE",
            dataIndex: "SS02_BKG_FLT_DAT",
            key: "SS02_BKG_FLT_DAT",
            className: "head_tb_repo",
          },
        ],
      },
      {
        title: "MAWB",
        dataIndex: "SS03_AWB_NO",
        key: "SS03_AWB_NO",
        className: "head_tb_repo",
      },
      {
        title: "PCS",
        dataIndex: "SS04_QTY_PCS",
        key: "SS04_QTY_PCS",
        className: "head_tb_repo",
      },
      {
        title: "WGT",
        dataIndex: "SS05_WGT_GRS",
        key: "SS05_WGT_GRS",
        className: "head_tb_repo",
      },
      {
        title: "ACCEPTANCED DATE",
        dataIndex: "SS06_ACPT_WGT_TIM",
        key: "SS06_ACPT_WGT_TIM",
        className: "head_tb_repo",
      },
      {
        title: "CUSTOMS CLEARENCE",
        dataIndex: "SS08_CUS_CLR_TIM",
        key: "SS08_CUS_CLR_TIM",
        className: "head_tb_repo",
      },
      {
        title: "OUTGOING FLIGHT",
        dataIndex: "parent",
        key: "parent",
        className: "head_tb_repo",
        children: [
          {
            title: "NO",
            dataIndex: "SS12_FLT_NO",
            key: "SS12_FLT_NO",
            className: "head_tb_repo",
          },
          {
            title: "DATE",
            dataIndex: "SS13_FLT_DAT",
            key: "SS13_FLT_DAT",
            className: "head_tb_repo",
          },
          {
            title: "PCS",
            dataIndex: "SS10_PCS",
            key: "SS10_PCS",
            className: "head_tb_repo",
          },
          {
            title: "WGT",
            dataIndex: "SS11_WGT",
            key: "SS11_WGT",
            className: "head_tb_repo",
          },
          {
            title: "STD",
            dataIndex: "SS14_STD",
            key: "SS14_STD",
            className: "head_tb_repo",
          },
          {
            title: "ATD",
            dataIndex: "SS15_ATD",
            key: "SS15_ATD",
            className: "head_tb_repo",
          },
        ],
      },
    ];

    setTableDataSHP(
      <Table
        className="table-bill"
        dataSource={dataSource}
        columns={columns}
        bordered
        scroll={{ x: "calc(300px + 50%)" }}
        pagination={{
          position: "bottomRight",
          defaultPageSize: 1000,
          hideOnSinglePage: true,
        }}
        summary={() => (
          <>
            <Table.Summary.Row>
              <Table.Summary.Cell index={0} colSpan={14} style={{}}>
                Total: {footer}
              </Table.Summary.Cell>
            </Table.Summary.Row>
          </>
        )}
      />
    );
  };
  const submitReport = async (value) => {
    try {
      setLoading(true);
      let dataSourceDocs = [];
      let footerDocs = "";
      let start_date =
        today.getDate() +
        "/" +
        (today.getMonth() + 1) +
        "/" +
        today.getFullYear();
      let end_date =
        today.getDate() +
        "/" +
        (today.getMonth() + 1) +
        "/" +
        today.getFullYear();
      let code = "*";
      let user_code;
      let flightNumber = "*";

      if (value.code !== undefined) {
        code = value.code;
      }
      if (value.user_code !== undefined) {
        user_code = value.user_code;
      }
      if (value.dateReport !== undefined) {
        start_date = value.dateReport[0].format("DD/MM/YYYY");
        end_date = value.dateReport[1].format("DD/MM/YYYY");
      }
      if (value.flightNumber !== undefined) {
        flightNumber = value.flightNumber;
      }
      let data = {
        STR_CONNECT: "tcs",
        START_DATE: start_date,
        END_DATE: end_date,
        FLIGHT_NO: flightNumber,
        AWB_NO: code,
        AGENT_CODE:
          dataLogin.data.user_code === "admi"
            ? user_code
            : dataLogin.data.user_code,
      };

      await GET_SS_EXP_STATUS_SHP(data, dataLogin.token).then((res) => {
        if (res && res.status === 200 && res.data && res.data.mCol.length > 0) {
          res.data.mCol.map((data, index) => {
            dataSourceDocs.push({
              key: index + 1,
              Key: data.Key,
              SS01_BKG_FLT_NO: data.SS01_BKG_FLT_NO,
              SS02_BKG_FLT_DAT: data.SS02_BKG_FLT_DAT,
              SS03_AWB_NO: data.SS03_AWB_NO,
              SS04_QTY_PCS: data.SS04_QTY_PCS,
              SS05_WGT_GRS: data.SS05_WGT_GRS,
              SS06_ACPT_WGT_TIM: data.SS06_ACPT_WGT_TIM,
              SS07_FLG_APV: data.SS07_FLG_APV,
              SS08_CUS_CLR_TIM: data.SS08_CUS_CLR_TIM,
              SS09_AWB_NO_S: data.SS09_AWB_NO_S,
              SS10_PCS: data.SS10_PCS,
              SS11_WGT: data.SS11_WGT,
              SS12_FLT_NO: data.SS12_FLT_NO,
              SS13_FLT_DAT: data.SS13_FLT_DAT,
              SS14_STD: data.SS14_STD,
              SS15_ATD: data.SS15_ATD,
            });
            footerDocs = index + 1;
          });
          // console.log(dataSourceDocs);
          renderTableSHP(footerDocs, dataSourceDocs);
          setLoading(false);
        } else {
          renderTableSHP(footerDocs, dataSourceDocs);
          setLoading(false);
        }
      });
    } catch (error) {
      if (error.response.status === 401) {
        notification.error({
          style: { marginRight: "-50%" },
          message: t("home.sessionNoti"),
        });
        dispatch(checkinUser({}));
        dispatch(setPaymentInfo(undefined));
        dispatch(userAwbLogout());
        localStorage.removeItem("userAWB");
        localStorage.removeItem("checkin");
        localStorage.removeItem("pInfo");
        history.push("/");
      }
    }
  };

  const [form] = useForm();
  const tailLayout = {
    wrapperCol: { offset: 1, span: 16 },
  };
  const dateFormat = "DD-MM-YYYY";

  return (
    <div>
      {/* <BackTop /> */}
      <Row className="awb-tab-title-wrapper">
        <Col span={24} className="awb-tab-title">
          <h5>REPORT FOR OUTBOUND SHIPMENT</h5>
        </Col>
      </Row>
      <Row className="awb-tab-content">
        <Row className="control-wrapper">
          <Form
            className="form-report"
            onFinish={submitReport}
            layout={"inline"}
            initialValues={{
              dateReport: [
                moment(today, dateFormat),
                moment(today, dateFormat),
              ],
              awbNo: "*",
              flightNumber: "*",
            }}
            form={form}
            autoComplete="off"
          >
            <Form.Item
              {...tailLayout}
              name="flightNumber"
              label="Flight Number"
            >
              <Input />
            </Form.Item>
            <Form.Item {...tailLayout} name="awbNo" label="AWB">
              <Input />
            </Form.Item>
            <Form.Item
              {...tailLayout}
              label={t("header.dateReport")}
              name="dateReport"
            >
              <RangePicker format={dateFormat} />
            </Form.Item>
            <Row style={{ width: "100%" }}>
              <Col
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
                span={24}
              >
                <Form.Item>
                  <Button style={{ width: "fit-content" }} htmlType={"submit"}>
                    Go
                  </Button>
                </Form.Item>
                <Form.Item>
                  <Button
                    style={{ width: "fit-content" }}
                    onClick={handlePrint}
                  >
                    Print
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Row>
      </Row>
      <Row ref={componentRef} className="result-wrapper">
        <Row className="result-content-wrapper">
          <Col span={24} className="result-content">
            <h5>REPORT FOR OUTBOUND SHIPMENT</h5>
            <Skeleton loading={loading}>{tableDataSHP}</Skeleton>
          </Col>
        </Row>
      </Row>
    </div>
  );
};
export default TabReportForOutboundShipment;
