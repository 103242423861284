import React, { useEffect, useState } from 'react';
import { Form, Table, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import ModalSearchForm from '../EsidModal/ModalSearchForm';
import { formatFltDateDisplay } from '../../../../../utils/utils';
import { GetEsidExportById } from '../../../../../config/nvesidExportApi';
import { errorHandling } from '../../../../../config/AuthIntercept';
import { useDispatch } from 'react-redux';
import { setEsidExpSelected } from '../../../../../actions/esidExportAction';
import { EsidExp } from '../../EsidExp';
import { useHistory, useLocation } from 'react-router-dom';

const basketStatus = ['NY', 'VR', 'DR', 'FR', 'AQ', 'AC', 'AM'];
const paidStatus = ['AP', 'QR'];
const invStatus = ['FN', 'OK'];
const notStatusAccpYet = ['AP', 'QR', 'FN'];
export const AirlinesEsidList = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('common');
  const dateFormat = formatFltDateDisplay;
  const [dataSource, setDataSource] = useState([]);
  const [searchForm] = Form.useForm();
  const [esidStatus, setEsidStatus] = useState(
    localStorage.getItem('esidFindStatus') || basketStatus
  );
  const [autoRefresh, setAutoRefresh] = useState(0);
  const [viewDetail, setViewDetail] = useState(false);
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const autoRef = '0';
    setAutoRefresh(Number.parseFloat(autoRef));
    localStorage.setItem('GetFromLocation', 'ALL');
    const esidSts = [...basketStatus, ...paidStatus, ...invStatus, ...notStatusAccpYet];
    setEsidStatus(esidSts);
    localStorage.setItem('esidFindStatus', esidSts);
    // eslint-disable-next-line
  }, []);

  const columns = [
    {
      title: 'AWB/ESID',
      dataIndex: 'awbPfxAndNum',
      width: '15%',
      sorter: (a, b) => a.awbPfxAndNum.localeCompare(b.awbPfxAndNum),
    },
    {
      title: t('registerCar.table.flightNo'),
      dataIndex: 'fltCarAndNum',
      width: '15%',
      sorter: (a, b) => a.fltCarAndNum.localeCompare(b.fltCarAndNum),
    },
    {
      title: t('registerCar.table.flightDate'),
      dataIndex: 'datFltOrigin',
      width: '15%',
      render: (_, record) => moment(record.datFltOrigin).format(dateFormat).toLocaleUpperCase(),
      sorter: (a, b) => moment(a.datFltOrigin).unix() - moment(b.datFltOrigin).unix(),
    },
    {
      title: t('registerCar.table.esid'),
      dataIndex: 'esidId',
      width: '15%',
      sorter: (a, b) => a.esidId - b.esidId,
      defaultSortOrder: 'descend',
    },
    {
      title: t('esidExpList.table.dgrChk'),
      dataIndex: 'shcDgr',
      key: 'shcDgr',
      width: '7%',
      sorter: (a, b) => a.shcDgr - b.shcDgr,
      render: (_, record) => (record.shcDgr === 'Y' ? 'Y' : ''),
    },
    {
      title: t('esidExpList.table.dgrAcp'),
      dataIndex: 'dgrAcpStfCod',
      key: 'dgrAcpStfCod',
      width: '10%',
      sorter: (a, b) => a.dgrAcpStfCod.localeCompare(b.dgrAcpStfCod),
      render: (_, record) => record.dgrAcpStfCod || '',
    },
    {
      title: t('admin.acceptance.desRmk002'),
      dataIndex: 'desRmk002',
      key: 'desRmk002',
      width: '15%',
      align: 'left',
      ellipsis: true,
      sorter: (a, b) => a.desRmk002.localeCompare(b.desRmk002),
    },
    {
      title: t('Đã gửi mail'),
      dataIndex: 'numOfMalSend',
      key: 'numOfMalSend',
      width: '10%',
    },
    {
      title: t('esidExpList.table.status'),
      dataIndex: 'codSts',
      width: '25%',
      render: (_, record) => t(`esid.codSts.${record.codSts}`),
      sorter: (a, b) => t(`esid.codSts.${a.codSts}`).localeCompare(t(`esid.codSts.${b.codSts}`)),
    },
  ];

  const [esidLoading, setEsidLoading] = useState(false);
  const onSelectChange = async (selectedRowKeys, selectedRows) => {
    try {
      if (selectedRows && selectedRows.length > 0) {
        setEsidLoading(true);
        const { data } = await GetEsidExportById(selectedRows[0].esidId);
        setEsidLoading(false);
        dispatch(setEsidExpSelected(data));
        setViewDetail(true);
      }
    } catch (error) {
      errorHandling(error, history, location.pathname, false);
    }
  };

  const backToEsidList = () => {
    dispatch(setEsidExpSelected(undefined));
    setViewDetail(false);
  }

  return (
    <>
      {viewDetail ? (
        <>
          <Button type='primary' onClick={backToEsidList}>{t("ESID List")}</Button>
          <EsidExp />
        </>
      ) : (
        <>
          <div style={{ marginBottom: '5px' }}>
            <ModalSearchForm
              setDataSource={setDataSource}
              form={searchForm}
              filterCodSts={esidStatus}
              autoRefresh={autoRefresh}
            />
          </div>
          <Table
            bordered
            columns={columns}
            pagination={{
              position: 'bottomRight',
              defaultPageSize: 100,
            }}
            dataSource={dataSource}
            loading={esidLoading}
            rowClassName="cursorPointer"
            onRow={(record) => ({
              onClick: () => onSelectChange([record.key], [record]),
            })}
          />
        </>
      )}
    </>
  );
};
