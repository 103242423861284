import {
  Button,
  Modal,
  Table,
  Form,
  notification,
  Row,
  Col,
  DatePicker,
  Input,
} from "antd";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { GET_EXP_FLIGHT_CUTOFF_TIME } from "../../../config/webTrackApi";
export const ModalCutOffTime = ({
  setVisible,
  visible,
  setFlightDate,
  setFlightNo,
  dateIsString,
}) => {
  const [visibleConfirm, setVisibleConfirm] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [dataProject, setDataProject] = useState([]);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const { t } = useTranslation("common");
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [valueInput, setValueInput] = useState("");

  const columns = [
    {
      title: t("CutOffTime.fight"),
      dataIndex: "F01_COD_FLT_CAR",
      // key: "F01_COD_FLT_CAR",
      className: "head_tb_repo",
    },
    {
      title: t("CutOffTime.fightNum"),
      dataIndex: "F02_COD_FLT_NUM",
      // key: "F02_COD_FLT_NUM",
      className: "head_tb_repo",
    },
    {
      title: t("CutOffTime.Departure"),
      dataIndex: "F03_DAT_FLT_ORI",
      // key: "F03_DAT_FLT_ORI",
      className: "head_tb_repo",
    },
    {
      title: t("CutOffTime.from"),
      dataIndex: "F04_COD_APT_BRD",
      // key: "F04_COD_APT_BRD",
      className: "head_tb_repo",
    },
    {
      title: t("CutOffTime.flightStd"),
      dataIndex: "F08_STD",
      render: (_, record) => {
        // eslint-disable-next-line
        const fltStdDat = moment(record.F08_STD, 'DDMMMYYYY HH:mm');
        // eslint-disable-next-line
        const fltEtdDat = moment(record.F10_ETD || record.F08_STD, 'DDMMMYYYY HH:mm');
        // return fltEtdDat.isAfter(fltStdDat) ? record.F10_ETD : record.F08_STD;
        return record.F08_STD;
      },
      // key: "F08_STD",
      className: "head_tb_repo",
    },
  ];

  const handleOk = () => {
    try {
      if (selectedRows.length > 0) {
        setFlightNo(
          selectedRows[0].F01_COD_FLT_CAR + selectedRows[0].F02_COD_FLT_NUM
        );
        const fltStdDat = moment(selectedRows[0].F08_STD, 'DDMMMYYYY HH:mm');
        const fltEtdDat = moment(selectedRows[0].F10_ETD || selectedRows[0].F08_STD, 'DDMMMYYYY HH:mm');
        const fltDat = fltEtdDat.isAfter(fltStdDat) ? fltEtdDat : fltStdDat;
        console.log(fltDat)
        if (moment().isAfter(fltStdDat.add(1, 'd'))) {
          notification.error({
            message: t("CutOffTime.selectFltErr"),
          });
          return;  
        }
        setFlightDate(dateIsString ? selectedRows[0].F08_STD : moment(selectedRows[0].F08_STD, 'DDMMMYYYY HH:mm'));
        notification.success({
          style: { marginRight: "-50%" },
          message: t("CutOffTime.successNoti"),
        });
        setVisibleConfirm(false);
        setTimeout(() => setVisible(false), 200);
      }
    } catch (error) {
      console.log(error);
      notification.error({
        style: { marginRight: "-50%" },
        message: t("feedback.error"),
      });
    }
  };

  const fetchList = async (flightDate) => {
    if (flightDate) {
      setLoading(true);
      try {
        let data = [];
        const url = "api/GET_EXP_FLIGHT_CUTOFF_TIME";
        const params = {
          STR_CONNECT: "AAA",
          EXP_FLT_DATE: moment(flightDate).format("DD/MM/YYYY"),
        };
        const fligthCutOffRes = await GET_EXP_FLIGHT_CUTOFF_TIME(url, params);
        if (
          fligthCutOffRes.status === 200 &&
          fligthCutOffRes.data !== "" &&
          fligthCutOffRes.data.mCol.length >= 1
        ) {
          fligthCutOffRes.data.mCol.forEach((dt, index) => {
            data.push({
              key: index,
              index: index + 1,
              F01_COD_FLT_CAR: dt.F01_COD_FLT_CAR,
              F02_COD_FLT_NUM: dt.F02_COD_FLT_NUM,
              F03_DAT_FLT_ORI: dt.F03_DAT_FLT_ORI,
              F04_COD_APT_BRD: dt.F04_COD_APT_BRD,
              F05_COD_APT_OFF: dt.F05_COD_APT_OFF,
              F08_STD: dt.F08_STD,
              F10_ETD: dt.F10_ETD,
              F_KEY: dt.Key,
            });
          });
          setDataSource(data);
        }
        setLoading(false);
      } catch (error) {
        notification.error({
          style: { marginRight: "-50%" },
          message: "Lỗi!",
        });
      }
    }
  };

  const onSearch = useCallback(
    (value) => {
      setLoading(true);
      setTimeout(() => {
        setValueInput(value);
        const newData = dataSource?.filter((items) =>
          (
            items.F01_COD_FLT_CAR.toLowerCase() +
            items.F02_COD_FLT_NUM.toLowerCase()
          ).includes(value.toLowerCase())
        );
        setDataProject(newData);
        setLoading(false);
      }, 400);
    },
    // eslint-disable-next-line
    [valueInput]
  );

  useEffect(() => {
    setDataProject(dataSource ?? []);
  }, [dataSource]);
  useEffect(() => {
    const timeout = setTimeout(() => {
      onSearch(valueInput);
    }, 500);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line
  }, [valueInput]);

  useEffect(() => {
    visible && fetchList(moment());
    return () => {
      setSelectedRows([]);
      setDataSource([]);
      setSelectedRowKeys([]);
    };
  }, [visible]);

  const onSelectChange = (selectedRowKeys, selectedRows) => {
    console.log(selectedRows)
    setSelectedRowKeys(selectedRowKeys);
    setSelectedRows(selectedRows);
  };

  const dateFormat = "MM-DD-YYYY";
  return (
    <>
      <Modal
        width={"60%"}
        title={t("CutOffTime.flightList")}
        style={{ height: "620px" }}
        visible={visible}
        onCancel={() => {
          setSelectedRowKeys([]);
          setSelectedRows([]);
          setVisible(false);
        }}
        footer={[
          <Button
            key={`btnCancel2`}
            className="btn-payment"
            onClick={() => {
              setSelectedRowKeys([]);
              setSelectedRows([]);
              setVisible(false);
            }}
            style={{ width: "fit-content", marginLeft: "5px" }}
          >
            Cancel
          </Button>,
          <Button
            key={`btnOk2`}
            className="btn-payment"
            onClick={() => setVisibleConfirm(true)}
            style={{ width: "fit-content" }}
          >
            Ok
          </Button>,
        ]}
      >
        <Form initialValues={{ flightDate: moment() }} form={form}>
          <Row>
            <Col md={13} span={24}>
              <Form.Item name="flightNo" label={t("tabInfo.flight")}>
                <Input.Search
                  onSearch={(value) => {
                    onSearch(value);
                    /* setValueInput(value); */
                  }}
                  onChange={(e) => setValueInput(e.target.value)}
                />
              </Form.Item>
            </Col>
            <Col
              style={{ display: "flex", justifyContent: "center" }}
              md={11}
              span={24}
            >
              <Form.Item name="flightDate" label={t("tabInfo.flightDate")}>
                <DatePicker
                  onChange={(e) => fetchList(e)}
                  style={{ width: "100%" }}
                  format={`${dateFormat}`}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <Table
          bordered
          loading={loading}
          className="table-cutoff"
          rowKey='F_KEY'
          columns={columns}
          pagination={{
            position: "bottomRight",
            defaultPageSize: 20,
            hideOnSinglePage: true,
          }}
          rowSelection={{
            selectedRowKeys,
            fixed: true,
            type: "radio",
            onChange: onSelectChange,
          }}
          dataSource={dataProject}
        />
      </Modal>
      {visibleConfirm && (
        <Modal
          centered
          width={"620px"}
          title={"Thông báo"}
          visible={visibleConfirm}
          onCancel={() => setVisibleConfirm(false)}
          footer={[
            <Button onClick={() => setVisibleConfirm(false)}>Không</Button>,
            <Button onClick={handleOk} loading={loading} type="primary">
              Đồng ý
            </Button>,
          ]}
        >
          <div>Bạn có đồng ý chọn chuyến bay này?</div>
          <div>Chuyến bay: {selectedRows[0]?.F01_COD_FLT_CAR + selectedRows[0]?.F02_COD_FLT_NUM}</div>
          <div>Ngày bay: {selectedRows[0]?.F08_STD}</div>
        </Modal>
      )}
    </>
  );
};
