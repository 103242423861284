import {
    Button,
    Checkbox,
    Col,
    Input,
    Modal,
    Row,
    Space,
    Form,
    Divider,
    notification
  } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import styles from '../../style.module.css'
import { ContentItem } from "./ContentItem";
import { EsidStatusEnum } from "../../../../constant/enumerations";
import { errorHandling } from "../../../../config/AuthIntercept";
import { ExpGetEmailsWithAir, ExpSendMailToAirline, UpdateEsidExportById } from "../../../../config/nvesidExportApi";
import { setEsidExpSelected } from "../../../../actions/esidExportAction";

export const FinalizeModal = ({ visible, setVisible, handleSubmitFinalize, isSendToAirlineModal = false, modalSearchForm, htmlExpContent }) => {
    // eslint-disable-next-line
    const { t } = useTranslation("common");
    const dispatch = useDispatch();
    const selectEsidExport = useSelector((state) => state.esidExport.esidExpSelected);
    const expCarDimToEdit = useSelector((state) => state.esidExport.expCarDimToEdit);
    const [checked, setChecked] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const selectAirports = useSelector(state => state.airport.airports) ?? [];
    const [airlineForm] = Form.useForm();

    useEffect(() => {
        const getEmails = async () => {
            try {
                const recipients = await ExpGetEmailsWithAir(selectEsidExport?.codFltCar);
                const emails = recipients.data.map(m => m.email);
                airlineForm.setFieldsValue({
                    cosigneeAirline: selectEsidExport?.codFltCar,
                    airlineEmail: emails.join(';')
                });
            } catch (error) {
                airlineForm.setFieldsValue({
                    cosigneeAirline: selectEsidExport?.codFltCar,
                    airlineEmail: ''
                });
                // errorHandling(error);
            }
        }   
        if (selectEsidExport) {
            getEmails();
        } else {
            airlineForm.setFieldsValue({
                cosigneeAirline: selectEsidExport?.codFltCar,
                airlineEmail: ''
            });
        }
        // eslint-disable-next-line
    }, [selectEsidExport]);
    // const showSuccess = (msg) => {
    //     notification.success({
    //       message: t("Congrats"),
    //       description: msg,
    //       top: 100,
    //     })
    // }
    const showError = (msg) => {
        notification.error({
            message: t("Oops"),
            description: msg,
            top: 100,
        })
    }
    // eslint-disable-next-line
    const showWarning = (msg) => {
        notification.warning({
            message: t("Oops"),
            description: msg,
            top: 100,
        })
    }

    const getAirportName = (airportCode) => {
        if ( selectEsidExport && selectAirports) {
            const apt = selectAirports.find(
                (x) => x.codApt.toLocaleUpperCase() === airportCode.toLocaleUpperCase()
            );
            return apt ? apt.namApt : null;
        }
    }

    const [mailSending, setMailSending] = useState(false);
    const handleSendToAirline = async (esidExp) => {
        const airlineValue = await airlineForm.validateFields();
        try {
            setMailSending(true);
            let htmlContent = htmlExpContent;//.replace('/static/', `${window.location.protocol}/${window.location.host}/static/`);
            htmlContent = htmlContent.replace('></style>','> .none-style { display: none; }</style>');
            htmlContent = htmlContent.replace('Tên đầy đủ, chữ ký nhân viên tiếp nhận: </span>', `Tên đầy đủ, chữ ký nhân viên tiếp nhận: ${esidExp.acpStfNam}</span>`)
            htmlContent = htmlContent.replace('name="acpStfName">Ngày giờ tiếp nhận: </span>', `name="acpStfName">Ngày giờ tiếp nhận: ${moment(esidExp.acpDat).format("DD/MM/YYYY HH:mm")}</span>`)

            const sendData = {
                codFltCar: airlineValue.cosigneeAirline,
                sendTo: airlineValue.airlineEmail,
                codAwbPfx: selectEsidExport.codAwbPfx,
                codAwbNum: selectEsidExport.codAwbNum,
                htmlExpContent: htmlContent
            };
            await ExpSendMailToAirline(sendData);
            const exp = esidExp?.codAwbPfx ? esidExp : {...selectEsidExport}
            const updRes = await UpdateEsidExportById({ 
                ...exp, 
                numOfMalSend: selectEsidExport?.numOfMalSend ? selectEsidExport?.numOfMalSend + 1 : 1
            });
            dispatch(setEsidExpSelected(updRes.data));
            setMailSending(false);
            if (isSendToAirlineModal) {
                handleCloseModal();
            }
        } catch (error) {
            setMailSending(false);
            errorHandling(error);
        }
    }
    const handleSubmit = async () => {
        if (isSendToAirlineModal || checked) {
            const airlineValue = await airlineForm.validateFields();
            console.log(airlineValue)
        }
        setIsSubmitting(true);
        if (selectEsidExport.shcDgr === 'Y' && selectEsidExport.codSts !== EsidStatusEnum.DR) {
            showError(t('admin.notification.dgrAccecptError'));
            handleCloseModal();
            setIsSubmitting(false);
            return;
        }
        if (selectEsidExport.shcBup !== 'Y' && selectEsidExport.expCarDim.length === 0) {
            showError(t('admin.notification.dmsEmptyError'));
            handleCloseModal();
            setIsSubmitting(false);
            return;
        }
        if (selectEsidExport.expCarWgt.length === 0) {
            showError(t('admin.notification.wgtEmptyError'));
            handleCloseModal();
            setIsSubmitting(false);
            return;
        }
        
        const qtyPcsTotal = expCarDimToEdit.filter(x => x.codSts !== 'XX').map(x => x.qtyDimPcs).reduce((prev, current) => prev + current, 0);
        if (selectEsidExport.qtyPcs !== qtyPcsTotal && selectEsidExport.shcBup !== 'Y') {
            Modal.error({
                title: "Thông báo",
                content: "Số lượng tiếp nhận khác số lượng khai báo. Vui lòng kiểm tra lại.",
                onOk: () => {
                    setIsSubmitting(false);
                    return;
                }
            });
            return;
        }

        if (isSendToAirlineModal) {
            //TODO:API SEND TO AIRLINE
            await handleSendToAirline();
        }
        else {
            const exp = await handleSubmitFinalize();
            if (checked) {
                //TODO:API SEND TO AIRLINE
                await handleSendToAirline(exp);
            }
        }
        handleCloseModal();
        setIsSubmitting(false);
        modalSearchForm.submit();

        // if (selectEsidExport.qtyPcs !== qtyPcsTotal || !selectEsidExport.expCarWgt[0].volWgt || !selectEsidExport.expCarWgt[0].wgtGrs) {
            // console.log('Số kiện khai báo khác số kiện tiếp nhận.');
            // Modal.confirm({
            //     title: 'Thông báo',
            //     content: <div>
            //         {selectEsidExport.qtyPcs !== qtyPcsTotal && (
            //             <>
            //                 <div style={{ fontWeight: 'bold' }}>Số kiện khai báo khác số kiện tiếp nhận.</div>
            //                 <div>{`Số kiện khai báo ESID: ${selectEsidExport.qtyPcs}`}</div>
            //                 <div>{`Số kiện tiếp nhận: ${qtyPcsTotal}`}</div>
            //             </>
            //         )}
            //         {(!selectEsidExport.expCarWgt[0].volWgt || !selectEsidExport.expCarWgt[0].wgtGrs) && (
            //             <>
            //                 <div style={{ fontWeight: 'bold' }}>Trọng lượng của lô hàng không đúng.</div>
            //                 <div>{`Trọng lượng theo thể tích: ${selectEsidExport.expCarWgt[0].volWgt}`}</div>
            //                 <div>{`Trọng lượng: ${selectEsidExport.expCarWgt[0].wgtGrs}`}</div>
            //                 <div>{`Trọng lượng tính cước: ${selectEsidExport.expCarWgt[0].volWgt > selectEsidExport.expCarWgt[0].wgtGrs ? selectEsidExport.expCarWgt[0].volWgt : selectEsidExport.expCarWgt[0].wgtGrs}`}</div>
            //             </>
            //         )}
            //         <div style={{ fontWeight: 'bold' }}>Bạn có muốn tiếp tục Finalize ESID này không?</div>
            //     </div>,
            //     okText: 'Tiếp tục Finalize',
            //     onOk: async () => {
            //         if (isSendToAirlineModal) {
            //             //TODO:API SEND TO AIRLINE
            //             await handleSendToAirline();
            //         }
            //         else {
            //             const esid = await handleSubmitFinalize();
            //             if (checked) {
            //                 //TODO:API SEND TO AIRLINE
            //                 await handleSendToAirline(esid); // trạng thái appceptance đã hoàn thành, hardcode, do dispatch esid chua xong
            //             }
            //         }
            //         handleCloseModal();
            //         setIsSubmitting(false);
            //         modalSearchForm.submit();
            //     },
            //     onCancel: () => {
            //         if (isSubmitting || mailSending) {
            //             return;
            //         }
            //         setIsSubmitting(false);
            //         return;
            //     }
            // });
        // } else {
        //     if (isSendToAirlineModal) {
        //         //TODO:API SEND TO AIRLINE
        //         await handleSendToAirline();
        //     }
        //     else {
        //         const exp = await handleSubmitFinalize();
        //         if (checked) {
        //             //TODO:API SEND TO AIRLINE
        //             await handleSendToAirline(exp);
        //         }
        //     }
        //     handleCloseModal();
        //     setIsSubmitting(false);
        //     modalSearchForm.submit()
        // }
    }

    const handleCloseModal = () => {
        setVisible(false);
        setIsSubmitting(false);
        airlineForm.resetFields();
    }

    return (
        <Modal
            title={isSendToAirlineModal ? t('admin.acceptance.modal.sendToAirline') : 'Finalize ESID'}
            className={styles.EsidModal}
            visible={visible}
            centered
            onCancel={handleCloseModal}
            width={700}
            footer={[
                <Button
                    key={`btnCancel1`}
                    className="btn-payment"
                    onClick={handleCloseModal}
                    style={{ width: "fit-content", marginLeft: "5px" }}
                    disabled={isSubmitting || mailSending}
                >
                    {t('admin.acceptance.modal.cancel')}
                </Button>,
                <Button
                    key={`btnOk1`}
                    className="btn-payment"
                    loading={isSubmitting || mailSending}
                    onClick={isSendToAirlineModal ? handleSendToAirline : handleSubmit}
                    style={{ width: "fit-content" }}
                >
                    { isSendToAirlineModal ? t('admin.acceptance.modal.sendBtn') : 'Finalize' }
                </Button>,
            ]}
            destroyOnClose={true}
        >
            <Space size={0} direction="vertical" style={{ marginBottom: 10, width: '100%' }}>
                <ContentItem label={t('admin.acceptance.modal.airWaybillNumber')} value={`${selectEsidExport?.codAwbPfx} - ${selectEsidExport?.codAwbNum}`}/>
                <ContentItem label={t('admin.acceptance.modal.pcsNumber')} value={selectEsidExport?.qtyPcs}/>
                <ContentItem label={t('admin.acceptance.modal.flightDate')} value={moment(selectEsidExport?.datFltOri).format('DD-MM-YYYY')}/>
                <ContentItem label={t('admin.acceptance.modal.flightNo')} value={selectEsidExport?.codFltNum}/>
                <ContentItem label={t('admin.acceptance.modal.oriFlight')} value={`${selectEsidExport?.codOri} - ${getAirportName(selectEsidExport?.codOri)}`}/>
                <ContentItem label={t('admin.acceptance.modal.fdsFlight')} value={`${selectEsidExport?.codFds} - ${getAirportName(selectEsidExport?.codFds)}`}/>
            </Space>
            <Divider  style={{ margin: '0 0 10px', borderTop: '1px solid rgb(0 0 0 / 27%)' }}/>
            {
                !isSendToAirlineModal && 
                    <Checkbox checked={checked} onChange={(e) => setChecked(e.target.checked)}>{t('admin.acceptance.modal.sendToAirline')}</Checkbox>
            }
            {(isSendToAirlineModal || checked) && (
                <Row>
                    <Col span={24} style={{ fontWeight: 'bold', marginTop: isSendToAirlineModal ? 0 : '10px' }}>
                        {`Gửi lần ${(selectEsidExport?.numOfMalSend || 0) + 1}`}
                    </Col>
                </Row>
            )}
            <Row>
                <Col md={14} sm={14} xs={24}>
                    <Form
                        autoComplete="off"
                        labelAlign="left"
                        labelCol={{span: 24}}
                        wrapperCol={{span: 24}}
                        form={airlineForm}
                    >
                        <Form.Item
                            label={<label style={{ fontSize: 14 }}>{t('admin.acceptance.modal.airLineName')}</label>}
                            name={'cosigneeAirline'}
                            rules={[
                                {
                                    required: true,
                                    message: 'Không được để trống'
                                },
                            ]}
                            >
                            <Input disabled={!checked && !isSendToAirlineModal} />
                        </Form.Item>
                        <Form.Item
                            label={<label style={{ fontSize: 14 }}>Email</label>}
                            name={'airlineEmail'}
                            rules={[
                                {
                                    required: true,
                                    message: 'Không được để trống'
                                },
                                {
                                    type: 'email',
                                    message: 'Phải là email'
                                }
                            ]}
                        >
                            <Input disabled={!checked && !isSendToAirlineModal}/>
                        </Form.Item>
                    </Form>
                </Col>
                <Col md={10} sm={10} xs={24}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%'}}>
                        <img
                            style={{ objectFit: "cover", width: "100%", height: "auto", margin: 'auto', maxWidth: 200 }}
                            alt="finalize"
                            src="../../img/img_SendMail2.png"
                        />
                    </div>
                </Col>
            </Row>
        </Modal>
  )
}
