import React from 'react'
import { Space, List, Empty} from 'antd';
import styles from '../../style.module.css'
import { GoodsCard } from './GoodsCard';

export const SmallGoodsTable = ({ dataSource, handleEdit, handleDelete, isDgrReceived }) => {

    return (
        <>
        <Space direction='vertical' className={`${styles.SmallGoodsTable} scrollable smallTable`}>
            {
                dataSource && dataSource.length > 0 ? 
                <List
                    grid={{
                        gutter: 10,
                        lg: 2,
                        md: 2,
                        sm: 2,
                        xs: 1
                    }}
                    itemLayout="vertical"
                    dataSource={dataSource}
                    renderItem={item => (
                        <GoodsCard handleEdit={handleEdit} item={item} handleDelete={handleDelete} disabled={isDgrReceived} />
                    )}
                    split
                    className='smallTable-list'
                /> :
                (
                    <Empty />
                )
            }
        </Space>
        </>
    )
}
