import React from 'react'
import { Space, Form, Input, Button, InputNumber, Modal } from 'antd';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { setLivAnmSelectedToEdit } from '../../../../actions/livAnmAction';
import { useEffect } from 'react';
const placeHolders = {
    SPECIES: 'eg: ABC132',
    PACKAGES: 'eg: I',
    QUANTITY: 'eg: 1',
    DES_NAME: 'eg: Dog',
    SPEC_DHL_COD: '',
}
export const AddLivAnimalsModal = ({ modalVisible, handleAdd, handleUpdate,setModalVisible, form }) => {
    const { t } = useTranslation("common");
    const [confirmLoading, setConfirmLoading] = useState(false);
    const livAnmSelectedToEdit = useSelector((state) => state.livAnms.livAnmSelectedToEdit);
    const [isSaveAndAdd, setIsSaveAndAdd] = useState(false);
    const dispatch = useDispatch();

    const handleSubmit = (formValue) => {
        // console.log(isSaveAndAdd);
        setConfirmLoading(true);
        if(livAnmSelectedToEdit){
            // console.log(formValue);
            // console.log({...dgrSelectedToEdit, ...formValue});
            const newData = {...livAnmSelectedToEdit, ...formValue};
            handleUpdate(newData);
        }else{
          handleAdd(formValue);
        }
        setConfirmLoading(false);
        form.resetFields();
        if(!isSaveAndAdd){
            setModalVisible(false);
        }
    }

    const handleCancel = () =>{
        form.resetFields();
        dispatch(setLivAnmSelectedToEdit(null));
        setModalVisible(false);
        setIsSaveAndAdd(false);
    }
    
    const handleSetSaveAndAdd = (val) =>{
        setIsSaveAndAdd(val);
    }

    useEffect(() => {
        form.setFieldsValue({
            numOfPkg: livAnmSelectedToEdit?.numOfPkg,
            specSpecConReq: livAnmSelectedToEdit?.specSpecConReq,
            specDesNam: livAnmSelectedToEdit?.specDesNam,
            qtyAvi: livAnmSelectedToEdit?.qtyAvi,
            specHdlCod: livAnmSelectedToEdit?.specHdlCod,
          })
        // eslint-disable-next-line
    },[livAnmSelectedToEdit])

    return (
        <Modal
            visible={modalVisible}
            onCancel={handleCancel}
            footer={null}
            centered
            className={'800px'}
        >
            <Form
                labelAlign='left'
                labelCol={{span: 24}}
                onFinish={handleSubmit}
                form={form}
                initialValues={{
                    numOfPkg: '',
                    specSpecConReq: '',
                    specDesNam: '',
                    qtyAvi: ''
                }}
                autoComplete="off"
            >
            <Form.Item
                name="numOfPkg"
                label={t("livAnm.formInfo.numOfPkg")}
                rules={[
                {
                    required: true,
                    message: t("livAnm.errorInfo.required"),
                },
                ]}
            >
                <Input placeholder={placeHolders.PACKAGES} />
            </Form.Item>
            <Form.Item
                style={{ marginTop: 5}}
                name="specSpecConReq"
                label={t("livAnm.formInfo.specSpecConReq")}
                rules={[
                {
                    required: true,
                    message: t("livAnm.errorInfo.required"),
                },
                ]}
                >
                <Input placeholder={placeHolders.SPECIES} />
            </Form.Item>
            <Form.Item
                name="specDesNam"
                label={t("livAnm.formInfo.specDesNam")}
                rules={[
                {
                    required: true,
                    message: t("livAnm.errorInfo.required"),
                },
                ]}
                >
                <Input placeholder={placeHolders.DES_NAME}/>
            </Form.Item>
            <Form.Item
                name="qtyAvi"
                label={t("livAnm.formInfo.qtyAvi")}
                rules={[
                {
                    required: true,
                    message: t("livAnm.errorInfo.required"),
                },
                { 
                    type:'number',
                    message: t("livAnm.errorInfo.onlyNum")
                }
                ]}
                >
                <InputNumber style={{width: '100%', height: '100%'}} min={0} max={10} placeholder={placeHolders.QUANTITY}/>
            </Form.Item>
            <Form.Item
                name="specHdlCod"
                label={t("livAnm.formInfo.specHdlCod")}
                >
                <Input placeholder={placeHolders.SPEC_DHL_COD} />
            </Form.Item>
            <Form.Item style={{display: 'flex', justifyContent: 'center', marginTop: 10}}>
                <Space style={{justifyContent: 'center', width: '100%'}} >
                    {livAnmSelectedToEdit ? (
                    <>
                        <Button loading={confirmLoading} type='primary' htmlType='submit' style={{ width: "fit-content" }} className='submitBtn' onClick={() => {handleSetSaveAndAdd(false)}}>
                            {t('dangerousGood.formInfo.btnEdit')}
                        </Button>
                    </>
                    ) : (
                        <Button loading={confirmLoading} type='primary' htmlType='submit' style={{ width: "fit-content" }} className='submitBtn' onClick={() => {handleSetSaveAndAdd(false)}}>
                            {t('dangerousGood.formInfo.btnAdd')}
                        </Button>
                    )}
                    <Button loading={confirmLoading} type='primary' htmlType='submit' style={{ width: "fit-content" }} className='submitBtn' onClick={() => {handleSetSaveAndAdd(true)}}>
                        {t('dangerousGood.formInfo.btnSaveAndAdd')}
                    </Button>
                    <Button loading={confirmLoading} type='primary' style={{ width: "fit-content" }} onClick={handleCancel} className='submitBtn'>
                            {t('dangerousGood.formInfo.btnCancel')}
                    </Button>
                </Space>
            </Form.Item>
            </Form>
        </Modal>
    )
}
