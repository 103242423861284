/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Row, Col, Tabs, Button, message, notification } from "antd";
import Banner from "../components/Banner";
import { userAwbLogout } from "../actions/userAWB";
import * as API from "../config/apiConfig";
import "./page-css/Airlines.css";
import ChangePasswordTab from "../components/ChangePasswordTab";
import { useDispatch, useSelector } from "react-redux";
import TabInfo from "../components/TabInfo";
import TabAirlinesReport from "../components/tracking/airlines/TabAirlinesReport";
import TabAirlinesInven from "../components/tracking/airlines/TabAirlinesInven";
import TabAirlinesExpStatus from "../components/tracking/airlines/TabAirlinesExpStatus";
import TabAirlinesExpInven from "../components/tracking/airlines/TabAirlinesExpInven";
import TabCutOffTime from "../components/TabCutOffTime";
import { useHistory } from "react-router";
import { showLangSwitch } from "../actions/menu";
import { useTranslation } from "react-i18next";
import { setMAWB } from "../actions/MAWB";
import { AirlinesEsidList } from "./admin/EsidExport/components/EsidList/AirlinesEsidList";
const { TabPane } = Tabs;

const Airlines = () => {
  const { dataLogin } = useSelector((state) => state.userAwb);
  const token = JSON.parse(localStorage.getItem("userAWB"));
  const dispatch = useDispatch();
  const history = useHistory();
  const [activeKey, setActiveKey] = useState("export-info");
  const { t } = useTranslation("common");
  const today = new Date();
  // date =
  //   today.getFullYear() +
  //   "-" +
  //   (today.getMonth() + 1) +
  //   "-" +
  //   today.getDate();

  const logOut = () => {
    API.logout(token).then((res) => {
      dispatch(userAwbLogout());
      dispatch(setMAWB({}));
      localStorage.removeItem("userAWB");
      localStorage.removeItem("MAWB");
      message.success(res.data.message);
      history.push("/");
    });
  };

  useEffect(() => {
    dispatch(showLangSwitch(false));
    if (dataLogin.data.user_code.startsWith("TCS")) {
      notification.warning({
        message: "Tài khoản này không có quyền truy cập!",
        style: { marginRight: "-50%" },
      });
      history.push("/");
    }
  }, []);
  const operations = (
    <Button
      className="logout-btn"
      onClick={logOut}
      style={{ width: "fit-content" }}
    >
      {t("header.logOut")}
    </Button>
  );
  return (
    <Row
      style={{ display: "flex", justifyContent: "center" }}
      className="airlines-wrapper"
    >
      <Banner
        title={""}
        className="banner-wrapper"
        cover={"../../img/004.jpg"}
      />
      <Row
        style={{ display: "flex", justifyContent: "center" }}
        className="airlines-content-wrapper"
      >
        <Col
          style={{ display: "flex", justifyContent: "center" }}
          className="airlines-content"
          span={20}
        >
          <Tabs
            tabBarExtraContent={operations}
            tabBarGutter={10}
            size={"default"}
            // defaultActiveKey={"export-info"}
            activeKey={activeKey}
            onChange={(key) => {
              setActiveKey(key);
            }}
          >
            <TabPane
              className="awb-tab-content-wrapper"
              tab={t("airlines.cargoTracking")}
              key={"export-info"}
            >
              <TabInfo />
            </TabPane>
            <TabPane
              className="awb-tab-content-wrapper"
              tab={t("header.report")}
              key={"report"}
            >
              <TabAirlinesReport today={today} />
            </TabPane>
            <TabPane
              className="awb-tab-content-wrapper"
              tab={t("header.inventory")}
              key={"inventory"}
            >
              <TabAirlinesInven today={today} />
            </TabPane>
            <TabPane
              className="awb-tab-content-wrapper"
              tab={t("airlines.NoneDelivery")}
              key={"export-status"}
            >
              <TabAirlinesExpStatus />
            </TabPane>
            <TabPane
              className="awb-tab-content-wrapper"
              tab={t("airlines.exportCargo")}
              key={"export-inventory"}
            >
              <TabAirlinesExpInven />
            </TabPane>
            <TabPane
              className="awb-tab-content-wrapper"
              tab={t("admin.esid.expInfo")}
              key={"airlines-esid-list"}>
                <>
                  <AirlinesEsidList />
                </>
            </TabPane>
            <TabPane
              className="awb-tab-content-wrapper"
              tab={t("header.schedule")}
              key={"schedule"}
            >
              <TabCutOffTime />
            </TabPane>
            <TabPane
              className="awb-tab-content-wrapper"
              tab={t("header.changePass")}
              key={"changePass"}
            >
              <ChangePasswordTab />
            </TabPane>
          </Tabs>
        </Col>
      </Row>
    </Row>
  );
};

export default Airlines;
