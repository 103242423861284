import axiosInstance from "./AuthIntercept";
import * as Constant from "./Constant";

export function getCaptcha() {
  return axiosInstance.get(`${Constant.DOMAIN_CLIENT}captcha`);
}

export async function getMenu(langId) {
  return await axiosInstance.post(`${Constant.DOMAIN_CLIENT}getMenu`, {
    id_menutype: 93,
    lang_id: langId,
  });
}

export function getMenuById(id) {
  return axiosInstance.get(`${Constant.DOMAIN_CLIENT}getMenuById/${id}`);
}
export async function getListMenuDetail(langId, menuId) {
  return await axiosInstance.post(`${Constant.DOMAIN_CLIENT}getListMenuDetail`, {
    lang_id: langId,
    menuId: menuId,
  });
}
export async function getMenuBySlug(slug) {
  return await axiosInstance.get(`${Constant.DOMAIN_CLIENT}getMenuBySlug/${slug}`);
}
export async function get(content) {
  return await axiosInstance.get(`${Constant.DOMAIN_CLIENT}${content}`);
}
export async function getContent(contentName, langId) {
  return await axiosInstance.get(
    `${Constant.DOMAIN_CLIENT}get${contentName}/${langId}`
  );
}
export async function getContentById(Id) {
  return await axiosInstance.get(`${Constant.DOMAIN_CLIENT}getContentById/${Id}`);
}

export async function getContentCustomerNew(langId, menuId) {
  return await axiosInstance.post(`${Constant.DOMAIN_CLIENT}getContentCustomerNew`, {
    lang_id: langId,
    menuId: menuId,
  });
}
export async function getHomeNews(contentName, langId) {
  return await axiosInstance.get(`${Constant.DOMAIN_CLIENT}${contentName}/${langId}`);
}

export async function getPost(contentId) {
  return await axiosInstance.get(
    `${Constant.DOMAIN_CLIENT}getContentById/${contentId}`
  );
}

export async function getTypeUpload() {
  return await axiosInstance.get(`${Constant.DOMAIN_CLIENT}getTypeFileUpload`);
}

export async function getYearUpload() {
  return await axiosInstance.get(`${Constant.DOMAIN_CLIENT}getYearFileUpload`);
}

export async function downloadFileUpload(filename) {
  return (window.location.href = `${Constant.DOMAIN_CLIENT}downloadFile/${filename}`);
}

export async function login(username, password, captchaId, captchaCode) {
  return await axiosInstance.post(`${Constant.DOMAIN_CLIENT}AwbUser/login`, {
    password: password,
    username: username,
    captcha: {
      id: captchaId,
      code: captchaCode
    }
  });
}

export async function getNameFileUpload(year, type) {
  return await axiosInstance.post(`${Constant.DOMAIN_CLIENT}getNameFileUpload`, {
    year: year,
    type: type,
  });
}

export async function changePasswordUserAwb(password, passwordOld, token) {
  return await axiosInstance.put(
    `${Constant.DOMAIN_CLIENT}AwbUser/changePassword`,
    {
      password: password,
      passwordOld: passwordOld,
    },
    {
      headers: {
        Authorization: token,
      },
    }
  );
}

export async function createQR(
  VEHICE_NUM,
  DRIVER_NAME,
  CONTACT_NUM,
  DRIVER_ID,
  AWB_NO,
  COD_HWB_NUM,
  PCS,
  WGT,
  DAT_ISSUES,
  ARRIVAL_TIME,
  DAT_FLT_ORI,
  COD_FLT_CAR,
  COD_FLT_NUM,
  ACCP_REFERENCE_ID
) {
  return await axiosInstance.post(
    `${Constant.DOMAIN_CLIENT}PDA_EXP_PRE_ACCP_SAVE_OBJ_WEB?STR_CONNECT=sss`,
    {
      VEHICE_NUM: VEHICE_NUM,
      DRIVER_NAME: DRIVER_NAME,
      CONTACT_NUM: CONTACT_NUM,
      DRIVER_ID: DRIVER_ID,
      AWB_NO: AWB_NO,
      COD_HWB_NUM: COD_HWB_NUM,
      PCS: PCS,
      WGT: WGT,
      DAT_FLT_ORI: DAT_FLT_ORI,
      COD_FLT_CAR,
      COD_FLT_NUM,
      DAT_ISSUES: DAT_ISSUES,
      ISSUE_USER: "NAMVV",
      ARRIVAL_TIME: ARRIVAL_TIME,
      TCS_GATE: "00",
      COD_SVC_TYP: "TCS",
      ACCP_REFERENCE_ID
    }
  );
}
export async function logout(token) {
  return await axiosInstance.post(
    `${Constant.DOMAIN_CLIENT}AwbUser/logout`,
    {},
    {
      headers: {
        Authorization: typeof token === 'string' ? token : token.data.token,
      },
    }
  );
}

export const IMP_WEB_CHK_INS = (newObj, token) =>
  axiosInstance.post(
    `${Constant.DOMAIN_CLIENT}insertPayment`,
    {
      ...newObj,
    },
    {
      headers: {
        Authorization: token,
      },
    }
  );
export const IMP_WEB_CHK_UPD = (newObj, token) =>
  axiosInstance.put(
    `${Constant.DOMAIN_CLIENT}updatePayment`,
    {
      ...newObj,
    },
    {
      headers: {
        Authorization: token,
      },
    }
  );
export const IMP_WEB_CHK_LOAD = (obj, token) =>
  axiosInstance.post(
    `${Constant.DOMAIN_CLIENT}chkLoad`,
    {
      ...obj,
    },
    {
      headers: {
        Authorization: token,
      },
    }
  );
export const IMP_WEB_CHK_LOAD_NY_HAWB_FEE_LST = (obj, token) =>
  axiosInstance.post(
    `${Constant.DOMAIN_CLIENT}hawbFeeLst`,
    {
      ...obj,
    },
    {
      headers: {
        Authorization: token,
      },
    }
  );
export const IMP_WEB_CHK_LOAD_APT_AGT = (
  COD_AWB_PFX,
  COD_AWB_NUM,
  COD_AWB_SFX,
  COD_HWB_NUM,
  token
) =>
  axiosInstance.post(
    `${Constant.DOMAIN_CLIENT}loadAptAgt`,
    {
      COD_AWB_PFX,
      COD_AWB_NUM,
      COD_AWB_SFX,
      COD_HWB_NUM,
    },
    {
      headers: {
        Authorization: token,
      },
    }
  );
export const IMP_WEB_CHK_UPD_APT_AGC = (obj, AGENT_CODE, AGENT_NAME, token) =>
  axiosInstance.post(
    `${Constant.DOMAIN_CLIENT}updateAgent`,
    {
      MAWB: { ...obj },
      AGENT_CODE,
      AGENT_NAME,
    },
    {
      headers: {
        Authorization: token,
      },
    }
  );
export const IMP_WEB_CHK_GET_AWB_DTL = (obj, token) =>
  axiosInstance.post(
    `${Constant.DOMAIN_CLIENT}getAwbDetail`,
    {
      ...obj,
    },
    {
      headers: {
        Authorization: token,
      },
    }
  );
/* export const IMP_WEB_CHK_CHECKVALID = (obj, token) =>
  axiosInstance.post(
    `${Constant.DOMAIN_TCS_CHECKOUT}IMP_WEB_CHK_CHECKVALID`,
    {
      ...obj,
    },
    {
      headers: {
        Authorization: token,
      },
    }
  );
export const IMP_WEB_CHK_CHECKVALID_RCV_ID = (obj, token) =>
  axiosInstance.post(
    `${Constant.DOMAIN_TCS_CHECKOUT}IMP_WEB_CHK_CHECKVALID_RCV_ID`,
    {
      ...obj,
    },
    {
      headers: {
        Authorization: token,
      },
    }
  ); */
export const IMP_WEB_CHK_CRE_CHK_OBJ = (obj, token) =>
  axiosInstance.post(
    `${Constant.DOMAIN_CLIENT}createCheckInObj`,
    {
      ...obj,
    },
    {
      headers: {
        Authorization: token,
      },
    }
  );
export const IMP_WEB_CHK_CRE_BIL_CHG_OBJ = (obj, token) =>
  axiosInstance.post(
    `${Constant.DOMAIN_CLIENT}createBillCharge`,
    {
      ...obj,
    },
    {
      headers: {
        Authorization: token,
      },
    }
  );
export const IMP_WEB_CHK_LOAD_BIL_CHG_OBJ = (obj, token) =>
  axiosInstance.post(
    `${Constant.DOMAIN_CLIENT}loadBillCharge`,
    {
      ...obj,
    },
    {
      headers: {
        Authorization: token,
      },
    }
  );
export const IMP_WEB_CHK_PAID_ALL_INVOICE = (obj, token) =>
  axiosInstance.post(
    `${Constant.DOMAIN_CLIENT}payment`,
    {
      ...obj,
    },
    {
      headers: {
        Authorization: token,
      },
    }
  );
export const IMP_WEB_CHK_PAID_CDT_INVOICE = (obj, token) =>
  axiosInstance.post(
    `${Constant.DOMAIN_CLIENT}PaidCDTInvoice`,
    {
      ...obj,
    },
    {
      headers: {
        Authorization: token,
      },
    }
  );
export const signIn = (username, password, captchaId, captchaCode) =>
  axiosInstance.post(`${Constant.DOMAIN_CLIENT}loginPayment`, {
    username,
    password,
    captcha: {
      id: captchaId,
      code: captchaCode
    }
  });
export const AWB_MST_LoadByAgent = (queryString, token) =>
  axiosInstance.post(
    `${Constant.DOMAIN_CLIENT}loadByAgent`,
    { queryString },
    {
      headers: {
        Authorization: token,
      },
    }
  );
export const IMP_WEB_CHK_LOAD_LST = (
  COD_AWB_PFX,
  COD_AWB_NUM,
  COD_AWB_SFX,
  COD_HWB_NUM,
  token
) =>
  axiosInstance.post(
    `${Constant.DOMAIN_CLIENT}loadLst`,
    {
      COD_AWB_PFX,
      COD_AWB_NUM,
      COD_AWB_SFX,
      COD_HWB_NUM,
    },
    {
      headers: {
        Authorization: token,
      },
    }
  );
export const IMP_WEB_CHK_CRE_HAWB_FEE_OBJ = (obj, token) =>
  axiosInstance.post(
    `${Constant.DOMAIN_CLIENT}hawbFeeObj`,
    {
      ...obj,
    },
    {
      headers: {
        Authorization: token,
      },
    }
  );
export const IMP_WEB_CHK_ISSUE_E_INVOICE = (obj, token) =>
  axiosInstance.post(
    `${Constant.DOMAIN_CLIENT}issueEInvoice`,
    {
      ...obj,
    },
    {
      headers: {
        Authorization: token,
      },
    }
  );
export const IMP_WEB_CHK_UPD_PAYMENT_MODE = (obj, token) =>
  axiosInstance.post(
    `${Constant.DOMAIN_CLIENT}updatePaymentMode`,
    {
      ...obj,
    },
    {
      headers: {
        Authorization: token,
      },
    }
  );

export const IMP_WEB_CHK_CHK_PAID = (obj, token) =>
  axiosInstance.post(
    `${Constant.DOMAIN_CLIENT}chkPaid`,
    {
      ...obj,
    },
    {
      headers: {
        Authorization: token,
      },
    }
  );
export const IMP_WEB_CHK_GET_ACC = (
  COD_AWB_PFX,
  COD_AWB_NUM,
  COD_AWB_SFX,
  COD_HWB_NUM,
  DLV_ODR_RCV_ID,
  DLV_ODR_RCV_NAM,
  token
) =>
  axiosInstance.post(
    `${Constant.DOMAIN_CLIENT}IMP_WEB_CHK_GET_ACC`,
    {
      COD_AWB_PFX,
      COD_AWB_NUM,
      COD_AWB_SFX,
      COD_HWB_NUM,
      DLV_ODR_RCV_ID,
      DLV_ODR_RCV_NAM,
    },
    {
      headers: {
        Authorization: token,
      },
    }
  );
export const CHECK_MAWB = (AWB_PFX, AWB_NO, captchaId, captchaCode) =>
  axiosInstance.post(`${Constant.DOMAIN_CLIENT}CHECK_MAWB`, {
    AWB_PFX,
    AWB_NO,
    captcha: {
      id: captchaId,
      code: captchaCode
    }
  });
export const updateOrderId = (obj, token) =>
  axiosInstance.post(
    `${Constant.DOMAIN_CLIENT}updateOrderId`,
    {
      ...obj,
    },
    {
      headers: {
        Authorization: token,
      },
    }
  );
