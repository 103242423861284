/* eslint-disable */

import React, { useEffect } from "react";
import { Row, Col } from "antd";
import "./page-css/MAWBInfo.css";
import Banner from "../components/Banner";

import TabInfo from "../components/TabInfo";
import { useDispatch } from "react-redux";
import { showLangSwitch } from "../actions/menu";

const MAWBInfo = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(showLangSwitch(true));
  }, []);
  return (
    <Row className="contacts-wrapper">
      <Banner
        title={""}
        className="banner-wrapper"
        cover={"../../img/004.jpg"}
      />
      <Row className="contact-info">
        <Col offset={3} span={19}>
          <TabInfo />
        </Col>
      </Row>
    </Row>
  );
};

export default MAWBInfo;
