import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setEsidExpSelected, setExpBilChgLst } from '../../../../../actions/esidExportAction';
import { EXP_WEB_CHK_CHK_PAID, EsidIssueEInvoice, EsidLoadBilCharge, ExpWebCheckLoadLst } from '../../../../../config/esidExportApi';
import { Button, Form, Spin, Table } from 'antd';
import { useTranslation } from 'react-i18next';
import { formatCurrency } from '../../../../../utils/utils';
import moment from 'moment';
import { BillingAddrForm } from '../../../../../components/EsidCheckout/InvoiceInfo/BillingAddrForm';
import { InvoiceTable } from '../../../../../components/EsidCheckout/InvoiceInfo/InvoiceTable';
import { InvoiceDetailTable } from '../../../../../components/EsidCheckout/InvoiceInfo/InvoiceDetailTable';
import { objectToAntFormData } from '../../../../../components/tracking/CertLivAnm/TabExpCertLivAnm';
import { GetEsidExportById } from '../../../../../config/nvesidExportApi';
import RoleClaim from '../../../../../constant/roleClaim';
import useHasRoles from '../../../../../hooks/useHasRoles';

export const ExpPaymentInfo = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation("common");
  const esidExpSelected = useSelector((state) => state.esidExport.esidExpSelected);
  const bilChgLst = useSelector((state) => state.esidExport.bilChgLst);
  const [loadingData, setLoadingData] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [billForm] = Form.useForm();
  const [invoices, setInvoices] = useState();
  const [invoicesDetail, setInvoicesDetail] = useState();
  const [selectedWebChkRows, setSelectedRows] = useState([]);

  const hasAccountant = useHasRoles([RoleClaim.EsidAdmin, RoleClaim.EsidAccountant]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!esidExpSelected) {
          return;
        }
        setLoadingData(true);
        const chkLoadRes = await ExpWebCheckLoadLst({
          COD_AWB_PFX: esidExpSelected.codAwbPfx,
          COD_AWB_NUM: esidExpSelected.codAwbNum,
          COD_AWB_SFX: esidExpSelected.codAwbSfx,
          COD_HWB_NUM: esidExpSelected.codHwb ?? '',
        });
        if (chkLoadRes?.data?.mCol?.length >= 1) {
          dispatch(setExpBilChgLst(chkLoadRes.data.mCol));
        }
        setLoadingData(false);
      } catch (error) {
        console.log(error);
        setLoadingData(false);
      }
    };
    fetchData();
    // eslint-disable-next-line
  }, [esidExpSelected]);

  const columns = [
    {
      title: 'STT',
      dataIndex: 'key',
      key: 'key',
    },
    {
      title: 'Trạng thái',
      dataIndex: 'COD_STS',
      key: 'COD_STS',
      render: (_, record) => t(`esid.codSts.${record.COD_STS}`),
      align: 'left',
    },
    {
      title: 'Không vận đơn',
      dataIndex: 'COD_AWB_PFX',
      key: 'COD_AWB_PFX',
      colSpan: 2,
    },
    {
      title: '',
      dataIndex: 'COD_AWB_NUM',
      key: 'COD_AWB_NUM',
      colSpan: 0,
    },
    {
      title: 'Thanh toán bởi',
      dataIndex: 'CNE_CODE',
      key: 'CNE_CODE',
      colSpan: 2,
    },
    {
      title: 'Tên',
      dataIndex: 'CNE_NAME',
      key: 'CNE_NAME',
      colSpan: 0,
      align: 'left',
    },
    {
      title: 'PTTT',
      dataIndex: 'COD_PAY_MOD',
      key: 'COD_PAY_MOD',
    },
    {
      title: 'Tổng tiền',
      dataIndex: 'AMT_TOT',
      key: 'AMT_TOT',
      render: (_, record) => formatCurrency(record.AMT_TOT),
      align: 'right',
    },
    {
      title: 'Họ tên người gửi hàng',
      dataIndex: 'DLV_ODR_RCV_NAM',
      key: 'DLV_ODR_RCV_NAM',
    },
    {
      title: 'CCCD',
      dataIndex: 'DLV_ODR_RCV_ID',
      key: 'DLV_ODR_RCV_ID',
    },
    {
      title: 'Ngày lập',
      dataIndex: 'DAT_CRE',
      key: 'DAT_CRE',
      render: (_, record) => moment(record.DAT_CRE).format('DD/MM/YYYY HH:mm'),
    },
  ];

  const onSelectChange = async (selectedRowKeys, selectedRows) => {
    setSelectedRowKeys(selectedRowKeys);
    setSelectedRows(selectedRows);
    console.log(selectedRows)
    try {
      setLoadingData(true);
      const billChrRes = await EsidLoadBilCharge(selectedRows[0]);
      if (billChrRes.data) {
        setInvoices(billChrRes.data.P_RET_BIL_CHG_COLL.mCol);
        setInvoicesDetail(billChrRes.data.P_RET_EXP_WEB_BIL_CHG_COLL.mCol);
        billForm.setFields(objectToAntFormData(billChrRes.data.P_RET_EXP_WEB_BIL_CHG_COLL.mCol[0]));
      }
      setLoadingData(false);
    } catch (error) {
      console.log(error);
      setLoadingData(false);
    }
  };

  const handleEInvoiceIssue = async () => {
    if (selectedWebChkRows.length > 0) {
      const webChk = selectedWebChkRows[0];
      if (webChk.COD_STS === 'AM') {
        await EXP_WEB_CHK_CHK_PAID(webChk);
      }
      if (webChk.COD_STS === 'AP') {
        await EsidIssueEInvoice(webChk);
      }
      try {
        const esidRes = await GetEsidExportById(webChk.ESID_ID);
        dispatch(setEsidExpSelected(esidRes.data));
      } catch (error) {
        console.log(error);
      }
    }
  }

  return (
    <Spin spinning={loadingData}>
      {selectedWebChkRows?.length > 0 && (
        <div style={{ textAlign: 'right', marginBottom: 10 }}>
          <Button disabled={!hasAccountant} type='danger' onClick={handleEInvoiceIssue}>Phát hành hóa đơn điện tử</Button>
        </div>
      )}
      <Table
        bordered
        className="table-payment"
        columns={columns}
        pagination={false}
        rowSelection={{
          selectedRowKeys,
          type: "radio",
          fixed: true,
          hideSelectAll: true,
          onChange: onSelectChange,
        }}
        dataSource={bilChgLst?.map((bil, idx) => ({ ...bil, key: idx + 1 }))}
        rowClassName='cursorPointer'
        onRow={(record) => ({
          onClick: () => onSelectChange([record.key], [record])  
        })}
      />
      {invoices &&
        <Spin spinning={loadingData}>
          <div style={{ padding: '20px'}}>
              <BillingAddrForm form={billForm} />
            <div style={{ marginTop: '10px' }}>
              <InvoiceTable invoices={invoices} />
              <InvoiceDetailTable invoiceDetails={invoicesDetail}/>
            </div>
          </div>
        </Spin>
      }
    </Spin>
  );
};
