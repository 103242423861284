/* eslint-disable */

import React, { useEffect, useState } from "react";
import { Tabs, Button, message, notification } from "antd";
import Banner from "../components/Banner";
import * as API from "../config/apiConfig";
import { userAwbLogout } from "../actions/userAWB";
import "./page-css/Agent.css";
import ChangePasswordTab from "../components/ChangePasswordTab";
import { useDispatch, useSelector } from "react-redux";
import TabCutOffTime from "../components/TabCutOffTime";
import TabInfo from "../components/TabInfo";
import TabAgentReport from "../components/tracking/agent/TabAgentReport";
import TabAgentInven from "../components/tracking/agent/TabAgentInven";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import { showLangSwitch } from "../actions/menu";
import { setMAWB } from "../actions/MAWB";
import TabHawbFee from "../components/TabHawbFee";

const { TabPane } = Tabs;
const Agent = () => {
    const history = useHistory();
    const token = JSON.parse(localStorage.getItem("userAWB"));
    const { dataLogin } = useSelector((state) => state.userAwb);
    const [activeKey, setActiveKey] = useState("import-info");
    const dispatch = useDispatch();
    const { t } = useTranslation("common");
    const today = new Date(),
        date =
            today.getFullYear() +
            "-" +
            (today.getMonth() + 1) +
            "-" +
            today.getDate() +
            " " +
            today.getHours() +
            ":" +
            today.getMinutes();

    useEffect(() => {
        dispatch(showLangSwitch(false));
        if (dataLogin.data.user_code.startsWith("TCS")) {
            notification.warning({
                message: "Tài khoản này không có quyền truy cập!",
                style: { marginRight: "-50%" },
            });
            history.push("/");
        }
    }, []);
    const logOut = () => {
        API.logout(token)
            .then((res) => {
                dispatch(userAwbLogout());
                dispatch(setMAWB({}));
                localStorage.removeItem("userAWB");
                localStorage.removeItem("MAWB");
                message.success(res.data.message);
                history.push("/");
            })
            .catch((err) => console.log(err));
    };
    const operations = (
        <Button
            className="logout-btn"
            onClick={logOut}
            style={{ width: "fit-content" }}
        >
            {t("header.logOut")}
        </Button>
    );

    const handleTabChange = (key) => {
        if (key === "esidExport") {
            history.push("/Esid/Export");
        } else {
            setActiveKey(key);
        }
    }

    return (
        <div className="agent-wrapper">
            <Banner
                title={""}
                className="banner-wrapper"
                cover={"../../img/004.jpg"}
            />
            <div className="agent-content-wrapper">
                <div style={{ padding: "0px 8%" }} className="agent-content">
                    <Tabs
                        tabBarExtraContent={operations}
                        tabBarGutter={15}
                        activeKey={activeKey}
                        onChange={handleTabChange}
                        size={"default"}
                    >
                        <TabPane
                            className="awb-tab-content-wrapper"
                            tab={t("header.exportInfo")}
                            key={"import-info"}
                        >
                            <TabInfo />
                        </TabPane>
                        <TabPane
                            className="awb-tab-content-wrapper"
                            tab={t("header.report")}
                            key={"report"}
                        >
                            <TabAgentReport setActiveKey={setActiveKey} />
                        </TabPane>
                        <TabPane
                            className="awb-tab-content-wrapper"
                            tab={t("header.inventory")}
                            key={"inventory"}
                        >
                            <TabAgentInven />
                        </TabPane>
                        <TabPane
                            className="awb-tab-content-wrapper"
                            tab={t("header.hawbFee")}
                            key={"hawb-fee"}
                        >
                            <TabHawbFee />
                        </TabPane>

                        <TabPane
                            className="awb-tab-content-wrapper"
                            tab={t("header.schedule")}
                            key={"schedule"}
                        >
                            <TabCutOffTime />
                        </TabPane>
                        {/* <TabPane
							className="awb-tab-content-wrapper"
							tab={t('header.registryInventory')}
							key={'registry-inventory'}
						>
							<TabRegistryInventory today={today} />
						</TabPane> */}
                        <TabPane
                            className="awb-tab-content-wrapper"
                            tab={t("header.changePass")}
                            key={"changePass"}
                        >
                            <ChangePasswordTab />
                        </TabPane>
                        <TabPane
                            className="awb-tab-content-wrapper"
                            tab={t("home.esidExport")}
                            key={"esidExport"}
                        >
                            <div>ESID</div>
                        </TabPane>
                    </Tabs>
                </div>
            </div>
        </div>
    );
};

export default Agent;
