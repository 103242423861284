import { SET_LIV_ANMS, SET_LIVANMS_SELECTED_VALUE, SET_ESID_LOADING, SET_DISABLE_EDIT_LIV_ANM_FORM_DECL, SET_VISABLE_EDIT_LIV_ANM_BUTTON, SET_LIV_SELECTED_TO_EDIT } from "../constant/actionTypes";

export const setLivAnms = (livAnms) => {
  return {
    type: SET_LIV_ANMS,
    payload: livAnms
  }
}
export const setEsidLoading = (value) => {
  return {
    type: SET_ESID_LOADING,
    payload: value
  }
}
export const setLivAnmsSelectedValue = (value) => {
  return {
    type: SET_LIVANMS_SELECTED_VALUE,
    payload: value
  }
}
export const setDisableEditLivAnmsFormDecl = (value) => {
  return {
    type: SET_DISABLE_EDIT_LIV_ANM_FORM_DECL,
    payload: value
  }
}
export const setIsVisableEditLivAnmButton = (value) => {
  return {
    type: SET_VISABLE_EDIT_LIV_ANM_BUTTON,
    payload: value
  }
}
export const setLivAnmSelectedToEdit = (value) => {
  return {
    type: SET_LIV_SELECTED_TO_EDIT,
    payload: value
  }
}