import React from 'react'
import { Table, Space, Tooltip, Button } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { useTranslation } from "react-i18next";
// import { useSelector } from 'react-redux';
// import { EsidStatusEnum } from '../../../../../../constant/enumerations';
import { DimensionsTableHead } from './DimensionsTableHead';

export const DimensionsTable = ({ dataSource, handleEdit, handleDelete, setSizeEditingKey, setSizeModalVisible, addSizeForm, isEsidExpFinalized, openDmsPrint }) => {
    const { t } = useTranslation("common");
    const defaultColumns = [
      {
        title: t("admin.acceptance.sizeNo"),
        dataIndex: 'codSeqNum',
        key: 'codSeqNum',
        width: !dataSource || dataSource.length === 0 ? '12%' : '10%',
        render: (_, record, index) => index + 1
      },
      {
        title: t("admin.acceptance.lengthHolder"),
        dataIndex: 'qtyDimLen',
        key: 'qtyDimLen',
        editable: true,
        width: !dataSource || dataSource.length === 0 ? '18%' : '15%'
      },
      {
        title: t("admin.acceptance.wideHolder"),
        dataIndex: 'qtyDimWid',
        key: 'qtyDimWid',
        editable: true,
        width: !dataSource || dataSource.length === 0 ? '18%' : '15%'
      },
      {
        title: t("admin.acceptance.heightHolder"),
        dataIndex: 'qtyDimHgt',
        key: 'qtyDimHgt',
        editable: true,
        width: !dataSource || dataSource.length === 0 ? '18%' : '15%'
      },
      {
        title: t("admin.acceptance.qtyHolder"),
        dataIndex: 'qtyDimPcs',
        key: 'qtyDimPcs',
        editable: true,
        width: !dataSource || dataSource.length === 0 ? '18%' : '15%'
      },
      {
        title: t("admin.acceptance.volumeHolder"),
        dataIndex: 'qtyDimWgt',
        key: 'qtyDimWgt',
        editable: true,
        width: !dataSource || dataSource.length === 0 ? '18%' : '15%',
      },
      {
        dataIndex: 'action',
        width: '15%',
        fixed: 'right',
        hidden: !dataSource || dataSource.length === 0 || isEsidExpFinalized,
        render: (_, record) => {
          return (
            dataSource.length >= 1 && !isEsidExpFinalized ? (
              <Space>
                <Tooltip title={t("dangerousGood.tableInfo.btnEdit")}>
                  <Button type='text' onClick={() => handleEdit(record)} icon={<EditOutlined />} />
                </Tooltip>
                <Tooltip title={t("dangerousGood.tableInfo.btnDelete")}>
                    <Button type='text' onClick={() => handleDelete(record)} danger icon={<DeleteOutlined />} />
                </Tooltip>
              </Space>
            ) : null
          )
        },
      },
    ];
    return (
        <Table 
            bordered
            key='uuid'
            rowKey='uuid'
            dataSource={dataSource.sort((a,b) => a.codSeqNum - b.codSeqNum)}
            scroll={{x: 300}}
            columns={defaultColumns.filter(col => !col.hidden)}
            pagination={false}
            title={() => (<DimensionsTableHead openDmsPrint={openDmsPrint} disabled={isEsidExpFinalized} setSizeEditingKey={setSizeEditingKey} setSizeModalVisible={setSizeModalVisible} addSizeForm={addSizeForm} />)}
            style={{ marginTop: 10, padding: '2px 5px' }}
        />
  )
}
