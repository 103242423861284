/* eslint-disable */

import React, { useEffect, useState } from "react";
import {
  Col,
  Row,
  Table,
  Form,
  Input,
  Modal,
  notification,
  Button,
  Spin,
  Skeleton,
} from "antd";
import "./css/awb.css";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "antd/lib/form/Form";
import { IMP_WEB_CHK_ISSUE_E_INVOICE } from "../../config/apiConfig";
import ModalSelectMethod from "./TabInvoiceInfo/ModalSelectMethod";
import { IMP_WEB_CHK_PAID_CDT_INVOICE } from "./../../config/apiConfig";
import { checkinUser, userAwbLogout } from "../../actions/userAWB";
import { setPaymentInfo } from "../../actions/payment";
import { useHistory } from "react-router";
import moment from "moment";
const { warning } = Modal;

export default function InvoiceInfo({
  info,
  loadData,
  invoiceInfo,
  chargeInfo,
  setActiveKey,
}) {
  const invoiceColumns = [
    {
      title: "Invoice No",
      dataIndex: "INV_NO",
      key: "INV_NO",
      width: "5%",
      render: (text, record) => <a>{text}</a>,
    },
    {
      title: "Serial No",
      dataIndex: "INV_SRL_NO",
      key: "INV_SRL_NO",
      width: "17%",
      render: (text, record) => <a>{text}</a>,
    },
    {
      title: "Total Gross Amt",
      dataIndex: "AMT_NET",
      key: "AMT_NET",
      width: "16%",
      className: "awb-table-currency",
    },
    {
      title: "Total Vat Amt",
      dataIndex: "AMT_TAX",
      key: "AMT_TAX",
      width: "16%",
      className: "awb-table-currency",
    },
    {
      title: "Total Amt",
      dataIndex: "AMT_TOTAL",
      key: "AMT_TOTAL",
      width: "15%",
      className: "awb-table-currency",
    },
    {
      title: "Remarks",
      dataIndex: "REMARKS",
      key: "REMARKS",
      width: "13%",
    },
    {
      title: "Print Flag",
      dataIndex: "PrintFlag",
      key: "PrintFlag",
      width: "10%",
    },
  ];
  const invoiceDetailColumns = [
    {
      title: "AWB/HWB No",
      dataIndex: "AWB",
      key: "AWB",
      window: "20%",
    },
    {
      title: "Charge Code",
      dataIndex: "COD_CHG",
      key: "COD_CHG",
      width: "17%",
    },
    {
      title: "Qty",
      dataIndex: "QTY",
      key: "QTY",
    },
    {
      title: "Gross Amt",
      dataIndex: "AMT_GROSS",
      key: "AMT_GROSS",
      className: "awb-table-currency",
    },
    {
      title: "VAT Amt",
      dataIndex: "AMT_TAX",
      key: "AMT_TAX",
      className: "awb-table-currency",
      width: "15%",
    },
    {
      title: "Total Amt",
      dataIndex: "TOTAL_AMT",
      key: "TOTAL_AMT",
      className: "awb-table-currency",
      width: "15%",
    },
    {
      title: "Payment Mode",
      dataIndex: "COD_PAY_MOD",
      key: "COD_PAY_MOD",
      width: "10%",
    },
  ];
  const [loading, setLoading] = useState(false);
  const [hasData, setHasData] = useState(false);
  const [visible, setVisible] = useState(false);
  const [invoiceDataSource, setInvoiceDataSource] = useState();
  const dispatch = useDispatch();
  const [invoiceDetailDataSource, setInvoiceDetailDataSource] = useState();
  const { MAWB } = useSelector((state) => state.mawb);
  const { token } = useSelector((state) => state.userAwb.dataCheckin);
  const [form] = useForm();
  const history = useHistory();
  const [rowId, setRowId] = useState(0);
  const [rowInvoiceNo, setRowInvoiceNo] = useState(
    chargeInfo[0] && chargeInfo[0].INV_NO
  );
  const [total, setTotal] = useState(0);
  const [totalGross, setTotalGross] = useState(0);
  const [totalTax, setTotalTax] = useState(0);
  const [totalInv, setTotalInv] = useState(0);
  const [totalGrossInv, setTotalGrossInv] = useState(0);
  const [totalTaxInv, setTotalTaxInv] = useState(0);
  const showData = async () => {
    if (info !== undefined) {
      try {
        setLoading(true);
        let data = [];
        let totalInv = 0;
        let totalGrossInv = 0;
        let totalTaxInv = 0;
        //eslint-disable
        invoiceInfo.map((dt, index) => {
          data.push({
            key: index,
            INV_NO: dt.INV_NO,
            INV_SRL_NO: dt.INV_SRL_NO,
            AMT_TOTAL: dt.AMT_TOTAL.toString().replace(
              /\B(?=(\d{3})+(?!\d))/g,
              ","
            ),
            AMT_TAX: dt.AMT_TAX.toString().replace(
              /\B(?=(\d{3})+(?!\d))/g,
              ","
            ),
            AMT_NET: dt.AMT_NET.toString().replace(
              /\B(?=(\d{3})+(?!\d))/g,
              ","
            ),
            REMARKS: dt.REMARKS,
          });
          totalInv += dt.AMT_TOTAL;
          totalGrossInv += dt.AMT_NET;
          totalTaxInv += dt.AMT_TAX;
        });
        setTotalInv(totalInv.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));

        setTotalGrossInv(
          totalGrossInv.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        );
        setTotalTaxInv(
          totalTaxInv.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        );
        setInvoiceDataSource(data);
        setLoading(false);
        //eslint-enable
      } catch (err) {
        notification.error({ message: "Lỗi", style: { marginRight: "-50%" } });
      }
    }
  };
  const handleRowSelect = (record) => {
    return {
      onClick: () => {
        setRowId(record.key);
        setRowInvoiceNo(record.INV_NO);
      },
    };
  };
  const showDataRow = () => {
    if (rowId !== undefined) {
      if (chargeInfo !== undefined) {
        let data = [];
        setLoading(true);
        let total = 0;
        let totalGross = 0;
        let totalTax = 0;
        //eslint-disable-next-line
        chargeInfo.map((dt) => {
          if (dt.INV_NO === rowInvoiceNo) {
            data.push({
              AWB:
                MAWB.hawb !== ""
                  ? MAWB.first + MAWB.last + MAWB.suffix + "/" + MAWB.hawb
                  : MAWB.first + MAWB.last + MAWB.suffix,
              COD_CHG: dt.COD_CHG,
              QTY: dt.QTY,
              AMT_GROSS: dt.AMT_GROSS.toString().replace(
                /\B(?=(\d{3})+(?!\d))/g,
                ","
              ),
              WAIVE_AMT: dt.WAIVE_AMT.toString().replace(
                /\B(?=(\d{3})+(?!\d))/g,
                ","
              ),
              AMT_TAX: dt.AMT_TAX.toString().replace(
                /\B(?=(\d{3})+(?!\d))/g,
                ","
              ),
              TOTAL_AMT: (dt.AMT_GROSS + dt.WAIVE_AMT + dt.AMT_TAX)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
              COD_PAY_MOD: dt.COD_PAY_MOD,
            });
            form.setFieldsValue({
              name: dt.NAM_VTN,
              address: dt.DES_ADD_FULL,
              place: dt.DES_ADD_PLC,
              pos: dt.DES_ADD_POS,
              city: dt.NAM_CTY,
              country: dt.NAM_COU,
              tax: dt.COD_TAX_CMP,
            });
            total += dt.AMT_GROSS + dt.WAIVE_AMT + dt.AMT_TAX;
            totalGross += dt.AMT_GROSS;
            totalTax += dt.AMT_TAX;
          }
        });
        setTotal(total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));

        setTotalGross(
          totalGross.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        );
        setTotalTax(totalTax.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));
        setInvoiceDetailDataSource(data);
        setLoading(false);
      }
    }
  };
  const nextTab = () => {
    if (info.COD_STS !== "FN" && info.COD_STS !== "OK")
      warning({
        title: "Thông báo",
        icon: <ExclamationCircleOutlined />,
        content: "Bạn cần phải thanh toán để tiếp tục!",
      });
    else setActiveKey("eInvoice-info");
  };
  const prevTab = () => {
    setActiveKey("awb");
  };
  const checkOut = async () => {
    if (moment(info.DAT_DLV).diff(moment(), "days") < 0)
      notification.warning({
        message: `Lô hàng đã được đăng ký nhận hàng ngày ${moment(
          info.DAT_DLV
        ).format("DDMMMYYYY HH:mm")} , vui lòng liên hệ quầy để tính lại phí`,
        style: { marginRight: "-50%" },
      });
    else {
      try {
        setLoading(true);
        if (info.AMT_CSH_INV === 0 && info.AMT_CDT_INV > 0) {
          const checkPaidCdtRes = await IMP_WEB_CHK_PAID_CDT_INVOICE(
            {
              ...info,
            },
            token
          );
          await loadData();
          if (
            checkPaidCdtRes.data.P_CURR_OBJ_CHK.COD_STS === "FN" ||
            checkPaidCdtRes.data.P_CURR_OBJ_CHK.COD_STS === "OK" ||
            checkPaidCdtRes.data.P_CURR_OBJ_CHK.COD_STS === "AP"
          ) {
            notification.success({
              message: "Đã hoàn tất làm thủ tục cho lô hàng này",
              style: { marginRight: "-50%" },
            });
          }
        }
        if (
          /* info.AMT_CDT_INV === 0 && */
          info.AMT_CSH_INV > 0 /*  && */
          /*  info.AMT_PRO_INV === 0 */
        )
          setVisible(true);
        setLoading(false);
      } catch (error) {
        if (error.response.status === 401) {
          notification.error({
            style: { marginRight: "-50%" },
            message: t("home.sessionNoti"),
          });

          dispatch(checkinUser({}));
          dispatch(setPaymentInfo(undefined));
          dispatch(userAwbLogout());
          localStorage.removeItem("userAWB");
          localStorage.removeItem("checkin");
          localStorage.removeItem("pInfo");

          history.push("/");
        } else
          notification.error({
            message: "Lỗi",
            style: { marginRight: "-50%" },
          });
      }
    }
  };
  const issueEInvoice = async () => {
    if (info.COD_STS === "AP") {
      try {
        await IMP_WEB_CHK_ISSUE_E_INVOICE(info, token);
        loadData();
        if (info.COD_STS === "AP") {
          notification.error({
            message: "Vui lòng thử lại sau!",
            style: { marginRight: "-50%" },
          });
        }
        if (info.COD_STS === "FN") nextTab();
      } catch (error) {
        if (error.response.status === 401) {
          notification.error({
            style: { marginRight: "-50%" },
            message: t("home.sessionNoti"),
          });

          dispatch(checkinUser({}));
          dispatch(setPaymentInfo(undefined));
          dispatch(userAwbLogout());
          localStorage.removeItem("userAWB");
          localStorage.removeItem("checkin");
          localStorage.removeItem("pInfo");
          history.push("/");
        } else
          notification.error({
            message: "Lỗi",
            style: { marginRight: "-50%" },
          });
      }
    }
  };

  useEffect(() => {
    showDataRow();
  }, [rowId]);
  useEffect(() => {
    showData();
  }, [info]);
  useEffect(() => {
    showDataRow();
  }, []);

  return (
    <Skeleton loading={hasData} active={hasData}>
      <ModalSelectMethod
        data={info}
        visible={visible}
        setVisible={setVisible}
        loadData={loadData}
        setLoading={setLoading}
        setHasData={setHasData}
        loading={loading}
        isHawb={false}
      />
      {!hasData && (
        <>
          <div>
            <Row>
              <Col span={"auto"}>
                <h2>Address Details</h2>
              </Col>
              <Col
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
                flex="auto"
              >
                <Button
                  className="btn-payment"
                  style={{ width: "fit-content", marginRight: "5px" }}
                  onClick={prevTab}
                >
                  {/* {t("header.submit")} */} Quay lại
                </Button>
                <Button
                  className="btn-payment"
                  style={{ width: "fit-content", marginRight: "5px" }}
                  onClick={checkOut}
                  disabled={["AP", "FN", "XX", "OK"].includes(info.COD_STS)}
                  loading={loading}
                >
                  {/* {t("header.submit")} */} Thanh toán
                </Button>
                <Button
                  className="btn-payment"
                  style={{ width: "fit-content", marginRight: "5px" }}
                  onClick={issueEInvoice}
                  disabled={info.COD_STS !== "AP"}
                >
                  {/* {t("header.submit")} */} Xuất hóa đơn điện tử
                </Button>

                <Button
                  className="btn-payment"
                  style={{ width: "fit-content" }}
                  onClick={nextTab}
                >
                  {/* {t("header.submit")} */} Tiếp tục
                </Button>
              </Col>
            </Row>
            <Form form={form} layout="vertical" className="form-invoice-info">
              <Row gutter={[5, 0]}>
                <Col span={24} md={12} xl={12}>
                  <Form.Item name="name" label="Name">
                    <Input readOnly />
                  </Form.Item>
                </Col>
                <Col span={24} md={12} xl={12}>
                  <Form.Item name="address" label="Full address">
                    <Input readOnly />
                  </Form.Item>
                </Col>
              </Row>
              <Row style={{ marginTop: "-10px" }} gutter={[5, 0]}>
                <Col span={6} md={4}>
                  <Form.Item name="place" label="Place">
                    <Input readOnly />
                  </Form.Item>
                </Col>
                <Col span={6} md={4}>
                  <Form.Item name="pos" label="P. Code">
                    <Input readOnly />
                  </Form.Item>
                </Col>
                <Col span={6} md={4}>
                  <Form.Item name="city" label="City">
                    <Input readOnly />
                  </Form.Item>
                </Col>
                <Col span={6} md={4}>
                  <Form.Item name="country" label="Country">
                    <Input readOnly />
                  </Form.Item>
                </Col>
                <Col span={24} md={4}>
                  <Form.Item name="tax" label="TAX code">
                    <Input readOnly />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </div>
          <div style={{ marginTop: "-25px" }}>
            <h2>Invoice</h2>
            <Row>
              <Col span={24}>
                <Table
                  loading={loading}
                  className="table-payment"
                  dataSource={invoiceDataSource}
                  columns={invoiceColumns}
                  onRow={handleRowSelect}
                  rowClassName={(record) => {
                    return record.key === rowId ? "row-selected" : "";
                  }}
                  pagination={false}
                  summary={() => {
                    return (
                      <>
                        <Table.Summary.Row>
                          <Table.Summary.Cell index={1} colSpan={2}>
                            Tổng:
                          </Table.Summary.Cell>
                          <Table.Summary.Cell
                            className="awb-table-currency"
                            index={1}
                          >
                            {totalGrossInv}
                          </Table.Summary.Cell>

                          <Table.Summary.Cell
                            className="awb-table-currency"
                            index={6}
                          >
                            {totalTaxInv}
                          </Table.Summary.Cell>
                          <Table.Summary.Cell
                            className="awb-table-currency"
                            index={0}
                            colSpan={1}
                          >
                            {totalInv}
                          </Table.Summary.Cell>
                          <Table.Summary.Cell
                            className="awb-table-currency"
                            index={0}
                            colSpan={2}
                          ></Table.Summary.Cell>
                        </Table.Summary.Row>
                      </>
                    );
                  }}
                />
              </Col>
            </Row>
          </div>
          <div>
            <h2>Invoice Details</h2>
            <Row>
              <Col span={24}>
                <Table
                  className="table-payment"
                  loading={loading}
                  dataSource={invoiceDetailDataSource}
                  columns={invoiceDetailColumns}
                  pagination={false}
                  summary={() => {
                    return (
                      <>
                        <Table.Summary.Row>
                          <Table.Summary.Cell index={1} colSpan={3}>
                            Tổng:
                          </Table.Summary.Cell>
                          <Table.Summary.Cell
                            className="awb-table-currency"
                            index={1}
                          >
                            {totalGross}
                          </Table.Summary.Cell>

                          <Table.Summary.Cell
                            className="awb-table-currency"
                            index={6}
                          >
                            {totalTax}
                          </Table.Summary.Cell>
                          <Table.Summary.Cell
                            className="awb-table-currency"
                            index={0}
                            colSpan={1}
                          >
                            {total}
                          </Table.Summary.Cell>
                          <Table.Summary.Cell
                            className="awb-table-currency"
                            index={0}
                            colSpan={1}
                          ></Table.Summary.Cell>
                        </Table.Summary.Row>
                      </>
                    );
                  }}
                />
              </Col>
            </Row>
          </div>
        </>
      )}
    </Skeleton>
  );
}
