import React from 'react'
import { Table, Typography } from 'antd'
import { useTranslation } from 'react-i18next';
import { formatCurrency } from '../../../utils/utils';
import { useSelector } from 'react-redux';

const { Text } = Typography;
export const InvoiceDetailTable = ({ invoiceDetails }) => {
    const { t } = useTranslation("common");
    const selectEsidExport = useSelector(state => state.esidExport.esidExpSelected);
    const invoiceDetailColumns = [
        {
          title: "AWB/HWB No",
          dataIndex: "AWB",
          key: "AWB",
          window: "20%",
          render: () => (<>{`${selectEsidExport.codAwbPfx}${selectEsidExport.codAwbNum}${selectEsidExport.codHwb ? `/${selectEsidExport.codHwb}` : ''}`}</>)
        },
        {
          title: "Charge Code",
          dataIndex: "COD_CHG",
          key: "COD_CHG",
          width: "17%",
        },
        {
          title: t("esidCheckout.invoiceDetail.qty"),
          dataIndex: "QTY",
          key: "QTY",
        },
        {
          title: t("esidCheckout.invoiceDetail.totalGrs"),
          dataIndex: "AMT_GROSS",
          key: "AMT_GROSS",
          render: (_, record) => (<>{formatCurrency(record.AMT_GROSS - record.WAIVE_AMT)}</>)
        },
        // {
        //   title: t("esidCheckout.invoice.totalWaive"),
        //   dataIndex: 'WAIVE_AMT',
        //   key: 'WAIVE_AMT',
        //   render: (_, record) => (<>{formatCurrency(record.WAIVE_AMT, record.COD_CUR)}</>)
        // },
        {
          title: t("esidCheckout.invoiceDetail.totalVat"),
          dataIndex: "AMT_TAX",
          key: "AMT_TAX",render: (_, record) => (<>{formatCurrency(record.AMT_TAX)}</>),
          width: "15%",
        },
        {
          title: t("esidCheckout.invoiceDetail.totalAmt"),
          dataIndex: "TOTAL_AMT",
          key: "TOTAL_AMT",
          render: (_, record) => (<>{formatCurrency(record.AMT_GROSS - record.WAIVE_AMT + record.AMT_TAX)}</>),
          width: "15%",
        },
        {
          title: t("esidCheckout.invoiceDetail.paymentMode"),
          dataIndex: "COD_PAY_MOD",
          key: "COD_PAY_MOD",
          width: "10%",
        },
      ];
  return (
    <Table 
        title={() => <Text strong>{t("esidCheckout.invoiceDetail.tableTitle")}</Text>}
        columns={invoiceDetailColumns}
        dataSource={invoiceDetails ?? []}
        pagination={false}
        bordered
        rowKey={"Key"}
        summary={(pageData) => {
            let totalGross = 0;
            // let totalWaive = 0;
            let totalVat = 0;
            let totalAmt = 0;
    
            pageData.forEach(({  AMT_TAX, AMT_GROSS, WAIVE_AMT }) => {
                totalGross += AMT_GROSS;
                // totalWaive += WAIVE_AMT;
                totalVat += AMT_TAX;
                totalAmt += AMT_GROSS - WAIVE_AMT + AMT_TAX;
            });
    
            return (
              <>
                <Table.Summary.Row style={{ backgroundColor: '#fafafa' }}>
                    <Table.Summary.Cell index={0} colSpan={3}>
                      <Text strong>
                        {t("esidCheckout.invoiceDetail.total")}
                      </Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={1} align='center'>
                        <Text strong>{formatCurrency(totalGross)}</Text>
                    </Table.Summary.Cell>
                    {/* <Table.Summary.Cell index={2} align='center'>
                        <Text strong>{formatCurrency(totalWaive)}</Text>
                    </Table.Summary.Cell> */}
                    <Table.Summary.Cell index={3} align='center'>
                        <Text strong>{formatCurrency(totalVat)}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={4} align='center'>
                        <Text strong>{formatCurrency(totalAmt)}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={5} colSpan={1}></Table.Summary.Cell>
                </Table.Summary.Row>
              </>
            );
        }}
    />
  )
}
