import React, { useEffect, useState } from 'react'
import { Space, Form, Input, Col, Row, Button, InputNumber, Modal, Checkbox } from 'antd';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { setDgrSelectedToEdit } from '../../../../actions/dgrGoodsAction';
import { addDgrType } from '../../../../constant/enumerations';

const placeHolders = {
    COD_UID: 'eg: 1234',
    SHIPPING_NAME: 'eg: Printing Ink',
    CLASS_DIVISION: 'eg: 3',
    PACKING_GROUP: 'eg: I',
    QUANTITY_AND_TYPE: 'eg: 1 Fibreboard box x 5.0L',
    PACKING_INST: 'eg: 353',
    AUTHORIZATION: 'eg: My Full Name',
    PIECES: 'eg: 3',
}

export const AddDangerousGoodsModal = ({ modalVisible, handleAdd, handleUpdate,setModalVisible, form, typeAdd }) => {
    const { t } = useTranslation("common");
    const [confirmLoading, setConfirmLoading] = useState(false);
    const dgrSelectedToEdit = useSelector((state) => state.dgrGoods.dgrSelectedToEdit);
    const [isSaveAndAdd, setIsSaveAndAdd] = useState(false);
    const dgrTypeStatusOK = useSelector(state => state.dgrGoods.dgrTypeStatusOK);
    const esidExport = useSelector((state) => state.esidExport.esidExpSelected);
    const [isCheckMamCheckbox, setIsCheckMamCheckbox] = useState(false);

    const dispatch = useDispatch();

    const handleSubmit = (formValue) => {
        setConfirmLoading(true);
        setIsCheckMamCheckbox(false);
        // console.log(formValue);
        let dgrTypes = [];
        
        //dgr
        if(formValue?.shcDgd) {
            const dgrType = dgrTypeStatusOK.find(dgrType => dgrType.codDgr === 'DGD');
            dgrTypes.push({
                esidId: esidExport.id,
                ...dgrType,
                namDgrExt: "",
                namDgrSfx: "",
                note: "",
                sorter: dgrType.codSrt,
            });
        }
        if(formValue?.shcCao) {
            const dgrType = dgrTypeStatusOK.find(dgrType => dgrType.codDgr === 'CAO');
            dgrTypes.push({
                esidId: esidExport.id,
                ...dgrType,
                namDgrExt: "",
                namDgrSfx: "",
                note: "",
                sorter: dgrType.codSrt,
            });
        }
        if(formValue?.shcMam) {
            const dgrType = dgrTypeStatusOK.find(dgrType => dgrType.codDgr === 'MAM');
            dgrTypes.push({
                esidId: esidExport.id,
                ...dgrType,
                namDgrExt: formValue?.pc,
                namDgrSfx: "PC",
                note: "",
                sorter: dgrType.codSrt,
            });
        }
        if(formValue?.shcUN3373) {
            const dgrType = dgrTypeStatusOK.find(dgrType => dgrType.codDgr === 'UN3373');
            dgrTypes.push({
                esidId: esidExport.id,
                ...dgrType,
                namDgrExt: "",
                namDgrSfx: "",
                note: "",
                sorter: dgrType.codSrt,
            });
        }
        // if(formValue?.note) {
        //     const dgrType = {
        //         codDgr: "",
        //         dgrNam: "",
        //         note: formValue.note,
        //         namDgrExt: "",
        //         namDgrSfx: ""
        //     }
        //     dgrTypes.push(dgrType);
        // }

        //ice
        if(formValue?.shcDric) {
            const dgrType = dgrTypeStatusOK.find(dgrType => dgrType.codDgr === 'DRIC');
            dgrTypes.push({
                esidId: esidExport.id,
                ...dgrType,
                namDgrExt: "",
                namDgrSfx: "",
                note: "",
                sorter: dgrType.codSrt,
            });
        }
        if(formValue?.shcUN1845) {
            const dgrType = dgrTypeStatusOK.find(dgrType => dgrType.codDgr === 'UN1845');
            dgrTypes.push({
                esidId: esidExport.id,
                ...dgrType,
                namDgrExt: "",
                namDgrSfx: "",
                note: "",
                sorter: dgrType.codSrt,
            });
        }
        //pin
        if(formValue?.shcUN3481) {
            const dgrType = dgrTypeStatusOK.find(dgrType => dgrType.codDgr === 'UN3481');
            dgrTypes.push({
                esidId: esidExport.id,
                ...dgrType,
                namDgrExt: "",
                namDgrSfx: "",
                note: "",
                sorter: dgrType.codSrt,
            });
        }
        if(formValue?.shcUN3091) {
            const dgrType = dgrTypeStatusOK.find(dgrType => dgrType.codDgr === 'UN3091');
            dgrTypes.push({
                esidId: esidExport.id,
                ...dgrType,
                namDgrExt: "",
                namDgrSfx: "",
                note: "",
                sorter: dgrType.codSrt,
            });
        }
        if(formValue?.shcPI966) {
            const dgrType = dgrTypeStatusOK.find(dgrType => dgrType.codDgr === 'PI966');
            dgrTypes.push({
                esidId: esidExport.id,
                ...dgrType,
                namDgrExt: "",
                namDgrSfx: "",
                note: "",
                sorter: dgrType.codSrt,
            });
        }
        if(formValue?.shcPI967) {
            const dgrType = dgrTypeStatusOK.find(dgrType => dgrType.codDgr === 'PI967');
            dgrTypes.push({
                esidId: esidExport.id,
                ...dgrType,
                namDgrExt: "",
                namDgrSfx: "",
                note: "",
                sorter: dgrType.codSrt,
            });
        }
        if(formValue?.shcPI969) {
            const dgrType = dgrTypeStatusOK.find(dgrType => dgrType.codDgr === 'PI969');
            dgrTypes.push({
                esidId: esidExport.id,
                ...dgrType,
                namDgrExt: "",
                namDgrSfx: "",
                note: "",
                sorter: dgrType.codSrt,
            });
        }
        if(formValue?.shcPI970) {
            const dgrType = dgrTypeStatusOK.find(dgrType => dgrType.codDgr === 'PI970');
            dgrTypes.push({
                esidId: esidExport.id,
                ...dgrType,
                namDgrExt: "",
                namDgrSfx: "",
                note: "",
                sorter: dgrType.codSrt,
            });
        }
        if(formValue?.shcEli) {
            const dgrType = dgrTypeStatusOK.find(dgrType => dgrType.codDgr === 'ELI');
            dgrTypes.push({
                esidId: esidExport.id,
                ...dgrType,
                namDgrExt: "",
                namDgrSfx: "",
                note: "",
                sorter: dgrType.codSrt,
            });
        }
        if(formValue?.shcElm) {
            const dgrType = dgrTypeStatusOK.find(dgrType => dgrType.codDgr === 'ELM');
            dgrTypes.push({
                esidId: esidExport.id,
                ...dgrType,
                namDgrExt: "",
                namDgrSfx: "",
                note: "",
                sorter: dgrType.codSrt,
            });
        }

        //
        if(dgrSelectedToEdit){
            const newData = {...dgrSelectedToEdit, ...formValue, dgrTypes};
            handleUpdate(newData);
        }else{
            handleAdd(formValue, dgrTypes);
        }
        setConfirmLoading(false);
        form.resetFields();
        if(!isSaveAndAdd){
            setModalVisible(false);
        }
    }

    const handleCancel = () =>{
        form.resetFields();
        dispatch(setDgrSelectedToEdit(null));
        setModalVisible(false);
        setIsSaveAndAdd(false);
    }
    
    const handleSetSaveAndAdd = (val) =>{
        setIsSaveAndAdd(val);
    }

    useEffect(() => {
        //dgr
        const isCheckedShcDgd = dgrSelectedToEdit?.dgrTypes?.find((type) => type.codDgr === 'DGD');
        const isCheckedShcCao = dgrSelectedToEdit?.dgrTypes?.find((type) => type.codDgr === 'CAO');
        const shcMam = dgrSelectedToEdit?.dgrTypes?.find((type) => type.codDgr === 'MAM');
        if(shcMam){
            setIsCheckMamCheckbox(true);
        }else{
            setIsCheckMamCheckbox(false);
        }
        const isCheckedShcUN3373 = dgrSelectedToEdit?.dgrTypes?.find((type) => type.codDgr === 'UN3373');
        // const note = dgrSelectedToEdit?.dgrTypes?.find((type) => type?.note && type?.note !== "");

        //ice
        const isCheckedShcDric = dgrSelectedToEdit?.dgrTypes?.find((type) => type.codDgr === 'DRIC');
        const isCheckedShcUN1845 = dgrSelectedToEdit?.dgrTypes?.find((type) => type.codDgr === 'UN1845');

        //pin
        const isCheckedShcUN3481 = dgrSelectedToEdit?.dgrTypes?.find((type) => type.codDgr === 'UN3481');
        const isCheckedShcUN3091 = dgrSelectedToEdit?.dgrTypes?.find((type) => type.codDgr === 'UN3091');
        const isCheckedShcPI966 = dgrSelectedToEdit?.dgrTypes?.find((type) => type.codDgr === 'PI966');
        const isCheckedShcPI967 = dgrSelectedToEdit?.dgrTypes?.find((type) => type.codDgr === 'PI967');
        const isCheckedShcPI969 = dgrSelectedToEdit?.dgrTypes?.find((type) => type.codDgr === 'PI969');
        const isCheckedShcPI970 = dgrSelectedToEdit?.dgrTypes?.find((type) => type.codDgr === 'PI970');
        const isCheckedShcEli = dgrSelectedToEdit?.dgrTypes?.find((type) => type.codDgr === 'ELI');
        const isCheckedShcElm = dgrSelectedToEdit?.dgrTypes?.find((type) => type.codDgr === 'ELM');

        form.setFieldsValue({
            //dgr
            dgrRegSeq: dgrSelectedToEdit?.dgrRegSeq,
            codUid: dgrSelectedToEdit?.codUid,
            codPsn: dgrSelectedToEdit?.codPsn,
            shcDgd: isCheckedShcDgd ? true : false,
            shcCao: isCheckedShcCao ? true : false,
            shcMam: shcMam ? true : false,
            pc: shcMam ? shcMam.namDgrExt: "",
            shcUN3373: isCheckedShcUN3373 ? true : false,
            desRmk: dgrSelectedToEdit?.desRmk,
            qtyPcs: dgrSelectedToEdit?.qtyPcs,
            qtyWgt: dgrSelectedToEdit?.qtyWgt,
            //ice
            shcDric: isCheckedShcDric ? true : false,
            shcUN1845: isCheckedShcUN1845 ? true : false,
            //pin
            shcUN3481: isCheckedShcUN3481 ? true : false,
            shcUN3091: isCheckedShcUN3091 ? true : false,
            shcPI966: isCheckedShcPI966 ? true : false,
            shcPI967: isCheckedShcPI967 ? true : false,
            shcPI969: isCheckedShcPI969 ? true : false,
            shcPI970: isCheckedShcPI970 ? true : false,
            shcEli: isCheckedShcEli ? true : false,
            shcElm: isCheckedShcElm ? true : false,
          })
        // eslint-disable-next-line
    },[dgrSelectedToEdit])

    const onChangeMamCheckbox = (e) =>{
        const val = e.target.checked
        if(!val){
          form.setFieldsValue({
            pc: ''
          })
        }
        setIsCheckMamCheckbox(val);
    }
    
    return (
        <Modal
            visible={modalVisible}
            onCancel={handleCancel}
            footer={null}
            centered
            width={'800px'}
        >
            {typeAdd === addDgrType.DGR && (
                <Form
                    labelAlign='left'
                    labelCol={{ span: 24 }}
                    onFinish={handleSubmit}
                    form={form}
                    initialValues={{
                        dgrRegSeq: '',
                        codUid: '',
                        codPsn: '',
                        shcDgd: false,
                        shcCao: false,
                        shcMam: false,
                        pc: '',
                        shcUN3373: false,
                        // note: ''
                    }}
                    autoComplete="off"
                >
                    <Row>
                        {/* <Col lg={6} md={8} sm={24} xs={24} style={{padding: 0}}>
                        </Col> */}
                        <Col span={24}>
                            <Form.Item
                                name='codUid'
                                label={t('dangerousGood.formInfo.UN')}
                                rules={[
                                    {
                                        required: true,
                                        message: t('dangerousGood.errorInfo.required'),
                                    },
                                ]}
                            >
                                <Input placeholder={placeHolders.COD_UID} maxLength={300}/>
                            </Form.Item>
                        </Col>
                        {/* <Col lg={18} md={16} sm={24} xs={24} style={{padding: '0 0 0 5px'}}>
                            <Form.Item
                                name="codPsn"
                                label={t('dangerousGood.formInfo.probShpName')}
                                rules={[
                                    {
                                        required: true,
                                        message: t('dangerousGood.errorInfo.required'),
                                    },
                                ]}
                            >
                                <Input placeholder={placeHolders.SHIPPING_NAME} />
                            </Form.Item>
                        </Col> */}
                    </Row>
                    <Row>
                        <Col span={24} style={{marginTop: '10px', marginBottom: '5px'}}>
                            <div style={{ fontWeight: 'bold' }}>{t("dangerousGood.formInfo.dgrType")}</div>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24} style={{ marginBottom: '10px', marginTop: '10px' }}>
                            <Form.Item
                                name="shcDgd"
                                noStyle
                                valuePropName="checked"
                            >
                                <Checkbox>
                                    {t("dangerousGood.formInfo.shcDgd")}
                                </Checkbox>
                            </Form.Item>
                        </Col>
                        <Col span={24} style={{ marginBottom: '10px' }}>
                            <Form.Item
                                name="shcCao"
                                noStyle
                                valuePropName="checked"
                            >
                                <Checkbox>
                                    {t("dangerousGood.formInfo.shcCao")}
                                </Checkbox>
                            </Form.Item>
                        </Col>
                        <Col span={8} style={{ marginBottom: '10px', display: 'flex', alignItems: 'center' }}>
                            <Form.Item
                                name="shcMam"
                                noStyle
                                valuePropName="checked"
                            >
                                <Checkbox onChange={onChangeMamCheckbox}>
                                    {t("dangerousGood.formInfo.shcMam")}
                                </Checkbox>
                            </Form.Item>
                        </Col>
                        <Col span={8} style={{ marginBottom: '10px', display: 'flex', alignItems: 'center' }}>
                            <Form.Item
                                name="pc"
                                noStyle
                            >
                                <Input suffix="PC" disabled={!isCheckMamCheckbox}/>
                            </Form.Item>
                        </Col>
                        <Col span={24} style={{ marginBottom: '10px' }}>
                            <Form.Item
                                name="shcUN3373"
                                noStyle
                                valuePropName="checked"
                            >
                                <Checkbox>
                                    {t("dangerousGood.formInfo.shcUN3373")}
                                </Checkbox>
                            </Form.Item>
                        </Col>
                        {/* <Col span={24}>
                            <Form.Item
                                name="note"
                                label="Note"
                                labelCol={8}
                                wrapperCol={16}
                            >
                                <Input />
                            </Form.Item>
                        </Col> */}
                    </Row>
                    <Form.Item style={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>
                        <Space style={{ justifyContent: 'center', width: '100%' }} >
                            {dgrSelectedToEdit ? (
                                <>
                                    <Button loading={confirmLoading} type='primary' htmlType='submit' style={{ width: "fit-content" }} className='submitBtn' onClick={() => { handleSetSaveAndAdd(false) }}>
                                        {t('dangerousGood.formInfo.btnEdit')}
                                    </Button>
                                </>
                            ) : (
                                <Button loading={confirmLoading} type='primary' htmlType='submit' style={{ width: "fit-content" }} className='submitBtn' onClick={() => { handleSetSaveAndAdd(false) }}>
                                    {t('dangerousGood.formInfo.btnAdd')}
                                </Button>
                            )}
                            <Button loading={confirmLoading} type='primary' htmlType='submit' style={{ width: "fit-content" }} className='submitBtn' onClick={() => { handleSetSaveAndAdd(true) }}>
                                {t('dangerousGood.formInfo.btnSaveAndAdd')}
                            </Button>
                            <Button loading={confirmLoading} type='primary' style={{ width: "fit-content" }} onClick={handleCancel} className='submitBtn'>
                                {t('dangerousGood.formInfo.btnCancel')}
                            </Button>
                        </Space>
                    </Form.Item>
                </Form>
            )}

            {typeAdd === addDgrType.ICE && (
                <Form
                    labelAlign='left'
                    labelCol={{ span: 24 }}
                    onFinish={handleSubmit}
                    form={form}
                    initialValues={{
                        shcDric: false,
                        shcUN1845: false
                    }}
                    autoComplete="off"
                >
                    <Row>
                        <Col lg={12} md={24} sm={24} xs={24} style={{padding: 0}}>
                            <Form.Item
                                name="qtyPcs"
                                label="Số kiện"
                                rules={[
                                    {
                                    required: true,
                                    message: `${t('dangerousGood.errorInfo.required')}`,
                                    },
                                    { 
                                    type:'number',
                                    message: `${t('dangerousGood.errorInfo.onlyNum')}`
                                    }
                                ]}
                            >
                                <InputNumber 
                                    style={{width: '100%', height: '100%'}} 
                                    size={'medium'} 
                                    min={1} 
                                    placeholder={placeHolders.PIECES}
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={12} md={24} sm={24} xs={24} style={{padding: '0 0 0 5px'}}>
                            <Form.Item
                                name="qtyWgt"
                                label={t('dangerousGood.formInfo.qtyWgt')}
                                rules={[
                                    { 
                                    type:'number',
                                    message: `${t('dangerousGood.errorInfo.onlyNum')}`
                                    }
                                ]}
                            >
                                <InputNumber
                                    type='number' 
                                    style={{width: '100%', height: '100%'}} 
                                    size={'medium'} 
                                    min={1} 
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24} style={{marginTop: '10px', marginBottom: '5px'}}>
                            <div style={{ fontWeight: 'bold' }}>{t("dangerousGood.formInfo.dgrType")}</div>
                        </Col>
                    </Row>
                    <Row>
                        {/* <Col span={24} style={{ marginBottom: '10px' }}>
                            <Form.Item
                                name="shcDric"
                                noStyle
                                valuePropName="checked"
                            >
                                <Checkbox>
                                    {t("dangerousGood.formInfo.shcDric")}
                                </Checkbox>
                            </Form.Item>
                        </Col> */}
                        <Col span={24} style={{ marginBottom: '10px' }}>
                            <Form.Item
                                name="shcUN1845"
                                noStyle
                                valuePropName="checked"
                            >
                                <Checkbox>
                                    {t("dangerousGood.formInfo.shcUN1845")}
                                </Checkbox>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item style={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>
                        <Space style={{ justifyContent: 'center', width: '100%' }} >
                            {dgrSelectedToEdit ? (
                                <>
                                    <Button loading={confirmLoading} type='primary' htmlType='submit' style={{ width: "fit-content" }} className='submitBtn' onClick={() => { handleSetSaveAndAdd(false) }}>
                                        {t('dangerousGood.formInfo.btnEdit')}
                                    </Button>
                                </>
                            ) : (
                                <Button loading={confirmLoading} type='primary' htmlType='submit' style={{ width: "fit-content" }} className='submitBtn' onClick={() => { handleSetSaveAndAdd(false) }}>
                                    {t('dangerousGood.formInfo.btnAdd')}
                                </Button>
                            )}
                            <Button loading={confirmLoading} type='primary' htmlType='submit' style={{ width: "fit-content" }} className='submitBtn' onClick={() => { handleSetSaveAndAdd(true) }}>
                                {t('dangerousGood.formInfo.btnSaveAndAdd')}
                            </Button>
                            <Button loading={confirmLoading} type='primary' style={{ width: "fit-content" }} onClick={handleCancel} className='submitBtn'>
                                {t('dangerousGood.formInfo.btnCancel')}
                            </Button>
                        </Space>
                    </Form.Item>
                </Form>
            )}
            {typeAdd === addDgrType.PIN && (
                <Form
                    labelAlign='left'
                    labelCol={{ span: 24 }}
                    onFinish={handleSubmit}
                    form={form}
                    initialValues={{
                        shcUN3481: false,
                        shcUN3091: false,
                        shcPI966: false,
                        shcPI967: false,
                        shcPI969: false,
                        shcPI970: false,
                        shcEli: false,
                        shcElm: false
                    }}
                    autoComplete="off"
                >
                    <Row>
                        <Col lg={12} md={24} sm={24} xs={24} style={{padding: 0}}>
                            <Form.Item
                                name="qtyPcs"
                                label="Số kiện"
                                rules={[
                                    {
                                    required: true,
                                    message: `${t('dangerousGood.errorInfo.required')}`,
                                    },
                                    { 
                                    type:'number',
                                    message: `${t('dangerousGood.errorInfo.onlyNum')}`
                                    }
                                ]}
                            >
                                <InputNumber 
                                    style={{width: '100%', height: '100%'}} 
                                    size={'medium'} 
                                    min={1} 
                                    placeholder={placeHolders.PIECES}
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={12} md={24} sm={24} xs={24} style={{padding: '0 0 0 5px'}}>
                            <Form.Item
                                name="qtyWgt"
                                label="Trọng lượng"
                                rules={[
                                    { 
                                    type:'number',
                                    message: `${t('dangerousGood.errorInfo.onlyNum')}`
                                    }
                                ]}
                            >
                                <InputNumber 
                                    type='number'
                                    style={{width: '100%', height: '100%'}} 
                                    size={'medium'} 
                                    min={1}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24} style={{marginTop: '10px', marginBottom: '5px'}}>
                            <div style={{ fontWeight: 'bold' }}>{t("dangerousGood.formInfo.dgrType")}</div>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24} style={{ marginBottom: '10px' }}>
                            <Form.Item
                                name="shcUN3481"
                                noStyle
                                valuePropName="checked"
                            >
                                <Checkbox>
                                    {t("dangerousGood.formInfo.shcUN3481")}
                                </Checkbox>
                            </Form.Item>
                        </Col>
                        <Col span={24} style={{ marginBottom: '10px' }}>
                            <Form.Item
                                name="shcUN3091"
                                noStyle
                                valuePropName="checked"
                            >
                                <Checkbox>
                                    {t("dangerousGood.formInfo.shcUN3091")}
                                </Checkbox>
                            </Form.Item>
                        </Col>
                        <Col span={24} style={{ marginBottom: '10px' }}>
                            <Form.Item
                                name="shcPI966"
                                noStyle
                                valuePropName="checked"
                            >
                                <Checkbox>
                                    {t("dangerousGood.formInfo.shcPI966")}
                                </Checkbox>
                            </Form.Item>
                        </Col>
                        <Col span={24} style={{ marginBottom: '10px' }}>
                            <Form.Item
                                name="shcPI967"
                                noStyle
                                valuePropName="checked"
                            >
                                <Checkbox>
                                    {t("dangerousGood.formInfo.shcPI967")}
                                </Checkbox>
                            </Form.Item>
                        </Col>
                        <Col span={24} style={{ marginBottom: '10px' }}>
                            <Form.Item
                                name="shcPI969"
                                noStyle
                                valuePropName="checked"
                            >
                                <Checkbox>
                                    {t("dangerousGood.formInfo.shcPI969")}
                                </Checkbox>
                            </Form.Item>
                        </Col>
                        <Col span={24} style={{ marginBottom: '10px' }}>
                            <Form.Item
                                name="shcPI970"
                                noStyle
                                valuePropName="checked"
                            >
                                <Checkbox>
                                    {t("dangerousGood.formInfo.shcPI970")}
                                </Checkbox>
                            </Form.Item>
                        </Col>
                        {/* <Col span={24} style={{ marginBottom: '10px' }}>
                            <Form.Item
                                name="shcEli"
                                noStyle
                                valuePropName="checked"
                            >
                                <Checkbox disabled>
                                    {t("dangerousGood.formInfo.shcEli")}
                                </Checkbox>
                            </Form.Item>
                        </Col> */}
                        {/* <Col span={24} style={{ marginBottom: '10px' }}>
                            <Form.Item
                                name="shcElm"
                                noStyle
                                valuePropName="checked"
                            >
                                <Checkbox disabled>
                                    {t("dangerousGood.formInfo.shcElm")}
                                </Checkbox>
                            </Form.Item>
                        </Col> */}
                    </Row>
                    <Form.Item style={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>
                        <Space style={{ justifyContent: 'center', width: '100%' }} >
                            {dgrSelectedToEdit ? (
                                <>
                                    <Button loading={confirmLoading} type='primary' htmlType='submit' style={{ width: "fit-content" }} className='submitBtn' onClick={() => { handleSetSaveAndAdd(false) }}>
                                        {t('dangerousGood.formInfo.btnEdit')}
                                    </Button>
                                </>
                            ) : (
                                <Button loading={confirmLoading} type='primary' htmlType='submit' style={{ width: "fit-content" }} className='submitBtn' onClick={() => { handleSetSaveAndAdd(false) }}>
                                    {t('dangerousGood.formInfo.btnAdd')}
                                </Button>
                            )}
                            <Button loading={confirmLoading} type='primary' htmlType='submit' style={{ width: "fit-content" }} className='submitBtn' onClick={() => { handleSetSaveAndAdd(true) }}>
                                {t('dangerousGood.formInfo.btnSaveAndAdd')}
                            </Button>
                            <Button loading={confirmLoading} type='primary' style={{ width: "fit-content" }} onClick={handleCancel} className='submitBtn'>
                                {t('dangerousGood.formInfo.btnCancel')}
                            </Button>
                        </Space>
                    </Form.Item>
                </Form>
            )}
        </Modal>
    )
}
