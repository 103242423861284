/* eslint-disable */

import React, { useEffect } from "react";
import { Row, Col } from "antd";
import "./page-css/Introduction.css";
import IntroductionContent from "../components/IntroductionContent";
import * as Constant from "../config/Constant";
import Banner from "../components/Banner";
import { useDispatch, useSelector } from "react-redux";
import { showLangSwitch } from "../actions/menu";

const Introduction = () => {
  const { menuItem } = useSelector((state) => state.menu);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(showLangSwitch(true));
  }, []);
  return (
    <Row className="introduction-wrapper">
      <Banner
        title={menuItem.menuParentTitle}
        className="banner-wrapper"
        cover={`${Constant.IMAGES_URL}${menuItem.banner}`}
      />
      <Row className="introduction-content-wrapper">
        <Col span={24}>
          <IntroductionContent />
        </Col>
      </Row>
    </Row>
  );
};

export default Introduction;
