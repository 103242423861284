import axiosInstance from "./AuthIntercept";
import * as Constant from "./Constant";

export async function GetBillDetail(url, data, token) {
  let result = await axiosInstance.post(
    Constant.DOMAIN_CLIENT + "GetBillDetail",
    {
      data,
      url,
    },
    {
      headers: {
        Authorization: token,
      },
    }
  );
  return result;
}
export async function GET_MAWB_CTC_INF(url, data, token) {
  let result = await axiosInstance.post(
    Constant.DOMAIN_CLIENT + "GET_MAWB_CTC_INF",
    {
      url,
      data,
    },
    {
      headers: {
        Authorization: token,
      },
    }
  );
  return result;
}
export async function GET_EXP_FLIGHT_CUTOFF_TIME(url, data) {
  let result = await axiosInstance.post(
    Constant.DOMAIN_CLIENT + "GET_EXP_FLIGHT_CUTOFF_TIME",
    {
      data: data,
      url,
    }
  );
  return result;
}
export async function GET_AGENT_NONEDLV_LIST(url, data, token) {
  let result = await axiosInstance.post(
    Constant.DOMAIN_CLIENT + "GET_AGENT_NONEDLV_LIST",
    {
      data: data,
      url,
    },
    {
      headers: {
        Authorization: token,
      },
    }
  );
  return result;
}
export async function GET_AIRLINE_NONEDLV_LIST(url, data, token) {
  let result = await axiosInstance.post(
    Constant.DOMAIN_CLIENT + "GET_AIRLINE_NONEDLV_LIST",
    {
      data: data,
      url,
    },
    {
      headers: {
        Authorization: token,
      },
    }
  );
  return result;
}
export async function GetReportAgent(urlDoc, urlSHP, data, token) {
  let result = await axiosInstance.post(
    Constant.DOMAIN_CLIENT + "GetReportAgent",
    {
      urlDoc,
      urlSHP,
      data,
    },
    {
      headers: {
        Authorization: token,
      },
    }
  );
  return result;
}
export const GET_EXP_SHPMENTS_IN_WH = (
  carrierId,
  flightNumber,
  flightDate,
  token
) =>
  axiosInstance.post(
    `${Constant.DOMAIN_CLIENT}GET_EXP_SHPMENTS_IN_WH`,
    {
      carrierId,
      flightNumber,
      flightDate,
    },
    {
      headers: {
        Authorization: token,
      },
    }
  );

export const GET_EXP_SHIPMENTS_STATUS = (
  carrierId,
  flightNumber,
  flightDate,
  token
) =>
  axiosInstance.post(
    `${Constant.DOMAIN_CLIENT}GET_EXP_SHIPMENTS_STATUS`,
    {
      carrierId,
      flightNumber,
      flightDate,
    },
    {
      headers: {
        Authorization: token,
      },
    }
  );
export const GET_EXP_ACCEPTANCE = async (awbFirst, awbLast) => {
  return await axiosInstance.post(`${Constant.DOMAIN_CLIENT}GET_EXP_ACCEPTANCE`, {
    awbFirst,
    awbLast,
  });
};
export const GET_EXP_BOOKING_INF = async (awbFirst, awbLast) => {
  return await axiosInstance.post(`${Constant.DOMAIN_CLIENT}GET_EXP_BOOKING_INF`, {
    awbFirst,
    awbLast,
  });
};
export const GET_EXP_SHIPMENT_INF = async (awbFirst, awbLast) => {
  return await axiosInstance.post(`${Constant.DOMAIN_CLIENT}GET_EXP_SHIPMENT_INF`, {
    awbFirst,
    awbLast,
  });
};
export const GET_SHIPMENT_INF = async (awbFirst, awbLast, hawb, token) => {
  return await axiosInstance.post(
    `${Constant.DOMAIN_CLIENT}GET_SHIPMENT_INF`,
    {
      awbFirst,
      awbLast,
      hawb,
    },
    {
      headers: {
        Authorization: token,
      },
    }
  );
};
export const GET_CUSTOMER_NAME = async (userCode, token) => {
  return await axiosInstance.post(
    `${Constant.DOMAIN_CLIENT}GET_CUSTOMER_NAME`,
    {
      userCode,
    },
    {
      headers: {
        Authorization: token,
      },
    }
  );
};
export const GET_HAWB_DOC_INF = async (awbFirst, awbLast, hawbNo, token) => {
  return await axiosInstance.post(
    `${Constant.DOMAIN_CLIENT}GET_HAWB_DOC_INF`,
    {
      awbFirst,
      awbLast,
      hawbNo,
    },
    {
      headers: {
        Authorization: token,
      },
    }
  );
};
export const GET_HAWB_CTC_INF = async (awbFirst, awbLast, hawbNo, token) => {
  return await axiosInstance.post(
    `${Constant.DOMAIN_CLIENT}GET_HAWB_CTC_INF`,
    {
      awbFirst,
      awbLast,
      hawbNo,
    },
    {
      headers: {
        Authorization: token,
      },
    }
  );
};
export const GET_HAWB_SHIPMENT_INF = async (
  awbFirst,
  awbLast,
  hawbNo,
  token
) => {
  return await axiosInstance.post(
    `${Constant.DOMAIN_CLIENT}GET_HAWB_SHIPMENT_INF`,
    {
      awbFirst,
      awbLast,
      hawbNo,
    },
    {
      headers: {
        Authorization: token,
      },
    }
  );
};
export const GET_AIRLINE_FLIGHT_REPORT_PART_A = async (
  flightNo,
  flightDate,
  token
) => {
  return await axiosInstance.post(
    `${Constant.DOMAIN_CLIENT}GET_AIRLINE_FLIGHT_REPORT_PART_A`,
    { flightNo, flightDate },
    {
      headers: {
        Authorization: token,
      },
    }
  );
};
export const GET_AIRLINE_FLIGHT_REPORT_PART_B = async (
  flightNo,
  flightDate,
  token
) => {
  return await axiosInstance.post(
    `${Constant.DOMAIN_CLIENT}GET_AIRLINE_FLIGHT_REPORT_PART_B`,
    { flightNo, flightDate },
    {
      headers: {
        Authorization: token,
      },
    }
  );
};
export const GET_AIRLINE_FLIGHT_REPORT_PART_C = async (
  flightNo,
  flightDate,
  token
) => {
  return await axiosInstance.post(
    `${Constant.DOMAIN_CLIENT}GET_AIRLINE_FLIGHT_REPORT_PART_C`,
    { flightNo, flightDate },
    {
      headers: {
        Authorization: token,
      },
    }
  );
};

export const GET_AIRLINE_FLIGHT_REPORT_PART_D = (flightNo, flightDate, token) =>
  axiosInstance.post(
    `${Constant.DOMAIN_CLIENT}GET_AIRLINE_FLIGHT_REPORT_PART_D`,
    {
      flightNo,
      flightDate,
    },
    {
      headers: {
        Authorization: token,
      },
    }
  );

export const GET_CHARGE_INF = (START_DATE, END_DATE, token) =>
  axiosInstance.post(
    `${Constant.DOMAIN_CLIENT}GET_CHARGE_INF`,
    {
      START_DATE,
      END_DATE,
    },
    {
      headers: {
        Authorization: token,
      },
    }
  );

export const GET_IMP_FLIGHT_INF = (carrierCode, flightNo, flightDate, token) =>
  axiosInstance.post(
    `${Constant.DOMAIN_CLIENT}GET_IMP_FLIGHT_INF`,
    {
      carrierCode,
      flightNo,
      flightDate,
    },
    {
      headers: {
        Authorization: token,
      },
    }
  );
export const GET_CHARGE_INF_CS = (
  awbFirst,
  awbLast,
  awbSuffix,
  HAWB_NO,
  flightDate,
  S_SESSTION_ID,
  token
) =>
  axiosInstance.post(
    `${Constant.DOMAIN_CLIENT}GET_CHARGE_INF_CS`,
    {
      awbFirst,
      awbLast,
      awbSuffix,
      HAWB_NO,
      flightDate,
      S_SESSTION_ID,
    },
    {
      headers: {
        Authorization: token,
      },
    }
  );

export const CALC_CHARGE = (
  awbFirst,
  awbLast,
  awbSuffix,
  hawbNo,
  CALC_DATE,
  S_SESSTION_ID,
  token
) =>
  axiosInstance.post(
    `${Constant.DOMAIN_CLIENT}CALC_CHARGE`,
    {
      awbFirst,
      awbLast,
      awbSuffix,
      hawbNo,
      CALC_DATE,
      S_SESSTION_ID,
    },
    {
      headers: {
        Authorization: token,
      },
    }
  );

export const GET_CHARGE_CODE_CS = (
  awbFirst,
  awbLast,
  awbSuffix,
  hawb,
  CALC_DATE,
  S_SESSTION_ID,
  token
) =>
  axiosInstance.post(
    `${Constant.DOMAIN_CLIENT}GET_CHARGE_CODE_CS`,
    {
      awbFirst,
      awbLast,
      awbSuffix,
      hawb,
      CALC_DATE,
      S_SESSTION_ID,
    },
    {
      headers: {
        Authorization: token,
      },
    }
  );

export const GET_CHG_DTL_WEB = (
  awbFirst,
  awbLast,
  awbSuffix,
  hawb,
  CHG_CODE,
  FL_DATE,
  S_SESSTION_ID,
  language,
  token
) =>
  axiosInstance.post(
    `${Constant.DOMAIN_CLIENT}GET_CHG_DTL_WEB`,
    {
      awbFirst,
      awbLast,
      awbSuffix,
      hawb,
      CHG_CODE,
      FL_DATE,
      S_SESSTION_ID,
      language,
    },
    {
      headers: {
        Authorization: token,
      },
    }
  );
export const GET_HAWB_LOCATION = (awbFirst, awbLast, hawbNo, token) =>
  axiosInstance.post(
    `${Constant.DOMAIN_CLIENT}GET_HAWB_LOCATION`,
    {
      awbFirst,
      awbLast,
      hawbNo,
    },
    {
      headers: {
        Authorization: token,
      },
    }
  );
export const GET_MAWB_LOCATION = (awbFirst, awbLast, token) =>
  axiosInstance.post(
    `${Constant.DOMAIN_CLIENT}GET_MAWB_LOCATION`,
    {
      awbFirst,
      awbLast,
    },
    {
      headers: {
        Authorization: token,
      },
    }
  );

export const GET_LETTER_INF = (mawb, hawb, token) =>
  axiosInstance.post(
    `${Constant.DOMAIN_CLIENT}GET_LETTER_INF`,
    {
      mawb,
      hawb,
    },
    { headers: { Authorization: token } }
  );

export const GET_SS_IMP_STATUS_SHP_V2 = async (data, token) => {
  return await axiosInstance.post(
    `${Constant.DOMAIN_CLIENT}GET_SS_IMP_STATUS_SHP_V2`,
    {
      data,
    },
    { headers: { Authorization: token } }
  );
};

export const GET_SS_EXP_STATUS_SHP = async (data, token) => {
  return await axiosInstance.post(
    `${Constant.DOMAIN_CLIENT}GET_SS_EXP_STATUS_SHP`,
    {
      data,
    },
    { headers: { Authorization: token } }
  );
};
