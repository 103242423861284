import React, { useState } from 'react'
import { Space, Form, Input, Col, Row, Button, InputNumber, Modal } from 'antd';
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';

const placeHolders = {
    REJECT: 'eg: 3',
}

export const RejectDangerousGoodsModal = ({ modalVisible, setModalVisible, handleReject, form }) => {
    const { t } = useTranslation("common");
    const [confirmLoading, setConfirmLoading] = useState(false);
    const dgrSelectedValue = useSelector((state) => state.dgrGoods.dgrSelectedValue)
    // const dgrSelectedToEdit = useSelector((state) => state.dgrGoods.dgrSelectedToEdit);

    const handleSubmit = (formValue) => {
        setConfirmLoading(true);
        handleReject(formValue);
        setConfirmLoading(false);
        form.resetFields();
        setModalVisible(false);
    }

    const handleCancel = () => {
        form.resetFields();
        setModalVisible(false);
    }

    // useEffect(() => {
    //     //dgr
    //     const isCheckedShcDgd = dgrSelectedToEdit?.dgrTypes?.find((type) => type.codDgr === 'DGD');
    //     const isCheckedShcCao = dgrSelectedToEdit?.dgrTypes?.find((type) => type.codDgr === 'CAO');
    //     // eslint-disable-next-line
    // }, [dgrSelectedToEdit])

    return (
        <Modal
            visible={modalVisible}
            onCancel={handleCancel}
            footer={null}
            centered
            width={'800px'}
        >
            <Form
                labelAlign='left'
                labelCol={{ span: 24 }}
                onFinish={handleSubmit}
                form={form}
                initialValues={{
                    rejectNum: dgrSelectedValue?.rejectNum ? dgrSelectedValue?.rejectNum : 0,
                    note: dgrSelectedValue?.note ? dgrSelectedValue?.note : ''
                }}
                autoComplete="off"
            >
                <Row>
                    <Col span={24}>
                        <Form.Item
                            name="rejectNum"
                            label= {t('dangerousGood.rejectNum')}
                            rules={[
                                {
                                    required: true,
                                    message: `${t('dangerousGood.errorInfo.required')}`,
                                },
                                {
                                    type: 'number',
                                    message: `${t('dangerousGood.errorInfo.onlyNum')}`
                                }
                            ]}
                        >
                            <InputNumber
                                style={{ width: '100%', height: '100%' }}
                                size={'medium'}
                                min={1}
                                placeholder={placeHolders.REJECT}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name="note"
                            label="Note"
                            // rules={[
                            //     {
                            //         required: true,
                            //         message: `${t('dangerousGood.errorInfo.required')}`,
                            //     },
                            // ]}
                        >
                            <Input
                                style={{ width: '100%', height: '100%' }}
                                size={'medium'}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item style={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>
                    <Space style={{ justifyContent: 'center', width: '100%' }} >
                        <Button loading={confirmLoading} type='primary' htmlType='submit' style={{ width: "fit-content" }} className='submitBtn'>
                            {t('dangerousGood.formInfo.btnAdd')}
                        </Button>
                        <Button loading={confirmLoading} type='primary' style={{ width: "fit-content" }} onClick={handleCancel} className='submitBtn'>
                            {t('dangerousGood.formInfo.btnCancel')}
                        </Button>
                    </Space>
                </Form.Item>
            </Form>
        </Modal>
    )
}
