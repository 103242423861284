/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { Tabs } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import * as API from '../config/apiConfig';
import './component-css/CustomTabs.css';
import Parser from 'html-react-parser';
import { useLocation } from 'react-router-dom';
import { changeLang, menuItemSelected } from '../actions/menu';

const { TabPane } = Tabs;

const styles = {
    button: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        width: '300px',
        height: '150px',
        margin: '10px',
        backgroundColor: '#f68b1e',
        color: 'white',
        borderRadius: '60px',
        textAlign: 'center',
        fontSize: '18px',
        padding: '20px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        cursor: 'pointer',
        img: {
            position: 'absolute',
            bottom: '10px',
            right: '10px',
            width: '50px',
        }
    }
};

const CustomTabs = () => {
  const location = useLocation();
  const [tabs, setTabs] = useState([]);
  const { langId, menuItem } = useSelector((state) => state.menu);
  const [activeKey, setActiveKey] = useState();
  const dispatch = useDispatch();

  const setMenu = async (menuId, langId) => {
    const menuRes = await API.getMenuById(menuId);
    const menuParentRes = await API.getMenuById(menuRes.data.parent);
    const menuReference = {
      key: menuRes.data.alias,
      title: menuRes.data.name,
      menuParentTitle: menuRes.data.name,
      banner: menuParentRes.data.params,
      menuId: menuRes.data.id,
      menuParentId: menuRes.data.parent,
      images: menuRes.data.images,
      referenceId: menuRes.data.reference_id,
    };
    dispatch(changeLang(langId));
    localStorage.setItem('langId', langId || 1);
    localStorage.setItem('LANGUAGE', langId === '2' ? 'vi' : 'en');
    dispatch(menuItemSelected(menuReference));
    localStorage.setItem('menuItem', JSON.stringify(menuReference));
  };
  const getContent = async () => {
    const slug = location.pathname.slice(location.pathname.lastIndexOf('/') + 1);
    const menuIdRes = await API.getMenuBySlug(slug);
    const { id, lang_id } = menuIdRes.data;

    API.getListMenuDetail(langId, id).then((res) => {
      if (slug === 'bang-gia-dich-vu') setTabs(res.data.data.reverse());
      else setTabs(res.data.data);
    });

    setMenu(id, lang_id + '');
  };

  useEffect(() => {
    getContent();
  }, [location]);
  useEffect(() => {
    API.getListMenuDetail(langId, menuItem.referenceId).then((res) => {
      const slug = location.pathname.slice(location.pathname.lastIndexOf('/') + 1);
      if (slug === 'bang-gia-dich-vu') setTabs(res.data.data.reverse());
      else setTabs(res.data.data);
    });
  }, [langId]);

  useEffect(() => {
    if (!activeKey || !tabs.includes(activeKey)) {
      setActiveKey(tabs[0]?.id.toString());
    }
  }, [tabs]);

  const [courses, setCourses] = useState([]);
  const [activeCourse, setActiveCourse] = useState();
  useEffect(() => {
    if (activeKey === '1000000321') { // các khóa đào tạo
        API.getListMenuDetail(langId, 1000000321).then((res) => {
            setCourses(res.data.data.reverse());
            if (res?.data?.data?.length > 0) {
                setActiveCourse(res.data.data[0]);
            }
        });
    }
  }, [langId, activeKey]);

  return (
    tabs && (
      <Tabs
        tabBarGutter={26}
        size={'default'}
        onChange={(key) => {
          setActiveKey(key);
        }}
        activeKey={activeKey}>
        {tabs.map((tab) => {
          return (
            <TabPane className="tab-content service-content-wrapper" tab={tab.title} key={tab.id}>
              <div className="custom-table">
                {activeKey === '1000000321' && (
                    <div style={{ width: '100%' }}>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 16 }}>
                            {courses.slice(0,3).map(course => (
                                <div key={course.id} onClick={() => setActiveCourse(course)} style={styles.button}>
                                    <div>{course.title}</div>
                                    <img src="/img/logo-nobg.png" alt="TCS Logo" width={60}></img>
                                </div>
                            ))}
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 16 }}>
                            {courses.slice(3,courses.length).map(course => (
                                <div key={course.id} onClick={() => setActiveCourse(course)} style={styles.button}>
                                    <div>{course.title}</div>
                                    <img src="/img/logo-nobg.png" alt="TCS Logo" width={60}></img>
                                </div>
                            ))}
                        </div>
                        {activeCourse && (
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <div style={{ maxWidth: '800px' }}>
                                <div className="customtabForm ck-content">
                                    {activeCourse.fulltext && Parser(activeCourse.fulltext !== null ? activeCourse.fulltext : '')}
                                </div>
                                </div>
                            </div>
                        )}
                    </div>
                )}
                {activeKey !== '1000000321' && (
                  <>
                    {tab.content_standard ? (
                      <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <div style={{ maxWidth: '800px' }}>
                          <h2>{tab.title}</h2>
                          <div className="customtabForm ck-content">
                            {tab.fulltext && Parser(tab.fulltext !== null ? tab.fulltext : '')}
                          </div>
                        </div>
                      </div>
                    ) : (
                      <>
                        <h2>{tab.title}</h2>
                        <div className="customtabForm ck-content">
                          {tab.fulltext && Parser(tab.fulltext !== null ? tab.fulltext : '')}
                        </div>
                      </>
                    )}
                  </>
                )}
              </div>
            </TabPane>
          );
        })}
      </Tabs>
    )
  );
};
export default CustomTabs;
