import moment from "moment";
import React from "react";
import Barcode from "react-barcode";

export class BarcodeToPrint extends React.PureComponent {
  // eslint-disable-next-line
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div className="barcode-wrapper">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <h3>CÔNG TY CỔ PHẦN DICH VỤ HÀNG HÓA TÂN SƠN NHẤT</h3>
          <div style={{ height: '100%' }}><Barcode width={4} height={150} value={this.props.value} /></div>
          <br />
          <h3 style={{ fontWeight: "bold" }}>
            {this.props.value.slice(0, this.props.value.indexOf("%"))}
          </h3>
          <h3>
            {this.props.MAWB.first}
            {this.props.MAWB.last}
            {this.props.MAWB.suffix}
            {this.props.HAWB !== "" && `/${this.props.HAWB}`}
          </h3>
          <h3>
            {this.props.pcs} PCS - {this.props.weight}kg Ngày đăng ký:
            {` ${moment(this.props.DAT_CRE).format("DD MMM YYYY HH:mm")}`}
          </h3>
          <h3>
            Ngày giờ đăng ký nhận hàng:
            {` ${moment(this.props.DAT_DLV).format("DD MMM YYYY HH:mm")}`}
          </h3>
          <h3>
            {this.props.DLV_ORD_RCV_ID}/{this.props.DLV_ORD_RCV_NAME}
          </h3>
          <h3>
            {this.props.status} {moment().format("DD MMM YYYY")}
          </h3>
        </div>
      </div>
    );
  }
}
