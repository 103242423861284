import * as types from "../constant/actionTypes";

export const userAwbLoggin = (userAwb) => {
  return {
    type: types.USER_LOGGEDIN,
    payload: userAwb,
  };
};
export const checkinUser = (user) => {
  return {
    type: types.CHECKIN,
    payload: user,
  };
};

export const userAwbLogout = () => {
  localStorage.removeItem("userAWB");
  localStorage.removeItem("userGuest");

  return {
    type: types.USER_LOGGEDOUT,
  };
};
export const userGuest = (user) => {
  return {
    type: types.USER_GUEST,
    payload: user,
  };
};
