/* eslint-disable */
import React from "react";
import moment from "moment";
import { GET_EXP_FLIGHT_CUTOFF_TIME } from "../config/webTrackApi";
import {
  Row,
  Col,
  Form,
  Button,
  Table,
  Skeleton,
  DatePicker,
  notification,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import { useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { checkinUser, userAwbLogout } from "../actions/userAWB";
import { setPaymentInfo } from "../actions/payment";
import { useHistory } from "react-router";
const TabCutOffTime = () => {
  const [tableDataFlight, setTableDataFlight] = useState();
  const [loading, setLoading] = useState();
  const { dataLogin } = useSelector((state) => state.userAwb);
  const { t } = useTranslation("common");
  const dispatch = useDispatch();
  const history = useHistory();
  const today = new Date(),
    date =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate();
  const [dateFlight, setDateFlight] = useState(
    today.getDate() + "/" + (today.getMonth() + 1) + "/" + today.getFullYear()
  );
  const [form] = useForm();
  const dateFormat = "DD-MM-YYYY";

  const submitFlight = () => {
    try {
      setLoading(true);
      let header = "DATE CUT-OFF TIME: " + dateFlight;
      let footer = "";
      let columns = [
        {
          title: t("CutOffTime.no"),
          dataIndex: "index",
          key: "index",
          className: "head_tb_repo",
        },
        {
          title: t("CutOffTime.fight"),
          dataIndex: "F01_COD_FLT_CAR",
          key: "F01_COD_FLT_CAR",
          className: "head_tb_repo",
        },
        {
          title: t("CutOffTime.fightNum"),
          dataIndex: "F02_COD_FLT_NUM",
          key: "F02_COD_FLT_NUM",
          className: "head_tb_repo",
        },
        {
          title: t("CutOffTime.Departure"),
          dataIndex: "F03_DAT_FLT_ORI",
          key: "F03_DAT_FLT_ORI",
          className: "head_tb_repo",
        },
        {
          title: t("CutOffTime.from"),
          dataIndex: "F04_COD_APT_BRD",
          key: "F04_COD_APT_BRD",
          className: "head_tb_repo",
        },
        {
          title: t("CutOffTime.to"),
          dataIndex: "F05_COD_APT_OFF",
          key: "F05_COD_APT_OFF",
          className: "head_tb_repo",
        },
        {
          title: t("CutOffTime.generalCargo"),
          dataIndex: "F09_CUT_OFF",
          key: "F09_CUT_OFF",
          className: "head_tb_repo",
        },
      ];
      let dataSource = [];
      let params = {
        STR_CONNECT: "AAA",
        EXP_FLT_DATE: dateFlight,
      };
      let url = "api/GET_EXP_FLIGHT_CUTOFF_TIME";
      GET_EXP_FLIGHT_CUTOFF_TIME(url, params).then((res) => {
        footer =
          langId === "2"
            ? "TỔNG CỘNG SỐ CHUYẾN BAY TRONG NGÀY: " +
              res.data.mCol.length +
              " CHUYẾN BAY"
            : "TOTAL FLIGHT:  " + res.data.mCol.length + " FLIGHT";
        if (res.status == 200 && res.data != "" && res.data.mCol.length >= 1) {
          res.data.mCol.map((dt, index) => {
            dataSource.push({
              key: index,
              index: index + 1,
              F01_COD_FLT_CAR: dt.F01_COD_FLT_CAR,
              F02_COD_FLT_NUM: dt.F02_COD_FLT_NUM,
              F03_DAT_FLT_ORI: dt.F03_DAT_FLT_ORI,
              F04_COD_APT_BRD: dt.F04_COD_APT_BRD,
              F05_COD_APT_OFF: dt.F05_COD_APT_OFF,
              F09_CUT_OFF: dt.F09_CUT_OFF,
            });
          });
          renderTableFlight(columns, header, footer, dataSource);
        } else {
          renderTableFlight(columns, header, footer, dataSource);
        }
        setLoading(false);
      });
    } catch (error) {
      if (error.response.status === 401) {
        notification.error({
          style: { marginRight: "-50%" },
          message: t("home.sessionNoti"),
        });
        dispatch(checkinUser({}));
        dispatch(setPaymentInfo(undefined));
        dispatch(userAwbLogout());
        localStorage.removeItem("userAWB");
        localStorage.removeItem("checkin");
        localStorage.removeItem("pInfo");
        history.push("/");
      }
    }
  };
  const renderTableFlight = (columns, header, footer, dataSource) => {
    setTableDataFlight(
      <Table
        className="table-cutoff"
        dataSource={dataSource}
        columns={columns}
        bordered
        // scroll={{ x: 1300 }}
        title={() => header}
        pagination={false}
        footer={() => footer}
      />
    );
  };
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const { langId } = useSelector((state) => state.menu);
  return (
    <div>
      <Row className="awb-tab-title-wrapper">
        <Col span={24} className="awb-tab-title">
          <h5>
            {langId === "2"
              ? "HÃY NHẬP NGÀY KIỂM TRA LỊCH BAY CUT-OFF TIME ĐỂ TIÊP TỤC"
              : "PLEASE INPUT DATE AND NEXT"}
          </h5>
        </Col>
      </Row>
      <Row className="awb-tab-content">
        <Row gutter={[0, 16]} className="control-wrapper">
          <Col offset={9} span={15} className="control">
            <Form
              initialValues={{ flightDate: moment(today, dateFormat) }}
              className="form-schedule"
              layout={"inline"}
              form={form}
              autoComplete="off"
            >
              <Form.Item name="flightDate" label="Date(dd/mm/yyy):	">
                <DatePicker format={dateFormat} className="flight-date" onChange={(value) => {
                  if (value) {
                    const dateVal = new Date(value);
                    setDateFlight(dateVal.getDate() + "/" + (dateVal.getMonth() + 1) + "/" + dateVal.getFullYear())
                  }
                }} />
              </Form.Item>
            </Form>
          </Col>
          <Col span={24} className="control">
            <Row className="control-button-group-wrapper">
              <Col offset={9}>
                <Button
                  style={{ width: "fit-content" }}
                  htmlType={"submit"}
                  onClick={submitFlight}
                >
                  {langId === "2" ? "Chọn" : "Next"}
                </Button>
              </Col>
              <Col>
                <Button onClick={handlePrint} style={{ width: "fit-content" }}>
                  Print
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="result-wrapper">
          <Row className="result-title-wrapper">
            <Col span={24} className="result-title">
              <h5>
                {langId === "2"
                  ? "CHI TIẾT LỊCH BAY CUT-OFF TIME"
                  : "CUT-OFF-TIME DETAIL"}
              </h5>
            </Col>
          </Row>
          <Row ref={componentRef} className="result-wrapper">
            <Col span={24} className="result">
              <Skeleton loading={loading}>{tableDataFlight}</Skeleton>
            </Col>
          </Row>
        </Row>
      </Row>
    </div>
  );
};
export default TabCutOffTime;
