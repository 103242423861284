/* eslint-disable */

import React, { useEffect, useState } from "react";
import { Row, Col } from "antd";
import "./page-css/Contacts.css";
import Banner from "../components/Banner";
import ContactsDetail from "../components/ContactsDetail";
import * as API from "../config/apiConfig";
import { useDispatch, useSelector } from "react-redux";
import { showLangSwitch } from "../actions/menu";

const Contacts = () => {
  const [contacts, setContacts] = useState([]);
  const [intro, setContactIntro] = useState([]);
  const { langId } = useSelector((state) => state.menu);
  const dispatch = useDispatch();
  const WAREHOUSE_ID = '02B1A03';

  useEffect(() => {
    dispatch(showLangSwitch(true));
  }, []);
  useEffect(() => {
    API.getContent(`Contact`, langId).then((res) => {
      setContacts(res.data);
    });
    renderIntro();
  }, [langId]);

  const contactIntro = {
    en: {
      name: "TAN SON NHAT CARGO SERVICES JOINT STOCK COMPANY",
      address:
        "46 - 48 Hau Giang Street, Ward 4, Tan Binh District, Ho Chi Minh City",
      phone: "Tel: (02-8) 38486489;",
      fax: " Fax: (02-8) 38427944",
      email: "operation@tcs.com.vn",
      warehouseID: `Warehouse ID (for Customs declaration): ${WAREHOUSE_ID}`,
    },
    vi: {
      name: "CÔNG TY CỔ PHẦN DỊCH VỤ HÀNG HÓA TÂN SƠN NHẤT",
      address: "46 - 48 Hậu Giang, Phường 4, Quận Tân Bình, Tp. Hồ Chí Minh",
      phone: "Điện thoại: (02-8) 38486489",
      fax: " Fax: (02-8) 38427944",
      email: "operation@tcs.com.vn",
      warehouseID: `Mã kho (để mở tờ khai hải quan): ${WAREHOUSE_ID} `,
    },
  };
  const renderIntro = () => {
    const content = langId === "2" ? contactIntro.vi : contactIntro.en;
    setContactIntro(
      <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
        <h2>{content.name}</h2>
        <p>{content.address}</p>
        <p>{content.phone}</p>
        <p> {content.fax}</p> 
        {/* <p>{content.sita} </p> */}
        <span style={{ marginBottom: '1em'}}>Email: <span style={{ color: "#ff6600" }}> {content.email}</span>
        </span>
        <p>{content.warehouseID}</p>
      </div>
    );
  };
  return (
    <Row className="contacts-wrapper">
      <Banner
        title={langId === "2" ? "LIÊN HỆ" : "CONTACT"}
        className="banner-wrapper"
        cover={"../../img/004.jpg"}
      />
      <Row className="contact-info">
        <Col offset={3} span={"auto"}>
          {intro}
        </Col>
      </Row>
      <Row className="contact-info">
        <Col style={{ display: "flex", justifyContent: "center" }} span={24}>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3919.044773091055!2d106.66063245078728!3d10.80788286152883!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3175293c988afdfb%3A0xc24d76162d853951!2sTan%20Son%20Nhat%20Cargo%20Services%20Co.%20Ltd!5e0!3m2!1sen!2s!4v1625037354022!5m2!1sen!2s"
            width="600"
            height="450"
            style={{ border: "0" }}
            allowfullscreen
            loading="lazy"
          ></iframe>
        </Col>
      </Row>

      <Row className="contacts-content-wrapper">
        <Col className="contacts-content" offset={3} span={21}>
          <ContactsDetail contacts={contacts} />
        </Col>
      </Row>
    </Row>
  );
};

export default Contacts;
