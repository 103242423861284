const initialState = {
	data: [],
	status: '',
};

const feedbackReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'POST_FEEDBACK':
			return (state = { ...state, status: action.payload });
		case 'STATUS_FEEDBACK':
			return (state = { ...state, status: action.payload });
		default:
			return state;
	}
};
export default feedbackReducer;
