import { Button, Row, Table, Tag, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { PlusCircleOutlined } from "@ant-design/icons";
import { ModalName, UserInviteStatus } from "../../../constant/enumerations";
import { setEsidStfList, setEsidStfUser, setModalEsidVisibility } from "../../../actions/esidExportAction";
import { useDispatch, useSelector } from "react-redux";
import { InviteUserModal } from "./InviteUserModal";
import { stringToColor } from "../../../utils/utils";
import { ExpGetAllUsers } from "../../../config/nvesidExportApi";
import { errorHandling } from "../../../config/AuthIntercept";

const { Title } = Typography;

export const ExpPermission = () => {
  const dispatch = useDispatch();
  const inviteUserModalVisible = useSelector((state) => state.esidExport.modal[ModalName.InviteUserModal]);
  const usersList = useSelector(state => state.esidExport.stfList);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getData = async () => {
      try {
        setLoading(true);
        const res = await ExpGetAllUsers();
        dispatch(setEsidStfList(res.data));
        setLoading(false);
      } catch (error) {
        setLoading(false);
        errorHandling(error);
      }
    }
    getData();
    // eslint-disable-next-line
  }, []); 

  const permissionColumns = [
    {
      title: "LOG ID",
      dataIndex: "codStfLog",
      key: "codStfLog",
      sorter: (a, b) => a.codStfLog.toLocaleLowerCase().localeCompare(b.codStfLog.toLocaleLowerCase()),
      width: '10%'
    },
    {
      title: "NAME",
      dataIndex: "namStf",
      key: "namStf",
      align: "left",
      sorter: (a, b) => a.namStf.toLocaleLowerCase().localeCompare(b.namStf.toLocaleLowerCase()),
      width: '20%'
    },
    {
      title: "ADMIN",
      dataIndex: "admin",
      key: "admin",
      width: '5%',
      render: (value) => value === 'Y' ? 'Y' : ''
    },
    {
      title: "DOC STAFF",
      dataIndex: "docStaff",
      key: "docStaff",
      width: '10%',
      render: (value) => value === 'Y' ? 'Y' : ''
    },
    {
      title: "DG STAFF",
      width: '10%',
      dataIndex: "dgStaff",
      key: "dgStaff",
      render: (value) => value === 'Y' ? 'Y' : ''
    },
    {
      title: "ACCEPTANCE",
      width: '7%',
      dataIndex: "acceptance",
      key: "acceptance",
      render: (value) => value === 'Y' ? 'Y' : ''
    },
    {
      title: "ACCOUNTANT",
      width: '7%',
      dataIndex: "accountant",
      key: "accountant",
      render: (value) => value === 'Y' ? 'Y' : ''
    },
    {
      title: "TECS",
      width: '7%',
      dataIndex: "tecs",
      key: "tecs",
      render: (value) => value === 'Y' ? 'Y' : ''
    },
    {
      title: "VIEW ESID",
      width: "20%",
      align: "left",
      render: (_, record) => record?.viewEsid?.split(',')?.filter(x => x !== 'Deny').map(x => <Tag color={stringToColor(x.trim())} style={{ marginTop: '2px' }}>{x.trim()}</Tag>)
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: '10%',
      render: (value) => <Tag bordered={false} color={tagColor(value)}>{value}</Tag>,
      sorter: (a, b) => a.status.toLocaleLowerCase().localeCompare(b.status.toLocaleLowerCase()),
    },
  ];

  const tagColor = (name) => {
    switch (name) {
      case UserInviteStatus.Grant:
        return "processing"
      case UserInviteStatus.Pending:
        return "warning"
      case UserInviteStatus.Declined:
        return "error"
      default:
        return ""
    }
  }

  const showInviteUserModal = (record = { status: UserInviteStatus.Grant }) => {
    dispatch(setEsidStfUser(record));
    dispatch(setModalEsidVisibility({ [ModalName.InviteUserModal]: true }));
  }

  return (
    <>
      <Row
        align="stretch"
        style={{ marginTop: "15px", marginLeft: "5px", marginBottom: "15px" }}
      >
        <Title style={{ flex: 1 }} level={4}>
          Permission Management
        </Title>
        <Button icon={<PlusCircleOutlined />} type="primary" onClick={() => showInviteUserModal()}>
          Invite User
        </Button>
      </Row>
      <Table
        key='id'
        loading={loading}
        dataSource={usersList}
        columns={permissionColumns}
        onRow={(record) => ({
          onClick: () =>   showInviteUserModal(record)
        })}
        pagination={{ pageSize: 100 }}
        rowClassName='cursorPointer'
        // loading={true}
      />
      {inviteUserModalVisible && (
        <InviteUserModal />
      )}
    </>
  );
};
