import { tempEnum } from "./enumerations";

const tempReqData = [
    {
        id: 1,
        code: tempEnum.FRO,
        title: 'Keep frozen (-10°C đến -18°C)'
    },
    {
        id: 2,
        code: tempEnum.COL,
        title: '+2°C đến +8°C'
    },
    {
        id: 3,
        code: tempEnum.CRT,
        title: '+15°C đến +25°C'
    },
    {
        id: 4,
        code: tempEnum.ERT,
        title: '+2°C đến +25°C'
    },
    // {
    //     id: 5,
    //     code: tempEnum.ACT,
    //     title: 'Lưu trữ trong khu vực có nhiệt độ môi trường tối thiểu cao hơn +5°C nhiệt độ cài đặt của thùng(tối ưu cao hơn +15°C đến +25°C)'
    // }
];

export default tempReqData