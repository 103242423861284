import { Button, Radio } from 'antd';
import React, {useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import RoleClaim from '../constant/roleClaim';
import useHasRoles from '../hooks/useHasRoles';

export const NotAllowed = () => {
  const history = useHistory();
  const { t } = useTranslation('common');

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  const hasAdmin = useHasRoles([RoleClaim.Admin, RoleClaim.BusinessAnalyst]);
  const hasAgent = useHasRoles([RoleClaim.Agent]);
  const hasVipAgent = useHasRoles([RoleClaim.VipAgent]);
  const hasAirlines = useHasRoles([RoleClaim.Airlines]);
  const haschekIn = useHasRoles([RoleClaim.CheckInOnl]);

  const getLogin = (e) => {
    if (e.target.value === 'statistic') {
      history.push(`/${e.target.value}`);  
      return;
    }
    history.push(`/Awb/${e.target.value}`);
  };

  return (
    <div style={{ marginTop: '50px', textAlign: 'center' }}>
      {t('home.notAllowed')}
      <div style={{ marginTop: "20px" }}>
        {hasAgent && <Radio onClick={getLogin} value='Agent'>{t('home.agent')}</Radio>}
        {hasVipAgent && <Radio onClick={getLogin} value='samsung'>{t('home.agent')}</Radio>}
        {hasAirlines && <Radio onClick={getLogin} value='Airlines'>{t('home.airlines')}</Radio>}
        {haschekIn && <Radio onClick={getLogin} value='checkoutLogin'>{t('home.checkin')}</Radio>}
        {hasAdmin && <Radio onClick={getLogin} value='statistic'>{t('home.statistic')}</Radio>}
      </div>
      <div style={{ marginTop: "20px" }}>
        <Button type="primary" onClick={() => history.push('/AwbLogin')}>
          Đăng nhập
        </Button>
      </div>
    </div>
  );
};
