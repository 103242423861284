import { CameraOutlined, UploadOutlined } from "@ant-design/icons";
import { Button, Col, Row, Upload } from "antd";
import "moment/locale/en-au";
import QrScanner from "qr-scanner";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { QrReader } from "react-qr-reader";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { GetEsidExportByAwb } from "../config/nvesidExportApi";
import { setEsidExpSelected } from "../actions/esidExportAction";

import "./component-css/QRReader.css";
import { ViewFinder } from "./ViewFinder";

const QRReaderView = () => {
  const { t } = useTranslation("common");
  const history = useHistory();
  const dispatch = useDispatch();
  const [camSelected, setCamSelected] = useState("environment");
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);

  const uploadProps = {
    name: "file",
    headers: {
      authorization: "authorization-text",
    },
    maxCount: 1,
    showUploadList: false,
    accept: "image/*",
    beforeUpload: (file) => {
      const isImage = file.type.includes("image/");
      if (isImage) {
        QrScanner.scanImage(file, { returnDetailedScanResult: true })
          .then((result) => {
            setData(result.data);
            setError(null);
          })
          .catch((e) => setData("No QR code found."));
      }
      return false;
    },
  };

  const handleSetCam = () => {
    setData(null);
    setError(null);
    setCamSelected("environment");
  };

  const handlePhotoLib = () => {
    setData(null);
    setError(null);
    setCamSelected(null);
  }

  const handleRedirectAcceptance = () => {
    history.push("/nvesid");
  };

  const handleFindEsidExp = async (qrText = '') => {
    if (qrText?.length < 11) {
      setData(null);
      setError(t('Not Found'));
      return;
    }
    const awbPfx = qrText.substring(0, 3);
    const awbNum = qrText.substring(3, qrText.length);
    try {
      const res = await GetEsidExportByAwb(awbPfx, awbNum);
      const exp = res.data;
      dispatch(setEsidExpSelected(exp));
      history.push('/nvesid');
    } catch (error) {
      setData(null);
      setError(t('Not Found'));
    }
    // const qrStr = qrText.split('#');
    // const idText = qrStr[1];
    // if (!isNumeric(idText)) {
    //   setData(null);
    //   setError(t('Not Found'));
    //   return;
    // }
    // const expId = parseInt(idText);
    // try {
    //   const res = await GetEsidExportById(expId);
    //   const exp = res.data;
    //   dispatch(setEsidExpSelected(exp));
    //   history.push('/nvesid');
    // } catch (error) {
    //   setData(null);
    //   setError(t('Not Found'));
    // }
  }

  useEffect(() => {
    if (data && data.length === 11) {
      handleFindEsidExp(data);
    } else {
      setData(null);
      setError(t('Not Found'));
    }
    // eslint-disable-next-line
  }, [data]);

  return (
    <div className="AppQR" >
      <div style={{ width: "400px" }}>
        {camSelected ? (
          <QrReader
            ViewFinder={ViewFinder}
            scanDelay={500}
            constraints={{
              facingMode: camSelected,
            }}
            onResult={(result, error) => {
              if (result) {
                setData(result.text);
              }

              if (error) {
                setError(error.message);
              }
            }}
            style={{ width: "100%" }}
          />
        ) : (
          <section>
            <div
              style={{
                width: "100%",
                paddingTop: "100%",
                overflow: "hidden",
                position: "relative",
              }}
            >
              <ViewFinder />
            </div>
          </section>
        )}
      </div>
      {data !== "" && <p>{data}</p>}
      {error !== "" && <p>{error}</p>}
      <Row
        gutter={[16, 16]}
        style={{
          display: "flex",
          width: "100%",
          justifyContent: 'center',
          alignItems: "center",
          rowGap: '10px'
        }}
      >
          <Col style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <Upload {...uploadProps}>
            <Button icon={<UploadOutlined />} onClick={handlePhotoLib}>
              {t("qrReader.photoLibrary")}
            </Button>
          </Upload>
        </Col>
        <Col style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Button icon={<CameraOutlined />} onClick={handleSetCam}>
            {t("qrReader.camera")}
          </Button>
        </Col>
        <Col style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Button onClick={handleRedirectAcceptance}>
            {t("home.adminEsidExport")}
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default QRReaderView;
