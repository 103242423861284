import React, { useState, useEffect, useRef} from "react";
import { Button, Space, Form, Tabs, Layout, notification, BackTop, Row, Col, Typography, Input, Modal, Checkbox } from "antd";
import { SearchForm } from "./components/SearchForm";
import styles from './style.module.css'
import { GeneralInfomationContainer } from "./components/GeneralInfo/GeneralInfomationContainer";
import { CodCtcTypEnum, DgrStatusEnum, esidExpAdminTabsEnum, EsidStatusEnum, LivAnmStatusEnum } from "../../../constant/enumerations";
import { useTranslation } from "react-i18next";
import { errorHandling } from "../../../config/AuthIntercept";
import { useHistory, useLocation } from "react-router-dom";
import { CreateCertLivAnm, CreateDeclDgr, CreateDgrAcceptance, CreateEsidExportAcceptance, DgUnFinalize, ExpAviAccept, ExpConsolFinalize, ExpConsolUnFinalize, ExpDgCheck, ExpDgCheckOK, ExpGetQrCodeImg, ExpUnFinalize, ExportEsidPdf, GetCertLivAnmByEsid, GetDeclDgrByEsid, SaveEsidExpCarDim, SaveEsidExpCarWgt, UpdateCertLivAnimal, UpdateDgrByDgrId, UpdateEsidExportById } from "../../../config/nvesidExportApi";
import GoodsTable from "./components/DangerousGoods/GoodsTable";
// import AnimalsTable from "./components/LivingAnimals/AnimalsTable";
import { AcceptanceForm } from "./components/Acceptance/AcceptanceForm";
import { SelectEsidModal } from "./components/EsidModal/SelectEsidModal";
import { useSelector, useDispatch } from "react-redux";
import { setAirportList, setEsidExpSelected, setExpConsol, setExpConsolDtlList, setExpConsolEdit, setExpConsolRegServices } from '../../../actions/esidExportAction'
import { setDgrSelectedValue, setDgrTypeStatusOK } from "../../../actions/dgrGoodsAction";
import { setLivAnmsSelectedValue } from "../../../actions/livAnmAction";
import { useCallback } from "react";
import { CancelEsid, CreateExpConsol, GetAllAirports, GetConsolByEsid, GetDgrTypeStatusOK, RejectDGR, UpdateExpConsol } from "../../../config/esidExportApi";
import { ArrowUpOutlined } from "@ant-design/icons";
import { FinalizeModal } from "./components/FinalizeModal";
import moment from "moment";
import tempReqData from "../../../constant/tempDropdown";
import { useReactToPrint } from "react-to-print";
import { RegistryExportPrint } from "../../../components/RegistryExportPrint";
import { ExpDeclDgrPrint } from "../../../components/tracking/ExpDeclDgr/ExpDeclDgrPrint";
import { ExpCertLivAnmPrint } from "../../../components/tracking/CertLivAnm/ExpCertLivAnmPrint";
import DryIceTable from "./components/DangerousGoods/DryIceTable";
import PinTable from "./components/DangerousGoods/PinTable";
import { RejectDangerousGoodsModal } from "./components/DangerousGoods/RejectDangerousGoodsModal";
import { ExpDeclConsolContainer } from "./components/ExpConsol/ExpConsolContainer";
import { ExpDeclConsolPrint } from "../../../components/tracking/ExpDeclConsol/ExpDeclConsolPrint";
import useHasRoles from '../../../hooks/useHasRoles';
import RoleClaim from '../../../constant/roleClaim';
import { encodeUint8Array } from "../../../utils/utils";
import { AviDeclaration } from "./components/LivingAnimals/AviDeclaration";
import { ExpPaymentInfo } from "./components/Payment/ExpPaymentInfo";

const objectToAntFormData = (object) => {
  const antFormData = [];
  for (const key in object) {
    antFormData.push({
      name: [key],
      value: object[key],
    });
  }
  return antFormData;
};
const { TabPane } = Tabs;
const { Content, Footer } = Layout;
let defaultTabs = [esidExpAdminTabsEnum.ExpInfo];
const handlingInfoKeys = [
  "shcPer",
  "shcOth002",
  "shcVal",
  "shcAvi",
  "shcDgr",
  "shcVun",
  "shcHum",
  "shcDip",
  "shcBup",
]
const isObjectEmpty = (obj) => {
  if (JSON.stringify(obj) === '{}') {
    return true;
  }
  for ( const key in obj ) {
    if (obj[key] !== null && obj[key] !== '') {
      return false;
    }
  }
  return true;
}
export const printConfig = `
  @media all {
    .page-break {
      display: none;
    }
  }
  @media print {
    html, body {
      padding: 0.7rem 0.7rem 0.7rem 0.7rem;
      height: initial !important;
      overflow: initial !important;
      -webkit-print-color-adjust: exact;
    }
  }
  @media print {
    .page-break {
      margin-top: 0.7rem;
      display: block;
      page-break-before: always;
    }
  }
  @media print {
    @page { 
      size: A4 portrait;
      margin: 0;
    }
  }
`
const paidStatus = ['AM', 'AP', 'QR', 'FN', 'OK'];
export const finalizedCodSts = [
  EsidStatusEnum.FR,
  EsidStatusEnum.AQ,
  EsidStatusEnum.AC,
  EsidStatusEnum.AM,
  EsidStatusEnum.AP,
  EsidStatusEnum.QR,
  EsidStatusEnum.FN,
  EsidStatusEnum.OK,
  EsidStatusEnum.XX,
]
export const isIncludeSts = (codSts, acceptedStsArr) => {
  if ( acceptedStsArr.includes(codSts) ) {
    return true;
  }
  return false;
}
export const EsidExp = () => {
  const { t } = useTranslation("common");
  const location = useLocation();
  const history = useHistory();
  const selectEsidExport = useSelector((state) => state.esidExport.esidExpSelected);
  const expConsolEdit = useSelector(state => state.esidExport.expConsolEdit);
  const expConsol = useSelector(state => state.esidExport.expConsol);
  const consolDtlList = useSelector(state => state.esidExport.consolDtlList);
  const consolRegServices = useSelector((state) => state.esidExport.consolRegServices);
  const expCarDimToEdit = useSelector((state) => state.esidExport.expCarDimToEdit);
  const expCarWgtToEdit = useSelector((state) => state.esidExport.expCarWgtToEdit);
  const selectDgrGoods = useSelector((state) => state.dgrGoods.dgrSelectedValue) || {};
  const selectLivAnms = useSelector((state) => state.livAnms.livAnmsSelectedValue) || {};
  const selectAirports = useSelector((state) => state.airport.airports);
  const selectUsername = useSelector((state) => state.userAwb.currentUser.fullName || state.userAwb.currentUser.user_name);
  const dgrSelectedValue = useSelector((state) => state.dgrGoods.dgrSelectedValue);
  const currentUser = useSelector((state) => state.userAwb.currentUser);
  const isEsidExpFinalized = selectEsidExport ? isIncludeSts(selectEsidExport.codSts, finalizedCodSts) : false;
  const isDgrReceived = selectEsidExport ? isIncludeSts(selectEsidExport.codSts, [...finalizedCodSts, EsidStatusEnum.DR]) : false;
  // const [esidExport, setEsidExport] = useState();
  const [shipperForm] = Form.useForm();
  const [agentForm] = Form.useForm();
  const [consigneeForm] = Form.useForm();
  const [notifyForm] = Form.useForm();
  const [airWaybillForm] = Form.useForm();
  const [handlingInfoForm] = Form.useForm();
  const [iataForm] = Form.useForm();
  const [dgChkForm] = Form.useForm();
  const [senderForm] = Form.useForm();
  const [acptStfForm] = Form.useForm();
  const [dgrAcptStfForm] = Form.useForm();
  const [weightForm] = Form.useForm();
  const [modalSearchForm] = Form.useForm();
  const [tabPaneActive, setTabPaneActive] = useState(esidExpAdminTabsEnum.ExpInfo);
  const [esidTabs, setEsidTabs] = useState([...defaultTabs]);
  const [esidModalVisible, setEsidModalVisible] = useState(false);
  const [finalizeModalVisible, setFinalizeModalVisible] = useState(false);
  const [isSendToAirlineModal, setIsSendToAirlineModal] = useState(false);
  const [isInformationSaving, setIsInformationSaving] = useState(false);
  const [isDgrAccepted, setIsDgrAccepted] = useState(false);
  const printComponentRef = useRef(null);
  const printDgrComponentRef = useRef(null);
  const printLivAnmComponentRef = useRef(null);
  const dispatch = useDispatch();
  const [adminForm] = Form.useForm();
  const [rejectForm] = Form.useForm();
  const [consoltMstForm] = Form.useForm();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const consolPrintRef = useRef();

  const hasAcceptance = useHasRoles([RoleClaim.EsidAdmin, RoleClaim.EsidACPT]);
  const hasDgStaff = useHasRoles([RoleClaim.EsidAdmin, RoleClaim.EsidDgStaff]);
  const hasDocStaff = useHasRoles([RoleClaim.EsidAdmin, RoleClaim.EsidDocStaff]);
  const hasTecs = useHasRoles([RoleClaim.EsidAdmin, RoleClaim.EsidTecsAgent]);
  const hasAirlines = useHasRoles([RoleClaim.Airlines]);

  const [imgQRUrl, setImgQRUrl]= useState();
  const [tecsDisabled, setTecsDisabled] = useState(false);
  useEffect(() => {
    if (!selectEsidExport) {
      setTecsDisabled(false);
      return;
    }
    if (selectEsidExport?.shcCod002 === "TEC") {
      setTecsDisabled(hasTecs);
    } else {
      setTecsDisabled(true);
    }
    if (selectEsidExport) {
      const getImgQR = async () => {
        try {
          const res = await ExpGetQrCodeImg(`${selectEsidExport.codAwbPfx}${selectEsidExport.codAwbNum}`);
          const arrayBuffer = res.data;
          const bytes = new Uint8Array(arrayBuffer);
          setImgQRUrl(`data:image/png;base64,${encodeUint8Array(bytes)}`)  
        } catch (error) {
          errorHandling(error);
        }
      }
      getImgQR();
      dgChkForm.setFieldsValue({ desRmk: selectEsidExport.desRmk });
    } else {
      setImgQRUrl(null);
      dgChkForm.resetFields();
    }
    // eslint-disable-next-line
  }, [selectEsidExport])

  const handleReject = async (formValue) => {
    setConfirmLoading(true);
    try{
      let data = {
        declId: selectDgrGoods.id,
        esidId: selectDgrGoods.esidId,
        reject: 'Y',
        accept: 'N',
        stfCod: currentUser.user_code,
        stfNam: currentUser.user_name,
        ...formValue
      };

      const res = await RejectDGR(data);
      dispatch(setDgrSelectedValue(
        {
          ...res.data,
          dgrItems: res.data.dgrItems.map((item, index) => (
            {
              ...item,
              key: index,
              codSts: item.codSts ?? DgrStatusEnum.NY
            }
          ))
        }
      ));
      setConfirmLoading(false);
      showSuccess(t('admin.notification.saveRejectSuccess'));
    }catch(error){
      setConfirmLoading(false);
      errorHandling(error, history, location.pathname, true);
    }
  }
  
  //TODO: fix when render
  // window.scrollTo({ left: 0, top: 0, behavior: 'auto' });

  const handleSubmit = (formValue) => {
    console.log(formValue);
  }

  const showSuccess = (msg) => {
    notification.success({
      message: t("Congrats"),
      description: msg,
      top: 100,
    })
  }
  // eslint-disable-next-line
  const showWarning = (msg) => {
    notification.warning({
      message: t("Oops"),
      description: msg,
      top: 100,
    })
  }
  const showError = (msg) => {
    notification.error({
      message: t("Oops"),
      description: msg,
      top: 100,
    })
  }

  const fetchAllAirport = async () => {
    try {
      const { data } = await GetAllAirports();
      dispatch(setAirportList(data));
    } catch (error) {
      errorHandling(error, history, location.pathname, false);
    }
  }
  
  const handleShowTabPane = (eisdExp) => {
    const esidTabList = [...defaultTabs];
      if (eisdExp?.shcDgr === 'Y') {
        esidTabList.push(esidExpAdminTabsEnum.ExpDgr);
      }
      if (eisdExp?.dgChk === 'Y') {
        esidTabList.push(esidExpAdminTabsEnum.ExpDgCheck);
      }
      if (eisdExp?.shcAvi === 'Y') {
        esidTabList.push(esidExpAdminTabsEnum.ExpLivAnm);
      }
      if (eisdExp?.shcConsol === 'Y') {
        esidTabList.push(esidExpAdminTabsEnum.ExpConsol);
      }
      if (eisdExp) {
        esidTabList.push(esidExpAdminTabsEnum.Acceptance);
      }
      if (paidStatus.includes(eisdExp?.codSts) && hasDocStaff) {
        esidTabList.push(esidExpAdminTabsEnum.Payment);
      }
      setEsidTabs(esidTabList);
  }

  const redirectToEsidInfo = () => {
    setTabPaneActive(esidExpAdminTabsEnum.ExpInfo);
  }

  const handleClearForm = () => {
    shipperForm.resetFields();
    consigneeForm.resetFields();
    agentForm.resetFields();
    notifyForm.resetFields();
    handlingInfoForm.resetFields();
    airWaybillForm.resetFields();
    acptStfForm.resetFields();
    dgrAcptStfForm.resetFields();
    senderForm.resetFields();
    dispatch(setDgrSelectedValue(null));
    dispatch(setLivAnmsSelectedValue(null));
    dispatch(setExpConsol(null));
    dispatch(setExpConsolDtlList(null));
    dispatch(setExpConsolRegServices(null));
    dispatch(setExpConsolEdit(null));
  }
  
  useEffect(() => {
      fetchAllAirport();
    // eslint-disable-next-line
  },[])

  const getConsolByESID = async () => {
    if (selectEsidExport?.id) {
      try {
        const res = await GetConsolByEsid(selectEsidExport.id);
        dispatch(setExpConsol(res.data));
      } catch (error) {
        dispatch(setExpConsol(null));
      }
    } else {
      dispatch(setExpConsol(null));
    }
  }

  useEffect(() => {
    if (selectEsidExport) {
      handleClearForm();
      handleShowTabPane(selectEsidExport);
      const { 
        id, 
        shipper, 
        consignee, 
        agent, 
        notify, 
        shpRegNam,
        shpRegIdx,
        shpRegTel,
        acpDat,
        acpStfNam,
        expCarWgt,
        dgrAcpStfNam,
        dgrAcpDat,
        desRmk002,
        ...rest 
      } = selectEsidExport;
      shipperForm.setFields(objectToAntFormData(shipper));
      consigneeForm.setFields(objectToAntFormData(consignee));
      agentForm.setFields(objectToAntFormData(agent));
      notifyForm.setFields(objectToAntFormData(notify));
      handlingInfoForm.setFields(objectToAntFormData(getHandlingInfomation(rest)));
      acptStfForm.setFields(objectToAntFormData({ acpStfNam: acpStfNam ?? selectUsername, acpDat: isEsidExpFinalized ? moment(acpDat) : moment() }))
      if (selectEsidExport.shcDgr === 'Y' && dgrAcpStfNam && dgrAcpDat) {
        dgrAcptStfForm.setFields(objectToAntFormData({ 
          dgrAcpStfNam: dgrAcpStfNam, 
          dgrAcpDat: dgrAcpDat ? moment(dgrAcpDat) : moment()
        }));
      }
      
      senderForm.setFields(objectToAntFormData({
        shpRegNam,
        shpRegIdx,
        shpRegTel,
        desRmk002
      }))

      const oriFlgt = selectAirports.find((x) => x.codApt === rest.codOri);
      const fdsFlgt = selectAirports.find((x) => x.codApt === rest.codFds);
      const ctyfds = selectAirports.find((x) => x.codCty === rest.codFds);
      airWaybillForm.setFields(objectToAntFormData({
        ...rest,
        flightNo: `${rest.codFltCar}${rest.codFltNum}`,
        originName: oriFlgt && oriFlgt.namApt ? oriFlgt.namApt : null,
        fdsName:  fdsFlgt?.namApt || ctyfds?.namCty,
        datFltOri: moment(rest.datFltOri),//.format(formatFltDateInput).toLocaleUpperCase(),
        shcConsol: rest?.shcConsol === 'Y' ? true : false
      }));

      (rest.shcDgr && rest.shcDgr === 'Y') && fetchDgrGoods(id);
      (rest.shcAvi && rest.shcAvi === 'Y') && fetchLivAnms(id);
      if (rest?.shcConsol === 'Y') {
        getConsolByESID();
      }
    }
    else {
      handleClearForm();
      handleShowTabPane(selectEsidExport);
    }
    // eslint-disable-next-line
  },[selectEsidExport])
  
  const getHandlingInfomation = (data) => {
    const checkboxArr = [];
    let shcOth001 = null;
    let shcColStr = null;
    let shcOth = null;
    let shcColTemReq = null;
    let shcColTemReqString = null;
    let shcVunNml = null;
    let shcEcort = null;
    let shcReinforce = null;
    let shcCod002 = null;
    
    if (data) {
      handlingInfoKeys.forEach((item) => {
        if (data[item] && data[item] === 'Y') {
          checkboxArr.push(item);
        }
      });
      if (data.shcOth001 && data.shcOth001 === 'Y') {
        shcOth001 = 'checked'
      }
      if (data.shcOth && data.shcOth === 'Y') {
        shcOth = 'checked'
      }
      if (data.shcColStr && data.shcColStr === 'Y') {
        shcColStr = 'checked'
      }
      if (data.shcVunNml && data.shcVunNml === 'Y') {
        shcVunNml = 'checked'
      }
      if (data.shcEcort && data.shcEcort === 'Y') {
        shcEcort = 'checked'
      }
      if (data.shcReinforce && data.shcReinforce === 'Y') {
        shcReinforce = 'checked'
      }
      if (data.shcColTemReq) {
        const temp = tempReqData.find(x => x.code === data.shcColTemReq);
        shcColTemReq = data.shcColTemReq ?? null;
        shcColTemReqString = temp.title ?? null;
      }
      if (data?.shcCod002 === 'TEC') {
        shcCod002 = 'checked';
      }
      return {
        handlingInfomations: checkboxArr,
        shcColStr,
        shcOth,
        shcOth001,
        shcColTemReq,
        shcColTemReqString,
        shcOthReq: data.shcOthReq,
        shcVunNml,
        shcEcort,
        shcReinforce,
        shcCod002
      }
    }
  }

  const fetchDgrGoods = useCallback(async (esidId) => {
      try {
        const dgrRespond = await GetDeclDgrByEsid(esidId);
        dgrRespond.data && 
        dispatch(setDgrSelectedValue(
          {
            ...dgrRespond.data,
            dgrItems: dgrRespond.data.dgrItems.map((item, index) => (
              {
                ...item,
                key: index,
                codSts: item.codSts ?? DgrStatusEnum.NY
              }
            ))
          }
        ));
      } catch (error) {
        errorHandling(error, history, location.pathname,false);
        dispatch(setDgrSelectedValue([]));
      }
      // eslint-disable-next-line
  },[selectDgrGoods]) 

  const fetchLivAnms = useCallback(async (esidId) => {
      try {
        iataForm.resetFields();
        const livAnmRespond = await GetCertLivAnmByEsid(esidId);
        if (livAnmRespond.data) {
          iataForm.setFieldsValue(livAnmRespond.data);
          dispatch(setLivAnmsSelectedValue(
            {
              ...livAnmRespond.data,
              certDtl: livAnmRespond.data.certDtl.map((item, index) => (
                {
                  ...item,
                  key: index,
                  codSts: item.codSts ?? LivAnmStatusEnum.NY
                }
              ))
            }
          ));
        }
      } catch (error) {
        errorHandling(error, history, location.pathname,false);
        dispatch(setLivAnmsSelectedValue([]));
      }
      // eslint-disable-next-line
  },[selectLivAnms]) 

  const getUpdatedValue = (oldValue, newValue, codCtc) => {
    return {
      ...oldValue,
      ...newValue,
      codAwbNum: oldValue?.codAwbNum ?? selectEsidExport?.codAwbNum,
      codAwbPfx: oldValue?.codAwbPfx ?? selectEsidExport?.codAwbPfx,
      codCtcTyp: oldValue?.codCtcTyp ?? codCtc,
      desAddStt: newValue?.desAddStt ?? "",
      codSts: oldValue?.codSts ?? 'NY'
    }
  }
  const getHandlingInfoValueForSubmit = async () => {
    const { 
      handlingInfomations, 
      shcColStr, 
      shcOth001, 
      shcCod002,
      shcColTemReq, 
      shcOthReq, 
      shcOth, 
      shcVunNml,
      shcEcort,
      shcReinforce
    } = await handlingInfoForm.validateFields();
    let handlingInfoValue = {
      shcColTemReq,
      shcOthReq,
      shcColStr: shcColStr || shcColStr === 'checked'  ? 'Y' : 'N',
      shcOth: shcOth ? 'Y' : 'N',
      shcOth001: shcOth001 || shcOth001 === 'checked' ? 'Y' : 'N',
      shcCod002: shcCod002 ? 'TEC' : 'TCS',
      shcVunNml: shcVunNml ? 'Y' : 'N',
      shcEcort: shcEcort ? 'Y' : 'N',
      shcReinforce: shcReinforce ? 'Y' : 'N',
    }
    handlingInfoKeys.forEach((key) => {
      if (handlingInfomations.includes(key)) {
        handlingInfoValue[key] = "Y";
      } else {
        handlingInfoValue[key] = "N";
      }
    })
    return handlingInfoValue;
  }
  //submit ExpInfo
  const handleSubmitExpInfo = async () => {
    if (selectEsidExport) {
      const shipperValue = await shipperForm.validateFields();
      const agentValue = await agentForm.validateFields();
      const consigneeValue = await consigneeForm.validateFields();
      const notifyValue = await notifyForm.validateFields();
      const airWaybillValue = await airWaybillForm.validateFields();
      const senderValue = await senderForm.validateFields();
      const handlingInfoValue = await getHandlingInfoValueForSubmit();
      //name required : check name if it null when object not empty (contain some fields has value)
      if ( !isObjectEmpty(shipperValue) && (!shipperValue.namCtc || shipperValue.namCtc === '') ) {
        showError(t("formInfo.nameShp_Err"));
        return;
      }
      if ( !isObjectEmpty(consigneeValue) && (!consigneeValue.namCtc || consigneeValue.namCtc === '') ) {
        showError(t("formInfo.nameCne_Err"));
        return;
      }
      if ( !isObjectEmpty(agentValue) && (!agentValue.namCtc || agentValue.namCtc === '') ) {
        showError(t("formInfo.nameAgt_Err"));
        return;
      }
      if ( !isObjectEmpty(notifyValue) && (!notifyValue.namCtc || notifyValue.namCtc === '') ) {
        showError(t("formInfo.notifyCo_Err"));
        return;
      }
      const updatedValue = {
        ...selectEsidExport,
        shipper: isObjectEmpty(shipperValue) ? 
          null : 
          getUpdatedValue(selectEsidExport.shipper, shipperValue, CodCtcTypEnum.Shipper),

        agent: isObjectEmpty(agentValue) ? 
          null : 
          getUpdatedValue(selectEsidExport.agent, agentValue, CodCtcTypEnum.Agent),

        consignee: isObjectEmpty(consigneeValue) ?
          null : 
          getUpdatedValue(selectEsidExport.consignee, consigneeValue, CodCtcTypEnum.Consignee),

        notify: isObjectEmpty(notifyValue)? 
          null : 
          getUpdatedValue(selectEsidExport.notify, notifyValue, CodCtcTypEnum.Notify),

        ...airWaybillValue,
        ...handlingInfoValue,
        ...senderValue,
        datFltOri: moment(moment(airWaybillValue.datFltOri), "DD/MM/YYYY HH:mm").toISOString(),
        codFltCar: airWaybillValue.flightNo.substring(0,2),
        codFltNum: airWaybillValue.flightNo.substring(2, airWaybillValue.flightNo.length),
        expCarDim: expCarDimToEdit || selectEsidExport.expCarDim || [],
        expCarWgt: expCarWgtToEdit ? [expCarWgtToEdit] : (selectEsidExport.expCarWgt || []),
        codSts: selectEsidExport.codSts ?? 'NY',
        shcConsol: airWaybillValue.shcConsol ? 'Y' : 'N',
        shpRegDat: selectEsidExport?.shpRegDat || (new Date()).toISOString(),
      } 
      setIsInformationSaving(true);
      try {
        const { data } = await UpdateEsidExportById(updatedValue);
        dispatch(setEsidExpSelected(data));
        setIsInformationSaving(false);
        showSuccess(t('admin.notification.saveSuccess'));
      } catch (error) {
        errorHandling(error, history, location.pathname);
        setIsInformationSaving(false);
      }
    }
  }
  
  const handleDgrAcpt = async () => {
    if ( selectEsidExport && selectDgrGoods ) {
      if ( !selectDgrGoods.dgrItems || selectDgrGoods.dgrItems.length === 0 ) {
        showError(t('admin.notification.dgrEmptyErr'));
        return;
      }
      const qtyPcsTotal = dgrSelectedValue.dgrItems?.map(item => item.qtyPcs).reduce((a, b) => a + b, 0) || 0;
      if (selectEsidExport.qtyPcs < qtyPcsTotal) {
        Modal.error({
            title: "Thông báo",
            content: "Số lượng tiếp nhận lớn hơn số lượng khai báo. Vui lòng kiểm tra lại.",
            onOk: () => {
                return;
            }
        });
        return;
      }
      setIsDgrAccepted(true);
      const createDgrPostValue = {
        esidId: selectEsidExport.id,
        shipper: selectEsidExport.shipper,
        consignee: selectEsidExport.consignee,
        notify: selectEsidExport.notify,
        agent: selectEsidExport.agent,
        codAwbPfx: selectEsidExport.codAwbPfx,
        codAwbNum: selectEsidExport.codAwbNum,
        codAwbSfx: selectEsidExport.codAwbSfx,
        codOri: selectEsidExport.codOri,
        codFds: selectEsidExport.codFds,
        codFltCar: selectEsidExport.codFltCar,
        codFltNum: selectEsidExport.codFltNum,
        datFltOri: selectEsidExport.datFltOri,
        shpRegDat: selectDgrGoods?.shpRegDat || new Date(),
        shpRegIdx: selectEsidExport.shpRegIdx,
        shpRegNam: selectEsidExport.shpRegNam,
        shpRegAdr: selectEsidExport.shpRegAdr,
        shpRegTel: selectEsidExport.shpRegTel,
        shpRegEml: selectEsidExport.shpRegEml,
        shpRegSignYn: selectEsidExport.shpRegSignYn,
        CodSts: "NY",
        UsrCre: selectEsidExport.UsrCre,
        UsrUpd: selectEsidExport.UsrUpd,
        dgrItems: selectDgrGoods.dgrItems ?? [],
      };
      try {
        let data = {};
        if ( selectDgrGoods.id ) {
          const respond = await UpdateDgrByDgrId(selectDgrGoods);
          data = respond && respond.data;
        }
        else {
          const respond = await CreateDeclDgr(createDgrPostValue);
          data = respond && respond.data;
        }
        data && dispatch(setDgrSelectedValue(
          {
            ...data,
            dgrItems: data?.dgrItems.map((item, index) => (
                {
                  ...item,
                  key: index,
                  codSts: item.codSts ?? DgrStatusEnum.NY
                }
            ))
          }
        ))

        const postValue = {
          ...selectEsidExport,
          acpStfNam: selectEsidExport.acpStfNam ?? null,
          acpDat: new Date(selectEsidExport.acpDat).toISOString(),
        }
        const dgrAcceptanceRes = await CreateDgrAcceptance(postValue);
        dispatch(setEsidExpSelected(dgrAcceptanceRes.data));
        showSuccess(t('admin.notification.saveSuccess'));
      } catch (error) {
        errorHandling(error, history, location.pathname)
      }
    }
    setIsDgrAccepted(false);
  }
  const handleSubmitDgrGoods = async () => {
    if ( selectEsidExport && selectDgrGoods ) {
      if ( !selectDgrGoods.dgrItems || selectDgrGoods.dgrItems.length === 0 ) {
        showError(t('admin.notification.dgrEmptyErr'));
        return;
      }
      const qtyPcsTotal = dgrSelectedValue.dgrItems?.map(item => item.qtyPcs).reduce((a, b) => a + b, 0) || 0;
      if (selectEsidExport.qtyPcs < qtyPcsTotal) {
        Modal.error({
            title: "Thông báo",
            content: "Số lượng tiếp nhận lớn hơn số lượng khai báo. Vui lòng kiểm tra lại.",
            onOk: () => {
                return;
            }
        });
        return;
      }
      setIsInformationSaving(true);
      const createDgrPostValue = {
        esidId: selectEsidExport.id,
        shipper: selectEsidExport.shipper,
        consignee: selectEsidExport.consignee,
        notify: selectEsidExport.notify,
        agent: selectEsidExport.agent,
        codAwbPfx: selectEsidExport.codAwbPfx,
        codAwbNum: selectEsidExport.codAwbNum,
        codAwbSfx: selectEsidExport.codAwbSfx,
        codOri: selectEsidExport.codOri,
        codFds: selectEsidExport.codFds,
        codFltCar: selectEsidExport.codFltCar,
        codFltNum: selectEsidExport.codFltNum,
        datFltOri: selectEsidExport.datFltOri,
        shpRegDat: selectDgrGoods?.shpRegDat || new Date(),
        shpRegIdx: selectEsidExport.shpRegIdx,
        shpRegNam: selectEsidExport.shpRegNam,
        shpRegAdr: selectEsidExport.shpRegAdr,
        shpRegTel: selectEsidExport.shpRegTel,
        shpRegEml: selectEsidExport.shpRegEml,
        shpRegSignYn: selectEsidExport.shpRegSignYn,
        CodSts: "NY",
        UsrCre: selectEsidExport.UsrCre,
        UsrUpd: selectEsidExport.UsrUpd,
        dgrItems: selectDgrGoods.dgrItems ?? [],
      };
      try {
        let data = {};
        if ( selectDgrGoods.id ) {
          const respond = await UpdateDgrByDgrId(selectDgrGoods);
          data = respond && respond.data;
        }
        else {
          const respond = await CreateDeclDgr(createDgrPostValue);
          data = respond && respond.data;
        }
        data && dispatch(setDgrSelectedValue(
          {
            ...data,
            dgrItems: data?.dgrItems.map((item, index) => (
                {
                  ...item,
                  key: index,
                  codSts: item.codSts ?? DgrStatusEnum.NY
                }
            ))
          }
        ))
        showSuccess(t('admin.notification.saveSuccess'));
      } catch (error) {
        errorHandling(error, history, location.pathname)
      }
    }
    setIsInformationSaving(false);
  }
  // TODO: update API
  const handleSubmitLivAnms = async () => {
    if ( selectEsidExport && selectLivAnms ) {
      const iataValues = await iataForm.validateFields();
      let iatAResult = {};
      let iataKeys = ['iatA001','iatA002','iatA003','iatA004','iatA005'];
      iataKeys.forEach(key => {
        if ( iataValues?.iatA?.includes(key) ) {
          iatAResult[key] = 'Y';
        }
        else {
          iatAResult[key] = 'N';
        }
      });
      const createCertLivAnmPostValue = {
        esidId: selectEsidExport.id,
        codAwbPfx: selectEsidExport.codAwbPfx,
        codAwbNum: selectEsidExport.codAwbNum,
        codAwbSfx: selectEsidExport.codAwbSfx,
        codOri: selectEsidExport.codOri,
        codFds: selectEsidExport.codFds,
        codFltCar: selectEsidExport.codFltCar,
        codFltNum: selectEsidExport.codFltNum,
        datFltOri: selectEsidExport.datFltOri,// Additional Handling Information
        shpRegDat: selectLivAnms?.shpRegDat || new Date(),
        shpRegIdx: selectEsidExport.shpRegIdx,
        shpRegNam: selectEsidExport.shpRegNam,
        shpRegAdr: selectEsidExport.shpRegAdr,
        shpRegTel: selectEsidExport.shpRegTel,
        shpRegEml: selectEsidExport.shpRegEml,
        shpRegSignYn: selectEsidExport.shpRegSignYn,
        codSts: "NY",
        usrCre: selectEsidExport.UsrCre,
        usrUpd: selectEsidExport.UsrUpd,
        certDtl: selectLivAnms.certDtl ?? [],
        ...iatAResult,
        specHdlCod: iataValues?.specHdlCod,
        desRmk: iataValues?.desRmk
      };
      setIsInformationSaving(true);
      
      try {
        let data = {};
        if ( selectLivAnms.id ) {
          const respond = await UpdateCertLivAnimal({
            ...selectLivAnms,
            ...iatAResult,
            specHdlCod: iataValues?.specHdlCod,
            desRmk: iataValues?.desRmk
          });
          data = respond && respond.data;
        }
        else {
          const respond = await CreateCertLivAnm(createCertLivAnmPostValue);
          data = respond && respond.data;
        }
        data && dispatch(setLivAnmsSelectedValue({
          ...data,
          certDtl: data.certDtl.map((item, index) => (
            {
              ...item,
              key: index,
              codSts: item.codSts ?? LivAnmStatusEnum.NY
            }
          ))
        }));
        // sau khi luu AVI, dong thoi accept dg
        const expRes = await ExpAviAccept(selectEsidExport.id, { userId: currentUser.codStfLog, desRam:  iataValues?.desRmk});
        if (expRes.data) {
          dispatch(setEsidExpSelected(expRes.data));
        }
        //
        setIsInformationSaving(false);
        showSuccess(t('admin.notification.saveSuccess'));
      } catch (error) {
        errorHandling(error, history, location.pathname);
        setIsInformationSaving(false);
      }
    }
  }
  // TODO: Send ESID To airline
  // const handleSendToAirline = () => {
  //   alert('go to airline');
  // }

  // button group action
  const onChange = (key) => {
    setTabPaneActive(key);
  };
  const handleSaveInformation = () => {
    const index = esidTabs.indexOf(tabPaneActive);

    if (esidTabs[index] === esidExpAdminTabsEnum.ExpInfo && selectEsidExport) {
      handleSubmitExpInfo();
    }
    //TODO: update API
    if (esidTabs[index] === esidExpAdminTabsEnum.ExpDgr && selectDgrGoods) {
      handleSubmitDgrGoods();
    }

    if (esidTabs[index] === esidExpAdminTabsEnum.ExpLivAnm && selectLivAnms) {
      handleSubmitLivAnms();
    }

  }
  const handleNextKey = () => {
    const index = esidTabs.indexOf(tabPaneActive);
    let nextIndex = index + 1;
    if (esidTabs[nextIndex] === esidExpAdminTabsEnum.Acceptance && !selectEsidExport) {
      return;
    }
    setTabPaneActive(esidTabs[nextIndex]);
  }
  const handlePrevKey = () => {
    const index = esidTabs.indexOf(tabPaneActive);
    setTabPaneActive(esidTabs[index - 1]);
  }

  const handleSaveAcpt = async () => {
    try {
      if (selectEsidExport.shcBup !== 'Y' && (!expCarDimToEdit || expCarDimToEdit?.length <= 0)) {
        showError(t('admin.notification.dmsEmptyError'));
        return;
      }
      if (!expCarWgtToEdit) {
        showError(t('admin.notification.wgtEmptyError'));
        return;
      }
      const qtyPcsTotal = expCarDimToEdit.filter(x => x.codSts !== 'XX').map(x => x.qtyDimPcs).reduce((prev, current) => prev + current, 0);
      if (selectEsidExport.qtyPcs < qtyPcsTotal) {
        Modal.error({
            title: "Thông báo",
            content: "Số lượng tiếp nhận lớn hơn số lượng khai báo. Vui lòng kiểm tra lại.",
            onOk: () => {
                return;
            }
        });
        return;
      }
      setIsInformationSaving(true);
      const esid = JSON.parse(JSON.stringify(selectEsidExport));
      esid.shcCod001 = localStorage.getItem('calcDimMode') || esid.shcCod001;
      await UpdateEsidExportById(esid);
      await SaveEsidExpCarDim({
        esidId: selectEsidExport.id,
        dimensionSizes: expCarDimToEdit
      });
      const expCarWgtRes = await SaveEsidExpCarWgt({
        esidId: selectEsidExport.id,
        weights: expCarWgtToEdit ? [expCarWgtToEdit] : (selectEsidExport.expCarWgt || []),
      });

      //get updated data
      dispatch(setEsidExpSelected(expCarWgtRes.data));
      setIsInformationSaving(false);
      showSuccess(t('admin.notification.saveSuccess'));
    } catch (error) {
      setIsInformationSaving(false);
      errorHandling(error, history, location.pathname, true);
    }
  }
  const handleSubmitAcceptance = async () => {
    // TODO: fix post value ( add expCarDim, weight)
    const staffValue = await acptStfForm.validateFields();
    const postValue = {
      ...selectEsidExport,
      acpStfNam: staffValue.acpStfNam ?? null,
      acpDat: new Date(staffValue.acpDat).toISOString(),
      expCarDim: expCarDimToEdit || selectEsidExport.expCarDim || [],
      expCarWgt: expCarWgtToEdit ? [expCarWgtToEdit] : (selectEsidExport.expCarWgt || []),
      acpQtyWgtGrs: expCarWgtToEdit?.wgtGrs || 0,
      acpQtyWgtVol: expCarWgtToEdit?.volWgt || 0,
      acpQtyWgtChg: expCarWgtToEdit?.volWgt > expCarWgtToEdit?.wgtGrs ? expCarWgtToEdit?.volWgt : expCarWgtToEdit?.wgtGrs,
      desRmk: expCarWgtToEdit?.desRmk || selectEsidExport?.desRmk || ''
    }
    try {
      await SaveEsidExpCarDim({
        esidId: selectEsidExport.id,
        dimensionSizes: expCarDimToEdit
      });
      await SaveEsidExpCarWgt({
        esidId: selectEsidExport.id,
        weights: expCarWgtToEdit ? [expCarWgtToEdit] : (selectEsidExport.expCarWgt || []),
      });
      const acceptanceRes = await CreateEsidExportAcceptance(postValue);
      dispatch(setEsidExpSelected(acceptanceRes.data));
      showSuccess("Finalize succesfully");
      return acceptanceRes.data;
    } catch (error) {
      showError("Finalize failed");
      errorHandling(error, history, location.pathname, false);
    }
  }

  const [htmlExpContent, setHtmlExpContent] = useState();
  const handleSavePdf = useReactToPrint({
    content: () => printComponentRef.current,
    copyStyles: true,
    documentTitle: `${selectEsidExport?.codAwbPfx || 'pfx'}${selectEsidExport?.codAwbNum || 'num'}.pdf`,
    pageStyle: printConfig,
    print: async (printIframe) => {
      const contentDoc = printIframe.contentDocument;
      if (contentDoc) {
        // const html = contentDoc.getElementsByTagName('html')[0];
        // const ps = [];
        // const bodyContent = html.getElementsByTagName('body')[0];
        // const bScripts = bodyContent.getElementsByTagName('script');
        // const bLinks = bodyContent.getElementsByTagName('link');
        // for (let index = 0; index < bScripts.length; index++) {
        //   ps.push(bScripts[index].remove());
        // }
        // for (let index = 0; index < bLinks.length; index++) {
        //   ps.push(bLinks[index].remove());
        // }
        // //
        // await Promise.all(ps);
        // const htmlString = `<body>${bodyContent.innerHTML}</body>`;
        const html = contentDoc.getElementsByTagName('html')[0];
        const headerContent = html.getElementsByTagName('head')[0];
        const hLinks = headerContent.getElementsByTagName('link');
        const ps = [];
        for (let index = 0; index < hLinks.length; index++) {
          console.log('link: ', index);
          ps.push(hLinks[index].remove());
        };
        const hStyles = headerContent.getElementsByTagName('style');
        for (let index = 0; index < hStyles.length; index++) {
          const st = hStyles[index];
          if (st.innerHTML === '') {
            ps.push(st.remove());
          }
        };
        await Promise.all(ps);
        const bodyContent = html.getElementsByTagName('body')[0];
        const htmlString = `<body>${bodyContent.innerHTML}</body>`;
        setHtmlExpContent(htmlString);
        setFinalizeModalVisible(true);
      }
    }
  });

  const [opeingPdf, setOpeningPdf] = useState(false);
  const handleOpenPdf = useReactToPrint({
    content: () => printComponentRef.current,
    copyStyles: true,
    documentTitle: `${selectEsidExport?.codAwbPfx || 'pfx'}${selectEsidExport?.codAwbNum || 'num'}.pdf`,
    pageStyle: printConfig,
    print: async (printIframe) => {
      const contentDoc = printIframe.contentDocument;
      if (contentDoc) {
        try {
          setOpeningPdf(true);
          const html = contentDoc.getElementsByTagName('html')[0];
          const headerContent = html.getElementsByTagName('head')[0];
          const hLinks = headerContent.getElementsByTagName('link');
          const ps = [];
          for (let index = 0; index < hLinks.length; index++) {
            console.log('link: ', hLinks[index]);
            ps.push(hLinks[index].remove());
          };
          const hStyles = headerContent.getElementsByTagName('style');
          for (let index = 0; index < hStyles.length; index++) {
            const st = hStyles[index];
            if (st.innerHTML === '') {
              ps.push(st.remove());
            }
          };
          await Promise.all(ps);
          const bodyContent = html.getElementsByTagName('body')[0];
          const htmlString = `<body>${bodyContent.innerHTML}</body>`;
          console.log(html);
          const sendData = {
            htmlExpContent: htmlString
          };
          const esidRes = await ExportEsidPdf(sendData);
          const url = window.URL.createObjectURL(new Blob([esidRes.data], { type: 'application/pdf' }));
          setOpeningPdf(false);
          const pdfTab = window.open(url, '_blank');
          setTimeout(() => {
            pdfTab.document.title = `${selectEsidExport?.codAwbPfx || 'pfx'}${selectEsidExport?.codAwbNum || 'num'}`;
          }, 1000);
        } catch (error) {
          notification.error({
            message: 'Thông báo',
            description: 'Có lỗi xảy ra. Vui lòng thử lại sau'
          });
          setOpeningPdf(false);
        }
      }
    }
  });

  const handleToPrint = useReactToPrint({
    content: () => printComponentRef.current,
    copyStyles: true,
    pageStyle: printConfig
  })
  // eslint-disable-next-line
  const handleDgrToPrint = useReactToPrint({
    content: () => printDgrComponentRef.current,
    copyStyles: true,
    pageStyle: printConfig
  })
  // eslint-disable-next-line
  const handleLivAnmToPrint = useReactToPrint({
    content: () => printLivAnmComponentRef.current,
    copyStyles: true,
    pageStyle: printConfig
  })

  const printExpConsol = useReactToPrint({
    content: () => consolPrintRef.current,
    copyStyles: true,
    pageStyle: printConfig
  });

  const fetchDgrTypeOption = async () => {
    try {
        const { data } = await GetDgrTypeStatusOK();
        dispatch(setDgrTypeStatusOK(data))
    } catch (error) {
        errorHandling(error, history, location.pathname, false);
    }
  }

  useEffect(()=>{
    if(dgrSelectedValue){ 
      let qtyPcsIceAndPin = dgrSelectedValue.dgrItems?.map(item => item.qtyPcs).reduce((a, b) => a + b, 0);

      adminForm.setFieldsValue({
        rejectNum: dgrSelectedValue.rejectNum || 0,
        qtyUN: dgrSelectedValue.dgrItems?.filter(item => item.codTyp === 'DGR')?.length || 0,
        qtyPcs: qtyPcsIceAndPin,
        note: dgrSelectedValue?.note,
      })

      rejectForm.setFieldsValue({
        rejectNum: dgrSelectedValue.rejectNum,
        note: dgrSelectedValue.note
      })
    }
    // eslint-disable-next-line
  },[dgrSelectedValue])

  useEffect(()=>{
    fetchDgrTypeOption();
    // eslint-disable-next-line
  },[])

  const unFinalizeDG = async () => {
    if (!selectEsidExport?.id || !dgrSelectedValue?.id) {
      showWarning("Không tìm thấy tờ khai DG cần Un-Finalize");
      return;
    }
    try {
      setIsInformationSaving(true);
      const dgUnFinalizeData = {
        esidId: selectEsidExport.id,
        dgMstId: dgrSelectedValue.id
      };
      const res = await DgUnFinalize(dgUnFinalizeData);
      dispatch(setEsidExpSelected(res.data));
      setIsInformationSaving(false);
    } catch (error) {
      setIsInformationSaving(false);
      errorHandling(error, history, location.pathname, true);
    }
  }

  const unFinalizeESID = async () => {
    if (!selectEsidExport?.id) {
      showWarning("Không tìm thấy tờ khai ESID cần Un-Finalize.");
      return;
    }
    try {
      setIsInformationSaving(true);
      const dgUnFinalizeData = {
        esidId: selectEsidExport.id
      };
      const res = await ExpUnFinalize(dgUnFinalizeData);
      dispatch(setEsidExpSelected(res.data));
      setIsInformationSaving(false);
    } catch (error) {
      setIsInformationSaving(false);
      errorHandling(error, history, location.pathname, true);
    }
  }

  const saveExpConsol = async () => {
    const consolData = await consoltMstForm.validateFields();
    if (consolDtlList.length === 0) {
      notification.error({
        message: 'Thông báo',
        description: 'Vui lòng nhập thông tin hàng hóa.'
      });
      return;
    }
    
    if (selectEsidExport.qtyPcs !== expConsolEdit.qtyPcs) {
        Modal.error({
            title: "Thông báo",
            content: (
              <div>
                <div>Số lượng tiếp nhận hàng khác số lượng khai báo. Vui lòng kiểm tra lại.</div>
                <div style={{ fontWeight: 'bold' }}>{`Số lượng khai báo ESID: ${selectEsidExport.qtyPcs}`}</div>
                <div style={{ fontWeight: 'bold' }}>{`Số lượng tiếp nhận hàng: ${expConsolEdit.qtyPcs}`}</div>
              </div>
            ),
            onOk: () => {
                return;
            }
        });
        return;
    }

    const dataExpConsol = {
      ...expConsolEdit,
      ...consolData,
      consolDetails: consolDtlList,
      regisServices: consolRegServices
    }
    try {
      setIsInformationSaving(true);
      const res = expConsolEdit?.id ? await UpdateExpConsol(expConsolEdit.id, dataExpConsol) : await CreateExpConsol(dataExpConsol);
      dispatch(setExpConsol(res.data));
      showSuccess(t('admin.notification.saveSuccess'));
    } catch (error) {
      errorHandling(error);
    } finally {
      setIsInformationSaving(false);
    }
  }

  const finalizeExpConsol = async () => {
    if (!selectEsidExport?.id || !expConsolEdit?.id) {
      showWarning("Không tìm thấy tờ khai Hàng CONSOL cần Finalize");
      return;
    }
    if (selectEsidExport.qtyPcs < expConsolEdit.qtyPcs) {
      Modal.error({
          title: "Thông báo",
          content: "Số lượng tiếp nhận lớn hơn số lượng khai báo. Vui lòng kiểm tra lại.",
          onOk: () => {
              return;
          }
      });
      return;
    }
    try {
      setIsInformationSaving(true);
      const finalizeData = {
        esidId: selectEsidExport.id,
        consolId: expConsolEdit.id
      };
      const res = await ExpConsolFinalize(finalizeData);
      dispatch(setExpConsol(res.data));
      setIsInformationSaving(false);
    } catch (error) {
      setIsInformationSaving(false);
      errorHandling(error, history, location.pathname, true);
    }
  }

  const unFinalizeExpConsol = async () => {
    if (!selectEsidExport?.id || !expConsolEdit?.id) {
      showWarning("Không tìm thấy tờ khai Hàng CONSOL cần Un-Finalize");
      return;
    }
    try {
      setIsInformationSaving(true);
      const finalizeData = {
        esidId: selectEsidExport.id,
        consolId: expConsolEdit.id
      };
      const res = await ExpConsolUnFinalize(finalizeData);
      dispatch(setExpConsol(res.data));
      setIsInformationSaving(false);
    } catch (error) {
      setIsInformationSaving(false);
      errorHandling(error, history, location.pathname, true);
    }
  }

  const [dgChkLoading, setDgCheckLoading] = useState(false);
  const handleDgCheck = async () => {
    Modal.confirm({
      title: 'Thông báo',
      content: `Bạn có chắc chắn chuyển Awb ${selectEsidExport?.codAwbPfx}-${selectEsidExport?.codAwbNum} cho tổ DG kiểm tra (DG Check) không?`,
      cancelText: 'Cancel',
      onOk: async () => {
        try {
          setDgCheckLoading(true);
          const esidRes = await ExpDgCheck(selectEsidExport.id, {});
          dispatch(setEsidExpSelected(esidRes.data));
          setDgCheckLoading(false);
        } catch (error) {
          setDgCheckLoading(false);
          errorHandling(error);
        }
      }
    });
  }

  const handleDgCheckOK = async () => {
    const values = await dgChkForm.validateFields();
    try {
      setDgCheckLoading(true);
      const esidRes = await ExpDgCheckOK(selectEsidExport.id, values);
      dispatch(setEsidExpSelected(esidRes.data));
      setDgCheckLoading(false);
    } catch (error) {
      setDgCheckLoading(false);
      errorHandling(error);
    }
  }

  const [dgrTypes, setDgrTypes] = useState(['DGR']);
  const onDgrTypeChange = (checkedValues) => {
    const dgTypes = [];
    if (selectDgrGoods?.dgrItems) {
      selectDgrGoods.dgrItems.filter(x => x.codSts !== 'XX').forEach((x) => {
        if (x.codTyp === 'DGR' && !dgTypes.includes('DGR')) {
          dgTypes.push('DGR');
        }
        if (x.codTyp === 'PIN' && !dgTypes.includes('PIN')) {
          dgTypes.push('PIN');
        }
        if (x.codTyp === 'ICE' && !dgTypes.includes('ICE')) {
          dgTypes.push('ICE');
        }
      });
    }
    checkedValues.forEach(x => {
      if (!dgTypes.includes(x)) {
        dgTypes.push(x);
      }
    });
    if (dgTypes.length > 0) {
      setDgrTypes(dgTypes);
    }
  };

  useEffect(() => {
    if (!dgrSelectedValue) {
      setDgrTypes(['DGR']);
      return;
    }
    const dgTypes = [];
    if (dgrSelectedValue.dgrItems) {
      dgrSelectedValue.dgrItems.forEach((x) => {
        if (x.codTyp === 'DGR' && !dgTypes.includes('DGR')) {
          dgTypes.push('DGR');
        }
        if (x.codTyp === 'PIN' && !dgTypes.includes('PIN')) {
          dgTypes.push('PIN');
        }
        if (x.codTyp === 'ICE' && !dgTypes.includes('ICE')) {
          dgTypes.push('ICE');
        }
      });
    }
    if (dgTypes.length === 0) {
      dgTypes.push('DGR');
    }
    setDgrTypes(dgTypes);
  }, [dgrSelectedValue]);

  const handleCancelEsid = async () => {
    if (selectEsidExport?.id) {
      Modal.confirm({
        title: 'Thông báo',
        content: `Bạn có chắc chắn hủy AWb ${selectEsidExport?.codAwbPfx}-${selectEsidExport?.codAwbNum} này không?`,
        cancelText: 'Cancel',
        onOk: async () => {
          try {
            setConfirmLoading(true);
            await CancelEsid(selectEsidExport.id);
            const esid = JSON.parse(JSON.stringify(selectEsidExport));
            esid.codSts = 'XX';
            dispatch(setEsidExpSelected(esid));
            setConfirmLoading(false);
            notification.error({
              message: "Thông báo",
              description: "ESID đã hủy thành công."
            });
          } catch (error) {
            setConfirmLoading(false);
            notification.error({
              message: "Thông báo",
              description: "Có lỗi xảy ra. Vi lòng thử lại sau."
            });
          }
        }
      });
    }
  }

  return (
    <>
      <Layout style={{ height: '100%' }}>
        <BackTop>
          <Button shape={'circle'} icon={<ArrowUpOutlined />}/>
        </BackTop>
        <Content style={{minHeight: 'unset', marginBottom: 10}}>
          <SelectEsidModal modalSearchForm={modalSearchForm} redirectToEsidInfo={redirectToEsidInfo} visible={esidModalVisible} setVisible={setEsidModalVisible} setEsidExport={(esidExp) => dispatch(setEsidExpSelected(esidExp))} />
          {!hasAirlines && (
            <>
              <SearchForm redirectToEsidInfo={redirectToEsidInfo} setEsidExport={(esidExp) => dispatch(setEsidExpSelected(esidExp))} handleOpenEsidModal={setEsidModalVisible} />
              {selectEsidExport && <div>{t('esidExpList.table.status')}{': '}{t(`esid.codSts.${selectEsidExport?.codSts}`)}</div>}
            </>
          )}
          <Tabs
            tabBarGutter={20}
            activeKey={tabPaneActive}
            onChange={onChange}
            // style={{paddingBlock: 10}}
            destroyInactiveTabPane={true}
            renderTabBar={(props, TabNavList) => (
              <TabNavList {...props} mobile={false}/>
            )}
            tabBarStyle={{ marginBottom: 5 }}
          >
            <TabPane
              className="awb-tab-content-wrapper exportInformation"
              tab={hasAirlines ? t('Shipper Instruction for dispatch') : t("admin.esid.expInfo")}
              key={esidExpAdminTabsEnum.ExpInfo}
            >
              <GeneralInfomationContainer forms={{shipperForm, agentForm, consigneeForm, notifyForm, airWaybillForm, handlingInfoForm, senderForm}} isEsidExpFinalized={isEsidExpFinalized}/>
            </TabPane>
            {
              selectEsidExport && selectEsidExport.shcDgr && selectEsidExport.shcDgr === 'Y' &&
                <TabPane
                  className="awb-tab-content-wrapper"
                  tab={t("admin.esid.expDgr")}
                  key={esidExpAdminTabsEnum.ExpDgr}>
                    <div style={{ margin: '20px 0px 20px 5px' }} >
                      <Checkbox.Group style={{ width: '100%', fontWeight: 600 }} value={dgrTypes} onChange={onDgrTypeChange}>
                        <Checkbox value="DGR" style={{ color: dgrTypes.includes('DGR') ? '#ffa401' : 'black'}}>HÀNG NGUY HIỂM</Checkbox>
                        <Checkbox value="ICE" style={{ color: dgrTypes.includes('ICE') ? '#ffa401' : 'black'}}>HÀNG ĐÁ KHÔ</Checkbox>
                        <Checkbox value="PIN" style={{ color: dgrTypes.includes('PIN') ? '#ffa401' : 'black'}}>HÀNG PIN</Checkbox>
                      </Checkbox.Group>
                    </div>
                    {dgrTypes.includes('DGR') && (
                      <GoodsTable 
                        isDgrReceived={isDgrReceived}
                        dataSource={selectDgrGoods.dgrItems ? 
                            selectDgrGoods.dgrItems?.filter(item => item.codSts !== LivAnmStatusEnum.XX) : 
                            []
                          } 
                      />
                    )}
                    
                    {dgrTypes.includes('ICE') && (<>
                      <div style={{marginBottom: '30px'}}/>
                      <DryIceTable 
                        isDgrReceived={isDgrReceived}
                        dataSource={selectDgrGoods.dgrItems ? 
                            selectDgrGoods.dgrItems?.filter(item => item.codSts !== LivAnmStatusEnum.XX) : 
                            []
                          } 
                      />
                    </>)}
                    {dgrTypes.includes('PIN') && (<>
                      <div style={{marginBottom: '30px'}}/>
                      <PinTable 
                        isDgrReceived={isDgrReceived}
                        dataSource={selectDgrGoods.dgrItems ? 
                            selectDgrGoods.dgrItems?.filter(item => item.codSts !== LivAnmStatusEnum.XX) : 
                            []
                          } 
                      />
                    </>)}
                    <div style={{marginBottom: '30px'}}/>
                    
                  <Form
                    labelAlign='left'
                    labelCol={{ span: 24 }}
                    onFinish={handleSubmit}
                    form={adminForm}
                    autoComplete="off"
                    initialValues={{
                      rejectNum: 0,
                      // qtyUN: qtyUN,
                      // qtyPcs: qtyPcs,
                    }}>
                    <Row>
                      <Col span={24}>
                        <Typography.Text style={{ fontWeight: 'bold', color: 'red' }}>{t('dangerousGood.dgrAct')}</Typography.Text>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6}>
                        <Form.Item
                          name="rejectNum"
                          label={t('dangerousGood.rejectNum')}
                        >
                          <Input
                            style={{ width: '100%', height: '100%' }}
                            size={'medium'}
                            min={1}
                            readOnly
                          />
                        </Form.Item>
                      </Col>
                      {/* <Col lg={8} style={{padding: '0 0 0 5px'}}>
                        <Form.Item
                          name="qtyUN"
                          label={t('dangerousGood.qtyUN')}
                        >
                          <Input
                            type='number'
                            style={{ width: '100%', height: '100%' }}
                            size={'medium'}
                            min={1}
                            readOnly
                          />
                        </Form.Item>
                      </Col> */}
                      <Col lg={12} style={{padding: '0 0 0 5px'}}>
                        <Form.Item
                          name="note"
                          label={t('dangerousGood.formInfo.note')}
                        >
                          <Input
                            style={{ width: '100%', height: '100%' }}
                            size={'medium'}
                            readOnly
                          />
                        </Form.Item>
                      </Col>
                      <Col lg={6} style={{padding: '0 0 0 5px'}}>
                        <Form.Item
                          name="qtyPcs"
                          label={t('dangerousGood.qtyPcs')}
                        >
                          <Input
                            type='number'
                            style={{ width: '100%', height: '100%' }}
                            size={'medium'}
                            min={1}
                            readOnly
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>
                </TabPane>
            }
            {selectEsidExport?.dgChk === "Y" && ( 
              <TabPane
                tab={"DG Check"}
                key={esidExpAdminTabsEnum.ExpDgCheck}
              >
                <div style={{ marginTop: "20px" }}>
                  <Form form={dgChkForm} layout="vertical">
                    <Form.Item
                      name='desRmk'
                      label={t('admin.acceptance.desRmk')}
                      required
                      rules={[{ required: true }]}
                    >
                      <Input.TextArea rows={6} readOnly={selectEsidExport?.nonDg === "Y"} />
                    </Form.Item>
                  </Form>
                </div>
              </TabPane>
            )}
            {
              selectEsidExport && selectEsidExport.shcAvi && selectEsidExport.shcAvi === 'Y' &&
                <TabPane
                  className="awb-tab-content-wrapper"
                  tab={t("admin.esid.expLivAnm")}
                  key={esidExpAdminTabsEnum.ExpLivAnm}
                >
                  <div style={{ marginTop: '20px' }}>
                    <AviDeclaration form={iataForm}/>
                  </div>
                  {/* <AnimalsTable isEsidExpFinalized={isEsidExpFinalized} iataForm={iataForm} dataSource={selectLivAnms.certDtl ? selectLivAnms.certDtl.filter(item => item.codSts !== LivAnmStatusEnum.XX) : []} /> */}
                </TabPane>
            }
            {selectEsidExport?.shcConsol === 'Y' && (
              <TabPane
                className="awb-tab-content-wrapper"
                tab={t("formInfo.consol")}
                key={esidExpAdminTabsEnum.ExpConsol}
              >
                <ExpDeclConsolContainer forms={{consoltMstForm}}/>
              </TabPane>
            )}
            <TabPane
              className="awb-tab-content-wrapper"
              tab={t("admin.esid.acceptance")}
              key={esidExpAdminTabsEnum.Acceptance}
              disabled={!selectEsidExport || selectEsidExport === null}
            >
              <AcceptanceForm forms={{ acptStfForm, weightForm, dgrAcptStfForm }} isEsidExpFinalized={isEsidExpFinalized} />
            </TabPane>
            {paidStatus.includes(selectEsidExport?.codSts) && hasDocStaff && (
              <TabPane
                className="awb-tab-content-wrapper"
                tab={t("esid.checkout")}
                key={esidExpAdminTabsEnum.Payment}
              >
                <ExpPaymentInfo />
              </TabPane>
            )}
          </Tabs>
          {finalizeModalVisible && (
            <FinalizeModal 
              htmlExpContent={htmlExpContent}
              modalSearchForm={modalSearchForm} 
              visible={finalizeModalVisible} 
              setVisible={setFinalizeModalVisible} 
              handleSubmitFinalize={handleSubmitAcceptance} 
              isSendToAirlineModal={isSendToAirlineModal} 
            />
          )}
          {selectEsidExport && (
            <Row>
              <Col span={0}>
                <RegistryExportPrint
                  ref={printComponentRef}
                  esidExport={
                    {
                      ...selectEsidExport,
                      expCarDim: selectEsidExport.expCarDim || [],
                      expCarWgt: selectEsidExport.expCarWgt || [],
                    }
                  }
                  dgrSelected={dgrSelectedValue}
                  aviSelected={selectLivAnms}
                  expConsol={expConsol}
                  imgQRUrl={imgQRUrl}
                  // QR={`${EsidTypeEnum.EsidExp}#${selectEsidExport.tcsCodRefNum}`}
                  // numberQR={selectEsidExport.tcsCodRefNum}
                />
              </Col>
            </Row>
          )}
          {
            !isObjectEmpty(selectDgrGoods) && (
              <Row>
                <Col span={0}>
                  <ExpDeclDgrPrint
                    ref={printDgrComponentRef}
                    dataExport={selectDgrGoods}
                  />
                </Col>
            </Row>
            )
          }
          {
            !isObjectEmpty(selectLivAnms) && (
              <Row>
                <Col span={0}>
                  <ExpCertLivAnmPrint
                    ref={printLivAnmComponentRef}
                    dataExport={selectLivAnms}
                  />
                </Col>
            </Row>
            )
          }
        </Content>
        <Footer style={{ backgroundColor: 'transparent'}}>
          <Space style={{width: '100%', padding: '0px min(2%,10px) 20px', display: 'flex', justifyContent: 'center'}}>
            {tabPaneActive !== esidTabs[0] && (
              <Button disabled={isInformationSaving} className={styles.submitBtn} onClick={handlePrevKey}>{t("admin.esid.prevBtn")}</Button>
            )}
            {/* ESID */}
            {tabPaneActive === esidExpAdminTabsEnum.ExpInfo && (
              <>
                {!isEsidExpFinalized && hasAcceptance && (
                  <>
                    <Button disabled={!selectEsidExport} loading={isInformationSaving} className={styles.submitBtn} onClick={handleSaveInformation}>
                      {t("admin.esid.saveBtn")}
                    </Button>
                    <Button disabled={!selectEsidExport} loading={isInformationSaving} className={styles.submitBtn} onClick={handleCancelEsid}>
                      {t("esidCancel")}
                    </Button>
                  </>
                )}
                <Button disabled={isInformationSaving || !selectEsidExport} className={styles.submitBtn} onClick={handleToPrint}>{t("admin.esid.printBtn")}</Button>
                {selectEsidExport && (
                  <Button disabled={isInformationSaving} loading={opeingPdf} className={styles.submitBtn} onClick={handleOpenPdf}>PDF</Button>
                )}
              </>
            )}
            {/* DGR */}
            {tabPaneActive === esidExpAdminTabsEnum.ExpDgr && (
              <>
                {!isEsidExpFinalized && (
                  <>
                    {isDgrReceived ? (
                      <>
                        {selectEsidExport?.codSts === 'DR' && hasDgStaff && (
                          <Button disabled={!tecsDisabled} className={styles.submitBtn} onClick={unFinalizeDG}>{t('admin.esid.dgUnFinalize')}</Button>
                        )}
                      </>
                    ) : (
                      <>
                        {hasDgStaff && (
                          <>
                            <Button
                              loading={isInformationSaving} className={styles.submitBtn} onClick={handleSaveInformation}>
                              { t("admin.esid.saveBtn") }
                            </Button>
                            <Button 
                              disabled={!tecsDisabled}
                              loading={confirmLoading} style={{ width: "fit-content" }} 
                              className='submitBtn' onClick={()=> {
                                if (!selectDgrGoods?.dgrItems || !selectDgrGoods?.dgrItems?.length) {
                                  showError(t('admin.notification.dgrEmptyErr'));
                                  return;
                                }
                                setModalVisible(true);
                              }}>
                              { t("admin.esid.rejectBtn") }
                            </Button>
                            <Button 
                              disabled={!tecsDisabled}
                              loading={isDgrAccepted} className={styles.submitBtn} onClick={handleDgrAcpt}>
                              { t("admin.esid.dgrReceive") }
                            </Button>
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
                <Button disabled={isInformationSaving || !selectEsidExport} className={styles.submitBtn} onClick={handleToPrint}>{t("admin.esid.printBtn")}</Button>
                {selectEsidExport && (
                  <Button disabled={isInformationSaving} className={styles.submitBtn} onClick={handleOpenPdf} loading={opeingPdf}>PDF</Button>
                )}
              </>
            )}
            {/* DG Check */}
            {tabPaneActive === esidExpAdminTabsEnum.ExpDgCheck && (
              <>
                <Button 
                  loading={dgChkLoading}
                  className={styles.submitBtn}
                  disabled={selectEsidExport?.nonDg === "Y"}
                  onClick={handleDgCheckOK}
                >
                  { t("admin.esid.dgrReceive") }
                </Button>
              </>
            )}
            {/* Animals Live */}
            {tabPaneActive === esidExpAdminTabsEnum.ExpLivAnm && (
              <>
                {!isEsidExpFinalized && hasDgStaff && (
                  <>
                    <Button
                      loading={isInformationSaving} className={styles.submitBtn} onClick={handleSaveInformation}>
                      { t("admin.esid.saveBtn") }
                    </Button>
                  </>
                )}
                {/* <Button disabled={isInformationSaving || !selectEsidExport} className={styles.submitBtn} onClick={handleToPrint}>{t("admin.esid.printBtn")}</Button> */}
              </>
            )}
            {/* Export CONSOL */}
            {tabPaneActive === esidExpAdminTabsEnum.ExpConsol && (
              <>
                {hasAcceptance && (
                  <>
                    {expConsolEdit?.codSts !== 'OK' ? (
                      <>
                        <Button loading={isInformationSaving} className={styles.submitBtn} onClick={saveExpConsol}>
                          { t("admin.esid.saveBtn") }
                        </Button>
                        <Button disabled={!tecsDisabled} loading={isInformationSaving} className={styles.submitBtn} onClick={finalizeExpConsol}>{t("admin.esid.dgrReceive")}</Button>
                      </>
                    ) : (
                      <Button disabled={!tecsDisabled} loading={isInformationSaving} className={styles.submitBtn} onClick={unFinalizeExpConsol}>{t("admin.esid.dgUnFinalize")}</Button>
                    )}
                  </>
                )}
                <Button disabled={isInformationSaving} className={styles.submitBtn} onClick={printExpConsol}>{t("admin.esid.printBtn")} QF/ED/33</Button>
                {selectEsidExport && (
                  <Button disabled={isInformationSaving} className={styles.submitBtn} onClick={handleOpenPdf} loading={opeingPdf}>PDF</Button>
                )}
              </>
            )}
            {/* ESID Finalize */}
            {tabPaneActive === esidExpAdminTabsEnum.Acceptance && (
              <>
                {isEsidExpFinalized && !hasAirlines ? (
                  <>
                    {selectEsidExport.codSts === 'FR' && hasAcceptance && (
                      <Button disabled={!isEsidExpFinalized || !tecsDisabled} className={styles.submitBtn} onClick={unFinalizeESID}>Un Finalize</Button>
                    )}
                    <Button 
                      className={styles.submitBtn} 
                      onClick={() => {
                        setIsSendToAirlineModal(true);
                        handleSavePdf();
                      }} 
                      // onClick={handleSavePdf}
                    >
                      {t('admin.acceptance.modal.sendToAirline')}
                    </Button>
                  </>
                ) : ( 
                  <>
                    {hasAcceptance && (
                      <>
                        <Button loading={isInformationSaving} className={styles.submitBtn} onClick={handleSaveAcpt}>
                          { t("admin.esid.saveBtn") }
                        </Button>
                        <Button 
                          loading={dgChkLoading} 
                          className={styles.submitBtn} 
                          onClick={handleDgCheck}
                          disabled={(selectEsidExport?.nonDg === "N" && selectEsidExport?.dgChk === "Y") || !tecsDisabled}
                        >
                            {t("admin.acceptance.needDgChk")}
                        </Button>
                        <Button 
                          className={styles.submitBtn} 
                          disabled={(selectEsidExport?.nonDg === "N" && selectEsidExport?.dgChk === "Y") || !tecsDisabled}
                          onClick={() => {
                            if (selectEsidExport.shcDgr === 'Y' && selectEsidExport.codSts !== EsidStatusEnum.DR) {
                              showError(t('admin.notification.dgrAccecptError'));
                              return;
                            }
                            if (selectEsidExport.shcConsol === 'Y' && !expConsol?.acpStfNam) {
                              Modal.error({
                                title: "Thông báo",
                                content: "Vận đơn phụ chưa được xác nhận. Vui lòng kiểm tra lại.",
                                onOk: () => {
                                    return;
                                }
                              });
                              return;
                            }
                            setIsSendToAirlineModal(false);
                            handleSavePdf();
                        }}>
                          Finalize
                        </Button>
                      </>
                    )}
                  </>
                )}
                <Button disabled={isInformationSaving} className={styles.submitBtn} onClick={handleToPrint}>{t("admin.esid.printBtn")}</Button>
                {selectEsidExport && (
                  <Button disabled={isInformationSaving} className={styles.submitBtn} onClick={handleOpenPdf} loading={opeingPdf}>PDF</Button>
                )}
              </>
            )}
            {tabPaneActive !== esidTabs[esidTabs.length - 1] && (
              <Button disabled={isInformationSaving} className={styles.submitBtn} onClick={handleNextKey}>{t("admin.esid.nextBtn")}</Button>
            )}
          </Space>
        </Footer>
      </Layout>
      {modalVisible && (
        <RejectDangerousGoodsModal
          modalVisible={modalVisible}
          setModalVisible={setModalVisible}
          form={rejectForm}
          handleReject={handleReject}
        />
      )}
      {expConsolEdit?.id && (
        <div style={{ display: 'none' }}>
          <ExpDeclConsolPrint
            ref={consolPrintRef}
            expConsol={expConsolEdit}
          />
        </div>
      )}
    </>
  );
};
