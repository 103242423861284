import axiosInstance from "./AuthIntercept";
import { DOMAIN_CLIENT } from "./Constant";

/**
 * Get ESID CTC CMP (Customer) list
 * @returns
 */
export async function GetEsidCtcCmpList() {
  return axiosInstance.get(`${DOMAIN_CLIENT}nvesid/EsidCtcCmp/GetByUsr`);
}

/**
 * Get an ESID CTC CMP by Id
 * @param {*} id
 * @returns
 */
export function GetEsidCtcCmpById(id = 0) {
  return axiosInstance.get(`${DOMAIN_CLIENT}nvesid/EsidCtcCmp/${id}`);
}

/**
 * Get an ESID Export by Id
 * @param {*} id 
 * @returns 
 */
export function GetEsidExportById(id = 0) {
  return axiosInstance.get(`${DOMAIN_CLIENT}nvesid/EsidExport/GetById/${id}`);
}

/**
 * Get an ESID Export by AWB
 * @param {*} awbPfx 
 * @param {*} awbNum 
 * @returns 
 */
export function GetEsidExportByAwb(awbPfx = '', awbNum = '') {
  return axiosInstance.get(`${DOMAIN_CLIENT}nvesid/EsidExport/GetByAwb?awbPfx=${awbPfx}&awbNum=${awbNum}`);
}

/**
 * Check the AWB already exists.
 * @param {*} awbPfx 
 * @param {*} awbNum 
 * @returns 
 */
export function EsidCheckAwb(awbPfx = '', awbNum = '') {
  return axiosInstance.get(`${DOMAIN_CLIENT}nvesid/EsidExport/CheckAwb?awbPfx=${awbPfx}&awbNum=${awbNum}`);
}

export function GetAllAirports() {
  return axiosInstance.get(`${DOMAIN_CLIENT}nvesid/Airport/GetAll`);
}
export function GetDeclDgrByEsid(id = 1) {
  return axiosInstance.get(`${DOMAIN_CLIENT}nvesid/EsidExport/GetDeclDgrByEsid/${id}`);
}
export function GetCertLivAnmByEsid(id = 1) {
  return axiosInstance.get(`${DOMAIN_CLIENT}nvesid/EsidExport/GetCertLivAnmByEsid/${id}`);
}

export function GetVhcRegById(id) {
  return axiosInstance.get(`${DOMAIN_CLIENT}nvesid/EsidExport/GetVhcRegById/${id}`);
}

export function GetAllEsidExpByCondition({esidId = '', awbPfx = '', awbNum = '', fltNo='', fromDate, toDate, codRefNum = '', dateType = 'ShpRegDat', codSts = '', depot = 'ALL'}) {
  return axiosInstance.get(`${DOMAIN_CLIENT}nvesid/EsidExport?esidId=${esidId}&awbPfx=${awbPfx}&awbNum=${awbNum}&fltNo=${fltNo}&fromDate=${fromDate}&toDate=${toDate}&dateType=${dateType}&codRefNum=${codRefNum}&codSts=${codSts}&depot=${depot}`);
}

export function GetVhcRegByToDate({fromDate, toDate, codRefNum = ''}) {
  return axiosInstance.get(`${DOMAIN_CLIENT}nvesid/EsidExport/GetVhcRegByToDate?fromDate=${fromDate}&toDate=${toDate}&codRefNum=${codRefNum}`);
}
export function CreateEsidExportAcceptance(data) {
  return axiosInstance.post(`${DOMAIN_CLIENT}nvesid/EsidExport/EsidExportAccept/${data.id}`,data);
}
export function CreateDgrAcceptance(data) {
  return axiosInstance.post(`${DOMAIN_CLIENT}nvesid/EsidExport/DgrAccept/${data.id}`,data);
}

export async function SignIn(username, password, captchaId, captchaCode) {
  return await axiosInstance.post(`${DOMAIN_CLIENT}nvesid/Auth/SignIn`, {
    password: password,
    username: username,
    captcha: {
      id: captchaId,
      code: captchaCode
    }
  });
}
/**
 * It takes an object with an id property and a data property, and sends a PUT request to the server
 * with the data.
 * @param data - {
 * @returns 
 */
export function UpdateEsidExportById(data) {
  return axiosInstance.put(`${DOMAIN_CLIENT}nvesid/EsidExport/Update/${data.id}`,data);
}

/**
 * It takes an object with three properties, and sends a PUT request to the server with the data from
 * the third property.
 * @param data - {
 * @returns 
 */
export function UpdateDgrByDgrId(data) {
  return axiosInstance.put(`${DOMAIN_CLIENT}nvesid/EsidExport/${data.esidId}/UpdateDgr/${data.id}`,data.dgrItems);
}

/**
 * It's a PUT request to the server, with the URL being the domain, the esidId, and the id.
 * The data is the data that is being sent to the server.
 * @param data - {
 * @returns 
 */
export function UpdateCertLivAnimal(data) {
  return axiosInstance.put(`${DOMAIN_CLIENT}nvesid/EsidExport/${data.esidId}/UpdateCertLivAnimal/${data.id}`,data);
}

/**
 * It takes an object, and sends it to the server.
 * @param expCarDim - {
 * @returns 
 */
export function SaveEsidExpCarDim(expCarDim) {
  return axiosInstance.post(`${DOMAIN_CLIENT}nvesid/EsidExport/${expCarDim.esidId}/SaveEsidExpCarDim`,expCarDim.dimensionSizes);
}
export function SaveEsidExpCarWgt(expCarWgt) {
  return axiosInstance.post(`${DOMAIN_CLIENT}nvesid/EsidExport/${expCarWgt.esidId}/SaveEsidExpCarWgt`,expCarWgt.weights);
}
export function CreateDeclDgr(data) {
  return axiosInstance.post(`${DOMAIN_CLIENT}nvesid/EsidExport/CreateDeclDgr`, data);
}
export function CreateCertLivAnm(data) {
  return axiosInstance.post(`${DOMAIN_CLIENT}nvesid/EsidExport/CreateCertLivAnm`, data);
}

export function DgUnFinalize(data) {
  return axiosInstance.post(`${DOMAIN_CLIENT}nvesid/EsidExport/DgUnFinalize`, data);
}

export function ExpUnFinalize(data) {
  return axiosInstance.post(`${DOMAIN_CLIENT}nvesid/EsidExport/ExpUnFinalize`, data);
}

export function ExpConsolFinalize(data) {
  return axiosInstance.post(`${DOMAIN_CLIENT}nvesid/EsidExport/ExpConsolFinalize`, data);
}

export function ExpConsolUnFinalize(data) {
  return axiosInstance.post(`${DOMAIN_CLIENT}nvesid/EsidExport/ExpConsolUnFinalize`, data);
}

export function ExpGetAllUsers() {
  return axiosInstance.get(`${DOMAIN_CLIENT}Auth/GetAllUsers`);
}

export function ExpGetNamStf(username) {
  return axiosInstance.get(`${DOMAIN_CLIENT}Auth/GetNamStf/${username}`);
}

export function ExpInviteUser(data) {
  return axiosInstance.post(`${DOMAIN_CLIENT}Auth/InviteUser`, data);
}

export function ExpRemoveUser(username) {
  return axiosInstance.post(`${DOMAIN_CLIENT}Auth/RemoveUser/${username}`);
}

export function ExpSendMailToAirline(data) {
  return axiosInstance.post(`${DOMAIN_CLIENT}nvesid/EsidExport/SendMailToAirline`, data);
}

export function ExportEsidPdf(data) {
  return axiosInstance.post(`${DOMAIN_CLIENT}nvesid/EsidExport/ExportEsidToPdf`, data, { responseType: 'arraybuffer' });
}

export function ExpGetQrCodeImg(text) {
  return axiosInstance.get(`${DOMAIN_CLIENT}QRCode/png/${text}`, { responseType: 'arraybuffer' });
}

export function ExpGetEmailsWithAir(codCar) {
  return axiosInstance.get(`${DOMAIN_CLIENT}nvesid/EsidExport/GetExpEmailsWithAir/${codCar}`);
}

export function ExpDgCheck(id, data) {
  return axiosInstance.post(`${DOMAIN_CLIENT}nvesid/EsidExport/ExpDgCheck/${id}`, data);
}

export function ExpDgCheckOK(id, data) {
  return axiosInstance.post(`${DOMAIN_CLIENT}nvesid/EsidExport/ExpDgCheckOK/${id}`, data);
}

export function ExpAviAccept(id, data) {
  return axiosInstance.post(`${DOMAIN_CLIENT}nvesid/EsidExport/${id}/aviAccept`, data);
}

export function ExpWebBillChgPaid(webChkId) {
  return axiosInstance.post(`${DOMAIN_CLIENT}nvesid/EsidExport/ExpWebBillChgPaid?webChkId=${webChkId}`);
}

export function ExpWebBillChgIssueEInv(webChkId) {
  return axiosInstance.post(`${DOMAIN_CLIENT}nvesid/EsidExport/ExpWebBillChgIssueEInv?webChkId=${webChkId}`);
}

export function ExportAwbsToExcel(data) {
  return axiosInstance.post(`${DOMAIN_CLIENT}nvesid/EsidExport/ExportAwbsToExcel`, data, {responseType: 'blob'});
}