/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Row, Col, Tooltip } from "antd";
import "./page-css/Links.css";
import Banner from "../components/Banner";
import AirlineBrand from "../components/AirlineBrand";
import * as API from "../config/apiConfig";
import { useDispatch, useSelector } from "react-redux";
import * as Constant from "../config/Constant";
import { useLocation } from "react-router";
import { changeLang, menuItemSelected } from "../actions/menu";

const Links = () => {
  const [brands, setBrands] = useState([]);
  const { langId, menuItem } = useSelector((state) => state.menu);
  const dispatch = useDispatch();
  const location = useLocation();

  const setMenu = async (menuId, langId) => {
    const menuRes = await API.getMenuById(menuId);
    const menuReference = {
      key: menuRes.data.alias,
      title: menuRes.data.name,
      menuParentTitle: menuRes.data.name,
      banner: menuRes.data.params,
      menuId: menuRes.data.id,
      menuParentId: menuRes.data.parent,
      images: menuRes.data.images,
      referenceId: menuRes.data.reference_id,
    };
    dispatch(changeLang(langId));
    localStorage.setItem("langId", langId || 1);
    localStorage.setItem("LANGUAGE", langId === "2" ? "vi" : "en");
    dispatch(menuItemSelected(menuReference));
    localStorage.setItem("menuItem", JSON.stringify(menuReference));
  };
  useEffect(async () => {
    const slug = location.pathname.slice(
      location.pathname.lastIndexOf("/") + 1
    );
    const menuIdRes = await API.getMenuBySlug(slug);
    const { id, lang_id } = menuIdRes.data;

    API.getHomeNews("links", langId)
      .then((res) => {
        setBrands(res.data);
      })
      .catch((err) => console.log(err));

    setMenu(id, lang_id + "");
  }, [location]);
  useEffect(() => {
    API.getHomeNews("links", langId)
      .then((res) => {
        setBrands(res.data);
      })
      .catch((err) => console.log(err));
  }, [langId]);
  return (
    <Row className="links-wrapper">
      <Banner
        title={menuItem.menuParentTitle}
        className="banner-wrapper"
        cover={`${Constant.IMAGES_URL}${menuItem.banner}`}
      />
      <Row className="links-content-wrapper">
        <Col className="links-content" offset={2} span={18}>
          {brands &&
            brands.map((brand, i) => {
              return (
                <Tooltip
                  key={i}
                  placement="right"
                  title={
                    langId === "2"
                      ? `Tên: ${brand.name}
                    Địa chỉ: ${brand.address}
                    Điện thoại: ${brand.phone}
                    Fax: ${brand.fax}`
                      : `Name: ${brand.name}
                    Address: ${brand.address}
                    Phone: ${brand.phone}
                    Fax: ${brand.fax}`
                  }
                >
                  <Col className="link-holder" offset={1} span={5}>
                    <AirlineBrand brand={brand} />
                  </Col>
                </Tooltip>
              );
            })}
        </Col>
      </Row>
    </Row>
  );
};

export default Links;
