/* eslint-disable */
import React, { useEffect, useState } from "react";
import {
  Col,
  Input,
  Form,
  Row,
  Button,
  List,
  DatePicker,
  notification,
  Skeleton,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import { useSelector } from "react-redux";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { checkinUser } from "../../actions/userAWB";
import { setPaymentInfo } from "../../actions/payment";
import { userAwbLogout } from "./../../actions/userAWB";
import {
  PDA_IMP_LND_SVC_LOAD_BY_OBJ_WEB,
  PDA_IMP_LND_SVC_SAVE,
} from "./../../config/checkinApi";
import { useHistory } from "react-router";

const TabVehicle = ({ info, setActiveKey }) => {
  const { MAWB } = useSelector((state) => state.mawb);
  const { data } = useSelector((state) => state.userAwb);
  const { token } = useSelector((state) => state.userAwb.dataCheckin);
  const { t } = useTranslation("common");
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [firstHalf, setFirstHalfListVehicle] = useState([]);
  const [listVehicle, setListVehicle] = useState([]);
  const [secondHalf, setSecondHalfListVehicle] = useState([]);

  const [form] = useForm();
  const [vehicleRegForm] = useForm();

  const showData = () => {
    if (info !== undefined) {
      setLoading(true);
      const {
        COD_QUE_NUM_CHK,
        FLG_IMP_EXP,
        COD_ORI,
        COD_FDS,
        QTY_TOT_PCS,
        QTY_GRS_WGT,
        QTY_GRS_RE_WGT,
        DAT_DLV,
        DLV_ODR_RCV_ID,
        DLV_ODR_RCV_NAM,
        EML_RCV_EINV,
        CUS_RMK,
      } = info;

      form.setFieldsValue({
        queueNo: COD_QUE_NUM_CHK,
        awbFirst: MAWB.first,
        awbLast: MAWB.last,
        hawb: MAWB.hawb,
        impExp: FLG_IMP_EXP,
        org: COD_ORI,
        des: COD_FDS,
        pcs: QTY_TOT_PCS,
        weight: QTY_GRS_WGT,
        "re-weight": QTY_GRS_RE_WGT === 0 ? "" : QTY_GRS_RE_WGT,
        "H/M": MAWB.hawb !== "" ? "H" : "M",
        receiveDate: moment(DAT_DLV),
        receiverId: DLV_ODR_RCV_ID,
        receiverName: DLV_ODR_RCV_NAM,
        email: EML_RCV_EINV,
        phone: CUS_RMK.slice(0, CUS_RMK.indexOf("-")),
      });
      fetchVehicleList();
      setLoading(false);
    }
  };

  const prevTab = () => {
    setActiveKey("eInvoice-info");
  };
  const fetchVehicleList = async () => {
    try {
      setLoading(true);
      const listVehicleRes = await PDA_IMP_LND_SVC_LOAD_BY_OBJ_WEB(
        { ...info, COD_REF_NUM: info.COD_REF_NUM.slice(0, 12) },
        token
      );
      if (listVehicleRes.data) {
        setListVehicle(listVehicleRes.data.mCol);
        setFirstHalfListVehicle(
          [...listVehicleRes.data.mCol].splice(
            0,
            Math.ceil(listVehicleRes.data.mCol.length / 2)
          )
        );
        setSecondHalfListVehicle(
          [...listVehicleRes.data.mCol].splice(
            Math.ceil(listVehicleRes.data.mCol.length / 2),
            listVehicleRes.data.mCol.length
          )
        );
      }
    } catch (error) {
      console.log(error);
      if (error.response.status === 401) {
        notification.error({
          style: { marginRight: "-50%" },
          message: t("home.sessionNoti"),
        });

        dispatch(checkinUser({}));
        dispatch(setPaymentInfo(undefined));
        dispatch(userAwbLogout());
        localStorage.removeItem("userAWB");
        localStorage.removeItem("checkin");
        localStorage.removeItem("pInfo");
        history.push("/");
      } else
        notification.error({
          message: "Lỗi",
          style: { marginRight: "-50%" },
        });
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    showData();
  }, [info]);

  const handleRegistry = async ({ plates, ...values }) => {
    let validFlag = true;
    if (plates.length - 1 === plates.lastIndexOf(";"))
      plates = plates.slice(0, -1);
    const platesArray = plates.toUpperCase().split(";", 10);
    platesArray.every((plate) => {
      if (plate.length < 7 || plate.length > 20) {
        notification.warning({
          style: { marginRight: "-50%" },
          message: `Biển số không hợp lệ: ${plate}`,
        });
        validFlag = false;
        return false;
      }
      if (listVehicle.includes(plate)) {
        notification.warning({
          style: { marginRight: "-50%" },
          message: `Biển số đã đăng ký: ${plate}`,
        });
        validFlag = false;
        return false;
      }
    });
    if (validFlag) {
      const savePlate = async (plate) => {
        try {
          setLoading(true);
          await PDA_IMP_LND_SVC_SAVE(
            {
              ...info,
              VEHICE_NUM: plate,
              COD_REF_NUM: info.COD_REF_NUM.slice(0, 12),
            },
            token
          );
        } catch (error) {
          console.log(error);
          if (error.response.status === 401) {
            notification.error({
              style: { marginRight: "-50%" },
              message: t("home.sessionNoti"),
            });

            dispatch(checkinUser({}));
            dispatch(setPaymentInfo(undefined));
            dispatch(userAwbLogout());
            localStorage.removeItem("userAWB");
            localStorage.removeItem("checkin");
            localStorage.removeItem("pInfo");
            history.push("/");
          } else
            notification.error({
              message: "Lỗi",
              style: { marginRight: "-50%" },
            });
        } finally {
          setLoading(false);
        }
      };
      const promiseArr = [];
      platesArray.forEach((plate) => {
        promiseArr.push(savePlate(plate));
      });
      await Promise.all(promiseArr)
        .then((res) => {
          notification.success({
            style: { marginRight: "-50%" },
            message: `Đăng ký biển số thành công!`,
          });
        })
        .then(fetchVehicleList)
        .catch((error) => {
          console.log(error);
          if (error.response.status === 401) {
            notification.error({
              style: { marginRight: "-50%" },
              message: t("home.sessionNoti"),
            });

            dispatch(checkinUser({}));
            dispatch(setPaymentInfo(undefined));
            dispatch(userAwbLogout());
            localStorage.removeItem("userAWB");
            localStorage.removeItem("checkin");
            localStorage.removeItem("pInfo");
            history.push("/");
          } else
            notification.error({
              message: "Lỗi",
              style: { marginRight: "-50%" },
            });
        });
    }
  };

  const limitInput = (e) => {
    switch (e.target.name) {
      case "plates":
        if (
          !e.target.value.match(/^[A-Za-z0-9-;*]+$/) ||
          !!e.target.value.match(/\s/)
        ) {
          vehicleRegForm.setFieldsValue({
            plates: e.target.value.slice(0, -1),
          });
        }
        break;
      default:
        break;
    }
  };

  return (
    <>
      <Row>
        <Col span={4}>
          <h2>Vehicle Registration</h2>
        </Col>
        <Col style={{ display: "flex", justifyContent: "flex-end" }} span={20}>
          <Button
            className="btn-payment"
            style={{ width: "fit-content" }}
            onClick={prevTab}
          >
            {/* {t("header.submit")} */} Quay lại
          </Button>
        </Col>
      </Row>
      <Form className="awb-payment" layout="vertical" form={form}>
        <Row gutter={[5, 0]}>
          <Col span={24} md={3}>
            <Form.Item name="queueNo" label="Queue No">
              <Input readOnly />
            </Form.Item>
          </Col>
          <Col span={24} md={3}>
            <Form.Item name="awbFirst" label="AWB Number">
              <Input readOnly />
            </Form.Item>
          </Col>
          <Col span={24} md={6}>
            <Form.Item label=" " name="awbLast">
              <Input readOnly />
            </Form.Item>
          </Col>
          <Col span={24} md={2}>
            <Form.Item name="H/M" label="H/M">
              <Input readOnly />
            </Form.Item>
          </Col>
          <Col span={24} md={7}>
            <Form.Item name="hawb" label="HWB Number">
              <Input readOnly />
            </Form.Item>
          </Col>
          <Col span={24} md={3}>
            <Form.Item name="impExp" label="IMP/EXP">
              <Input readOnly />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Form
          className="form-receiver"
          requiredMark={false}
          layout="vertical"
          form={form}
          autoComplete="off"
        >
          <Row gutter={[5, 0]}>
            <Col span={24} md={6}>
              <Form.Item
                name="receiveDate"
                label={/* t("header.newPassConfirm") */ "Ngày nhận"}
              >
                <DatePicker disabled format={"DD MMM YYYY HH:mm"} />
              </Form.Item>
            </Col>
            <Col span={24} md={6}>
              <Form.Item
                name="receiverId"
                label={/* t("header.newPassConfirm") */ "CCCD/ CMND người nhận"}
              >
                <Input readOnly style={{ textTransform: "uppercase" }} />
              </Form.Item>
            </Col>
            <Col span={24} md={6}>
              <Form.Item
                name="receiverName"
                label={/* t("header.username") */ "Họ và tên người nhận"}
              >
                <Input readOnly style={{ textTransform: "uppercase" }} />
              </Form.Item>
            </Col>
            <Col span={24} md={6}>
              <Form.Item
                name="phone"
                label={/* t("header.oldPass") */ "Số điện thoại người nhận"}
              >
                <Input readOnly style={{ textTransform: "uppercase" }} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
      <div>
        <Form
          className="form-receiver"
          requiredMark={false}
          layout="vertical"
          form={vehicleRegForm}
          onFinish={handleRegistry}
          autoComplete="off"
        >
          <Row gutter={[5, 0]}>
            <Col span={20}>
              <Form.Item
                name="plates"
                label={
                  "Biển số xe (Đăng ký tối đa 10 xe bằng cách nhập biển số xe, mỗi biển số dài ít nhất 7 ký tự, cách nhau bằng dấu ;)"
                }
              >
                <Input
                  name="plates"
                  onChange={limitInput}
                  style={{ textTransform: "uppercase" }}
                />
              </Form.Item>
            </Col>
            <Col
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              span={4}
            >
              <Form.Item label={" "}>
                <Button
                  className="btn-payment"
                  style={{ width: "fit-content" }}
                  htmlType="submit"
                  loading={loading}
                >
                  {/* {t("header.submit")} */} Tiếp tục
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
      <Skeleton active={loading} loading={loading}>
        <Row gutter={[5, 0]}>
          {firstHalf.length !== 0 && (
            <Col span={12}>
              <List
                dataSource={firstHalf}
                bordered
                renderItem={(item) => (
                  <List.Item style={{ justifyContent: "center" }}>
                    {item.VEHICE_NUM}
                  </List.Item>
                )}
              />
            </Col>
          )}

          {secondHalf.length !== 0 && (
            <Col span={12}>
              <List
                dataSource={secondHalf}
                bordered
                renderItem={(item) => (
                  <List.Item style={{ justifyContent: "center" }}>
                    {item.VEHICE_NUM}
                  </List.Item>
                )}
              />
            </Col>
          )}
        </Row>
      </Skeleton>
    </>
  );
};
export default TabVehicle;
