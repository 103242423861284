import { Col, Row, Typography } from "antd";
import React from "react";

export class ExpDeclDgrPrint extends React.PureComponent {
  // eslint-disable-next-line
  constructor(props) {
    super(props);
  }
  
  render() {
    const {
      shipper,
      consignee,
      codOri,
      codFds,
      codAwbPfx,
      codAwbNum,
      dgrItems,
    } = this.props.dataExport;
    return (
      <div style={{ display: "flex", justifyContent: "center", fontFamily: "sans-serif" }}>
        <div className="awb-tab-content" style={{ width: "770px" }}>
          <Row justify="start">
            <Col>
              <br></br>
              <Typography.Text
                style={{
                  marginBottom: "2px",
                  fontWeight: "600",
                  fontSize: "15px",
                }}
              >
                SHIPPER'S DECALRATION FOR DANGEROUS GOODS
              </Typography.Text>
            </Col>
          </Row>
          <div style={{ border: "1px solid" }}>
            <Row style={{ borderBottom: "1px solid" }}>
              <Col
                span={12}
                style={{
                  borderRight: "1px solid",
                  width: "100%",
                  padding: "5px 10px"
                }}
              >
                <Row>
                  <Col span={24} style={{ display: "flex", flexDirection: "column" }}>
                    <Typography.Text
                      style={{ fontWeight: 600, fontSize: "13px", marginBottom: "4px" }}
                    >
                      Shipper
                    </Typography.Text>
                    <Typography.Text className="item-name-dgr">
                      {shipper?.namCtc}
                    </Typography.Text>
                    <Typography.Text className="item-name-dgr">
                      {shipper?.desAddStt}
                    </Typography.Text>
                    <Typography.Text className="item-name-dgr">
                      {shipper?.desAddStt}
                      {/* hỏi lại */}
                    </Typography.Text>
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <Typography.Text className="item-name-dgr">
                      TEL: {shipper?.desPhone}
                    </Typography.Text>
                  </Col>
                  <Col span={12}>
                    <Typography.Text className="item-name-dgr">
                      FAX: {shipper?.fax}
                      {/* chua có */}
                    </Typography.Text>
                  </Col>
                </Row>
              </Col>
              <Col 
                span={12} 
                style={{
                  width: "100%",
                  padding: "5px 10px"
                }}
              >
                <Row>
                  <Col span={24} style={{ display: "flex", flexDirection: "column" }}>
                    <Typography.Text className="item-name-dgr">
                      Air Waybill No. <span style={{ fontSize: "16px", fontWeight: "bold", marginLeft: "60px" }}>{codAwbPfx}-{codAwbNum}</span>
                    </Typography.Text>
                    <p className="item-name"></p>
                    <Typography.Text className="item-name-dgr" style={{ marginBottom: "5px" }}>
                      Page <span>1</span> of <span> 1</span> Pages
                    </Typography.Text>
                    <Typography.Text className="item-name-dgr">
                      Shipper's Reference No.
                    </Typography.Text>
                    <Typography.Text className="item-name-dgr">
                      (optional)
                    </Typography.Text>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row
              justify="center"
              style={{ borderBottom: "1px solid" }}
            >
              <Col span={12} style={{ borderRight: "1px solid" }}>
                <div
                  style={{
                    width: "100%",
                    padding: "5px 10px",
                    display: "flex",
                    flexDirection: 'column'
                  }}
                >
                  <Typography.Text
                    style={{ fontWeight: 600, fontSize: "13px", marginBottom: "4px" }}
                  >
                    Consignee
                  </Typography.Text>
                  <Typography.Text className="item-name-dgr">
                    {consignee?.namCtc}
                  </Typography.Text>
                  <Typography.Text className="item-name-dgr">
                    {consignee?.desAddStt}
                  </Typography.Text>
                  <Typography.Text className="item-name-dgr">
                    {consignee?.desAddStt}
                    {/* chua có  addr */}
                  </Typography.Text>
                  <Typography.Text className="item-name-dgr">
                    PHONE: {consignee?.desPhone}
                    {/* chua có phone */}
                  </Typography.Text>
                  <Typography.Text className="item-name-dgr">
                    ATTN: {consignee?.desAddStt}
                    {/* chua có  attn */}
                  </Typography.Text>
                </div>
              </Col>
              <Col span={12} />
            </Row>
            <Row justify="center" style={{ borderBottom: "1px solid" }}>
              <Col span={12} style={{ borderRight: "1px solid", paddingBottom: "5px" }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-start",
                    width: "100%",
                    padding: "5px 10px",
                    borderBottom: "1px solid",
                    height: "40px",
                  }}
                >
                  <p
                    className="item-name"
                    style={{ fontSize: "9px", fontStyle: "italic" }}
                  >
                    Two completed and signed copies of this Decalration must be
                    handed to the operator
                  </p>
                </div>
                <Row
                  style={{
                    width: "100%",
                    paddingRight: "10px",
                    paddingLeft: "10px",
                    height: "27px",
                  }}
                >
                  <Typography.Text
                    style={{
                      fontWeight: "600",
                      fontSize: "14px",
                    }}
                  >
                    TRANSPORT DETAILS
                  </Typography.Text>
                </Row>
                <div
                  style={{
                    width: "100%",
                    paddingRight: "10px",
                    paddingLeft: "10px",
                    height: "95px",
                  }}
                >
                  <Row
                    style={{
                      width: "100%",
                      borderTop: "1px solid",
                      borderBottom: "1px solid",
                    }}
                  >
                    <Col
                      span={12}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: "column",
                        borderRight: "1px solid",
                      }}
                    >
                      <Row>
                        <p style={{ fontSize: "9px" }}>
                          This shipment is within the limitaions prescribed for:
                        </p>
                        <p style={{ fontSize: "9px", lineHeight: "5px" }}>
                          {" "}
                          (delete non-applicable)
                        </p>
                      </Row>

                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          borderTop: "1px solid",
                          borderLeft: "1px solid",
                        }}
                      >
                        <Col
                          span={12}
                          style={{
                            borderRight: "1px solid",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            position:'relative'
                          }}
                        >
                          <Typography.Text
                            style={{
                              textAlign: "center",
                              fontSize: "9px",
                              fontWeight: 600,
                            }}
                          >
                            PASSENGER AND CARGO AIRCRAFT
                          </Typography.Text>
                          {/* <div style={{display:'flex',justifyContent:'center',alignItems:'center', position:'absolute'}}>
                            <Typography.Text  
                              style={{
                                textAlign: "center",
                                fontSize: "20px",
                                fontWeight: "600px",
                            }}>
                              XXX
                            </Typography.Text>
                          </div> */}
                        </Col>
                        <Col
                          span={12}
                          style={{
                            padding: "5px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            position:'relative'
                          }}
                        >
                          <Typography.Text
                            style={{
                              textAlign: "center",
                              fontSize: "9px",
                              fontWeight: 600,
                            }}
                          >
                            CARGO AIRCRAFT ONLY
                          </Typography.Text>
                          <div style={{display:'flex',justifyContent:'center',alignItems:'center', position:'absolute'}}>
                            <Typography.Text  
                              style={{
                                textAlign: "center",
                                fontSize: "27px",
                                fontWeight: "600px",
                            }}>
                              XXX
                            </Typography.Text>
                          </div>
                        </Col>
                      </div>
                    </Col>
                    <Col span={12}>
                      <div>
                        <p className="item-name-dgr" style={{ textAlign: "center" }}>
                          Airport of Departure (optional):
                        </p>
                        <p
                          style={{
                            textAlign: "center",
                            fontSize: "18px",
                            fontWeight: "600"
                          }}
                        >
                          {codOri}
                        </p>
                      </div>
                    </Col>
                  </Row>
                </div>
                <div
                  style={{
                    width: "100%",
                    paddingLeft: "10px",
                    paddingRight: "10px",
                    height: "12px",
                  }}
                >
                  <Typography.Text
                    style={{
                      fontWeight: "400",
                      fontSize: "10px",
                    }}
                  >
                    Airport of Destination (optional):
                  </Typography.Text>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    width: "100%",
                    paddingLeft: "10px",
                    paddingRight: "10px",
                    height: "12px",
                  }}
                >
                  <Typography.Text
                    style={{
                      fontWeight: "400",
                      fontSize: "10px",
                    }}
                  >
                    {codFds}
                  </Typography.Text>
                </div>
              </Col>
              <Col
                span={12}
                style={{
                  width: "100%",
                  paddingTop: "10px",
                  paddingRight: "10px",
                  paddingLeft: "10px",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{display: "flex", flexDirection: "column"}}>
                    <Typography.Text
                      style={{
                        fontWeight: "600",
                        fontSize: "14px",
                        marginBottom: "10px"
                      }}
                    >
                      WARNING
                    </Typography.Text>
                    <Typography.Text
                      className="item-name-dgr"
                      style={{
                        fontWeight: "500",
                        fontSize: "13px",
                        lineHeight: "14px",
                      }}
                    >
                      Failure to comply in all respoects with the applicate
                      Dangerous Goods Regulations may be in breach of the
                      applicable law, subject to legal penalties
                    </Typography.Text>
                  </div>
                  <div
                    style={{
                      paddingRight: "5px",
                      paddingLeft: "5px",
                      borderTop: "1px solid",
                    }}
                  >
                    <Row>
                      <Typography.Text style={{ fontSize: "10px", marginBottom: "4px" }}>
                        Shipment type:
                        <span style={{ fontStyle: "italic" }}>
                          ( delete non-applicate)
                        </span>
                      </Typography.Text>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Row
                          style={{
                            width: "80%",
                            borderTop: "1px solid",
                            borderLeft: "1px solid",
                            borderRight: "1px solid",
                          }}
                        >
                          <Col
                            span={12}
                            style={{
                              borderRight: "1px solid",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              height: "27px",
                              position:'relative'
                            }}
                          >
                            <Typography.Text
                              style={{
                                fontWeight: 600,
                                textAlign: "center",
                                fontSize: "14px"
                              }}
                            >
                              NON-RADIOACTIVE
                            </Typography.Text>
                            {/* <div style={{display:'flex',justifyContent:'center',alignItems:'center', position:'absolute'}}>
                              <Typography.Text  
                                style={{
                                  textAlign: "center",
                                  fontSize: "27px",
                                  fontWeight: "600px",
                              }}>
                                XXX
                              </Typography.Text>
                            </div> */}
                          </Col>
                          <Col
                            span={12}
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              height: "27px",
                              position:'relative'
                            }}
                          >
                            <Typography.Text
                              style={{
                                fontWeight: 600,
                                textAlign: "center",
                                fontSize: "14px"
                              }}
                            >
                              RADIOACTIVE
                            </Typography.Text>
                            <div style={{display:'flex',justifyContent:'center',alignItems:'center', position:'absolute'}}>
                              <Typography.Text  
                                style={{
                                  fontSize: "22px",
                                  fontWeight: "600px",
                              }}>
                                XXXXXXXX
                              </Typography.Text>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Row>
                  </div>
                </div>
              </Col>
            </Row>
            <Row justify="center" style={{ borderBottom: "1px solid" }}>
              <Col
                span={24}
                style={{
                  width: "100%",
                  padding: "5px",
                  height: "30px",
                }}
              >
                <p
                  style={{
                    fontWeight: "600",
                    textAlign: "start",
                    fontSize: "16px",
                  }}
                >
                  NATURE AND QUANTITY OF DANGEROUS GOODS
                </p>
              </Col>
            </Row>

            <Row>
              <Col span={14} style={{ borderRight: "1px solid" }}>
                <Row
                  style={{
                    dispay: "flex",
                    width: "100%",
                    padding: "10px",
                    borderBottom: "1px solid",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "35px",
                  }}
                >
                  <Typography.Text style={{ textAlign: "center", fontWeight: "600", lineHeight: '99%', fontSize: "13px" }}>
                    Dangerous Goods Identification
                  </Typography.Text>
                </Row>
                <Row
                  style={{
                    width: "100%",
                    borderBottom: "1px solid",
                    height: "50px",
                  }}
                >
                  <Col
                    span={3}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: "10px",
                      borderRight: "1px solid",
                    }}
                  >
                    <p style={{ fontSize: "10px", textAlign: "center" }}>
                      UN or ID No.
                    </p>
                  </Col>
                  <Col
                    span={12}
                    style={{
                      display: "flex",
                      padding: "10px",
                      borderRight: "1px solid",
                    }}
                  >
                    <p style={{ fontSize: "10px", textAlign: "center" }}>
                      Proper Shipping Name
                    </p>
                  </Col>
                  <Col
                    span={6}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "100%",
                      height: "100%",
                      borderRight: "1px solid",
                    }}
                  >
                    <Typography.Text style={{ fontSize: "10px", textAlign: "center" }}>
                      Class or Division (subsidiary hazrad)
                    </Typography.Text>
                  </Col>
                  <Col
                    span={3}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "100%",
                      height: "100%",
                      padding: "5px",
                    }}
                  >
                    <Typography.Text style={{ fontSize: "10px", textAlign: "center" }}>
                      Packing group
                    </Typography.Text>
                  </Col>
                </Row>
              </Col>
              <Col span={10}>
                <Row
                  style={{
                    width: "100%",
                    height: "35px",
                  }}
                >
                  <Col
                    span={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: "10px",
                      borderRight: "1px solid",
                    }}
                  />
                  <Col
                    span={5}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: "10px",
                      borderRight: "1px solid",
                    }}
                  />
                  <Col
                    span={7}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: "10px",
                    }}
                  />
                </Row>
                <Row
                  style={{
                    width: "100%",
                    borderBottom: "1px solid",
                    height: "50px",
                  }}
                >
                  <Col
                    span={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: "10px",
                      borderRight: "1px solid",
                    }}
                  >
                    <p style={{ fontSize: "10px", textAlign: "center" }}>
                      Quantity and Type of Packing
                    </p>
                  </Col>

                  <Col
                    span={5}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: "10px",
                      borderRight: "1px solid",
                    }}
                  >
                    <p style={{ fontSize: "10px", textAlign: "center" }}>
                      Packing Inst.
                    </p>
                  </Col>
                  <Col
                    span={7}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      padding: "10px",
                    }}
                  >
                    <p style={{ fontSize: "10px", textAlign: "center" }}>
                      Authorization
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>

            {/* map */}
            {dgrItems?.map((item, index) => {
              return (
                <Row key={index} style={{height: "30px",}}>
                  <Col span={14} style={{ borderRight: "1px solid" }}>
                    <Row
                      style={{
                        width: "100%",
                        borderTop: '1px solid'
                      }}
                    >
                      <Col
                        span={3}
                        style={{
                          display: "flex",
                          justifyContent:'center',
                          alignItems: "center",
                          padding: "10px",
                          borderRight: "1px solid",
                          height: "30px",
                        }}
                      >
                        <Typography.Text style={{ fontSize: "10px", textAlign: "center" }}>
                          {item.codUid}
                        </Typography.Text>
                      </Col>
                      <Col
                        span={12}
                        style={{
                          display: "flex",
                          justifyContent:'center',
                          alignItems: "center",
                          padding: "10px",
                          borderRight: "1px solid",
                          height: "30px",
                        }}
                      >
                        <Typography.Text style={{ fontSize: "10px", textAlign: "center" }}>
                          {item.codPsn}
                        </Typography.Text>
                      </Col>
                      <Col
                        span={6}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          padding: "5px",
                          borderRight: "1px solid",
                          height: "30px",
                        }}
                      >
                        <Typography.Text style={{ fontSize: "10px", textAlign: "center" }}>
                          {item.clsDvs}
                        </Typography.Text>
                      </Col>
                      <Col
                        span={3}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          padding: "5px",
                        }}
                      >
                        <Typography.Text style={{ fontSize: "10px", textAlign: "center" }}>
                          {item.dgrPkg}
                        </Typography.Text>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={10}>
                    <Row
                      style={{
                        width: "100%",
                        borderTop: '1px solid'
                      }}
                    >
                      <Col
                        span={12}
                        style={{
                          display: "flex",
                          justifyContent:'center',
                          alignItems: "center",
                          padding: "10px",
                          borderRight: "1px solid",
                          height: "30px",
                        }}
                      >
                        <Typography.Text style={{ fontSize: "10px", textAlign: "center" }}>
                          {item.qtyTypPkg}
                        </Typography.Text>
                      </Col>

                      <Col
                        span={5}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          padding: "10px",
                          borderRight: "1px solid",
                          height: "30px",
                        }}
                      >
                        <Typography.Text style={{ fontSize: "10px", textAlign: "center" }}>
                          {item.pkgInst}
                        </Typography.Text>
                      </Col>
                      <Col
                        span={7}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          padding: "10px",
                          height: "30px",
                        }}
                      >
                        <Typography.Text style={{ fontSize: "10px", textAlign: "center" }}>
                          {item.dgrAuth}
                        </Typography.Text>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              );
            })}

            <Row
              style={{
                borderTop: "1px solid",
                borderBottom: "1px solid",
                padding: "5px",
              }}
            >
              <Col span={13}>
                <p className="item-name-dgr">
                  Additional Handling Information
                </p>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <p className="item-name-dgr">Emergency contact: +8490979 1407/ Mr Minh Kha Nguyen</p>
                </div>
              </Col>
              <Col span={11} />
            </Row>
            <Row
              style={{
                borderTop: "1px solid",
                marginTop: "10px",
              }}
            >
              <Row style={{ margin: "5px" }}>
                <Col span={13} style={{ borderRight: "1px solid" }}>
                  <p className="item-name-dgr">
                    I hereby declare that the contents of this consignment are
                    fully and accurately described above by the proper shipping
                    name, and are classified, packed marked and
                    labelled/placared, and are in all respects in proper
                    condition for transport according to applicable
                    international and national govermental regulations. I
                    declare that all of the applicable air transport
                    requirements have been met.
                  </p>
                </Col>
                <Col
                  span={11}
                  style={{ paddingLeft: "10px", paddingRight: "10px" }}
                >
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      flexDirection: "column",
                    }}
                  >
                    <div>
                      <p style={{ fontSize: "10px", lineHeight: "12px" }}>
                        Name of Signatory
                      </p>
                      <p style={{ fontSize: "13px", lineHeight: "12px" }}>
                        Minh Kha Nguyen/ Airfreight staff
                      </p>
                    </div>
                    <div>
                      <p style={{ fontSize: "10px", lineHeight: "12px" }}>
                        Date
                      </p>
                      <p style={{ fontSize: "13px", lineHeight: "12px" }}>
                        Ho Chi Minh City 02 Feb 2023
                      </p>
                    </div>
                    <div>
                      <p style={{ fontSize: "10px", lineHeight: "12px" }}>
                        Signature
                      </p>
                      <p
                        style={{
                          fontSize: "10px",
                          lineHeight: "12px",
                          fontStyle: "italic",
                        }}
                      >
                        (See warning above)
                      </p>
                    </div>
                  </div>
                </Col>
              </Row>
            </Row>
          </div>
        </div>
      </div>
    );
  }
}
