import * as types from '../constant/actionTypes'

export const setMAWB = (MAWB) => {
    if (MAWB) {
        localStorage.setItem('MAWB', JSON.stringify(MAWB));
    } else {
        localStorage.removeItem('MAWB');
    }
    return {
        type: types.SET_MAWB,
        payload: MAWB
    }
}
export const setHAWB = (HAWB) => {
    return {
        type: types.SET_HAWB,
        payload: HAWB
    }
}
