/* eslint-disable */

import React, { useEffect, useRef, useState } from "react";
import {
  Col,
  Row,
  Table,
  Form,
  Input,
  Button,
  notification,
  Modal,
} from "antd";
import "../css/awb.css";
import { useForm } from "antd/lib/form/Form";
import { useSelector } from "react-redux";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import ModalHawbList from "../TabAWB_HAWB/ModalHawbList";

const { warning } = Modal;

export default function HawbTabAWB({
  loadData,
  info,
  status,
  chargeInfo,
  billChargeDetail,
  ...props
}) {
  const [form] = useForm();
  const { MAWB, HAWB } = useSelector((state) => state.mawb);
  
  const [loading, setLoading] = useState();
  const [visible, setVisible] = useState(false);
  const [total, setTotal] = useState(0);
  const [rowId, setRowId] = useState(chargeInfo !== undefined && 0);
  const [rowBillNo, setRowBillNo] = useState(
    chargeInfo !== undefined && chargeInfo[0].COD_BIL
  );
  const [totalGross, setTotalGross] = useState(0);
  const [totalGrossBill, setTotalGrossBill] = useState(0);
  const [totalTax, setTotalTax] = useState(0);
  const [dataSource, setDataSource] = useState();
  const [dataSourceBill, setDataSourceBill] = useState();

  const columns = [
    {
      title: "Charge Code",
      dataIndex: "COD_CHG",
      key: "COD_CHG",
    },
    {
      title: "Quantity",
      dataIndex: "QTY",
      key: "QTY",
    },
    {
      title: "Gross Amt",
      dataIndex: "AMT_GROSS",
      key: "AMT_GROSS",
      className: "awb-table-currency",
    },
    {
      title: "VAT Amt",
      dataIndex: "AMT_TAX",
      key: "AMT_TAX",
      className: "awb-table-currency",
    },
    {
      title: "Total Amt",
      dataIndex: "TOTAL_AMT",
      key: "TOTAL_AMT",
      className: "awb-table-currency",
    },
    {
      title: "Paid",
      dataIndex: "STATUS",
      key: "STATUS",
      width: "5%",
    },
    {
      title: "Payment mode",
      dataIndex: "COD_PAY_MOD",
      key: "COD_PAY_MOD",
      width: "5%",
    },
    {
      title: "Payable by",
      dataIndex: "FLG_BIL_TO",
      key: "FLG_BIL_TO",
      width: "5%",
    },
    {
      title: "Invoice No",
      dataIndex: "INV_NO",
      key: "INV_NO",
    },
  ];
  const columnsBillCharge = [
    {
      title: "Date charge",
      dataIndex: "DAT_BIL_CHG",
      key: "DAT_BIL_CHG",
    },
    {
      title: "HAWB",
      dataIndex: "COD_HWB_NUM",
      key: "COD_HWB_NUM",
    },
    {
      title: "PCS",
      dataIndex: "QTY_PCS",
      key: "QTY_PCS",
    },
    {
      title: "Weight",
      dataIndex: "QTY_GRS_WGT",
      key: "QTY_GRS_WGT",
    },
    {
      title: "COD_DES",
      dataIndex: "COD_DES",
      key: "COD_DES",
    },
    {
      title: "Gross amt.",
      dataIndex: "CHG_AMT_GROSS",
      key: "CHG_AMT_GROSS",
      className: "awb-table-currency",
    },
  ];
  const showData = async () => {
    if (info !== undefined) {
      const {
        COD_QUE_NUM_CHK,
        FLG_IMP_EXP,
        COD_ORI,
        COD_FDS,
        QTY_TOT_PCS,
        QTY_GRS_WGT,
        QTY_GRS_RE_WGT,
        SHC1,
        SHC2,
        SHC3,
        CNE_CODE,
        CNE_NAME,
        AGENT_CODE,
        AGENT_NAME,
      } = info;
      form.setFieldsValue({
        queueNo: COD_QUE_NUM_CHK,
        awbFirst: MAWB.first,
        awbLast: MAWB.last,
        hawb: HAWB,
        impExp: FLG_IMP_EXP,
        org: COD_ORI,
        des: COD_FDS,
        pcs: QTY_TOT_PCS,
        weight: QTY_GRS_WGT,
        "re-weight": QTY_GRS_RE_WGT === 0 ? "" : QTY_GRS_RE_WGT,
        "H/M": HAWB !== "" ? "H" : "M",
        SHC1: SHC1,
        SHC2: SHC2,
        SHC3: SHC3,
        consigneeCode: CNE_CODE,
        consigneeName: CNE_NAME,
        agentCode: AGENT_CODE,
        agentName: AGENT_NAME,
      });
    }
    if (chargeInfo !== undefined) {
      try {
        let data = [];
        let total = 0;
        let totalGross = 0;
        let totalTax = 0;
        //eslint-disable
        chargeInfo.map((dt, index) => {
          data.push({
            key: index,
            COD_CHG: dt.COD_CHG,
            QTY: dt.QTY,
            AMT_GROSS: dt.AMT_GROSS.toString().replace(
              /\B(?=(\d{3})+(?!\d))/g,
              ","
            ),
            WAIVE_AMT: dt.WAIVE_AMT.toString().replace(
              /\B(?=(\d{3})+(?!\d))/g,
              ","
            ),
            AMT_TAX: dt.AMT_TAX.toString().replace(
              /\B(?=(\d{3})+(?!\d))/g,
              ","
            ),
            TOTAL_AMT: (dt.AMT_GROSS + dt.WAIVE_AMT + dt.AMT_TAX)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
            STATUS: dt.STATUS,
            COD_PAY_MOD: dt.COD_PAY_MOD,
            FLG_BIL_TO: dt.FLG_BIL_TO,
            INV_NO: dt.INV_SRL_NO + "/" + dt.INV_NO,
            COD_BIL: dt.COD_BIL,
          });
          total += dt.AMT_GROSS + dt.WAIVE_AMT + dt.AMT_TAX;
          totalGross += dt.AMT_GROSS;
          totalTax += dt.AMT_TAX;
        });
        setTotal(total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));

        setTotalGross(
          totalGross.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        );
        setTotalTax(totalTax.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));
        setDataSource(data);
      } catch (err) {
        notification.error({ message: "Lỗi", style: { marginRight: "-50%" } });
      }
    }
  };
  const nextTab = () => {
    if (status === "AQ")
      warning({
        title: "Thông báo",
        icon: <ExclamationCircleOutlined />,
        content: "Bạn cần phải tính phí để tiếp tục!",
      });
    else props.setActiveKey("invoice-info");
  };
  const prevTab = () => {
    props.setActiveKey("receiver-info");
  };
  const handleRowSelect = (record) => {
    return {
      onClick: () => {
        setRowId(record.key);
        setRowBillNo(record.COD_BIL);
      },
    };
  };
  const showDataRow = async () => {
    if (rowId !== undefined) {
      if (billChargeDetail !== undefined) {
        let data = [];
        setLoading(true);
        let totalGross = 0;
        //eslint-disable-next-line
        billChargeDetail.map((dt) => {
          if (dt.COD_BIL === rowBillNo) {
            data.push({
              DAT_BIL_CHG: dt.DAT_BIL_CHG,
              COD_HWB_NUM: dt.COD_HWB_NUM,
              QTY_PCS: dt.QTY_PCS,
              QTY_GRS_WGT: dt.QTY_GRS_WGT,
              COD_DES: dt.COD_DES,
              CHG_AMT_GROSS: dt.CHG_AMT_GROSS.toString().replace(
                /\B(?=(\d{3})+(?!\d))/g,
                ","
              ),
            });
            totalGross += dt.CHG_AMT_GROSS;
          }
        });
        setTotalGrossBill(
          totalGross.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        );
        setDataSourceBill(data);
        setLoading(false);
      }
    }
  };
  const submit = () => {
    setVisible(true);
  };
  useEffect(() => {
    showData();
  }, [info, chargeInfo, billChargeDetail]);
  useEffect(() => {
    showDataRow();
  }, [rowId, rowBillNo, info]);
  useEffect(() => {
    showDataRow();
  }, []);
  return (
    <div
      className="awb-payment"
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <ModalHawbList
        visible={visible}
        setVisible={setVisible}
        info={info}
        chargeInfo={chargeInfo}
        setRowBillNo={setRowBillNo}
        loadData={loadData}
      />
      <Row>
        <Col span={1}>
          <h2>AWB</h2>
        </Col>
        <Col
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
          span={23}
        >
          <Button
            className="btn-payment"
            style={{ width: "fit-content", marginRight: "5px" }}
            onClick={prevTab}
          >
            {/* {t("header.submit")} */} Quay lại
          </Button>
          <Button
            className="btn-payment"
            style={{ width: "fit-content", marginRight: "5px" }}
            onClick={() => form.submit()}
            disabled={status !== "AQ"}
          >
            {/* {t("header.submit")} */} Tính phí
          </Button>
          <Button
            className="btn-payment"
            style={{ width: "fit-content" }}
            onClick={nextTab}
          >
            {/* {t("header.submit")} */} Tiếp tục
          </Button>
        </Col>
      </Row>
      <Form
        className="awb-payment"
        layout="vertical"
        onFinish={submit}
        form={form}
        autoComplete="off"
      >
        <Row gutter={[5, 0]}>
          <Col span={24} md={2}>
            <Form.Item name="queueNo" label="Queue No">
              <Input readOnly />
            </Form.Item>
          </Col>
          <Col span={24} md={2}>
            <Form.Item name="awbFirst" label="AWB Number">
              <Input readOnly />
            </Form.Item>
          </Col>
          <Col span={24} md={4}>
            <Form.Item label=" " name="awbLast">
              <Input readOnly />
            </Form.Item>
          </Col>
          <Col span={24} md={1}>
            <Form.Item name="H/M" label="H/M">
              <Input readOnly />
            </Form.Item>
          </Col>
          <Col span={24} md={1}>
            <Form.Item name="impExp" label="IMP/EXP">
              <Input readOnly />
            </Form.Item>
          </Col>

          <Col span={24} md={2}>
            <Form.Item name="org" label="Org">
              <Input readOnly />
            </Form.Item>
          </Col>

          <Col span={24} md={2}>
            <Form.Item name="des" label="Des">
              <Input readOnly />
            </Form.Item>
          </Col>

          <Col span={24} md={3}>
            <Form.Item name="pcs" label="Pieces">
              <Input readOnly />
            </Form.Item>
          </Col>

          <Col span={24} md={3}>
            <Form.Item name="weight" label="Weight">
              <Input readOnly />
            </Form.Item>
          </Col>
        </Row>
        <hr style={{ marginTop: "-10px" }} />
      </Form>
      <h2>Table of Charge Details</h2>
      <Row>
        <Col className="table-payment" span={24}>
          <Table
            className="table-payment-charge-detail"
            pagination={false}
            dataSource={dataSource}
            loading={loading}
            onRow={handleRowSelect}
            rowClassName={(record) => {
              return record.key === rowId ? "row-selected" : "";
            }}
            columns={columns}
            summary={() => {
              return (
                <>
                  <Table.Summary.Row>
                    <Table.Summary.Cell index={1} colSpan={2}>
                      {" "}
                      Tổng:{" "}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell
                      className="awb-table-currency"
                      index={1}
                    >
                      {totalGross}
                    </Table.Summary.Cell>

                    <Table.Summary.Cell
                      className="awb-table-currency"
                      index={6}
                    >
                      {totalTax}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell
                      className="awb-table-currency"
                      index={0}
                      colSpan={1}
                    >
                      {total}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell
                      className="awb-table-currency"
                      index={0}
                      colSpan={4}
                    ></Table.Summary.Cell>
                  </Table.Summary.Row>
                </>
              );
            }}
          />
        </Col>
      </Row>
      <h2>Bill Charge Details</h2>
      <Row>
        <Col className="table-payment" span={24}>
          <Table
            className="table-payment-charge-detail"
            pagination={false}
            dataSource={dataSourceBill}
            loading={loading}
            columns={columnsBillCharge}
            summary={() => {
              return (
                <>
                  <Table.Summary.Row>
                    <Table.Summary.Cell
                      index={1}
                      colSpan={5}
                    ></Table.Summary.Cell>
                    <Table.Summary.Cell
                      className="awb-table-currency"
                      index={1}
                    >
                      Tổng: {totalGrossBill}
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                </>
              );
            }}
          />
        </Col>
      </Row>
    </div>
  );
}
