import React from "react";
import { useSelector } from "react-redux";
import { EditConsolModel } from "../../../../../components/tracking/ExpDeclConsol/components/EditConsolModel";
import { ShipmentDetailForm } from "../../../../../components/tracking/ExpDeclConsol/components/ShipmentDetailForm";
import { ConsolTable } from "../../../../../components/tracking/ExpDeclConsol/components/ConsolTable";
import { ServiceRegistration } from "../../../../../components/tracking/ExpDeclConsol/components/ServiceRegistration";
import { ConsolDeclarant } from "../../../../../components/tracking/ExpDeclConsol/components/ConsolDeclarant";
import { ModalName } from "../../../../../constant/enumerations";
import { StaffForm } from "../Acceptance/StaffForm";

export const ExpDeclConsolContainer = ({forms}) => {
  const consolModalVisible = useSelector(state => state.esidExport.modal[`${ModalName.ConsolModal}`]);
  const expConsolEdit = useSelector(state => state.esidExport.expConsolEdit);
  
  return (
    <div style={{ paddingTop: '20px' }}>
      {consolModalVisible && (
        <EditConsolModel />
      )}
      <ShipmentDetailForm form={forms.consoltMstForm}/>
      <ConsolTable />
      <ServiceRegistration />
      <ConsolDeclarant form={forms.consoltMstForm}/>
      {expConsolEdit?.codSts === "OK" && (
        <StaffForm readOnly={true} form={forms.consoltMstForm}/>
      )}
    </div>
  );
};
