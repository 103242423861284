import React from "react";
import ReactDOM from "react-dom";
import "normalize.css";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import rootReducer from "./reducers";
import { composeWithDevTools } from "redux-devtools-extension";
import { Provider } from "react-redux";
import { createStore } from "redux";
import { initLanguage } from "./helpers/language-helper";
import { I18nextProvider } from "react-i18next";
import i18n from "i18next";
import { setupAxios } from "./config/AuthIntercept";
import Routers from "./router";

const applied = composeWithDevTools();
const store = createStore(rootReducer, applied);
setupAxios(store, null);
initLanguage();

ReactDOM.render(
  <Provider store={store}>
    <I18nextProvider i18n={i18n}>
      <Routers />
    </I18nextProvider>
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
