import React, { useState } from 'react'
import { Table, Space, Typography, Button, Spin } from 'antd';
import { useTranslation } from "react-i18next";
import { errorHandling } from '../../../../config/AuthIntercept';
import { ExpWebCheckLoadLst, GetCertLivAnmByEsid, GetDeclDgrByEsid, GetEsidExportById } from '../../../../config/esidExportApi';
import { setEsidExpSelected, setEsidExpTabPaneActive, setEsidListCurrentPage, setEsidSortedListCondition, setExpBilChgLst, setModalEsidVisibility } from '../../../../actions/esidExportAction';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { EsidStatusEnum, esidExpTabsEnum, ModalName } from '../../../../constant/enumerations';
import moment from 'moment';
import { useEffect } from 'react';
import { setDgrSelectedValue } from '../../../../actions/dgrGoodsAction';
import { setLivAnmsSelectedValue } from '../../../../actions/livAnmAction';
import { formatFltDateDisplay } from '../../../../utils/utils';

const EsidTable = ({dataSource}) => {
    const { t } = useTranslation("common");
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const esidListCurrentPage = useSelector(state => state.esidExport.esidListCurrentPage);
    const esidSortedListCondition = useSelector(state => state.esidExport.esidSortedListCondition);

    const [currentPage, setCurrentPage] = useState(esidListCurrentPage);
    
    const [sortedInfo, setSortedInfo] = useState(esidSortedListCondition);

    const handleTableChange = (pagination, filters, sorter) => {
        setCurrentPage(pagination.current);
        setSortedInfo(sorter);
        dispatch(setEsidListCurrentPage(pagination.current));
        dispatch(setEsidSortedListCondition(sorter));
    };

    // if search condition changed, current page will be switched to 1
    useEffect(() => {
        setCurrentPage(esidListCurrentPage);
        // setSortedInfo({});
        dispatch(setEsidListCurrentPage(esidListCurrentPage));
    },[esidListCurrentPage, dispatch])

    const columns = [
    {
        title: 'AWB/ESID',
        dataIndex: 'awbPfxAndNum',
        key: 'awbPfxAndNum',
        sorter: (a, b) => { return a.awbPfxAndNum.localeCompare(b.awbPfxAndNum)},
        sortOrder: sortedInfo.columnKey === 'awbPfxAndNum' ? sortedInfo.order : null,
    },
    {
        title: t("esidExpList.table.flightNo"),
        dataIndex: 'fltCarAndNum',
        key: 'fltCarAndNum',
        sorter: (a, b) => { return a.fltCarAndNum.localeCompare(b.fltCarAndNum) },
        sortOrder: sortedInfo.columnKey === 'fltCarAndNum' ? sortedInfo.order : null,
    },
    {
        title: t("esidExpList.table.flightDate"),
        dataIndex: 'datFltOrigin',
        key: 'datFltOrigin',
        render: (_, record) => moment(record.datFltOrigin).format(formatFltDateDisplay).toLocaleUpperCase(),
        sorter: (a, b) => moment(a.datFltOrigin).unix() - moment(b.datFltOrigin).unix(),
        sortOrder: sortedInfo.columnKey === 'datFltOrigin' ? sortedInfo.order : null,
    },
    {
        title: t("esidExpList.table.esid"),
        dataIndex: 'esidId',
        key: 'esidId',
        sorter: (a, b) => a.esidId - b.esidId,
        // defaultSortOrder: 'descend',
        sortOrder: sortedInfo.columnKey === 'esidId' ? sortedInfo.order : 'descend',
    },
    {
        title: t("esidExpList.table.dgrChk"),
        dataIndex: 'shcDgr',
        key: 'shcDgr',
        width: '7%',
        sorter: (a, b) => a.shcDgr - b.shcDgr,
        render: (_, record) => record.shcDgr === 'Y' ? 'Y' : '',
        sortOrder: sortedInfo.columnKey === 'shcDgr' ? sortedInfo.order : null,
    },
    {
        title: t("esidExpList.table.status"),
        dataIndex: 'codSts',
        key: 'codSts',
        render: (_, record) => (record.dgrChk === 'Y' && [EsidStatusEnum.NY, EsidStatusEnum.VR].includes(record.codSts) ? t(`esid.codSts.dgrChk`) : t(`esid.codSts.${record.codSts}`)),
        sorter: (a, b) => t(`esid.codSts.${a.codSts}`).length - t(`esid.codSts.${b.codSts}`).length,
        align: 'left',
        sortOrder: sortedInfo.columnKey === 'codSts' ? sortedInfo.order : null,
    },
        {
            title: "",
            dataIndex: 'codSts',
            render: (_, record) => (
                <>
                    {record.codSts === EsidStatusEnum.AC ? (
                        <Button 
                            className='ant-btn-list'
                            style={{ width: "fit-content", marginLeft: 10 }}
                            onClick={(evt) => {
                                evt.stopPropagation();
                                selectEsidExportToPayment(record.esidId);
                            }}
                        >
                            {t(`esidExpList.checkout`)}
                        </Button>
                    ) : ("")}
                    {record.codSts === EsidStatusEnum.AM ? (
                        <Button
                            className='ant-btn-list'
                            style={{ width: "fit-content", marginLeft: 10 }}
                            onClick={(evt) => {
                                evt.stopPropagation();
                                selectEsidExportToPayment(record.esidId);
                            }}
                        >
                            {t(`esidExpList.checkout`)}
                        </Button>
                    ) : ("")}
                    {record.codSts === EsidStatusEnum.AP ? (
                        <Button
                            className='ant-btn-list'
                            style={{ width: "fit-content", marginLeft: 10 }}
                            onClick={(evt) => {
                                evt.stopPropagation();
                                selectEsidExportToPayment(record.esidId);
                            }}
                        >
                            {t(`esidExpList.checkoutDetail`)}
                        </Button>
                    ) : ("")}
                    {record.codSts === EsidStatusEnum.QR ? (
                        <Button
                            className='ant-btn-list'
                            style={{ width: "fit-content", marginLeft: 10 }}
                            onClick={(evt) => {
                                evt.stopPropagation();
                                selectEsidExportToPayment(record.esidId);
                            }}
                        >
                            {t(`esidExpList.checkoutDetail`)}
                        </Button>
                    ) : ("")}
                    {record.codSts === EsidStatusEnum.FN ? (
                        <Button
                            className='ant-btn-list'
                            style={{ width: "fit-content", marginLeft: 10 }}
                            onClick={(evt) => {
                                evt.stopPropagation();
                                selectEsidExportToPayment(record.esidId);
                            }}
                        >
                            {t(`esidExpList.checkoutDetail`)}
                        </Button>
                    ) : ("")}
                    {record.codSts === EsidStatusEnum.OK ? (
                        <Button
                            className='ant-btn-list'
                            style={{ width: "fit-content", marginLeft: 10 }}
                            onClick={(evt) => {
                                evt.stopPropagation();
                                selectEsidExportToPayment(record.esidId);
                            }}
                        >
                            {t(`esidExpList.checkoutDetail`)}
                        </Button>
                    ) : ("")}
                </>
            )
        }
    ];   

    const fetchDgrData = async (esidId) => {
        try {
            const dgrRes = await GetDeclDgrByEsid(esidId);
            dispatch(setDgrSelectedValue(dgrRes.data));
        } catch (error) {
            dispatch(setDgrSelectedValue(null));
        }
    }

    const selectEsidExport = async (id) => {
        try {
            localStorage.removeItem('esidExp');
            const res  =  await GetEsidExportById(id);
            dispatch(setEsidExpSelected(res.data));
            if (res.data.id && res.data.shcDgr === 'Y') {
                await fetchDgrData(res.data.id);
            } else {
                dispatch(setDgrSelectedValue(null));
            }
            if (res.data.id && res.data.shcAvi === 'Y') {
                try {
                    const aviRes = await GetCertLivAnmByEsid(res.data.id);
                    dispatch(setLivAnmsSelectedValue(aviRes.data));
                } catch (error) {
                    dispatch(setLivAnmsSelectedValue(null));
                }
            } else {
                dispatch(setLivAnmsSelectedValue(null));
            }
            window.scrollTo(0, 350);
            dispatch(setEsidExpTabPaneActive(esidExpTabsEnum.ExpRegis));
        } catch (error) {
            errorHandling(error, history, location.pathname);
        }
    }

    const [chkLoadSts, setChkLoadSts] = useState(false);
    const selectEsidExportToPayment = async (id) => {
        try {
            setChkLoadSts(true);
            localStorage.removeItem('esidExp');
            const res  =  await GetEsidExportById(id);
            dispatch(setEsidExpSelected(res.data));
            if (res.data.id && res.data.shcDgr === 'Y') {
                await fetchDgrData(res.data.id);
            } else {
                dispatch(setDgrSelectedValue(null));
            }
            const esidData = res.data;
            const chkLoadRes = await ExpWebCheckLoadLst({
                COD_AWB_PFX: esidData.codAwbPfx,
                COD_AWB_NUM: esidData.codAwbNum,
                COD_AWB_SFX: esidData.codAwbSfx,
                COD_HWB_NUM: esidData.codHwb ?? '',
            });
            if (chkLoadRes?.data?.mCol?.length >= 1) {
                setChkLoadSts(false);
                dispatch(setExpBilChgLst(chkLoadRes.data.mCol));
                dispatch(setModalEsidVisibility({ [ModalName.ExpBillCharge]: true }));
            } 
            // else if (chkLoadRes?.data?.mCol?.length === 1) {
            //     setChkLoadSts(false);
            //     dispatch(setExpBilChg(chkLoadRes.data.mCol[0]));
            //     window.scrollTo(0, 380);
            //     history.push("/Esid/Checkout");
            // }
        } catch (error) {
            setChkLoadSts(false);
            errorHandling(error, history, location.pathname);
        }
    }
    return (<>
        <Spin spinning={chkLoadSts}>
            <Table
                style={{marginTop: 10}}
                columns={columns}
                dataSource={dataSource}
                pagination={{current: currentPage}}
                onChange={handleTableChange}
                className={'esid-table'}
                bordered
                title={() => 
                    <Space direction='horizontal'>
                        <Typography.Text strong>{t("esid.exportList")}</Typography.Text> 
                        <Typography.Text style={{color: 'rgb(255, 151, 0)'}}>{`( ${t("esidExpList.table.rowInfo")} )`}</Typography.Text>
                    </Space>
                }
                onRow={(record) => {
                    return {
                    onClick: () => selectEsidExport(record.esidId), // click row
                    };
                }}
            />
        </Spin>
    </>)
}

export default EsidTable