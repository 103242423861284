/* eslint-disable */

import React, { useEffect, useState } from 'react';
import * as Constant from '../config/Constant';
import * as API from '../config/apiConfig';
import './component-css/FooterSlides.css';
import { useSelector } from 'react-redux';
const FooterSlides = () => {
	const [brands, setBrands] = useState([]);
	const { langId } = useSelector((state) => state.menu);
	const [countIndex, setCountIndex] = useState(0);

	useEffect(() => {
		API.getHomeNews('links', langId)
			.then((res) => {
				setBrands(res.data);
				setCountIndex(res.data.length);
			})
			.catch((err) => console.log(err));
	}, []);

	return (
		<>
			<div className="slider">
				<div
					className="slide-track"
					// style={{ width: `calc(10000px)` }}
					style={{ width: `calc(100px * ${countIndex})` }}
				>
					{brands.map((brand, index) => {
						return (
							<div key={index} className="slide" _blade="true">
								<a href={brand.link}>
									<img
										src={`${Constant.IMAGES_URL}${brand.image}`}
										alt="lg"
									/>
								</a>
							</div>
						);
					})}
				</div>
			</div>
		</>
	);
};
export default FooterSlides;
