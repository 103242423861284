import { Checkbox, Col, Row, Typography } from "antd";
import React from "react";
import styles from './TabExpDeclConsol.module.css'
import { consolServices } from "../../../constant/enumerations";
import moment from "moment";
import { urlLogoTcs } from "../../../image/logotcs_base64";

export class ExpDeclConsolPrint extends React.PureComponent {
  // eslint-disable-next-line
  constructor(props) {
    super(props);
  }
  render() {
    const { expConsol } = this.props;
    const datDecl = new Date(expConsol.datDecl || expConsol.datCre);
    return (
      <div style={{ display: "flex", justifyContent: "center", fontFamily: "sans-serif" }}>
        <div className="awb-tab-content" style={{ width: "770px", padding: 12 }}>
            <div className={styles.spaceBetween} style={{ alignItems: 'flex-end' }}>
                <div className={styles.logoContainer}>
                    <img src={urlLogoTcs} alt="tcs" className={styles.logo}/>
                </div>
                <div className={styles.titleContainer}>
                    <Typography.Text strong style={{ fontSize: 20 }}>PHIẾU TIẾP NHẬN HÀNG HÓA</Typography.Text>
                    <Typography.Text italic style={{ fontSize: 16 }}>DECLARATION FOR CONSOLIDATION SHIPMENT</Typography.Text>
                </div>
                <span style={{ opacity: 0.7 }}>QF/ED/33</span>
            </div>
            <div className={styles.shipmentDetails}>
                <div style={{ paddingLeft: 0 }}>
                    <Typography.Text strong>1. Thông tin hàng hóa tiếp nhận</Typography.Text>
                    <Typography.Text italic>{`(Shipment details)`}</Typography.Text>
                </div>
                <div style={{ display: 'flex', gap: 10, width: '100%' }}>
                    <div style={{ flex: 1, display: 'flex', flexDirection: 'row' }}>
                        <div style={{ display: 'flex', flexDirection: 'column'}}>
                            <Typography.Text strong>Số không vận đơn chính:</Typography.Text>
                            <Typography.Text italic>Master Airway bill</Typography.Text>
                        </div>
                        <div style={{ flex: 1, display: 'flex', justifyContent: 'center', borderBottom: '1px solid black', height: 20 }}>
                            <Typography.Text>{expConsol.codAwbPfx}{expConsol.codAwbNum}</Typography.Text>
                        </div>
                    </div>
                    <div style={{ flex: 1, display: 'flex', flexDirection: 'row' }}>
                        <div style={{ display: 'flex', flexDirection: 'column'}}>
                            <Typography.Text strong>Tổng số kiện:</Typography.Text>
                            <Typography.Text italic>Total Pieces</Typography.Text>
                        </div>
                        <div style={{ flex: 1, display: 'flex', justifyContent: 'center', borderBottom: '1px solid black', height: 20 }}>
                            <Typography.Text>{expConsol.qtyPcs}</Typography.Text>
                        </div>
                    </div>
                </div>
            </div>
            <table className={styles.consolTable}>
                <tr>
                    <th>STT</th>
                    <th>
                        <Typography.Text strong style={{ fontSize: 14 }}>SỐ VẬN ĐƠN PHỤ</Typography.Text>
                        <br />
                        <Typography.Text italic style={{ fontSize: 12, fontWeight: 400 }}>House Airway bill</Typography.Text>
                    </th>
                    <th>
                        <Typography.Text strong style={{ fontSize: 14 }}>Số định danh hàng hóa</Typography.Text>
                        <br />
                        <Typography.Text italic style={{ fontSize: 12, fontWeight: 400 }}>QR code</Typography.Text>
                    </th>
                    <th>
                        <Typography.Text strong style={{ fontSize: 14 }}>SỐ KIỆN</Typography.Text>
                        <br />
                        <Typography.Text italic style={{ fontSize: 12, fontWeight: 400 }}>Total Pieces</Typography.Text>
                    </th>
                    <th>
                        <Typography.Text strong style={{ fontSize: 14 }}>SỐ KÝ</Typography.Text>
                        <br />
                        <Typography.Text italic style={{ fontSize: 12, fontWeight: 400 }}>Weight by Kilo</Typography.Text>
                    </th>
                    <th>
                        <Typography.Text strong style={{ fontSize: 14 }}>CHỦNG LOẠI HÀNG</Typography.Text>
                        <br />
                        <Typography.Text italic style={{ fontSize: 12, fontWeight: 400 }}>Nature of Goods</Typography.Text>
                    </th>
                </tr>
                {
                    expConsol.consolDetails?.map((item, index) => 
                        <tr>
                            <td>{index + 1}</td>
                            <td>{item.codHwbNum}</td>
                            <td>{item.qrCod}</td>
                            <td>{item.qtyPcs}</td>
                            <td>{item.qtyWgt ? item.qtyWgt : ''}</td>
                            <td>{item.natureOfGoods}</td>
                        </tr>
                    )
                }
            </table>
            <div className={styles.shipmentDetails}>
                <div style={{ paddingLeft: 0 }}>
                    <Typography.Text strong>2. Đăng ký sử dụng dịch vụ</Typography.Text>
                    <Typography.Text italic>{`(Shipment registration):`}</Typography.Text>
                </div>
                <div style={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
                    <Typography.Text strong>Khách hàng đánh dấu "X" vào ô trống để đăng ký các dịch vụ sau:</Typography.Text>
                    <Typography.Text italic>Check "X" in the box to register the following services:</Typography.Text>
                </div>
                <div className={styles.checkBoxContainer}>
                    <Checkbox checked={expConsol.regisServices?.findIndex(x => x.codHdlReg === consolServices.PrintQRCode) > -1}>{`In số định danh hàng hóa (Printing QR code)`}</Checkbox>
                    <Checkbox style={{ marginLeft: 0 }} checked={expConsol.regisServices?.findIndex(x => x.codHdlReg === consolServices.FWB) > -1}>{`Gửi điện FWB (Sending FWB)`}</Checkbox>
                    <Checkbox style={{ marginLeft: 0 }} checked={expConsol.regisServices?.findIndex(x => x.codHdlReg === consolServices.FHL) > -1}>{`Gửi điện FHL (Sending FHL)`}</Checkbox>
                </div>
                <Typography.Text style={{ fontSize: 14 }}>
                    Đại lý hàng hóa/ Người gửi hàng chịu toàn bộ trách nhiệm về nội dung khai báo các lô hàng trên đây, đảm bảo các thông tin khai báo đúng với thực tế.
                </Typography.Text>
                <Typography.Text style={{ fontSize: 14 }} italic>
                    Shipper is responsible for all the contents of declaration for consolidation above and all the information is correct.
                </Typography.Text>
                <div className={styles.dateContainer}>
                    <Typography.Text style={{ fontSize: 14}}>{`${datDecl.getHours()} giờ ${datDecl.getMinutes()} phút, ngày ${datDecl.getDate()} tháng ${datDecl.getMonth() + 1} năm ${datDecl.getFullYear()}`}</Typography.Text>
                </div>
                <Row>
                    <Col span={12} className={`${styles.col}`}>
                        <div className={styles.colTitle}>
                            <Typography.Text strong>Xác nhận của NV TCS</Typography.Text>
                            <Typography.Text italic>{`(ký và ghi rõ họ tên)`}</Typography.Text>
                            <p>{moment(expConsol.acpDat).format('DD/MM/YYYY HH:mm')}</p>
                        </div>
                        <div className={styles.colTitle} style={{ marginTop: '40px' }}>{expConsol.acpStfNam}</div>
                    </Col>
                    <Col span={12} className={`${styles.col}`}>
                        <div className={styles.colTitle}>
                            <Typography.Text strong>Người khai</Typography.Text>
                            <Typography.Text italic>{`(ký và ghi rõ họ tên)`}</Typography.Text>
                        </div>
                        <div className={styles.colTitle} style={{ marginTop: '76px' }}>{expConsol.declNam}</div>
                    </Col>
                </Row>
            </div>
        </div>
      </div>
    );
  }
}
