import axiosInstance from "./AuthIntercept";
import { DOMAIN_CLIENT } from "./Constant";

/**
 * Get ESID CTC CMP (Customer) list
 * @returns
 */
export async function GetEsidCtcCmpList() {
  return axiosInstance.get(`${DOMAIN_CLIENT}EsidCtcCmp/GetByUsr`);
}

/**
 * Get an ESID CTC CMP by Id
 * @param {*} id
 * @returns
 */
export function GetEsidCtcCmpById(id = 0) {
  return axiosInstance.get(`${DOMAIN_CLIENT}EsidCtcCmp/${id}`);
}

/**
 * Create an ESID CTC CMP
 * @param {*} data
 * @returns
 */
export function CreateEsidCtcCmp(data) {
  return axiosInstance.post(`${DOMAIN_CLIENT}EsidCtcCmp/Create`, data);
}

export function CheckExistsTaxCode(data) {
  return axiosInstance.post(`${DOMAIN_CLIENT}EsidCtcCmp/CheckExistsTaxCode`, data);
}

/**
 * Update an ESID CTC CMP
 * @param {*} id
 * @param {*} data
 * @returns
 */
export function UpdateEsidCtcCmp(id = 0, data) {
  return axiosInstance.put(`${DOMAIN_CLIENT}EsidCtcCmp/Update/${id}`, data);
}

/**
 * Create an ESID Export
 * @param {*} data
 * @returns
 */
export function CreateEsidExport(data) {
  return axiosInstance.post(`${DOMAIN_CLIENT}EsidExport/Create`, data);
}

/**
 * Get an ESID Export by Id
 * @param {*} id 
 * @returns 
 */
export function GetEsidExportById(id = 0) {
  return axiosInstance.get(`${DOMAIN_CLIENT}EsidExport/GetById/${id}`);
}

export function CancelEsid(id = 0) {
  return axiosInstance.post(`${DOMAIN_CLIENT}EsidExport/CancelEsid/${id}`);
}

/**
 * Get an ESID Export by AWB
 * @param {*} awbPfx 
 * @param {*} awbNum 
 * @returns 
 */
export function GetEsidExportByAwb(awbPfx = '', awbNum = '') {
  return axiosInstance.get(`${DOMAIN_CLIENT}EsidExport/GetByAwb?awbPfx=${awbPfx}&awbNum=${awbNum}`);
}

/**
 * Check the AWB already exists.
 * @param {*} awbPfx 
 * @param {*} awbNum 
 * @returns 
 */
export function EsidCheckAwb(awbPfx = '', awbNum = '') {
  return axiosInstance.get(`${DOMAIN_CLIENT}EsidExport/CheckAwb?awbPfx=${awbPfx}&awbNum=${awbNum}`);
}

export function GetAllAirports() {
  return axiosInstance.get(`${DOMAIN_CLIENT}Airport/GetAll`);
}
export function GetDeclDgrByEsid(id = 1) {
  return axiosInstance.get(`${DOMAIN_CLIENT}EsidExport/GetDeclDgrByEsid/${id}`);
}
export function GetCertLivAnmByEsid(id = 1) {
  return axiosInstance.get(`${DOMAIN_CLIENT}EsidExport/GetCertLivAnmByEsid/${id}`);
}
export function CreateDeclDgr(data) {
  return axiosInstance.post(`${DOMAIN_CLIENT}EsidExport/CreateDeclDgr`, data);
}
export function CreateCertLivAnm(data) {
  return axiosInstance.post(`${DOMAIN_CLIENT}EsidExport/CreateCertLivAnm`, data);
}
export function CreateVhcReg(data) {
  return axiosInstance.post(`${DOMAIN_CLIENT}EsidExport/CreateVhcReg`, data);
}
export function GetVhcRegById(id) {
  return axiosInstance.get(`${DOMAIN_CLIENT}EsidExport/GetVhcRegById/${id}`);
}

export function GetAllEsidExpByCondition({esidId = '', awbPfx = '', awbNum = '', fromDate, toDate, codRefNum = '', codSts = ''}) {
  return axiosInstance.get(`${DOMAIN_CLIENT}EsidExport?esidId=${esidId}&awbPfx=${awbPfx}&awbNum=${awbNum}&fromDate=${fromDate}&toDate=${toDate}&codRefNum=${codRefNum}&codSts=${codSts}`);
}

export function GetVhcRegByToDate({fromDate, toDate, codRefNum = ''}) {
  return axiosInstance.get(`${DOMAIN_CLIENT}EsidExport/GetVhcRegByToDate?fromDate=${fromDate}&toDate=${toDate}&codRefNum=${codRefNum}`);
}
export function CreateEsidExportAcceptance(data) {
  return axiosInstance.post(`${DOMAIN_CLIENT}EsidExport/EsidExportAcceptance/${data.id}`,data);
}
export function CheckIfDrgIsAllowEditing(id) {
  return axiosInstance.post(`${DOMAIN_CLIENT}EsidExport/CheckDgrToEdit/${id}`);
}
export function UpdateInformationOfDgr(esidId, dgrId, data) {
  return axiosInstance.put(`${DOMAIN_CLIENT}EsidExport/${esidId}/UpdateDgr/${dgrId}`, data);
}
export function CheckIfLivAnmIsAllowEditing(id) {
  return axiosInstance.post(`${DOMAIN_CLIENT}EsidExport/CheckLivAnimalToEdit/${id}`);
}
export function UpdateInformationOfLivAnm(esidId, livAnmId, data) {
  return axiosInstance.put(`${DOMAIN_CLIENT}EsidExport/${esidId}/UpdateCertLivAnimal/${livAnmId}`, data);
}
export function CalDimVolWgt(data) {
  return axiosInstance.post(`${DOMAIN_CLIENT}EsidExport/CalDimVolWgt`, data);
}
export function UpdateEsidExp(data) {
  return axiosInstance.put(`${DOMAIN_CLIENT}EsidExport/Update/${data.id}`, data);
}

/**
 * ESID Exp Web Chk load
 * @param {*} data
 * @returns
 */
export function ExpWebCheckLoad(data) {
  return axiosInstance.post(`${DOMAIN_CLIENT}EsidExport/CheckLoad`, data);
}

/**
 * ESID Exp Web Chk load
 * @param {*} data
 * @returns
 */
export function ExpWebCheckLoadLst(data) {
  return axiosInstance.post(`${DOMAIN_CLIENT}EsidExport/CheckLoad`, data);
}

/**
 * ESID Exp Web Chk Get Awb Detail
 * @param {*} data
 * @returns
 */
export function ExpGetAwbDetail(data) {
  return axiosInstance.post(`${DOMAIN_CLIENT}EsidExport/GetAwbDetail`, data);
}

/**
 * ESID Exp Web Chk Load bill charge
 * @param {*} data
 * @returns
 */
export function ExpLoadBilCharge(data) {
  return axiosInstance.post(`${DOMAIN_CLIENT}EsidExport/LoadBilCharge`, data);
}

export async function SignIn(username, password, captchaId, captchaCode) {
  return await axiosInstance.post(`${DOMAIN_CLIENT}Auth/SignIn`, {
    password: password,
    username: username,
    captcha: {
      id: captchaId,
      code: captchaCode
    }
  });
}

// esid checkout

/**
 * ESID Checkout Load
 * @param {*} data
 * @returns
 */
export function EsidCheckLoad(data) {
  return axiosInstance.post(`${DOMAIN_CLIENT}EsidWebChk/chkLoad`, data);
}

/**
 * ESID Checkout Get Awb Detail
 * @param {*} data
 * @returns
 */
export function EsidGetAwbDetail(data) {
  return axiosInstance.post(`${DOMAIN_CLIENT}EsidWebChk/getAwbDetail`, data);
}

/**
 * ESID Checkout Load bill charge
 * @param {*} data
 * @returns
 */
export function EsidLoadBilCharge(data) {
  return axiosInstance.post(`${DOMAIN_CLIENT}EsidWebChk/loadBillCharge`, data);
}

export function GetDgrTypeStatusOK() {
  return axiosInstance.get(`${DOMAIN_CLIENT}EsidExport/GetDgrTypeByStatus?status=OK`);
}

/**
 * ESID Checkout Load bill charge
 * @param {*} data
 * @returns
 */
export function RejectDGR(data) {
  return axiosInstance.post(`${DOMAIN_CLIENT}EsidExport/DgrReject`, data);
}

export const EsidUpdPayMode = (obj) => {
  return axiosInstance.post(
    `${DOMAIN_CLIENT}EsidWebChk/updatePaymentMode`,
    {
      ...obj,
    },
  );
}
export const EsidUpdOrderId = (obj) =>
  axiosInstance.post(
    `${DOMAIN_CLIENT}EsidWebChk/updateOrderId`,
    {
      ...obj,
    },
  );
export const EsidPaidAllInv = (obj) =>
  axiosInstance.post(
    `${DOMAIN_CLIENT}EsidWebChk/payment`,
    {
      ...obj,
    },
  );

  export const EsidIssueEInvoice = (obj) =>
  axiosInstance.post(
    `${DOMAIN_CLIENT}/EsidWebChk/issueEInvoice`,
    {
      ...obj,
    },
  );

  export const EXP_WEB_CHK_CHK_PAID = (obj) =>
  axiosInstance.post(
    `${DOMAIN_CLIENT}/EsidWebChk/chkPaid`,
    {
      ...obj,
    }
  );

  export const EXP_WEB_CHK_PAID_CDT_INVOICE = (obj, token) =>
  axiosInstance.post(
    `${DOMAIN_CLIENT}EsidWebChk/PaidCDTInvoice`,
    {
      ...obj,
    },
    {
      headers: {
        Authorization: token,
      },
    }
  );
  export const updateExpWebChk = (obj, token) =>
  axiosInstance.put(
    `${DOMAIN_CLIENT}EsidWebChk/updateExpWebChk`,
    {
      ...obj,
    },
    {
      headers: {
        Authorization: token,
      },
    }
  );

  export const updatePayment = (obj, token) =>
  axiosInstance.put(
    `${DOMAIN_CLIENT}EsidWebChk/updatePayment`,
    {
      ...obj,
    },
    {
      headers: {
        Authorization: token,
      },
    }
  );
/**
 * Get CONSOL by ESID
 * @param {*} id
 * @returns
 */
export function GetConsolByEsid(esidID = 0) {
  return axiosInstance.get(`${DOMAIN_CLIENT}EsidExport/GetExpConsolByEsid/${esidID}`);
}

export function CreateExpConsol(data) {
  return axiosInstance.post(`${DOMAIN_CLIENT}EsidExport/CreateExpConsol`, data);
}

export function UpdateExpConsol(id, data) {
  return axiosInstance.put(`${DOMAIN_CLIENT}EsidExport/UpdateExpConsol/${id}`, data);
}