import React from 'react'
import styles from './payment.module.css'
import { Button, Typography, Divider } from 'antd'
import { useTranslation } from 'react-i18next'
import { formatCurrency } from '../../../utils/utils'

export const BillInformation = ({ handlePrevTab, invoicesDetail }) => {
    const { t } = useTranslation("common");
    let total = 0;
    let totalTax = 0;
    return (
        <div className={styles.billInforContainer}>
            <div className={styles.detailContainer}>
                <div className={styles.detailItem} style={{ marginBottom: 5, backgroundColor: '#fafafa' }}>
                    <Typography.Title level={4}>{t("esidCheckout.paymentTab.paymentInfo")}</Typography.Title>
                    <Button onClick={handlePrevTab}>{t("esidCheckout.detailBtn")}</Button>
                </div>
                {
                    invoicesDetail && invoicesDetail.length > 0 && 
                        invoicesDetail.map(invoiceDetail => {
                            total+= invoiceDetail.AMT_GROSS - invoiceDetail.WAIVE_AMT + invoiceDetail.AMT_TAX;
                            totalTax += invoiceDetail.AMT_TAX;
                            return (
                            <div className={styles.detailItem} key={invoiceDetail?.Key}>
                                <Typography.Text>{invoiceDetail.COD_CHG}</Typography.Text>
                                <Typography.Text strong>{formatCurrency(invoiceDetail.AMT_GROSS - invoiceDetail.WAIVE_AMT)}</Typography.Text>
                            </div>
                        )})
                }
                <div className={styles.detailItem}>
                    <Typography.Text>{t("esidCheckout.invoiceDetail.totalVat")}</Typography.Text>
                    <Typography.Text strong>{formatCurrency(totalTax)}</Typography.Text>
                </div>
                <Divider />
                <div className={styles.detailItem}>
                    <Typography.Text strong>{t("esidCheckout.paymentTab.total")}</Typography.Text>
                    <Typography.Text strong>{formatCurrency(total)}</Typography.Text>
                </div>
            </div>
        </div>
    )
}
