/* eslint-disable */

import { Col, Row, Skeleton } from "antd";
import { useHistory } from "react-router-dom";
import React, { useEffect } from "react";
import "./component-css/CustomCard.css";
import { useDispatch, useSelector } from "react-redux";
import { getMenuById } from "../config/apiConfig";
import { menuItemSelected } from "../actions/menu";

const CustomCard = (props) => {
  const { langId, menuItem } = useSelector((state) => state.menu);
  const history = useHistory();
  const dispatch = useDispatch();
  let menuId;
  useEffect(() => {
    if (langId === "2") {
      if (props.cardType === "intro") menuId = 2;
      if (props.cardType === "service") menuId = 3;
      if (props.cardType === "news") menuId = 8;
    }
    if (langId === "1") {
      if (props.cardType === "intro") menuId = 41;
      if (props.cardType === "service") menuId = 50;
      if (props.cardType === "news") menuId = 60;
    }
  });
  const handleClick = async (link) => {
    const menuParentRes = await getMenuById(menuId);
    if (props.cardType === "service") {
      if (langId === "2") {
        const menuRes = await getMenuById(263);
        const setMenu = {
          ...menuItem,
          banner: menuParentRes.data.params,
          menuParentId: menuParentRes.data.parent,
          menuParentTitle: menuParentRes.data.name,
          title: menuRes.data.name,
          menuId: menuRes.data.id,
        };
        dispatch(menuItemSelected(setMenu));
        localStorage.setItem("menuItem", JSON.stringify(setMenu));
      } else {
        const setMenu = {
          ...menuItem,
          banner: menuParentRes.data.params,
          menuParentId: menuParentRes.data.parent,
          menuParentTitle: menuParentRes.data.name,
          menuId: 51,
        };
        dispatch(menuItemSelected(setMenu));
        localStorage.setItem("menuItem", JSON.stringify(setMenu));
      }
    } else {
      const setMenu = {
        ...menuItem,
        banner: menuParentRes.data.params,
        menuParentId: menuParentRes.data.parent,
        menuParentTitle: menuParentRes.data.name,
      };
      dispatch(menuItemSelected(setMenu));
      localStorage.setItem("menuItem", JSON.stringify(setMenu));
    }

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    history.push(`${link}`);
  };

  return (
    <Skeleton loading={props.loading}>
      <Row className="card-wrapper">
        <Col xs={0} sm={0} md={8} lg={24}  >
          <div className="card-cover-wrapper">
            <div  style={{width: '100%', height: '100%'}}>
              <a onClick={() => handleClick(props.link)}>
                <img
                  style={{ width: "100%",  height: '156.84px' }}
                  alt="card-cover"
                  src={props.cover}
                />
              </a>
            </div>
          </div>
        </Col>
        <Col sm={20} md={20} lg={22} xl={22} className="card-body">
          <div>
            <div className="card-title-wrapper">
              <a
                  onClick={() => handleClick(props.link)}
                >{props.title}
              </a>
            </div>
            <div className="card-content-wrapper">{props.children}</div>
            <div className="card-footer-wrapper">
              <a
                className="detail-link"
                onClick={() => handleClick(props.link)}
              >
                {langId === "2" ? "Xem tiếp" : "More"}
              </a>
            </div>
          </div>
        </Col>
      </Row>
    </Skeleton>
  );
};
export default CustomCard;
