import { Button, Col, Form, Modal, Row, Space, notification } from "antd";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setEsidExpTabPaneActive, setExpConsol } from "../../../actions/esidExportAction";
import { esidExpTabsEnum } from "../../../constant/enumerations";
import { ExpDeclConsolContainer } from "./components/ExpDeclConsolContainer";
import { useReactToPrint } from "react-to-print";
import { ExpDeclConsolPrint } from "./ExpDeclConsolPrint";
import { errorHandling } from "../../../config/AuthIntercept";
import { CreateExpConsol, UpdateExpConsol } from "../../../config/esidExportApi";

export const TabExpDeclConsol = () => {
  const { t } = useTranslation("common");
  const dispatch = useDispatch();
  const esidSelected = useSelector((state) => state.esidExport.esidExpSelected);
  const expConsolEdit = useSelector((state) => state.esidExport.expConsolEdit);
  const consolDtlList = useSelector(state => state.esidExport.consolDtlList);
  const consolRegServices = useSelector((state) => state.esidExport.consolRegServices);

  const [consoltMstForm] = Form.useForm();

  const handleBackToESID = () => {
    window.scrollTo(0, 350);
    dispatch(setEsidExpTabPaneActive(esidExpTabsEnum.ExpRegis));
  };
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    copyStyles: true,
    pageStyle: `
      @media all {
        .page-break {
          display: none;
        }
      }
      @media print {
        html, body {
          padding: 0.7rem 0.7rem 0.7rem 0.7rem;
          height: initial !important;
          overflow: initial !important;
          -webkit-print-color-adjust: exact;
        }
      }
      @media print {
        .page-break {
          margin-top: 0.7rem;
          display: block;
          page-break-before: auto;
        }
      }
      @media print {
        @page { 
          size: A4 portrait;
          margin: 0;
        }
      }
    `
  });

  const [loading, setLoading] = useState(false);
  const saveExpConsol = async () => {
    const consolData = await consoltMstForm.validateFields();
    if (consolDtlList.length === 0) {
      notification.error({
        message: 'Thông báo',
        description: 'Vui lòng nhập thông tin hàng hóa.'
      });
      return;
    }

    if (esidSelected.qtyPcs !== expConsolEdit.qtyPcs) {
      Modal.error({
          title: "Thông báo",
          content: 
          (
            <div>
              <div>Số lượng tiếp nhận hàng khác số lượng khai báo. Vui lòng kiểm tra lại.</div>
              <div style={{ fontWeight: 'bold' }}>{`Số lượng khai báo ESID: ${esidSelected.qtyPcs}`}</div>
              <div style={{ fontWeight: 'bold' }}>{`Số lượng tiếp nhận hàng: ${expConsolEdit.qtyPcs}`}</div>
            </div>
          ),
          onOk: () => {
              return;
          }
      });
      return;
    }

    const dataExpConsol = {
      ...expConsolEdit,
      ...consolData,
      consolDetails: consolDtlList,
      regisServices: consolRegServices
    }
    try {
      setLoading(true);
      const res = expConsolEdit?.id ? await UpdateExpConsol(expConsolEdit.id, dataExpConsol) : await CreateExpConsol(dataExpConsol);
      dispatch(setExpConsol(res.data));
      notification.success({
        message: "Thông báo",
        description: "Lưu thông tin thành công."
      });
    } catch (error) {
      errorHandling(error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <p className="exp-sub-title" style={{ marginTop: "10px" }}>
        {t("formInfo.declConsolLabel")}
      </p>
      <Row justify="center">
        <Col span={24} md={24} lg={22} xl={22} className="awb-tab-content">
          <p className="registry-notice">{t("header.registryInvNotice")}</p>
        </Col>
      </Row>
      <ExpDeclConsolContainer forms={{consoltMstForm}}/>
      <Space
        style={{
          marginTop: "20px",
          display: "flex",
          justifyContent: "center",
        }}
        className="awb-tab-content"
      >
        <Button onClick={handleBackToESID} style={{ width: "fit-content" }}>
          {t("formInfo.backToESID")}
        </Button>
        <Button 
          onClick={saveExpConsol} 
          style={{ width: "fit-content" }} 
          loading={loading}
          disabled={!['NY', 'VR', 'DR'].includes(esidSelected?.codSts)}
        >
          {t("formInfo.submit")}
        </Button>
        <Button onClick={handlePrint} style={{ width: "fit-content" }} disabled={!expConsolEdit?.id}>
          {t("header.print")}
        </Button>
      </Space>
      {expConsolEdit?.id && (
        <Row>
          <Col span={0}>
            <ExpDeclConsolPrint
              ref={componentRef}
              expConsol={expConsolEdit}
            />
          </Col>
        </Row>
      )}
    </>
  );
};
